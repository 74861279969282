import { MultiValueLabel } from "./MultiValueLabel"
import { MultiValueRemove } from "./MultiValueRemove"
import { MultiValueContainer } from "./MultiValueContainer"
import { MultiselectInput } from "./MultiselectInput"
import { MultiselectOption } from "./MultiselectOption"
import { MultiselectLoadingIndicator } from "./MultiselectLoadingIndicator"
import { MultiselectNoOptionsMessage } from "./MultiselectNoOptionsMessage"
import { MultiselectLoadingMessage } from "./MultiselectLoadingMessage"

export const updatedComponents = {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueContainer,
  Input: MultiselectInput,
  Option: MultiselectOption,
  LoadingIndicator: MultiselectLoadingIndicator,
  NoOptionsMessage: MultiselectNoOptionsMessage,
  LoadingMessage: MultiselectLoadingMessage
}

const emptyResultsComponentsSet = {
  MenuList: () => null,
  Menu: () => null,
  LoadingMessage: () => null,
  NoOptionsMessage: () => null
}

export const getPossiblyHiddenResultComponents = (
  shouldHideResults: boolean
) => ({
  ...(shouldHideResults ? emptyResultsComponentsSet : {})
})
