import React, { useCallback, useMemo } from "react"
import { messages } from "setup/messages/messages"
import {
  H3,
  H5,
  Div,
  Flex,
  BodyText,
  Button,
  Loader,
  SelectOptionProps,
  Link
} from "@ikiru/talentis-fpc"
import { Form, Formik } from "formik"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import {
  Frequency,
  PlanDetailsField
} from "./constants/plan-details.definitions"
import { RadioLabelComponent } from "../components/RadioLabelComponent"
import { PlanDetailsFormValues } from "./constants/plan-details.types"
import { formatPrice } from "utils/format-price"
import { currencyOptions } from "../constants"
import { Currency } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

interface IPlanDetailsForm {
  totalUserNumberOptions: SelectOptionProps[]
  initialValues: PlanDetailsFormValues
  countUsers: number
  monthlyAmount: number
  yearlyAmount: number
  oldMonthlyAmount: number
  oldYearlyAmount: number
  currency?: Currency
  isLoadingEstimate?: boolean
  onSubmit: (values: PlanDetailsFormValues) => void
  onCurrencyChange: (currency: Currency) => void
}

export const PlanDetailsForm = ({
  totalUserNumberOptions,
  initialValues,
  countUsers,
  monthlyAmount,
  yearlyAmount,
  oldMonthlyAmount,
  oldYearlyAmount,
  currency,
  isLoadingEstimate,
  onCurrencyChange,
  onSubmit
}: IPlanDetailsForm) => {
  const handleCurrencyChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onCurrencyChange(event.target.value as Currency)
    },
    [onCurrencyChange]
  )

  const yearlyPricePerMonth = useMemo(() => yearlyAmount / 12, [yearlyAmount])
  const oldYearlyPricePerMonth = useMemo(
    () => (yearlyAmount !== oldYearlyAmount ? oldYearlyAmount / 12 : undefined),
    [oldYearlyAmount, yearlyAmount]
  )

  return (
    <Formik {...{ initialValues, onSubmit }}>
      {({ values }) => (
        <Form>
          <Div width="360px" mb={["xxl", "m", "m"]}>
            {Boolean(countUsers < 51) && (
              <Div>
                <FormikSelect
                  id={PlanDetailsField.TotalUsersNumber}
                  name={PlanDetailsField.TotalUsersNumber}
                  label={
                    messages.subscription.planDetails.form.totalNumberOfUsers
                  }
                  options={totalUserNumberOptions}
                />
              </Div>
            )}
            {+values[PlanDetailsField.TotalUsersNumber] < 51 ? (
              <>
                <Div mb="m">
                  <FormikSelect
                    id={PlanDetailsField.Currency}
                    name={PlanDetailsField.Currency}
                    label={messages.subscription.planDetails.form.currency}
                    options={currencyOptions}
                    onChange={handleCurrencyChange}
                  />
                </Div>
                <Div mb="xxs">
                  <H5 m="0" color="grey.dark">
                    {messages.subscription.planDetails.form.billingFrequency}
                  </H5>
                </Div>
                {!isLoadingEstimate ? (
                  <>
                    <Div mb="xs">
                      <FormikToggle
                        hideUnderline
                        name={PlanDetailsField.Frequency}
                        value={Frequency.Year}
                        label={
                          <RadioLabelComponent
                            label={messages.subscription.planDetails.form.yearlyAmount.format(
                              formatPrice(
                                yearlyPricePerMonth,
                                currency || values.currency
                              )
                            )}
                            oldPrice={
                              oldYearlyPricePerMonth
                                ? formatPrice(
                                    oldYearlyPricePerMonth,
                                    currency || values.currency
                                  )
                                : undefined
                            }
                          />
                        }
                        type="radio"
                      />
                    </Div>
                    <FormikToggle
                      hideUnderline
                      name={PlanDetailsField.Frequency}
                      value={Frequency.Month}
                      label={
                        <RadioLabelComponent
                          label={messages.subscription.planDetails.form.monthlyAmount.format(
                            formatPrice(
                              monthlyAmount,
                              currency || values.currency
                            )
                          )}
                          oldPrice={
                            monthlyAmount !== oldMonthlyAmount
                              ? formatPrice(
                                  oldMonthlyAmount,
                                  currency || values.currency
                                )
                              : undefined
                          }
                        />
                      }
                      type="radio"
                    />
                  </>
                ) : (
                  <Flex p="xl">
                    <Loader>
                      <H3 color="green.standard" my="none">
                        {messages.subscription.loadingPlans}
                      </H3>
                    </Loader>
                  </Flex>
                )}

                <Button
                  mt="s"
                  disabled={values.totalUsersNumber === "0" && countUsers === 0}
                >
                  {
                    messages.subscription.planDetails.form
                      .continueToBillingInformation
                  }
                </Button>
              </>
            ) : (
              <Flex bg="red.lightest" p="xs" mb="m">
                <BodyText color="red.darkest" my="none" fontWeight="600">
                  {messages.subscription.planDetails.form.forMoreThan}{" "}
                  <Link href="mailto: info@ikirupeople.com" target="_blank">
                    {messages.subscription.planDetails.form.contactUs}
                  </Link>
                  {messages.subscription.planDetails.form.soWeCanTailor}
                </BodyText>
              </Flex>
            )}
          </Div>
        </Form>
      )}
    </Formik>
  )
}
