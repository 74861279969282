import React, { useRef, useEffect } from "react"

import { IndustryDropdown, IndustryItem, IndustryItemContainer } from "./style"

// @ts-ignore
export const IndustryList = (props) => {
  const activeRef = useRef()
  const containerRef = useRef()

  useEffect(() => {
    if (activeRef.current && containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollTop = activeRef.current.offsetTop
    }
  }, [activeRef, containerRef])

  useEffect(() => {
    if (props.selectedIndex === -1 && containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollTop = 0
    }
  }, [props.selectedIndex])

  const onClick = (industry: string) => {
    props.onItemClicked(industry)
  }

  return (
    <IndustryDropdown ref={containerRef}>
      {props.industries.map((industry: string, index: number) => (
        <IndustryItemContainer
          // @ts-ignore
          active={props.selectedIndex === index}
          hover={props.hoveredIndex === index}
          ref={props.selectedIndex === index ? activeRef : null}
          onMouseEnter={() => props.setHoveredIndex(index)}
          onClick={() => onClick(industry)}
        >
          <IndustryItem>{industry}</IndustryItem>
        </IndustryItemContainer>
      ))}
    </IndustryDropdown>
  )
}
