import React from "react"
import { Tag, TagVariants } from "@ikiru/talentis-fpc"
import { colors } from "@ikiru/talentis-fpc"
import { H4 } from "@ikiru/talentis-fpc"
import { List } from "./style"

type KeywordsListProps = {
  title?: string
  keywords?: string[]
  onKeywordRemove?: (keyword: string) => any
  variant?: TagVariants
}
export const KeywordsList = (props: KeywordsListProps) => {
  const { keywords = [], title, onKeywordRemove, variant = "grey-dark" } = props

  return keywords && Boolean(keywords?.length) ? (
    <>
      {title && (
        <H4 my="none" color={colors.grey.dark}>
          {title}
        </H4>
      )}
      <List>
        {keywords.map((keyword) => (
          <Tag
            key={JSON.stringify(keyword)}
            as="li"
            mr="xxs"
            mb="none"
            mt="xs"
            variant={variant}
            disabled={Boolean(!onKeywordRemove)}
            onRemoveClick={onKeywordRemove && (() => onKeywordRemove(keyword))}
          >
            {keyword}
          </Tag>
        ))}
      </List>
    </>
  ) : null
}
