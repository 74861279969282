import React, { useMemo, useState } from "react"
import { Button, colors } from "@ikiru/talentis-fpc"
import {
  ExistingInputWrapper,
  StyledBodyText,
  StyledRemoveButton,
  StyledRemoveIcon,
  StyledUndoIcon
} from "./style"

type CVCustomInputProps = {
  text: string
  isDeleted: boolean
  handleDelete: () => void
  width?: string
  typeText?: string
  isDisabled?: boolean
  isOverview?: boolean
}

const CVInputExist = ({
  text,
  isDeleted,
  handleDelete,
  width,
  typeText,
  isDisabled,
  isOverview = false
}: CVCustomInputProps) => {
  const [isHover, setIsHover] = useState(false)
  const [isButtonHover, setIsButtonHover] = useState(false)

  const textType = useMemo(
    () => (typeText && text !== "-" ? `(${typeText})` : ""),
    [typeText, text]
  )

  return (
    <ExistingInputWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      width={width || "100%"}
      isDisabled={isDisabled}
      isOverview={isOverview}
    >
      <StyledBodyText isDeleted={isDeleted}>
        {text} {textType}
      </StyledBodyText>
      {isHover && !isDeleted && !isDisabled && (
        <StyledRemoveButton
          onClick={handleDelete}
          onMouseEnter={() => setIsButtonHover(true)}
          onMouseLeave={() => setIsButtonHover(false)}
          color={colors.white.standard}
          size="action-small"
        >
          <StyledRemoveIcon isHover={isButtonHover} />
        </StyledRemoveButton>
      )}
      {isHover && isDeleted && !isDisabled && (
        <Button
          color={colors.white.standard}
          size="action-small"
          colorway={{ group: "grey", variant: "light" }}
          onClick={handleDelete}
          onMouseEnter={() => setIsButtonHover(true)}
          onMouseLeave={() => setIsButtonHover(false)}
        >
          <StyledUndoIcon isHover={isButtonHover} />
        </Button>
      )}
    </ExistingInputWrapper>
  )
}

export default CVInputExist
