import React from "react"
import { Flex, H1, CrossIcon, Button, Div } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { LoadedSearchesModalWrapper, SaveSearchSectionBody } from "./style"
import { SavedSearchRecord } from "./SavedSearchRecord"
import { SavedSearch } from "../../SearchModule/types"

interface LoadSavedSearchesModalProps {
  showSavedSearch: (searchId: string) => void
  remove: (searchId: string) => void
  setDefaultSearch: (searchId: string, isDefault: boolean) => void
  searches: SavedSearch[] | undefined
}

export const LoadSavedSearchesModal = ({
  showSavedSearch,
  searches,
  remove,
  setDefaultSearch
}: LoadSavedSearchesModalProps) => {
  const { close } = useModal()

  return (
    <LoadedSearchesModalWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <H1 color="green.standard" my="none" mr="xxs">
          {messages.talentGraphSearch.savedSearch.loadedSearches}
        </H1>
        <Button
          onClick={() => close(ModalNames.LoadSaveSearches)}
          mode="standard-white"
          size="action-medium"
        >
          <CrossIcon fill="grey.dark" />
        </Button>
      </Flex>
      <SaveSearchSectionBody>
        <Div width="100%" mb="xs">
          {searches?.map((search: SavedSearch) => (
            <SavedSearchRecord
              key={search.id}
              search={search}
              showSavedSearch={showSavedSearch}
              remove={remove}
              setDefaultSearch={setDefaultSearch}
            />
          ))}
        </Div>
      </SaveSearchSectionBody>
    </LoadedSearchesModalWrapper>
  )
}
