import { useCallback, useEffect, useMemo, useState } from "react"
import { mapChargebeePlans } from "views/subscription/subscription-purchase-module/plan-helpers"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import {
  Currency,
  SubscriptionPurchaseStep,
  User
} from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import {
  Frequency,
  PlanDetailsField
} from "./form/constants/plan-details.definitions"
import { PlanDetailsFormValues } from "./form/constants/plan-details.types"
import { generateTotalUsersNumberOption } from "./utils"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { messages } from "setup/messages/messages"
import React from "react"
import { BodyText, Flex, WarningIcon, colors } from "@ikiru/talentis-fpc"
import isEmpty from "lodash/isEmpty"
import { useTeam } from "views/team/team/team-module.context"

export const usePlanDetails = () => {
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false)
  const [subscribeUsers, setSubscribeUsers] = useState<User[]>([])

  const { user } = useTeam()

  const { open, close } = useModal()

  const {
    changeStep,
    setPlanDetails,
    usersCount,
    purchaseData: { plan, couponsUsed },
    fetchPlans,
    selectPlan
  } = useSubscriptionPurchase()

  useEffect(() => {
    const teamTeam = usersCount.map((team: User) => {
      return {
        id: team.id,
        firstName: team.firstName,
        lastName: team.lastName,
        emailAddress: team.emailAddress,
        isActive: true,
        status: team.status,
        userRole: team.userRole
      }
    })
    setSubscribeUsers(teamTeam)
  }, [usersCount])

  const changeSubscribeUsers = useCallback(
    (id: string) => {
      const updatedTeamMembers = subscribeUsers.map((teamMember: User) => {
        if (teamMember.id === id) {
          teamMember.isActive = !teamMember.isActive
        }
        return teamMember
      })
      setSubscribeUsers(updatedTeamMembers)
    },
    [subscribeUsers]
  )

  const countUsers = useMemo(() => {
    const tempSubscribeUsers =
      subscribeUsers.filter((user: User) => user.isActive === true) || []
    return tempSubscribeUsers.length
  }, [subscribeUsers])

  const planDetailsInitialValues = useMemo(
    () => ({
      [PlanDetailsField.TotalUsersNumber]: `${countUsers}`,
      [PlanDetailsField.Currency]: plan?.currency || Currency.USD,
      [PlanDetailsField.Frequency]: Frequency.Year
    }),
    [countUsers, plan]
  )

  const onCurrencyChange = useCallback(
    async (currency: Currency) => {
      setIsLoadingEstimate(true)
      const [, response] = await fetchPlans(
        currency,
        couponsUsed?.map((c) => c.code) || []
      )
      const availablePlans = response?.data || []

      const transformedPlans = mapChargebeePlans(availablePlans)

      const newPlan = transformedPlans.find(({ type }) => type === plan?.type)

      newPlan && selectPlan(newPlan)
      setIsLoadingEstimate(false)
    },
    [couponsUsed, fetchPlans, plan, selectPlan]
  )

  const handleCallbackWithCloseModal = useCallback(
    (callback?: any) => () => {
      callback?.()
      close(ModalNames.RemoveUsers)
    },
    [close]
  )

  const openConfirmation = useCallback(() => {
    open(
      ModalNames.RemoveUsers,
      <ConfirmationModal
        title={messages.subscription.purchase.titleDeactivate}
        cancelButtonLabel={messages.subscription.purchase.cancelButton}
        confirmButtonLabel={messages.subscription.purchase.confirmationButton}
        onClose={handleCallbackWithCloseModal()}
        onCancel={handleCallbackWithCloseModal()}
        onConfirm={handleCallbackWithCloseModal(() => {
          changeStep(SubscriptionPurchaseStep.BILLING_ADDRESS)
        })}
      >
        <Flex alignItems="center" width="90%">
          <WarningIcon width={50} height={50} fill={colors.orange.standard} />
          <BodyText ml="xs" textAlign="center">
            {messages.subscription.purchase.deactivateDescription}
          </BodyText>
        </Flex>
      </ConfirmationModal>
    )
  }, [handleCallbackWithCloseModal, open, changeStep])

  const onSubmit = useCallback(
    (values: PlanDetailsFormValues) => {
      if (values.totalUsersNumber === "0") {
        values.totalUsersNumber = countUsers.toString()
      }

      const users: string[] = subscribeUsers
        .filter((user) => user.isActive === true)
        .map((user) => user.id)

      setPlanDetails({
        ...values,
        totalUsersNumber: +values.totalUsersNumber,
        users
      })

      const owner = subscribeUsers.find(
        (subscribeUser: User) =>
          subscribeUser.id === user?.profile?.UserId && !subscribeUser.isActive
      )

      if (!isEmpty(owner)) {
        openConfirmation()
      } else {
        changeStep(SubscriptionPurchaseStep.BILLING_ADDRESS)
      }
    },
    [
      countUsers,
      setPlanDetails,
      changeStep,
      subscribeUsers,
      openConfirmation,
      user
    ]
  )
  const monthlyPrice = useMemo(
    () =>
      (plan?.monthly?.price?.total || 0) -
      (plan?.monthly?.price?.taxAmount || 0),
    [plan]
  )

  const oldMonthlyPrice = useMemo(
    () => plan?.monthly?.price?.amount || 0,
    [plan]
  )

  const yearlyPrice = useMemo(
    () =>
      (plan?.yearly?.price?.total || 0) - (plan?.yearly?.price?.taxAmount || 0),
    [plan]
  )

  const oldYearlyPrice = useMemo(() => plan?.yearly?.price?.amount || 0, [plan])

  return {
    initialValues: planDetailsInitialValues,
    totalUserNumberOptions: [
      ...Array(countUsers > 51 ? 51 : 52 - countUsers)
    ].map((_, index) => ({
      label: Boolean(index === 51 - countUsers)
        ? `${index + countUsers - 1}+`
        : generateTotalUsersNumberOption(index + countUsers, countUsers),
      value: `${index + countUsers}`
    })),
    countUsers,
    onSubmit,
    monthlyAmount: monthlyPrice,
    yearlyAmount: yearlyPrice,
    oldMonthlyAmount: oldMonthlyPrice,
    oldYearlyAmount: oldYearlyPrice,
    isLoadingEstimate,
    currency: plan?.currency,
    onCurrencyChange,
    subscribeUsers,
    changeSubscribeUsers
  }
}
