import { LinkAssignment } from "views/candidates/candidates.types"
import { Nullable } from "tsdef"
import { LocalPerson } from "models/LocalPerson/localPerson.definitions"
import { InterviewProgressState } from "views/persons/components/person-assignments/components/InterviewProgress/interview-progress.types"
import { Employment } from "views/persons/person.types"
import { CandidateFilter } from "../../assignments/assignment-module/assignment-module.types"
import {
  InterviewProgressStage,
  InterviewProgressStatus
} from "../../assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"

export type Discriminator = "Candidate" | "CampaignContact"

export type NormalizedPerson = {
  id: string
  dataPoolPersonId?: string
  name: string
  jobTitle: Nullable<string>
  company: Nullable<string>
  photo?: {
    url?: string
  }
  currentJob: Employment
}

export type Task = {
  photo: string
  assignTo: Nullable<string>
  assignmentId: string
  campaignId: string
  discriminator: Discriminator
  dueDate: Nullable<Date>
  id: string
  interviewProgressState?: Nullable<InterviewProgressState>
  normalizedPersonData?: any
  linkAssignment?: LinkAssignment
  linkCampaign?: any
  linkPerson: LocalPerson
  personId: string
  status: string
}

export type TaskManagementState = {
  isLoading: boolean
  totalItemCount: number
  totalFilteredCount: number
  filterTotalNumber: number
  pageNumber: number
  pageSize: number
  appliedFilters: CandidateFilter[]
  dueDates: Record<DueDatesTypes | string, number>
  isAssignment: boolean
  selectedTab: string
  selectedRecordId: string
  tasks: Task[]
  allAvailableFilters: FilterArrayType
  filteredTasks: Nullable<Task[]>
  overlayTaskIndex: number
}

export enum TaskManagementActionTypes {
  SetIsLoading = "setIsLoading",
  SetPageSize = "setPageSize",
  SetPageNumber = "setPageNumber",
  SetTasksCount = "setTasksCount",
  SetDueDates = "setDueDates",
  SetIsAssignment = "setIsAssignment",
  SetSelectedTab = "setSelectedTab",
  SetSelectedRecordId = "setSelectedRecordId",
  SetApplyFilters = "setApplyFilters",
  SetTasks = "setTasks",
  SetAllAvailableFilters = "setAllAvailableFilters",
  SetOverlayTaskIndex = "setOverlayTaskIndex"
}

export enum DueDatesTypes {
  Overdue = "overdue",
  Yesterday = "yesterday",
  Today = "today",
  Tomorrow = "tomorrow",
  Future = "future"
}

export type TaskManagementContextValues = TaskManagementState & {
  setPageNumber: SetPageNumber
  setIsAssignment: SetIsAssignment
  setSelectedTab: SetSelectedTab
  updateTask: UpdateTask
  updateFilters: UpdateFilters
  setSelectedRecordId: SetSelectedRecordId
  getTaskList: GetTaskList
  setTasks: SetTasks
  setOverlayTaskIndex: SetOverlayTaskIndex
}

export type FilterArrayType = {
  assignmentId: Nullable<string[]>
  stage: InterviewProgressStage[]
  status: InterviewProgressStatus[]
  assignedTo: Nullable<string[]>
}

export type SetIsLoading = (data: boolean) => void
export type SetPageNumber = (pageNumber: number) => void
export type SetTasksCount = (
  totalItems: number,
  filterTotalNumber: number
) => void
export type SetDueDates = (
  dueDates: Record<DueDatesTypes | string, number>
) => void
export type SetIsAssignment = (isAssignment: boolean) => void
export type SetSelectedTab = (selectedTab: string) => void
export type UpdateTask = (oldTask: Task, newAssignmentTask: Task) => void
export type UpdateFilters = (
  candidateFilters: CandidateFilter[],
  removing?: boolean
) => any
export type SetSelectedRecordId = (recordId: string) => void
export type SetTasks = (tasks: Task[]) => void
export type GetTaskList = (params?: URLSearchParams) => void
export type SetOverlayTaskIndex = (taskIndex: number) => void
