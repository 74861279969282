import { apiRequest } from "setup/api/api"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { CampaignRecord } from "./type"

export const pageSize = 25

export const getAllCampaigns = async (
  pageNumber?: number,
  status?: string,
  name?: string
) => {
  const tempStatus = status && status !== "all" ? status : ""
  const [, response] = await apiRequest.get({
    endpoint: CampaignsEndpoints.Root,
    config: {
      params: {
        pageSize: pageSize,
        page: pageNumber || 1,
        status: tempStatus,
        name: name || ""
      }
    }
  })

  const campaigns = response?.data.campaigns || []

  const campaignStatuses = response?.data.campaignStatuses || []

  return { campaigns, campaignStatuses }
}

export const getAllSearchCampaigns = async (name: string, status: string) => {
  const tempStatus = status === "all" ? "" : status
  const [, response] = await apiRequest.get({
    endpoint: CampaignsEndpoints.Root,
    config: {
      params: { pageSize: 300, page: 1, status: tempStatus, name: name }
    }
  })

  const campaignNames =
    response?.data.campaigns?.map(
      (campaign: CampaignRecord) => campaign.name
    ) || []

  return { campaignNames }
}
