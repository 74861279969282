import { useEffect, useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

const useCampaignNotes = () => {
  const [isLoading, setLoading] = useState(false)
  const { campaignId, setNotes } = useCampaign()

  const updateNotes = useCallback(async () => {
    if (!campaignId) return

    setLoading(true)

    const [, response] = await apiRequest.get({
      endpoint: CampaignsEndpoints.Notes,
      endpointParams: { id: campaignId }
    })

    if (response && response.data) {
      const notes = response.data.map((item: any) => ({
        ...item.note,
        linkCreatedByUser: item.createdBy,
        linkUpdatedByUser: item.updatedBy
      }))
      setNotes(notes)
    } else {
      setNotes([])
    }

    setLoading(false)
  }, [campaignId, setNotes])

  useEffect(() => {
    updateNotes()
  }, [updateNotes])

  return { isLoading }
}

export { useCampaignNotes }
