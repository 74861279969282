import { useCallback, useMemo, useState } from "react"
import { CVDataType } from "views/persons/person.types"
import { extractPersonData, sortDragAndDropItems } from "../../utils"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ItemDataType } from "components/FPCComponents/DragAndDrop/constants/types"

type useHandleDndItemProps = {
  dragType: "education" | "experience"
}
const useHandleDndItem = ({ dragType }: useHandleDndItemProps) => {
  const {
    cvParsing,
    setCVParsing,
    person,
    setDragItems: setItems,
    dragItems: items
  } = usePerson()

  const [hasDeletedAll, setHasDeletedAll] = useState(false)

  const updateTempPersonData = useCallback(
    (sortedItems: ItemDataType[]) => {
      const { tempParsed, tempExisting } = extractPersonData(sortedItems)

      if (dragType === "education") {
        setCVParsing({
          ...cvParsing,
          skills: {
            ...cvParsing.skills,
            parsedEducation: tempParsed,
            existingEducation: tempExisting
          }
        })
      } else {
        setCVParsing({
          ...cvParsing,
          professionalExperience: {
            parsed: tempParsed,
            existing: tempExisting
          }
        })
      }
    },
    [dragType, setCVParsing, cvParsing]
  )

  const onDeleteAll = () => {
    setHasDeletedAll(true)
    const newState = items
      ?.filter(
        (item) =>
          item.columnId === "2" || item.itemComponentProps.type === "parsed"
      )
      .map((item) => {
        if (item.itemComponentProps.type === "parsed") {
          item.columnId = "2"
          item.itemComponentProps.isMovedToExisting = false
          item.itemComponentProps.changedValues = {}
        }
        return item
      })
    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)

    setItems(sortedItems)
  }

  const onAddAll = () => {
    const newState = items.map((item) => {
      if (item.columnId === "2") {
        item.columnId = "1"
        if (item.itemComponentProps.type === CVDataType.Parsed) {
          item.itemComponentProps.isMovedToExisting = true
        } else {
          item.itemComponentProps.isMovedToParsed = false
        }
      }
      return item
    })
    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  const undoAddAll = () => {
    const newState = items.map((item) => {
      if (item.itemComponentProps.type === CVDataType.Parsed) {
        item.columnId = "2"
        item.itemComponentProps.isMovedToExisting = false
      }
      if (item.itemComponentProps.changedValues) {
        item.itemComponentProps.changedValues = {}
      }
      return item
    })
    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  const disableEditAll = useMemo(() => {
    if (dragType === "education") {
      if (Boolean(person?.education.length)) {
        return !Boolean(items?.filter((item) => item.columnId === "1").length)
      }
      return false
    } else {
      if (Boolean(person?.experience.length)) {
        return !Boolean(items?.filter((item) => item.columnId === "1").length)
      }
      return false
    }
  }, [dragType, items, person?.education.length, person?.experience.length])

  const disableRestoreAll = useMemo(() => {
    const hasData = Boolean(
      items?.filter((item) => item.type === CVDataType.Parsed).length
    )
    const dataMoved = Boolean(
      items?.filter((item) => item.itemComponentProps.isMovedToExisting).length
    )
    return !(dataMoved || hasData)
  }, [items])

  const disableAddAll = useMemo(() => {
    return !Boolean(items?.filter((item) => item.columnId === "2").length)
  }, [items])

  return {
    items,
    setItems,
    hasDeletedAll,
    setHasDeletedAll,
    disableAddAll,
    onAddAll,
    onDeleteAll,
    undoAddAll,
    disableEditAll,
    updateTempPersonData,
    disableRestoreAll
  }
}

export default useHandleDndItem
