import React, { MutableRefObject } from "react"
import { Formik, Form } from "formik"
import { FormikDropzone } from "components/functional/formik/formik-dropzone/FormikDropzone"
import { Dropzone } from "components/functional/dropzone/Dropzone"
import { OnUploadProjectDocumentSubmit } from "./upload-project-document.actions"
import {
  UploadProjectDocumentField,
  UploadProjectDocumentInitialValues
} from "./constants/upload-project-document.definitions"

type UploadProjectDocumentProps = {
  onSubmit: OnUploadProjectDocumentSubmit
  buttonRef?: MutableRefObject<HTMLButtonElement>
  onFileDialogCancel: () => void
}

export const UploadProjectDocument = (props: UploadProjectDocumentProps) => {
  const { onSubmit, buttonRef, onFileDialogCancel } = props
  return (
    <Formik
      initialValues={UploadProjectDocumentInitialValues}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { resetForm, isSubmitting } = formik
        return (
          <Form>
            <FormikDropzone
              innerRef={buttonRef}
              id={UploadProjectDocumentField.File}
              name={UploadProjectDocumentField.File}
              onFileDialogCancel={onFileDialogCancel}
              renderDropzoneComponent={(props) => (
                <Dropzone
                  {...props}
                  resetForm={resetForm}
                  isSubmitting={isSubmitting}
                />
              )}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
