import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import { useSearch } from "views/search/SearchModule/context"
import { Link } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { useFormikContext } from "formik"

const SearchCriteriaActions = () => {
  const { resetFilters } = useSearch()
  const { resetForm } = useFormikContext()

  const handleResetClick = useCallback(() => {
    resetFilters()
    resetForm()
  }, [resetFilters, resetForm])

  return (
    <>
      <Link onClick={handleResetClick} my="none">
        <SmallText fontWeight="600" color="white.standard" my="none">
          {messages.talentGraphSearch.clearAll}
        </SmallText>
      </Link>
    </>
  )
}

export default React.memo(SearchCriteriaActions)
