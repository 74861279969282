import * as Yup from "yup"
import { SignupField } from "views/users/signup/form/constants/signup.definitions"

export const signupValidationSchema = Yup.object({
  [SignupField.SearchFirmName]: Yup.string().required("Required"),
  [SignupField.SearchFirmCountryCode]: Yup.string().required("Required"),
  [SignupField.SearchFirmPhoneNumber]: Yup.string(),
  [SignupField.UserFirstName]: Yup.string().required("Required"),
  [SignupField.UserLastName]: Yup.string().required("Required"),
  [SignupField.UserEmailAddress]: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  [SignupField.UserPassword]: Yup.string()
    .required("Required.")
    .min(8, "Password is too short - should be 8 chars minimum.")
})
