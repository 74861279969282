import React from "react"
import { FormikProps } from "formik"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { Button, Flex, PlusIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  CreatePersonField,
  CreatePersonValues
} from "../../../constant/definitions"
import { AddButtonContainer } from "../../person-web-links/components/style"
import { DefaultEditedField } from "components/EditableDetails/editable-details.types"

type ButtonRefKey = "addPhoneNumberButtonRef" | "addEmailAddressButtonRef"

type EditableDetailsSectionButtonProps = {
  innerRefs: {
    [key in ButtonRefKey]: React.MutableRefObject<HTMLButtonElement>
  }
  formikProps: FormikProps<CreatePersonValues>
  defaultEditedField?: DefaultEditedField
} & Omit<React.HTMLProps<HTMLButtonElement>, "hidden" | "type" | "aria-label">

export const EditableContactDetailsSectionButton = (
  props: EditableDetailsSectionButtonProps
) => {
  const { innerRefs, formikProps, defaultEditedField } = props
  const { addPhoneNumberButtonRef, addEmailAddressButtonRef } = innerRefs

  const onAddField = (
    { setFieldValue, values }: FormikProps<CreatePersonValues>,
    FieldName: keyof Pick<PersonProfileDetails, "phones" | "taggedEmails">
  ) => {
    const array = values[FieldName] as PersonValue<any>[]
    const isAnyEmpty = !array.every(Boolean)

    if (!isAnyEmpty) {
      setFieldValue(FieldName, [
        ...values[FieldName],
        { value: "", isReadOnly: false }
      ])
    }
  }

  return (
    <AddButtonContainer>
      <Flex>
        {defaultEditedField !== CreatePersonField.EmailAddresses && (
          <Button
            ml="xxs"
            size="small"
            prefix={<PlusIcon fill="currentColor" width={12} height={12} />}
            mode="standard-white"
            onClick={(e) => {
              onAddField(formikProps, CreatePersonField.PhoneNumbers)
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {messages.form.generic.phone}
          </Button>
        )}
        {defaultEditedField !== CreatePersonField.PhoneNumbers && (
          <Button
            ml="xxs"
            size="small"
            prefix={<PlusIcon fill="currentColor" width={12} height={12} />}
            mode="standard-white"
            onClick={(e) => {
              onAddField(formikProps, CreatePersonField.EmailAddresses)
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {messages.form.generic.emailAddress}
          </Button>
        )}
        <button
          ref={addPhoneNumberButtonRef}
          hidden
          type="button"
          aria-label="add-phone-number-button"
          onClick={() =>
            onAddField(formikProps, CreatePersonField.PhoneNumbers)
          }
        />
        <button
          ref={addEmailAddressButtonRef}
          hidden
          type="button"
          aria-label="add-email-address-button"
          onClick={() =>
            onAddField(formikProps, CreatePersonField.EmailAddresses)
          }
        />
      </Flex>
    </AddButtonContainer>
  )
}
