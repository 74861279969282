import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { InteractiveSignet as Signet } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { SidebarContext } from "components/visual/header/SidebarContext"
import { preventDefault } from "utils/prevent-default"
import { LogoWrapper } from "./SidebarLogo.styled"
import { InteractiveFullLogo } from "@ikiru/talentis-fpc"

type SidebarLogoProps = {
  homepageDisabled?: boolean
}

export const SidebarLogo = React.memo(
  ({ homepageDisabled }: SidebarLogoProps) => {
    const { isExpanded } = useContext(SidebarContext)

    return (
      <LogoWrapper disabled={homepageDisabled}>
        <Link
          {...(homepageDisabled
            ? {
                onClick: preventDefault
              }
            : {})}
          to={RouterUrl.Homepage}
        >
          {isExpanded && <InteractiveFullLogo height={33} />}
          {!isExpanded && <Signet height={33} />}
        </Link>
      </LogoWrapper>
    )
  }
)
