import React from "react"
import { StyledMultiselectInput } from "../styles"
import { components } from "react-select"

// there's something wrong with the react-select prop types - the type that's expected
// in the parent's components object doesn't contain "selectProps" even though it's clearly
// in the props object
export const MultiselectInput = (props: any) => {
  const { selectProps, ...other } = props
  const {
    "data-e2e-target": e2eTarget,
    "data-e2e-target-name": e2eTargetName
  } = selectProps

  return (
    <StyledMultiselectInput
      as="div"
      status={selectProps.status}
      small={Boolean(selectProps?.value)}
    >
      <components.Input
        {...other}
        data-e2e-target={e2eTarget}
        data-e2e-target-name={e2eTargetName}
      />
    </StyledMultiselectInput>
  )
}
