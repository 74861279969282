export const tagColor = (color: string | undefined) => {
  switch (color) {
    case "yellow.lightest":
      return "yellow"
    case "orange.lightest":
      return "orange-lightest"
    case "red.lightest":
      return "red"
    case "green.lightest":
      return "green"
    case "grey.light":
      return "grey-light"
    case "grey.standard":
      return "grey-standard"
    default:
      return "white"
  }
}

export const spanTagColor = (color: string | undefined) => {
  switch (color) {
    case "yellow.lightest":
      return "yellow.light"
    case "orange.lightest":
      return "orange.light"
    case "red.lightest":
      return "red.light"
    case "green.lightest":
      return "green.standard"
    case "grey.light":
      return "grey.light"
    case "grey.standard":
      return "grey.standard"
    default:
      return "white"
  }
}
