import {
  AuthMethod,
  PossibleAuthActionSets
} from "setup/auth/module/constants/auth.types"

export const AuthActions = new Map<AuthMethod, PossibleAuthActionSets>([
  [
    AuthMethod.Redirect,
    {
      signIn: "signinRedirect",
      signOut: "signoutRedirect",
      signInCallback: "signinRedirectCallback",
      signOutCallback: "signoutRedirectCallback"
    }
  ],
  [
    AuthMethod.Popup,
    {
      signIn: "signinPopup",
      signOut: "signoutPopup",
      signInCallback: "signinPopupCallback",
      signOutCallback: "signoutPopupCallback"
    }
  ]
])

export const authInitialState = {
  isLoggedIn: false,
  managerReady: false,
  user: undefined,
  manager: undefined
}
