import React from "react"
import { Div, Flex, H5, H6, Toggle } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ParsedDataWrapper } from "./style"

type ParsedDataProps = {
  isAllChecked?: boolean
  checkedAll?: () => void
  title?: string
}

export const ParsedData = ({
  isAllChecked,
  checkedAll,
  title
}: ParsedDataProps) => {
  return (
    <ParsedDataWrapper>
      <H5 m="0">{messages.person.cvparsing.parsedData}</H5>

      {checkedAll && (
        <Flex alignItems="baseline">
          <H6 m="0" mr="xs">
            {title || messages.person.cvparsing.updateAll}
          </H6>
          <Div width="10%">
            <Toggle
              hideUnderline
              small
              type="checkbox"
              checked={isAllChecked}
              onChange={checkedAll}
            />
          </Div>
        </Flex>
      )}
    </ParsedDataWrapper>
  )
}
