import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { LittleText } from "@ikiru/talentis-fpc"

// This message is provided by Google, and required to be allowed to remove the recaptcha badge
// as per: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
export const RecaptchaMessage = () => (
  <Flex textAlign="left" opacity="0.5" mt="xxs">
    <LittleText>
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </LittleText>
  </Flex>
)
