import React, { lazy, Suspense } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import { Task } from "views/homepage/task-management-module/task-management-module.types"

const TaskRecordWrapper = lazy(
  () =>
    import(
      "views/homepage/components/TaskManagement/task-management-record/TaskRecordWrapper"
    )
)

const Tasks = () => {
  const { tasks } = useTaskManagement()

  const itemsCount = tasks.length

  return (
    <Div mb={-10} pl="14px" pr="16px" py={Boolean(itemsCount) ? "m" : "none"}>
      {tasks.map((task: Task, index: number) => (
        <Suspense fallback={<></>}>
          <AnimatePresence key={task.personId}>
            <motion.div
              key={task.personId}
              transition={{ duration: 1 }}
              initial={{ opacity: 0, width: "100%" }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Flex width="100%" mb="xs">
                <TaskRecordWrapper task={task} taskIndex={index} />
              </Flex>
            </motion.div>
          </AnimatePresence>
        </Suspense>
      ))}
    </Div>
  )
}

export default Tasks
