import { noop } from "lodash"
import { SearchState, SearchContextValues, SearchActionTypes } from "./types"

export const initialSearchState: SearchState = {
  isLoading: false,
  areFiltersExpanded: true,
  paginationProps: {
    hasNextPage: false,
    hasPreviousPage: false,
    isFirstPage: false,
    isLastPage: false,
    lastItemOnPage: 0,
    pageCount: 0,
    pageSize: 0,
    totalItemCount: 0,
    pageNumber: 0
  },
  persons: [],
  selectedSearchPersonId: "",
  setPersonsFound: () => {},
  setIsLoading: () => {},
  setPagination: () => {},
  updatePerson: () => {}
}

export const searchReducer = (
  state: SearchState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action

  switch (type) {
    case SearchActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case SearchActionTypes.SetPage:
      return { ...state, pageNumber: payload }
    case SearchActionTypes.SetRecordsCount:
      return {
        ...state,
        totalItems: payload?.totalItems || 0,
        totalPages: payload?.totalPages || 0
      }
    case SearchActionTypes.SetPersons:
      return {
        ...state,
        persons: payload,
        isLoading: false
      }
    case SearchActionTypes.UpdatePerson:
      const personIndex = state.persons.findIndex(
        (person) => person.id === payload.id
      )

      state.persons[personIndex] = payload
      return { ...state }
    case SearchActionTypes.SetSelectedSearchPersonId:
      return { ...state, selectedSearchPersonId: payload }
    case SearchActionTypes.SetPagination:
      return {
        ...state,
        paginationProps: { ...state.paginationProps, ...payload }
      }
    default:
      return state
  }
}

export const initialSearchContextValues: SearchContextValues = {
  ...initialSearchState,
  onPageChange: noop,
  setSelectedSearchPersonId: noop,
  updatePerson: noop
}
