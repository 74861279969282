import React, { useMemo } from "react"
import moment from "moment"
import { personDocumentsE2eTargets } from "views/persons/components/person-documnets/definitions"
import { apiRequest } from "setup/api/api"
import { Flex, Div, Link, Line, SmallText } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { messages } from "setup/messages/messages"
import PersonNoDataScreen from "views/persons/components/person-no-data-message/PersonNoDataScreen"
import { ProjectDocumentType } from "../types"
import { ProjectType, ProjectValues } from "../form/constants/consts"

type ProjectDocumentListProps = {
  documents: ProjectDocumentType
  projectId: string
  projectType: ProjectType
}

export const ProjectDocumentList = ({
  documents,
  projectId,
  projectType
}: ProjectDocumentListProps) => {
  const sortedDocuments = useMemo(
    () => documents?.sort((a, b) => moment(b.createdDate).diff(a.createdDate)),
    [documents]
  )

  const onDocumentClick = async (e: any, documentId: string) => {
    e.preventDefault()
    e.stopPropagation()

    const [error, response] = await apiRequest.get({
      endpoint: ProjectValues[projectType].downloadEndpoint,
      endpointParams: { id: projectId, documentId },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    !error && window.open(response?.data?.temporaryUrl)
  }

  return (
    <Div width="100%">
      {sortedDocuments.length ? (
        <Flex width="100%" flexWrap="wrap">
          {sortedDocuments?.map((document, index) => (
            <Div key={document.id} width="100%">
              {index !== 0 ? <Line variant="thin" /> : null}
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                pt={index === 0 ? "xs" : "s"}
                pb="s"
                data-e2e-target={personDocumentsE2eTargets.personDocument}
                flexWrap={["wrap", "nowrap"]}
              >
                <Div width={["100%", "auto"]}>
                  <Link
                    as="button"
                    type="button"
                    standalone
                    data-e2e-target={
                      personDocumentsE2eTargets.personDocumentLink
                    }
                    m={0}
                    textAlign="left"
                    onClick={(e) => onDocumentClick(e, document.id)}
                  >
                    {document.filename}
                  </Link>
                </Div>
                <SmallText m={0} mt={["xs", 0]}>
                  {formatDateTime(document.createdDate)}
                </SmallText>
              </Flex>
            </Div>
          ))}
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <PersonNoDataScreen
            message={messages.person.personTabSection.noDataTexts.noDocuments}
          />
        </Flex>
      )}
    </Div>
  )
}
