import styled from "styled-components"
import { GridBox, Div, Link } from "@ikiru/talentis-fpc"
import { OfflimitsProps, backgroundOffLimits } from "components/OffLimits/style"

const avatarBoxSize = "69px"
export const avatarSize = "59px"

export const PersonRecordBox = styled(Link)<OfflimitsProps>`
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  position: relative;
  width: 100%;
  min-height: 90px;
  border: 2px solid ${({ theme }) => theme.colors?.grey?.light};
  border-radius: ${({ theme }) => theme.borderRadius?.large}px;
  background: ${({ theme }) => theme.colors?.white?.standard};
  padding: 0;
  cursor: pointer;

  margin-top: ${({ theme }) => theme.space?.xs}px;
  margin-bottom: 0;
  ${({ isOfflimits }) => ` 
    grid-template-columns: ${isOfflimits ? "20px " : ""} ${avatarBoxSize} 1fr;
    grid-template-areas:
      "${isOfflimits ? "ifOfflimits" : ""} avatar name"
      "${isOfflimits ? "ifOfflimits" : ""} avatar company"
      "${isOfflimits ? "ifOfflimits" : ""} avatar jobTitle"
      "${isOfflimits ? "ifOfflimits" : ""} avatar location"
      "${isOfflimits ? "ifOfflimits" : ""} avatar websites";
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors?.grey?.dark};
  }

  ${({ theme, isOfflimits }) => `
    ${theme.mediaQueries?.xs} {
      grid-template-columns: ${
        isOfflimits
          ? `20px ${avatarBoxSize} 1.5fr repeat(3, 1fr) 1.5fr`
          : `${avatarBoxSize} 1fr 1.5fr 20px repeat(2, 1fr) 1.5fr`
      };
      grid-template-areas:
        "${
          isOfflimits
            ? "ifOfflimits avatar name name company jobTitle location"
            : "avatar name name empty company jobTitle location"
        }"
        "${
          isOfflimits
            ? "ifOfflimits avatar websites websites company jobTitle location"
            : "avatar websites websites empty company jobTitle location"
        }";
    }

  `}
`

export const AvatarBox = styled(GridBox)<OfflimitsProps>`
  grid-area: avatar;
  padding-top: ${({ theme }) => theme.space?.xs}px;
  padding-left: ${({ theme }) => theme.space?.xs}px;
  width: ${avatarBoxSize};
  ${({ theme }) => `
    ${theme.mediaQueries?.md} {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  `};
  ${backgroundOffLimits}
`

export const AvatarWrapper = styled(Div)`
  width: ${avatarSize};
  height: ${avatarSize};
`

export const NameDetailsBox = styled(GridBox)<OfflimitsProps>`
  grid-area: name;
  word-break: break-word;
  display: flex;
  padding: ${({ theme }) => theme.space?.xs}px;
  padding-bottom: 0;
  align-items: flex-start;
  ${backgroundOffLimits}
`
export const CompanyDetailsBox = styled(GridBox)<OfflimitsProps>`
  grid-area: company;
  word-break: break-word;
  padding: ${({ theme }) => theme.space?.xs}px;
  padding-top: ${({ theme }) => theme.space?.xs}px;
  padding-bottom: 0;

  ${({ theme }) => `
    ${theme.mediaQueries?.xs} {
      padding-top: ${theme.space?.xs}px;
      border-left: 1px solid ${theme.colors?.grey?.light};
     
    }
  `}

  @media (max-width: 575px) {
    ${backgroundOffLimits}
  }
`
export const JobTitleDetailsBox = styled(GridBox)<OfflimitsProps>`
  grid-area: jobTitle;
  word-break: break-word;
  padding: 0 ${({ theme }) => theme.space?.xs}px;

  ${({ theme }) => `
    ${theme.mediaQueries?.xs} {
      padding-top: ${theme.space?.xs}px;
      border-left: 1px solid ${theme.colors?.grey?.light};
    }
  `}
  @media (max-width: 575px) {
    ${backgroundOffLimits}
  }
`

export const LocationDetailsBox = styled(GridBox)<OfflimitsProps>`
  grid-area: location;
  word-break: break-word;
  padding: 0 ${({ theme }) => theme.space?.xs}px;

  ${({ theme }) => `
    ${theme.mediaQueries?.xs} {
      padding-top: ${theme.space?.xs}px;
      border-left: 1px solid ${theme.colors?.grey?.light};
    }
  `}

  @media (max-width: 575px) {
    ${backgroundOffLimits}
  }
`

export const WebsitesDetailsBox = styled(GridBox)<OfflimitsProps>`
  grid-area: websites;
  padding: ${({ theme }) => theme.space?.xs}px;

  & svg {
    height: 100%;
    width: auto;
    transition: filter 0.3s ease;
  }

  svg {
    &:hover {
      filter: brightness(0.8);
    }
  }
  ${backgroundOffLimits}
`

export const LinkIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
`

export const OffLimitsContainerBox = styled(GridBox)`
  grid-area: ifOfflimits;
`

export const EmptyBox = styled(GridBox)`
  grid-area: empty;
`
