import React, { useCallback } from "react"
import { StyledNotesList } from "components/Notes/components/style"
import { Line, Div, Loader } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { getOnEditNoteSubmit } from "components/Notes/helpers"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import NoteAssignmentItem from "./NoteAssignmentItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { useAssignmentNotes } from "../../hooks"

interface Note {
  id: string
  noteTitle: string
  noteDescription: string
}

const NotesList = () => {
  const { isLoading } = useAssignmentNotes()
  const { notes, setNotes, assignmentId } = useAssignment()

  const { open, close } = useModal()

  const onSubmit = useCallback(
    (noteId: string) =>
      getOnEditNoteSubmit(
        AssignmentsEndpoints.Notes,
        assignmentId,
        noteId,
        (item) => {
          const updatedNote = {
            ...item.note,
            linkCreatedByUser: item.createdBy,
            linkUpdatedByUser: item.updatedBy
          }
          const newNotes = notes.map((item) =>
            item.id === updatedNote.id ? updatedNote : item
          )
          setNotes(newNotes)
          close(ModalNames.EditNoteItemData)
        }
      ),
    [assignmentId, notes, setNotes, close]
  )

  const noteItemOpenModal = useCallback(
    (note: Note) => {
      const { noteTitle, noteDescription, id } = note
      open(
        ModalNames.EditNoteItemData,
        <Div mb="xs">
          <NoteEditForm
            initialValues={{
              noteTitle,
              noteDescription
            }}
            onSubmit={onSubmit(id)}
          />
        </Div>
      )
    },
    [onSubmit, open]
  )

  return (
    <StyledNotesList>
      {isLoading ? (
        <Loader />
      ) : (
        notes.map((note, index) => (
          <Div key={`note_${note?.id}`}>
            {index !== 0 && <Line variant="thin" />}
            <NoteAssignmentItem {...{ note, openModal: noteItemOpenModal }} />
          </Div>
        ))
      )}
    </StyledNotesList>
  )
}

export default React.memo(NotesList)
