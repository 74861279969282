import { FormikHelpers } from "formik"
import { SignupInitialValues } from "./constants/signup.definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { commonFormStateKey, FormStates } from "utils/forms/form-state"
import { AxiosResponse } from "axios"
import { SearchFirmEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type SignupValues = typeof SignupInitialValues

export const onSignupSubmit = async (
  values: SignupValues,
  helpers: FormikHelpers<SignupValues>,
  onSuccess?: VoidFunction
) => {
  const { setFieldError, setStatus, setSubmitting } = helpers
  setSubmitting(true)
  setStatus({
    [commonFormStateKey]: FormStates.Pending
  })

  const [error] = await apiRequest.post({
    endpoint: SearchFirmEndpoints.Root,
    data: values,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  setStatus({
    [commonFormStateKey]: FormStates.Static
  })

  setSubmitting(false)

  callWithErrorMessages(setFieldError, error as AxiosResponse)
  if (!error) {
    setStatus({
      [commonFormStateKey]: FormStates.Finished
    })
    onSuccess?.()
  }
}
