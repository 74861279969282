import React, { useMemo, useState } from "react"
import {
  BodyText,
  Button,
  Div,
  Flex,
  Link,
  PositiveIcon,
  SmallText,
  colors,
  theme
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import UsageIcon from "views/persons/components/person-details/components/UsageIcon"
import ContactLookUp from "../../../../person-details/components/ContactLookUp/index"
import { EmailTypeEnums, PhoneTypeEnums } from "../form/constants/definitions"
import { sortProfileDetails } from "views/persons/components/person-details/utlis"
import { SectionTitle } from "../../../style"
import ClickablePhoneNumber from "views/persons/components/person-details/components/PersonPhoneList/ClickableNumber"

const PersonContactsView = () => {
  const { profileDetails, personId } = usePerson()
  const [showTeaser, setShowTeaser] = useState(false)

  const { linkedInProfileUrl, company } = profileDetails

  const sortedProfileDetails = useMemo(
    () => sortProfileDetails(profileDetails),
    [profileDetails]
  )
  const showRocketReachPreview = () => setShowTeaser(true)
  const phoneNumbers = useMemo(
    () =>
      sortedProfileDetails.phones.filter((phone) =>
        Boolean(phone.value.phoneNumber)
      ),
    [sortedProfileDetails.phones]
  )

  const emails = useMemo(
    () => sortedProfileDetails.taggedEmails,
    [sortedProfileDetails.taggedEmails]
  )

  return (
    <Div py="xs">
      {Boolean(phoneNumbers.length) || Boolean(emails.length) ? (
        <>
          {Boolean(phoneNumbers.length) && (
            <>
              <SectionTitle>
                {messages.person.personTabSection.phoneNumbers}
              </SectionTitle>
              <Flex flexDirection="column" mt="xxs" ml="-5px">
                {phoneNumbers.map((phoneNum) => {
                  const viewBox =
                    phoneNum.value.phoneUsageType === "other"
                      ? "-9 -9 25 25"
                      : undefined
                  const scaleUpOther =
                    phoneNum.value.phoneUsageType === "other" ? 1.4 : 1.2
                  return (
                    <Flex alignItems="center" mb="xs">
                      <Flex alignItems="baseline">
                        <UsageIcon
                          scaleUp={scaleUpOther}
                          viewBox={viewBox}
                          usageType={
                            phoneNum.value.phoneUsageType as PhoneTypeEnums
                          }
                        />
                        <ClickablePhoneNumber
                          phoneNumber={phoneNum.value.phoneNumber}
                        />
                      </Flex>
                      {phoneNum.value.isPreferred && (
                        <Flex alignItems="center">
                          <PositiveIcon />
                          <SmallText
                            ml="xxs"
                            mb="0px"
                            fontWeight={700}
                            color={theme.colors.grey.standard}
                          >
                            {messages.form.generic.preferred}
                          </SmallText>
                        </Flex>
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            </>
          )}
          {Boolean(emails.length) && (
            <>
              <SectionTitle mt="xs">
                {messages.person.personTabSection.emailAddress}
              </SectionTitle>
              <Flex flexDirection="column" mt="xxs" ml="-5px">
                {emails.map((email) => {
                  const viewBox =
                    email.value.type === "other" ? "-6 -9 22 27" : undefined
                  const scaleUpOther = email.value.type === "other" ? 1.3 : 1.2
                  return (
                    <Flex alignItems="center" mb="xs">
                      <Flex alignItems="baseline">
                        <UsageIcon
                          isEmail
                          scaleUp={scaleUpOther}
                          viewBox={viewBox}
                          usageType={email.value.type as EmailTypeEnums}
                        />
                        <BodyText m="0" ml="4px" mr="5px">
                          <Link ml="none" href={`mailto:${email.value.email}`}>
                            {email.value.email}
                          </Link>
                        </BodyText>
                      </Flex>
                      {email.value.isPreferred && (
                        <Flex alignItems="center">
                          <PositiveIcon />
                          <SmallText
                            ml="xxs"
                            mb="0px"
                            fontWeight={700}
                            color={theme.colors.grey.standard}
                          >
                            {messages.form.generic.preferred}
                          </SmallText>
                        </Flex>
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            </>
          )}
          {Boolean(linkedInProfileUrl?.value || company?.value) &&
            (!showTeaser ? (
              <Button
                mt="xs"
                mode="standard-green"
                onClick={showRocketReachPreview}
                mb="xs"
                ml="-3px"
                size="small"
              >
                {messages.person.teasers.findContactDetailsButton}
              </Button>
            ) : (
              <ContactLookUp
                personId={personId}
                onClose={() => setShowTeaser(false)}
              />
            ))}
        </>
      ) : (
        <BodyText m="0px" color={colors.grey.standard}>
          {messages.person.personTabSection.noDataTexts.noContacts}
        </BodyText>
      )}
    </Div>
  )
}

export default PersonContactsView
