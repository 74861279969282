import moment from "moment"

const calcDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => moment().add(offset, unit)

export const getLTEDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => {
  const date = calcDate(offset, unit)
  date.hours(23).minutes(59).seconds(59)

  return date
}

export const getGTEDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => {
  const date = calcDate(offset, unit)
  date.hours(0).minutes(0).seconds(0)

  return date
}
