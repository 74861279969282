import React, { useState, useCallback } from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import {
  createAssignmentInitialValues,
  createAssignmentInitialValues as defaultInitialValues,
  CreateAssignmentValues
} from "views/assignments/components/assignment-create-form/constants/assignment-details-form.definitions"
import { createAssignmentValidationSchema } from "views/assignments/components/assignment-create-form/constants/assignment-details-form.schema"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { OnAssignmentDetailsSubmit } from "views/assignments/components/assignment-create-form/assignment-details-form.actions"
import { Div, Flex, setItem } from "@ikiru/talentis-fpc"
import { SectionBody } from "@ikiru/talentis-fpc"
import { SectionHeader } from "@ikiru/talentis-fpc"
import { H1 } from "@ikiru/talentis-fpc"
import LocationSearch from "../location-select/LocationSearch"
import { beforeLocationSubmit } from "../location-select/utils"
import { Location } from "../../assignment.types"
import CompanySearch from "views/assignments/components/assignment-company/CompanySearch"
import { CompanySelected } from "../assignment-company/types"
import FormikSelect from "../../../../components/functional/formik/formik-select/FormikSelect"
import {
  AssignmentDataField,
  assignmentStatuses,
  e2eTargets
} from "../assignment-data/constants/definitions"
import { localStorageKeys } from "../../../../setup/storage/storage.definitions"

type AssignmentDetailsFormProps = {
  initialValues?: Partial<CreateAssignmentValues>
  onSubmit: OnAssignmentDetailsSubmit
  formAssignmentStatusComponent?: (
    formikProps: FormikProps<CreateAssignmentValues>
  ) => JSX.Element
  formActionsComponent: (
    formikProps: FormikProps<CreateAssignmentValues>
  ) => JSX.Element
}

export const AssignmentDetailsForm = (props: AssignmentDetailsFormProps) => {
  const [locationValue, setlocationValue] = useState<Location>({
    label: createAssignmentInitialValues.location,
    value: createAssignmentInitialValues.geoLocation
  })

  const [companyValue, setCompanyValue] = useState<CompanySelected>({
    id: "",
    name: ""
  })

  const {
    initialValues,
    onSubmit,
    formAssignmentStatusComponent,
    formActionsComponent
  } = props

  const submit = useCallback(
    (
      value: CreateAssignmentValues,
      action: FormikHelpers<CreateAssignmentValues>
    ) => {
      setItem(localStorageKeys.assignmentStatusInput, value.status)
      if (companyValue.id) {
        value.companyId = companyValue.id
      } else {
        value.companyId = undefined
      }
      value.companyName = companyValue.name
      beforeLocationSubmit(value, action, onSubmit, locationValue)
    },
    [companyValue, locationValue, onSubmit]
  )

  return (
    <Formik
      initialValues={{
        ...defaultInitialValues,
        ...initialValues
      }}
      validationSchema={createAssignmentValidationSchema}
      onSubmit={submit}
    >
      {(formik) => {
        return (
          <Form>
            <Div>
              <Div mb="m">
                <Flex my="xxs" justifyContent="flex-start" alignItems="center">
                  <H1 m={0} color="green.standard" flexGrow={1}>
                    {messages.assignment.addAnAssignment}
                  </H1>
                  {formAssignmentStatusComponent?.(formik)}
                  {formActionsComponent(formik)}
                </Flex>
              </Div>
              <Div mb="m">
                <SectionHeader
                  size="xSmall"
                  title={messages.assignment.roleDetails}
                />
                <SectionBody>
                  <FormikSelect
                    layout={{ width: "100%" }}
                    data-e2e-target-name={e2eTargets.status}
                    options={assignmentStatuses}
                    id={AssignmentDataField.Status}
                    name={AssignmentDataField.Status}
                    label={messages.form.generic.assignmentStatus}
                  />
                  <FormikInput
                    layout={{ width: "100%" }}
                    id={AssignmentDataField.JobTitle}
                    name={AssignmentDataField.JobTitle}
                    label={messages.form.generic.jobTitle}
                  />
                  <LocationSearch
                    id={AssignmentDataField.Location}
                    name={AssignmentDataField.Location}
                    label={messages.form.generic.location}
                    selectedLocation={setlocationValue}
                  />
                </SectionBody>
              </Div>
              <Div>
                <SectionHeader
                  size="xSmall"
                  title={messages.assignment.clientDetails}
                />
                <SectionBody>
                  <Flex width="100%" flexWrap="wrap">
                    <Div width="100%">
                      <CompanySearch
                        id={AssignmentDataField.CompanyName}
                        name={AssignmentDataField.CompanyName}
                        label={messages.form.generic.companyName}
                        selectedCompany={setCompanyValue}
                        isEdit
                      />
                    </Div>
                  </Flex>
                </SectionBody>
              </Div>
            </Div>
          </Form>
        )
      }}
    </Formik>
  )
}
