import React, { useCallback } from "react"
import { H6 } from "@ikiru/talentis-fpc"
import { NoteModal } from "./NoteModal/index"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { PersonNote } from "views/persons/components/person-notes/types"
import NoteListItem from "./NoteListItem"
import { AddNoteButtonWrapper, NoteContainer } from "./styles"
import { Nullable } from "tsdef"
import { NoteAddValues } from "./types"

type PersonRecordNotesPropTypes = {
  note?: any
  personName?: Nullable<string> | undefined
  name: string
  onEdit?: NoteAddValues
  onCreate?: NoteAddValues
  typeTitle: string
  showAddIcon?: boolean
  showMostRecent?: boolean
  outputOnlyLabel?: boolean
  buttonMarginTop?: string
  label?: string
  openModal?: () => {}
}

export const PersonRecordNotes = ({
  note,
  personName,
  name,
  onEdit,
  onCreate,
  typeTitle,
  showAddIcon = true,
  showMostRecent = true,
  outputOnlyLabel = false,
  label = "",
  buttonMarginTop = "5px",
  openModal
}: PersonRecordNotesPropTypes) => {
  const { open } = useModal()

  const openNoteModalCallback = useCallback(
    (note?: PersonNote) => {
      const title = `${
        note
          ? messages.clientPortal.notes.editNote
          : messages.clientPortal.notes.addNote
      } ${
        Boolean(personName)
          ? `${messages.clientPortal.notes.for} ${personName}`
          : ""
      }`
      open(
        ModalNames.PersonRecordNoteModal,
        <NoteModal
          title={title}
          onSubmit={note ? onEdit(note.id) : onCreate}
          name={name}
          initialValues={note}
          typeTitle={typeTitle}
          documents={note?.documents}
          noteId={note?.id}
        />
      )
    },
    [open, name, onCreate, onEdit, personName, typeTitle]
  )
  const openNoteModal = openModal || openNoteModalCallback

  return (
    <NoteContainer noPadding={true}>
      {note ? (
        <>
          {showMostRecent && (
            <H6 mt="5px">{messages.person.note.mostRecentNote}</H6>
          )}
          <NoteListItem
            note={note}
            onEdit={() => openNoteModal(note)}
            {...(showAddIcon ? { onCreate: () => openNoteModal() } : {})}
          />
        </>
      ) : outputOnlyLabel ? (
        <span onClick={() => openNoteModal()}>{label}</span>
      ) : (
        <AddNoteButtonWrapper
          size="extra-small"
          mode="standard-green"
          mt={buttonMarginTop}
          onClick={() => openNoteModal()}
        >
          + {messages.person.note.newNote}
        </AddNoteButtonWrapper>
      )}
    </NoteContainer>
  )
}
