import React from "react"
import { Formik, Form } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { formState, initialFormState } from "utils/forms/form-state"
import {
  InviteUserField,
  inviteUserInitialValues,
  TestIds
} from "views/team/invite-user/constants/invite-user.definitions"
import { inviteUserValidationSchema } from "views/team/invite-user/constants/invite-user.schema"
import { onInviteUserSubmit } from "views/team/invite-user/invite-user.actions"
import { Button } from "@ikiru/talentis-fpc"
import { H3 } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { LicensesWarningModal } from "./components/licenses-warning-modal"

export const InviteUser = () => {
  const { open } = useModal()
  const callback = () => {
    open(ModalNames.Licenses, <LicensesWarningModal />)
  }
  return (
    <>
      <Formik
        initialValues={inviteUserInitialValues}
        validationSchema={inviteUserValidationSchema}
        onSubmit={onInviteUserSubmit(callback)}
        initialStatus={initialFormState}
      >
        {(formik) => {
          const { status, isSubmitting, resetForm } = formik
          return !formState.isFinished(status) ? (
            <Form>
              <FormikInput
                id={InviteUserField.Email}
                name={InviteUserField.Email}
                label={messages.form.generic.emailAddress}
              />
              <br />
              <Button type="submit" disabled={isSubmitting}>
                {messages.manageTeam.inviteUserCta}
              </Button>
            </Form>
          ) : (
            <>
              <H3>{messages.manageTeam.success.title}</H3>
              <div data-testid={TestIds.SuccessMsg}>
                {messages.manageTeam.success.message}{" "}
                <strong>{status.userEmailAddress}</strong>
              </div>

              <button onClick={() => resetForm()}>
                {messages.manageTeam.inviteAnother}
              </button>
            </>
          )
        }}
      </Formik>
    </>
  )
}
