import { useCallback, useEffect, useMemo, useState } from "react"
import { AxiosInstance } from "axios"
import { UserManager, User, UserManagerSettings } from "oidc-client-ts"
import {
  PossibleAuthActionSets,
  AuthMethod,
  UserRoles,
  UserRolePermissionLevels
} from "setup/auth/module/constants/auth.types"
import { useAuth } from "setup/auth/module/auth.context"
import { AuthActions } from "setup/auth/module/constants/auth.definitions"
import {
  AuthReducerActionTypes,
  AuthReducerActions
} from "setup/auth/module/constants/auth.reducer"
import { Authorize } from "setup/auth/module/api/response.authorize"
import { setBearerToken } from "setup/auth/module/api/set-headers"
import { setIsLoggedInToStorage } from "./auth.utils"
import { apiRequest } from "setup/api/api"
import {
  SearchFirmEndpoints,
  UsersEndpoints
} from "setup/api/endpoints/endpoints"
import { useTelemetry } from "utils/hooks/use-telemetry"

type DispatchType = React.Dispatch<AuthReducerActions<unknown>>

export const useOidcSignInOut = (details: {
  manager?: UserManager
  actions: PossibleAuthActionSets
  setUser: (user?: User | undefined) => void
  method: AuthMethod
}) => {
  const { manager, actions, setUser, method } = details
  const { getTalentisUser } = useTalentisUser()

  const getUser = useCallback(async () => {
    if (!manager) return

    const retrievedUser = await manager?.getUser()

    if (!retrievedUser) return undefined

    let talentisUser

    if (document.location.pathname !== "/not-permission") {
      talentisUser = (await getTalentisUser(retrievedUser?.access_token)) || {}
    }

    const user = { ...retrievedUser, ...talentisUser }

    return user
  }, [getTalentisUser, manager])

  useEffect(() => {
    if (manager) {
      manager.clearStaleState()

      if (method === AuthMethod.Popup) {
        manager[actions.signInCallback]?.()
        manager[actions.signOutCallback]?.()
      }

      manager.signinSilentCallback()
      manager.events.addSilentRenewError(() => {
        setUser()
        method === AuthMethod.Popup && setIsLoggedInToStorage(false)
        manager?.removeUser()
      })

      getUser().then((user) => {
        setUser(user)
        method === AuthMethod.Popup && setIsLoggedInToStorage(true)
      })
    }
  }, [manager, actions, method, setUser, getUser])

  const login = useCallback(async () => {
    await manager?.[actions?.signIn]()
  }, [manager, actions])

  const logout = useCallback(async () => {
    await manager?.[actions?.signOut]()
  }, [manager, actions])

  return [login, logout]
}

export const useActions = (method: AuthMethod) =>
  useMemo(() => AuthActions.get(method) as PossibleAuthActionSets, [method])

export const useSetUser = (dispatch: DispatchType) =>
  useCallback(
    (user?: User) =>
      dispatch({ type: AuthReducerActionTypes.UpdateUser, payload: user }),
    [dispatch]
  )

export const useApiInterceptors = (details: {
  manager?: UserManager
  api: AxiosInstance
  user?: User
  logout: (callback?: (() => any) | undefined) => any
}) => {
  const [authHeaderAdded, setAuthHeaderAdded] = useState(false)

  const { user, api, manager, logout } = details
  useEffect(() => {
    user && setBearerToken(api, user.access_token)
    user && setAuthHeaderAdded(true)
  }, [api, user])

  useEffect(() => {
    if (manager) {
      const instance = new Authorize(api, manager, logout)
      const { resolve, reject } = instance
      const boundResolve = resolve.bind(instance)
      const boundReject = reject.bind(instance)

      api.interceptors.response.use(boundResolve, boundReject)
    }
  }, [manager, api, logout])

  return authHeaderAdded
}

export const useCreateManager = (details: {
  dispatch: DispatchType
  config: UserManagerSettings
}) => {
  const { dispatch, config } = details

  useEffect(() => {
    const manager = new UserManager(config)
    dispatch({ type: AuthReducerActionTypes.UpdateManager, payload: manager })
  }, [config, dispatch])
}

export const useTalentisUser = () => {
  const { user, logout } = useAuth()
  const { trackSubscriptionExpired } = useTelemetry()

  const userId = useMemo(() => user?.profile?.UserId, [user])

  //Identify user in UserGuiding
  useEffect(() => {
    if (userId) {
      window?.userGuiding?.identify?.(userId as string)
    }
  }, [userId])

  const getTalentisUser = useCallback(
    async (token?: string) => {
      let additionalConfig = {}

      if (token) {
        additionalConfig = {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      }

      const [, response] = await apiRequest.get({
        endpoint: UsersEndpoints.Me,
        config: additionalConfig
      })

      if (response?.data?.isSubscriptionExpired) {
        trackSubscriptionExpired(response?.data)
      }
      if (response?.data && !response?.data?.userRole && logout) {
        logout()
      }

      return response?.data
    },
    [trackSubscriptionExpired, logout]
  )

  const userHasPermission = useCallback(
    (role: UserRoles) => {
      if (!user || !user.userRole) {
        return false
      }

      return (
        UserRolePermissionLevels[user?.userRole] >=
        UserRolePermissionLevels[role]
      )
    },
    [user]
  )

  const updateInitialLoginFLag = useCallback(async () => {
    const [, response] = await apiRequest.put({
      endpoint: SearchFirmEndpoints.PassInitialLogin,
      data: {},
      endpointParams: { searchFirmId: user?.searchFirmId },
      config: {
        headers: {
          Authorization: "Bearer " + user?.access_token
        }
      }
    })

    if (response?.data?.isSubscriptionExpired) {
      trackSubscriptionExpired(response?.data)
    }
    if (response?.data && !response?.data?.userRole && logout) {
      logout()
    }

    return response?.data
  }, [user, logout, trackSubscriptionExpired])

  return { getTalentisUser, userHasPermission, updateInitialLoginFLag }
}
