import React, { useCallback, useMemo } from "react"
import { Button, colors, Div, Flex, PencilIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import moment from "moment"
import { Experience, ExperienceFormValues } from "views/persons/person.types"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { PersonExperienceDeleteButton } from "./PersonExperienceDeleteButton"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { EditPersonExperience } from "../../professional-experience-modal/EditPersonExperience"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { usePerson } from "../../../person-module/candiate-module.context"
import { sortExperience } from "../utils"
import { ExperienceResponse } from "../../../person-module/person-module.types"
import { PersonExperienceDetails } from "./PersonExperienceDetails"
import { getCompensation } from "./helper"

type PersonExperienceViewType = {
  editMode?: boolean
}

export const PersonExperienceView = ({
  editMode
}: PersonExperienceViewType) => {
  const { open, close } = useModal()
  const {
    personId,
    profileDetails,
    setProfileDetails,
    deleteExperience,
    editExperience,
    setPersonOverlayUpdated
  } = usePerson()

  const experiences = useMemo(
    () => sortExperience(profileDetails.experience.value, undefined, true),
    [profileDetails]
  )

  const updatePersonInContext = useCallback(
    (response: ExperienceResponse) => {
      setProfileDetails({
        ...profileDetails,
        companyId: {
          isReadOnly: profileDetails.companyId.isReadOnly,
          value: response?.[1]?.data?.companyId || ""
        },
        company: {
          isReadOnly: profileDetails.company.isReadOnly,
          value: response?.[1]?.data?.company || ""
        },
        jobTitle: {
          isReadOnly: profileDetails.jobTitle.isReadOnly,
          value: response?.[1]?.data?.jobTitle || ""
        },
        experience: {
          isReadOnly: profileDetails.experience.isReadOnly,
          value: response?.[1]?.data?.experience || []
        }
      })
    },
    [profileDetails, setProfileDetails]
  )

  const editPersonExperienceModal = useCallback(
    (experience: Experience) => {
      const onSubmit = async (values: any) => {
        const { compensationChanged, newCompensation } = getCompensation(
          experience,
          values
        )

        const payload = {
          title: values.title,
          description: values.description,
          startDate: values.startDate,
          endDate: values.endDate,
          company: values.company,
          companyId: values.companyId
        }

        if (compensationChanged) {
          //@ts-ignore
          payload.compensation = newCompensation
        }

        Object.keys(payload).forEach(
          // @ts-ignore
          (payloadKey: keyof ExperienceFormValues) => {
            if (payloadKey === "startDate") {
              if (
                moment(payload[payloadKey]).format("DD/MM/YYYY") ===
                moment(experience[payloadKey]).format("DD/MM/YYYY")
              ) {
                delete payload[payloadKey]
              }
              return
            }
            if (payload[payloadKey] === experience[payloadKey]) {
              delete payload[payloadKey]
            }
          }
        )
        const exp = { ...experience, ...values }
        const response = await editExperience(personId, exp.id, payload)
        setPersonOverlayUpdated(true)
        updatePersonInContext(response)
      }

      open(
        ModalNames.EditPersonExperience,
        <EditPersonExperience
          initialValues={experience}
          onSubmit={onSubmit}
          isCompanyEditable
        />
      )
    },
    [
      open,
      editExperience,
      personId,
      setPersonOverlayUpdated,
      updatePersonInContext
    ]
  )

  return (
    <Flex flexDirection="column" width="100%" flexWrap="wrap">
      {Boolean(profileDetails?.jobTitle || profileDetails?.company) && (
        <Div width="100%">
          {experiences.map((exp, index, array) => {
            const expStartDate = exp.startDate
              ? moment(exp.startDate).format("MMM YYYY")
              : null
            const expEndDate = exp.endDate
              ? moment(exp.endDate).format("MMM YYYY")
              : exp.startDate
              ? messages.talentGraphSearch.present
              : null

            const jobHeader = (
              <CompanyLink
                isDots={true}
                companyName={exp.company}
                companyId={personId ? exp.companyId : ""}
                fontSize={[15, 15, 16]}
              />
            )

            return (
              <Flex
                key={exp.id}
                borderBottom={
                  array.length - 1 === index
                    ? undefined
                    : `1px solid ${colors.grey.light}`
                }
                mt="xs"
              >
                <PersonExperienceDetails
                  jobHeader={jobHeader}
                  expStartDate={expStartDate}
                  expEndDate={expEndDate}
                  experience={exp}
                  title={exp.title}
                />

                {editMode && (
                  <Flex justifyContent="flex-end" flexGrow="1" ml="xs">
                    <Button
                      mode="standard-white"
                      size="action-medium"
                      onClick={() => {
                        // @ts-ignore
                        editPersonExperienceModal(exp)
                      }}
                    >
                      <PencilIcon />
                    </Button>
                    <PersonExperienceDeleteButton
                      editCallback={() => {
                        open(
                          ModalNames.DeletePersonExperience,
                          <ConfirmationModal
                            title={messages.person.experience.areyousuredelete}
                            confirmButtonLabel={messages.generic.delete}
                            cancelButtonLabel={messages.generic.cancel}
                            onCancel={() =>
                              close(ModalNames.DeletePersonExperience)
                            }
                            onClose={() =>
                              close(ModalNames.DeletePersonExperience)
                            }
                            onConfirm={async () => {
                              const response = await deleteExperience(
                                personId,
                                exp.id
                              )
                              setProfileDetails({
                                ...profileDetails,
                                companyId: {
                                  isReadOnly:
                                    profileDetails.companyId.isReadOnly,
                                  value: response?.[1]?.data?.companyId || ""
                                },
                                company: {
                                  isReadOnly: profileDetails.company.isReadOnly,
                                  value: response?.[1]?.data?.company || ""
                                },
                                jobTitle: {
                                  isReadOnly:
                                    profileDetails.jobTitle.isReadOnly,
                                  value: response?.[1]?.data?.jobTitle || ""
                                },
                                experience: {
                                  isReadOnly:
                                    profileDetails.experience.isReadOnly,
                                  value: profileDetails.experience.value.filter(
                                    (loc) => loc.id !== exp.id
                                  )
                                }
                              })
                              setPersonOverlayUpdated(true)
                              close(ModalNames.DeletePersonExperience)
                            }}
                          />
                        )
                      }}
                    />
                  </Flex>
                )}
              </Flex>
            )
          })}
        </Div>
      )}
    </Flex>
  )
}
