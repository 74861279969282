import React from "react"
import { Form, Formik, FormikHelpers } from "formik"
import { Div, SMTPValid, SmtpValidBadge } from "@ikiru/talentis-fpc"
import { CreatePersonField } from "views/persons/components/person-details/form/constants/definitions"
import { messages } from "setup/messages/messages"
import {
  EmailTypeEnums,
  PhoneTypeEnums,
  emailTypeOptions,
  phoneNumberInitialValue,
  phoneTypeOptions
} from "./constants/definitions"
import {
  CreatePersonValues,
  createPersonInitialValues,
  e2eTargets
} from "../../../constant/definitions"
import { OnPersonDetailsSubmitType } from "views/persons/components/person-details/form/actions"
import { getChangedValuesWithArray } from "views/assignments/utils"
import { ModalFormContainer } from "../../person-details-section/form/style"
import { EditableContactDetailsSectionButton } from "../components/EditableContactDetailsSectionButton"
import FormActionButtons from "../../FormActionButtons"
import NestedInputs from "views/persons/components/person-details/form/NestedInputs"
import { DefaultEditedField } from "components/EditableDetails/editable-details.types"
import { personContactsValidationSchema } from "./constants/schema"

export type ButtonRefKey =
  | "addPhoneNumberButtonRef"
  | "addEmailAddressButtonRef"

type PersonContactEditProps = {
  initialValues: Partial<CreatePersonValues>
  onSubmit: OnPersonDetailsSubmitType
  defaultEditedField?: DefaultEditedField
  buttonRefs: {
    [key in ButtonRefKey]: React.MutableRefObject<HTMLButtonElement>
  }
}

const PersonContactEdit = ({
  initialValues,
  onSubmit,
  buttonRefs,
  defaultEditedField
}: PersonContactEditProps) => {
  const submitForm = (
    values: Partial<CreatePersonValues>,
    action: FormikHelpers<CreatePersonValues>
  ) => {
    const trimmedValues = {
      ...(values.phones &&
        values.phones.length > 0 && {
          phones: values.phones.filter((phone) =>
            Boolean(phone.value.phoneNumber)
          )
        }),
      ...(values.taggedEmails &&
        values.taggedEmails.length > 0 && {
          taggedEmails: values.taggedEmails.filter((email) =>
            Boolean(email.value.email)
          )
        })
    }
    const changeValues = initialValues
      ? getChangedValuesWithArray(trimmedValues, initialValues)
      : trimmedValues

    onSubmit(changeValues, action)
  }

  return (
    <Formik
      initialValues={{ ...createPersonInitialValues, ...initialValues }}
      onSubmit={submitForm}
      validationSchema={personContactsValidationSchema}
      validateOnChange
    >
      {(formik) => {
        const { values, setFieldValue } = formik

        return (
          <Div>
            <ModalFormContainer defaultWidth={755}>
              <Form>
                <FormActionButtons
                  title={
                    messages.person.personTabSection.form.contactInfoEditMode
                  }
                />
                {defaultEditedField !== CreatePersonField.EmailAddresses && (
                  <NestedInputs
                    label={messages.form.generic.phone}
                    e2eTargets={e2eTargets.phoneNumbers}
                    inputName={CreatePersonField.PhoneNumbers}
                    selectProps={{
                      nestedKey: "value.phoneUsageType",
                      ComponentProp: (name, index) => ({
                        value:
                          values.phones[index].value.phoneUsageType ||
                          PhoneTypeEnums.Personal,
                        options: phoneTypeOptions
                      })
                    }}
                    inputProps={{
                      nestedKey: "value.phoneNumber",
                      ComponentProp: (name, index) => ({
                        type: "tel",
                        disabled:
                          values?.[name as CreatePersonField.PhoneNumbers]?.[
                            index
                          ]?.isReadOnly
                      })
                    }}
                    toggleProps={{
                      nestedKey: "value.isPreferred",
                      ComponentProp: (name, index) => ({
                        type: "checkbox",
                        value: Boolean(
                          values.phones[index]?.value?.isPreferred
                        ),
                        checked: Boolean(
                          values.phones[index]?.value?.isPreferred
                        ),
                        hideUnderline: true
                      })
                    }}
                    defaultEditedField={defaultEditedField}
                    inputInitialValue={phoneNumberInitialValue}
                  />
                )}
                {defaultEditedField !== CreatePersonField.PhoneNumbers && (
                  <NestedInputs
                    label={messages.form.generic.emailAddresses}
                    e2eTargets={e2eTargets.taggedEmails}
                    inputName={CreatePersonField.EmailAddresses}
                    noPrefferedHeader={
                      Boolean(values.phones.length) &&
                      !Boolean(defaultEditedField)
                    }
                    selectProps={{
                      nestedKey: "value.type",
                      ComponentProp: (name, index) => ({
                        value:
                          values.taggedEmails[index].value.type ||
                          EmailTypeEnums.Personal,
                        options: emailTypeOptions
                      })
                    }}
                    inputProps={{
                      nestedKey: "value.email",
                      onChange: (_, name, index) => {
                        setFieldValue(
                          `${name}[${index}].value.smtpValid`,
                          SMTPValid.MANUAL
                        )
                      },
                      ComponentProp: (name, index) => ({
                        type: "email",
                        disabled:
                          values?.[name as CreatePersonField.EmailAddresses]?.[
                            index
                          ]?.isReadOnly,

                        sufix: () => (
                          <SmtpValidBadge
                            smtpValid={
                              values?.[
                                name as CreatePersonField.EmailAddresses
                              ]?.[index]?.value?.smtpValid
                            }
                          />
                        )
                      })
                    }}
                    toggleProps={{
                      nestedKey: "value.isPreferred",
                      ComponentProp: (name, index) => ({
                        type: "checkbox",
                        value: Boolean(
                          values.taggedEmails[index].value.isPreferred
                        ),
                        checked: Boolean(
                          values.taggedEmails[index].value.isPreferred
                        ),
                        hideUnderline: true
                      })
                    }}
                    defaultEditedField={defaultEditedField}
                    inputInitialValue={{
                      value: { email: "", smtpValid: "" },
                      isReadOnly: false
                    }}
                  />
                )}
              </Form>
            </ModalFormContainer>
            <EditableContactDetailsSectionButton
              formikProps={formik}
              innerRefs={buttonRefs}
              defaultEditedField={defaultEditedField}
            />
          </Div>
        )
      }}
    </Formik>
  )
}

export default PersonContactEdit
