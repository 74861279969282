import React, { useEffect, useState } from "react"
import {
  Link,
  EarthIcon,
  PolygonIcon,
  Flex,
  H6,
  Div,
  //PlusIcon,
  FacebookIcon as Facebook,
  YouTubeTempIcon as YouTube,
  LinkedInIcon as LinkedIn,
  CrunchbaseIcon as Crunchbase,
  XingIcon as Xing,
  ReutersIcon as Reuters,
  BloombergIcon as Bloomberg,
  ZoomInfoIcon as ZoomInfo,
  TwitterIcon as Twitter,
  OwlerIcon as Owler,
  CompaniesHouseIcon as CompaniesHouse
} from "@ikiru/talentis-fpc"
import { transformLinkText } from "utils/links"
import { companyDetailsE2ETargets as e2eTargets } from "../definitions"
//import { messages } from "setup/messages/messages"
import { LinkIcon, WebSitesList, StyledPopupTrigger } from "./style"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { EditableDetailsPayload } from "components/EditableDetails/editable-details.types"
import { WebSiteType } from "../types"

export type WebSite = {
  linkTo: WebSiteType
  url: string
  id: string
}

type WebSitesRecordType = {
  webSites: WebSite[]
  addWebsiteButtonRef?: any
  editableControls: EditableDetailsPayload
}

type Option = {
  label: string | JSX.Element
  onClick?: () => void
}

const mapIcons = {
  linkedIn: <LinkedIn />,
  xingProfile: <Xing />,
  crunchBaseProfile: <Crunchbase />,
  reutersProfile: <Reuters />,
  bloomberg: <Bloomberg />,
  facebook: <Facebook />,
  zoomInfo: <ZoomInfo />,
  twitter: <Twitter />,
  owler: <Owler />,
  ch: <CompaniesHouse />,
  youTube: <YouTube />
}

export const WebSitesRecord = ({ webSites }: WebSitesRecordType) => {
  const [notCompanyWebSites, setNotCompanyWebSites] = useState<WebSite[]>([])
  const [companyWebSites, setCompanyWebSites] = useState<WebSite[]>([])
  //const { setEditMode } = editableControls

  const websiteOptions: Option[] = [
    ...companyWebSites.map((website) => ({
      label: (
        <Link
          href={website.url}
          standalone
          m="0"
          size="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          {transformLinkText(website.url)}
        </Link>
      )
    }))
    // {
    //   label: (
    //     <Link href="#" standalone m="0" size="small">
    //       <PlusIcon height="12px" />
    //       {messages.form.generic.webSites}
    //     </Link>
    //   ),
    //   onClick: async () => {
    //     await setEditMode()
    //     addWebsiteButtonRef?.current?.click()
    //   }
    // }
  ]

  useEffect(() => {
    let tempNotCompanyWebSite: WebSite[] = []
    let tempCompanyWebSite: WebSite[] = []

    webSites.forEach((item: WebSite) => {
      const icon = item.linkTo !== "other" && mapIcons[item.linkTo]
      const iconExists = Boolean(icon)
      iconExists
        ? tempNotCompanyWebSite.push(item)
        : tempCompanyWebSite.push(item)
    })
    setNotCompanyWebSites(tempNotCompanyWebSite)
    setCompanyWebSites(tempCompanyWebSite)
  }, [webSites])

  return (
    <WebSitesList as="ul" data-e2e-target-name={e2eTargets.webSites}>
      {notCompanyWebSites.map((item, index) => {
        const icon = item.linkTo !== "other" && mapIcons[item.linkTo]
        return (
          <Div
            as="li"
            key={`${item?.url}-${index}`}
            data-e2e-target-name={`${e2eTargets.webSites}-${index}`}
            mr="xs"
            mt="0"
            width="28px"
            height="28px"
          >
            <LinkIcon
              href={item?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {icon}
            </LinkIcon>
          </Div>
        )
      })}

      {!!companyWebSites.length && (
        <Flex alignItems="center" ml="xxs">
          <EarthIcon width="18px" height="18px" viewBox="0 0 16 16" />
          <Link
            m="none"
            mx="xxs"
            href={companyWebSites[0].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {transformLinkText(companyWebSites[0].url)}
          </Link>
          {companyWebSites.length > 0 && (
            <OptionsPopup
              alignSelf="center"
              display="flex"
              config={{
                PopupProps: {
                  nested: true,
                  position: "bottom right"
                }
              }}
              trigger={
                <StyledPopupTrigger>
                  {companyWebSites.length > 1 && (
                    <H6 m="none" mr="2px">
                      {`+${companyWebSites.length - 1}`}
                    </H6>
                  )}
                  <PolygonIcon />
                </StyledPopupTrigger>
              }
              options={websiteOptions}
            ></OptionsPopup>
          )}
        </Flex>
      )}
    </WebSitesList>
  )
}
