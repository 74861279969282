import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import {
  PersonAvatarField,
  PersonAvatarValues
} from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { commonFormStateKey, FormStates } from "utils/forms/form-state"
import { callWithErrorMessages } from "utils/forms/forms"
import { GetFormAction, FormAction } from "utils/forms/form.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnUploadPersonPhotoAvatarSubmitType = FormAction<PersonAvatarValues>
type GetOnUploadPersonPhotoAvatarSubmitType = GetFormAction<PersonAvatarValues>

export const getOnUploadPersonPhotoAvatarSubmit: GetOnUploadPersonPhotoAvatarSubmitType =
  (personId, onSuccessfulUpload, beforeSubmit) => async (values, helpers) => {
    const { setSubmitting, setStatus, setFieldError } = helpers
    personId = await beforeSubmit(personId)
    const file = values[PersonAvatarField.File]

    const formData = new FormData()

    file && formData.append("file", file)

    setSubmitting(true)

    setStatus({
      [commonFormStateKey]: FormStates.Pending
    })

    const [error] = await apiRequest.put({
      endpoint: PersonsEndpoints.Photo,
      data: formData,
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    callWithErrorMessages(setFieldError, error)

    setStatus({
      [commonFormStateKey]: !error ? FormStates.Finished : FormStates.Static
    })

    if (!error) {
      onSuccessfulUpload!(personId)
    }
  }

export type OnAvatarDeleteType = () => void
type GetOnAvatarDeleteType = GetFormAction<OnAvatarDeleteType>

export const getOnAvatarDelete: GetOnAvatarDeleteType =
  (personId, onSuccessfulUpload, beforeSubmit) => async () => {
    personId = await beforeSubmit(personId)
    const [error] = await apiRequest.delete({
      endpoint: PersonsEndpoints.Photo,
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    !error && onSuccessfulUpload!()
  }
