import React from "react"
import pick from "lodash/pick"
import groupBy from "lodash/groupBy"
import {
  ChargebeePlan,
  Currency,
  PeriodUnit,
  PricingEntity,
  SubscriptionPlan,
  SubscriptionPlanFeature,
  SubscriptionType
} from "./subscription-purchase-module.types"
import { messages } from "setup/messages/messages"
import { centsToDollars } from "utils/format-price"

type PlanOptions = {
  tokens?: number
}

export const generateSubscriptionPlanFeatures = (
  { tokens = 0 }: PlanOptions = { tokens: 0 }
): Partial<Record<SubscriptionType, SubscriptionPlanFeature[]>> => ({
  [SubscriptionType.Talentis]: [
    {
      description: messages.subscription.features.unlimitedAccess
    },
    {
      description:
        messages.subscription.features.contactCreditsAvailableForPurchase
    }
  ],
  [SubscriptionType.TalentisConnect]: [
    {
      description: messages.subscription.features.everythingInTheTalentisPlan
    },
    {
      description: (
        <>
          <strong>
            {messages.subscription.features.contactCreditsIncluded.format(
              tokens
            )}
          </strong>{" "}
          {messages.subscription.features.per}{" "}
          <strong>{messages.subscription.features.user}</strong>{" "}
          {messages.subscription.features.perMonth}
        </>
      )
    },
    {
      description:
        messages.subscription.features.significantDiscountOnAdditional
    }
  ]
})

const convertPricingEntityPrices = (pricingEntity: PricingEntity) => ({
  ...pricingEntity,
  price: {
    ...pricingEntity.price,
    total: centsToDollars(pricingEntity.price.total),
    amount: centsToDollars(pricingEntity.price.amount),
    discount: centsToDollars(pricingEntity.price.discount),
    taxAmount: centsToDollars(pricingEntity.price.taxAmount)
  }
})

export const mapChargebeePlans = (
  plans: ChargebeePlan[]
): SubscriptionPlan[] => {
  const groupedPlansByType = groupBy(plans, "planType")

  const availablePlans = Object.entries(groupedPlansByType).map(
    ([type, pricingPlan]) => {
      const monthlyPricing = pricingPlan.find(
        (p) => p.periodUnit === PeriodUnit.MONTH
      )
      const yearlyPricing = pricingPlan.find(
        (p) => p.periodUnit === PeriodUnit.YEAR
      )

      // We might have a daily plan for testing purposes
      const dailyPricing = pricingPlan.find(
        (p) => p.periodUnit === PeriodUnit.DAY
      )

      const monthlyPricingEntity = pick(monthlyPricing, [
        "id",
        "planId",
        "price",
        "defaultTokens"
      ]) as PricingEntity

      const yearlyPricingEntity = pick(yearlyPricing, [
        "id",
        "planId",
        "price",
        "defaultTokens"
      ]) as PricingEntity

      const dailyPricingEntity = pick(dailyPricing, [
        "id",
        "planId",
        "price",
        "defaultTokens"
      ]) as PricingEntity

      return {
        type: type as SubscriptionType,
        currency: pricingPlan?.[0]?.currencyCode as Currency,
        monthly: monthlyPricing
          ? convertPricingEntityPrices(monthlyPricingEntity)
          : dailyPricing
          ? convertPricingEntityPrices(dailyPricingEntity)
          : undefined,
        yearly: yearlyPricing
          ? convertPricingEntityPrices(yearlyPricingEntity)
          : undefined
      }
    }
  )

  return availablePlans
}
