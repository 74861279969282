import { PersonAssignments } from "views/persons/components/person-assignments/form/constants/add-person-assignment.definitions"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { GetFormAction, FormAction } from "utils/forms/form.types"
import { PersonAssignmentsValues } from "./constants/add-person-assignment.definitions"
import { serializeAllErrorMessages } from "utils/forms/forms"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnAddPersonAssignment = FormAction<PersonAssignmentsValues>
type GetOnAddPersonAssignment = GetFormAction<PersonAssignmentsValues>

export const addPersonToAssignment = async (
  assignmentId: string,
  personId: string
) => {
  return await apiRequest.post({
    endpoint: CandidatesEndpoints.Root,
    data: { assignmentId, personId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
}
export const getOnAddPersonAssignment: GetOnAddPersonAssignment =
  (personId, onSuccess, beforeSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers
    const assignmentId = values[PersonAssignments.Assignments]
    personId = await beforeSubmit(personId)
    const [error, response] = await addPersonToAssignment(
      assignmentId,
      personId || ""
    )

    response && onSuccess!(response.data)
    error &&
      setFieldError(
        PersonAssignments.Assignments,
        serializeAllErrorMessages(error)
      )
  }
