import React, { useMemo } from "react"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { sectionFieldSearchValuesTemplateString } from "./consts"
import { messages } from "setup/messages/messages"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { get } from "lodash"
import { Nullable } from "tsdef"

type DateInputFiledProps = {
  index: number
  indexSection: number
}

export const DateInputFiled = ({
  index,
  indexSection
}: DateInputFiledProps) => {
  const { updateFilters, filters } = useInternalSearch()

  const fieldSearchDate = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )

  const currentFieldDateValue = get(filters, fieldSearchDate, [])

  const isTwoDate = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return condition === "between"
  }, [filters, indexSection, index])

  const handleDatesChange = (date: Nullable<Date> | string, type: string) => {
    const updatedValues: string[] = [...currentFieldDateValue]

    if (updatedValues.length < 2) {
      updatedValues[0] = updatedValues[0] || ""
      updatedValues[1] = updatedValues[1] || ""
    }

    if (type === "to") {
      //@ts-ignore
      updatedValues[0] = date
    } else if (type === "from") {
      //@ts-ignore
      updatedValues[1] = date
    }

    updateFilters({
      [fieldSearchDate]: updatedValues
    })
  }

  const handleDateChange = (date: Nullable<Date | string>) => {
    updateFilters({
      [fieldSearchDate]: [date]
    })
  }

  return (
    <>
      {isTwoDate ? (
        <Flex>
          <Div mr="xs">
            <FormikDatepicker
              variant="middle"
              id={`${fieldSearchDate}0`}
              name={`${fieldSearchDate}0`}
              label="from"
              onChangeDate={(date: Nullable<Date | string>) =>
                handleDatesChange(date, "to")
              }
              defaultValue={currentFieldDateValue[0]}
            />
          </Div>
          <Div>
            <FormikDatepicker
              variant="middle"
              id={`${fieldSearchDate}1`}
              name={`${fieldSearchDate}1`}
              label="to"
              onChangeDate={(date: Nullable<Date | string>) =>
                handleDatesChange(date, "from")
              }
              defaultValue={currentFieldDateValue[1]}
            />
          </Div>
        </Flex>
      ) : (
        <Div width="250px">
          <FormikDatepicker
            variant="middle"
            id={fieldSearchDate}
            name={fieldSearchDate}
            label={messages.person.assignments.dueDate}
            onChangeDate={(date: Nullable<Date | string>) =>
              handleDateChange(date)
            }
            defaultValue={currentFieldDateValue[0]}
          />
        </Div>
      )}
    </>
  )
}
