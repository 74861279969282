import styled from "styled-components"
import { Div, fontFamilies } from "@ikiru/talentis-fpc"

export const SearchDropdown = styled(Div)`
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.grey.lightest};
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
`

export const SearchDropdownItem = styled.p`
  color: ${({ theme }) => theme.colors.grey.dark};
  padding: 8px 5px;
  cursor: pointer;
  margin: 0px;
  font-weight: 500;
  font-family: ${fontFamilies.gibson};
  font-size: 20px;
  line-height: 22px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.light};
  }
`
