import React, { useCallback } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import {
  Flex,
  CrossIcon,
  Button,
  colors,
  Div,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  CommentaryField,
  initialDefaultCommentaryState,
  CommentaryForm
} from "components/Notes/components/forms/definitions"
import { addCommentaryValidationSchema } from "views/persons/components/person-notes/form/constants/schema"
import { PersonNoteModalWrapper, WarningIconStyled } from "./style"
import { FormAction } from "utils/forms/form.types"
import { NoteAddValues } from "../types"
import { Candidate } from "views/candidates/candidates.types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"

export type OnNoteSubmitType = FormAction<NoteAddValues>
interface NoteModalProps {
  onSubmit: OnNoteSubmitType
  initialValues?: {
    commentaryDescription: string
    commentaryShowToClient: boolean
  }
  title: string
  typeTitle: string
  candidate: Candidate
}

export const NoteModal = ({
  onSubmit,
  initialValues,
  title,
  candidate
}: NoteModalProps) => {
  const { close } = useModal()
  const onFormSubmit = useCallback(
    (data: CommentaryForm, action: FormikHelpers<NoteAddValues>) => {
      close(ModalNames.PersonRecordCommentaryModal)
      onSubmit(data, action)
    },
    [close, onSubmit]
  )

  const formikContextValue = {
    initialValues: {
      ...initialDefaultCommentaryState,
      ...initialValues
    },
    validationSchema: addCommentaryValidationSchema,
    onSubmit: onFormSubmit,
    isInitialValid: (props: any) => {
      return props.initialValues.commentaryDescription?.length
    }
  }

  return (
    <PersonNoteModalWrapper>
      <ModalSectionHeader
        size="xSmall"
        title={title}
        actions={
          <>
            <Button
              onClick={() => close(ModalNames.PersonRecordCommentaryModal)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </>
        }
      />

      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <Flex p="m" flexDirection="column">
              <FormikTextarea
                rows={10}
                id={CommentaryField.CommentaryDescription}
                name={CommentaryField.CommentaryDescription}
                label={messages.person.commentary.form.commentaryText}
                resize="none"
              />
              {candidate.commentary?.showToClient && (
                <Flex flexDirection="row" mt="m">
                  <WarningIconStyled fill={colors.orange.standard} />
                  <Div pl="xs">
                    {messages.person.commentary.currentlyShared}
                    <br />
                    {messages.person.commentary.pleaseUncheck}
                    <Div mt="s">
                      <FormikToggle
                        small
                        id={CommentaryField.CommentaryShowToClient}
                        name={CommentaryField.CommentaryShowToClient}
                        label={messages.person.commentary.showInClientView}
                        hideUnderline
                        type="checkbox"
                      />
                    </Div>
                  </Div>
                </Flex>
              )}
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="grey.light"
              p="xs"
            >
              <Button type="submit" disabled={!isValid}>
                {messages.person.commentary.saveCommentary}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </PersonNoteModalWrapper>
  )
}
