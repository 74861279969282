import React from "react"
import {
  Div,
  Flex,
  PositiveIcon,
  H4,
  BodyText,
  Link
} from "@ikiru/talentis-fpc"
import { SupportDetailsForm } from "./form"
import { useSupport } from "./hooks"
import { messages } from "setup/messages/messages"

interface ContactInfoProps {
  label: string
  linkText: string
  linkHref: string
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  label,
  linkText,
  linkHref
}) => (
  <Div pl="m" height="15px" mt="xs">
    <BodyText mt="s" mb="none" display="inline-block">
      {label}
    </BodyText>
    &nbsp;
    <Link href={linkHref} style={{ fontSize: "13px" }}>
      {linkText}
    </Link>
  </Div>
)

export const SupportForm: React.FC = () => {
  const { onSubmit, showSentMsg, initialValues } = useSupport()

  return (
    <>
      <Div mx="s" mb={["xxl", "m", "m"]}>
        {showSentMsg ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="453px"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxWidth="270px"
              textAlign="center"
            >
              <PositiveIcon width={49} height={49} />
              <H4 mt="m">{messages.supportForm.sentSuccess}</H4>
            </Flex>
          </Flex>
        ) : (
          <SupportDetailsForm {...{ onSubmit, initialValues }} />
        )}
      </Div>
      <Div mb="xl">
        <BodyText pl="m" my="xxs">
          Or if you wish to email or call us, here is our contact
        </BodyText>
        <ContactInfo
          label={messages.supportForm.appendix.emailUs}
          linkText="support@ikirupeople.com"
          linkHref="mailto:support@ikirupeople.com"
        />
        <ContactInfo
          label={messages.supportForm.appendix.americas}
          linkText="+1 (201) 653 0013"
          linkHref="tel:+12016530013"
        />
        <ContactInfo
          label={messages.supportForm.appendix.ukEmea}
          linkText="+44 (0) 1256 297000"
          linkHref="tel:+4401256297000"
        />
        <ContactInfo
          label={messages.supportForm.appendix.auNz}
          linkText="+61 (0)2 8322 8111"
          linkHref="tel:+6102832281111"
        />
      </Div>
    </>
  )
}
