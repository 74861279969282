import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const NoteSelectionModalWrapper = styled(Div)`
  background: ${({ theme }) => theme.colors.grey.lightest};
  width: 390px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
`
