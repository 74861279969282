// import HelpImage from "assets/instruction.png"
import React, { useCallback, useEffect } from "react"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

type PropTypes = {
  inviteSent: boolean
}

export const InvitationSkippedInfo = ({ inviteSent }: PropTypes) => {
  const { close } = useModal()

  const next = useCallback(async () => {
    close(ModalNames.InviteColleagues)
  }, [close])

  // --- Delete when reactiving invite collegue
  useEffect(() => {
    next()
  }, [next])
  //----------------------------------------------

  return (
    <></>
    // <Div>
    //   {inviteSent && <LargeText textAlign="center" mt="sm" mb="sm" mx="m" color="grey.dark">
    //     {messages.form.generic.youCanAddOrRemove}
    //   </LargeText>}
    //   <Div mb="m" alignItems="center" justifyContent="center" display="flex">
    //     <img src={HelpImage} alt={"help"} />
    //   </Div>
    //   <Flex justifyContent="center" alignItems="center" flexDirection="column" mb="l">
    //     <Button
    //       mode="primary"
    //       type="submit"
    //       onClick={next}
    //       color="white.standard"
    //     >
    //       {messages.form.generic.next}
    //     </Button>
    //   </Flex>
    // </Div>
  )
}
