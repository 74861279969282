import React from "react"
import { Div, H5, BodyText, Flex, colors } from "@ikiru/talentis-fpc"
import { AssignmentPackageData } from "views/assignments/components/assignment-data/constants/definitions"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { AssignmentDataBlock } from "../AssignmentDataBlock"

export const AssignmentSalaryPackage = ({
  currency,
  salaryFrom,
  salaryTo,
  packageComment
}: AssignmentPackageData) => {
  const hasSalaryPackage = salaryFrom || salaryTo || packageComment

  return (
    <Div py="m">
      {!hasSalaryPackage && (
        <BodyText my="0" color={colors.grey.standard}>
          {messages.assignment.assignmentNoPackage}
        </BodyText>
      )}
      <Flex>
        {Boolean(salaryFrom) && (
          <AssignmentDataBlock
            title={messages.assignment.assignmentSalaryFrom}
            value={salaryFrom}
            currency={currency}
          />
        )}
        {Boolean(salaryTo) && (
          <AssignmentDataBlock
            title={messages.assignment.assignmentSalaryTo}
            value={salaryTo}
            currency={currency}
          />
        )}
      </Flex>
      {packageComment && (
        <Div mt="m">
          <H5 m="0">{messages.assignment.assignmentPackageComment}</H5>
          <ShowMore visibleLines={3} description={packageComment} />
        </Div>
      )}
    </Div>
  )
}
