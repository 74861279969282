import moment from "moment"
import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"

export const getStartDate = (startDate: Nullable<Date>) =>
  startDate ? moment(startDate).format("MMM YYYY") : null

export const getEndDate = (
  endDate: Nullable<Date>,
  startDate: Nullable<Date>
) => {
  return endDate
    ? moment(endDate).format("MMM YYYY")
    : startDate
    ? messages.talentGraphSearch.present
    : null
}
