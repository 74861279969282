import React, { useEffect, useState } from "react"
import { BodyText } from "@ikiru/talentis-fpc"
import { useDragLayer } from "react-dnd"
import { ColumnType, ItemDataType } from ".."
import { DraggingStyledDiv, LayerDragItemContainer } from "./style"

type CustomDragLayerProps = {
  itemList: ItemDataType[]
  columns: ColumnType[]
  DragPreviewCustomContainer?: (props: any) => JSX.Element
}
export const CustomDragLayer = ({
  itemList,
  columns,
  DragPreviewCustomContainer
}: CustomDragLayerProps) => {
  const [selectedItem, setSelectedItem] = useState<ItemDataType>()
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }))

  useEffect(() => {
    if (item) {
      const beingDraggedItem = itemList.find(
        (itemData) => itemData.id === item.itemId
      )
      setSelectedItem(beingDraggedItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const getDragItemComponent = () => {
    const DragItem = columns.find(
      (column) => column.id === selectedItem?.columnId
    )?.DragComponent

    if (DragItem) {
      return <DragItem isDragging {...selectedItem?.itemComponentProps} />
    } else {
      if (DragPreviewCustomContainer) {
        return (
          <DragPreviewCustomContainer>
            {item.content}
          </DragPreviewCustomContainer>
        )
      } else {
        return (
          <DraggingStyledDiv>
            <BodyText m="0" p="0">
              {item.content}
            </BodyText>
          </DraggingStyledDiv>
        )
      }
    }
  }

  if (!isDragging || !currentOffset) {
    return null
  }

  return (
    <LayerDragItemContainer currentOffset={currentOffset}>
      {getDragItemComponent()}
    </LayerDragItemContainer>
  )
}
