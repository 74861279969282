import { intl } from "setup/intl"
import { Currency } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

export const formatPrice = (price: number, currency: Currency) =>
  intl.formatNumber(price, {
    currency,
    style: "currency",
    minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
    maximumFractionDigits: 2
  })

export const centsToDollars = (cents: number): number =>
  cents > 0 ? cents / 100 : 0
