import React from "react"
import DropContainer from "components/FPCComponents/DragAndDrop/CustomDnD/DropContentColumn/DropContainer"
import { DragType, DraggableDataName } from "../../constants/definition"
import { DragContentContainer } from "./style"
import { Div, Flex, UndoIcon, BodyText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { get } from "lodash"
import { usePerson } from "views/persons/person-module/candiate-module.context"

interface ExistingDropItemWrapperProps {
  children: JSX.Element
  id: string
  objectName: DraggableDataName
  acceptType: DragType
  isParsed: boolean
  hasBeingChange: boolean
  isValueValid: boolean
  undoDataChange: (id: string, objectName: string) => void
}

const ExistingDropItemWrapper = ({
  children,
  id,
  objectName,
  acceptType,
  isParsed,
  hasBeingChange,
  undoDataChange,
  isValueValid
}: ExistingDropItemWrapperProps) => {
  const { currentHoveredItem: componentHover } = usePerson()

  return (
    <DropContainer
      acceptType={acceptType}
      customColumnDetails={{
        dataId: id,
        objectName: objectName
      }}
    >
      {(isOver) => {
        return (
          <Flex alignItems="baseline">
            <DragContentContainer
              isDropped={
                hasBeingChange ||
                (isParsed && !Boolean(componentHover === objectName))
              }
              isOver={isOver || componentHover === objectName}
            >
              {isValueValid && children}
              {!isValueValid && (
                <BodyText m="none">
                  {messages.person.cvparsing.experience.dropHere.format(
                    get(
                      messages,
                      `person.cvparsing.experience.experienceDetails.${objectName}`
                    ) || ""
                  )}
                </BodyText>
              )}
            </DragContentContainer>
            {hasBeingChange && (
              <Div
                ml="xxs"
                style={{ cursor: "pointer" }}
                onClick={() => undoDataChange(id, objectName)}
              >
                <UndoIcon color="white" fill="white" />
              </Div>
            )}
          </Flex>
        )
      }}
    </DropContainer>
  )
}

export default ExistingDropItemWrapper
