const config = {
  site: window.fpConfig.chargebee.site,
  publishableKey: window.fpConfig.chargebee.publishableKey
}

const initChargebee = () => {
  window.Chargebee.init(config)
}

export default initChargebee
