import styled from "styled-components"
import { Flex, H4, H6, SmallText } from "@ikiru/talentis-fpc"

export const BetaIcon = styled(H6)`
  color: ${({ theme }) => theme.colors.yellow.darkest};
  background-color: ${({ theme }) => theme.colors.yellow.lightest};
  border: 1px solid ${({ theme }) => theme.colors.yellow.darkest};
  margin: 0px;
  margin-left: 7px;
  margin-right: ${({ theme }) => theme.space.m}px;
  font-weight: 600;
  padding: 2px ${({ theme }) => theme.space.xs}px;
  height: 20px;
  border-radius: 4px;
  letter-spacing: 0.3px;
  user-select: none;
`

export const HeaderContainer = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.space.xl}px;
  margin-bottom: ${({ theme }) => theme.space.m}px;
  padding: 0px auto;
`

export const GPTHeader = styled(H4)`
  padding-left: ${({ theme }) => theme.space.m}px;
  margin: 0px;
`

export const CustomSmallText = styled(SmallText)`
  padding: 0px ${({ theme }) => theme.space.xl}px;
  margin: ${({ theme }) => theme.space.xs}px 0px 25px 0px;
`
