import styled from "styled-components"

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100svh;
`
export const AlertsWrapper = styled.div`
  max-height: 30%;
`
export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1;
  }
`
