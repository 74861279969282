export enum PersonAvatarField {
  File = "file"
}

export type PersonAvatarValues = {
  [PersonAvatarField.File]: Blob | ""
}

export type PersonPhoto = {
  url: string
}

export const PersonAvatarInitialValues: PersonAvatarValues = {
  [PersonAvatarField.File]: ""
}

export const personAvatarE2ETargets = {
  input: "person-avatar-dropzone-input",
  uploadButton: "person-avatar-upload-button",
  saveButton: "person-avatar-save-button",
  dropzoneContainer: "person-avatar-dropzone-container",
  containerImage: "person-avatar-container-image",
  deleteButton: "person-avatar-delete-button",
  deleteConfirmationButton: "person-avatar-delete-confirmation-button",
  deleteCancelButton: "person-avatar-delete-cancel-button"
}
