import React, { Suspense, useCallback, useState } from "react"
import { Flex, BodyText, Avatar, Div, Loader } from "@ikiru/talentis-fpc"
import {
  AvatarBox,
  ButtonStyled,
  CompanyRecordWrapper,
  DropDownIconStyled,
  RecordBox,
  RecordFooterBox
} from "./styles"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { CompanyCandidateStage } from "./CompanyCandidateStage"
import { CompanyCandidateStages } from "views/assignments/assignment.types"
import { useAssignmentCompanies } from "../hooks"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { AnimatePresence, motion } from "framer-motion"
import CandidateRecordWrapper from "../../candidates-list/components/candidate-record/CandidateRecordWrapper"
import CandidateComponent from "../../candidates-list/components/interview-stage-candidates/CandidateComponent"
import { StageItem } from "views/assignments/assignment-module/assignment-module.types"
import { OffLimitsType } from "components/OffLimits/type"
import { Candidate } from "views/candidates/candidates.types"
import { messages } from "setup/messages/messages"
import { RouterButton } from "components/RouterLink/RouterLink"
import { RouterUrl } from "setup/router/routes"
//import { Pagination } from "components/functional/pagination/Pagination"

type CompanyRecordProps = {
  id: string
  name: string
  logoUrl: string
  stages: StageItem[]
  offLimits: OffLimitsType
  candidates?: Candidate
}

export const CompanyRecord = React.memo(
  ({
    id,
    name,
    logoUrl,
    stages,
    offLimits,
    candidates
  }: CompanyRecordProps) => {
    //@ts-ignore
    const candidateStages: CompanyCandidateStages = stages.reduce(
      (acc, item) => {
        acc[item.data] = item.count
        return acc
      },
      {}
    )

    const [isExpanded, setIsExpanded] = useState(false)

    const { companiesCandidates, assignmentId, assignmentDetails } =
      useAssignment()

    const { getCompaniesCandidates, isLoadingCandidates } =
      useAssignmentCompanies()

    const showCandidates = useCallback(async () => {
      setIsExpanded((pre) => !pre)

      //@ts-ignore
      const candidatesId = candidates?.map((candidate) => candidate.id)
      !isExpanded && getCompaniesCandidates(id, candidatesId)
    }, [isExpanded, id, candidates, getCompaniesCandidates])

    // const pageCount = companiesCandidates?.[id]?.page?.pageCount || 0

    // const paginationProps = useMemo(() => {
    //   return {
    //     hasNextPage: companiesCandidates?.[id]?.page?.hasNextPage || false,
    //     hasPreviousPage:
    //       companiesCandidates?.[id]?.page?.hasPreviousPage || false,
    //     maxPages: 5,
    //     pageCount: companiesCandidates?.[id]?.page?.pageCount || 0,
    //     pageNumber: companiesCandidates?.[id]?.page?.pageNumber || 1,
    //     pageSize: companiesCandidates?.[id]?.page?.pageSize || 25,
    //     totalItemCount: companiesCandidates?.[id]?.page?.totalItemCount || 0
    //   }
    // }, [companiesCandidates])

    // const handlePageChange = useCallback(
    //   (page: number) => {
    //     getCompaniesCandidates(id, page)
    //   },
    //   [id]

    return (
      <CompanyRecordWrapper>
        <Flex>
          <RecordBox>
            <AvatarBox>
              <Avatar
                borderless
                width={60}
                height={60}
                disableHoverOpacity
                photo={{ url: logoUrl }}
              />
            </AvatarBox>

            <CompanyLink
              companyName={name}
              companyId={id}
              fontSize={[15, 15, 16]}
              my={0}
            />
          </RecordBox>
          <CompanyCandidateStage candidateStages={candidateStages} />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
            my="xs"
            ml="auto"
            pr="xs"
          >
            <RouterButton
              as="a"
              to={`${RouterUrl.ProfileNewContact}?assignmentId=${assignmentId}&company=${name}&jobTitle=${assignmentDetails.jobTitle}&location=${assignmentDetails.location}`}
              size="small"
              mode="standard-grey-light"
            >
              {messages.assignment.details.actions.addCandidate}
            </RouterButton>
            <ButtonStyled
              mode="standard-white"
              size="action-small"
              onClick={() => showCandidates()}
            >
              <DropDownIconStyled up={isExpanded} />
            </ButtonStyled>
          </Flex>
        </Flex>

        {isExpanded && (
          <RecordFooterBox>
            {isLoadingCandidates ? (
              <Div mt="m">
                <Loader />
              </Div>
            ) : companiesCandidates?.[id]?.candidates.length ? (
              <>
                {companiesCandidates[id].candidates.map((candidate) => (
                  <Suspense fallback={<></>}>
                    <AnimatePresence key={candidate.id}>
                      <motion.div
                        key={candidate.id}
                        transition={{ duration: 1 }}
                        initial={{ opacity: 0, width: "100%" }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <Flex width="100%" mb="xs">
                          <CandidateRecordWrapper
                            candidate={candidate}
                            personPhoto={null}
                          >
                            <CandidateComponent />
                          </CandidateRecordWrapper>
                        </Flex>
                      </motion.div>
                    </AnimatePresence>
                  </Suspense>
                ))}
                {/* <Flex width="100%" flexDirection="column">
                      {pageCount > 0 && (
                        <Flex width="100%" justifyContent="center" mb={20}>
                          <Pagination
                            {...paginationProps}
                            onPageChange={handlePageChange}
                            isSmall
                          />
                        </Flex>
                      )}
                    </Flex> */}
              </>
            ) : (
              <BodyText>{messages.companiesSearch.noCompanyCandidate}</BodyText>
            )}
          </RecordFooterBox>
        )}
      </CompanyRecordWrapper>
    )
  }
)
