import { Div, Flex, H5, spacing, theme, SmallText } from "@ikiru/talentis-fpc"
import styled, { css } from "styled-components"

type ColumnContainerColor = {
  columnColor: string
  borderStyle: React.CSSProperties["border"]
  minHeight: string
  maxHeight: string
}

type ExplainMessageContainerProps = {
  isHoveringOver: boolean
  hoverBg: string
}

export const scrollBarCvStyle = css`
  overflow-y: auto;
  padding-right: ${spacing.xs}px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey.light};
    border-radius: 4px;
  }
`

export const TitleWrapper = styled(Flex)`
  min-width: 26.2%;
  border: 0.5px solid ${theme.colors.grey.light};
  border-right: none;
  margin-top: ${theme.space.l}px;
`

export const ColumnContainer = styled(Div)<ColumnContainerColor>`
  border: ${({ borderStyle }) => borderStyle};
  border-top: 2px solid ${({ columnColor }) => columnColor};
  padding-bottom: ${({ theme }) => theme.space.xl}px;
  min-height: 185px;
  overflow-y: auto;
  position: relative;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  @media (min-height: 576px) {
    height: ${({ minHeight }) => minHeight};
  }
  @media (min-height: 768px) {
    height: ${({ maxHeight }) => maxHeight};
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    padding-right: ${theme.space.xs}px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey.light};
  }
`

export const ColumnTitleContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  margin: 0px;
`

export const ColumnTitle = styled(H5)`
  padding: 0px;
  margin: 0px;
`
export const ExplainMessageContainer = styled(Flex)`
  position: -webkit-sticky;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  min-height: 70px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${theme.space.m}px;
  padding-left: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;
  background-color: ${({ theme }) => theme.colors.grey.lightest};
`
export const ExplainMessage = styled(SmallText)<ExplainMessageContainerProps>`
  background-color: ${({ isHoveringOver, hoverBg, theme }) =>
    isHoveringOver ? hoverBg : theme.colors.grey.lightest};
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${theme.space.xs}px 45px;
  width: 100%;
`
