import React, { useCallback } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import {
  Flex,
  CrossIcon,
  Button,
  colors,
  Div,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  BiographyField,
  BiographyForm,
  initialDefaultBiographyState
} from "components/Notes/components/forms/definitions"
import { addBiographyValidationSchema } from "views/persons/components/person-notes/form/constants/schema"
import { PersonNoteModalWrapper, WarningIconStyled } from "./style"
import { Candidate } from "views/candidates/candidates.types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { BioAddValues, OnAddBioSubmitType } from "../types"

interface BioModalProps {
  onSubmit: OnAddBioSubmitType
  initialValues?: {
    biographyDescription: string
    setAsMainBiography: boolean
  }
  title: string
  typeTitle: string
  candidate: Candidate
}

export const BioModal = ({ onSubmit, initialValues, title }: BioModalProps) => {
  const { close } = useModal()
  const onFormSubmit = useCallback(
    (data: BiographyForm, action: FormikHelpers<BioAddValues>) => {
      close(ModalNames.PersonRecordBiographyModal)
      onSubmit(data, action)
    },
    [close, onSubmit]
  )

  const formikContextValue = {
    initialValues: {
      ...initialDefaultBiographyState,
      ...initialValues
    },
    validationSchema: addBiographyValidationSchema,
    onSubmit: onFormSubmit,
    isInitialValid: (props: any) => {
      return props.initialValues.biographyDescription?.length
    }
  }

  return (
    <PersonNoteModalWrapper>
      <ModalSectionHeader
        size="xSmall"
        title={title}
        actions={
          <>
            <Button
              onClick={() => close(ModalNames.PersonRecordBiographyModal)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </>
        }
      />

      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <Flex p="m" flexDirection="column">
              <FormikTextarea
                rows={10}
                id={BiographyField.BiographyDescription}
                name={BiographyField.BiographyDescription}
                label={messages.person.bio.form.biographyText}
                resize="none"
              />

              <Flex flexDirection="row" mt="m">
                <WarningIconStyled fill={colors.orange.standard} />
                <Div pl="xs">
                  {messages.person.bio.setMain}
                  <Div mt="s">
                    <FormikToggle
                      small
                      id={BiographyField.SetAsMainBiography}
                      name={BiographyField.SetAsMainBiography}
                      label={messages.person.bio.setMainLabel}
                      hideUnderline
                      type="checkbox"
                    />
                  </Div>
                </Div>
              </Flex>
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="grey.light"
              p="xs"
            >
              <Button type="submit" disabled={!isValid}>
                {messages.person.bio.form.save}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </PersonNoteModalWrapper>
  )
}
