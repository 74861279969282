import React from "react"
import {
  RegisterUser,
  RegisterUserProps
} from "views/users/register-user/RegisterUser"

type RegisterInvitedUserTokenValidProps = {} & RegisterUserProps
export const RegisterInvitedUserTokenValid = (
  props: RegisterInvitedUserTokenValidProps
) => {
  const { email, company, userId, searchFirmId } = props
  return (
    <RegisterUser
      company={company}
      email={email}
      userId={userId}
      searchFirmId={searchFirmId}
    />
  )
}
