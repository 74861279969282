import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { LinkedInIcon } from "@ikiru/talentis-fpc"
import { KnownWebSite, knownWebSitesIcons } from "./definitions"
import { LinkIcon } from "../style"
import { WebSite } from "views/persons/person.types"

type PersonWebSitesProps = {
  webSites: WebSite[]
  linkedInProfileUrl?: string
}

export const PersonWebSites = ({
  webSites,
  linkedInProfileUrl
}: PersonWebSitesProps) => {
  const knownWebsites = webSites
    .filter(({ type }) => Boolean(type) && type !== "other")
    .map((website) => website as KnownWebSite)

  const openLink = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  return (
    <Flex as="ul" flexWrap="wrap" p={0} m={0} style={{ listStyleType: "none" }}>
      {linkedInProfileUrl && (
        <Div as="li">
          <LinkIcon
            data-e2e-target="person-website"
            data-e2e-target-name="webSite-linkedIn"
            onClick={() => openLink(linkedInProfileUrl)}
          >
            <LinkedInIcon />
          </LinkIcon>
        </Div>
      )}
      {knownWebsites.map((item, index) => {
        const Icon = knownWebSitesIcons[item.type]

        return (
          <Div as="li" key={`${item}-${index}`} ml="xxs">
            <LinkIcon
              data-e2e-target="person-website"
              data-e2e-target-name="webSite-linkedIn"
              onClick={() => openLink(item.url)}
            >
              {Icon && <Icon />}
            </LinkIcon>
          </Div>
        )
      })}
    </Flex>
  )
}
