import React, { useEffect } from "react"
import { useModal } from "utils/hooks/use-modal"
import { useAuth } from "setup/auth/module/auth.context"
import { ModalNames } from "setup/modal/modal.definitions"
// import { SearchWelcomeModal } from "../search-welcome"
import { useSearch } from "../../SearchModule/context"
import { cloneDeep } from "lodash"
import { examplesSearch } from "../../components/ExperienceTalentisSearch/consts"
import {
  finishUserGuiding,
  onWatchGuideClick
} from "../../components/FindMoreCandidates/utils"

export const OpenCloseUserGuidingModal = () => {
  const {
    open: openModal,
    close: closeModal,
    onClose,
    isOpen,
    activeModal
  } = useModal()
  const { user } = useAuth()
  const { loadExamplesSearch } = useSearch()
  useEffect(() => {
    if (user?.firstUsage === "none" || user?.firstUsage === "runAgain") {
      // openModal(ModalNames.SearchWelcome, <SearchWelcomeModal />, true)
    } else if (isOpen && activeModal === ModalNames.SearchWelcome) {
      onClose(ModalNames.SearchWelcome, () => {
        onWatchGuideClick(fpConfig.userGuidingId)
        loadExamplesSearch(cloneDeep(examplesSearch.semiconductor))
      })
      closeModal(ModalNames.SearchWelcome)
    }
  }, [
    user,
    openModal,
    closeModal,
    loadExamplesSearch,
    onClose,
    isOpen,
    activeModal
  ])
  useEffect(
    () => () => {
      closeModal(ModalNames.SearchWelcome)
      finishUserGuiding(fpConfig.userGuidingId)
    },
    [closeModal]
  )

  return <></>
}
