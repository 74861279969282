import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { hexToRGB } from "utils/hexToRGB"
import { ScrollMenu } from "react-horizontal-scrolling-menu"

type navStyle = {
  disabled?: boolean
  isRowTab: boolean
}

export const HorizontalScrollContainer = styled(Div)`
  .react-horizontal-scrolling-menu--wrapper {
    height: 52px;
    width: 100%;
    overflow: visible;
    background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 1)};
    border-top: 2px solid ${({ theme }) => theme.colors?.white?.standard};
    border-bottom: 2px solid ${({ theme }) => theme.colors?.white?.standard};
  }

  .react-horizontal-scrolling-menu--scroll-container {
    overflow: hidden;
  }
  .react-horizontal-scrolling-menu--item {
    box-sizing: border-box;
    margin-left: 0px;
    height: 50px;
    background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 1)};
  }
  .react-horizontal-scrolling-menu--separator {
    min-width: 2px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors?.white?.standard};
  }
`

export const StageTab = styled(Div)`
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.6)};
`
export const ScrollMenuContainer = styled(ScrollMenu)`
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const NavigationArrowContainer = styled(Div)<navStyle>`
  display: ${({ disabled }) => (disabled ? "none" : "flex")};
  padding-top: ${({ isRowTab }) => (isRowTab ? "5" : "15")}px;
  height: ${({ isRowTab }) => (isRowTab ? "36" : "45")}px;
  width: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.5)};
`
