import React, { useEffect } from "react"
import { useSearch } from "views/search/SearchModule/context"
import { useToggleButtonField } from "../hooks"
import { LogicalOperator, FilterType } from "views/search/SearchModule/types"
import { ToggleButton, ToggleButtonGroup } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type LogicalConnectionProps = {
  filterType:
    | FilterType.JobTitlesBundleSearchUsingANDLogic
    | FilterType.KeywordsBundleSearchUsingORLogic
}

export const LogicalConnection = ({ filterType }: LogicalConnectionProps) => {
  const { filters, updateFilters } = useSearch()

  const onChange = useToggleButtonField(filterType)

  const keywordLogic = filters?.[filterType] || LogicalOperator.AND

  useEffect(() => {
    !filters?.[filterType] &&
      updateFilters({ [filterType]: LogicalOperator.AND })
  }, [updateFilters, filters, filterType])

  return (
    <ToggleButtonGroup
      required
      exclusive
      onChange={onChange}
      value={keywordLogic}
    >
      <ToggleButton
        label={messages.talentGraphSearch.logic.and}
        value={LogicalOperator.AND}
        type="button"
      />
      <ToggleButton
        label={messages.talentGraphSearch.logic.or}
        value={LogicalOperator.OR}
        type="button"
      />
    </ToggleButtonGroup>
  )
}
