import {
  get,
  isBoolean,
  isEmpty,
  noop,
  omit,
  pickBy,
  set,
  toPath,
  cloneDeep
} from "lodash"
import {
  Filters,
  SearchState,
  SearchContextValues,
  SearchActionTypes
} from "./types"
import { scrollToTop } from "./utils"

export const initialSearchState: SearchState = {
  isLoading: false,
  areFiltersExpanded: true,
  isCriteriaNotDefined: true,
  pageNumber: 1,
  pageSize: 25,
  filters: {},
  totalItems: 0,
  totalPages: 0,
  persons: [],
  activeAssignments: [],
  selectedAssignment: "",
  updatingPersons: {},
  hasStoredFilters: false,
  savedSearches: [],
  isLoadingSeaches: false,
  isFinishedLoadingSeaches: false,
  selectedSearchPersonId: "",
  selectedSearchDataPoolId: "",
  activeCampaigns: [],
  selectedCampaign: "",
  isAssignmentSelect: null,
  isAnonymized: false,
  hasResetFilters: false,
  hasSearched: false
}

export const searchReducer = (
  state: SearchState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action

  switch (type) {
    case SearchActionTypes.SetIsAnonymized:
      return { ...state, isAnonymized: payload }
    case SearchActionTypes.SetFiltersExpanded:
      return { ...state, areFiltersExpanded: payload }
    case SearchActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case SearchActionTypes.SetIsCriteriaNotDefined:
      return { ...state, isCriteriaNotDefined: payload }
    case SearchActionTypes.SetPage:
      return { ...state, pageNumber: payload }
    case SearchActionTypes.SetActiveAssignments:
      return { ...state, activeAssignments: payload }
    case SearchActionTypes.SetSelectedAssignment:
      return { ...state, selectedAssignment: payload }
    case SearchActionTypes.SetRecordsCount:
      return {
        ...state,
        totalItems: payload?.totalItems || 0,
        totalPages: payload?.totalPages || 0
      }
    case SearchActionTypes.SetPersons:
      return {
        ...state,
        persons: payload,
        hasSearched: true,
        isLoading: false
      }
    case SearchActionTypes.ClearCertainFilters:
      const futureFilters: Filters = cloneDeep(state.filters)

      for (const [filterName] of Object.entries(payload)) {
        const pathArr = toPath(filterName)
        const deleteKey = pathArr.pop()
        if (pathArr.length) {
          get(futureFilters, pathArr)?.splice(deleteKey, 1)
        } else if (deleteKey) {
          delete futureFilters[deleteKey as keyof Filters]
        }
      }

      return {
        ...state,
        filters: pickBy(futureFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        ),
        // Reset page if filters changed
        pageNumber: 1
      }
    case SearchActionTypes.UpdateFilters:
      const newFilters: Filters = { ...state.filters }

      for (const [filterName, filterValue] of Object.entries(payload)) {
        set(newFilters, filterName, filterValue)
      }

      return {
        ...state,
        filters: pickBy(newFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        )
      }
    case SearchActionTypes.SetHasStoredFilters:
      return {
        ...state,
        hasStoredFilters: payload
      }
    case SearchActionTypes.SetPersonUpdating:
      return {
        ...state,
        updatingPersons: {
          ...state.updatingPersons,
          [payload]: true
        }
      }
    case SearchActionTypes.RemoveUpdatingPerson:
      return {
        ...state,
        updatingPersons: omit(state.updatingPersons, payload.key)
      }
    case SearchActionTypes.UpdatePerson:
      return { ...state, persons: payload, hasSearched: false }
    case SearchActionTypes.ResetFilters: {
      scrollToTop()
      return {
        ...initialSearchState,
        hasSearched: false,
        activeAssignments: state.activeAssignments,
        activeCampaigns: state.activeCampaigns,
        savedSearches: state.savedSearches,
        hasResetFilters: true
      }
    }
    case SearchActionTypes.SetHasResetFilters: {
      return { ...state, hasResetFilters: payload }
    }
    case SearchActionTypes.SavedSearch:
      return { ...state, savedSearches: payload }
    case SearchActionTypes.SetIsLoadingSeaches:
      return { ...state, isLoadingSeaches: payload }
    case SearchActionTypes.SetIsFinishedLoadingSeaches:
      return { ...state, isFinishedLoadingSeaches: payload }
    case SearchActionTypes.SetSelectedSearchPersonId:
      return { ...state, selectedSearchPersonId: payload }
    case SearchActionTypes.SetSelectedSearchDataPoolId:
      return { ...state, selectedSearchDataPoolId: payload }
    case SearchActionTypes.SetActiveCampaigns:
      return { ...state, activeCampaigns: payload }
    case SearchActionTypes.SetSelectedCampaign:
      return { ...state, selectedCampaign: payload }
    case SearchActionTypes.SetIsAssignmentSelect:
      return { ...state, isAssignmentSelect: payload }
    default:
      return state
  }
}

export const initialSearchContextValues: SearchContextValues = {
  ...initialSearchState,
  paginationProps: {
    pageNumber: 0,
    pageSize: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    maxPages: 0,
    pageCount: 0,
    totalItemCount: 0
  },
  getPreparedFilters: () => null,
  onPageChange: noop,
  setSelectedAssignment: noop,
  handleLocalPerson: noop,
  handleDataPoolPerson: noop,
  linkageDataPoolPerson: () => Promise.resolve(),
  addArrayBasedFilter: noop,
  removeArrayBasedFilter: noop,
  clearArrayBasedFilter: noop,
  setFiltersExpanded: noop,
  updateFilters: noop,
  clearFilters: noop,
  resetFilters: noop,
  loadPreviousSearch: noop,
  loadExamplesSearch: noop,
  handleSaveSearch: noop,
  fetchSearches: noop,
  removeSavedSearch: noop,
  setDefaultSearch: noop,
  setSelectedSearchPersonId: noop,
  setSelectedSearchDataPoolId: noop,
  updatePerson: noop,
  updatePersonsStage: noop,
  updatePersonsAssignment: noop,
  setSelectedCampaign: noop,
  setIsAssignmentSelect: noop,
  fetchCampaigns: noop,
  fetchAssignments: noop,
  updatePersonsCampaign: noop,
  searchTalentGraph: noop,
  setIsAnonymized: noop,
  setHasResetFilters: noop
}
