import React, { useMemo } from "react"
import { Form, Formik, FormikHelpers } from "formik"
import {
  personNotesFormE2ETargets,
  personNotesInitialTouched,
  LinkNote
} from "views/persons/components/person-notes/form/constants/definitions"
import { addNoteValidationSchema } from "views/persons/components/person-notes/form/constants/schema"
import {
  PersonNoteEditFormProps,
  PersonNoteEditValues
} from "views/persons/components/person-notes/form/constants/types"
import { LinkToNote } from "./LinkToNote"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonNoteForm } from "./PersonNoteForm"
import { NoteAttachments } from "components/PersonRecords/Notes/NoteModal/NoteAttachments"
import { Button, Div, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { e2eTargets } from "../definitions"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { ModalFormContainer } from "../components/style"

type onSubmitType = (
  values: PersonNoteEditValues,
  formikHelpers: FormikHelpers<PersonNoteEditValues>
) => void

export const PersonNotesEditForm = (props: PersonNoteEditFormProps) => {
  const {
    onSubmit: onSubmitFromProps,
    assignmentOptions,
    campaignOptions,
    initialValues,
    documents,
    noteId
  } = props

  const { setPersonOverlayNoteUpdated } = usePerson()

  const onSubmit: onSubmitType = (values, actions) => {
    setPersonOverlayNoteUpdated(true)
    onSubmitFromProps(
      {
        ...values,
        linkAssignment: null,
        linkCampaign: null
      },
      actions
    )
  }

  const initialIds = useMemo(
    () => ({
      assignmentId: initialValues.linkAssignment?.id || "",
      campaignId: initialValues.linkCampaign?.id || ""
    }),
    [initialValues.linkAssignment, initialValues.linkCampaign]
  )

  const selectedProject = useMemo(
    () =>
      initialValues.linkAssignment?.id
        ? LinkNote.Assignment
        : initialValues.linkCampaign?.id
        ? LinkNote.Campaign
        : LinkNote.None,
    [initialValues.linkAssignment, initialValues.linkCampaign]
  )

  const { close } = useModal()

  return (
    <ModalFormContainer>
      <Formik
        initialValues={{ ...initialValues, ...initialIds }}
        initialTouched={personNotesInitialTouched}
        validationSchema={addNoteValidationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <>
              <Form data-e2e-target-name={personNotesFormE2ETargets.noteForm}>
                <ModalSectionHeader
                  title={messages.person.note.form.noteEditMode}
                  size="xSmall"
                  actions={
                    <>
                      <Button
                        type="submit"
                        size="small"
                        mr="xs"
                        data-e2e-target={e2eTargets.saveNoteButton}
                      >
                        {messages.generic.save}
                      </Button>
                      <Button
                        data-e2e-target={e2eTargets.cancelNoteButton}
                        mode="standard-white"
                        size="small"
                        type="button"
                        onClick={() => {
                          close(ModalNames.AddOrEditPersonNote)
                        }}
                      >
                        {messages.generic.cancel}
                      </Button>
                    </>
                  }
                />
                <Div p="m">
                  <PersonNoteForm />

                  {!!documents?.length && noteId && (
                    <Div mt="15px">
                      <NoteAttachments documents={documents} noteId={noteId} />
                    </Div>
                  )}
                  <LinkToNote
                    assignmentOptions={assignmentOptions}
                    campaignOptions={campaignOptions}
                    selectedProject={selectedProject}
                  />
                </Div>
              </Form>
            </>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
