import React from "react"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { usePerson } from "../person-module/candiate-module.context"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"
import { useModal } from "utils/hooks/use-modal"
import { useSearch } from "views/search/SearchModule/context"
import { getOnAddPersonAssignment } from "../components/person-assignments/form/actions"
import { ModalNames } from "setup/modal/modal.definitions"
import { CandidateAddedFrom } from "setup/app-insights/definitions"
import { AddPersonAssignment } from "views/persons/components/person-assignments/form"
import { PersonAssignmentProps } from "../components/person-assignments"
import { AddPersonCampaign } from "views/persons/components/person-campaigns/form"
import { PersonCampaignProps } from "../components/person-campaigns"
import { getOnAddPersonCampaign } from "../components/person-campaigns/form/actions"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"

export const useLinkToProject = () => {
  const {
    candidates,
    contacts,
    setCandidates,
    setContacts,
    personId,
    setPersonOverlayUpdated
  } = usePerson()
  const { trackAddCandidate } = useTelemetry()
  const { updatePersonsAssignment, updatePersonsCampaign } = useSearch()
  const { updatePersonProject } = useInternalSearch()
  const { createLocalPerson } = usePersonDataPoolLinkage()
  const { open: openModal, close } = useModal()

  const onAddPersonAssignment = getOnAddPersonAssignment(
    personId,
    (data) => {
      setCandidates({ [data.id]: data, ...candidates })

      const { assignmentId, personId } = data
      updatePersonsAssignment(personId, assignmentId)
      updatePersonProject(personId, assignmentId, "assignment")
      setPersonOverlayUpdated(true)
      close(ModalNames.LinkAssignmentOrCampaign)
      trackAddCandidate({
        addedFrom: CandidateAddedFrom.MainSitePerson,
        assignmentId,
        localPersonId: personId
      })
    },
    createLocalPerson
  )

  const onAddPersonCampaign = getOnAddPersonCampaign(
    personId,
    (data) => {
      setContacts({ [data.id]: data, ...contacts })
      const { campaignId, personId } = data
      updatePersonsCampaign(personId, campaignId)
      updatePersonProject(personId, campaignId, "campaign")
      setPersonOverlayUpdated(true)
      close(ModalNames.LinkAssignmentOrCampaign)
    },
    createLocalPerson
  )

  const linkCampaignModal = ({
    loading,
    options,
    project
  }: PersonCampaignProps) => {
    openModal(
      ModalNames.LinkAssignmentOrCampaign,
      <AddPersonCampaign
        campaigns={options}
        isLoading={loading.campaigns}
        onSubmit={onAddPersonCampaign}
        project={project}
      />
    )
  }

  const linkAssignmentModal = ({
    options,
    loading,
    project
  }: PersonAssignmentProps) => {
    openModal(
      ModalNames.LinkAssignmentOrCampaign,
      <AddPersonAssignment
        assignments={options}
        isLoading={loading.assignments}
        onSubmit={onAddPersonAssignment}
        project={project}
      />
    )
  }

  return { linkAssignmentModal, linkCampaignModal }
}
