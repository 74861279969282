export const peopleSearchPages: string[] = [
  "linkedin.com/in",
  "bloomberg.com/profile/person",
  "reuters.com/finance/stocks/officer-profile",
  "crunchbase.com/person"
]

export enum GooglePersonSearchQueryParams {
  PersonName = "talentisPersonName",
  Keywords = "talentisKeywords"
}
