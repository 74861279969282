import React, { useMemo } from "react"
import { BodyText, Flex } from "@ikiru/talentis-fpc"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import ExperienceData from "./components/ExperienceData"
import { OverviewRow } from "./OverviewRow"
import _ from "lodash"
import { isEmptyData } from "../helpers"
import { messages } from "setup/messages/messages"

export const OverviewProfessionalExperience = () => {
  const { cvParsing } = usePerson()

  const filteredValues = useMemo(() => {
    if (cvParsing.professionalExperience) {
      const cvExperience = cvParsing.professionalExperience
      const existing = cvExperience.existing.filter((exp) => {
        return !_.isEmpty(exp.changedValues)
      })
      const parsed = cvExperience.parsed.filter((exp) => {
        return exp.isMovedToExisting
      })

      return [...existing, ...parsed]
    }
    return []
  }, [cvParsing.professionalExperience])

  return (
    <Flex flexDirection="column">
      {isEmptyData(filteredValues) ? (
        <BodyText pl="xxs">{messages.person.cvparsing.noChanges}</BodyText>
      ) : (
        filteredValues.map((exp, index) => (
          <OverviewRow
            key={exp.id}
            bottomBorder={filteredValues.length - 1 === index}
          >
            <ExperienceData {...exp} />
          </OverviewRow>
        ))
      )}
    </Flex>
  )
}
