import React from "react"
import { messages } from "setup/messages/messages"
import { DropzoneProps } from "./dropzone.definitions"
import { Button, Loader } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { H5 } from "@ikiru/talentis-fpc"

const e2eTargets = {
  uploadButton: "upload-button"
}

export const Dropzone = ({
  value,
  resetForm,
  onFileDialogCancel,
  isSubmitting
}: DropzoneProps) => {
  return (
    value && (
      <Flex bg="white.standard" p="s" alignItems="center">
        <H5 flexGrow={1} m={0}>
          {value.name}
        </H5>
        <Flex>
          {isSubmitting ? (
            <Loader size="small" />
          ) : (
            <>
              <Button
                onClick={() => {
                  resetForm()
                  onFileDialogCancel && onFileDialogCancel()
                }}
                size="small"
                mode="standard-white"
                mr="xxs"
              >
                {messages.form.generic.clear}
              </Button>
              <Button
                size="small"
                type="submit"
                data-e2e-target-name={e2eTargets.uploadButton}
              >
                {messages.form.generic.upload}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    )
  )
}
