import * as Yup from "yup"
import { urlRegex } from "utils/regexes"
import { messages } from "setup/messages/messages"
import { LinkedInUrlField } from "./definitions"

export const linkedInValidationSchema = Yup.object({
  [LinkedInUrlField.LinkedInUrl]: Yup.object({
    value: Yup.string()
      .matches(urlRegex, messages.person.details.errors.invalidLink)
      .max(150, messages.form.generic.errors.mustBeNoLongerThan.format(150))
      .required(messages.generic.required)
  })
})
