import {
  Flex,
  Div,
  H4,
  colors,
  H6,
  SmallText,
  Link,
  breakpoints,
  spacing
} from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const List = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  li {
    padding-bottom: 0;
    flex-basis: 100%;
  }
`

export const ListItem = styled.li`
  display: flex;
  width: auto;
  align-items: center;
  & > * {
    margin: 0;
  }

  & div {
    margin-left: ${({ theme }) => theme.space.xxs}px;
  }
`

export const LinkIcon = styled.div`
  max-width: 28px;
  max-height: 28px;
`
export const WebSitesList = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  list-style-type: none;
  svg {
    &:hover {
      filter: brightness(0.9);
    }
  }
`
type PersonBioStyledProps = {
  expanded: boolean
  ref?: React.MutableRefObject<HTMLParagraphElement | undefined>
}

export const PersonBioStyled = styled(Div)<PersonBioStyledProps>`
  display: -webkit-box;
  white-space: pre-line;
  ${({ expanded }) => !expanded && `-webkit-line-clamp: 3;`};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const StyledH4 = styled(H4)`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: ${colors.grey.darkest};
  margin-top: 7px;
  margin-bottom: 7px;
  display: inline-block;
`

export const StyledPopupTrigger = styled(Flex)`
  cursor: pointer;
  align-items: center;
`

export const ItemContainer = styled(Flex)`
  margin-left: 13px;
  cursor: default;
  align-items: center;
  justify-content: space-between;
`
export const UsageTypeStyle = styled(H6)`
  margin: 0px;
  margin-left: 5px;
`

export const ItemValueStyle = styled(H6)`
  margin: 0px;
  margin-right: 5px;
  font-weight: 400;
`
export const CustomLink = styled(Link)`
  margin-right: 5px;
  margin-bottom: 0px;
`
export const PreferredLabel = styled(SmallText)`
  color: ${({ theme }) => theme.colors.grey.standard};
  font-weight: 700;
`

export const TeamContainer = styled(Div)`
  margin-left: ${spacing.xs}px;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-left: 0;
  }
`

export const WebsitesTeamContainer = styled(Flex)`
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
