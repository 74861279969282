import { useState, useEffect, useRef } from "react"
import { PersonConsentsFormFields } from "./types"
import {
  initialDefaultPersonConsentState,
  PersonConsentsField
} from "./constants/definitions"
import {
  GDPRStatuses,
  GDPRStatusesForGivenLawfulBasis,
  GDPRStatusOption
} from "./constants/gdpr-statuses"
import {
  getPersonConsentValudationSchema,
  initialPersonConsentValidationSchema
} from "./constants/schema"
import { GDPRLawfulBasis } from "./constants/gdpr-lawful-basis"

export const usePersonConsentOptions = (
  initialConsentStateFromAPI?: PersonConsentsFormFields
) => {
  const [initialValues, setInitialValues] = useState<PersonConsentsFormFields>(
    initialConsentStateFromAPI || initialDefaultPersonConsentState
  )

  const [lawfulBasis, setLawfulBasis] = useState(
    initialValues.gdprLawfulBasisOption as GDPRLawfulBasis
  )

  const [availableGDPRStatuses, setAvailableGDPRStatuses] = useState(
    [] as GDPRStatusOption[]
  )

  const [validationSchema, setValidationSchema] = useState(
    initialPersonConsentValidationSchema
  )

  const initialValuesRef = useRef(initialValues)

  useEffect(() => {
    const GDPRStatusesForLawfulBasis =
      GDPRStatusesForGivenLawfulBasis.get(lawfulBasis)

    GDPRStatusesForLawfulBasis &&
      setAvailableGDPRStatuses(GDPRStatusesForLawfulBasis)
  }, [lawfulBasis])

  useEffect(() => {
    const GDPRStatusesForLawfulBasis =
      GDPRStatusesForGivenLawfulBasis.get(lawfulBasis)

    const isInitialGDPRSet = !initialConsentStateFromAPI
    const statusIsNullInAPI =
      initialConsentStateFromAPI &&
      !initialConsentStateFromAPI![PersonConsentsField.Status]
    const lawfulBasisChanged =
      initialValuesRef.current.gdprLawfulBasisOption !== lawfulBasis

    const shouldSetDefaultInitialGDPRValue =
      isInitialGDPRSet || statusIsNullInAPI || lawfulBasisChanged

    /**
     * If gdpr statuses are available and the conditions are met
     * (meaning either:
     * - there's nothing set for the consent state
     * - or nothing set for the gdpr status
     * - or lawful basis has changed compared to what came from API
     * ) set it to the default value which is "not started"
     * otherwise clear the field
     */

    const newInitialGDPRStatus = GDPRStatusesForLawfulBasis?.length
      ? shouldSetDefaultInitialGDPRValue && {
          [PersonConsentsField.Status]: GDPRStatuses.NotStarted
        }
      : { [PersonConsentsField.Status]: undefined }

    setInitialValues({
      ...initialValuesRef.current,
      [PersonConsentsField.LawfulBasis]: lawfulBasis,
      ...newInitialGDPRStatus
    })
  }, [lawfulBasis, initialConsentStateFromAPI, initialValuesRef])

  useEffect(() => {
    const schema = getPersonConsentValudationSchema(availableGDPRStatuses)
    setValidationSchema(schema)
  }, [availableGDPRStatuses])

  const onLawfulBasisChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setLawfulBasis(event.currentTarget.value as GDPRLawfulBasis)
  }

  const shouldRenderStatuses =
    Boolean(availableGDPRStatuses?.length) ||
    (initialConsentStateFromAPI?.gdprLawfulBasisOptionsStatus &&
      initialValues.gdprLawfulBasisOptionsStatus ===
        initialConsentStateFromAPI?.gdprLawfulBasisOptionsStatus)

  return {
    validationSchema,
    onLawfulBasisChange,
    availableGDPRStatuses,
    initialValues,
    shouldRenderStatuses
  }
}
