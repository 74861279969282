import React from "react"
import { Nullable } from "tsdef"
import { Div, H5, BodyText } from "@ikiru/talentis-fpc"
import { generateCurrency } from "views/assignments/utils"
import { defaultCurrency } from "../constants/definitions"

type AssignmentDataBlockProps = {
  title: string
  value: Nullable<number>
  currency?: string
}

export const AssignmentDataBlock = ({
  title,
  value,
  currency = defaultCurrency
}: AssignmentDataBlockProps) => (
  <Div pr="xxs" minWidth="190px">
    <H5 my="0">{title}</H5>
    <BodyText my={0}>{generateCurrency(value, currency)}</BodyText>
  </Div>
)
