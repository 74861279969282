import React, { useEffect, useMemo, useRef, useState } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { PersonOverlayContainer, PersonContent } from "./style"
import { useChangeItem } from "./hook/useChangeItem"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { getCurrentCandidateIndex, getUpdatedPhotoAndCallBack } from "./utils"

const AssignmentCompanyPersonOverlay = () => {
  const [candidatePosition, setCandidatePosition] = useState<number>(1)
  const {
    setSelectedCandidatedId,
    selectedCandidatedId,
    pageNumber,
    pageSize,
    isCandidatesLoading,
    totalPages,
    updateCompanyCandidate,
    companiesCandidates,
    selectedCompany,
    companies
  } = useAssignment()

  // will be need when pagination will be ready on backend
  const handlePageChange = (page: number) => {
    console.log("handlePageChange", page)
  }

  const candidates = useMemo(() => {
    return companiesCandidates?.[selectedCompany]?.candidates || []
  }, [companiesCandidates, selectedCompany])

  const companyName = useMemo(() => {
    return companies.find((company: any) => company.id === selectedCompany).name
  }, [companies, selectedCompany])

  const { directionNext, directionPrevious } = useChangeItem(
    { handlePageChange },
    pageNumber,
    pageSize,
    companiesCandidates?.[selectedCompany].candidates.length || 0,
    companiesCandidates?.[selectedCompany].candidates || [],
    selectedCandidatedId,
    setSelectedCandidatedId,
    totalPages
  )

  const {
    getProfileData,
    person,
    setPersonOverlayUpdated,
    personOverlayUpdated,
    candidates: updatedPersons,
    personOverlayPhotoUpdated,
    profileDetails
  } = usePerson()

  useEffect(() => {
    if (selectedCandidatedId) {
      getProfileData(selectedCandidatedId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCandidatedId])

  const overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = () => {
    setSelectedCandidatedId("")
  }

  useEffect(() => {
    if (personOverlayUpdated) {
      const candidate = Object.values(updatedPersons)[0]

      getUpdatedPhotoAndCallBack(
        candidate.personId,
        personOverlayPhotoUpdated,
        (photoUrl) => {
          const newPersonData = person
          newPersonData!.isOfflimits = profileDetails.isOfflimits
          candidate.normalizedPersonData = newPersonData

          if (photoUrl) {
            candidate.photo = photoUrl
          }

          updateCompanyCandidate(candidate)
          setPersonOverlayUpdated(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    profileDetails,
    setPersonOverlayUpdated,
    updatedPersons
  ])

  const closeOverlayFromBackground = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      closeOverlay()
    }
  }

  useEffect(() => {
    const candidateNumber = getCurrentCandidateIndex(
      candidates,
      selectedCandidatedId,
      pageNumber
    )

    if (!isCandidatesLoading && candidateNumber !== -1) {
      setCandidatePosition(candidateNumber)
    }
  }, [selectedCandidatedId, pageNumber, isCandidatesLoading, candidates])

  return (
    <PersonOverlayContainer onClick={closeOverlayFromBackground}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={closeOverlay}
            candidateStage={companyName}
            totalItems={
              companiesCandidates?.[selectedCompany].candidates.length || 0
            }
            currentCandidateNumber={candidatePosition}
          />
          <PersonOverlay
            personId={selectedCandidatedId}
            isLoadingCandidates={isCandidatesLoading}
            project="assignment"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default AssignmentCompanyPersonOverlay
