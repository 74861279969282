import { Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const ListPersonCandidatesContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.space.xs}px;
`
