import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import { createPersonValidationSchema } from "views/persons/components/person-details/form/constants/schema"
import {
  CreatePersonField,
  CreatePersonValues,
  createPersonInitialValues as defaultInitialValues
} from "views/persons/components/person-details/form/constants/definitions"
import { OnPersonDetailsSubmitType } from "views/persons/components/person-details/form/actions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { messages } from "setup/messages/messages"
import { FormikArrayField } from "components/functional/formik/formik-array-field/FormikArrayField"
import { InputRefKey } from "../types"
import { SMTPValid, SmtpValidBadge, Div } from "@ikiru/talentis-fpc"
import { getChangedValuesWithArray } from "views/assignments/utils"
import { usePerson } from "../../../person-module/candiate-module.context"
import NestedInputs from "./NestedInputs"
import {
  EmailTypeEnums,
  PhoneTypeEnums,
  emailTypeOptions,
  phoneNumberInitialValue,
  phoneTypeOptions
} from "../../person-details-tab/components/person-contact-info/form/constants/definitions"
import { e2eTargets } from "../../person-details-tab/constant/definitions"
import { countLines } from "utils/helpers"

const prepareFilledArrayWithMinOneElement = (
  array: any[],
  nestedKey?: string
): any[] => {
  const filtered = array?.filter((item) =>
    nestedKey ? Boolean(item?.value?.[nestedKey]) : Boolean(item?.value)
  )
  return filtered?.length === 0 ? [] : filtered
}

type PersonDetailsFormProps = {
  checkPersonsExist?: (values: CreatePersonValues) => () => void
  clearExistingPersonsList?: () => void
  initialValues?: Partial<CreatePersonValues>
  onSubmit: OnPersonDetailsSubmitType
  formActionsComponent: (
    formikProps: FormikProps<CreatePersonValues>
  ) => JSX.Element
  editMode?: boolean
  inputRefs?: {
    [key in InputRefKey]: React.MutableRefObject<HTMLInputElement>
  }
}

export const PersonDetailsForm = (props: PersonDetailsFormProps) => {
  const {
    checkPersonsExist,
    initialValues,
    onSubmit,
    formActionsComponent,
    editMode = false,
    inputRefs
  } = props
  const { defaultEditedField } = usePerson()

  const submitForm = (
    values: CreatePersonValues,
    action: FormikHelpers<CreatePersonValues>
  ) => {
    const tempValues = {
      ...values,
      webSites: prepareFilledArrayWithMinOneElement(
        values?.webSites || [],
        "url"
      ),
      taggedEmails: prepareFilledArrayWithMinOneElement(
        values.taggedEmails || [],
        "email"
      ),
      phones: prepareFilledArrayWithMinOneElement(
        values.phones || [],
        "phoneNumber"
      )
    }

    const changeValues = initialValues
      ? getChangedValuesWithArray(tempValues, initialValues)
      : values

    onSubmit(changeValues, action)
  }

  return (
    <Formik
      initialValues={{
        ...defaultInitialValues,
        ...initialValues
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={createPersonValidationSchema}
      onSubmit={submitForm}
    >
      {(formik) => {
        const { values, setFieldValue } = formik

        return (
          <Form>
            <FormikInput
              id={CreatePersonField.Name}
              name={`${CreatePersonField.Name}.value`}
              label={messages.form.generic.name}
              onBlur={checkPersonsExist?.(values)}
            />
            <FormikInput
              id={CreatePersonField.Location}
              name={`${CreatePersonField.Location}.value`}
              label={messages.form.generic.location}
              ref={inputRefs?.locationInputRef}
              disabled={values?.location?.isReadOnly}
            />
            <Div mt={editMode ? "m" : 0}>
              <FormikInput
                autoFocus={defaultEditedField === CreatePersonField.LinkedInUrl}
                id={CreatePersonField.LinkedInUrl}
                name={`${CreatePersonField.LinkedInUrl}.value`}
                label={messages.form.generic.linkedInUrl}
                ref={inputRefs?.linkedinUrlInputRef}
                disabled={values?.linkedInProfileUrl?.isReadOnly}
              />
            </Div>
            {editMode ? (
              <FormikArrayField
                autoFocusFirstElement={
                  defaultEditedField === CreatePersonField.WebSites
                }
                id={CreatePersonField.WebSites}
                name={CreatePersonField.WebSites}
                e2eTarget={e2eTargets.webSites}
                label={messages.form.generic.webSite}
                displayAddMoreBtn={false}
                displayActionBtn={false}
                nestedKey="value.url"
                initialValue={{ value: { url: "" }, isReadOnly: false }}
                ComponentProps={(name, index) => {
                  return {
                    disabled:
                      values?.[name as CreatePersonField.WebSites]?.[index]
                        ?.isReadOnly
                  }
                }}
              />
            ) : null}
            {!editMode ? (
              <>
                <FormikInput
                  id={CreatePersonField.JobTitle}
                  name={`${CreatePersonField.JobTitle}.value`}
                  label={messages.form.generic.jobTitle}
                />
                <FormikInput
                  id={CreatePersonField.Company}
                  name={`${CreatePersonField.Company}.value`}
                  label={messages.form.generic.companyName}
                />
              </>
            ) : null}
            <Div mt={editMode ? "m" : 0}>
              <FormikTextarea
                id={CreatePersonField.Bio}
                name={`${CreatePersonField.Bio}.value`}
                label={messages.form.generic.biography}
                rows={countLines(values.bio.value || "")}
              />
            </Div>
            <NestedInputs
              label={messages.form.generic.phone}
              e2eTargets={e2eTargets.phoneNumbers}
              inputName={CreatePersonField.PhoneNumbers}
              selectProps={{
                nestedKey: "value.phoneUsageType",
                ComponentProp: (name, index) => ({
                  value:
                    values.phones[index].value.phoneUsageType ||
                    PhoneTypeEnums.Personal,
                  options: phoneTypeOptions
                })
              }}
              inputProps={{
                nestedKey: "value.phoneNumber",
                ComponentProp: (name, index) => ({
                  type: "tel",
                  disabled:
                    values?.[name as CreatePersonField.PhoneNumbers]?.[index]
                      ?.isReadOnly
                })
              }}
              toggleProps={{
                nestedKey: "value.isPreferred",
                ComponentProp: (name, index) => ({
                  type: "checkbox",
                  value: Boolean(values.phones[index]?.value?.isPreferred),
                  checked: Boolean(values.phones[index]?.value?.isPreferred),
                  hideUnderline: true
                })
              }}
              defaultEditedField={defaultEditedField}
              inputInitialValue={phoneNumberInitialValue}
            />
            <Div mt={editMode ? "m" : 0}>
              <NestedInputs
                label={messages.form.generic.emailAddresses}
                e2eTargets={e2eTargets.taggedEmails}
                inputName={CreatePersonField.EmailAddresses}
                noPrefferedHeader={
                  Boolean(values.phones.length) && !Boolean(defaultEditedField)
                }
                selectProps={{
                  nestedKey: "value.type",
                  ComponentProp: (name, index) => ({
                    value:
                      values.taggedEmails[index].value.type ||
                      EmailTypeEnums.Personal,
                    options: emailTypeOptions
                  })
                }}
                inputProps={{
                  nestedKey: "value.email",
                  onChange: (_, name, index) => {
                    setFieldValue(
                      `${name}[${index}].value.smtpValid`,
                      SMTPValid.MANUAL
                    )
                  },
                  ComponentProp: (name, index) => ({
                    onBlur: checkPersonsExist?.(values),
                    type: "email",
                    disabled:
                      values?.[name as CreatePersonField.EmailAddresses]?.[
                        index
                      ]?.isReadOnly,

                    sufix: () => (
                      <SmtpValidBadge
                        smtpValid={
                          values?.[name as CreatePersonField.EmailAddresses]?.[
                            index
                          ]?.value?.smtpValid
                        }
                      />
                    )
                  })
                }}
                toggleProps={{
                  nestedKey: "value.isPreferred",
                  ComponentProp: (name, index) => ({
                    type: "checkbox",
                    value: Boolean(
                      values.taggedEmails[index].value.isPreferred
                    ),
                    checked: Boolean(
                      values.taggedEmails[index].value.isPreferred
                    ),
                    hideUnderline: true
                  })
                }}
                defaultEditedField={defaultEditedField}
                inputInitialValue={{
                  value: { email: "", smtpValid: "" },
                  isReadOnly: false
                }}
              />
            </Div>
            {formActionsComponent(formik)}
          </Form>
        )
      }}
    </Formik>
  )
}
