import { ModalNames } from "setup/modal/modal.definitions"
import { ReactPortal } from "react"
import { Nullable } from "tsdef"

export type SingleModalEntry = {
  isOpen: boolean
  element: JSX.Element
}

export type OpenModals = {
  [name: string]: SingleModalEntry
}

type ModalInfo = {
  modalName: string
  message: string
  payload?: any
  [key: string]: any
}

export type ModalState = {
  isOpen: boolean
  isOpenInPortal: boolean
  isCloseEnabled: boolean
  activeModal: string
  openModals: OpenModals
  skipped: string
  modalError: ModalInfo | null
  modalSuccess: ModalInfo | null
}

export type ModalContextValues = ModalState & {
  openInPortal: OpenModal
  renderModalInPortal: () => Nullable<ReactPortal>
  open: OpenModal
  close: CloseModal
  closeAll: CloseAllModals
  onClose: OnClose
  removeOnCloseCallback: RemoveOnCloseCallback
  skip: OnSkip
  setModalError: OnError
  setModalSuccess: OnSuccess
}

export enum ModalActionTypes {
  Open = "open",
  Close = "close",
  CloseAll = "close-all",
  Skip = "skip",
  SetModalError = "setModalError",
  SetModalSuccess = "setModalSuccess",
  OpenInPortal = "openInPortal"
}

export type ModalAction = {
  type: ModalActionTypes
  payload?: any
}

export type ModalCallback = () => void
export type ModalCallbackCollection = {
  name: string
  callback: ModalCallback
}[]
export type OpenModal = (
  name: ModalNames,
  modal: JSX.Element,
  isCloseEnabled?: boolean
) => void
export type CloseModal = (name: ModalNames) => void
export type CloseAllModals = () => void
export type OnClose = (name: ModalNames, callback: ModalCallback) => void
export type OnSkip = (name: ModalNames, callback: ModalCallback) => void
export type OnError = (payload: object | null, callback: ModalCallback) => void
export type OnSuccess = (
  payload: object | null,
  callback: ModalCallback
) => void
export type RemoveOnCloseCallback = (
  name: ModalNames,
  callback: ModalCallback
) => void
