import React, { useMemo, useState } from "react"
import { Formik, FormikProps, FormikHelpers } from "formik"
import { EditPersonExperienceFormLayout } from "./form/layout"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  Experience,
  ExperienceFormValues,
  SalaryPackageType
} from "../../person.types"
import { PersonExperienceValidationSchema } from "./form/schema"
import { CompanySelected } from "../../../assignments/components/assignment-company/types"
import { ModalFormContainer } from "../person-details-tab/components/person-details-section/form/style"
import {
  Flex,
  ModalSectionHeader,
  Button,
  SmallText
} from "@ikiru/talentis-fpc"
import { EditPersonSalaryPackage } from "./form/EditPersonSalaryPackage"
import { PersonTab } from "./PersonStep"
import { formatDateTime } from "utils/format-date"
import { isEmpty } from "lodash"
import { getChangedValues, getInitialValues } from "./helper"
import { StyledForm } from "./style"

type PersonExperienceFormProps = {
  onSubmit: (
    values: Partial<ExperienceFormValues & SalaryPackageType>,
    helpers: FormikHelpers<ExperienceFormValues & SalaryPackageType>
  ) => void
  initialValues?: Experience
  formActionsComponent?: (
    formikProps: FormikProps<ExperienceFormValues & SalaryPackageType>
  ) => JSX.Element
  isCreate?: boolean
  isCompanyEditable?: boolean
}

export const EditPersonExperience = ({
  onSubmit,
  initialValues,
  formActionsComponent,
  isCreate = false,
  isCompanyEditable = false
}: PersonExperienceFormProps) => {
  const [isSalarySelected, setIsSalarySelected] = useState(false)

  const [companyValue, setCompanyValue] = useState<CompanySelected>({
    id: initialValues?.companyId || "",
    name: initialValues?.company || ""
  })

  const { close } = useModal()

  const submitForm = (
    values: ExperienceFormValues & SalaryPackageType,
    action: FormikHelpers<ExperienceFormValues & SalaryPackageType>
  ) => {
    const changedValues = getChangedValues(initialFormValues, values)

    if (!isEmpty(changedValues.salary)) {
      localStorage.setItem("salaryCurrency", values.salary.currency)
    }

    if (!isEmpty(changedValues.package)) {
      localStorage.setItem("packageCurrency", values.package.currency)
    }

    onSubmit(
      {
        ...values,
        company: companyValue.name || "",
        companyId: companyValue.id || null
      },
      action
    )
    close(ModalNames.EditPersonExperience)
  }

  const initialFormValues = useMemo(
    () => getInitialValues(initialValues),
    [initialValues]
  )

  return (
    <ModalFormContainer defaultWidth={670}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={PersonExperienceValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <StyledForm>
            <ModalSectionHeader
              title={
                isCreate
                  ? messages.person.personTabSection.form
                      .profExperienceCreateMode
                  : messages.person.personTabSection.form.profExperienceEditMode
              }
              size="xSmall"
            />

            <Flex my="s">
              <PersonTab
                title={messages.person.experience.title}
                handleStep={() => setIsSalarySelected((pre) => !pre)}
                active={!isSalarySelected}
              />
              <PersonTab
                active={isSalarySelected}
                title={messages.person.experience.titleSalary}
                handleStep={() => setIsSalarySelected((pre) => !pre)}
              />
            </Flex>

            {isSalarySelected ? (
              <>
                <EditPersonSalaryPackage />
                {initialValues?.compensation?.updated && (
                  <Flex mt="xs">
                    <SmallText display="block" mb="none" mr="xxs">
                      {messages.offLimits.lastComment}
                      {formatDateTime(initialValues.compensation.updated)}
                    </SmallText>

                    <SmallText>
                      {` ${initialValues?.compensation?.updatedBy?.firstName} ${initialValues?.compensation?.updatedBy?.lastName}`}
                    </SmallText>
                  </Flex>
                )}
              </>
            ) : (
              <EditPersonExperienceFormLayout
                companyValue={companyValue}
                setCompanyValue={setCompanyValue}
                companyEditable={isCompanyEditable}
              />
            )}

            {formActionsComponent?.(formik)}
            <Flex justifyContent="flex-end" mt="s">
              <Button size="small" type="submit" mr="xxs">
                {messages.form.generic.save}
              </Button>
              <Button
                mode="standard-white"
                size="small"
                type="button"
                onClick={() => close(ModalNames.EditPersonExperience)}
              >
                {messages.generic.cancel}
              </Button>
            </Flex>
          </StyledForm>
        )}
      </Formik>
    </ModalFormContainer>
  )
}
