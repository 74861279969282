import React from "react"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { StyledFlex, TutorialTitles } from "./views/support/style"

interface TutorialItemProps {
  videoUrl: string
  title: string
}

export const TutorialItem = ({ videoUrl, title }: TutorialItemProps) => {
  const { open } = useModal()

  const handleVideoClick = () => {
    open(
      ModalNames.VideoExample,
      <iframe
        title={`${title} Modal`}
        src={videoUrl}
        width="900"
        height="700"
        frameBorder="0"
        allowFullScreen
      />,
      true
    )
  }

  return (
    <StyledFlex onClick={handleVideoClick}>
      <iframe
        title={title}
        src={videoUrl}
        width="200"
        height="100"
        frameBorder="0"
        style={{ pointerEvents: "none" }}
      />
      <TutorialTitles>{title}</TutorialTitles>
    </StyledFlex>
  )
}
