import React, { useCallback, useEffect, useMemo } from "react"
import { Link, Flex, getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { PersonDetails } from "views/persons/components/person-details"
import { PersonExperience } from "views/persons/components/person-experience"
import { PersonDocuments } from "views/persons/components/person-documnets"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { LinkIcon, OpenNewTabLink } from "./style"
import { PersonAssignment } from "views/persons/components/person-assignments"
import { PersonNotes } from "views/persons/components/person-notes"
import { Loader } from "components/Loader/Loader"
import { messages } from "setup/messages/messages"
import { PersonCampaign } from "views/persons/components/person-campaigns"
import { useMediaQuery } from "utils/hooks/use-media-query"
import useTabLoader from "views/persons/pages/manage-person/hooks/useTabLoader"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import PersonDetailsTab from "../person-details-tab/PersonDetailsTab"
import PersonRecordDates from "../PersonRecordDates/PersonRecordDates"
import { useChangeSearchPerson } from "./hook/useChangeSearchPerson"
import { windowOpenProfileDetails } from "./utils"
import { useSearch } from "views/search/SearchModule/context"
import PersonOtherInfo from "../person-other-info/PersonOtherInfo"
import { PersonAttributes } from "../person-keywords"
interface PersonOverlayProps {
  personId?: string
  datapoolId?: string
  isLoadingCandidates?: boolean
  project?: string
}

const PersonOverlay = ({
  personId,
  datapoolId,
  isLoadingCandidates = false,
  project
}: PersonOverlayProps) => {
  const { isLoading, getProfileData, notes, createdDate } = usePerson()

  const { selectedSearchPersonId } = useSearch()
  const {
    assignmentLength,
    assignmentProps,
    campaignLength,
    campaignProps,
    isLoadingDocuments,
    isNotesLoading,
    assignmentNoteOptions,
    campaignNoteOptions,
    noteLength,
    documentLength
  } = useTabLoader()

  const { getPersonSearch } = useChangeSearchPerson()

  const lastNoteDate = useMemo(
    () => (notes?.length ? notes[0].createdDate : undefined),
    [notes]
  )

  const fetchPersonDetails = useCallback(() => {
    if (personId) {
      getProfileData(personId)
    } else {
      getPersonSearch(datapoolId)
    }
  }, [personId, getProfileData, getPersonSearch, datapoolId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPersonDetails, [])

  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  const onOpenNewTab = (personId: string) => async () => {
    windowOpenProfileDetails(personId, project)
  }

  return (
    <Flex flexDirection="column" px="l">
      <Flex
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        mt="xs"
      >
        {(personId || selectedSearchPersonId) && (
          <OpenNewTabLink isLarge={isLarge}>
            <Link
              onClick={onOpenNewTab(personId || selectedSearchPersonId)}
              standalone
            >
              {messages.searchPerson.addPersonModal.openNewTab}
              <LinkIcon />
            </Link>
          </OpenNewTabLink>
        )}
      </Flex>
      {isLoading || isLoadingCandidates ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Loader />
        </Flex>
      ) : (
        <Flex flexWrap="wrap" mt="m" width="100%">
          <PersonDetails
            personLinkAssignmentProps={assignmentProps}
            personLinkCampaignProps={campaignProps}
            project={project}
          />
          <Tabs>
            <Tab title={messages.person.personTabHeaders.personDetails}>
              <PersonDetailsTab />
            </Tab>
            <Tab
              defaultSelected
              title={messages.person.personTabHeaders.professionalExperience}
            >
              <PersonExperience />
            </Tab>
            <Tab
              title={`${messages.person.personTabHeaders.assignment}${
                assignmentLength > 0 ? messages.person.personTabHeaders.s : ""
              } (${assignmentLength})`}
            >
              <PersonAssignment {...assignmentProps} project={project} />
            </Tab>
            <Tab
              title={`${messages.person.personTabHeaders.campaigns}${
                campaignLength > 0 ? messages.person.personTabHeaders.s : ""
              } (${campaignLength})`}
            >
              <PersonCampaign {...campaignProps} project={project} />
            </Tab>
            <Tab
              title={`${messages.person.personTabHeaders.notes}${
                noteLength > 0 ? messages.person.personTabHeaders.s : ""
              } (${noteLength})`}
            >
              <PersonNotes
                isNotesLoading={isNotesLoading}
                assignmentNoteOptions={assignmentNoteOptions}
                campaignNoteOptions={campaignNoteOptions}
              />
            </Tab>
            <Tab
              title={`${messages.person.personTabHeaders.document}${
                documentLength > 0 ? messages.person.personTabHeaders.s : ""
              } (${documentLength})`}
            >
              <PersonDocuments isLoadingDocuments={isLoadingDocuments} />
            </Tab>

            <Tab title={messages.person.personTabHeaders.attributes}>
              <PersonAttributes />
            </Tab>

            <Tab title={messages.person.personTabHeaders.other}>
              <PersonOtherInfo />
            </Tab>
          </Tabs>
          <PersonRecordDates
            createdDate={createdDate}
            lastNoteDate={lastNoteDate}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default PersonOverlay
