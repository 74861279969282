import React from "react"
import { BodyText, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { cloneDeep, get } from "lodash"
import { OverviewRow } from "./OverviewRow"
import { isEmptyData } from "../helpers"

const filterByCheckedOrDeleted = (data: any) => {
  if (Array.isArray(data)) {
    return data
      .map((item, index) => ({ ...item, prevIndex: index }))
      .filter((item) => item && (item.isChecked || item.isDeleted))
  } else if (typeof data === "object" && data !== null) {
    const result = {}
    for (const key in data) {
      if (data[key] && (data[key].isChecked || data[key].isDeleted)) {
        //@ts-ignore
        result[key] = data[key]
      }
    }
    return result
  } else {
    return data
  }
}

export const OverviewContactInfo = () => {
  const { cvParsing, setCVParsing } = usePerson()

  const filteredData: any = {}
  for (const key in cvParsing.contactInfo) {
    filteredData[key] = filterByCheckedOrDeleted(cvParsing.contactInfo[key])
  }

  const undoRemoving = (key: string, index: number) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.contactInfo[key][index].isDeleted =
      !tempCVParsing.contactInfo[key][index].isDeleted
    setCVParsing({ ...tempCVParsing })
  }

  const checkedParseField = (key: string, index: number) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.contactInfo[key][index].isChecked =
      !tempCVParsing.contactInfo[key][index].isChecked
    setCVParsing({ ...tempCVParsing })
  }

  const onChangeParsed = (date: any, index: number, type: string) => {
    const tempCVParsing = cloneDeep(cvParsing)

    if (tempCVParsing.contactInfo[type][index].hasOwnProperty("tempValue")) {
      delete tempCVParsing.contactInfo[type][index]["tempValue"]
    }

    if (type === "webSites") {
      tempCVParsing.contactInfo[type][index].parsed = { text: date }
    } else {
      tempCVParsing.contactInfo[type][index].parsed = { ...date }
    }

    setCVParsing({ ...tempCVParsing })
  }

  const saveTempValue = (date: any, index: number, type: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    if (type === "webSites") {
      tempCVParsing.contactInfo[type][index].tempValue = { text: date }
    } else {
      tempCVParsing.contactInfo[type][index].tempValue = { ...date }
    }
    setCVParsing({ ...tempCVParsing })
  }

  return (
    <Flex flexDirection="column">
      {isEmptyData(filteredData) ? (
        <BodyText pl="xxs">{messages.person.cvparsing.noChanges}</BodyText>
      ) : (
        Object.entries(filteredData).map(([key, values]) => (
          <>
            {/* @ts-ignore */}
            {values.length > 0 &&
              values.map((value: any, index: number) => {
                return (
                  <OverviewRow
                    key={key}
                    title={
                      index === 0
                        ? get(messages, `person.cvparsing.contactInfo.${key}`)
                        : ""
                    }
                    value={value.existing?.text || value.parsed?.text}
                    isDeleted={value.isDeleted}
                    bottomBorder={values.length - 1 === index}
                    valueKey={key}
                    handleDelete={() => checkedParseField(key, value.prevIndex)}
                    isPreferred={value.parsed.isPreferred}
                    undoRemoving={() => undoRemoving(key, value.prevIndex)}
                    onChangeParsed={(date) =>
                      onChangeParsed(date, value.prevIndex, key)
                    }
                    selectedValue={value.parsed.selectedValue}
                    saveTempValue={(text) =>
                      saveTempValue(text, value.prevIndex, key)
                    }
                  />
                )
              })}
          </>
        ))
      )}
    </Flex>
  )
}
