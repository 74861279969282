import React, { useState } from "react"
import { messages } from "setup/messages/messages"
import { Button, Tag, Flex } from "@ikiru/talentis-fpc"
import { FilterPopup } from "./FilterPopup"
import { tagColor } from "views/assignments/components/candidates-list/helpers"
import { ProjectRecordFilter } from "./types"
interface IProjectFiltersProps {
  appliedFilters: ProjectRecordFilter[]
  removeFilter: (filter: ProjectRecordFilter) => void
  filterComponent?: React.ReactNode
}

export const ProjectFilters = ({
  appliedFilters,
  removeFilter,
  filterComponent
}: IProjectFiltersProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onMenuOpen = () => setIsMenuOpen(true)

  const onMenuClose = () => setIsMenuOpen(false)

  return (
    <Flex alignItems="center" mt="-15px" flexWrap="wrap">
      <FilterPopup
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        filterComponent={filterComponent}
        trigger={
          <Button
            size="small"
            borderColor={isMenuOpen ? "grey.dark" : "grey.standard"}
            borderWidth="2px"
            borderStyle="solid"
            mode={isMenuOpen ? "standard-grey" : "standard-white"}
            colorway={
              isMenuOpen ? { group: "grey", variant: "dark" } : undefined
            }
            mr="xs"
            mt="xs"
          >
            + {messages.project.filters.filter}
          </Button>
        }
      />
      {appliedFilters?.map((filter: any) => (
        <Tag
          variant={tagColor(filter?.variant)}
          height={24}
          mt="xs"
          mb="0"
          key={filter.label}
          onRemoveClick={() => removeFilter(filter)}
        >
          {filter.label}
        </Tag>
      ))}
    </Flex>
  )
}
