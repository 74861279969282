import React, { useMemo } from "react"
import { Div } from "@ikiru/talentis-fpc"
import ViewableSection from "components/ViewableSection"
import { PersonSectionHeaders } from "../../style"
import { messages } from "setup/messages/messages"
import PersonDetailSectionView from "./components/PersonDetailSectionView"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import EditPersonDetails from "./form"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import useSubmitPerson from "../../hook/useSubmitPerson"
import { parseMomentDate } from "utils/moment"

const PersonDetailSection = () => {
  const { profileDetails } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()

  const initialValues = useMemo(() => {
    const {
      name,
      bio,
      firstName,
      lastName,
      middleName,
      knownAs,
      dateOfBirth,
      title,
      nationality
    } = profileDetails

    const parsedDateOfBirth = parseMomentDate(dateOfBirth)

    return {
      name,
      bio,
      firstName,
      lastName,
      middleName,
      knownAs,
      dateOfBirth: parsedDateOfBirth,
      title,
      nationality
    }
  }, [profileDetails])

  const editPersonDetail = () => {
    const data = {
      ...initialValues,
      dateOfBirth: new Date(initialValues.dateOfBirth)
        ? initialValues.dateOfBirth
        : new Date()
    }
    openModal(
      ModalNames.EditPersonDetails,
      <EditPersonDetails onSubmit={onPersonSubmit} initialValues={data} />
    )
  }

  return (
    <Div width="100%" mb="s">
      <ViewableSection
        view={<PersonDetailSectionView />}
        title={
          <PersonSectionHeaders>
            {messages.person.personTabHeaders.personDetails}
          </PersonSectionHeaders>
        }
        editFunction={editPersonDetail}
      />
    </Div>
  )
}

export default PersonDetailSection
