import React, { useEffect, useMemo } from "react"
import { BrowserExtensionContext } from "./Context"
import { useVersionChecker } from "./hooks"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga"

type BrowserExtensionProviderProps = {
  children: React.ReactNode
}

export const BrowserExtensionProvider = ({
  children
}: BrowserExtensionProviderProps) => {
  const { checkIfExtensionInstalled } = useVersionChecker()
  const location = useLocation()
  const contextValues = useMemo(() => ({}), [])

  useEffect(() => {
    checkIfExtensionInstalled()

    ReactGA.pageview(location.pathname)
    checkIfExtensionInstalled()
  }, [location, checkIfExtensionInstalled])

  return (
    <BrowserExtensionContext.Provider value={contextValues}>
      {children}
    </BrowserExtensionContext.Provider>
  )
}
