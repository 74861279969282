import { tempInnerModalStyles } from "setup/modal/modal.helpers"
import styled from "styled-components"
import styledCss from "@styled-system/css"
import { Button } from "@ikiru/talentis-fpc"

export const LicensesWarningModalWrapper = styled.div`
  ${styledCss(tempInnerModalStyles)}
  ${({ theme }) => `
  
  width: 400px;
  background: ${theme.colors.grey.light};
  padding: ${theme.space.m}px ${theme.space.m}px ${theme.space.m}px;
  border-radius: 0;
  `}
`

export const CloseButton = styled(Button)`
  position: absolute;
  right: 0;

  & svg {
    width: 70%;
    height: 70%;
  }
`
