import { apiRequest } from "setup/api/api"
import { CompanyEndpoints } from "setup/api/endpoints/endpoints"

export const createCompanyByName = async (name: string) => {
  const [, response] = await apiRequest.post({
    endpoint: CompanyEndpoints.CreateWithName,
    data: { name }
  })

  return response?.data
}
