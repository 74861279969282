import { useCallback, useEffect, useState } from "react"
import { Nullable } from "tsdef"
import { apiRequest } from "setup/api/api"
import { GatedTalent } from "setup/api/endpoints/endpoints"

type useGT2PersonParams = {
  dataPoolPersonId: string
}

const useGT2Person = ({ dataPoolPersonId }: useGT2PersonParams) => {
  const [isLoading, setIsLoading] = useState(true)
  const [gt2Person, setGt2Person] = useState<Nullable<any>>(null)

  const getGt2Person = useCallback(async () => {
    setIsLoading(true)
    const [error, response] = await apiRequest.get({
      endpoint: GatedTalent.Root,
      endpointParams: dataPoolPersonId
    })

    if (error) {
      console.log(error)
    } else {
      const data = response?.data

      setGt2Person(data)
      setIsLoading(false)
    }
  }, [dataPoolPersonId])

  const seeCVDocs = useCallback(async () => {
    const [, response] = await apiRequest.get({
      endpoint: GatedTalent.GetCvUrl,
      endpointParams: dataPoolPersonId
    })

    if (response?.data.url) {
      window.open(response?.data.url, "_blank")
    }
  }, [dataPoolPersonId])

  useEffect(() => {
    if (dataPoolPersonId) {
      getGt2Person()
    }
  }, [dataPoolPersonId, getGt2Person])

  return {
    isLoading,
    gt2Person,
    seeCVDocs
  }
}

export { useGT2Person }
