import { useState, useEffect, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { noop } from "lodash"
import { Location } from "history"

const confirm = () => new Promise((resolve) => resolve(true))

export const useCustomPrompt = ({
  onCancel = noop,
  onConfirm = confirm
}: {
  onCancel?: (location?: Location) => void
  onConfirm?: () => Promise<unknown>
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      const { pathname } = location
      const { pathname: nextPathname } = nextLocation

      if (!confirmedNavigation && nextPathname !== pathname) {
        setModalVisible(true)
        setLastLocation(nextLocation)
        return false
      }

      return true
    },
    [confirmedNavigation, location]
  )

  const handleConfirmNavigationClick = useCallback(async () => {
    const confirmSave = await onConfirm()

    if (confirmSave) {
      setModalVisible(false)
      setConfirmedNavigation(true)
    }
  }, [onConfirm])

  const closeModal = useCallback(() => {
    if (lastLocation) {
      onCancel(lastLocation)
    }
  }, [lastLocation, onCancel])

  const handleClosePress = useCallback(() => {
    setModalVisible(false)
    onCancel()
  }, [onCancel])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, navigate, lastLocation])

  return {
    closeModal,
    handleClosePress,
    handleConfirmNavigationClick,
    handleBlockedNavigation,
    modalVisible
  }
}
