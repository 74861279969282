import React from "react"
import { pick } from "lodash"
import { EditableSection } from "components/EditableSection"
import { messages } from "setup/messages/messages"
import { Campaign } from "views/campaigns/campaign.types"
import { patchCampaign } from "views/campaigns/actions"
import { TagList } from "components/Tags/TagList"
import { TagsForm } from "components/Tags/form"
import {
  TagsDataField,
  TagsPrimaryData
} from "components/Tags/constants/definitions"
import { CampaignTagsPrimaryData } from "./constants/definitions"

type CampaignTagsProps = Campaign & {
  onCampaignUpdated: (campaign: Campaign) => void
}

export const CampaignTags = React.memo(
  ({ onCampaignUpdated, ...data }: CampaignTagsProps) => {
    const campaignTags: CampaignTagsPrimaryData = pick(
      data,
      Object.values(TagsDataField)
    )

    return (
      <>
        <EditableSection
          isDefaultExpanded
          targetName={TagsDataField.Tags}
          size="xxSmall"
          header={{
            title: messages.campaign.campaignTags
          }}
          view={() => <TagList tags={campaignTags.tags} />}
          edit={(editableControls) => {
            const onSubmit = patchCampaign<TagsPrimaryData>(
              data,
              (campaign) => {
                onCampaignUpdated(campaign)
                editableControls.setViewMode()
              }
            )

            return <TagsForm initialValues={campaignTags} onSubmit={onSubmit} />
          }}
        />
      </>
    )
  }
)
