import React, { useCallback, useMemo } from "react"
import { PersonSearchResult } from "views/persons/pages/search-person/search-person/search-person.definitions"
import { Link } from "@ikiru/talentis-fpc"
import { Avatar } from "@ikiru/talentis-fpc"
import { spacing } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { ConnectedIcon } from "@ikiru/talentis-fpc"
import {
  KnownWebSite,
  knownWebSitesIcons
} from "views/persons/pages/search-person/components/PersonWebSites/definitions"
import { useNewContactModal } from "views/persons/components/contact-add-modal/hooks"
import {
  AvatarWrapper,
  PersonDetails,
  DuplicateRecordWrapper,
  TruncatedText,
  WebsiteIcon
} from "./style"

type PersonFoundRecordProps = {
  onClickCallBack?: (id: string) => void
  isActive?: boolean
  maxWidth?: string
} & PersonSearchResult

export const PersonFoundRecord = React.memo(
  ({
    id,
    name,
    jobTitle,
    company,
    location,
    linkedInProfileUrl,
    webSites = [],
    photo,
    isActive = false,
    onClickCallBack,
    maxWidth = "350px"
  }: PersonFoundRecordProps) => {
    const { closeAndRedirect } = useNewContactModal()

    const onPersonClick = useCallback(async () => {
      if (onClickCallBack) {
        onClickCallBack(id)
      } else {
        closeAndRedirect(id)
      }
    }, [closeAndRedirect, id, onClickCallBack])

    const personWebsites = useMemo(
      () =>
        (linkedInProfileUrl
          ? [{ type: "linkedIn", url: linkedInProfileUrl }, ...webSites]
          : webSites
        )
          .filter(({ type }) => Boolean(type) && type !== "other")
          .map((website) => website as KnownWebSite),
      [webSites, linkedInProfileUrl]
    )

    return (
      <DuplicateRecordWrapper
        maxWidth={maxWidth}
        isActive={isActive}
        onClick={onPersonClick}
      >
        <AvatarWrapper>
          <Avatar
            data-e2e-target-name={`person-${name}-avatar`}
            photo={photo}
            width={50}
            height={50}
            name={name}
            disableHoverOpacity
          />
        </AvatarWrapper>
        <PersonDetails>
          <Flex mb="xxs" flexWrap="nowrap">
            <TruncatedText>
              <Link mb={0} fontWeight={600} mr="xxs">
                {name}
              </Link>
            </TruncatedText>
            <ConnectedIcon width={spacing.m} />
          </Flex>
          <TruncatedText fontWeight={600}>{company}</TruncatedText>
          <TruncatedText>{jobTitle}</TruncatedText>
          <TruncatedText>{location}</TruncatedText>
        </PersonDetails>
        <Flex flexDirection="column" ml="auto">
          {personWebsites.slice(0, 2).map((item) => {
            const Icon = knownWebSitesIcons[item.type]

            return (
              <WebsiteIcon
                key={item.url}
                onClick={(e) => e.stopPropagation()}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Icon && <Icon />}
              </WebsiteIcon>
            )
          })}
        </Flex>
      </DuplicateRecordWrapper>
    )
  }
)
