import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { FormAction } from "utils/forms/form.types"
import { CreatePersonValues } from "views/persons/components/person-details/form/constants/definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnPersonDetailsSubmitType = FormAction<CreatePersonValues>

type ApiRequestType = (
  values: CreatePersonValues
) => Promise<NormalizedReturnTuple>

const onSubmit =
  (request: ApiRequestType) =>
  async (
    values: CreatePersonValues,
    helpers: FormikHelpers<CreatePersonValues>
  ) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const onCreatePersonSubmit = onSubmit((values) =>
  apiRequest.post({
    endpoint: PersonsEndpoints.Root,
    data: values
  })
)

export const sendPersonPatchRequest = (
  personId: string,
  values: CreatePersonValues
) => {
  return apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: values,
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
}

export const onEditPersonSubmit = (personId: string) =>
  onSubmit((values) => sendPersonPatchRequest(personId, values))

export const linkPersonToDatapool = (personId: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.LinkPerson,
    endpointParams: personId
  })

export const callProfileEndpoint = (personID: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.Root,
    endpointParams: personID,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

export const getPersonKeywords = (id: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.Keywords,
    endpointParams: { id: id },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

export const newValues = {
  Id: "6898ca64-7ac7-4262-83b8-fc1b58f10fe0",
  Title: "Attributes",
  Key: "0",
  Description: null,
  WhereUsed: "Hidden",
  IsAttribute: false,
  Children: [
    {
      id: "d06a8d40-52e0-4078-aa9a-35b14e552629",
      title: "Industries",
      key: "0-0",
      Description: null,
      WhereUsed: ["person", "candidate"],
      IsAttribute: true,
      children: [
        {
          title: "Procurement and Supply Chain",
          id: "d06a8d40-52e0-4078-aa9a-35b14e54444629",
          key: "0-0-1",
          children: [
            {
              title: "Logistics",
              id: "d06a8d40-52e0-4rrrrrr35b14e552629",
              key: "0-0-1-905"
            }
          ]
        },
        {
          title: "Transportation",
          id: "d06a8d40-52e0333339a222222-35b14e552629",
          key: "0-0-2",
          children: [
            {
              title: "Air Transportation",
              id: "d06a8d40-52e0333339a-35b14e552629",
              key: "0-0-2-273",
              children: [
                {
                  title: "Commercial Air Transport",
                  id: "d06a8d40-52eeeeeee0-4078-aa9a-35b14e552629",
                  key: "0-0-2-273-151"
                }
              ]
            },
            {
              title: "Marine Transportation",
              id: "d0eeeeeee6a8d40-52e0333339a-35b14e552629",
              key: "0-0-2-274",
              children: [
                {
                  title: "Commercial Shipping",
                  id: "d06a8d40-52e0-4erere078-aa9a-35b14e552629",
                  key: "0-0-2-274-151"
                },
                {
                  title: "Passenger Ferries, Cruise Shipping",
                  id: "d033336a8d40-52e0-4078-aa9a-35b14e552629",
                  key: "0-0-2-274-152"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: "848c2edc-06a7-4f61-86a2-6df4dbe2de02",
      title: "Industries Other",
      key: "0-1",
      Description: null,
      WhereUsed: ["person", "candidate", "company"],
      IsAttribute: false,
      children: [
        {
          id: "a76e2ebf-836d-48bc-b05f-251e56143093",
          title: "Building",
          key: "0-1-1",
          Description: null,
          WhereUsed: ["person", "candidate"],
          IsAttribute: true,
          children: [
            {
              title: "Building 1111",
              id: "d033336a8d40-1111-aa9a-35b14e552629",
              key: "0-1-1-2"
            }
          ]
        },
        {
          id: "ecc0f5d8-14c4-442a-9bc4-dbdd5e276836",
          title: "Legal",
          key: "0-1-2",
          Description: null,
          WhereUsed: ["person", "candidate"],
          IsAttribute: true
        }
      ]
    }
  ]
}

export const values = [
  {
    title: "Industry",
    key: "0-0",
    id: "0-0",
    children: [
      {
        title: "Technology",
        key: "0-0-1",
        id: "0-0-1",
        children: [
          {
            title: "AI",
            key: "0-0-1-1",
            id: "0-0-1-1"
          }
        ]
      }
    ]
  },
  {
    title: "Function",
    key: "0-1",
    id: "0-1",
    children: [
      {
        title: "Board",
        key: "0-1-1",
        id: "0-1-1",
        children: [
          {
            title: "Chair",
            key: "0-1-1-1",
            id: "0-1-1-1"
          },
          {
            title: "Senior NED",
            key: "0-1-1-2",
            id: "0-1-1-2"
          },
          {
            title: "NED",
            key: "0-1-1-3",
            id: "0-1-1-3"
          },
          {
            title: "Remco",
            key: "0-1-1-4",
            id: "0-1-1-4"
          },
          {
            title: "Audit Co",
            key: "0-1-1-5",
            id: "0-1-1-5"
          }
        ]
      },
      {
        title: "C-Level",
        key: "0-1-2",
        id: "0-1-2",
        children: [
          {
            title: "Chief Executive",
            key: "0-1-2-1",
            id: "0-1-2-1"
          },
          {
            title: "Chief Financial",
            key: "0-1-2-2",
            id: "0-1-2-2"
          },
          {
            title: "Chief Marketing",
            key: "0-1-2-3",
            id: "0-1-2-3"
          },
          {
            title: "Chief People",
            key: "0-1-2-4",
            id: "0-1-2-4"
          }
        ]
      },
      {
        title: "Manager",
        key: "0-1-3",
        id: "0-1-3"
      }
    ]
  },
  {
    title: "Qualifications",
    key: "0-2",
    id: "0-2"
  },
  {
    title: "Languages",
    key: "0-4",
    id: "0-4",
    children: [
      {
        title: "English",
        key: "0-4-1",
        id: "0-4-1",
        children: [
          {
            title: "English Fluent",
            key: "0-4-1-1",
            id: "0-4-1-1"
          }
        ]
      }
    ]
  }
]

export const getPersonAttributes = async (id: string) => {
  const [, response] = await apiRequest.get({
    endpoint: PersonsEndpoints.PersonAttributes,
    endpointParams: id,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return values //response?.data
}

type ApiLinkedInRequestType = (
  linkedInUrl: string
) => Promise<NormalizedReturnTuple>

const onSubmitLinkedInUrl =
  (request: ApiLinkedInRequestType) =>
  async (
    linkedInUrl: string,
    helpers: FormikHelpers<{
      linkedInUrl: {
        value: string
      }
    }>
  ) => {
    const { setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(linkedInUrl)

    setSubmitting(false)

    return [error, response]
  }

export const onAddLinkedInUrl = (personId: string) =>
  onSubmitLinkedInUrl((linkedInUrl) =>
    apiRequest.patch({
      endpoint: PersonsEndpoints.Root,
      data: { linkedInProfileUrl: linkedInUrl },
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })
  )
