import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import {
  CreatePersonField,
  personTitle,
  e2eTargets
} from "views/persons/components/person-details/form/constants/definitions"
import { StyledDiv } from "./style"
import { messages } from "setup/messages/messages"

const PersonDetailsForm = ({ values }: any) => {
  return (
    <>
      <Flex>
        <StyledDiv pl="0">
          <FormikSelect
            options={personTitle}
            data-e2e-target-name={e2eTargets.title}
            id={CreatePersonField.Title}
            name={CreatePersonField.Title}
            label={messages.form.generic.title}
          />
        </StyledDiv>
        <StyledDiv pr="0">
          <FormikInput
            id={CreatePersonField.Name}
            name={`${CreatePersonField.Name}.value`}
            label={messages.form.generic.name}
            value={values.name?.value || ""}
          />
        </StyledDiv>
      </Flex>
      <Flex>
        <StyledDiv pl="0">
          <FormikInput
            data-e2e-target-name={e2eTargets.firstName}
            id={CreatePersonField.FirstName}
            name={CreatePersonField.FirstName}
            label={messages.form.generic.firstName}
          />
        </StyledDiv>
        <StyledDiv pr="0">
          <FormikInput
            data-e2e-target-name={e2eTargets.lastName}
            id={CreatePersonField.LastName}
            name={CreatePersonField.LastName}
            label={messages.form.generic.lastName}
          />
        </StyledDiv>
      </Flex>
      <Flex>
        <StyledDiv pl="0">
          <FormikInput
            data-e2e-target-name={e2eTargets.middleName}
            id={CreatePersonField.MiddleName}
            name={CreatePersonField.MiddleName}
            label={messages.form.generic.middleName}
          />
        </StyledDiv>
        <StyledDiv pr="0">
          <FormikInput
            data-e2e-target-name={e2eTargets.knownAs}
            id={CreatePersonField.KnownAs}
            name={CreatePersonField.KnownAs}
            label={messages.form.generic.knownAs}
          />
        </StyledDiv>
      </Flex>
      <Flex>
        <StyledDiv pl="0">
          <FormikInput
            data-e2e-target-name={e2eTargets.nationality}
            id={CreatePersonField.Nationality}
            name={CreatePersonField.Nationality}
            label={messages.form.generic.nationality}
          />
        </StyledDiv>
        <StyledDiv pr="0">
          <FormikDatepicker
            data-e2e-target-name={e2eTargets.dateOfBirth}
            id={CreatePersonField.DateOfBirth}
            name={CreatePersonField.DateOfBirth}
            label={messages.form.generic.dateOfBirth}
          />
        </StyledDiv>
      </Flex>
    </>
  )
}

export default PersonDetailsForm
