import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { CompanyEndpoints } from "setup/api/endpoints/endpoints"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type ApiRequestType = (
  values: { linkedInUrl: { value: string } },
  companyId: string
) => Promise<NormalizedReturnTuple>

const onSubmit =
  (request: ApiRequestType) =>
  async (
    values: { linkedInUrl: { value: string } },
    companyId: string,
    helpers: FormikHelpers<{ linkedInUrl: { value: string } }>
  ) => {
    const { setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values, companyId)

    setSubmitting(false)
    // callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const linkCompanyToDatapool = () =>
  onSubmit((values, companyId) =>
    apiRequest.post({
      endpoint: CompanyEndpoints.LinkToDataPool,
      data: { linkedInUrl: values.linkedInUrl.value, companyId },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })
  )
