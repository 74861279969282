import React from "react"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { COMPANIES_LIMIT } from "views/search/SearchModule/consts"
import { useSearch } from "views/search/SearchModule/context"
import { FilterType } from "views/search/SearchModule/types"
import { SearchCriteriaField } from "../definitions"
import { useArrayFieldChange } from "../hooks"
import { CurrentPastBothToggle } from "../components/CurrentPastBothToggle"

export const CompanyFilter = () => {
  const { filters, removeArrayBasedFilter } = useSearch()

  const onKeyPress = useArrayFieldChange({
    field: SearchCriteriaField.Company,
    filterType: FilterType.Companies,
    limit: COMPANIES_LIMIT
  })

  const onBlur = useArrayFieldChange({
    field: SearchCriteriaField.Company,
    filterType: FilterType.Companies,
    limit: COMPANIES_LIMIT,
    isOnBlur: true
  })

  return (
    <Div width="100%">
      <FormikInput
        name={SearchCriteriaField.Company}
        label={messages.talentGraphSearch.filters.company}
        placeholder={messages.talentGraphSearch.placeholders.companyNames}
        onKeyPress={
          onKeyPress as (e: React.KeyboardEvent<HTMLInputElement>) => void
        }
        onBlur={onBlur as (e: React.FocusEvent<HTMLInputElement>) => void}
        disableDefaultOnBlur
      />
      <KeywordsList
        keywords={filters?.[FilterType.Companies] || []}
        onKeywordRemove={(value) =>
          removeArrayBasedFilter(FilterType.Companies, value)
        }
      />
      <CurrentPastBothToggle
        fieldName={SearchCriteriaField.CompanyLogic}
        filterType={FilterType.CompanyLogic}
      />
    </Div>
  )
}
