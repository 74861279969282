import React from "react"
import { TaskManagementModule } from "views/homepage/task-management-module/TaskManagementModule"
import TaskManagement from "./index"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"

export const TaskManagementWrapper = () => (
  <TaskManagementModule>
    <PersonProfileWrapper>
      <TaskManagement />
    </PersonProfileWrapper>
  </TaskManagementModule>
)
