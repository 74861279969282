import React from "react"
import { Formik, Form } from "formik"
import { messages } from "setup/messages/messages"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"
import { PersonConsentsProps } from "./types"
import { usePersonConsentOptions } from "./hooks"
import { gdprLawfulBasisOptions } from "./constants/gdpr-lawful-basis"
import { PersonConsentsField } from "./constants/definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"

export const PersonConsentsForm = (props: PersonConsentsProps) => {
  const { formActionsComponent, onSubmit, initialConsentState } = props

  const {
    validationSchema,
    initialValues,
    onLawfulBasisChange,
    availableGDPRStatuses,
    shouldRenderStatuses
  } = usePersonConsentOptions(initialConsentState)

  const personConsentsData = {
    ...initialValues,
    gdprExpiration:
      initialValues.gdprExpiration && new Date(initialValues.gdprExpiration)
  }

  return (
    <ModalFormContainer defaultWidth={480}>
      <Formik
        initialValues={personConsentsData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            {formActionsComponent(formik)}
            <FormikSelect
              options={gdprLawfulBasisOptions}
              id={PersonConsentsField.LawfulBasis}
              name={PersonConsentsField.LawfulBasis}
              onChange={onLawfulBasisChange}
              data-e2e-target-name={PersonConsentsField.LawfulBasis}
              label={messages.person.consents.form.lawfulBasisLabel}
            />
            {shouldRenderStatuses && (
              <FormikSelect
                options={availableGDPRStatuses}
                id={PersonConsentsField.Status}
                name={PersonConsentsField.Status}
                data-e2e-target-name={PersonConsentsField.Status}
                label={messages.person.consents.form.statusLabel}
              />
            )}
            <FormikInput
              id={PersonConsentsField.DataOrigin}
              name={PersonConsentsField.DataOrigin}
              data-e2e-target-name={PersonConsentsField.DataOrigin}
              label={messages.person.consents.form.dataOriginLabel}
            />
            <FormikDatepicker
              id={PersonConsentsField.GdprExpiration}
              name={PersonConsentsField.GdprExpiration}
              label={messages.person.consents.form.gdprExpiration}
            />
            <FormikTextarea
              rows={3}
              id={PersonConsentsField.GdprComment}
              name={PersonConsentsField.GdprComment}
              data-e2e-target-name={PersonConsentsField.GdprComment}
              label={messages.person.consents.form.gdprComment}
            />
          </Form>
        )}
      </Formik>
    </ModalFormContainer>
  )
}
