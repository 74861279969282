import React from "react"
import { externalLinks } from "setup/externalLinks"
import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber
} from "@chargebee/chargebee-js-react-wrapper"
import { messages } from "setup/messages/messages"
import { ChargebeeInput } from "../ChargebeeInput/ChargebeeInput"
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup"

interface ChargebeeCardDetailsProps {
  cardComponentRef: React.MutableRefObject<ChargebeeComponents>
}

export const ChargebeeCardDetails = (props: ChargebeeCardDetailsProps) => {
  const { cardComponentRef } = props
  const chargebeeFonts = [externalLinks.typekitCss]

  return (
    <CardComponent ref={cardComponentRef} icon={false} fonts={chargebeeFonts}>
      <ChargebeeInput
        label={messages.subscription.paymentDetails.cardNumber}
        Component={CardNumber}
      />
      <ChargebeeInput
        label={messages.subscription.paymentDetails.expiryDate}
        Component={CardExpiry}
      />
      <ChargebeeInput
        label={messages.subscription.paymentDetails.securityCode}
        Component={CardCVV}
      />
    </CardComponent>
  )
}
