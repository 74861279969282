import styled from "styled-components"
import { Button } from "@ikiru/talentis-fpc"

type Props = {
  isLight?: boolean
  noPadding?: boolean
}

type ButtonProps = {
  top?: number | string
  right?: number | string
  position?: string
}

export const ConfirmationModalWrapper = styled.div<Props>`
  background: ${({ theme, isLight }) =>
    isLight ? theme.colors.grey.lightest : theme.colors.grey.light};
  padding: ${({ theme, noPadding }) => (noPadding ? 0 : theme.space.m)}px;
  width: 100svw;
  height: 100svh;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      width: auto;
      height: auto;
      min-height: 320px;
      max-width: 390px;
    }
  `}
`

export const CloseButton = styled(Button)<ButtonProps>`
  position: ${({ position }) => position || "absolute"};
  top: ${({ theme, top }) => top || theme.space.xs}px;
  right: ${({ theme, right }) => right || theme.space.xs}px;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      top: ${theme.space.m}px;
      right: ${theme.space.m}px;
    }
  `}
`
