import React from "react"
import { Flex, SmallText, colors } from "@ikiru/talentis-fpc"
import moment from "moment"
import { messages } from "setup/messages/messages"
import { PersonDateContainer } from "./style"

type PersonRecordDatesProps = {
  createdDate: string | Date | null
  lastNoteDate?: string | Date
}

const PersonRecordDates = ({
  createdDate,
  lastNoteDate
}: PersonRecordDatesProps) => {
  return (
    <PersonDateContainer>
      {createdDate && (
        <Flex mr={lastNoteDate ? "s" : "none"}>
          <SmallText mr="xxs" color={colors.grey.standard}>
            {messages.person.details.createdDate}
          </SmallText>
          <SmallText color={colors.grey.standard}>
            {moment(createdDate).format("DD/MM/YYYY")}
          </SmallText>
        </Flex>
      )}
      {lastNoteDate && (
        <Flex>
          <SmallText mr="xxs" color={colors.grey.standard}>
            {messages.person.details.latestNoteDate}
          </SmallText>
          <SmallText color={colors.grey.standard}>
            {moment(lastNoteDate).format("DD/MM/YYYY")}
          </SmallText>
        </Flex>
      )}
    </PersonDateContainer>
  )
}

export default PersonRecordDates
