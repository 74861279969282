import { FilterType } from "views/search/SearchModule/types"

export const KEYWORDS_BOXES_LIMIT = 5

export const initialTemplateString = `${FilterType.KeywordBundle}[{0}]`
export const keywordsTemplateString = `${FilterType.KeywordBundle}[{0}].keywords`
export const keywordsLogicTemplateString = `${FilterType.KeywordBundle}[{0}].keywordsSearchUsingORLogic`
export const keywordsRolesLogicTemplateString = `${FilterType.KeywordBundle}[{0}].keywordsSearchLogic`
export const keywordsRecordPersonTemplateString = `${FilterType.KeywordBundle}[{0}].keywordsSearchRecordPerson`
export const keywordsRecordCompanyTemplateString = `${FilterType.KeywordBundle}[{0}].keywordsSearchRecordCompany`
export const keywordsRecordPersonRegExTemplateString = `${FilterType.KeywordBundle}[[0-9]{1,}].keywordsSearchRecordPerson`
export const keywordsRecordCompanyRegExTemplateString = `${FilterType.KeywordBundle}[[0-9]{1,}].keywordsSearchRecordCompany`
