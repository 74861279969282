import React from "react"
import {
  Formik,
  Form,
  FormikHelpers,
  FormikProps,
  FieldArray,
  Field,
  FieldProps
} from "formik"
import {
  TagsPrimaryData,
  tagsPrimaryDataInitialValues,
  TagsDataField
} from "components/Tags/constants/definitions"
import { TagsPrimaryValidationSchema } from "components/Tags/constants/schema"
import { StyledInput } from "./style"
import { Error, BodyText, Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { getChangedValues } from "views/assignments/utils"
import { isEmpty } from "lodash"
import { Tag } from "components/Tags/tags.types"

type TagsFormProps = {
  onSubmit: (
    values: TagsPrimaryData,
    helpers: FormikHelpers<TagsPrimaryData>
  ) => void
  initialValues?: TagsPrimaryData
  formActionsComponent?: (
    formikProps: FormikProps<TagsPrimaryData>
  ) => JSX.Element
}

export const TagsForm = ({
  onSubmit,
  initialValues,
  formActionsComponent
}: TagsFormProps) => {
  const { trackAssignmentTags } = useTelemetry()

  const submitForm = (
    values: TagsPrimaryData,
    action: FormikHelpers<TagsPrimaryData>
  ) => {
    const changeValues: Tag[] = []
    values?.tags?.forEach((tag, index) => {
      if (!isEmpty(getChangedValues(tag, initialValues?.tags?.[index]))) {
        changeValues.push(tag)
      }
    })
    if (changeValues.length) trackAssignmentTags(changeValues)
    onSubmit(values, action)
  }

  return (
    <>
      <BodyText>{messages.tags.editTags}</BodyText>
      <Formik
        initialValues={{
          ...tagsPrimaryDataInitialValues,
          ...initialValues
        }}
        validationSchema={TagsPrimaryValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          const { values } = formik
          return (
            <Form>
              <FieldArray
                name={TagsDataField.Tags}
                render={(arrayHelpers) => (
                  <>
                    {values.tags.map((tag, index) => (
                      <Field
                        name={`${TagsDataField.Tags}[${index}].name`}
                        key={tag.id}
                      >
                        {({ field, meta }: FieldProps) => {
                          return (
                            <Div>
                              <StyledInput
                                data-lpignore
                                {...field}
                                name={`${TagsDataField.Tags}[${index}].name`}
                                placeholder={field.value}
                                color={tag.color}
                                background={tag.background}
                                onClick={(e) => {
                                  const eventTarget =
                                    e.target as HTMLInputElement
                                  eventTarget.select()
                                }}
                              />
                              {meta.error && <Error>{meta.error}</Error>}
                            </Div>
                          )
                        }}
                      </Field>
                    ))}
                  </>
                )}
              />
              {formActionsComponent?.(formik)}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
