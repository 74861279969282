import React from "react"
import {
  DragContentItem,
  OnDropProps
} from "components/FPCComponents/DragAndDrop/CustomDnD/DropContentItem/DragContentItem"
import { DraggableItemContainer } from "./style"
import { DragType, DraggableDataName } from "../../constants/definition"
import { usePerson } from "views/persons/person-module/candiate-module.context"

interface ParsedDragItemWrapperProps {
  children: JSX.Element
  componentName: DraggableDataName
  dragType: DragType
  setItems: (transferData: OnDropProps) => void
  id: string
  content: string
}

const ParsedDragItemWrapper = (props: ParsedDragItemWrapperProps) => {
  const { children, componentName, setItems, id, content, dragType } = props
  const { setCurrentHoveredItem } = usePerson()

  return (
    <DraggableItemContainer
      onMouseOver={() => setCurrentHoveredItem(componentName)}
      onMouseOut={() => setCurrentHoveredItem("")}
    >
      <DragContentItem
        onDropCallback={setItems}
        dataId={id}
        dragType={dragType}
        content={content}
        objectName={componentName}
      >
        {children}
      </DragContentItem>
    </DraggableItemContainer>
  )
}

export default ParsedDragItemWrapper
