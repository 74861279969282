import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"

export const generateAIBio = async (
  personId: string,
  isShort: boolean,
  language: string
) => {
  const [, response] = await apiRequest.get({
    endpoint: PersonsEndpoints.GenerateAiBio,
    endpointParams: {
      personId,
      isShort: isShort ? "true" : "false",
      Language: language
    }
  })

  return response?.data
}

export const saveNewBio = async (bio: string, personId: string) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: { bio },
    endpointParams: personId
  })
  return response?.data
}
