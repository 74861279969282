import { GDPRLawfulBasis } from "views/persons/components/person-consents/form/constants/gdpr-lawful-basis"
import { messages } from "setup/messages/messages"

export type GDPRStatusOption = {
  label: string
  value: GDPRStatuses
}

export enum GDPRStatuses {
  NotStarted = "notStarted",
  ConsentRequestSent = "consentRequestSent",
  ConsentGiven = "consentGiven",
  ConsentRefused = "consentRefused",
  NotificationSent = "notificationSent",
  Objected = "objected"
}

const gdprStatusOptions = messages.person.consents.form.options.status

const notStarted: GDPRStatusOption = {
  label: gdprStatusOptions.notStarted,
  value: GDPRStatuses.NotStarted
}
const consentRequestSent: GDPRStatusOption = {
  label: gdprStatusOptions.consentRequestSent,
  value: GDPRStatuses.ConsentRequestSent
}

const consentGiven: GDPRStatusOption = {
  label: gdprStatusOptions.consentGiven,
  value: GDPRStatuses.ConsentGiven
}

const consentRefused: GDPRStatusOption = {
  label: gdprStatusOptions.consentRefused,
  value: GDPRStatuses.ConsentRefused
}

const notificationSent: GDPRStatusOption = {
  label: gdprStatusOptions.notificationSent,
  value: GDPRStatuses.NotificationSent
}

const objected: GDPRStatusOption = {
  label: gdprStatusOptions.objected,
  value: GDPRStatuses.Objected
}

const consentStatuses = [
  notStarted,
  consentRequestSent,
  consentGiven,
  consentRefused
]

const interestStatuses = [notStarted, notificationSent, objected]

export const GDPRStatusesForGivenLawfulBasis = new Map([
  [GDPRLawfulBasis.None, []],
  [GDPRLawfulBasis.NotRequired, []],
  [GDPRLawfulBasis.VerbalConsent, consentStatuses],
  [GDPRLawfulBasis.EmailConsent, consentStatuses],
  [GDPRLawfulBasis.DigitalConsent, consentStatuses],
  [GDPRLawfulBasis.LegitimateInterest, interestStatuses]
])
