import styled from "styled-components"
import { colors, DropdownArrowIcon, Flex } from "@ikiru/talentis-fpc"

type DropDownIconStyledProps = {
  up?: boolean
}

export const DropDownIconStyled = styled(
  DropdownArrowIcon
)<DropDownIconStyledProps>`
  width: 10px;
  height: 10px;
  ${({ up }) => up && `transform: rotate(180deg)`};
  path {
    fill: ${colors.white.standard};
  }
`

export const StyledAccordion = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
`

export const HeaderWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  background: ${colors.green.dark};
  padding-left: 20px;
  padding-right: 20px;
`

export const BodyAccordion = styled(Flex)`
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`
