import React, { useCallback, useEffect, useState } from "react"
import { Formik } from "formik"
import { messages } from "setup/messages/messages"
import {
  Button,
  Div,
  Flex,
  H5,
  Loader,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { StyledParseTextForm } from "./styles"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"
import {
  ParseTextField,
  initialParseText,
  parseTextValidationSchema
} from "../definitions"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import {
  isEmptyPersonRecord,
  isObjectEmptyAIResult,
  parsePersonWithAI
} from "../../cv-parsing/helpers"
import {
  CVProfessionalExperience,
  CVSkills,
  Person
} from "views/persons/person.types"
import { getCVExperience } from "../../person-details/utils/get-cv-experience"
import { getNormalizedCvSkill } from "../../person-details/utils/get-cv-skills"
import { getNormalizedCVPersonDetails } from "../../person-details/utils/get-cv-person-details"
import { NetworkIssue } from "../../cv-parsing/NetworkIssue"
import { useWindowSize } from "utils/hooks/use-window-size"
import { NoCVParsing } from "../../cv-parsing/NoCVParsing"
import { ParseTextAIIssue } from "./ParseTextAIIssue"
import { OverviewCVModal } from "../../cv-parsing/overview/OverviewCVModal"
import { ParsingResumeModal } from "../../cv-parsing/parsing/ParsingResumeModal"

export const ParseText = () => {
  const [isParsing, setIsParsing] = useState(false)
  const [isNetworkIssue, setIsNetworkIssue] = useState(false)
  const [isParsingAIIssue, setIsParsingAIIssue] = useState(false)
  const [isSmallSize, setIsSmallSize] = useState(false)

  const { width } = useWindowSize()

  useEffect(() => {
    if (width && width > 990) {
      setIsSmallSize(false)
    } else {
      setIsSmallSize(true)
    }
  }, [width])

  const {
    profileDetails,
    setCVParsing,
    cvParsing,
    person,
    keywords,
    setTempCvParsingData
  } = usePerson()

  const { close, openInPortal, open } = useModal()

  const getParseCVData = useCallback(
    (parsePerson: Person) => {
      let experienceData: CVProfessionalExperience = null!
      let parsedSkills: CVSkills = null!

      const isEmptyProfile = isEmptyPersonRecord(profileDetails)

      experienceData = getCVExperience(parsePerson, person, isEmptyProfile)

      parsedSkills = getNormalizedCvSkill(
        parsePerson,
        person,
        keywords,
        isEmptyProfile
      )

      const { cvPersonDetails, cvContactDetails } =
        getNormalizedCVPersonDetails(
          parsePerson,
          profileDetails,
          isEmptyProfile
        )

      setCVParsing({
        ...cvParsing,
        personDetails: cvPersonDetails,
        contactInfo: cvContactDetails,
        professionalExperience: experienceData,
        skills: parsedSkills
      })

      setTempCvParsingData(parsePerson)

      const isParsingStop = localStorage.getItem("isCancelParsing")

      if (isParsingStop !== "true") {
        setIsParsing(false)
        close(ModalNames.ParseText)

        if (isEmptyPersonRecord(profileDetails)) {
          open(ModalNames.OverviewCVModal, <OverviewCVModal />)
        } else {
          openInPortal(ModalNames.ParsingResume, <ParsingResumeModal />)
        }
      }
    },
    [
      profileDetails,
      setCVParsing,
      cvParsing,
      setTempCvParsingData,
      person,
      close,
      openInPortal,
      open,
      keywords
    ]
  )

  const submitForm = async (values: { [ParseTextField.ParseText]: string }) => {
    setIsParsing(true)
    const { response } = await parsePersonWithAI(values.parseText)
    const { createdDate, ...aiResult } = response?.data

    if (isObjectEmptyAIResult(aiResult)) {
      setIsParsing(false)
      setIsParsingAIIssue(true)
    } else if (response?.data) {
      getParseCVData(response?.data)
    } else {
      setIsNetworkIssue(true)
    }
  }

  const closeParseText = () => {
    close(ModalNames.ParseText)
  }

  return (
    <ModalFormContainer defaultWidth={670}>
      <Formik
        onSubmit={submitForm}
        initialValues={initialParseText}
        validationSchema={parseTextValidationSchema}
      >
        {({ isSubmitting, dirty, isValid, resetForm }) => {
          return (
            <StyledParseTextForm>
              <ModalSectionHeader
                title={messages.person.experience.parseTextModalTitle}
                size="xSmall"
              />
              {isParsingAIIssue ? (
                <ParseTextAIIssue
                  closeModal={closeParseText}
                  handleTryAgain={() => {
                    setIsParsing(false)
                    setIsParsingAIIssue(false)
                  }}
                />
              ) : (
                <>
                  {isSmallSize ? (
                    <NoCVParsing />
                  ) : isNetworkIssue ? (
                    <NetworkIssue
                      handleTryAgain={() => {
                        setIsNetworkIssue(false)
                        setIsParsing(false)
                      }}
                    />
                  ) : (
                    <>
                      {isParsing ? (
                        <Flex
                          flexDirection="column"
                          mt="70px"
                          alignItems="center"
                        >
                          <Loader />
                          <H5>
                            {messages.person.cvparsing.parsingPersonWithAI}
                          </H5>
                        </Flex>
                      ) : (
                        <>
                          <Div mb="m" mt="m">
                            <FormikTextarea
                              id={ParseTextField.ParseText}
                              name={`${ParseTextField.ParseText}`}
                              label={
                                messages.person.experience
                                  .parseTextModalPlaceholder
                              }
                              rows={10}
                              isMicrophone
                            />
                          </Div>
                          <Flex mb="m" justifyContent="flex-end">
                            <Button
                              type="submit"
                              size="small"
                              mr="xs"
                              disabled={isSubmitting || !isValid || !dirty}
                            >
                              {messages.person.experience.parseTextButton}
                            </Button>
                            <Button
                              mode="standard-white"
                              size="small"
                              disabled={isSubmitting}
                              type="button"
                              onClick={() => {
                                closeParseText()
                                resetForm()
                              }}
                            >
                              {messages.generic.cancel}
                            </Button>
                          </Flex>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </StyledParseTextForm>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
