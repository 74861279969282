import React from "react"
import { messages } from "setup/messages/messages"
import { BodyText, H3, H5, SmallText } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { Tag } from "@ikiru/talentis-fpc"
import { TrialDetails } from "../../definitions"
import { useTrialPlanDetails } from "./hooks"

interface TrialPlanDetailsProps {
  trialPlanDetails: TrialDetails
}

export const TrialPlanDetails = (props: TrialPlanDetailsProps) => {
  const { formattedEndDate, daysRemaining } = useTrialPlanDetails(
    props.trialPlanDetails
  )

  return (
    <>
      <H3 m="0" color="green.dark">
        {messages.billing.currentPlan.freeTrial}
      </H3>
      <Flex alignItems="center">
        <H5 my="xs" mr="s">
          {messages.billing.currentPlan.endDate.format(formattedEndDate)}
        </H5>
        {daysRemaining !== null && (
          <Tag variant="orange" height="24px" m="0">
            {messages.billing.currentPlan.daysRemaining.format(daysRemaining)}
          </Tag>
        )}
      </Flex>
      <BodyText>{messages.billing.currentPlan.afterYourTrial}</BodyText>
      <SmallText>{messages.billing.currentPlan.dataDeleted}</SmallText>
    </>
  )
}
