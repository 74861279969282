import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import {
  EditPersonExperienceField,
  EditPersonSalaryPackageField
} from "../../../person.types"

export const PersonExperienceValidationSchema = Yup.object({
  [EditPersonExperienceField.Title]: Yup.string()
    .max(100, messages.form.generic.errors.mustBeNoLongerThan.format(100))
    .required(messages.generic.required),
  [EditPersonSalaryPackageField.Comment]: Yup.string().max(
    500,
    messages.form.generic.errors.mustBeNoLongerThan.format(500)
  )
})
