import React, { useMemo } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Button, ModalSectionHeader } from "@ikiru/talentis-fpc"
import {
  PersonCampaigns,
  personCampaignsE2ETargets,
  getPersonCampaignsInitialValues
} from "views/persons/components/person-campaigns/form/constants/add-person-campaign.definitions"
import { messages } from "setup/messages/messages"
import { OnAddPersonCampaign } from "views/persons/components/person-campaigns/form/actions"
import { PersonCampaignOption } from "views/persons/components/person-campaigns/types"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"

type AddPersonCampaignProps = {
  onSubmit: OnAddPersonCampaign
  campaigns: PersonCampaignOption[]
  isLoading: boolean
  project?: string
}

export const AddPersonCampaign = (props: AddPersonCampaignProps) => {
  const { campaigns, onSubmit, isLoading, project } = props

  const availableCampaigns = campaigns?.filter((campaign) => !campaign.disabled)

  const projectStorageKey = useMemo(() => {
    if (project) return `${project}Campaign`
    const urlParams = new URLSearchParams(window.location.search)
    const projectUrl = urlParams.get("project")

    return `${projectUrl}Campaign`
  }, [project])

  const storedValue = localStorage.getItem(projectStorageKey)
  const projectValue =
    storedValue !== null && storedValue !== "null" ? storedValue : ""

  const initialValues = getPersonCampaignsInitialValues(
    projectValue ||
      (availableCampaigns.length === 1 ? availableCampaigns[0].value : "")
  )

  const { close } = useModal()
  const closeModal = () => close(ModalNames.LinkAssignmentOrCampaign)

  const campaignsExist = campaigns.length

  const options = isLoading
    ? [{ label: messages.generic.loading }]
    : !campaignsExist
    ? [{ label: messages.person.campaigns.noCampaignsMessage }]
    : availableCampaigns.length > 1
    ? [
        {
          label: messages.person.campaigns.selectCampaign,
          value: "",
          disabled: true
        },
        ...campaigns
      ]
    : campaigns

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    localStorage.setItem(projectStorageKey, values.campaigns)
    onSubmit(values, actions)
  }

  return (
    <ModalFormContainer defaultWidth={480}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitForm}
      >
        {({ isSubmitting, values }) => {
          const actionPending = isLoading || isSubmitting
          const disableButton =
            actionPending ||
            !campaignsExist ||
            !availableCampaigns?.length ||
            !values.campaigns
          const disableSelect = actionPending || !campaignsExist
          return (
            <Form>
              <ModalSectionHeader
                title={messages.person.campaigns.linkCampaign}
                size="xSmall"
                actions={
                  <>
                    <Button
                      disabled={disableButton}
                      size="small"
                      type="submit"
                      mr="xxs"
                      data-e2e-target={personCampaignsE2ETargets.submitButton}
                    >
                      {messages.person.campaigns.linkToCampaign}
                    </Button>
                    <Button
                      mode="standard-white"
                      size="small"
                      type="button"
                      onClick={closeModal}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </>
                }
              />
              <FormikSelect
                status="default"
                label={messages.person.campaigns.campaingName}
                id={PersonCampaigns.Campaigns}
                name={PersonCampaigns.Campaigns}
                data-e2e-target-name={personCampaignsE2ETargets.campaignsSelect}
                options={options}
                disabled={disableSelect}
              />
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
