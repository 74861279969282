import React from "react"
import { Formik, Form } from "formik"
import { addNoteValidationSchema } from "./schema"
import { NoteAddFormProps } from "./types"
import NoteFormLayout from "./NoteFormLayout"
import { initialDefaultNoteState } from "./definitions"
import { StyledDiv } from "../style"

const NoteAddForm = ({ formActionsComponent, onSubmit }: NoteAddFormProps) => {
  return (
    <StyledDiv>
      <Formik
        initialValues={initialDefaultNoteState}
        validationSchema={addNoteValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              {formActionsComponent?.(formik)}
              <NoteFormLayout />
            </Form>
          )
        }}
      </Formik>
    </StyledDiv>
  )
}

export default React.memo(NoteAddForm)
