import React from "react"
import styled from "styled-components"
import {
  BodyText,
  DropdownArrowIcon,
  Button,
  StyledSystemProps,
  Flex
} from "@ikiru/talentis-fpc"

type isForDescription = {
  isLongDescription: boolean
}

type BodyTextStyledProps = {
  expanded: boolean
  visibleLines: number
  ref?: React.MutableRefObject<HTMLParagraphElement | undefined>
  noExtraInfo: boolean
  isSmall?: boolean
}

type DropDownIconStyledProps = {
  up?: boolean
}

type HasBackground = {
  hasBackground: boolean
  dropDownBottomPosition?: number
}

export const ShowMoreContainer = styled(Flex)<isForDescription>`
  justify-content: space-between;
  align-items: flex-end;
  height: ${({ isLongDescription }) => (isLongDescription ? null : "20px")};
`

export const SpanStyled = styled.span<{ isSmall?: boolean }>`
  font-weight: 600;
  ${({ isSmall }) => isSmall && `font-size: 13px`};
`

export const BodyTextStyled = styled(BodyText)<BodyTextStyledProps>`
  display: -webkit-box;
  white-space: pre-line;
  ${({ expanded, visibleLines }) =>
    !expanded && `-webkit-line-clamp: ${visibleLines};`};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  width: ${({ noExtraInfo }) => (noExtraInfo ? "100%" : "")};
  ${({ isSmall }) => isSmall && `font-size: 13px`};
`

export const DropDownIconStyled = styled(
  DropdownArrowIcon
)<DropDownIconStyledProps>`
  ${({ up }) => up && `transform: rotate(180deg)`};
`

export const ButtonStyled = styled(Button)<StyledSystemProps & HasBackground>`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${({ theme, hasBackground }) =>
    hasBackground ? theme.colors.white.standard : "transparent"};

  &:hover {
    background-color: ${({ hasBackground }) => !hasBackground && "transparent"};
  }
`
