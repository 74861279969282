import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"
import styled from "styled-components"
import styledCss from "@styled-system/css"
import { Form } from "formik"

export const SaveSearchModalWrapper = styled.div`
  ${styledCss(tempInnerGreyModalStyles)}
  width: 480px;
`

export const SaveForm = styled(Form)`
  width: 400px;
`
