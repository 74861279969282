import React, { useCallback, useReducer, useRef } from "react"
import { ModalContext } from "setup/modal/modal.context"
import { modalReducer, initialModalState } from "setup/modal/modal.reducer"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  ModalActionTypes,
  ModalCallback,
  ModalCallbackCollection,
  OpenModal,
  CloseModal,
  CloseAllModals,
  OnSkip,
  OnError,
  OnSuccess
} from "setup/modal/modal.types"
import { tempWrapperStyles } from "setup/modal/modal.helpers"
import { createPortal } from "react-dom"

type ModalModuleProps = {
  children: React.ReactNode
}

export const ModalModule = (props: ModalModuleProps) => {
  const { children } = props
  const [state, dispatch] = useReducer(modalReducer, initialModalState)
  const onCloseCallbacks = useRef<ModalCallbackCollection>([])

  const callCallbacks = useCallback(
    (collection: ModalCallbackCollection, name?: string) => {
      const callbacks = name
        ? collection.filter((entry) => entry.name === name)
        : collection
      for (const { callback } of callbacks) {
        callback()
      }
    },
    []
  )

  const open: OpenModal = useCallback(
    (name, modal, isCloseEnabled = false) => {
      dispatch({
        type: ModalActionTypes.Open,
        payload: { name, element: modal, isCloseEnabled }
      })
    },
    [dispatch]
  )

  const close: CloseModal = useCallback(
    (name) => {
      callCallbacks(onCloseCallbacks.current, name)
      dispatch({ type: ModalActionTypes.Close, payload: { name } })
    },
    [callCallbacks, dispatch]
  )

  const openInPortal: OpenModal = useCallback(
    (name, modal, isCloseEnabled = false) => {
      dispatch({
        type: ModalActionTypes.OpenInPortal,
        payload: { name, element: modal, isCloseEnabled }
      })
    },
    [dispatch]
  )

  const closeAll: CloseAllModals = useCallback(() => {
    callCallbacks(onCloseCallbacks.current)
    dispatch({ type: ModalActionTypes.CloseAll })
  }, [callCallbacks, dispatch])

  const onClose = useCallback(
    (name: ModalNames, callback: ModalCallback) => {
      onCloseCallbacks.current.push({ name, callback })
    },
    [onCloseCallbacks]
  )

  const removeOnCloseCallback = useCallback(
    (name: string, callback: ModalCallback) => {
      onCloseCallbacks.current = onCloseCallbacks.current.filter(
        (entry) => entry.name !== name && entry.callback !== callback
      )
    },
    []
  )

  const skip: OnSkip = useCallback((name: string, callback: ModalCallback) => {
    dispatch({ type: ModalActionTypes.Skip, payload: { name } })
    return callback()
  }, [])

  const setModalError: OnError = useCallback(
    (payload: object | null, callback: ModalCallback) => {
      dispatch({ type: ModalActionTypes.SetModalError, payload })
      return callback()
    },
    []
  )

  const setModalSuccess: OnSuccess = useCallback(
    (payload: object | null, callback: ModalCallback) => {
      dispatch({ type: ModalActionTypes.SetModalSuccess, payload })
      return callback()
    },
    []
  )

  const renderModal = useCallback(
    () => (
      <div
        onClick={state.isCloseEnabled ? closeAll : () => {}}
        style={tempWrapperStyles}
        data-e2e-target="modal-wrapper"
      >
        <div onClick={(e) => e.stopPropagation()}>
          {Object.entries(state.openModals)
            .filter(([, value]) => value.isOpen)
            .map(([key, value]) => React.cloneElement(value.element, { key }))}
        </div>
      </div>
    ),
    [state, closeAll]
  )

  const renderModalInPortal = useCallback(() => {
    return state.isOpenInPortal
      ? createPortal(renderModal(), document.body)
      : null
  }, [state, renderModal])

  return (
    <ModalContext.Provider
      value={{
        activeModal: state.activeModal,
        isOpen: state.isOpen,
        openModals: state.openModals,
        isOpenInPortal: state.isOpenInPortal,
        isCloseEnabled: state.isCloseEnabled,
        skipped: state.skipped,
        modalError: state.modalError,
        modalSuccess: state.modalSuccess,
        openInPortal,
        renderModalInPortal,
        open,
        close,
        closeAll,
        onClose,
        removeOnCloseCallback,
        skip,
        setModalError,
        setModalSuccess
      }}
    >
      {children}
      {state.isOpen && renderModal()}
    </ModalContext.Provider>
  )
}
