import React from "react"
import { CompanyModule } from "views/companies/company-module/CompanyModule"
import { Company } from "./Company"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"

export const CompanyDetailsWrapper = () => (
  <CompanyModule>
    <PersonProfileWrapper>
      <Company />
    </PersonProfileWrapper>
  </CompanyModule>
)
