import { WebStorageStateStore, UserManagerSettings } from "oidc-client-ts"
import { RouterUrl } from "setup/router/routes"
import { ValueOf } from "tsdef"

const OIDCServerUrl =
  process.env.REACT_APP_OIDC_URL || window.fpConfig.identityServerUrl

export enum OIDCRedirectRouteAction {
  SignIn = "sign-in",
  SignOut = "sign-out"
}
export const OIDCSignInRedirectRouteUrl =
  `/oidc-redirect/${OIDCRedirectRouteAction.SignIn}` as ValueOf<
    typeof RouterUrl
  >
export const OIDCSignOutRedirectRouteUrl =
  `/oidc-redirect/${OIDCRedirectRouteAction.SignOut}` as ValueOf<
    typeof RouterUrl
  >

export const getRouteWithPageUrl = (route: ValueOf<typeof RouterUrl>) =>
  window.location.origin + route

const mainRoute = getRouteWithPageUrl(RouterUrl.Homepage)

export const config: UserManagerSettings = {
  client_id: "ParsnipsWebApp",
  response_type: "code",
  scope: "openid profile ParsnipsApi",
  authority: OIDCServerUrl,
  silent_redirect_uri: mainRoute,
  redirect_uri: getRouteWithPageUrl(OIDCSignInRedirectRouteUrl),
  post_logout_redirect_uri: getRouteWithPageUrl(OIDCSignOutRedirectRouteUrl),
  loadUserInfo: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({
    store: localStorage
  })
}
