import styled from "styled-components"
import { Flex } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"

export const AssignmentRecordBox = styled.a`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey.dark};
  }
`

export const AssignmentDataBox = styled(Flex)`
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs}px;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.none}px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      margin-bottom: ${theme.space.none}px;
      padding: ${theme.space.s}px ${theme.space.xs}px;
      width: 25%;
      border-right: 1px solid ${theme.colors.grey.light};
    }
  `}

  &:last-child {
    border-right: 0px;
  }
`

export const AssignmentRecordFooter = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.grey.lightTranslucent};
  padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.xs}px;
  flex-direction: column-reverse;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      padding: 0px;
      align-items: center;
      flex-direction: row;
    }
  `}
`

export const AssignmentTeamStyled = styled(Flex)`
  padding-right: ${({ theme }) => theme.space.xs}px;
  width: 100%;
  flex-wrap: none;
  color: ${({ theme }) => theme.colors.grey.dark};
  border-right: none;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      padding:  ${theme.space.s}px ${theme.space.xs}px;
      width: 25%;
      border-right: 1px solid ${theme.colors.grey.light};
    }
  `}
`

export const FlexStyled = styled(Flex)`
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      width:75%;
      flex-direction: row;
      margin-bottom: 0px;
      padding-left: ${theme.space.xs}px;
    }
  `}
`

export const AssignmentStartDate = styled(SmallText)`
  flex-grow: 1;
  white-space: nowrap;
  margin: 0;
  color: ${({ theme }) => theme.colors.grey.dark};
`
