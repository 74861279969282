import { FieldInputProps } from "formik"

export enum UploadPersonDocumentField {
  File = "file"
}

export type UploadPersonDocumentValues = {
  [UploadPersonDocumentField.File]: FieldInputProps<File> | string
}

export const UploadPersonDocumentInitialValues: UploadPersonDocumentValues = {
  [UploadPersonDocumentField.File]: ""
}
