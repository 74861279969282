import styled, { css } from "styled-components"

export const AvatarWrapper = styled.div<{ height?: number; width?: number }>`
  text-decoration: none;
  ${({ height, width }) =>
    css`
      height: ${height || 60}px;
      width: ${width || 60}px;
    `}
`
