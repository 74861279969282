import React, { useState, useRef } from "react"
import { useIntl } from "react-intl"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import { Nullable } from "tsdef"
import {
  DatepickerHeader,
  DatepickerHeaderProps,
  StyledDatepickerContainer,
  InputProps,
  colors
} from "@ikiru/talentis-fpc"

import { getDatePatternMask, getLocaleDatePattern } from "utils/format-date"
import { CVInputDatepickerWrapper, PencilIconStyle, StyleInput } from "./style"
import InputFooter from "./InputFooter"
import OverviewInputFooter from "./OverviewInputFooter"

type CVDatePickerProps = InputProps & {
  label?: React.ReactChild
  value: Nullable<Date>
  setValue: (date: Nullable<Date>) => void
  name: Pick<InputProps, "name">
  width?: string
  maxDate?: Date
  minDate?: Date
  dateFormat?: string
  isOverview?: boolean
  handleDelete?: () => void
  saveTempValue?: (date: Nullable<Date>) => void
}

type DatapickerRenderHeaderProps = {
  date: Date
} & Pick<
  DatepickerHeaderProps,
  "increaseMonth" | "decreaseMonth" | "changeYear"
>

export const CVDatePicker = React.forwardRef(
  (props: CVDatePickerProps, ref) => {
    const {
      name,
      width,
      maxDate,
      minDate,
      dateFormat = getLocaleDatePattern(),
      id,
      value,
      setValue,
      isOverview = false,
      handleDelete,
      saveTempValue
    } = props
    const intl = useIntl()

    const [date, setDate] = useState<Nullable<Date> | string>(
      value ? moment(value).toDate() : null
    )
    const [isEditing, setIsEditing] = useState(false)
    const [isHover, setIsHover] = useState(false)

    const handleEditClick = () => {
      setIsEditing(true)
    }

    const datepickerRef = useRef<any>(ref)

    const onChange = (date: Nullable<Date>) => {
      if (moment(date).isValid()) {
        setDate(date)
        saveTempValue && saveTempValue(date)
      } else {
        setDate(null)
      }
    }

    const renderCustomHeader = ({
      date,
      decreaseMonth,
      increaseMonth,
      changeYear
    }: DatapickerRenderHeaderProps) => {
      const month = moment.months()[moment(date).month()]
      const year = moment(date).year()

      return (
        <DatepickerHeader
          month={month}
          year={year}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          changeYear={changeYear}
        />
      )
    }

    const handleSaveClick = () => {
      //@ts-ignore
      setValue(date)
      setIsEditing(false)
    }

    const handleCancelClick = () => {
      setDate(value)
      setValue(value)
      setIsEditing(false)
    }

    return (
      <CVInputDatepickerWrapper
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        width={width || "100%"}
        isEditing={isEditing}
        isDisabled={false}
      >
        <StyledDatepickerContainer width={width || "100%"}>
          <DatePicker
            ref={datepickerRef}
            id={id}
            name={name}
            calendarClassName="talentis-datepicker"
            dateFormat={dateFormat}
            showPopperArrow={false}
            selected={date as Date}
            minDate={minDate}
            maxDate={maxDate}
            locale={intl.locale}
            onChange={onChange}
            popperPlacement="bottom-end"
            popperClassName="talentis-datepicker-popper"
            renderCustomHeader={renderCustomHeader}
            onCalendarClose={() => {
              datepickerRef.current?.input.inputElement.blur()
            }}
            customInput={
              <StyleInput
                autoComplete="off"
                ref={ref}
                onInput={(e) => {
                  // @ts-ignore
                  if (e.target.value === "_/__/____" || !e.target.value) {
                    setValue(null)
                    setDate(null)
                  }
                }}
                data-e2e-target="form-datapicker-input"
                mask={getDatePatternMask(dateFormat)}
                disabled={!isEditing}
              />
            }
          />
        </StyledDatepickerContainer>
        {!isEditing && isHover && (
          <>
            {isOverview ? (
              <OverviewInputFooter
                handleDelete={handleDelete}
                handleEditClick={handleEditClick}
              />
            ) : (
              <PencilIconStyle
                color={colors.grey.dark}
                onClick={handleEditClick}
                width={14}
                height={14}
              />
            )}
          </>
        )}
        {isEditing && (
          <InputFooter
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        )}
      </CVInputDatepickerWrapper>
    )
  }
)
