import React from "react"
import { Div, Flex, SmallText, Toggle } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { OffLimits } from "../OffLimits"
import { Experience } from "views/persons/person.types"
import { formatDateTime } from "utils/format-date"
import { CompanyDiv, InheritedOffLimitsBlockStyled } from "../style"
import { Nullable } from "tsdef"

type InheritedOffLimitsDataProps = {
  inheritedOffLimits: Experience
  onOverrideClick: (companyId: Nullable<string>, isOverridden: boolean) => void
}

export const InheritedOffLimitsBlock = ({
  inheritedOffLimits,
  onOverrideClick
}: InheritedOffLimitsDataProps) => {
  const { offlimits, offlimitsOverride, company, companyId } =
    inheritedOffLimits

  return (
    <InheritedOffLimitsBlockStyled>
      <CompanyDiv>
        <CompanyLink
          companyName={company}
          companyId={companyId}
          fontSize={[15, 15, 16]}
          mb="xxs"
        />

        <OffLimits {...offlimits} isNoLastEdit isEditMode />
      </CompanyDiv>
      <Div width="30%" pt="s" pr="s" textAlign="right">
        <Flex ml="120px" mb="xxs">
          <Toggle
            type="checkbox"
            hideUnderline
            checked={offlimitsOverride?.isOverridden}
            onChange={() =>
              onOverrideClick(companyId, !offlimitsOverride?.isOverridden)
            }
            small
            label={messages.offLimits.override}
          />
        </Flex>

        {offlimitsOverride?.updated && (
          <>
            <SmallText display="block" mb="none">
              {messages.offLimits.lastComment}
              {formatDateTime(offlimitsOverride?.updated)}
            </SmallText>

            <SmallText>
              {` ${offlimitsOverride?.updatedBy?.firstName} ${offlimitsOverride?.updatedBy?.lastName}`}
            </SmallText>
          </>
        )}
      </Div>
    </InheritedOffLimitsBlockStyled>
  )
}
