import styled from "styled-components"
import { Div, LargeText, fontFamilies } from "@ikiru/talentis-fpc"

export const SearchDropdown = styled(Div)`
  width: 400px;
  max-height: 250px;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.grey.lightest};
  position: absolute;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
`

export const SearchDropdownItem = styled.p`
  color: ${({ theme }) => theme.colors.grey.dark};
  padding: 8px 5px;
  cursor: pointer;
  margin: 0px;
  font-weight: 500;
  font-family: ${fontFamilies.gibson};
  font-size: 18px;
  line-height: 22px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.light};
  }
`
export const StyledLargeText = styled(LargeText)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey.standard};
  padding: 15px;
  padding-top: 0px;
`
