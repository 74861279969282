import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import {
  AssignmentPackageData,
  assignmentPackageDataInitialValues,
  defaultCurrency
} from "views/assignments/components/assignment-data/constants/definitions"
import { getChangedValues } from "views/assignments/utils"
import { Button, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "./style"
import { AssignmentPackageSalaryFormLayout } from "../../form/AssignmentPackageSalaryFormLayout"
import { AssignmentPackageDataValidationSchema } from "../../constants/schema"

type EditPackageDataProps = {
  onSubmit: (
    values: AssignmentPackageData,
    helpers: FormikHelpers<AssignmentPackageData>
  ) => void
  initialValues?: AssignmentPackageData
  formActionsComponent?: (
    formikProps: FormikProps<AssignmentPackageData>
  ) => JSX.Element
}

export const EditPackageData = ({
  onSubmit,
  initialValues,
  formActionsComponent
}: EditPackageDataProps) => {
  const { close } = useModal()

  const submitForm = (
    values: AssignmentPackageData,
    action: FormikHelpers<AssignmentPackageData>
  ) => {
    values.salaryFrom = Number(values.salaryFrom)
    values.salaryTo = Number(values.salaryTo)

    const changeValues = getChangedValues(values, initialValues)

    values.currency && localStorage.setItem("currency", values.currency)

    onSubmit(changeValues, action)
    close(ModalNames.EditPackageData)
  }

  return (
    <StyledDiv>
      <Formik
        initialValues={{
          ...assignmentPackageDataInitialValues,
          ...initialValues,
          ...(initialValues?.currency
            ? { currency: initialValues?.currency }
            : { currency: localStorage.getItem("currency") || defaultCurrency })
        }}
        validationSchema={AssignmentPackageDataValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form>
            <ModalSectionHeader
              size="xSmall"
              title={messages.assignment.assignmentPackageEdit}
              actions={
                <>
                  <Button mx="xxs" my="0" type="submit" size="small">
                    {messages.form.generic.save}
                  </Button>
                  <Button
                    mode="secondary"
                    mx="xxs"
                    my="0"
                    size="small"
                    onClick={() => close(ModalNames.EditPackageData)}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
            <AssignmentPackageSalaryFormLayout />
            {formActionsComponent?.(formik)}
          </Form>
        )}
      </Formik>
    </StyledDiv>
  )
}
