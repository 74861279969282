export const examplesSearch = {
  diversity: {
    jobTitleBundle: [
      {
        jobTitles: ["chief"]
      }
    ],
    keywordBundle: [
      {
        keywordsSearchRecordCompany: false,
        keywordsSearchRecordPerson: true,
        keywords: [
          "arab american",
          "arabic",
          "asian american",
          "chinese",
          "female",
          "females",
          "girl",
          "girls",
          "her",
          "hispanic",
          "ladies",
          "lady",
          "Latino",
          "lgbt",
          "lgbtq",
          "mother",
          "native american",
          "she",
          "sister",
          "sorority",
          "woman",
          "women",
          "they/their",
          "their/they"
        ]
      }
    ]
  },
  product: {
    JobTitlesBundleSearchUsingANDLogic: "and",
    jobTitleBundle: [
      {
        jobTitles: ["product"],
        searchJobTitleLogic: "either"
      },
      {
        jobTitles: ["chief", "director", "head", "vp", "vice", "manager"]
      }
    ],
    keywordBundle: [
      {
        keywordsSearchLogic: "either",
        keywords: ["fintech", "payments"]
      }
    ],
    KeywordsBundleSearchUsingORLogic: "and"
  },
  selling: {
    JobTitlesBundleSearchUsingANDLogic: "and",
    jobTitleBundle: [
      {
        jobTitles: [
          "sales",
          "business development",
          "revenue",
          "account",
          "commercial"
        ],
        searchJobTitleLogic: "current"
      },
      {
        jobTitles: ["boeing"],
        searchJobTitleLogic: "current"
      }
    ]
  },
  ceos: {
    jobTitleBundle: [
      {
        jobTitles: ["chief executive", "ceo"]
      }
    ],
    keywordBundle: [
      {
        keywords: ["financial", "finance"],
        keywordsSearchRecordCompany: false,
        keywordsSearchRecordPerson: true
      },
      {
        keywords: ["consumer", "fmcg"]
      }
    ],
    KeywordsBundleSearchUsingORLogic: "and"
  },
  semiconductor: {
    JobTitlesBundleSearchUsingANDLogic: "and",
    jobTitleBundle: [
      {
        jobTitles: ["sales", "revenue", "business development", "commercial"],
        searchJobTitleLogic: "current"
      },
      {
        jobTitles: ["director", "vice president", "head", "chief"],
        searchJobTitleLogic: "either"
      }
    ],
    keywordBundle: [
      {
        keywords: ["microchip", "semiconductor"],
        keywordsSearchRecordCompany: true,
        keywordsSearchRecordPerson: true,
        keywordsSearchLogic: "current"
      },
      {
        keywords: [
          "international",
          "global",
          "EMEA",
          "Americas",
          "North America",
          "worldwide"
        ],
        keywordsSearchRecordCompany: false,
        keywordsSearchRecordPerson: true,
        keywordsSearchLogic: "either"
      }
    ],
    KeywordsBundleSearchUsingORLogic: "and"
  }
}
