import React from "react"
import { Flex, H6, colors } from "@ikiru/talentis-fpc"
import { StyledBadge } from "views/search/components/PersonRecordLayout/styles"

type CompanyPersonProjectDetailsProps = {
  count: number
  name: string
}

export const CompanyPersonProjectDetails = React.memo(
  ({ name, count }: CompanyPersonProjectDetailsProps) => {
    return (
      <Flex pr="s" alignItems="center" lineHeight={0}>
        <H6 m="none" color={colors.green.dark} fontWeight={500}>
          {name}
        </H6>
        <StyledBadge count={count}>{count}</StyledBadge>
      </Flex>
    )
  }
)
