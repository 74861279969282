import { colors, Flex, spacing } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const LinkFormContainerStyled = styled(Flex)`
  margin-top: ${spacing.xs}px;
  padding: ${spacing.xs}px;
  background: ${colors.green.lightest};
  align-items: center;
  flex-direction: column;
  width: 100%;
  word-break: break-word;
`

export const LinkContainerStyled = styled(Flex)`
  margin-top: -10px;

  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background: ${colors.green.lightest};
  width: 100%;
  position: relative;
  flex-direction: column;
`
