import styled, { css } from "styled-components"
import styledCss from "@styled-system/css"
import { H5Style as H5 } from "@ikiru/talentis-fpc"

type SidebarSettingProps = {
  isSettingActive?: boolean
  disabled?: boolean
}

export const SidebarFooterSettingsWrapper = styled.div`
  padding: ${({ theme }) => theme.space.xs}px;
`

export const SidebarSettingIcon = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space.xs}px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.grey.dark} !important;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 50%;
  border: 2px solid;
  & svg {
    max-height: 100%;
    max-width: 100%;
    fill: ${({ theme }) => theme.colors.grey.dark};
  }
`

export const SidebarSettingLabel = styled.span`
  ${styledCss(H5)}
  color: ${({ theme }) => theme.colors.grey.darkest};
  margin-bottom: 0;
`

const hoverLinkCss = css<SidebarSettingProps>`
  border-color: ${({ theme }) => theme.colors.grey.darkest};
  background-color: ${({ theme, isSettingActive }) =>
    isSettingActive ? theme.colors.grey.darkest : theme.colors.grey.light};

  & svg {
    fill: ${({ theme, isSettingActive }) =>
      isSettingActive
        ? theme.colors.white.standard
        : theme.colors.grey.darkest};
  }
`

export const SidebarSetting = styled.div<SidebarSettingProps>`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.3;
      &:not(:focus):hover ${SidebarSettingIcon} {
        ${hoverLinkCss}
      }
  `}
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    margin-bottom: 5px;
  }

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.xs}px;
    @media only screen and (min-height: 394px) and (max-height: 695px) {
      margin-bottom: 5px;
    }
  }

  ${({ isSettingActive, theme }) =>
    isSettingActive &&
    `
    & ${SidebarSettingIcon} {
      border-color: ${theme.colors.grey.dark};
      background-color: ${theme.colors.grey.dark};
    
      & svg {
        fill: ${theme.colors.white.standard};
      }
    }

    & ${SidebarSettingLabel} {
      color: ${theme.colors.grey.darkest};
    }
  `}
`
