import { useMemo } from "react"
import {
  ProjectRecordFilter,
  FilterArrayType
} from "components/ProjectRecordsFilters/types"
import { checkFirstSelected } from "components/ProjectRecordsFilters/helpers"
import uniq from "lodash/uniq"
import { messages } from "setup/messages/messages"
import {
  getLTEDate,
  getGTEDate
} from "components/ProjectRecordsFilters/filters/DueDateFilters/consts"
import {
  getIsDueDateLast7Days,
  getIsDueDateNext14Days,
  getIsDueDateNext30Days,
  getIsDueDateNext7Days,
  getIsDueDateOverdue,
  getIsDueDateToday,
  getIsDueDateTommorow,
  getIsDueDateYesterday
} from "components/ProjectRecordsFilters/filters/DueDateFilters/utils"
import { DueDateType } from "components/ProjectRecordsFilters/types"

/**
 * Return a list of "due date" filters, including only those which at least one record has assigned
 */
export const useDueDate = (
  dueDateFilter: Date[],
  allAvailableFilters: FilterArrayType,
  appliedFilters: ProjectRecordFilter[]
) => {
  const dueDateFilters = useMemo<ProjectRecordFilter[]>(() => {
    const currentDueDatesArray = checkFirstSelected("dueDate", appliedFilters)
      ? dueDateFilter
      : allAvailableFilters.dueDates || []

    const teamMembersDueDates = currentDueDatesArray.reduce((result, date) => {
      const isOverDue =
        date && getIsDueDateOverdue(date) ? "dueDateOverdue" : null
      const isLast7Days =
        date && getIsDueDateLast7Days(date) ? "dueDateLast7Days" : null
      const isYesterday =
        date && getIsDueDateYesterday(date) ? "dueDateYesterday" : null
      const isToday = date && getIsDueDateToday(date) ? "dueDateToday" : null
      const isTommorow =
        date && getIsDueDateTommorow(date) ? "dueDateTomorrow" : null
      const isNext7Days =
        date && getIsDueDateNext7Days(date) ? "dueDateNext7Days" : null
      const isNext14Days =
        date && getIsDueDateNext14Days(date) ? "dueDateNext14Days" : null
      const isNext30Days =
        date && getIsDueDateNext30Days(date) ? "dueDateNext30Days" : null
      const isNoDueDate = !date ? "dueDateNoDueDate" : null
      const isAnyDueDate = date ? "dueDateAnyDate" : null

      const currentDateRequirements = [
        isOverDue,
        isLast7Days,
        isYesterday,
        isToday,
        isTommorow,
        isNext7Days,
        isNext14Days,
        isNext30Days,
        isNoDueDate,
        isAnyDueDate
      ].filter((item) => item) as DueDateType[]

      return [...result, ...currentDateRequirements]
    }, [] as DueDateType[])

    const uniqDueDates = uniq(teamMembersDueDates)

    return [
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.overdue,
        value: {
          lte: getLTEDate(-1).toDate()
        },
        filterKey: "dueDateOverdue",
        disabled: !uniqDueDates.includes("dueDateOverdue")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.last7Days,
        value: {
          lte: getLTEDate(-1).toDate(),
          gte: getGTEDate(-7).toDate()
        },
        filterKey: "dueDateLast7Days",
        disabled: !uniqDueDates.includes("dueDateLast7Days")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.yesterday,
        value: {
          lte: getLTEDate(-1).toDate(),
          gte: getGTEDate(-1).toDate()
        },
        filterKey: "dueDateYesterday",
        disabled: !uniqDueDates.includes("dueDateYesterday")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.today,
        value: {
          lte: getLTEDate().toDate(),
          gte: getGTEDate().toDate()
        },
        filterKey: "dueDateToday",
        disabled: !uniqDueDates.includes("dueDateToday")
      },
      {
        recordProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.tomorrow,
        value: {
          lte: getLTEDate(1).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateTomorrow",
        disabled: !uniqDueDates.includes("dueDateTomorrow")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.next7Days,
        value: {
          lte: getLTEDate(7).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext7Days",
        disabled: !uniqDueDates.includes("dueDateNext7Days")
      },
      {
        recordProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.next14Days,
        value: {
          lte: getLTEDate(14).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext14Days",
        disabled: !uniqDueDates.includes("dueDateNext14Days")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.next30Days,
        value: {
          lte: getLTEDate(30).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext30Days",
        disabled: !uniqDueDates.includes("dueDateNext30Days")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.anyDate,
        value: {
          exists: true
        },
        filterKey: "dueDateAnyDate",
        disabled: !uniqDueDates.includes("dueDateAnyDate")
      },
      {
        recordProperty: `dueDate`,
        label: messages.project.filters.dueDateOptions.noDueDate,
        value: {
          exists: false
        },
        filterKey: "dueDateNoDueDate",
        disabled: !uniqDueDates.includes("dueDateNoDueDate")
      }
    ]
  }, [dueDateFilter, allAvailableFilters.dueDates, appliedFilters])

  return {
    dueDateFilters
  }
}
