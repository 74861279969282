import React, { useContext } from "react"
import { noop } from "lodash"
import { SidebarContext } from "components/visual/header/SidebarContext"
import { HeaderHelper } from "components/navigation/constants/navigation.definitions"
import {
  SidebarHelpersContainer,
  SidebarHelperLink,
  SidebarHelperText,
  SidebarHelperIcon
} from "./SidebarHelpers.styled"

type Props = {
  helpers: HeaderHelper[]
}

export const SidebarHelpers = React.memo(({ helpers }: Props) => {
  const { isExpanded } = useContext(SidebarContext)

  return (
    <SidebarHelpersContainer>
      {helpers.map(
        ({ onClick = noop, path, label, icon, e2eTargets }, index) => (
          <SidebarHelperLink
            key={index}
            to={path || "#"}
            onClick={onClick}
            data-e2e-target={e2eTargets?.e2eTarget}
            data-e2e-target-name={e2eTargets?.e2eTargetName}
          >
            <SidebarHelperIcon>{icon}</SidebarHelperIcon>
            {isExpanded && !!label && (
              <SidebarHelperText>{label}</SidebarHelperText>
            )}
          </SidebarHelperLink>
        )
      )}
    </SidebarHelpersContainer>
  )
})
