import { useCallback } from "react"
import { useAssignment } from "../assignment-module/assignment-module.context"
import { useModal } from "utils/hooks/use-modal"
import { EditAssignData } from "../components/assignment-data/components/AssignmentDataModal/EditAssignData"
import { ModalNames } from "setup/modal/modal.definitions"
import React from "react"
import { useTeam } from "views/team/team/team-module.context"
import { AssignmentPrimaryData } from "../components/assignment-data/constants/definitions"
import { parseMomentDate } from "utils/moment"

const useSubmitAssignmentDetails = () => {
  const { assignmentDetails, setAssignmentDetails, patchAssignmentData } =
    useAssignment()
  const { teamMembers } = useTeam()
  const { open, close } = useModal()

  const submitForm = useCallback(
    async (values: AssignmentPrimaryData) => {
      const [, response] = await patchAssignmentData(
        assignmentDetails.id,
        values
      )
      const newAssignment = response?.data

      const startDate = parseMomentDate(response?.data?.startDate)

      setAssignmentDetails({ ...newAssignment, startDate })

      close(ModalNames.EditAssignData)
    },
    [assignmentDetails, setAssignmentDetails, patchAssignmentData, close]
  )

  const assignmentOpenModal = useCallback(() => {
    open(
      ModalNames.EditAssignData,
      <EditAssignData
        initialValues={assignmentDetails}
        onSubmit={submitForm}
        assignmentTeamMembers={assignmentDetails.teamMembers}
        teamMembers={teamMembers}
      />,
      true
    )
  }, [open, submitForm, assignmentDetails, teamMembers])

  return { assignmentOpenModal, setAssignmentDetails }
}

export default useSubmitAssignmentDetails
