import React, { useCallback, useMemo } from "react"
import { messages } from "setup/messages/messages"
import { Flex, Button, H6, PositiveIcon } from "@ikiru/talentis-fpc"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"

type LinkPersonProjectProps = {
  personId: string
  assignmentIds?: string[]
  campaignIds?: string[]
}

export const LinkPersonProject = ({
  personId,
  assignmentIds = [],
  campaignIds = []
}: LinkPersonProjectProps) => {
  const {
    selectedAssignment,
    selectedCampaign,
    activeAssignments,
    activeCampaigns,
    isAssignmentSelect,
    linkToProject
  } = useInternalSearch()

  const handleLinkToProject = useCallback(() => {
    linkToProject(personId)
  }, [personId, linkToProject])

  const personLinkedToAssignment = useMemo(() => {
    return assignmentIds.includes(selectedAssignment)
  }, [assignmentIds, selectedAssignment])

  const personLinkedToCampaign = useMemo(
    () => campaignIds.includes(selectedCampaign),
    [campaignIds, selectedCampaign]
  )

  const assignment = useMemo(
    () =>
      activeAssignments?.find(
        (assignment) => assignment.id === selectedAssignment
      ),
    [activeAssignments, selectedAssignment]
  )

  const campaign = useMemo(
    () => activeCampaigns?.find((campaign) => campaign.id === selectedCampaign),
    [activeCampaigns, selectedCampaign]
  )

  const personIsAssigned = () => {
    return isAssignmentSelect ? selectedAssignment : selectedCampaign
  }

  return (
    <Flex flexDirection="column" justifyContent="center" margin="auto">
      {!personIsAssigned() ? (
        <H6
          textAlign="center"
          my="none"
          fontWeight={500}
          color={isAssignmentSelect ? "green.dark" : "grey.standard"}
        >
          {isAssignmentSelect ? (
            <>
              {messages.internalSearch.selectAssignmentToLinkOne}
              <br /> {messages.internalSearch.selectAssignmentToLinkTwo}
            </>
          ) : (
            <>
              {messages.internalSearch.selectCampaignToLinkOne}
              <br /> {messages.internalSearch.selectCampaignToLinkTwo}
            </>
          )}
        </H6>
      ) : (
          isAssignmentSelect
            ? !personLinkedToAssignment
            : !personLinkedToCampaign
        ) ? (
        <Button
          size="extra-small"
          onClick={handleLinkToProject}
          mode="standard-white"
        >
          {isAssignmentSelect
            ? messages.talentGraphSearch.linkToAssignment
            : messages.talentGraphSearch.linkToCampaign}
        </Button>
      ) : (
        <Flex
          flexDirection={["row", "row", "row", "column"]}
          width="100%"
          alignItems="center"
          my="s"
        >
          <PositiveIcon width={30} height={30} />
          <H6
            mb="none"
            ml={["xs", "xs", "xs", "none"]}
            mt={["none", "none", "none", "xxs"]}
            textAlign="center"
            color="grey.dark"
          >
            {messages.talentGraphSearch.linkedTo.format(
              isAssignmentSelect ? assignment?.name || "" : campaign?.name || ""
            )}
          </H6>
        </Flex>
      )}
    </Flex>
  )
}
