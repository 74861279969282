import { useState, useEffect, useRef } from "react"

export const useComponentVisible = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState<boolean>(initialIsVisible)
  const divRef = useRef(null)!

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsComponentVisible(false)
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [divRef])

  return { divRef, isComponentVisible, setIsComponentVisible }
}
