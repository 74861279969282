import styled from "styled-components"
import { Div, Flex, SectionBody, spacing } from "@ikiru/talentis-fpc"

export const StyledDiv = styled(Div)`
  overflow: scroll;
`

export const StyledPopupTrigger = styled(Flex)`
  border: none;
  cursor: pointer;
  align-items: center;
`

export const StyledPopupOption = styled(Flex)`
  justify-content: space-between;
  margin: 0 ${spacing.s}px;
  padding: ${spacing.xs}px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
  }
`
export const StyledHeader = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DateFlex = styled(Flex)`
  display: flex;
`
export const FlexSectionBody = styled(SectionBody)`
  background: ${({ theme }) => theme.colors?.grey?.light};
  display: block;
  min-height: 90px;
`
