export const e2eTargets = { profile: "person-profile" }

export enum PersonTabHeaders {
  PersonDetails = "personDetails",
  ProfessionalExperience = "ProfessionalExperience",
  Assignment = "assignment",
  Campaigns = "campagins",
  Notes = "notes",
  Document = "document",
  Other = "other"
}
