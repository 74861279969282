import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const fetchPersonPhoto = async ({
  localId
}: {
  localId?: string
  dataPoolId?: string
}) => {
  let photoUrl
  if (localId) {
    const [, response] = await apiRequest.get({
      endpoint: PersonsEndpoints.Photo,
      endpointParams: localId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })
    photoUrl = response?.data?.photo?.url || null
  }

  return photoUrl
}

export const fetchPersonPhotos = async (
  localIds: string[]
): Promise<{ personId: string; photo: string }[]> => {
  if (!localIds?.length) {
    return []
  }
  const [, response] = await apiRequest.post({
    endpoint: PersonsEndpoints.GetPersonsPhotos,
    data: localIds,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const fetchPhotosAndSetUpdatedEntities = (
  persons: { personId: string }[],
  setEntitiesCallback: (
    persons: ({ personId: string; photo: string } | any)[]
  ) => void
) => {
  if (persons?.length) {
    fetchPersonPhotos(persons.map((task) => task.personId)).then((photos) => {
      if (!photos?.length) return
      const userPhotoObject: { [key: string]: string } = photos.reduce(
        (acc, val) => {
          acc[val.personId as string] = val.photo
          return acc
        },
        {} as { [key: string]: string }
      )
      const entities = persons.map((task) => ({
        ...task,
        photo: userPhotoObject[String(task.personId)]
      }))
      setEntitiesCallback(entities)
    })
  }
}
