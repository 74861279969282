import { BetaIcon, Div, H4 } from "@ikiru/talentis-fpc"
import React, { useState } from "react"
import GPTInput from "views/search/components/ChatGPTSaerch/components/GPTInput"
import { messages } from "setup/messages/messages"
import { GPTHeaderContainer } from "./style"

interface GPTTemplateInputProps {
  value: string
  setValue: any
  onSubmit: () => Promise<void>
  defaultSearchPlaceHolder: string
}

const GPTTemplateInput = ({
  onSubmit,
  setValue,
  value,
  defaultSearchPlaceHolder
}: GPTTemplateInputProps) => {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const onGenerate = async () => {
    if (Boolean(value)) {
      setIsError(false)
      setErrorMessage("")
      onSubmit()
    } else {
      setIsError(true)
      setErrorMessage(messages.project.outReach.coreMessageError)
    }
  }

  return (
    <Div width="100%">
      <GPTHeaderContainer>
        <H4 mb="27px" mr="xxs">
          {messages.project.outReach.gptTitle}
        </H4>
        <BetaIcon>{messages.search.chatGPT.beta}</BetaIcon>
      </GPTHeaderContainer>
      <GPTInput
        label={messages.project.outReach.coreMessage}
        placeHolder={defaultSearchPlaceHolder}
        explainedLabel={messages.project.outReach.coreMessageExplain}
        gptSearch={value}
        setGPTSearch={setValue}
        isError={isError}
        customErrorMessage={errorMessage}
        onSubmit={onGenerate}
      />
    </Div>
  )
}

export default GPTTemplateInput
