import {
  PersonProfileFields,
  PersonProfileDetails
} from "views/persons/pages/manage-person/person-profile.types"
import { PhoneTypeEnums } from "../../person-details-tab/components/person-contact-info/form/constants/definitions"

export const getNormalizedPersonProfileDetails = (
  data: PersonProfileDetails
) => {
  const {
    name,
    jobTitle,
    location,
    company,
    companyId,
    experience,
    bio,
    taggedEmails,
    phones,
    linkedInProfileUrl,
    webSites,
    dataPoolPersonId,
    rocketReachFetchedInformation,
    isOfflimits,
    title,
    firstName,
    lastName,
    middleName,
    knownAs,
    nationality,
    dateOfBirth,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    city,
    country,
    postcode,
    education
  } = data

  const phoneSwitchbordToBusiness = phones.map((phoneData) => {
    if (
      PhoneTypeEnums.Business_Switchboard.toLowerCase() ===
      phoneData.value.phoneUsageType.toString().toLowerCase()
    ) {
      phoneData.value.phoneUsageType = PhoneTypeEnums.Business
    }
    return phoneData
  })

  const profileDetails: PersonProfileDetails = {
    [PersonProfileFields.Name]: name,
    [PersonProfileFields.Company]: company,
    [PersonProfileFields.CompanyId]: companyId,
    [PersonProfileFields.Experience]: experience,
    [PersonProfileFields.Bio]: bio,
    [PersonProfileFields.EmailAddresses]: taggedEmails,
    [PersonProfileFields.JobTitle]: jobTitle,
    [PersonProfileFields.LinkedInProfileUrl]: linkedInProfileUrl,
    [PersonProfileFields.Location]: location,
    [PersonProfileFields.PhoneNumbers]: phoneSwitchbordToBusiness,
    [PersonProfileFields.WebSites]: webSites,
    [PersonProfileFields.DataPoolId]: dataPoolPersonId,
    [PersonProfileFields.RocketReachFetchedInformation]:
      rocketReachFetchedInformation,
    [PersonProfileFields.IsOffLimits]: isOfflimits,
    [PersonProfileFields.Title]: title,
    [PersonProfileFields.FirstName]: firstName,
    [PersonProfileFields.LastName]: lastName,
    [PersonProfileFields.MiddleName]: middleName,
    [PersonProfileFields.KnownAs]: knownAs,
    [PersonProfileFields.Nationality]: nationality,
    [PersonProfileFields.DateOfBirth]: dateOfBirth,
    [PersonProfileFields.AddressLine1]: addressLine1,
    [PersonProfileFields.AddressLine2]: addressLine2,
    [PersonProfileFields.AddressLine3]: addressLine3,
    [PersonProfileFields.AddressLine4]: addressLine4,
    [PersonProfileFields.City]: city,
    [PersonProfileFields.Country]: country,
    [PersonProfileFields.PostCode]: postcode,
    [PersonProfileFields.Education]: education
  }

  return profileDetails
}
