import React from "react"
import { Flex, Div, PolygonIcon, H6, Link, PlusIcon } from "@ikiru/talentis-fpc"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { messages } from "setup/messages/messages"
import { StyledPopupTrigger } from "../style"
import { PhoneDataType } from "views/persons/person.types"
import UsageIcon from "../UsageIcon"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { onToggleIsPreffered } from "../actions"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "../../utils/get-person-details"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import ItemComponent from "../ItemComponent"
import {
  PhoneTypeEnums,
  phoneTypeOptions
} from "../../../person-details-tab/components/person-contact-info/form/constants/definitions"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import ClickablePhoneNumber from "./ClickableNumber"

type PersonPhonesProps = {
  personId: string
  phoneNumbers: PersonValue<PhoneDataType>[]
  editPersonPhones: (data: boolean) => void
}

export const PersonPhones = ({
  personId,
  phoneNumbers,
  editPersonPhones
}: PersonPhonesProps) => {
  const { setProfileDetails } = usePerson()

  const onToggle = async (oldPhoneNumIndex: number) => {
    const toggledPhoneValues = phoneNumbers.map((phoneData, index) => {
      if (oldPhoneNumIndex === index) {
        phoneData.value.isPreferred = !phoneData.value.isPreferred
      }
      return phoneData.value
    })

    const data = await onToggleIsPreffered(
      { phones: toggledPhoneValues },
      personId
    )
    const mergedPersonData = new LocalPersonModel(data)

    const profileDetails = getNormalizedPersonProfileDetails(mergedPersonData)

    setProfileDetails(profileDetails)
  }

  const options: HeaderOption[] = [
    ...phoneNumbers.map((phoneData, index) => {
      const usageLabel = phoneTypeOptions.find(
        (phoneType) => phoneType.value === phoneData.value.phoneUsageType
      )?.label
      return {
        label: (
          <ItemComponent
            isPreferred={phoneData.value.isPreferred}
            onToggle={onToggle}
            index={index}
            usageType={phoneData.value.phoneUsageType as PhoneTypeEnums}
            usageLabel={usageLabel as string}
            itemValue={phoneData.value.phoneNumber}
          />
        ),
        disableClose: true
      }
    }),
    {
      label: (
        <Link size="small" href="#" standalone m="0">
          <PlusIcon height="12px" />
          &nbsp;
          {messages.form.generic.addPhoneNumber}
        </Link>
      ),
      onClick: () => {
        editPersonPhones(true)
      }
    }
  ]

  return (
    <Div>
      <Flex alignItems="center" mb="xxs" mt="7px">
        {phoneNumbers.length > 0 && (
          <Flex alignItems="center">
            <UsageIcon
              usageType={phoneNumbers[0].value.phoneUsageType as PhoneTypeEnums}
            />
            <ClickablePhoneNumber
              phoneNumber={phoneNumbers[0].value.phoneNumber}
            />
          </Flex>
        )}
        {phoneNumbers.length > 0 && (
          <OptionsPopup
            alignSelf="center"
            display="flex"
            config={{
              PopupProps: {
                position: "bottom right"
              }
            }}
            trigger={
              <StyledPopupTrigger>
                {phoneNumbers.length > 1 && (
                  <H6 m="none" mr="2px">
                    {`+${phoneNumbers.length - 1}`}
                  </H6>
                )}
                <PolygonIcon />
              </StyledPopupTrigger>
            }
            options={options}
          />
        )}
      </Flex>
    </Div>
  )
}
