import React, { useMemo } from "react"
import { Div } from "@ikiru/talentis-fpc"
import { sectionFieldSearchValuesTemplateString } from "./consts"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { SelectSection } from "views/internal-search/InternalSearchModule/types"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { useArrayFieldSelectChange } from "views/internal-search/hooks"
import { get } from "lodash"
import { PreferredList } from "../PreferredList"

type SelectInputFieldProps = {
  index: number
  indexSection: number
  valueForSelect: SelectSection[]
}

export const SelectInputField = ({
  index,
  indexSection,
  valueForSelect
}: SelectInputFieldProps) => {
  const { removeArrayBasedInternalFilter, filters, updateFilters } =
    useInternalSearch()

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )
  const currentSelectValues = get(filters, fieldSearchValuesName, [])

  const handleSelectValues = useArrayFieldSelectChange({
    field: fieldSearchValuesName
  })

  const handleSelectValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateFilters({
      [fieldSearchValuesName]: [e.target.value]
    })
  }

  const isValues = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return ["any", "none", "all"].includes(condition)
  }, [filters, indexSection, index])

  return (
    <Div minWidth="250px">
      {isValues ? (
        <>
          <FormikSelect
            label="Select a team member"
            options={valueForSelect}
            id={fieldSearchValuesName}
            name={fieldSearchValuesName}
            firstOption={{
              label: "Select a team member",
              value: ""
            }}
            variant="middle"
            onChange={
              handleSelectValues as (
                e: React.FormEvent<HTMLSelectElement>
              ) => void
            }
          />

          <PreferredList
            keywords={currentSelectValues}
            valueForSelect={valueForSelect}
            onKeywordRemove={(value) =>
              removeArrayBasedInternalFilter(fieldSearchValuesName, value)
            }
          />
        </>
      ) : (
        <FormikSelect
          label="Select a team member"
          options={valueForSelect}
          id={fieldSearchValuesName}
          name={fieldSearchValuesName}
          variant="middle"
          firstOption={{
            label: "Select a team member",
            value: ""
          }}
          onChange={handleSelectValue}
          controlled
          value={currentSelectValues[0]}
        />
      )}
    </Div>
  )
}
