import React, { PropsWithChildren } from "react"
import { compose } from "utils/compose"

type EnhancedRouteProps = {
  validators?: ((Component: () => JSX.Element) => () => JSX.Element | null)[]
  component: React.ComponentType<any>
}

export const ValidatedRoute = ({
  validators = [],
  component
}: EnhancedRouteProps) => {
  const Composed = component
    ? compose(...validators)(
        component as React.ComponentType<PropsWithChildren<any>>
      )
    : component
  return Composed ? <Composed /> : Composed
}
