import React from "react"
import { Formik, FormikHelpers, FormikValues } from "formik"
import { addNoteValidationSchema } from "./schema"
import { NoteEditFormProps } from "./types"
import NoteFormLayout from "./NoteFormLayout"
import { NoteEditValues } from "../../types"
import { Button, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { StyledDiv, StyledForm } from "../style"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"

type FormReferenceType = ((instance: any) => void) &
  React.MutableRefObject<FormikValues>

type onSubmitType = (
  values: NoteEditValues,
  formikHelpers: FormikHelpers<NoteEditValues>
) => void

export const NoteEditForm = React.forwardRef(
  (props: NoteEditFormProps, ref) => {
    const { onSubmit: onSubmitFromProps, initialValues } = props

    const { close } = useModal()

    const onSubmit: onSubmitType = (values, actions) => {
      onSubmitFromProps(
        {
          ...values
        },
        actions
      )
    }

    return (
      <StyledDiv>
        <Formik
          innerRef={ref as FormReferenceType}
          initialValues={initialValues}
          validationSchema={addNoteValidationSchema}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <StyledForm>
                <ModalSectionHeader
                  size="xSmall"
                  title={messages.person.note.editNote}
                  actions={
                    <>
                      <Button type="submit" size="small" mr="xxs">
                        {messages.form.generic.save}
                      </Button>
                      <Button
                        size="small"
                        mode="standard-white"
                        onClick={() => close(ModalNames.EditNoteItemData)}
                      >
                        {messages.generic.cancel}
                      </Button>
                    </>
                  }
                />
                <NoteFormLayout />
              </StyledForm>
            )
          }}
        </Formik>
      </StyledDiv>
    )
  }
)
