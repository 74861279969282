import React, { useState } from "react"
import { Signup as SignupForm } from "views/users/signup/form/Signup"
import { Link } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import Extension from "@ikiru/talentis-fpc/dist/assets/extension/extension.png"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { H2, H4, H5 } from "@ikiru/talentis-fpc"
import { FullWoIcon as FullWo } from "@ikiru/talentis-fpc"
import ExtensionMobile from "@ikiru/talentis-fpc/dist/assets/extension/extension-mobile.png"
import { getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useAuth } from "setup/auth/module/auth.context"
import {
  browserExtensionDownloadUrl,
  isBrowserExtensionSupported
} from "setup/browser-extension/helpers"
import { ExtensionPicture, BrowserExtensionLinkWrapper } from "./style"
import { Helmet } from "react-helmet"

const isExtensionSupported = isBrowserExtensionSupported()

export const Signup = () => {
  const { login } = useAuth()
  const [isSignedUp, setIsSignedUp] = useState(false)

  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.signUp)}</title>
      </Helmet>
      <Flex
        width="100%"
        overflowY="auto"
        bg="green.dark"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
      >
        <Div width="100%">
          <Flex my="l" width="100%" alignItems="center" justifyContent="center">
            <FullWo height="42px" />
          </Flex>
        </Div>
        <Div width={["100%", "100%", "420px"]}>
          <Flex
            flexWrap="wrap"
            bg="grey.lightest"
            p={["xxs", "xl"]}
            alignItems="center"
            justifyContent="center"
          >
            <SignupForm onSuccess={() => setIsSignedUp(true)} />
          </Flex>
          <Div mb="xs" textAlign="center" width="100%">
            <H4 mt="m" mb="xxs" color="white.standard">
              {messages.signup.alreadyGotAnAccount}
            </H4>
            <Link onClick={login} variant="white">
              {messages.signup.logIn}
            </Link>
          </Div>
        </Div>
        {isSignedUp && isExtensionSupported && (
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent={["space-evenly", "space-evenly", "auto"]}
            mt="m"
            pt={["xl", "xl", "none"]}
            flexGrow={1}
            width="100%"
            bg="green.darkest"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
              width={[1, 1, 1 / 2]}
              px={["xxs", "xxs", "m", "xxl"]}
              mx={["xxs", "xxs", "m", "xxl"]}
            >
              <H5 my={0} color="white.standard">
                {messages.signup.extension.getReadyToUse}
              </H5>
              <H2 my={0} color="white.standard">
                {messages.signup.extension.installBrowserExtensionDescription}
              </H2>
              <BrowserExtensionLinkWrapper
                target="_blank"
                href={browserExtensionDownloadUrl}
              >
                <Button mt="m" mx="auto">
                  {messages.signup.extension.installBrowserExtension}
                </Button>
              </BrowserExtensionLinkWrapper>
            </Flex>
            <Flex
              py="m"
              px={["xl", "xl", "xs"]}
              alignItems="center"
              flexDirection="column"
              width={[1, 1, 1 / 2]}
              position="relative"
            >
              <ExtensionPicture>
                <source
                  media={getMinWidthMediaQuery("sm")}
                  srcSet={Extension}
                />
                <img
                  src={ExtensionMobile}
                  alt={messages.signup.extension.installBrowserExtension}
                />
              </ExtensionPicture>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  )
}
