import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { Assignment } from "views/assignments/assignment.types"
import { StatusTabDetails } from "views/assignments/components/assignments-group"
import { pageSize } from "views/assignments/constants/definitions"

export const getAssignments = async (
  pageNumber = 1,
  selectedStatus = "",
  defaultStatusArray?: StatusTabDetails[],
  name?: string,
  totalItemCount?: number,
  companyId?: string,
  showMyAssignmentsOnly?: boolean
) => {
  const status = selectedStatus !== "all" ? selectedStatus : ""
  const [, response] = await apiRequest.get({
    endpoint: AssignmentsEndpoints.Root,
    config: {
      params: {
        pageSize: totalItemCount ? totalItemCount : pageSize,
        page: pageNumber,
        status: status,
        name,
        ...(companyId ? { companyId } : {}),
        showMyAssignmentsOnly: showMyAssignmentsOnly
      }
    }
  })

  const [, statuesResponse] = await apiRequest.get({
    endpoint: AssignmentsEndpoints.Statuses,
    config: { params: { name, companyId, showMyAssignmentsOnly } }
  })

  const statusDetails = statuesResponse?.data || []
  let totalCount = 0
  statusDetails?.forEach((status: StatusTabDetails) => {
    totalCount = totalCount + status.count
  })

  let statusDetailsArray: StatusTabDetails[] = [
    { data: "all", count: totalCount }
  ]
  statusDetails.forEach((status: StatusTabDetails) =>
    statusDetailsArray.push({ data: status.data, count: status.count })
  )

  defaultStatusArray?.forEach((status: StatusTabDetails) => {
    const isActive = statusDetailsArray.find(
      (activeStatus: StatusTabDetails) => activeStatus.data === status.data
    )
    if (!isActive) {
      statusDetailsArray.push({ data: status.data, count: 0 })
    }
  })

  const selectStatusCount = statusDetailsArray.find(
    (status: StatusTabDetails) => status.data === selectedStatus
  )
  const assignments: Assignment[] = response?.data?.assignments || []
  const currentCount =
    selectedStatus === "all" ? totalCount : selectStatusCount?.count || 0
  const pageCount = currentCount > pageSize ? currentCount / pageSize : 0

  const assignmentsNames: string[] = assignments.map(
    (assignment: any) => assignment.name
  )

  return {
    totalCount,
    assignments,
    assignmentsNames,
    currentCount,
    pageCount,
    statusDetailsArray
  }
}
