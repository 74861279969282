import React, { useCallback } from "react"
import get from "lodash/get"
import {
  Div,
  getMinWidthMediaQuery,
  H4,
  Section,
  Toggle
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { InterviewProgressStage } from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { useStageCandidates } from "./hooks"
import { useMediaQuery } from "utils/hooks/use-media-query"
import CandidateRecord from "../CandidateRecord"

type StageCandidatesSectionProps = {
  stage: InterviewProgressStage
}

const StageCandidatesSection = ({ stage }: StageCandidatesSectionProps) => {
  const {
    photos,
    candidates,
    selectedCandidatesCount,
    areAllSelected,
    onToggleStage,
    onToggleCandidate,
    onToggleShowToClient
  } = useStageCandidates(stage)

  const ToggleAction = useCallback(
    () => (
      <Toggle
        small
        hideUnderline
        type="checkbox"
        checked={areAllSelected}
        label={messages.clientPortal.showAll}
        onChange={onToggleStage}
      />
    ),
    [areAllSelected, onToggleStage]
  )

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)
  const isCandidatesExist = Boolean(candidates.length)

  return (
    <Section
      key={stage}
      // @ts-ignore
      expandable
      subHeader={isMobile ? <ToggleAction /> : null}
      header={{
        // @ts-ignore
        size: "xxSmall",
        title: get(messages, `assignment.interviewStage.${stage}`),
        itemsCount: isCandidatesExist
          ? messages.clientPortal.selectedCandidates.format(
              selectedCandidatesCount,
              candidates.length
            )
          : undefined,
        titleProps: {
          as: H4,
          my: 0,
          p: 0
        },
        actions: isMobile ? null : <ToggleAction />
      }}
      bodyProps={{ py: "none", pb: !isCandidatesExist ? "none" : "xs" }}
      mt={2}
    >
      <Div pb={candidates.length > 0 ? "xs" : "none"} width="100%">
        {candidates.map((candidate) => (
          <Div key={candidate.id} mt="xs" width="100%">
            <CandidateRecord
              {...{ candidate }}
              photo={photos?.[candidate.personId]}
              onToggle={onToggleCandidate(candidate)}
              onToggleShowToClient={onToggleShowToClient(candidate)}
            />
          </Div>
        ))}
      </Div>
    </Section>
  )
}

export default StageCandidatesSection
