import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { Flex, H4, Overlay } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useSearch } from "views/search/SearchModule/context"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { useMandatoryFiltersWereSet } from "../hooks"
import { Filters } from "../../../../SearchModule/types"
import { debounce } from "lodash"
import { InputWrapper } from "./style"

export const CommonExperienceFilter = ({
  componentLabel,
  filterMaxName,
  filterMinName
}: {
  componentLabel: string
  filterMaxName: keyof Filters
  filterMinName: keyof Filters
}) => {
  const { filters, clearFilters, updateFilters } = useSearch()
  const [to, setTo] = useState<string>()
  const [from, setFrom] = useState<string>()
  const mandatoryFiltersWereSet = useMandatoryFiltersWereSet()
  const currentMin = filters[filterMinName] as string
  const currentMax = filters[filterMaxName] as string

  useEffect(() => {
    if (!mandatoryFiltersWereSet && currentMin) {
      clearFilters({
        [filterMinName]: undefined
      })
    }
  }, [mandatoryFiltersWereSet, clearFilters, currentMin, filterMinName])

  useEffect(() => {
    if (!mandatoryFiltersWereSet && currentMax) {
      clearFilters({
        [filterMaxName]: undefined
      })
    }
  }, [mandatoryFiltersWereSet, clearFilters, currentMax, filterMaxName])

  const onInputMin = (value: string) =>
    updateFilters({ [filterMinName]: value })

  const onInputMax = (value: string) =>
    updateFilters({ [filterMaxName]: value })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputMaxDebounced = useCallback(
    debounce((value) => {
      onInputMax(value)
    }, 1000),
    []
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputMinDebounced = useCallback(
    debounce((value) => {
      onInputMin(value)
    }, 1000),
    []
  )
  useEffect(() => {
    setFrom(currentMin || "0")
  }, [currentMin])
  useEffect(() => {
    setTo(currentMax || "")
  }, [currentMax])
  return (
    <>
      <Overlay opacity={0.5} visible={!mandatoryFiltersWereSet} />
      <Flex width="100%" flexWrap="wrap">
        <H4 mt="none" mb="xxs" color="grey.dark">
          {componentLabel}
        </H4>
        <Flex>
          <InputWrapper width="50%">
            <FormikInput
              type="number"
              name={filterMinName}
              id={filterMinName}
              label={messages.talentGraphSearch.filters.from}
              onClick={(e) => {
                ;(e.target as HTMLInputElement)?.select()
              }}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (Number(e.target.value) > 100) {
                  return false
                }
                onInputMinDebounced(e.target.value)
                setFrom(e.target.value)
              }}
              value={from}
            />
          </InputWrapper>
          <InputWrapper width="50%" ml="xxs">
            <FormikInput
              type="number"
              name={filterMaxName}
              id={filterMaxName}
              label={messages.talentGraphSearch.filters.to}
              onClick={(e) => {
                ;(e.target as HTMLInputElement)?.select()
              }}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (Number(e.target.value) > 100) {
                  return false
                }
                onInputMaxDebounced(e.target.value)
                setTo(e.target.value)
              }}
              value={to}
            />
          </InputWrapper>
        </Flex>
      </Flex>
    </>
  )
}
