import styled from "styled-components"
import { Flex, H6, SmallText, spacing } from "@ikiru/talentis-fpc"

export const StyledOptions = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: ${spacing.xs}px;
`

export const StyledWrapper = styled(Flex)`
  background-color: white;
  width: 100%;
  flex-direction: column;
  padding-left: ${spacing.xxs}px;
  margin-top: ${spacing.m}px;
  margin-bottom: ${spacing.xs}px;
`

export const StyledName = styled(H6)`
  flex: 0 1 100px;
  max-width: 100px;
  margin: 0;
`

export const StyledAddress = styled(SmallText)`
  flex: 0 1 200px;
  max-width: 200px;
  margin: 0;
`
