import React, { useMemo } from "react"
import { Div, Flex, colors } from "@ikiru/talentis-fpc"
import { sortByStartDate } from "utils/sort"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import EducationCard from "../../cv-parsing/parsing/components/Skills/EducationCard"

const PersonEducationView = () => {
  const { person } = usePerson()

  const education = useMemo(() => {
    let education = person?.education || []
    const currentExp = education
      .filter((exp) => Boolean(exp.startDate) && !Boolean(exp.endDate))
      .sort((expA, expB) => sortByStartDate(expA.startDate, expB.startDate))

    const prevExp = education
      .filter((exp) => Boolean(exp.startDate) && Boolean(exp.endDate))
      .sort((expA, expB) => sortByStartDate(expA.startDate, expB.startDate))

    const noDates = education.filter((exp) => !Boolean(exp.startDate))

    return [...currentExp, ...prevExp, ...noDates]
  }, [person?.education])

  return (
    <Flex flexDirection="column" width="100%" flexWrap="wrap">
      {education.map(
        ({ value, institute, startDate, endDate, id }, index, array) => {
          return (
            <Flex
              key={`${index}-${id}`}
              borderBottom={
                array.length - 1 === index
                  ? undefined
                  : `1px solid ${colors.grey.light}`
              }
              mt="xs"
            >
              <Div pb="xs">
                <EducationCard
                  value={value}
                  institute={institute?.name}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Div>
            </Flex>
          )
        }
      )}
    </Flex>
  )
}

export default PersonEducationView
