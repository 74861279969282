import React, { useEffect } from "react"
import { AISearchContainer } from "./style"
import {
  BodyText,
  Button,
  Div,
  Flex,
  H4,
  H5,
  PositiveIcon
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { BoldText } from "./components/FinishedSearch/style"
import LoadingSearch from "./components/LoadingSearch"

type SimilarPersonAiSearchProps = {
  personId: string
  getSimilarPeople: (personId: string) => Promise<void>
  isLoading: boolean
  name: string
}
const SimilarPersonAiSearch = ({
  personId,
  getSimilarPeople,
  isLoading,
  name
}: SimilarPersonAiSearchProps) => {
  useEffect(() => {
    if (personId) {
      getSimilarPeople(personId)
    }
  }, [getSimilarPeople, personId])

  return (
    <AISearchContainer>
      {isLoading ? (
        <LoadingSearch />
      ) : (
        <>
          <H4 mt="xl" mb="s">
            {messages.assignment.similarPeople.findSimilarPeople}
          </H4>
          <H5 mt="none" mb="s">
            "{name}"
          </H5>
          <Flex flexDirection="column" alignItems="center">
            <Flex width="100%" alignItems="center" justifyContent="center">
              <Div mb="-3px" mr="xxs">
                <PositiveIcon width={18} height={18} />
              </Div>
              <BodyText m="0px">
                {messages.search.chatGPT.searchIsReady}
              </BodyText>
            </Flex>
            <BodyText textAlign="center" mt="7px" px="m">
              {messages.search.chatGPT.reviewAndMakeChanges}
              <BoldText> "{messages.search.chatGPT.runAgain}" </BoldText>
              {messages.search.chatGPT.toSeeCandidates}
            </BodyText>
            <Button
              mt="s"
              mode="standard-white"
              onClick={() => getSimilarPeople(personId)}
              size="small"
            >
              {messages.search.chatGPT.notHappy}
            </Button>
          </Flex>
        </>
      )}
    </AISearchContainer>
  )
}

export default SimilarPersonAiSearch
