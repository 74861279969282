export enum LinkNote {
  None = "none",
  Assignment = "assignment",
  Campaign = "campaign"
}

export enum LinkNoteField {
  LinkNote = "linkNote"
}

export enum PersonNoteField {
  NoteTitle = "noteTitle",
  NoteDescription = "noteDescription",
  AssignmentId = "assignmentId",
  CampaignId = "campaignId"
}

export const initialDefaultPersonNoteState = {
  [PersonNoteField.NoteTitle]: "",
  [PersonNoteField.NoteDescription]: "",
  [PersonNoteField.AssignmentId]: "",
  [PersonNoteField.CampaignId]: ""
}

export const personNotesFormE2ETargets = {
  note: "note",
  noteForm: "note-form",
  noteTitle: "note-title",
  noteDescription: "note-description",
  noteAssignment: "note-assignment",
  noteLinkedAssignment: "note-linked-assignment",
  noteAssignmentTag: "note-assignment-tag",
  editNote: "edit-note-button",
  noteCampaign: "note-campaign",
  noteLinkedCampaign: "note-linked-campaign"
}

export const personNotesInitialTouched = {
  [PersonNoteField.NoteTitle]: true,
  [PersonNoteField.NoteDescription]: true,
  [PersonNoteField.AssignmentId]: true,
  [PersonNoteField.CampaignId]: true
}
