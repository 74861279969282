import React, { useState, useRef } from "react"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { useSearch } from "views/search/SearchModule/context"
import { FilterType } from "views/search/SearchModule/types"
import { SearchCriteriaField } from "../definitions"
import { CurrentPastBothToggle } from "../components/CurrentPastBothToggle"

import { IndustryList } from "./IndustryList"
import { industries } from "./constants"

export const IndustryFilter = () => {
  const { filters, removeArrayBasedFilter, updateFilters } = useSearch()
  const [isIndustryListVisible, setIsIndustryListVisible] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [hoveredIndex, setHoveredIndex] = useState(-1)

  const filteredIndustries = industries.filter((industry) => {
    return (
      !filters?.industries?.includes(industry) &&
      industry.toLowerCase().includes(keyword.toLowerCase())
    )
  })

  const inputRef = useRef()

  const onChange = (e: any) => {
    setKeyword(e.target.value)

    if (!isIndustryListVisible) {
      setIsIndustryListVisible(true)
    }
  }

  const onKeyDown = (e: any) => {
    if (e.keyCode === 38) {
      // UpArrow
      setSelectedIndex(
        selectedIndex <= 0 ? filteredIndustries.length - 1 : selectedIndex - 1
      )
      e.preventDefault()
      e.stopPropagation()
    } else if (e.keyCode === 40) {
      // DownArrow
      setSelectedIndex(
        selectedIndex >= filteredIndustries.length - 1 ? 0 : selectedIndex + 1
      )
      e.preventDefault()
      e.stopPropagation()
    } else if (e.keyCode === 13) {
      e.preventDefault()
      onItemClicked(filteredIndustries[selectedIndex])
      setSelectedIndex(-1)
    } else {
      setSelectedIndex(-1)
    }
  }

  const onClick = () => {
    setIsIndustryListVisible(true)
  }

  const onFocus = () => {
    setIsIndustryListVisible(true)
  }

  const onBlur = () => {
    setTimeout(() => {
      setIsIndustryListVisible(false)
    }, 300)
  }

  const onItemClicked = (industry: string) => {
    industry &&
      updateFilters({
        [FilterType.Industries]: [...(filters.industries || []), industry]
      })
    setKeyword("")
    setIsIndustryListVisible(false)
    ;(inputRef.current as unknown as HTMLElement)?.focus?.()
  }

  return (
    <Div width="100%" position="relative" zIndex={2}>
      <FormikInput
        name={SearchCriteriaField.Industries}
        label={messages.talentGraphSearch.filters.sector}
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={keyword}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
        autoComplete="off"
        disableDefaultOnBlur
        data-lpignore
        status="default"
      />
      {isIndustryListVisible && (
        <IndustryList
          industries={filteredIndustries}
          selectedIndex={selectedIndex}
          hoveredIndex={hoveredIndex}
          setHoveredIndex={setHoveredIndex}
          onItemClicked={onItemClicked}
        />
      )}
      <KeywordsList
        keywords={filters?.[FilterType.Industries] || []}
        onKeywordRemove={(value) =>
          removeArrayBasedFilter(FilterType.Industries, value)
        }
      />
      <CurrentPastBothToggle
        fieldName={SearchCriteriaField.IndustriesLogic}
        filterType={FilterType.IndustriesLogic}
      />
    </Div>
  )
}
