import styled, { css } from "styled-components"
import styledCss from "@styled-system/css"
import { H5Style as H5 } from "@ikiru/talentis-fpc"
import { activeLinkClassName } from "components/visual/header/constants/header.definitions"

export const LinksContainer = styled.div``

export const LinkIcon = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space.xs}px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 50%;
  border: 2px solid;
  & svg {
    max-height: 100%;
    max-width: 100%;
    fill: ${({ theme }) => theme.colors.grey.dark};
  }
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    height: 30px;
    width: 30px;
    margin-right: 0px;
  }
`

export const LinkText = styled.span`
  ${styledCss(H5)}
  color: ${({ theme }) => theme.colors.grey.dark};
  margin-bottom: 0;
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    margin-left: 5px;
  }
`

const activeLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.grey.dark};

  & svg {
    fill: ${({ theme }) => theme.colors.white.standard};
  }
`

const hoverLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.darkest};
  background-color: ${({ theme }) => theme.colors.grey.light};

  & svg {
    fill: ${({ theme }) => theme.colors.grey.darkest};
  }
`

export const outlookActiveLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.grey.dark};

  & svg {
    path:first-child {
      fill: ${({ theme }) => theme.colors.white.standard};
      stroke: ${({ theme }) => theme.colors.white.standard};
      stroke-width: 0;
    }
    path:nth-child(2) {
      stroke: ${({ theme }) => theme.colors.white.standard};
    }
    path:nth-child(3) {
      fill: ${({ theme }) => theme.colors.white.standard};
    }
  }
`

export const reportActiveLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.grey.dark};

  & svg {
    path:first-child {
      fill: ${({ theme }) => theme.colors.grey.dark};
      stroke: ${({ theme }) => theme.colors.white.standard};
    }
    path:nth-child(2) {
      stroke: ${({ theme }) => theme.colors.white.standard};
    }
    path:nth-child(3) {
      fill: ${({ theme }) => theme.colors.white.standard};
    }
  }
`

const supportActiveLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.grey.dark};

  & svg {
    path:first-child {
      fill: ${({ theme }) => theme.colors.white.standard};
    }
    path:nth-child(2) {
      fill: ${({ theme }) => theme.colors.grey.dark};
    }
    path:nth-child(3) {
      stroke: ${({ theme }) => theme.colors.grey.dark};
    }
    path:nth-child(4) {
      fill: ${({ theme }) => theme.colors.white.standard};
    }
    path:nth-child(5) {
      fill: ${({ theme }) => theme.colors.grey.dark};
    }
  }
`

const getActiveClass = (activeClassCustom?: string) => {
  switch (activeClassCustom) {
    case "outlook-link-active":
      return outlookActiveLinkCss
    case "reports-link-active":
      return reportActiveLinkCss
    case "support-link-active":
      return supportActiveLinkCss
    default:
      return activeLinkCss
  }
}

type LinkWrapperProps = {
  activeClassCustom?: string
  disabled?: boolean
}

export const LinkWrapper = styled.div<LinkWrapperProps>`
  text-decoration: none !important;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.xs}px;
    @media only screen and (min-height: 394px) and (max-height: 695px) {
      margin-bottom: 5px;
    }
  }

  &.${activeLinkClassName} ${LinkIcon} {
    ${({ activeClassCustom }) => getActiveClass(activeClassCustom)}
  }

  ${({ disabled, theme }) =>
    !disabled
      ? `
      cursor: pointer;

      &:not(.${activeLinkClassName}):not(:focus):hover ${LinkText} {
        color: ${theme.colors.grey.darkest};
      }
  
      &:hover ${LinkIcon} {
        ${hoverLinkCss}
      }

      &.${activeLinkClassName}:hover ${LinkIcon} {
        background-color: ${theme.colors.grey.darkest};
        & svg {
          fill: ${theme.colors.white.standard};
        }
      }
  `
      : `
    cursor: default;
    opacity: 0.3;
  `}
`
