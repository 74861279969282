import { Button, colors, CrossIcon, Flex } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"
import NoteForm from "."
import { Overlay } from "./styles"
import { NoteFormWrapperProps } from "./types"

const NoteFormWrapper = ({ onCancel, ...props }: NoteFormWrapperProps) => {
  return (
    <>
      <Overlay />
      <NoteForm
        {...props}
        formActionsComponent={(formik) => {
          const { isSubmitting, resetForm } = formik

          return (
            <Flex justifyContent="flex-end" width="100%" mb="none">
              <Button
                type="submit"
                size="extra-small"
                mr="xs"
                disabled={isSubmitting}
              >
                {!!props?.note ? messages.generic.update : messages.generic.add}
              </Button>
              <Button
                mode="standard-grey-light"
                size="action-small"
                disabled={isSubmitting}
                type="button"
                onClick={() => {
                  resetForm()
                  onCancel?.()
                }}
              >
                <CrossIcon fill={colors.grey.dark} />
              </Button>
            </Flex>
          )
        }}
      />
    </>
  )
}

export default React.memo(NoteFormWrapper)
