import React, { useCallback } from "react"
import { ArrayHelpers } from "formik"
import { Button } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"

const e2eTargetActionNames = {
  REMOVE: "remove"
}

type FieldArrayActionButtonsProps = ArrayHelpers & {
  index: number
  e2eTarget: string
}

export const FieldArrayActionButtons = React.memo(
  ({ remove, e2eTarget, index }: FieldArrayActionButtonsProps) => {
    const handleRemove = useCallback(
      (e: any) => {
        e.stopPropagation()
        remove(index)
      },
      [remove, index]
    )

    return (
      <Div
        style={{
          display: "flex",
          alignItems: "flex-end"
        }}
      >
        <Button
          data-e2e-target-name={`${e2eTarget}-${index}-${e2eTargetActionNames.REMOVE}`}
          onClick={handleRemove}
          mx={5}
          ml={10}
          type="button"
          size="action-small"
          colorway={{ group: "grey", variant: "lightest" }}
          hoverColorway={{ group: "grey", variant: "light" }}
        >
          <CrossIcon />
        </Button>
      </Div>
    )
  }
)
