import { useCallback } from "react"
import { useAppInsights } from "setup/app-insights/Context"
import {
  CandidateAddedEventData,
  InsightsEventName,
  CandidateAssignemntEventData
} from "setup/app-insights/definitions"
import { useAuth } from "setup/auth/module/auth.context"
import { TalentisUser } from "setup/auth/module/constants/auth.types"
import { Tag } from "components/Tags/tags.types"

export const useTelemetry = () => {
  const { trackEvent } = useAppInsights()
  const { user } = useAuth()

  const trackAddCandidate = useCallback(
    (data: CandidateAddedEventData) => {
      trackEvent(
        { name: InsightsEventName.CandidateAdded },
        { ...data, searchFirmId: user?.profile?.SearchFirmId }
      )
    },
    [trackEvent, user]
  )

  const trackSubscriptionExpired = useCallback(
    (data: TalentisUser) => {
      trackEvent({ name: InsightsEventName.SubscriptionExpired }, data)
    },
    [trackEvent]
  )

  const trackCandidateAssignToSet = useCallback(
    (data: CandidateAssignemntEventData) => {
      trackEvent(
        { name: InsightsEventName.CandidateAssignToSet },
        {
          ...data,
          searchFirmId: user?.profile?.SearchFirmId,
          userId: user?.profile?.UserId
        }
      )
    },
    [trackEvent, user]
  )

  const trackCandidateDueDateSet = useCallback(
    (data: CandidateAssignemntEventData) => {
      trackEvent(
        { name: InsightsEventName.CandidateDueDateSet },
        {
          ...data,
          searchFirmId: user?.profile?.SearchFirmId,
          userId: user?.profile?.UserId
        }
      )
    },
    [trackEvent, user]
  )

  const trackCandidateCompletedOrRemoveSet = useCallback(
    (data: CandidateAssignemntEventData) => {
      trackEvent(
        { name: InsightsEventName.CandidateCompletedOrRemoveSet },
        {
          ...data,
          searchFirmId: user?.profile?.SearchFirmId,
          userId: user?.profile?.UserId
        }
      )
    },
    [trackEvent, user]
  )

  const trackOnBoardingAssignmentFormShown = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.OnboardingAssignmentShown },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackOnBoardingAssignmentCompleted = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.OnboardingAssignmentCompleted },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackAssignmentView = useCallback(
    (data: CandidateAssignemntEventData) => {
      trackEvent(
        { name: InsightsEventName.AssignmentView },
        {
          ...data,
          searchFirmId: user?.profile?.SearchFirmId
        }
      )
    },
    [trackEvent, user]
  )

  const trackAssignmentFilterEnabled = useCallback(
    (data: CandidateAssignemntEventData) => {
      trackEvent(
        { name: InsightsEventName.AssignemntFilterEnabled },
        {
          ...data,
          searchFirmId: user?.profile?.SearchFirmId
        }
      )
    },
    [trackEvent, user]
  )

  const trackExportDisabled = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.ExportDisabled },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackSavedSearches = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.SavedSearches },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackAssignmentTags = useCallback(
    (data: Tag[]) => {
      trackEvent(
        { name: InsightsEventName.AssignmentTagsChanged },
        {
          data,
          searchFirmId: user?.profile?.SearchFirmId,
          userId: user?.profile?.UserId
        }
      )
    },
    [trackEvent, user]
  )

  const trackAddTagsToCandidate = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.AddTagsToCandidate },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackSearchXray = useCallback(
    (female: boolean) => {
      trackEvent(
        { name: InsightsEventName.SearchInXray },
        {
          female: female,
          origin: "WebApp"
        }
      )
    },
    [trackEvent]
  )

  const trackAISearchUse = useCallback(() => {
    trackEvent(
      { name: InsightsEventName.GPTAISearch },
      {
        searchFirmId: user?.profile?.SearchFirmId,
        userId: user?.profile?.UserId
      }
    )
  }, [trackEvent, user])

  const trackN360Error = useCallback(
    (n360Id: string) => {
      trackEvent(
        { name: InsightsEventName.QuickSearchErrorN360GetID },
        {
          n360Id,
          searchFirmId: user?.profile?.SearchFirmId,
          userId: user?.profile?.UserId
        }
      )
    },
    [trackEvent, user]
  )

  return {
    trackAddCandidate,
    trackSubscriptionExpired,
    trackCandidateAssignToSet,
    trackCandidateDueDateSet,
    trackCandidateCompletedOrRemoveSet,
    trackAssignmentView,
    trackAssignmentFilterEnabled,
    trackOnBoardingAssignmentCompleted,
    trackOnBoardingAssignmentFormShown,
    trackExportDisabled,
    trackSavedSearches,
    trackAssignmentTags,
    trackAddTagsToCandidate,
    trackSearchXray,
    trackAISearchUse,
    trackN360Error
  }
}
