import styled from "styled-components"
import { Flex } from "@ikiru/talentis-fpc"

export const SearchUpHeader = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey.dark};
  align-items: center;
  position: sticky;
  z-index: 3;
  top: 0;
  justify-content: space-between;
`
