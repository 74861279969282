import styled from "styled-components"
import { Button, Div, ShareIcon, colors } from "@ikiru/talentis-fpc"

export const StyledDiv = styled(Div)`
  display: inline-block;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors?.grey?.light};
  border-radius: 6px;
  padding: ${({ theme }) => theme.space.m}px;
  margin-top: ${({ theme }) => theme.space.m}px;
`

export const StyledButton = styled(Button)`
  background: ${colors.grey.standard};
  color: ${colors.white.standard};
  border-color: ${({ theme }) => theme.colors?.grey?.standard};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.standard};
    border-color: ${({ theme }) => theme.colors?.grey?.standard};
    color: ${colors.white.standard};
  }
`
export const StyledShareIcon = styled(ShareIcon)`
  width: 12px;
  height: 12px;
  fill: ${colors.white.standard} !important;
  g {
    fill: ${colors.white.standard} !important;
  }
`
export const StyledLink = styled.a`
  text-decoration: none !important;
`
