import { useState, useEffect } from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"

export const useOnDocumentsLoad = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { setDocuments, personId } = usePerson()

  useEffect(() => {
    const populateDocuments = async () => {
      if (personId) {
        const [, response] = await apiRequest.get({
          endpoint: PersonsEndpoints.Documents,
          endpointParams: personId
        })

        setDocuments(response?.data?.documents)
        setIsLoading(false)
      } else {
        setDocuments([])
        setIsLoading(false)
      }
    }
    populateDocuments()
  }, [personId, setDocuments])

  return {
    isLoading
  }
}
