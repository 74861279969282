import { Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const CompanyDetailsContainer = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-items: start;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  padding: 0px;

  ${({ theme }) => `
  ${theme.mediaQueries.sm} {
    display: grid;
    grid-template-columns: repeat(4, minmax(max-content, auto));
  }
`};
`
