import React from "react"
import { Flex, BodyText } from "@ikiru/talentis-fpc"
import { formatDate } from "utils/format-date"
import { SectionTitle } from "../../../style"

interface DetailSectionProps {
  title: string
  data: string | number | boolean | Date | null | undefined
  isDate?: string | Date | null
  minHeight?: string
}

const DetailSection: React.FC<DetailSectionProps> = ({
  title,
  data,
  isDate = false
}) => {
  const dataValue = isDate
    ? data
      ? formatDate(data as Date)
      : ""
    : String(data || "")
  const minHeight = dataValue ? "auto" : "21px"

  return (
    <Flex flexDirection="column" py="xs" alignItems="flex-start" width="100%">
      <SectionTitle>{title}</SectionTitle>
      <BodyText my="0" minHeight={minHeight}>
        {dataValue}
      </BodyText>
    </Flex>
  )
}

export default DetailSection
