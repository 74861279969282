import { Grid, SmallText, Toggle } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const NestedInputContainer = styled(Grid)`
  padding: 0px;
  grid-template-columns: 200px 3fr 90px;
  margin-top: 40px;
  gap: 10px;
`
export const SubHeaderText = styled(SmallText)`
  font-size: 15px;
  color: ${({ theme }) => theme?.colors?.grey.standard};
  font-weight: 700;
  margin: 0px;
  align-self: center;
`

export const ToggleCheckBoxStyle = styled(Toggle)`
  margin: 55px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
`
