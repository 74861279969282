import React, { useMemo } from "react"
import {
  Flex,
  Tag,
  BodyText,
  Div,
  colors,
  SmallText,
  H5
} from "@ikiru/talentis-fpc"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import AssignAttributes from "../assign-attributes/AssignAttributes"
import { StyledPlusIcon } from "../../styles"
import PersonKeywords from "./PersonKeywords"

type AttributesListTreeProps = {
  attributes: any
  remove: (key: string) => void
}

const AttributesListTree = ({
  attributes,
  remove
}: AttributesListTreeProps) => {
  const { openInPortal } = useModal()

  const assignAttributes = (keyNode: string) => {
    openInPortal(
      ModalNames.AssignAttributes,
      <AssignAttributes keyNode={keyNode} />
    )
  }

  const renderTree = (nodes, path = "") => {
    return nodes.map((node) => {
      if (!node.isChecked) {
        return null
      }

      const currentPath = path ? `${path} • ${node.title}` : node.title

      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        if (node.children.every((child) => !child.children)) {
          const checkedChildren = node.children.filter(
            (child) => child.isChecked
          )

          if (checkedChildren.length > 0) {
            return (
              <BodyText
                mt="0"
                mb="0"
                mr="0"
                fontSize="14px"
                key={node.key}
                fontWeight="600"
                color={colors.green.darkest}
              >
                {currentPath} {"•"}
                {checkedChildren.map((leaf) => (
                  <Tag
                    m="0px"
                    mb="5px"
                    ml="5px"
                    variant="green"
                    key={leaf.key}
                    onRemoveClick={() => remove(leaf.key)}
                  >
                    {leaf.title}
                  </Tag>
                ))}
              </BodyText>
            )
          }

          return null
        }

        const children = renderTree(node.children, currentPath)
        if (children) {
          return <React.Fragment key={node.key}>{children}</React.Fragment>
        }

        return null
      }

      if (node.isChecked && !node.children) {
        return (
          <BodyText
            mt="0"
            mb="0"
            mr="0"
            fontSize="14px"
            key={node.key}
            fontWeight="600"
            color={colors.green.darkest}
          >
            {path && `${path} • `}
            <Tag
              key={node.key}
              m="0px"
              mb="5px"
              variant="green"
              onRemoveClick={() => remove(node.key)}
            >
              {node.title}
            </Tag>
          </BodyText>
        )
      }

      return null
    })
  }

  const displayItems = useMemo(() => {
    return attributes?.map((attribute) => ({
      title: `${attribute.title} `,
      nodeKey: attribute.key,
      children: renderTree(attribute.children || [attribute])
    }))
  }, [attributes])

  return (
    <>
      {displayItems?.map((attribute: any) => (
        <Div key={attribute.nodeKey} mb="20px">
          <Flex alignItems="center" key={attribute.nodeKey} mb="5px">
            <H5 my="0" mr="5px">
              {attribute.title}
            </H5>
            <StyledRemoveButton
              mode="standard-white"
              size="action-small"
              onClick={() => assignAttributes(attribute.nodeKey)}
            >
              <StyledPlusIcon />
            </StyledRemoveButton>
          </Flex>
          <></>

          {attribute.children?.every((item) => item === null) ? (
            <SmallText m="0" pb="20px" color={colors.grey.standard}>
              No {attribute.title} assigned
            </SmallText>
          ) : (
            <>{attribute.children}</>
          )}
        </Div>
      ))}
      <PersonKeywords />
    </>
  )
}

export default AttributesListTree
