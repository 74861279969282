import { get } from "lodash"
import { messages } from "setup/messages/messages"
import isEmpty from "lodash/isEmpty"
import {
  PhoneUsageTypeNumExchanger,
  emailTypeNumExchanger
} from "../../person-details/utlis"
import { CVProfessionalExperience, CVSkills } from "views/persons/person.types"

export const getTypeMessage = (type: string, value: string) => {
  switch (type) {
    case "phones":
      return get(messages, `form.generic.phoneTypeLabels.${value}`)
    case "taggedEmails":
      return get(messages, `form.generic.emailTypeLabels.${value}`)
    default:
      return undefined
  }
}

export const getRegExp = (type: string) => {
  switch (type) {
    case "phones":
      return /^\+?[\d\s]*$/
    case "taggedEmails":
      return /^[^\s@]+@[^\s@]+\.[^\s@]+\s*$/
    default:
      return /^(ftp|http|https):\/\/[^ "]+$/
  }
}

export const getErrorMessage = (type: string) => {
  switch (type) {
    case "phones":
      return messages.person.details.errors.invalidPhoneNumber
    case "taggedEmails":
      return messages.person.details.errors.invalidEmail
    default:
      return messages.form.generic.errors.urlFormat
  }
}

export const filterUpdateContactInfo = (data: any) => {
  if (Array.isArray(data)) {
    return data.filter(
      (item) =>
        item && (item.isChecked || (!item.isDeleted && isEmpty(item.parsed)))
    )
  } else if (typeof data === "object" && data !== null) {
    const result = {}
    for (const key in data) {
      if (
        data[key] &&
        (data[key].isChecked ||
          (!data[key].isDeleted && isEmpty(!data[key].parsed)))
      ) {
        //@ts-ignore
        result[key] = data[key]
      }
    }
    return result
  } else {
    return data
  }
}

export const updatePersonDetails = (data: any) => {
  return (
    Object.entries(data)
      // @ts-ignore
      .filter(([_key, value]) => value.isChecked && value.parsed)
      .reduce((acc, [key, value]) => {
        // @ts-ignore
        acc[key] = value.parsed
        return acc
      }, {})
  )
}

export const updateContactInfo = (data: any) => {
  const tempContactInfo: any = {}
  for (const key in data) {
    tempContactInfo[key] = filterUpdateContactInfo(data[key])
  }

  const phoneNumbers: string[] = []
  const phones = tempContactInfo.phones.map((phone: any) => {
    const {
      isPreferred = "",
      phoneDeviceType = "",
      selectedValue = "",
      text = ""
    } = !isEmpty(phone.existing)
      ? phone.existing
      : !isEmpty(phone.parsed)
      ? phone.parsed
      : {}
    return {
      phoneNumber: text,
      phoneUsageType: selectedValue,
      phoneDeviceType: phoneDeviceType || "other",
      isPreferred: isPreferred
    }
  })

  const webSites = tempContactInfo.webSites.map((webSite: any) => {
    const { text = "" } = !isEmpty(webSite.existing)
      ? webSite.existing
      : !isEmpty(webSite.parsed)
      ? webSite.parsed
      : {}
    return {
      url: text
    }
  })

  const taggedEmails = tempContactInfo.taggedEmails.map((email: any) => {
    const {
      isPreferred = "",
      selectedValue = "",
      text = "",
      smtpValid = ""
    } = !isEmpty(email.existing)
      ? email.existing
      : !isEmpty(email.parsed)
      ? email.parsed
      : {}

    return {
      email: text,
      type: selectedValue,
      smtpValid: smtpValid,
      isPreferred: isPreferred
    }
  })

  phones.map((phoneData: any) => {
    phoneData = PhoneUsageTypeNumExchanger(phoneData)
    phoneNumbers.push(phoneData.phoneNumber)
    return phoneData
  })

  taggedEmails.map((taggedEmail: any) => {
    taggedEmail = emailTypeNumExchanger(taggedEmail)
    return taggedEmail
  })

  return {
    phones: phones[0]?.phoneNumber ? [...phones] : undefined,
    phoneNumbers: phoneNumbers.length > 0 ? phoneNumbers : undefined,
    webSites: webSites[0]?.url ? [...webSites] : undefined,
    taggedEmails: taggedEmails[0]?.email ? [...taggedEmails] : undefined
  }
}

export const getUpdatedExperience = (
  cvExperience: CVProfessionalExperience
) => {
  const existing = cvExperience.existing.filter((exp) => {
    return !exp.isMovedToParsed
  })
  const parsed = cvExperience.parsed.filter((exp) => {
    return exp.isMovedToExisting
  })

  return [...existing, ...parsed]
}

export const getUpdatedEducations = (cvEducation: CVSkills) => {
  const existing = cvEducation.existingEducation.filter((exp) => {
    return !exp.isMovedToParsed
  })
  const parsed = cvEducation.parsedEducation.filter((exp) => {
    return exp.isMovedToExisting
  })

  return [...existing, ...parsed]
}

export const getUpdatedKeywords = (cvKeywords: CVSkills) => {
  const existing = cvKeywords.existingKeywords.filter(
    (keywords) => keywords.isDeleted
  )
  const parsing = cvKeywords.parsingKeywords.filter(
    (keywords) => !keywords.isDeleted
  )

  return [...existing, ...parsing]
}

export const getSavingKeywords = (cvKeywords: CVSkills) => {
  const existing = cvKeywords.existingKeywords.filter(
    (keywords) => !keywords.isDeleted
  )
  const parsing = cvKeywords.parsingKeywords.filter(
    (keywords) => !keywords.isDeleted
  )

  return [...existing, ...parsing]
}
export const updateExperience = (cvExperience: CVProfessionalExperience) => {
  const newExperienceList = getUpdatedExperience(cvExperience)
  const normalizedExp = newExperienceList.map((exp) => {
    let tempExp = exp
    if (exp?.changedValues) {
      Object.entries(exp?.changedValues).forEach(([key, value]) => {
        tempExp = { ...tempExp, [key]: value }
      })
    }

    return tempExp
  })

  const cleanedExp = normalizedExp.map((exp, index) => {
    const tempExp = exp as any
    delete tempExp.changedValues
    delete tempExp.type
    delete tempExp.isMovedToParsed
    delete tempExp.isMovedToExisting

    return tempExp
  })

  return { experience: cleanedExp }
}

export const updateSkills = (skills: CVSkills) => {
  const newEducation = getUpdatedEducations(skills)
  const cleanedEducation = newEducation.map((edc, index) => {
    const tempEdc = edc as any
    delete tempEdc.changedValues
    delete tempEdc.type
    delete tempEdc.isMovedToParsed
    delete tempEdc.isMovedToExisting
    return tempEdc
  })
  return { education: cleanedEducation }
}

export const updateKeywords = (skills: CVSkills) => {
  const updateKeywords = getSavingKeywords(skills)

  const newKeywords = updateKeywords.map(({ isDeleted, ...rest }) => rest)

  return newKeywords
}

export const processTempValueInArray = (array: any) => {
  //@ts-ignore
  array.forEach((item) => {
    if (item.tempValue) {
      item.isChecked = true
      item.parsed = { ...item.tempValue }
      delete item.tempValue
    }
  })
}

export const removeTempValueInArray = (array: any) => {
  //@ts-ignore
  array.forEach((item) => {
    if (item.tempValue) {
      delete item.tempValue
    }
  })
}

export const processTempContactInfo = (data: any) => {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      processTempValueInArray(data[key])
    }
  }
  return data
}

export const removeTempContactInfo = (data: any) => {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      removeTempValueInArray(data[key])
    }
  }
  return data
}

export const removeTempPersonDetails = (data: any) => {
  for (const key in data) {
    if (data[key].hasOwnProperty("tempValue")) {
      delete data[key].tempValue
    }
  }
  return data
}

export const hasTempValue = (obj: any) => {
  for (const key in obj) {
    if (obj[key] && obj[key].tempValue) {
      return true
    } else if (typeof obj[key] === "object" && hasTempValue(obj[key])) {
      return true
    }
  }
  return false
}

export const hasPersonDetailsTemp = (obj: any) => {
  for (const key in obj) {
    if (obj[key].hasOwnProperty("tempValue")) {
      return true
    }
  }
}

export const processTempPersonDetails = (data: any) => {
  for (const key in data) {
    if (data[key].hasOwnProperty("tempValue")) {
      data[key].parsed = data[key].tempValue
      data[key].isChecked = true
      delete data[key].tempValue
    }
  }
  return data
}
