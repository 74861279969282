import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const StyledTooltipContent = styled(Div)`
  border: 2px solid ${({ theme }) => theme.colors?.grey?.darkest};
  border-radius: ${({ theme }) => theme.space?.xxs}px;
  background: ${({ theme }) => theme.colors?.white?.standard};
  position: absolute;
  white-space: nowrap;
`
