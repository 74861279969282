import React from "react"
import { Route, Routes } from "react-router-dom"
import FourZeroFour from "views/no-page-found/FourZeroFour"

export const CustomSwitch = (props: any) => {
  const { children } = props
  return (
    <Routes>
      {children}
      <Route path="*" element={<FourZeroFour />} />
    </Routes>
  )
}
