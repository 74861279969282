import { apiRequest } from "setup/api/api"
import { SearchEndpoints } from "setup/api/endpoints/endpoints"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { FormikMultiselectOptionType } from "components/functional/formik/formik-multiselect/FormikMultiselect"
import { AzureLocation } from "views/search/SearchModule/types"

export const isValidSearchValue = (value: string) => value.length < 2

const getLocationOptions = async (searchString: string) => {
  const [, response] = await apiRequest.get<AzureLocation[]>({
    endpoint: SearchEndpoints.GetLocations,
    config: expandEndpointByParams({ searchString })
  })

  return response?.data || []
}

export const generateTypeaheadOptions = (
  azureLocations: AzureLocation[] = []
): FormikMultiselectOptionType<AzureLocation>[] =>
  azureLocations.map((azureLocation) => ({
    label: generateLabel(azureLocation),
    value: azureLocation
  }))

export const loadAzureLocationsOptions = (
  inputValue: string,
  selectedLocations: AzureLocation[] = []
) =>
  new Promise<FormikMultiselectOptionType<AzureLocation>[]>(async (resolve) => {
    if (inputValue?.length < 2) {
      resolve([])
    } else {
      const azureLocations = await getLocationOptions(inputValue)

      const filteredOption: AzureLocation[] = azureLocations.filter(
        (l: AzureLocation) =>
          !selectedLocations.some((sl: AzureLocation) => l.id === sl.id)
      )

      const options = generateTypeaheadOptions(filteredOption || azureLocations)

      resolve(options)
    }
  })

const withComma = (message?: string) => (message ? `${message}` : "")

export const generateLabel = (location: AzureLocation) => {
  const municipalitySubdivision = withComma(
    location?.address?.municipalitySubdivision
  )
  const municipality = withComma(location?.address?.municipality)
  const countryTertiarySubdivision = withComma(
    location?.address?.countryTertiarySubdivision
  )
  const countrySecondarySubdivision = withComma(
    location?.address?.countrySecondarySubdivision
  )
  const countrySubdivision = withComma(location?.address?.countrySubdivision)

  const countrySubdivisionName = withComma(
    location?.address?.countrySubdivisionName
  )
  const country = location?.address?.country || ""
  let locationStringArray = [
    municipalitySubdivision,
    municipality,
    countryTertiarySubdivision,
    countrySecondarySubdivision,
    countrySubdivision,
    countrySubdivisionName,
    country
  ]
  const locationString = [...new Set(locationStringArray)]
    .filter((l) => l !== "")
    .join(", ")
  return locationString.replace(/\s+/g, " ").trim()
}
