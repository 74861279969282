import React, { useCallback, useEffect, useMemo } from "react"
import { messages } from "setup/messages/messages"
import { Link } from "@ikiru/talentis-fpc"
import { spacing } from "@ikiru/talentis-fpc"
import { LeftArrow } from "@ikiru/talentis-fpc"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { H1, H2, H5 } from "@ikiru/talentis-fpc"
import { StepsIndicator } from "@ikiru/talentis-fpc"
import { ExitSubscriptionPrompt } from "components/ExitSubscriptionPrompt"
import { OverlayLoader } from "components/Loader/OverlayLoader"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { OrderSuccess } from "views/subscription/components/order-success"
import {
  defaultCurrency,
  getPlanTitle
} from "views/subscription/components/plan-selection/utils"
import { flowSteps, flowStepsArray } from "./flow-steps"
import { useAuth } from "setup/auth/module/auth.context"
import { formatDate } from "utils/format-date"
import { Frequency } from "views/subscription/components/plan-details/form/constants/plan-details.definitions"
import { LegalSubtext } from "views/subscription/components/legal-subtext"
import get from "lodash/get"
import { useNavigate } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"

export const SubscriptionPurchase = () => {
  const {
    step,
    changeStep,
    purchaseData,
    paymentData,
    paymentEstimate,
    isLoading,
    loadingMessage,
    setStepTitle,
    stepTitle
  } = useSubscriptionPurchase()

  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const { paymentComplete } = paymentData

  const { planName, planCurrency, planTotalUsers, planFrequency } = useMemo(
    () => ({
      planName: purchaseData.plan ? getPlanTitle(purchaseData.plan) : "",
      planCurrency: purchaseData.planDetails
        ? purchaseData.planDetails!.currency
        : defaultCurrency,
      planTotalUsers: purchaseData.planDetails
        ? purchaseData.planDetails!.totalUsersNumber
        : 0,
      planFrequency: purchaseData.planDetails
        ? purchaseData.planDetails!.frequency
        : Frequency.Year
    }),
    [purchaseData]
  )

  // TODO: May need to update this with the response from the payment intent to ensure we have actual prices paid
  const { total, subtotal, discount, taxAmount } = useMemo(
    () => ({
      total: paymentEstimate.total || 0,
      subtotal: paymentEstimate.amount || 0,
      discount: paymentEstimate.discount || 0,
      taxAmount: paymentEstimate.taxAmount || 0
    }),
    [paymentEstimate]
  )

  const {
    previousStepLabel,
    component: SubscriptionComponent,
    footerComponent: FooterComponent,
    banners = [],
    title = "",
    legalSubText = ""
  } = useMemo(() => flowSteps?.[step] || {}, [step])

  const activeStepIndex = useMemo(
    () => flowStepsArray.findIndex?.((key) => step === key) || 0,
    [step]
  )

  useEffect(() => {
    setStepTitle(title)
  }, [setStepTitle, title])

  const goBack = useCallback(() => {
    const newStepIndex = activeStepIndex - 1

    if (newStepIndex >= 0 && newStepIndex < flowStepsArray.length)
      changeStep(flowStepsArray[newStepIndex])
  }, [activeStepIndex, changeStep])

  useEffect(() => {
    const handlePaymentComplete = async () => {
      const userId = get(user, "profile.UserId")

      if (!purchaseData.planDetails?.users.includes(userId)) {
        await logout?.()
        navigate(RouterUrl.Homepage)
      }
    }

    paymentComplete && handlePaymentComplete()
  }, [paymentComplete, user, purchaseData, logout, navigate])

  return (
    <>
      <OverlayLoader overlayProps={{ position: "fixed" }} isLoading={isLoading}>
        {loadingMessage}
      </OverlayLoader>

      {paymentComplete && (
        <Flex position="relative" flexDirection="column" alignItems="center">
          <Div position="absolute" width={[300, 450]}>
            <StepsIndicator
              steps={Object.keys(flowSteps)}
              activeIndex={Object.keys(flowSteps).length}
            />
          </Div>
          <Flex
            mt="xxs"
            mb="l"
            py="xs"
            width="100%"
            bg="grey.lightest"
            flexDirection="column"
            alignItems="center"
          >
            <OrderSuccess
              currencyCode={planCurrency}
              totalPrice={total}
              subtotal={subtotal}
              taxAmount={taxAmount}
              discount={discount}
              numberOfUsers={planTotalUsers}
              frequency={planFrequency}
              planName={planName}
              appliedCoupons={purchaseData.couponsUsed || []}
            />
          </Flex>
        </Flex>
      )}

      {!paymentComplete && (
        <>
          <ExitSubscriptionPrompt when={!!purchaseData?.plan} />
          <Flex flexDirection="column">
            <H1 my={0} color="green.standard">
              {messages.subscription.purchase.upgradeYourPlan}
            </H1>
            <H5 mt={0} color="grey.standard">
              {!user?.isSubscriptionExpired &&
                user?.subscriptionExpired &&
                !user?.planType &&
                messages.subscription.purchase.freeTrialEnds.format(
                  formatDate(user.subscriptionExpired)
                )}
            </H5>
            <Flex
              position="relative"
              flexDirection="column"
              alignItems="center"
            >
              <Div position="absolute" width={[300, 450]}>
                <StepsIndicator
                  steps={Object.keys(flowSteps)}
                  activeIndex={activeStepIndex}
                />
              </Div>
              <Flex
                mt="xxs"
                mb="l"
                py="xs"
                width="100%"
                bg="grey.lightest"
                flexDirection="column"
                alignItems="center"
              >
                <Div mr="auto" height={spacing.m}>
                  {previousStepLabel && (
                    <Link
                      onClick={goBack}
                      color="grey.dark"
                      ml="xs"
                      mr="auto"
                      size="small"
                    >
                      <>
                        <LeftArrow height={spacing.xs} />
                        {previousStepLabel}
                      </>
                    </Link>
                  )}
                </Div>

                {banners.map((Banner, index) => (
                  <Banner key={index} my="s" />
                ))}

                <H2 my="xs" color="grey.dark" mt={["l", "l", "xs"]} mb="0">
                  {stepTitle}
                </H2>

                {SubscriptionComponent && <SubscriptionComponent />}

                <LegalSubtext maxWidth={350} message={legalSubText} />
              </Flex>
              {FooterComponent && <FooterComponent />}
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}
