import React, { useMemo } from "react"
import { Div, H5 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { ProjectTeamMember, Membership } from "./types"

type ProjectTeamMembersProps = {
  teamMembers?: ProjectTeamMember[]
}

export const ProjectTeamMembers = ({
  teamMembers
}: ProjectTeamMembersProps) => {
  const { members, owners } = useMemo(() => {
    const members: string[] = []
    const owners: string[] = []

    teamMembers?.forEach((member: ProjectTeamMember) => {
      if (member.membership === Membership.Member) {
        members.push(`${member.firstName} ${member.lastName}`)
      } else if (member.membership === Membership.Owner) {
        owners.push(`${member.firstName} ${member.lastName}`)
      }
    })

    return { members, owners }
  }, [teamMembers])

  return (
    <>
      {Boolean(owners.length) && (
        <Div m="0" mt="m">
          <H5 m="0">{messages.assignment.assignmentOwners}</H5>
          <ShowMore visibleLines={3} description={owners.join(", ")} />
        </Div>
      )}
      {Boolean(members.length) && (
        <Div m="0" mt="m">
          <H5 m="0">{messages.assignment.assignmentMembers}</H5>
          <ShowMore visibleLines={3} description={members.join(", ")} />
        </Div>
      )}
    </>
  )
}
