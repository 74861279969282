import { FilterType } from "./types"

export const JOB_TITLE_KEYWORDS_LIMIT = 50
export const KEYWORDS_LIMIT = 50
export const LOCATIONS_LIMIT = 10
export const COUNTRIES_LIMIT = 10
export const COMPANIES_LIMIT = 50
export const PAGE_SIZE = 25
export const VISIBLE_PAGES = 5

export const optionalFields = [
  FilterType.Distance,
  FilterType.DistanceUnit,
  FilterType.KeywordsBundleSearchUsingORLogic,
  FilterType.JobTitleBundleLogic,
  FilterType.IndustriesLogic,
  FilterType.CompanyLogic,
  FilterType.CompanySizeLogic,
  FilterType.JobTitlesBundleSearchUsingANDLogic
]
