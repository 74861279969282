import React, { useCallback } from "react"
import { PersonNote } from "views/persons/components/person-notes/types"
import { PersonNoteEditValues } from "../form/constants/types"
import { getOnEditPersonNoteSubmit } from "../form/actions"
import { Div, Flex, SelectOptionProps } from "@ikiru/talentis-fpc"
import { PersonNotesEditForm } from "../form/PersonNotesEditForm"
import { FormAction } from "utils/forms/form.types"
import { List, NoteListContainer } from "./style"
import { personNotesFormE2ETargets } from "../form/constants/definitions"
import PersonNoteItem from "./PersonNoteItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"

type PersonNotesListProps = {
  notes: PersonNote[]
  personId: string
  editPersonNote: (note: PersonNote) => void
  assignmentOptions: SelectOptionProps[]
  campaignOptions: SelectOptionProps[]
}

type onPersonNoteSubmit = (noteId: string) => FormAction<PersonNoteEditValues>

export const PersonNotesList = ({
  notes,
  personId,
  assignmentOptions,
  editPersonNote,
  campaignOptions
}: PersonNotesListProps) => {
  const { open: openModal, close } = useModal()
  const onSubmit: onPersonNoteSubmit = useCallback(
    (noteId) =>
      getOnEditPersonNoteSubmit(personId, noteId, (note) => {
        editPersonNote(note)
        close(ModalNames.AddOrEditPersonNote)
      }),
    [close, editPersonNote, personId]
  )

  const openEditModal = (note: PersonNote) => {
    const { noteTitle, noteDescription, id, linkAssignment, linkCampaign } =
      note
    openModal(
      ModalNames.AddOrEditPersonNote,
      <Div mb="xs">
        <PersonNotesEditForm
          initialValues={{
            noteTitle,
            noteDescription,
            linkAssignment,
            assignmentId: "",
            campaignId: "",
            linkCampaign: linkCampaign || null
          }}
          onSubmit={onSubmit(id)}
          assignmentOptions={assignmentOptions}
          campaignOptions={campaignOptions}
          documents={note.documents}
          noteId={note.id}
        />
      </Div>
    )
  }

  return (
    <Flex justifyContent="center" width="100%">
      {notes.length ? (
        <Div width="100%">
          <List>
            {notes.map((note, index) => {
              const { id } = note
              return (
                <NoteListContainer key={id}>
                  <PersonNoteItem
                    note={note}
                    index={index}
                    editNote={openEditModal}
                    buttonEditDataE2ETarget={personNotesFormE2ETargets.editNote}
                  />
                </NoteListContainer>
              )
            })}
          </List>
        </Div>
      ) : (
        <PersonNoDataScreen
          message={messages.person.personTabSection.noDataTexts.noNotes}
        />
      )}
    </Flex>
  )
}
