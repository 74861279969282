import { useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { SearchEndpoints } from "setup/api/endpoints/endpoints"
import { emptyResult } from "../const"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import {
  Filters,
  KeywordBundle,
  LogicalOperator
} from "views/search/SearchModule/types"
import { useSearch } from "views/search/SearchModule/context"
import { scrollToTop } from "views/search/SearchModule/utils"
import { useAlert } from "setup/alert/hooks"
import { messages } from "setup/messages/messages"

export const useAISearch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSimilarPersonError, setIsSimilarPersonError] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [filtersAI, setFiltersAI] = useState<Filters>({})
  const { updateFilters } = useSearch()
  const { error: errorHandler } = useAlert()
  const updateSearchCriteria = useCallback(
    (returnData: NormalizedReturnTuple<any>, callback?: () => void) => {
      const [error, response] = returnData
      const data = response?.data
      if (data.generatedSearchCriteria !== "Invalid response from ChatGPT") {
        const searchCriteria = JSON.parse(data.generatedSearchCriteria || "")

        if (
          !data.generatedSearchCriteria.trim() ||
          !Boolean(searchCriteria) ||
          Boolean(error) ||
          JSON.stringify(emptyResult) === data.generatedSearchCriteria
        ) {
          setIsError(true)
        } else {
          //Need to change totalJobExperienceYears in to an object {min: xxx, max: xxx}
          if (searchCriteria.totalExperienceYears) {
            if (searchCriteria.totalExperienceYears.min) {
              searchCriteria.totalJobExperienceYearsMin = `${searchCriteria.totalExperienceYears.min}`
            }
            if (searchCriteria.totalExperienceYears.max) {
              searchCriteria.totalJobExperienceYearsMax = `${searchCriteria.totalExperienceYears.max}`
            }
            delete searchCriteria.totalExperienceYears
          }

          //Need to change this these keys [JobTitlesBundleSearchUsingANDLogic, KeywordsBundleSearchUsingORLogic] to lower case
          const logicChanger = (data: boolean) => {
            return data ? LogicalOperator.AND : LogicalOperator.OR
          }

          if (searchCriteria.jobTitlesBundleSearchUsingANDLogic) {
            searchCriteria.JobTitlesBundleSearchUsingANDLogic = logicChanger(
              searchCriteria.jobTitlesBundleSearchUsingANDLogic
            )
            delete searchCriteria.jobTitlesBundleSearchUsingANDLogic
          }

          if (searchCriteria.keywordsBundleSearchUsingORLogic) {
            searchCriteria.KeywordsBundleSearchUsingORLogic = logicChanger(
              !searchCriteria.keywordsBundleSearchUsingORLogic
            )
            delete searchCriteria.keywordsBundleSearchUsingORLogic
          }

          if (searchCriteria.keywordBundle) {
            searchCriteria.keywordBundle.forEach(
              (keywordBundle: KeywordBundle, index: number) => {
                if (keywordBundle.keywordsSearchUsingORLogic) {
                  searchCriteria.keywordBundle[
                    index
                  ].keywordsSearchUsingORLogic = logicChanger(
                    !searchCriteria.keywordBundle[index]
                      .keywordsSearchUsingORLogic
                  )
                }
              }
            )
          }

          updateFilters(searchCriteria)
          setFiltersAI(searchCriteria)

          setHasSearched(true)
          callback?.()
        }
        scrollToTop()
      } else {
        errorHandler(messages.assignment.details.candidates.searchAIError, {
          closable: true
        })
      }
      setIsLoading(false)
    },
    [updateFilters, errorHandler]
  )

  const getAISearchCriteria = async (dataFormat: string) => {
    setIsLoading(true)

    const data = await apiRequest.post({
      endpoint: SearchEndpoints.AISearch,
      data: { searchCriteria: dataFormat }
    })

    return data
  }

  const getSimilarPeople = useCallback(
    async (personId: string) => {
      setIsLoading(true)

      const data = await apiRequest.get({
        endpoint: SearchEndpoints.SimilarPeopleAi,
        endpointParams: personId
      })

      const [error, response] = data
      if (error || !response?.data.generatedSearchCriteria) {
        setIsSimilarPersonError(true)
        setIsLoading(false)
      } else {
        updateSearchCriteria(data)
      }
    },
    [updateSearchCriteria]
  )

  return {
    updateSearchCriteria,
    getAISearchCriteria,
    getSimilarPeople,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isSimilarPersonError,
    hasSearched,
    setHasSearched,
    filtersAI
  }
}
