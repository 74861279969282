import React from "react"
import { Nullable } from "tsdef"
import { Flex, SmallText, colors, fontFamilies } from "@ikiru/talentis-fpc"
import { StyledBadge } from "../../styles"
import { PersonLink } from "../../../../../../components/PersonRecords/OverlayOpenner/PersonLink"
import { useSearch } from "views/search/SearchModule/context"

type ActivitiesDetailsProps = {
  count: number
  name: string
  datapoolId?: string
  personId?: Nullable<string>
}

export const ActivitiesDetails = React.memo(
  ({ personId, name, count, datapoolId }: ActivitiesDetailsProps) => {
    const { setSelectedSearchDataPoolId } = useSearch()

    return (
      <PersonLink
        personId={personId}
        underline={false}
        setPersonId={setSelectedSearchDataPoolId}
        dataPoolId={datapoolId}
      >
        <Flex pr="s" alignItems="center" lineHeight={0}>
          <SmallText
            mb="none"
            color={colors.green.dark}
            fontFamily={fontFamilies.gibson}
            fontWeight={500}
          >
            {name}
          </SmallText>
          <StyledBadge count={count}>{count}</StyledBadge>
        </Flex>
      </PersonLink>
    )
  }
)
