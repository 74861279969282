import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import AITemplates from "components/ProjectActions/AITemplates/AiTemplates"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import useAssignmentTemplates from "./hooks/useAssignmentTemplates"
import { messages } from "setup/messages/messages"

const AssignmentTemplates = () => {
  const { setAssignmentTemplates, assignmentTemplates } = useAssignment()
  const { generateAITemplates, isLoading, updateAiTemplate } =
    useAssignmentTemplates()

  return (
    <Div>
      <AITemplates
        onGenerateTemplate={generateAITemplates}
        setTemplateValues={setAssignmentTemplates}
        templateValues={assignmentTemplates}
        updateTemplate={updateAiTemplate}
        isLoading={isLoading}
        defaultSearchPlaceHolder={
          messages.project.outReach.coreMessageAssignmentPH
        }
      />
    </Div>
  )
}

export default AssignmentTemplates
