import { OffLimitsType, OfflimitsOverrideType } from "components/OffLimits/type"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const patchOffLimits = async (
  personId: string,
  offLimits: OffLimitsType
) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: { offLimits },
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
  return response?.data
}

export const patchInheritedCompanyOffLimits = async (
  personId: string,
  experienceId: string,
  data: OfflimitsOverrideType
) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.Experience,
    endpointParams: { personId, experienceId },
    data,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
  return response?.data
}
