import React, { useState } from "react"
import { messages } from "setup/messages/messages"
import { Flex, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"
import { ThreeDotsIcon } from "@ikiru/talentis-fpc"
import { PlusIcon } from "@ikiru/talentis-fpc"
import {
  ClientPortalShareSettingsModalWrapper,
  CloseButton,
  ScrollableDiv
} from "./styles"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Form, Formik, FormikProps } from "formik"
import {
  ClientPortalShareField,
  clientPortalShareInitialValues,
  ClientPortalShareValues
} from "./constants/client-portal-share-form.definitions"
import { getClientPortalShareValidationSchema } from "./constants/client-portal-share-form.schema"
import { OnClientPortalShareCreateSubmit } from "./client-portal-share-form.actions"
import { Button } from "@ikiru/talentis-fpc"
import {
  Div,
  spacing,
  Typography,
  Line,
  Popup,
  BodyText,
  H4,
  H6
} from "@ikiru/talentis-fpc"
import { Share } from "views/assignments/assignment.types"

type ClientPortalShareSettingsModalProps = {
  accesses: Share[]
  onClose: VoidFunction
  onSubmit: OnClientPortalShareCreateSubmit
  onDelete: (email: string) => Promise<void>
  formClientPortalShareSettingsComponent?: (
    formikProps: FormikProps<ClientPortalShareValues>
  ) => JSX.Element
  formActionsComponent: (
    formikProps: FormikProps<ClientPortalShareValues>
  ) => JSX.Element
}

export const ClientPortalShareSettingsModal = ({
  accesses,
  onClose,
  onSubmit,
  formActionsComponent,
  formClientPortalShareSettingsComponent,
  onDelete
}: ClientPortalShareSettingsModalProps) => {
  const [showForm, setShowForm] = useState(false)
  const clientPortalUrl =
    process.env.REACT_CLIENT_PORTAL_URL || window.fpConfig.clientPortalUrl
  return (
    <ClientPortalShareSettingsModalWrapper>
      <ModalSectionHeader
        title={messages.clientPortal.access.reportShareSettings}
        size="xSmall"
        actions={
          <>
            <CloseButton
              mode="standard-white"
              size="action-medium"
              onClick={onClose}
              title={messages.generic.close}
              mt="6px"
              mr="xxs"
            >
              <CrossIcon title={messages.generic.close} />
            </CloseButton>
          </>
        }
      />

      {!!accesses.length && (
        <>
          <Div bg="green.lightest" p="17px 20px" mb={spacing.m}>
            <H4 mt="none" mb="xxs" color="grey.dark">
              {messages.clientPortal.access.sharingWithYourClientContact}
            </H4>
            <Typography.BodyText m="none" color="grey.dark">
              {messages.clientPortal.access.copyTheUniqueAccess}
            </Typography.BodyText>
          </Div>
          <Flex
            mx="-20px"
            pl="m"
            pr="m"
            mb="xxs"
            justifyContent="space-between"
            alignItems="center"
            width="calc(100%+40px)"
            bg="grey.light"
          >
            <H4 my="xs" display="inline" color="grey.dark">
              {messages.clientPortal.access.clientContactsWithAccess}
            </H4>
            <Button
              prefix={<PlusIcon fill="currentColor" width={12} height={12} />}
              mode="standard-green"
              type="submit"
              size="small"
              onClick={() => {
                setShowForm(true)
              }}
            >
              <H6 m="none" color="white.standard">
                {messages.clientPortal.access.clientContact}
              </H6>
            </Button>
          </Flex>
        </>
      )}
      {(!accesses?.length || showForm) && (
        <Formik
          initialValues={clientPortalShareInitialValues}
          validationSchema={getClientPortalShareValidationSchema(accesses)}
          onSubmit={onSubmit}
          mt="xxs"
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <FormikInput
                    autoFocus
                    width="100%"
                    id={ClientPortalShareField.Email}
                    name={ClientPortalShareField.Email}
                    label={
                      messages.clientPortal.access.clientContactEmailAccess
                    }
                  />
                  {formClientPortalShareSettingsComponent?.(formik)}
                  {formActionsComponent(formik)}
                </Form>
              </>
            )
          }}
        </Formik>
      )}
      <ScrollableDiv>
        <Flex flexDirection="column" justifyContent="center" alignItems="start">
          {!accesses.length && (
            <BodyText mt="s" mb="none">
              {messages.clientPortal.access.onlyUnique}
            </BodyText>
          )}
          {!accesses.length && (
            <BodyText mt="s" mb="none">
              {messages.clientPortal.access.youCanRemove}
            </BodyText>
          )}
          {accesses.map((access, index, arr) => (
            <React.Fragment key={access.userName}>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                alignItems="flex-center"
              >
                <Flex mb="m" mt="s" flexDirection="column">
                  <Typography.BodyText
                    fontWeight="600"
                    m="none"
                    color="grey.darkest"
                  >
                    {access.email}
                  </Typography.BodyText>
                  <Button
                    width="max-content"
                    onClick={() => {
                      navigator.clipboard.writeText(`
                Portal link: ${clientPortalUrl} 
                Username: ${access.userName} 
                ${
                  access?.password?.length ? "Password: " + access.password : ""
                }`)
                    }}
                    size="small"
                    mode="standard-grey"
                    mt={spacing.xxs}
                  >
                    {messages.clientPortal.access.copyAccessDetails}
                  </Button>
                </Flex>
                <Popup
                  mt="s"
                  trigger={<ThreeDotsIcon style={{ cursor: "pointer" }} />}
                >
                  <BodyText
                    // @ts-ignore
                    onClick={() => onDelete(access.email)}
                    style={{ cursor: "pointer" }}
                    color="grey.dark"
                    fontWeight="500"
                    p="none"
                    mt="xxs"
                    mr="s"
                    mb="xxs"
                    ml="xxs"
                  >
                    {messages.clientPortal.access.removeAccess}
                  </BodyText>
                </Popup>
              </Flex>
              {index !== arr.length - 1 && <Line variant="thin" />}
            </React.Fragment>
          ))}
        </Flex>
      </ScrollableDiv>
    </ClientPortalShareSettingsModalWrapper>
  )
}
