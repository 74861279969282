import React, { useEffect, useState } from "react"
import { colors, H5, H6 } from "@ikiru/talentis-fpc"
import {
  DropDownIconStyled,
  BodyAccordion,
  HeaderWrapper,
  StyledAccordion
} from "./styles"

export type AccordionProps = {
  title: string
  nodeKey?: string
  isOpen?: boolean
  children: React.ReactNode[]
  onClick?: (nodeKey?: string) => void
}

const Accordion = ({
  title,
  nodeKey,
  isOpen = false,
  children,
  onClick
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    setIsAccordionOpen(isOpen)
  }, [isOpen])

  return (
    <StyledAccordion>
      <HeaderWrapper
        onClick={() => {
          setIsAccordionOpen((prevValue) => !prevValue)
          onClick && onClick(nodeKey)
        }}
      >
        <H5 my="5px" fontSize="14px" color={colors.white.standard}>
          {title}
        </H5>
        <DropDownIconStyled up={isAccordionOpen} />
      </HeaderWrapper>
      {isAccordionOpen && (
        <>
          {children?.every((item) => item === null) ? (
            <H6 m="0" pb="20px" color={colors.grey.standard}>
              No {title} assigned
            </H6>
          ) : (
            <BodyAccordion>{children}</BodyAccordion>
          )}
        </>
      )}
    </StyledAccordion>
  )
}

export default Accordion
