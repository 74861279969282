import React, { useEffect, useMemo, useState } from "react"

type UseHorizantialScrollProps = {
  scrollElement: React.RefObject<HTMLDivElement>
  scrollUpAmmount?: number
}

const useHorizantialScroll = ({
  scrollElement,
  scrollUpAmmount = 150
}: UseHorizantialScrollProps) => {
  const [divWidth, setDivWidth] = useState<number>(0)
  const [isOverflow, setIsOverflow] = useState<boolean>(false)
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0)

  const scrollLeft = (isNext = true) => {
    const divElement = scrollElement.current!

    const newPositon =
      currentScrollPosition + (isNext ? scrollUpAmmount : -scrollUpAmmount)
    if (
      (isNext && newPositon < divWidth + scrollUpAmmount) ||
      (!isNext && newPositon >= 0)
    ) {
      divElement.scrollLeft = newPositon
      setCurrentScrollPosition(newPositon)
    }
  }

  const disablePrev = useMemo(
    () => currentScrollPosition <= 0,
    [currentScrollPosition]
  )

  const disableNext = useMemo(
    () => currentScrollPosition > divWidth,
    [currentScrollPosition, divWidth]
  )

  useEffect(() => {
    if (!isOverflow) {
      setCurrentScrollPosition(0)
    }
  }, [isOverflow])

  useEffect(() => {
    const { current } = scrollElement

    const trigger = () => {
      if (current) {
        const hasOverflow = current.offsetWidth < current.scrollWidth
        setIsOverflow(hasOverflow)
        setDivWidth(current.scrollWidth - current.clientWidth)
      }
    }

    if (current) {
      window.addEventListener("resize", trigger)
      trigger()
    }
    return () => window.removeEventListener("resize", trigger)
  }, [scrollElement])

  return { scrollLeft, isOverflow, disablePrev, disableNext }
}

export default useHorizantialScroll
