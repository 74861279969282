import React from "react"
import { BodyText, Div, Flex, colors } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import DetailSection from "../../person-details-section/components/DetailSection"
import { SectionTitle } from "../../../style"
import { usePerson } from "views/persons/person-module/candiate-module.context"

const PersonAddressView = () => {
  const { profileDetails } = usePerson()

  const flexContainerStyle = {
    minHeight:
      profileDetails.addressLine1 || profileDetails.addressLine2
        ? "auto"
        : "21px"
  }

  return (
    <Div width="100%">
      {profileDetails.location.value ? (
        <>
          <DetailSection
            title={messages.person.personTabSection.location}
            data={profileDetails.location.value}
          />
          <Div>
            <SectionTitle>{messages.form.generic.postalAddress}</SectionTitle>
            <Flex style={flexContainerStyle}>
              {profileDetails.addressLine1 || ""}
              <br />
              {profileDetails.addressLine2 || ""}
            </Flex>
          </Div>
          <Flex
            alignItems="flex-start"
            justifyContent="space-evenly"
            width="100%"
          >
            <DetailSection
              title={messages.form.generic.postcode}
              data={profileDetails.postcode}
            />
            <DetailSection
              title={messages.form.generic.city}
              data={profileDetails.city}
            />
            <DetailSection
              title={messages.form.generic.country}
              data={profileDetails.country}
            />
          </Flex>
        </>
      ) : (
        <BodyText m="0px" color={colors.grey.standard}>
          {messages.person.personTabSection.noDataTexts.noAddress}
        </BodyText>
      )}
    </Div>
  )
}

export default PersonAddressView
