import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import AttributesListTree from "./AttributesListTree"
import { assignAttributes } from "../../actions"
import { getLastNodeIds, removeNodeByKey } from "../../helper"
import { ComingSoon } from "../../styles"

const AttributesList = () => {
  const { attributes, setAttributes, personId } = usePerson()

  const removeAttributes = (key: string) => {
    const newTest = removeNodeByKey(attributes, key)

    const ids = getLastNodeIds(newTest)

    const updateAttributes = assignAttributes(personId, ids)
    setAttributes(newTest)
  }

  return (
    <Flex flexDirection="column" width="100%">
      <ComingSoon>
        Preview: Fully customize and search attributes. Unlimited attribute
        groups. Unlimited sublevels. Coming soon!
      </ComingSoon>
      <AttributesListTree attributes={attributes} remove={removeAttributes} />
    </Flex>
  )
}

export default AttributesList
