export enum CardDetailsFields {
  Name = "NameOnCard",
  TermsAndConditionsAccepted = "TermsAndConditionsAccepted"
}

export type CardDetailsType = {
  [CardDetailsFields.Name]: string
  [CardDetailsFields.TermsAndConditionsAccepted]: boolean
}

export const initialCardDetailsValues = {
  [CardDetailsFields.Name]: "",
  [CardDetailsFields.TermsAndConditionsAccepted]: false
}
