import React from "react"
import {
  Button,
  BodyText,
  Div,
  Flex,
  H4,
  SmallText,
  colors,
  Span
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { HintJobtitlesModalWrapper } from "./style"
import JobtitlesExamplePicture from "assets/jobtitlesExample.png"
import AddJobtitleButtonPicture from "assets/addJobtitleButton.png"

export const HintJobTitlesModal = () => {
  const { close } = useModal()

  return (
    <HintJobtitlesModalWrapper>
      <Flex justifyContent="space-between">
        <H4 color={colors.grey.dark} mt="0">
          {messages.talentGraphSearch.searchJobtitlesTitle}
        </H4>
        <Button
          onClick={() => close(ModalNames.HintJobTitles)}
          mode="primary"
          size="small"
        >
          {messages.talentGraphSearch.gotIt}
        </Button>
      </Flex>
      <BodyText textAlign="center" mb="m">
        {messages.talentGraphSearch.searchKeywordParagraphFirst}
        <Span fontWeight="bold">
          {messages.talentGraphSearch.searchJobtitlesPartSecond}
        </Span>
        <br />
        {messages.talentGraphSearch.searchJobtitlesPartThird}

        <br />
        {messages.talentGraphSearch.searchJobtitlesPartForth}
      </BodyText>
      <BodyText mx="45px">
        {messages.talentGraphSearch.searchJobTitlesExample}
      </BodyText>
      <Flex justifyContent="space-between" mx="xl">
        <Flex flexDirection="column" mr="xs">
          <img
            alt="Hint jobtitles an example"
            width={270}
            src={JobtitlesExamplePicture}
          />
          <SmallText m="0">
            {messages.talentGraphSearch.searchJobTitlesExampleAndOr}
          </SmallText>
        </Flex>
        <Flex flexDirection="column" mt="60px">
          <BodyText pt="3px">
            1. {messages.talentGraphSearch.searchJobTitlesHitOne}
          </BodyText>
          <Flex pt="45px">
            <BodyText>
              2. {messages.talentGraphSearch.searchJobTitlesHitSecond}
            </BodyText>
            <Div mt="xs">
              <img
                alt="Jobtitles add button"
                width={121}
                height={41}
                src={AddJobtitleButtonPicture}
              />
            </Div>
          </Flex>

          <BodyText m="0" mt="8px">
            3. {messages.talentGraphSearch.searchJobTitlesHitThird}
          </BodyText>
          <BodyText textAlign="center" mt="xl">
            {messages.talentGraphSearch.searchJobTitlesQuery}
            <br />
            <Span fontWeight="bold">
              {messages.talentGraphSearch.searchJobTitlesExampleAndOr}
            </Span>
          </BodyText>
        </Flex>
      </Flex>
    </HintJobtitlesModalWrapper>
  )
}
