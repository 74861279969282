import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const NoteFormWrapper = styled(Div)`
  position: relative;
  z-index: 6;
  background: ${({ theme }) => theme.colors.white.standard};
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.xs}px;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(26, 37, 55, 0.5);
  z-index: 5;
`
