import { TeamMember } from "views/team/team/team-module.types"
import { Task } from "views/homepage/task-management-module/task-management-module.types"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getChangedValues } from "views/assignments/utils"
import isEmpty from "lodash/isEmpty"
import { TaskForm } from "./types"

export const setMembersOptions = (teamMembers: TeamMember[]) => {
  return teamMembers.map((member: TeamMember) => ({
    label: `${member.firstName} ${member.lastName}`,
    value: member.id
  }))
}

export const normalizedTaskPerson = (tasks: Task[]) => {
  return tasks?.map((task: Task) => ({
    ...task,
    normalizedPersonData: new LocalPersonModel(task?.linkPerson).getTaskValues()
  }))
}

export const submitCandidateInterviewChanges = (
  values: TaskForm,
  initialValues: TaskForm
) => {
  const formattedInitialValues = {
    assignTo: initialValues.assignTo,
    dueDate: initialValues.dueDate
  }

  const formattedValues = {
    assignTo: values.assignTo,
    dueDate: values.dueDate
  }

  const interviewProgressValues = {
    stage: values?.stage,
    status: values?.status
  }

  const interviewProgressInitialValues = {
    stage: initialValues?.stage,
    status: initialValues?.status
  }
  const changeValues = getChangedValues(formattedValues, formattedInitialValues)
  const changedInterviewProgress = getChangedValues(
    interviewProgressValues,
    interviewProgressInitialValues
  )

  const newValues: any = {
    ...changeValues,
    interviewProgressState: changedInterviewProgress
  }

  if (isEmpty(changedInterviewProgress)) {
    delete newValues.interviewProgressState
  }

  return newValues
}

export const submitContactInterviewChanges = (
  values: TaskForm,
  initialValues: TaskForm
) => {
  const formattedInitialValues = {
    status: initialValues.status,
    assignTo: initialValues.assignTo,
    dueDate: initialValues.dueDate
  }

  const formattedValues = {
    status: values.status,
    assignTo: values.assignTo,
    dueDate: values.dueDate
  }

  const changeValues = getChangedValues(formattedValues, formattedInitialValues)

  const newValues: TaskForm = { ...changeValues }

  return newValues
}
