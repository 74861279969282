import React from "react"
import { Button, CrossIcon, Flex, TickIcon } from "@ikiru/talentis-fpc"

type InputFooterProps = {
  handleSaveClick: () => void
  handleCancelClick: () => void
  isDisabled?: boolean
}

const InputFooter = ({
  handleSaveClick,
  handleCancelClick,
  isDisabled = false
}: InputFooterProps) => {
  return (
    <Flex>
      <Button
        mode="standard-grey-light"
        size="action-small"
        onClick={handleSaveClick}
        disabled={isDisabled}
        mr="xxs"
      >
        <TickIcon width={12} height={12} />
      </Button>
      <Button
        mode="standard-grey-light"
        size="action-small"
        onClick={handleCancelClick}
      >
        <CrossIcon width={12} height={12} />
      </Button>
    </Flex>
  )
}

export default InputFooter
