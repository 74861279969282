import React, { useMemo } from "react"
import { RouterUrl } from "setup/router/routes"
import { useCandidate } from "./CandidateContext"
import { CandidateRecordLayout } from "./CandidateRecord.layout"
import { InterviewProgress } from "./components/InterviewProgress"

export const CandidateRecord = React.memo(() => {
  const { candidate, isUpdating } = useCandidate()

  const candidateUrl = useMemo(
    () => `${RouterUrl.ProfileDetails}/${candidate.personId}`,
    [candidate.personId]
  )
  const data = candidate?.normalizedPersonData || {}

  return (
    <CandidateRecordLayout
      StageComponent={InterviewProgress}
      isConnected
      {...{
        ...data,
        isUpdating,
        candidateUrl,
        candidate
      }}
    />
  )
})
