import React, { useState } from "react"
import { Div, Flex, Button } from "@ikiru/talentis-fpc"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { CreatePersonField } from "../../../constant/definitions"
import { messages } from "setup/messages/messages"
import GenerateBioForm from "views/persons/components/person-details/components/GenerateBio/GenerateBioForm"
import { FormikProps } from "formik"
import { countLines } from "utils/helpers"
import { loadAIBioSettings } from "views/persons/components/person-details/components/GenerateBio/utils"

type PersonBioFormProps = {
  formik: FormikProps<any>
  values: any
}

const PersonBioForm = ({ values, formik }: PersonBioFormProps) => {
  const [aiMode, setAIMode] = useState(false)

  const onSave = (generatedBio: string) => {
    formik?.setFieldValue(`${CreatePersonField.Bio}.value`, generatedBio)
    setAIMode(false)
  }

  return (
    <Div mt="xxs">
      {!aiMode ? (
        <>
          <FormikTextarea
            id={CreatePersonField.Bio}
            name={`${CreatePersonField.Bio}.value`}
            label={messages.form.generic.biography}
            rows={countLines(values.bio?.value || "")}
            isMicrophone
          />
          <Flex mt="xxs" justifyContent="flex-end">
            <Button
              mode="standard-grey-dark"
              size="extra-small"
              onClick={() => setAIMode(true)}
              type="button"
            >
              {messages.aiBio.generateWithAI}
            </Button>
          </Flex>
        </>
      ) : (
        <GenerateBioForm
          onCloseFun={() => setAIMode(false)}
          onGenerate={onSave}
          noBanner
          bioSettings={loadAIBioSettings()}
        />
      )}
    </Div>
  )
}

export default PersonBioForm
