import React from "react"
import { LinkContainerStyled } from "./style"
import { messages } from "setup/messages/messages"
import {
  BodyText,
  Button,
  Flex,
  PositiveIcon,
  Div,
  H5,
  Span
} from "@ikiru/talentis-fpc"
import { LinkedInForm } from "views/assignments/components/assignment-client-details/linkedin-form"
import { OnCompanyLinkSubmitType } from "views/assignments/components/assignment-client-details/linkedin-form/constants/definitions"
import { FormSubmitButtons } from "views/companies/components/company-details/components/style"

type LinkToTalentGraphProps = {
  showLinkSuccess: boolean
  editLinkedIn: boolean
  setEditLinkedIn: React.Dispatch<React.SetStateAction<boolean>>
  isLinkedToDataPool: boolean
  dataNotFound: boolean
  successMessage: string
  notFoundMessage: JSX.Element | string
  needToLinkMessage: string
  exampleText: string
  onCancel: () => void
  onSubmit: OnCompanyLinkSubmitType
  isForCompany?: boolean
}
const LinkToTalentGraph = (props: LinkToTalentGraphProps) => {
  const {
    showLinkSuccess,
    onCancel,
    onSubmit,
    editLinkedIn,
    setEditLinkedIn,
    isLinkedToDataPool,
    dataNotFound,
    needToLinkMessage,
    notFoundMessage,
    successMessage,
    exampleText,
    isForCompany = false
  } = props

  return (
    <>
      {showLinkSuccess && (
        <LinkContainerStyled isForCompany={isForCompany}>
          <Flex flexDirection="row">
            <PositiveIcon width={30} height={30} />
            <BodyText
              ml="xs"
              my="none"
              mr="none"
              display="flex"
              alignItems="center"
            >
              {successMessage}
            </BodyText>
          </Flex>
        </LinkContainerStyled>
      )}
      {!isLinkedToDataPool && (
        <LinkContainerStyled isForCompany={isForCompany}>
          {editLinkedIn ? (
            <>
              <H5 width="100%" textAlign="left" mt="0" mb="xs">
                {messages.assignment.addLinkedInExample}:
              </H5>
              <Span mb="xs">{exampleText}</Span>
              <LinkedInForm
                formActionsComponent={(formikProps) => (
                  <FormSubmitButtons>
                    <Button
                      type="submit"
                      size="small"
                      mr="xs"
                      disabled={formikProps.isSubmitting}
                    >
                      {messages.generic.save}
                    </Button>
                    <Button
                      mode="standard-white"
                      size="small"
                      disabled={formikProps.isSubmitting}
                      type="button"
                      onClick={() => {
                        onCancel()
                        formikProps.resetForm()
                      }}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </FormSubmitButtons>
                )}
                onSubmit={onSubmit}
              />
              {dataNotFound && <Div mt="xs">{notFoundMessage}</Div>}
            </>
          ) : (
            <Flex flexDirection="row">
              <Div>{needToLinkMessage}</Div>
              <Button
                ml="s"
                mode="primary"
                size="extra-small"
                onClick={() => setEditLinkedIn(true)}
              >
                {messages.person.details.linkGraph}
              </Button>
            </Flex>
          )}
        </LinkContainerStyled>
      )}
    </>
  )
}

export default LinkToTalentGraph
