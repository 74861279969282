import { Experience, Stage } from "views/persons/person.types"
import { Nullable } from "tsdef"
import { InterviewProgressStage } from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"

export const countAllStages = (stages: Stage[]) => {
  return stages
    ?.filter((stage) =>
      [
        InterviewProgressStage.Shortlist,
        InterviewProgressStage.InternalInterview,
        InterviewProgressStage.FirstClientInterview,
        InterviewProgressStage.SecondClientInterview,
        InterviewProgressStage.ThirdClientInterview,
        InterviewProgressStage.Offer,
        InterviewProgressStage.Placed
      ]
        .map((stage) => stage.toLowerCase())
        .includes(stage.name.toLowerCase() as InterviewProgressStage)
    )
    .reduce((acc, value) => (acc += value.count), 0)
}

export const buildSearchUrl = (
  experience: Experience[],
  name?: Nullable<string>
): string => {
  const jobQuery = experience
    ?.slice(0, 3)
    .reduce(
      (previousValue: string, currentValue: Experience) =>
        previousValue + `"${currentValue?.company}" `,
      ""
    )

  const searchUrl =
    encodeURIComponent(`"${experience[0]?.company}" `) +
    encodeURIComponent(jobQuery) +
    `intitle:${name?.split(" ")[0]}`

  return `https://www.google.com/search?q=${searchUrl}`
}

export const checkValue = (value: string | undefined): any => {
  return value && value !== "-"
}

export const generateInitials = (fullName: string) => {
  const initials = fullName?.match(/\b\w/g) || []
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase()
}
