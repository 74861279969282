import {
  Experience,
  experienceDataInitialValues,
  ExperienceFormValues,
  salaryPackageInitialValues,
  SalaryPackageType
} from "../../person.types"
import { isEqual, reduce } from "lodash"

export const getInitialValues = (initialValues?: Experience) => {
  return {
    ...experienceDataInitialValues,
    ...salaryPackageInitialValues,
    ...initialValues,
    startDate: initialValues?.startDate
      ? new Date(initialValues.startDate)
      : null,
    endDate: initialValues?.endDate ? new Date(initialValues.endDate) : null,
    salary: {
      value: initialValues?.compensation?.salary?.value || 0,
      currency:
        initialValues?.compensation?.salary?.currency ||
        localStorage.getItem("salaryCurrency") ||
        "USD"
    },
    package: {
      value: initialValues?.compensation?.package?.value || 0,
      currency:
        initialValues?.compensation?.package?.currency ||
        localStorage.getItem("packageCurrency") ||
        "USD"
    },
    comment: initialValues?.compensation?.comment || ""
  }
}

export const getChangedValues = (
  initialValues: ExperienceFormValues & SalaryPackageType,
  currentValues: ExperienceFormValues & SalaryPackageType
) => {
  return reduce(
    currentValues,
    (result, value, key) => {
      if (
        key in initialValues &&
        !isEqual(value, initialValues[key as keyof typeof initialValues])
      ) {
        //@ts-ignore
        result[key as keyof typeof initialValues] = value
      }
      return result
    },
    {} as Partial<ExperienceFormValues & SalaryPackageType>
  )
}
