import styled from "styled-components"
import { Div, Flex } from "@ikiru/talentis-fpc"

export const LinkIcon = styled.div`
  cursor: pointer;
`

export const WebSitesList = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0px;
  padding-top: ${({ theme }) => theme.space.xs}px;
  padding-bottom: ${({ theme }) => theme.space.xs}px;
  list-style-type: none;
  svg {
    &:hover {
      filter: brightness(0.9);
    }
  }
`

export const AddButtonContainer = styled(Div)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.xs}px;
  margin-bottom: -10px;
`
