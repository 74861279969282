import React from "react"
import { messages } from "setup/messages/messages"
import { LargeText, Flex, BodyText } from "@ikiru/talentis-fpc"

export const NoMatchingResults = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      mt="xs"
      mb="75px"
    >
      <LargeText color="grey.dark">
        {messages.project.filters.noResultsFoundTitle}
      </LargeText>
      <BodyText mt="xxs" color="grey.standard">
        {messages.project.filters.noResultsFoundText}
      </BodyText>
    </Flex>
  )
}
