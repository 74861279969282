import React, { useMemo, useRef } from "react"
import { Flex, Link } from "@ikiru/talentis-fpc"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { Nullable } from "tsdef"
import { WebSite } from "views/persons/person.types"
import { WebSitesRecord, mapIcons } from "./WebSitesRecord"
import { messages } from "setup/messages/messages"
import useSubmitPerson from "views/persons/components/person-details-tab/hook/useSubmitPerson"
import { useModal } from "utils/hooks/use-modal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { CreatePersonField } from "../../form/constants/definitions"
import PersonWebLinkEdit from "views/persons/components/person-details-tab/components/person-web-links/form/PersonWebLinkEdit"
import { ModalNames } from "setup/modal/modal.definitions"

type WebSiteListProps = {
  webSites: PersonValue<WebSite>[]
  linkedInProfileUrl: Nullable<PersonValue<string>>
}

const WebSiteList = ({ webSites, linkedInProfileUrl }: WebSiteListProps) => {
  const { setLinkedinEditMode } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()
  const addWebsiteButtonRef = useRef<HTMLButtonElement>(null!)

  const hasPersonalLinks = useMemo(
    () =>
      Boolean(
        webSites.filter((item) => {
          const icon =
            item.value.type &&
            item.value.type !== "other" &&
            mapIcons[item.value.type]
          const iconExists = Boolean(icon)
          return !iconExists
        }).length
      ),
    [webSites]
  )

  const editPersonWebLinks = (addingNewEntry = false) => {
    const initialValue = {
      webSites: webSites.length
        ? webSites
        : [{ value: { url: "", dpWebsite: false }, isReadOnly: false }],
      linkedInProfileUrl: linkedInProfileUrl || {
        value: "",
        isReadOnly: false
      }
    }

    const buttonRefs = {
      addWebsiteButtonRef
    }

    if (addingNewEntry) {
      initialValue.webSites.push({
        value: { url: "", dpWebsite: false },
        isReadOnly: false
      })
    }
    let defaultEditedField: CreatePersonField

    if (Boolean(linkedInProfileUrl?.value)) {
      defaultEditedField = CreatePersonField.WebSites
    } else {
      defaultEditedField = CreatePersonField.LinkedInUrl
      setLinkedinEditMode(true)
    }
    openModal(
      ModalNames.EditPersonDetails,
      <PersonWebLinkEdit
        onSubmit={onPersonSubmit}
        initialValues={initialValue}
        buttonRefs={buttonRefs}
        defaultEditedField={defaultEditedField}
      />
    )
  }

  return (
    <Flex>
      {Boolean(webSites.length || linkedInProfileUrl) && (
        <WebSitesRecord
          webSites={webSites}
          linkedInProfileUrl={linkedInProfileUrl}
          editPersonWebLinks={editPersonWebLinks}
        />
      )}
      {!hasPersonalLinks && (
        <Flex alignItems="center">
          <Link
            mt="0"
            mb="none"
            onClick={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              editPersonWebLinks()
            }}
          >
            + {messages.form.generic.addMissingWebsite}
          </Link>
        </Flex>
      )}
    </Flex>
  )
}

export default WebSiteList
