import styled from "styled-components"
import { Div } from "@ikiru/talentis-fpc"

export const WebSiteLink = styled.a`
  svg {
    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const WebsitesDetailsBox = styled(Div)`
  padding: ${({ theme }) => theme.space.xs}px;
`
