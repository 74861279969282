import React from "react"
import styled from "styled-components"
import {
  BodyText,
  Button,
  DropdownArrowIcon,
  SmallText
} from "@ikiru/talentis-fpc"
import { Form } from "formik"

type TextStyledProps = {
  expanded: boolean
  visibleLines: number
  ref?: React.MutableRefObject<HTMLParagraphElement | undefined>
}

type DropDownIconStyledProps = {
  up?: boolean
}

export const DropDownIconStyled = styled(
  DropdownArrowIcon
)<DropDownIconStyledProps>`
  ${({ up }) => up && `transform: rotate(180deg)`};
`

const commonTextStyles = () => `
  display: -webkit-box;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0;
  width: 100%;
`

export const CommentWrapper = styled(SmallText)<TextStyledProps>`
  font-weight: 600;
  ${commonTextStyles}
  ${({ expanded, visibleLines }: TextStyledProps) =>
    !expanded && `-webkit-line-clamp: ${visibleLines};`};
  margin-bottom: 0;
`

export const DescriptionWrapper = styled(BodyText)<TextStyledProps>`
  ${commonTextStyles}
  ${({ expanded, visibleLines }: TextStyledProps) =>
    !expanded && `-webkit-line-clamp: ${visibleLines};`};
  margin-bottom: 15px;
`

export const ButtonStyled = styled(Button)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`

export const StyledForm = styled(Form)`
  height: 230px;
`

export const StyledParseTextForm = styled(Form)`
  height: 400px;
`
