import React, { useCallback, useReducer } from "react"
import { SearchContext } from "./context"
import { searchReducer, initialSearchState } from "./reducer"
import {
  SearchActionTypes,
  SetIsLoading,
  SetPage,
  SetPersons,
  SetSelectedSearchPersonId,
  SetPagination,
  UpdatePerson
} from "./types"
type SearchModuleProps = {
  children: React.ReactNode
}

export const SearchModule = (props: SearchModuleProps) => {
  const { children } = props

  const [state, dispatch] = useReducer(searchReducer, initialSearchState)

  const setIsLoading: SetIsLoading = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.SetIsLoading, payload: data })
    },
    [dispatch]
  )

  const setPagination: SetPagination = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.SetPagination, payload: data })
    },
    [dispatch]
  )

  const setPage: SetPage = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetPage,
        payload: data
      })
    },
    [dispatch]
  )

  const setPersonsFound: SetPersons = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetPersons,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedSearchPersonId: SetSelectedSearchPersonId = useCallback(
    (personId: string) => {
      dispatch({
        type: SearchActionTypes.SetSelectedSearchPersonId,
        payload: personId
      })
    },
    [dispatch]
  )

  const updatePerson: UpdatePerson = useCallback(
    (person) => {
      dispatch({
        type: SearchActionTypes.UpdatePerson,
        payload: person
      })
    },
    [dispatch]
  )

  return (
    <SearchContext.Provider
      value={{
        ...state,
        onPageChange: setPage,
        setPersonsFound,
        setIsLoading,
        setPagination,
        setSelectedSearchPersonId,
        updatePerson
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
