import React from "react"
import {
  Flex,
  PolygonIcon,
  Avatar,
  Div,
  colors,
  H6,
  theme
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  StyledPopupTrigger,
  PlusIconStyled,
  StyledTitle,
  StyledPopupOption
} from "./style"
import { ProjectTeamMember, Membership } from "./types"
import { capitalizeFirstLetter } from "views/search/SearchModule/utils"
import Tooltip from "components/Tooltip/Tooltip"
import { navigationSelectors } from "components/visual/layout/definitions"
import { PopupProps as ReactPopupProps } from "reactjs-popup/dist/types"
import { StyledSystemProps } from "@ikiru/talentis-fpc/dist/components/helpers/DynamicComponent"
import AddTeamMemberOption from "./AddTeamMembers"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"

type ProjectTeamProps = {
  projectTeamMembers: ProjectTeamMember[]
  isList?: boolean
  title?: string
  showLabel?: boolean
  useAddButton?: boolean
  showAddButton?: boolean
  onClickAddButton?: () => void
  isOffLimits?: boolean
  overridePopupConfig?: {
    PopupProps?: Partial<ReactPopupProps>
    ContentProps?: StyledSystemProps
  }
  sortingFn?: (a: ProjectTeamMember, b: ProjectTeamMember) => number
}

export const ProjectTeam: React.FC<ProjectTeamProps> = ({
  projectTeamMembers,
  isList,
  title,
  onClickAddButton,
  showLabel = false,
  useAddButton = false,
  isOffLimits = false,
  showAddButton = false,
  overridePopupConfig = {},
  sortingFn = (a: ProjectTeamMember, b: ProjectTeamMember) => {
    if (a.membership === b.membership) {
      const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
      const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
      return nameA.localeCompare(nameB)
    } else if (
      a.membership === Membership.Owner &&
      b.membership === Membership.Member
    ) {
      return -1
    } else {
      return 1
    }
  }
}) => {
  const projectTeamMembersSort = projectTeamMembers?.sort(sortingFn) || []

  const options: HeaderOption[] = [
    { label: <StyledTitle>{title}</StyledTitle> },
    ...projectTeamMembersSort.map((teamMember: ProjectTeamMember) => ({
      label: (
        <StyledPopupOption key={teamMember.userId}>
          <H6 m="0" color={colors.grey.dark}>
            {teamMember.firstName} {teamMember.lastName}
          </H6>
          <H6 m="0" color={colors.grey.standard} width="20%">
            {capitalizeFirstLetter(teamMember.membership || "")}
          </H6>
        </StyledPopupOption>
      )
    }))
  ]

  if (showAddButton) {
    options.push({
      label: (
        <AddTeamMemberOption
          onClick={() => onClickAddButton && onClickAddButton()}
        />
      )
    })
  }

  const label = isList ? (
    <H6 m="none" mr="xxs" color={colors.green.dark} fontWeight="500">
      {title || messages.assignment.assignmentTeam}:
    </H6>
  ) : (
    <H6 mt="0" mr="xxs" mb="xxs">
      {title || messages.assignment.assignmentTeam}:
    </H6>
  )

  return (
    <Flex alignItems="baseline" flexWrap="wrap">
      <Div mr={showLabel && !projectTeamMembers?.length ? "55px" : 0}>
        {!projectTeamMembers?.length && !useAddButton
          ? showLabel
            ? label
            : null
          : label}
      </Div>

      {projectTeamMembersSort.length
        ? projectTeamMembersSort
            .slice(0, 5)
            .map((teamMember: ProjectTeamMember) => (
              <Tooltip
                key={teamMember.userId}
                content={
                  <H6 m="xs" color={colors.grey.dark}>
                    {capitalizeFirstLetter(teamMember.membership || "")}:
                    {` ${teamMember.firstName} ${teamMember.lastName}`}
                  </H6>
                }
              >
                <Div
                  width={isList ? 26 : 30}
                  height={isList ? 26 : 30}
                  mr="5px"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  <Avatar
                    borderless
                    name={`${teamMember.firstName} ${teamMember.lastName}`}
                    colorBackground={teamMember.color}
                  />
                </Div>
              </Tooltip>
            ))
        : useAddButton && (
            <Div onClick={onClickAddButton}>
              <PlusIconStyled
                fillColor={theme.colors.grey.dark}
                backgroundColor={
                  isOffLimits
                    ? theme.colors.white.standard
                    : theme.colors.grey.light
                }
              />
            </Div>
          )}
      {Boolean(projectTeamMembersSort.length) && (
        <OptionsPopup
          alignSelf="center"
          display="flex"
          config={{
            PopupProps: {
              closeOnDocumentClick: true,
              position: "bottom left",
              arrow: false
            },
            ...overridePopupConfig
          }}
          closeOnScrollSelector={`#${navigationSelectors.mainContainer}`}
          trigger={
            <StyledPopupTrigger
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              {projectTeamMembersSort.length > 5 && (
                <H6 m="none" mr="2px" ml="5px">
                  {`+${projectTeamMembersSort.length - 5}`}
                </H6>
              )}
              <PolygonIcon />
            </StyledPopupTrigger>
          }
          options={options}
        />
      )}
    </Flex>
  )
}
