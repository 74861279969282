import React, { useEffect, useRef } from "react"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { CustomColumnDetailsType } from "../DropContentColumn/DropContainer"
import { DragItemContainer } from "./style"

export type OnDropProps = {
  locationId: string
  locationName: string
  movingId: string
  movingName: string
}

type DragContentItemProps = {
  dataId: string
  objectName: string
  dragType: string
  content: string
  onDropCallback: (props: OnDropProps) => void
  children: JSX.Element
}

export const DragContentItem = ({
  dragType,
  dataId,
  objectName,
  content,
  onDropCallback,
  children
}: DragContentItemProps) => {
  const ref = useRef(null)

  const [, drag, preview] = useDrag({
    type: dragType,
    item: { objectName, dataId, content },
    end: (item, monitor) => {
      const dropResult: CustomColumnDetailsType | null = monitor.getDropResult()
      if (dropResult?.dataId) {
        const { dataId, objectName } = dropResult
        onDropCallback({
          locationId: dataId,
          locationName: objectName,
          movingId: item.dataId,
          movingName: item.objectName
        })
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(ref)

  useEffect(() => {
    preview(getEmptyImage())
  }, [preview])

  return <DragItemContainer ref={ref}>{children}</DragItemContainer>
}
