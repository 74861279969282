import { generateWithoutReadOnly } from "models/LocalPerson/localPerson.utils"
import { useMemo } from "react"
import { nestedKeyError, prepareRequestData } from "../utlis"
import { camelCaseErrors } from "utils/transformer"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "views/persons/components/person-details/utils/get-person-details"
import {
  OnPersonDetailsSubmitType,
  callProfileEndpoint,
  linkPersonToDatapool,
  onEditPersonSubmit
} from "views/persons/components/person-details/form/actions"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { useModal } from "utils/hooks/use-modal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { CreatePersonField } from "../constant/definitions"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

const useSubmitPerson = () => {
  const {
    profileDetails,
    setProfileDetails,
    personId: localPersonId,
    setPersonOverlayUpdated
  } = usePerson()
  const { createLocalPerson } = usePersonDataPoolLinkage()

  const { close } = useModal()

  const profileOldBio = useMemo(() => profileDetails.bio, [profileDetails])

  const onPersonSubmit: OnPersonDetailsSubmitType = async (
    values,
    formikHelpers
  ) => {
    let personId = await createLocalPerson(localPersonId)

    const prepared = prepareRequestData(values, profileOldBio)
    const data = generateWithoutReadOnly(prepared, [
      "name",
      "company",
      "linkedInProfileUrl",
      "bio",
      "dateOfBirth"
    ])

    let [error, response] = await onEditPersonSubmit(personId)(
      data as PersonProfileDetails,
      formikHelpers
    )

    if (
      error?.data?.title === "There is a conflict." &&
      error?.data?.status === 409
    ) {
      formikHelpers.setFieldError(
        `${CreatePersonField.LinkedInUrl}.value`,
        messages.person.details.linkError
      )
    } else {
      if (values.linkedInProfileUrl && values.linkedInProfileUrl.value) {
        try {
          await linkPersonToDatapool(personId)
          const [, profileResponse] = await callProfileEndpoint(personId)
          response = profileResponse
        } catch (e) {
          console.log(e)
        }
      }

      if (error?.data?.status === 400) {
        const camelCasedErrors = camelCaseErrors(error.data?.errors)

        Object.entries(camelCasedErrors).forEach(([key, value]) => {
          nestedKeyError(key, value as string, formikHelpers)
        })
      } else {
        const mergedPersonData = new LocalPersonModel(response?.data)

        const profileDetails =
          getNormalizedPersonProfileDetails(mergedPersonData)

        setProfileDetails(profileDetails)
        close(ModalNames.EditPersonDetails)
        setPersonOverlayUpdated(true)
      }
    }
  }

  return { onPersonSubmit }
}

export default useSubmitPerson
