import React, { useState } from "react"
import { H5, Span, Button, Div, Flex, Link, Error } from "@ikiru/talentis-fpc"
import { Nullable } from "tsdef"
import { RouterUrl } from "setup/router/routes"
import { FormSubmitButtons } from "views/companies/components/company-details/components/style"
import { LinkContainerStyled } from "../../assignment-client-details/components/ViewAssignmentClientDetails/styles"
import { LinkedInForm } from "../../assignment-client-details/linkedin-form"
import { messages } from "setup/messages/messages"
import { LinkUsedCompanyType } from "../AssignmentDetailsSection"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { linkCompanyToDatapool } from "../../assignment-client-details/linkedin-form/actions"
import { LinkedInUrlField } from "../../assignment-client-details/linkedin-form/constants/definitions"

type LinkedInCheckerProps = {
  editLinkedIn: boolean
  setEditLinkedIn: React.Dispatch<React.SetStateAction<boolean>>
  linkUsedCompany: Nullable<LinkUsedCompanyType>
  setLinkUsedCompany: React.Dispatch<
    React.SetStateAction<Nullable<LinkUsedCompanyType>>
  >
  setShowLinkSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const LinkedInChecker = ({
  editLinkedIn,
  setEditLinkedIn,
  linkUsedCompany,
  setLinkUsedCompany,
  setShowLinkSuccess
}: LinkedInCheckerProps) => {
  const [companyNotFound, setCompanyNotFound] = useState(false)
  const {
    assignmentDetails,
    setAssignmentDetails,
    assignmentId,
    patchAssignmentData
  } = useAssignment()

  const handleLinkedInSubmit = async (values: any, action: any) => {
    setCompanyNotFound(false)
    const [error] = await linkCompanyToDatapool()(
      values,
      assignmentDetails.companyId!,
      action
    )
    if (error?.data?.ConflictDetails && error?.status === 409) {
      setLinkUsedCompany(error.data.ConflictDetails)
      action.setFieldError(
        `${LinkedInUrlField.LinkedInUrl}.value`,
        messages.assignment.linkExists
      )
    } else if (error) {
      setCompanyNotFound(true)
    } else {
      setAssignmentDetails({
        ...assignmentDetails,
        isCompanyLinkedToDataPool: true
      })
      setEditLinkedIn(false)
      setTimeout(() => setShowLinkSuccess(false), 4000)

      setShowLinkSuccess(true)
    }
  }
  return (
    <>
      {editLinkedIn && (
        <LinkContainerStyled>
          <>
            <H5 width="100%" textAlign="left" my="xs">
              {messages.assignment.addLinkedInExample}:
            </H5>
            <Span mb="xs">https://www.linkedin.com/company/ikiru-people/</Span>
            <LinkedInForm
              formActionsComponent={(formikProps) => (
                <FormSubmitButtons>
                  <Button
                    type="submit"
                    size="small"
                    mr="xs"
                    disabled={formikProps.isSubmitting}
                  >
                    {messages.generic.save}
                  </Button>
                  <Button
                    mode="standard-white"
                    size="small"
                    disabled={formikProps.isSubmitting}
                    type="button"
                    onClick={() => {
                      setEditLinkedIn(false)
                      setCompanyNotFound(false)
                      setLinkUsedCompany(null)
                      formikProps.resetForm()
                    }}
                  >
                    {messages.generic.cancel}
                  </Button>
                </FormSubmitButtons>
              )}
              onSubmit={handleLinkedInSubmit}
            />
            {companyNotFound && (
              <Error>{messages.assignment.linkCorrect}</Error>
            )}
            {linkUsedCompany && (
              <>
                <Div>
                  {messages.assignment.linkUsedBy}{" "}
                  <Span fontWeight="600">{linkUsedCompany.Name}</Span>.{" "}
                  {messages.assignment.wouldLink}{" "}
                  <Link
                    href={`${RouterUrl.CompanyDetails}/${linkUsedCompany.Id}`}
                  >
                    {linkUsedCompany.Name}
                    {linkUsedCompany.LogoUrl}
                  </Link>
                  ?
                </Div>
                <Flex justifyContent="flex-end" width="100%" mb="xs" mt="s">
                  <Button
                    onClick={async () => {
                      await patchAssignmentData(assignmentId, {
                        companyName: linkUsedCompany.Name,
                        companyId: linkUsedCompany.Id,
                        companyLogo: linkUsedCompany.LogoUrl
                      })
                      setAssignmentDetails({
                        ...assignmentDetails,
                        isCompanyLinkedToDataPool: true,
                        companyName: linkUsedCompany.Name,
                        companyId: linkUsedCompany.Id,
                        companyLogo: linkUsedCompany.LogoUrl
                      })
                      setLinkUsedCompany(null)
                      setEditLinkedIn(false)
                    }}
                    type="button"
                    size="small"
                    mr="xs"
                  >
                    {messages.generic.yes}
                  </Button>
                  <Button
                    mode="standard-white"
                    size="small"
                    type="button"
                    onClick={() => {
                      setLinkUsedCompany(null)
                    }}
                  >
                    {messages.generic.noCancel}
                  </Button>
                </Flex>
              </>
            )}
          </>
        </LinkContainerStyled>
      )}
    </>
  )
}

export default LinkedInChecker
