import {
  Button,
  CrossIcon,
  Div,
  Flex,
  H2,
  Loader,
  PlusIcon,
  RadioBox
} from "@ikiru/talentis-fpc"
import { Note } from "components/NoteItem/types"
import { noop } from "lodash"
import React from "react"
import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"
import { Candidate } from "views/candidates/candidates.types"
import { useCandidateNotes } from "./hooks"
import NoteBox from "./NoteBox"
import NoteFormWrapper from "./NoteForm/Wrapper"
import { NoteSelectionModalWrapper } from "./styles"

type NoteSelectionModalProps = {
  candidate: Candidate
  onCancel?: VoidFunction
  onSave?: (note: Nullable<Note>) => void
}

const NoteSelectionModal = ({
  candidate,
  onCancel = noop,
  onSave = noop
}: NoteSelectionModalProps) => {
  const {
    isLoading,
    notes,
    selectedNote,
    isNewNoteMode,
    onUpdateNote,
    onSelectNote,
    setNewNoteMode,
    onCreateNoteSubmit
  } = useCandidateNotes(candidate)

  return (
    <NoteSelectionModalWrapper>
      <Flex flexDirection="column" maxHeight={550}>
        <Flex pt="m" px="m" pb="xs" justifyContent="space-between">
          <H2 mb="xs" mt="none" maxWidth="90%" color="grey.dark">
            {messages.clientPortal.notes.selectNoteFor.format(
              candidate.normalizedPersonData?.name || ""
            )}
          </H2>
          <Button
            mode="standard-white"
            size="action-medium"
            onClick={onCancel}
            title={messages.generic.close}
          >
            <CrossIcon title={messages.generic.close} />
          </Button>
        </Flex>
        <Div px="m" pb="xs">
          <RadioBox
            checked={selectedNote === null}
            onChange={() => onSelectNote(null)}
            title={messages.clientPortal.notes.dontShow}
          />
          <Button
            mt="xxs"
            size="extra-small"
            mode="standard-grey-light"
            onClick={() => setNewNoteMode(true)}
            prefix={<PlusIcon fill="currentColor" width={12} height={12} />}
          >
            {messages.clientPortal.notes.newNote}
          </Button>
        </Div>
        {isNewNoteMode && (
          <Div mb="xs" px="m">
            <NoteFormWrapper
              onCancel={() => setNewNoteMode(false)}
              onSubmit={onCreateNoteSubmit}
            />
          </Div>
        )}
        <Div px="m" overflowY="auto">
          {notes
            ?.sort((note1, note2) => {
              if (note1.id === candidate?.sharedNoteId) {
                return -1
              } else if (note2.id === candidate?.sharedNoteId) {
                return 1
              }
              return 0
            })
            .map((note) => (
              <Div key={note.id} mb="xxs">
                <NoteBox
                  {...{ note, onSelectNote, onUpdateNote }}
                  isSelected={selectedNote?.id === note.id}
                  onSelectNote={onSelectNote}
                  personId={candidate.personId}
                />
              </Div>
            ))}
          {isLoading && <Loader />}
        </Div>
        <Flex justifyContent="center" py="xs" bg="grey.light">
          <Button onClick={() => onSave?.(selectedNote)}>
            {messages.clientPortal.notes.saveChanges}
          </Button>
        </Flex>
      </Flex>
    </NoteSelectionModalWrapper>
  )
}

export default React.memo(NoteSelectionModal)
