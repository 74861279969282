import styled from "styled-components"
import { Flex } from "@ikiru/talentis-fpc"
interface CampaignTabContainerProps {
  isSelected: boolean
}
interface TabsContainerProps {
  numberColumns: number
}

export const TabsContainer = styled(Flex)<TabsContainerProps>`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const CampaignTabContainer = styled(Flex)<CampaignTabContainerProps>`
  flex-direction: column;
  background: rgba(213, 222, 231, 0.6);
  align-items: center;

  margin-right: 2px;
  height: 47px;
  padding-top: 5px;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.green.standard : theme.colors?.white?.standard};
`
