import React, { useMemo } from "react"
import { Nullable } from "tsdef"
import { Link, getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

type PersonLinkProps = {
  personId?: Nullable<string>
  dataPoolId?: string
  children: React.ReactNode
  underline?: boolean
  setPersonId: (data: string) => void
  setOverlayTaskIndex?: (taskIndex: number) => void
  taskIndex?: number
  companyId?: string
}

export const PersonLink = React.memo(
  ({
    personId,
    dataPoolId,
    children,
    underline,
    setPersonId,
    setOverlayTaskIndex,
    taskIndex,
    companyId
  }: PersonLinkProps) => {
    const { selectedTab, setSelectedCompany } = useAssignment()

    const personUrl = useMemo(
      () => `${RouterUrl.ProfileDetails}/${personId}`,
      [personId]
    )

    const mediaQuery = getMinWidthMediaQuery("md")
    const isLarge = useMediaQuery(mediaQuery)

    const openOverlay = () => {
      if (selectedTab === "companyCandidate") {
        setSelectedCompany(companyId)
      }

      setOverlayTaskIndex && setOverlayTaskIndex(taskIndex || 0)

      if (dataPoolId) setPersonId(dataPoolId)
      else if (personId) {
        setPersonId(personId)
      }
    }

    return (
      <Link
        mb={0}
        fontWeight={600}
        mr="xxs"
        underline={underline}
        {...(personUrl
          ? isLarge
            ? { onClick: openOverlay }
            : dataPoolId && !personId
            ? {}
            : { href: personUrl }
          : {})}
        {...{ cursor: underline || isLarge ? "pointer" : "normal" }}
      >
        {children}
      </Link>
    )
  }
)
