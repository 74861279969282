import React, { RefObject, useCallback } from "react"
import Column from "./Columns"
import { CurrentItemType, MovableItem } from "./Items"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { CustomDragLayer } from "./Items/CustomDragLayer"
import {
  ColumnType,
  ItemDataType,
  ExplainMessageProps
} from "./constants/types"

type DragAndDroProps = {
  ref?: RefObject<HTMLDivElement>
  columns: ColumnType[]
  itemsData: ItemDataType[]
  explainMessage?: ExplainMessageProps
  DragPreviewCustomContainer?: (props: any) => JSX.Element
  setItems: (currentItem: CurrentItemType, columnId: string) => void
}

export const DragAndDrop = ({
  columns,
  itemsData = [],
  explainMessage,
  setItems,
  DragPreviewCustomContainer
}: DragAndDroProps) => {
  const getColumnCard = useCallback(
    (columnId: string): ((props: any) => JSX.Element) => {
      return columns.find((column) => column.id === columnId)!.DragComponent
    },
    [columns]
  )

  const returnItemsForColumn = useCallback(
    (columnId: string) => {
      return itemsData
        .filter((item) => item.columnId === columnId)
        .map((item, index) => (
          <MovableItem
            key={item.id}
            itemId={item.id}
            currentColumnId={item.columnId}
            type={item.type}
            setItems={setItems}
            ItemComponent={getColumnCard(item.columnId)}
            itemProps={item.itemComponentProps}
            index={index}
          />
        ))
    },
    [getColumnCard, itemsData, setItems]
  )

  return (
    <>
      <CustomDragLayer
        itemList={itemsData}
        columns={columns}
        DragPreviewCustomContainer={DragPreviewCustomContainer}
      />
      <Flex justifyContent="space-evenly" width="100%">
        {columns.map((c, index) => {
          return (
            <Div
              key={c.id}
              minWidth={
                columns[0].sideTitle ? (index === 0 ? "57%" : "43%") : "50%"
              }
              mr={index === 0 ? c.marginRight : 0}
            >
              <Column
                key={c.id}
                columnDetails={c}
                explainMessage={explainMessage}
                ActionComponent={c.actionComponent}
              >
                {returnItemsForColumn(c.id)}
              </Column>
            </Div>
          )
        })}
      </Flex>
    </>
  )
}
