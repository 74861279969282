import React from "react"
import {
  Button,
  BodyText,
  Div,
  Flex,
  H4,
  Line,
  SmallText,
  colors,
  Span
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { HintKeywordsModalWrapper } from "./style"
import HintKeywordsANDPicture from "assets/keywordsAND.png"
import HintKeywordsORPicture from "assets/keywordsOR.png"

export const HintKeywordsModal = () => {
  const { close } = useModal()

  return (
    <HintKeywordsModalWrapper>
      <Flex justifyContent="space-between">
        <H4 color={colors.grey.dark} mt="0">
          {messages.talentGraphSearch.searchKeywordTitle}
        </H4>
        <Button onClick={() => close(ModalNames.HintKeywords)} mode="primary">
          {messages.talentGraphSearch.gotIt}
        </Button>
      </Flex>
      <BodyText textAlign="center" mb="m">
        {messages.talentGraphSearch.searchKeywordParagraphFirst}
        <Span fontWeight="bold">
          {messages.talentGraphSearch.searchKeywordParagraphSecond}
        </Span>
        <br />
        {messages.talentGraphSearch.searchKeywordHint}
      </BodyText>

      <Flex justifyContent="space-between" mx="xl">
        <Flex flexDirection="column">
          <img
            alt="Hint and an example"
            width={300}
            src={HintKeywordsANDPicture}
          />
          <SmallText textAlign="center" mt="xs">
            {messages.talentGraphSearch.searchKeywordsExampleAnd}
          </SmallText>
        </Flex>
        <Flex flexDirection="column">
          <img
            alt="Hint or an example"
            width={300}
            src={HintKeywordsORPicture}
          />
          <SmallText textAlign="center" mt="xs">
            {messages.talentGraphSearch.searchKeywordsExampleOr}
          </SmallText>
        </Flex>
      </Flex>
      <Line variant="thin" mt="xl" mb="s" />
      <BodyText textAlign="center" mb="xs">
        {messages.talentGraphSearch.searchKeywordsVideo}
      </BodyText>
      <Div textAlign="center" mb="s">
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/883187823?h=3071562af3"
          width="430"
          height="260"
          frameBorder="0"
          allowFullScreen
        />
      </Div>
    </HintKeywordsModalWrapper>
  )
}
