import React from "react"
import { messages } from "setup/messages/messages"
import { colors } from "@ikiru/talentis-fpc"
import { H1, LargeText } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"
import { RouterLink } from "components/RouterLink/RouterLink"
import { RouterUrl } from "setup/router/routes"

type ErrorMessages = { header: string; content: string }

type RegisterInvitedUserTokenInvalidProps = {
  errorMessage?: ErrorMessages
}

export const RegisterInvitedUserTokenInvalid = (
  props: RegisterInvitedUserTokenInvalidProps
) => {
  const { errorMessage } = props

  return (
    <Div textAlign="center">
      <H1
        fontSize={["29px"]}
        lineHeight={["32px"]}
        color={colors.green.standard}
      >
        {errorMessage?.header || messages.registerUser.invalid.base}
      </H1>
      <Div px="s">
        <LargeText color={colors.grey.standard}>
          {errorMessage?.content}
        </LargeText>
      </Div>
      <RouterLink to={RouterUrl.Homepage}>{messages.signup.logIn}</RouterLink>
    </Div>
  )
}
