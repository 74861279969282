import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { fetchPersonPhotos } from "models/LocalPerson/localPerson.actions"
import { LocalPerson } from "models/LocalPerson/localPerson.definitions"

export const formatResults = (results: LocalPerson[]) =>
  results?.map((person) => new LocalPersonModel(person).getPersonValues())

export const getPersonsWithPhotos = async (personsFound: any) => {
  const personIds = personsFound.map((person: any) => person.id)

  const personPhotoList = (await fetchPersonPhotos(personIds)) || []

  const personWithPhotos = personsFound.map((person: any) => {
    const foundPerson =
      personPhotoList.find((photo) => person.id === photo.personId)?.photo || ""
    person["photo"] = { url: foundPerson }
    return person
  })

  return personWithPhotos
}
