import { useEffect, useState } from "react"
import { InterviewProgressStatus } from "views/campaigns/components/contacts/components/contact-record/components/InterviewProgress/definitions"
import {
  getAppliedFiltersParams,
  getDataFilter
} from "components/ProjectRecordsFilters/helpers"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

export const useFilterData = () => {
  const { appliedFilters, campaignId } = useCampaign()

  const [statusFilter, setStatusFilter] = useState<InterviewProgressStatus[]>(
    []
  )
  const [assignToFilter, setAssignToFilter] = useState<string[]>([])
  const [tagIdsFilter, setTagIdsFilter] = useState<number[]>([])
  const [dueDateFilter, setDueDateFilter] = useState<Date[]>([])

  useEffect(() => {
    let isFiltersLoading = true

    const fetchFilters = async () => {
      const params = getAppliedFiltersParams(appliedFilters)

      const [, response] = await apiRequest.get({
        endpoint: ContactsEndpoints.FilterData,
        endpointParams: { campaignId: campaignId },
        config: {
          params: params,
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (isFiltersLoading) {
        const { statuses, assignedsTo, dueDates, tags } = response?.data || {}

        setStatusFilter(getDataFilter(statuses))
        setAssignToFilter(getDataFilter(assignedsTo))
        setTagIdsFilter(getDataFilter(tags))
        setDueDateFilter(getDataFilter(dueDates))
      }
    }

    fetchFilters()

    return () => {
      isFiltersLoading = false
    }
  }, [appliedFilters, campaignId])

  return {
    statusFilter,
    assignToFilter,
    tagIdsFilter,
    dueDateFilter
  }
}
