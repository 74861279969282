import React, { useState, useCallback, useRef, useEffect } from "react"
import {
  EditableDetailsProps,
  EditableDetailsMode
} from "components/EditableDetails/editable-details.types"
import { Div } from "@ikiru/talentis-fpc"

export const EditableDetails = (props: EditableDetailsProps) => {
  const {
    view,
    edit,
    actions,
    footer,
    actionsOnBottom = false,
    height = "auto",
    position,
    defaultEditMode = false,
    noBottomSpace = false
  } = props
  const [mode, setMode] = useState<EditableDetailsMode>(
    defaultEditMode ? "edit" : "view"
  )
  useEffect(() => {
    if (defaultEditMode !== null) {
      setMode(defaultEditMode ? "edit" : "view")
    }
  }, [defaultEditMode])
  const ref = useRef<HTMLDivElement>(null)

  const isInEditMode = mode === "edit"
  const isInViewMode = mode === "view"

  const setEditMode = useCallback(() => setMode("edit"), [])
  const setViewMode = useCallback(() => setMode("view"), [])

  const payload = { setEditMode, setViewMode, isInEditMode, isInViewMode, ref }

  return (
    <Div
      ref={ref}
      width="100%"
      mb={noBottomSpace ? "0" : "s"}
      style={{ height }}
      {...(position ? { position } : {})}
    >
      {Boolean(!actionsOnBottom) && actions?.(payload)}
      {isInEditMode && edit?.(payload)}
      {isInViewMode && view(payload)}
      {Boolean(actionsOnBottom) && actions?.(payload)}
      {footer?.(payload)}
    </Div>
  )
}
