import { Button, H6, Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

type Props = {
  isSelected?: boolean
  noPadding?: boolean
}

export const SwitchLabel = styled(H6)<Props>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.grey.dark : theme.colors?.grey.standard};
  margin-left: ${({ theme }) => theme.space?.xxs}px;
  margin-top: 0px;
  margin-bottom: ${({ theme }) => theme.space?.xxs}px;
`

export const ProjectButton = styled(Button)`
  width: 125px;
  min-height: 30px;
  margin-top: -5px;
`

export const ProjectButtonLabel = styled(H6)`
  color: ${({ theme }) => theme?.colors?.white.standard};
  margin: 0px;
  margin-left: -10px;
`
export const StyledFlex = styled(Flex)`
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
`
