import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { GeneralFilters } from "components/ProjectRecordsFilters/filters/GeneralFilters"
import { messages } from "setup/messages/messages"
import { useContactFilter } from "./hooks"
import useEditCampaignFilter from "./hooks/useEditCampaignFilter"

export const FilterContact = () => {
  const {
    interviewProgressStatusFilters,
    assignedToFilters,
    dueDateFilters,
    tagsFilters
  } = useContactFilter()
  const { addFilter, filterIsApplied, removeFilter } = useEditCampaignFilter()
  const generalFiltersData = [
    {
      translatedString: messages.assignment.filters.status,
      filtersData: interviewProgressStatusFilters,
      marLeft: "none"
    },
    {
      translatedString: messages.assignment.filters.assignedTo,
      filtersData: assignedToFilters
    },
    {
      translatedString: messages.assignment.filters.dueDate,
      filtersData: dueDateFilters
    },
    {
      translatedString: messages.assignment.filters.tagsCandidates,
      filtersData: tagsFilters,
      widthMax: "195px"
    }
  ]

  const filterMap = generalFiltersData.map(
    ({
      translatedString,
      filtersData,
      marLeft = "xxs",
      widthMax = "170px"
    }) => {
      return (
        <Div
          minWidth={["auto", widthMax]}
          ml={marLeft}
          bg="white.standard"
          flex={1}
        >
          <GeneralFilters
            translatedString={translatedString}
            filtersData={filtersData}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
      )
    }
  )

  return <>{filterMap}</>
}
