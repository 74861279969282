import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { FormAction } from "utils/forms/form.types"
import { Campaign } from "./campaign.types"
import { parseMomentDate } from "utils/moment"

export type OnCampaignDetailsSubmit = FormAction<Campaign>

type CampaignUpdateCallback = (data: Campaign) => void
type ApiRequestType = (
  values: Partial<Campaign>
) => Promise<NormalizedReturnTuple>

const onUpdate =
  <T>(request: ApiRequestType, callback?: CampaignUpdateCallback) =>
  async (values: T, helpers: FormikHelpers<T>) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const newCampaignData = { ...values }
    const [error, response] = await request(newCampaignData)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)

    if (response) {
      const { data = {} } = response

      const startDate = parseMomentDate(data?.startDate)

      callback?.({ ...data, startDate })
    }

    return [error, response]
  }

export const patchCampaign = <T extends Partial<Campaign>>(
  campaign: Partial<Campaign>,
  callback?: CampaignUpdateCallback
) =>
  onUpdate<T>(
    (values) =>
      apiRequest.patch({
        endpoint: CampaignsEndpoints.Root,
        data: values,
        endpointParams: campaign.id
      }),
    callback
  )
