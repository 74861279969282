import { FormikHelpers } from "formik"
import { CreateAssignmentValues } from "../assignment-create-form/constants/assignment-details-form.definitions"
import { AssignmentRoleDetailsData } from "../assignment-role-details/constants/definitions"
import { Location } from "views/assignments/assignment.types"
import { getChangedValues } from "views/assignments/utils"

export const beforeLocationSubmit = (
  value: CreateAssignmentValues | AssignmentRoleDetailsData,
  action: FormikHelpers<any>,
  onSubmit: (
    value: AssignmentRoleDetailsData | CreateAssignmentValues,
    action: FormikHelpers<any>
  ) => void,
  formatedValue: Location,
  initialValues?: AssignmentRoleDetailsData
) => {
  if (formatedValue.label !== "") {
    value.location = formatedValue.label.trim()
    value.geoLocation = formatedValue?.value
  }

  if (initialValues) {
    const changeValues = getChangedValues(value, initialValues)
    onSubmit(changeValues, action)
  } else {
    onSubmit(value, action)
  }
}
