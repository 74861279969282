import React from "react"
import { ModalModule } from "setup/modal/ModalModule"
import { PersonModule } from "views/persons/person-module/PersonModule"

interface Props {
  children: React.ReactNode
}

export const PersonProfileWrapper = ({ children }: Props) => {
  return (
    <PersonModule>
      <ModalModule>{children}</ModalModule>
    </PersonModule>
  )
}
