import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { KeywordsList } from "views/search/components/KeywordsList"

type MultiPersonInputProps = {
  name: string
  label: string
  placeholder?: string
  onChange: (data: string[]) => void
  inputList: string[]
}

const MultiPersonInput = ({
  name,
  label,
  placeholder = "",
  onChange,
  inputList
}: MultiPersonInputProps) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      Boolean(e.currentTarget.value) &&
        onChange([...inputList, e.currentTarget.value])
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    Boolean(e.currentTarget.value) &&
      onChange([...inputList, e.currentTarget.value])
  }

  const removeItem = (value: string) => {
    const newKeywordList =
      inputList.filter((keyword) => keyword !== value) || []
    onChange(newKeywordList)
  }

  return (
    <Div>
      <FormikInput
        name={name}
        label={label}
        placeholder={placeholder}
        disableDefaultOnBlur
        {...{
          onKeyPress: onKeyPress as (
            e: React.KeyboardEvent<HTMLInputElement>
          ) => void,
          onBlur: onBlur as (e: React.FocusEvent<HTMLInputElement>) => void
        }}
      />
      {inputList && (
        <Div height=" fit-content" maxHeight="10svh" overflowY="auto">
          <KeywordsList
            keywords={inputList || []}
            onKeywordRemove={removeItem}
          />
        </Div>
      )}
    </Div>
  )
}

export default MultiPersonInput
