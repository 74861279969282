import React from "react"
import { Flex } from "@ikiru/talentis-fpc"

const FilterWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => (
  <Flex
    height="auto"
    pt="2px"
    pb="xxs"
    width="100%"
    bg="green.lightest"
    justifyContent="center"
    position="relative"
  >
    {children}
  </Flex>
)

export default React.memo(FilterWrapper)
