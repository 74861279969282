import { Assignment } from "views/assignments/assignment.types"

export enum AssignmentClientDetailsField {
  CompanyName = "companyName",
  CompanyId = "companyId",
  isCompanyLinkedToDataPool = "isCompanyLinkedToDataPool",
  id = "id"
}

export type AssignmentClientDetailsData = Pick<
  Assignment,
  "companyName" | "companyId" | "isCompanyLinkedToDataPool"
>

export const assignmentClientDetailsInitialValues: AssignmentClientDetailsData =
  {
    [AssignmentClientDetailsField.CompanyName]: ""
  }

export const e2eTargets = {
  clientDetails: "client-details",
  companyName: "company-name"
}

export const companyNamesMaxLength = 110
