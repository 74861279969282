import React from "react"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import NavigationArrow from "./NavigationArrows"
import { HorizontalScrollContainer } from "./style"

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>

const HorizontalScroll = ({
  selectedItem,
  children,
  isRowTab = false
}: any) => {
  const restorePosition = React.useCallback(
    ({
      scrollContainer,
      getItemById,
      scrollToItem
    }: scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        scrollToItem(getItemById(selectedItem), "auto")
      }
    },
    [selectedItem]
  )

  return (
    <HorizontalScrollContainer>
      <ScrollMenu
        LeftArrow={<NavigationArrow isNext={false} isRowTab={isRowTab} />}
        RightArrow={<NavigationArrow isNext={true} isRowTab={isRowTab} />}
        onInit={restorePosition}
      >
        {children}
        <div className=".react-horizontal-scrolling-menu--separator" />
      </ScrollMenu>
    </HorizontalScrollContainer>
  )
}

export default HorizontalScroll
