import { AssignmentDetails } from "views/assignments/pages/manage-assignment/assignment-details.types"
import {
  AssignmentDataField,
  AssignmentStatus
} from "../../assignment-data/constants/definitions"
import { getItem } from "@ikiru/talentis-fpc"
import { localStorageKeys } from "setup/storage/storage.definitions"

export const createAssignmentInitialValues: AssignmentDetails = {
  [AssignmentDataField.Status]:
    (getItem(
      localStorageKeys.assignmentStatusInput,
      false
    ) as AssignmentStatus) || AssignmentStatus.Active,
  [AssignmentDataField.CompanyName]: "",
  [AssignmentDataField.CompanyId]: "",
  [AssignmentDataField.JobTitle]: "",
  [AssignmentDataField.Location]: "",
  [AssignmentDataField.GeoLocation]: {
    type: "",
    id: "",
    entityType: "",
    address: {}
  }
}

export const e2eTargets = {
  submitButton: "submit-form",
  modifyAssignmentDetailsButton: "modify-assignment-details",
  editButton: "edit-assignment",
  cancelButton: "cancel-assignment-edit"
}

export type CreateAssignmentValues = typeof createAssignmentInitialValues
