import {
  AxiosError,
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosPromise,
  Method
} from "axios"
import { UserManager } from "oidc-client-ts"
import {
  setBearerToken,
  setNewAuthTokenForCurrentConfig
} from "setup/auth/module/api/set-headers"
import { isString } from "lodash"

type QueuedRequest = {
  config: AxiosRequestConfig
  resolve: (request: AxiosPromise<any>) => void
}

export class Authorize {
  refreshing = false
  manager: UserManager
  api: AxiosInstance
  awaitingRequests: QueuedRequest[] = []
  onError?: () => any = () => {}
  token = ""

  constructor(api: AxiosInstance, manager: UserManager, onError?: () => any) {
    this.api = api
    this.manager = manager
    this.onError = onError
  }

  private _revisitRequestsAwaitingForToken = () =>
    this.awaitingRequests.forEach(({ config, resolve }, index) => {
      this.token && setNewAuthTokenForCurrentConfig(config, this.token)

      resolve(this.api(config))

      this.awaitingRequests.splice(index, 1)
    })

  private _queueForNewToken = (config: AxiosRequestConfig) => {
    return new Promise((resolve) => {
      this.awaitingRequests.push({ config, resolve })
    })
  }

  public resolve = (response: AxiosResponse) => {
    if (this.awaitingRequests.length && this.token) {
      this._revisitRequestsAwaitingForToken()
    }
    return response
  }

  public reject = async (error: AxiosError) => {
    const currentConfig = error.config

    const methodsToParseBody: Method[] = [
      "POST",
      "post",
      "PUT",
      "put",
      "PATCH",
      "patch"
    ]

    if (
      currentConfig.method &&
      methodsToParseBody.includes(currentConfig.method) &&
      currentConfig.data
    ) {
      currentConfig.data = isString(currentConfig.data)
        ? JSON.parse(currentConfig.data)
        : currentConfig.data
    }

    if (error?.response?.status === 401) {
      if (!this.refreshing) {
        this.refreshing = true

        try {
          const user = await this.manager?.signinSilent()
          if (!user) {
            this.onError?.()
          } else {
            this.token = user.access_token

            setBearerToken(this.api, this.token)
            setNewAuthTokenForCurrentConfig(currentConfig, this.token)

            return this.api(currentConfig)
          }
        } catch (e) {
          this.onError?.()
        } finally {
          this.refreshing = false
          this._revisitRequestsAwaitingForToken()
        }
      } else {
        return this._queueForNewToken(currentConfig)
      }
    }

    return Promise.reject(error)
  }
}
