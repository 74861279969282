import React from "react"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ListPersonContacts } from "./components/ListPersonContacts"
import { PersonCampaignOption } from "./types"
import PersonSections from "../person-components/PersonSections"
import PersonSectionButton from "../person-components/PersonSectionButton"
import { useLinkToProject } from "views/persons/hooks/useLinKToProject"

type CampaignIsLoadingType = {
  campaigns: boolean
  contacts: boolean
}

export type PersonCampaignProps = {
  options: PersonCampaignOption[]
  loading: CampaignIsLoadingType
  project?: string
}

export const PersonCampaign = React.memo((props: PersonCampaignProps) => {
  const { contacts } = usePerson()
  const { loading } = props
  const { linkCampaignModal } = useLinkToProject()

  return (
    <PersonSections
      title={messages.person.campaigns.title}
      sectionCount={Object.values(contacts).length}
      actions={
        <PersonSectionButton
          disabled={loading.campaigns}
          mode="standard-grey-dark"
          label={messages.person.campaigns.campaignLink}
          onClick={() => linkCampaignModal(props)}
        />
      }
    >
      <ListPersonContacts contacts={contacts} isLoading={loading.contacts} />
    </PersonSections>
  )
})
