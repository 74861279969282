import React from "react"
import { Div, Flex, Input, SmallText, Textarea } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { CreateCampaignField } from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.definitions"

const CampaignCreateModal = ({
  values,
  handleChange,
  handleBlur,
  errors
}: any) => {
  return (
    <Flex width="100%" flexWrap="wrap" flexDirection="column" padding="s">
      <Div width="100%">
        <Input
          value={values?.name}
          onChange={handleChange}
          onBlur={handleBlur}
          id={CreateCampaignField.CampaignName}
          name={CreateCampaignField.CampaignName}
          label={`${messages.campaign.campaignName}*`}
        />
        <SmallText color="#FF0000">{errors?.name}</SmallText>
      </Div>
      <Div width="100%">
        <Textarea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.description}
          id={CreateCampaignField.Description}
          name={CreateCampaignField.Description}
          label={messages.campaign.campaignDescription}
          resize="none"
        />
        <SmallText color="#FF0000">{errors?.description}</SmallText>
      </Div>
    </Flex>
  )
}

export default CampaignCreateModal
