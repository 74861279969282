import React, { useCallback, useState } from "react"
import { motion } from "framer-motion"
import {
  LargeSummaryWrapper,
  SmallSummaryWrapper,
  SummaryExpandButton
} from "./style"
import { getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { DropdownArrowIcon } from "@ikiru/talentis-fpc"
import { OrderSummary, OrderSummaryProps } from "../OrderSummary"

export const OrderSummarywrapper = (props: OrderSummaryProps) => {
  const mediaQuery = getMinWidthMediaQuery("sm")
  const isLarge = useMediaQuery(mediaQuery)

  // summaryExpanded is only used on small displays
  const [summaryExpanded, setSummaryExpended] = useState<boolean>(false)

  const toggleSummary = useCallback(() => {
    setSummaryExpended(!summaryExpanded)
  }, [setSummaryExpended, summaryExpanded])

  const orderSummary = <OrderSummary {...props} />

  return (
    <>
      {isLarge && <LargeSummaryWrapper>{orderSummary}</LargeSummaryWrapper>}

      {!isLarge && (
        <SmallSummaryWrapper>
          <SummaryExpandButton
            size="action-small"
            mode="standard-grey-light"
            onClick={toggleSummary}
          >
            <motion.div
              animate={{
                transform: summaryExpanded ? "" : "rotate(180deg)"
              }}
            >
              <DropdownArrowIcon />
            </motion.div>
          </SummaryExpandButton>
          <motion.div
            style={{ overflow: "hidden" }}
            animate={{ height: summaryExpanded ? "auto" : "65px" }}
          >
            {orderSummary}
          </motion.div>
        </SmallSummaryWrapper>
      )}
    </>
  )
}
