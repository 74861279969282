import { Tag } from "components/Tags/tags.types"
import {
  /*Membership,*/
  ProjectTeamMember
} from "components/ProjectTeamMembers/types"

export enum CampaignStatus {
  Active = "active",
  Closed = "closed"
}

export type Campaign = {
  id: string
  name: string
  description: string
  startDate: string | Date
  status: CampaignStatus
  tags: Tag[]
  teamMembers: ProjectTeamMember[]
}

/*
export type ProjectTeamUpdate = {
  userId: string
  membership: Omit<Membership, "knownTo"> | null
}
*/
