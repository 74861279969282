import React, { useMemo } from "react"
import { useDrop } from "react-dnd"
import { Div, Flex, H5 } from "@ikiru/talentis-fpc"
import {
  ColumnContainer,
  ColumnTitle,
  ColumnTitleContainer,
  ExplainMessage,
  ExplainMessageContainer,
  TitleWrapper
} from "./style"
import { ColumnType, ExplainMessageProps } from "../constants/types"

type ColumnProps = {
  columnDetails: ColumnType
  children: React.ReactNode
  ActionComponent?: () => JSX.Element
  explainMessage?: ExplainMessageProps
  canDropLogic?: (currentColumnId: string) => boolean
}

const Column = ({
  children,
  canDropLogic,
  columnDetails,
  ActionComponent,
  explainMessage
}: ColumnProps) => {
  const {
    columnColor,
    title: columnName,
    borderStyle,
    titleColor,
    id,
    sideTitle,
    titleLeftPadding = "10px",
    minHeight = "53vh",
    maxHeight = "63vh"
  } = columnDetails
  const [{ isOver }, drop] = useDrop({
    accept: columnDetails.acceptType,
    drop: (item) => {
      return { name: item.currentColumnId, newColumnName: id }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),

    canDrop: (item: any) => {
      const { currentColumnId } = item
      return canDropLogic ? canDropLogic(currentColumnId) : true
    }
  })

  const showExplainBox = useMemo(
    () => !explainMessage?.excludeList.includes(id),
    [explainMessage?.excludeList, id]
  )

  return (
    <Flex width="100%" height="100%" ref={drop}>
      {sideTitle && (
        <TitleWrapper>
          <H5 m="none" p="xxs">
            {sideTitle}
          </H5>
        </TitleWrapper>
      )}
      <Div width="100%">
        <ColumnTitleContainer bg={columnColor} pl={titleLeftPadding}>
          <ColumnTitle color={titleColor}>{columnName}</ColumnTitle>
          {ActionComponent && <ActionComponent />}
        </ColumnTitleContainer>
        <ColumnContainer
          minHeight={minHeight}
          maxHeight={maxHeight}
          borderStyle={borderStyle}
          columnColor={columnColor}
        >
          {explainMessage?.explainMessage && showExplainBox && (
            <ExplainMessageContainer>
              <ExplainMessage
                isHoveringOver={isOver}
                hoverBg={explainMessage.hoverColor}
              >
                {explainMessage.explainMessage}
              </ExplainMessage>
            </ExplainMessageContainer>
          )}
          <Div textAlign="left">{children}</Div>
        </ColumnContainer>
      </Div>
    </Flex>
  )
}

export default Column
