import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import {
  CurrentPastEitherLogic,
  DistanceUnit,
  LogicalOperator
} from "views/search/SearchModule/types"

export enum SearchCriteriaField {
  Industries = "industries",
  JobTitle = "searchJobTitleBundle.{0}.searchJobTitle",
  JobTitleBundle = "searchJobTitleBundle",
  Keyword = "searchKeywordBundle.{0}.searchKeyword",
  ExcludeKeyword = "excludeKeyword",
  KeywordBundle = "searchKeywordBundle",
  Location = "searchLocation",
  LocationDistance = "locationDistance",
  LocationDistanceUnit = "locationDistanceUnit",
  CurrentJobExperienceYearsMin = "currentJobExperienceYearsMin",
  CurrentJobExperienceYearsMax = "currentJobExperienceYearsMax",
  TotalJobExperienceYearsMin = "totalJobExperienceYearsMin",
  TotalJobExperienceYearsMax = "totalJobExperienceYearsMax",
  Country = "searchCountry",
  BoardLevelExperience = "boardLevelExperience",
  BoostFemale = "boostFemale",
  LikelyToMove = "likelyToMove",
  KeywordsSearchUsingORLogic = "searchKeywordBundle.{0}.keywordsSearchUsingORLogic",
  KeywordsSearchLogic = "searchKeywordBundle.{0}.keywordsSearchLogic",
  KeywordsSearchRecordPerson = "searchKeywordBundle.{0}.keywordsSearchRecordPerson",
  KeywordsSearchRecordCompany = "searchKeywordBundle.{0}.keywordsSearchRecordCompany",
  KeywordsBundleSearchUsingORLogic = "KeywordsBundleSearchUsingORLogic",
  JobTitleLogic = "searchJobTitleBundle.{0}.searchJobTitleLogic",
  IndustriesLogic = "searchIndustriesLogic",
  Company = "searchCompany",
  CompanyLogic = "searchCompanyLogic",
  CompanySizeLogic = "companySizeLogic",
  JobTitlesBundleSearchUsingANDLogic = "JobTitlesBundleSearchUsingANDLogic",
  SearchCriteriaField = "SearchCriteriaField"
}

export const searchCriteriaValidationSchema = Yup.object({
  [SearchCriteriaField.JobTitleBundle]: Yup.array(
    Yup.object({
      searchJobTitle: Yup.string().max(
        50,
        messages.talentGraphSearch.errors.jobTitleLength
      )
    })
  ),
  [SearchCriteriaField.KeywordBundle]: Yup.array(
    Yup.object({
      searchKeyword: Yup.string().max(
        50,
        messages.talentGraphSearch.errors.keywordLength
      )
    })
  )
})

export const initialValues = {
  [SearchCriteriaField.Industries]: [],
  [SearchCriteriaField.JobTitle]: "",
  [SearchCriteriaField.Keyword]: "",
  [SearchCriteriaField.ExcludeKeyword]: "",
  [SearchCriteriaField.Location]: "",
  [SearchCriteriaField.Country]: "",
  [SearchCriteriaField.BoardLevelExperience]: false,
  [SearchCriteriaField.LikelyToMove]: false,
  [SearchCriteriaField.KeywordsSearchUsingORLogic]: LogicalOperator.OR,
  [SearchCriteriaField.KeywordsSearchLogic]: CurrentPastEitherLogic.Either,
  [SearchCriteriaField.KeywordsSearchRecordPerson]: true,
  [SearchCriteriaField.KeywordsSearchRecordCompany]: true,
  [SearchCriteriaField.LocationDistance]: 0,
  [SearchCriteriaField.LocationDistanceUnit]: DistanceUnit.KM,
  [SearchCriteriaField.Company]: "",
  [SearchCriteriaField.JobTitleLogic]: CurrentPastEitherLogic.Either,
  [SearchCriteriaField.IndustriesLogic]: CurrentPastEitherLogic.Either,
  [SearchCriteriaField.CompanyLogic]: CurrentPastEitherLogic.Either,
  [SearchCriteriaField.CompanySizeLogic]: CurrentPastEitherLogic.Either,
  [SearchCriteriaField.KeywordBundle]: [],
  [SearchCriteriaField.JobTitleBundle]: [],
  [SearchCriteriaField.KeywordsBundleSearchUsingORLogic]: LogicalOperator.AND,
  [SearchCriteriaField.JobTitlesBundleSearchUsingANDLogic]: LogicalOperator.AND
}
