import React from "react"
import { SearchDropdown, SearchDropdownItem } from "./style"
import { Location } from "views/assignments/assignment.types"
import { Div } from "@ikiru/talentis-fpc"

type ListLocationSearchProps = {
  locations: Location[]
  onItemClicked: (location: Location) => void
  editStyle: boolean
}

export const ListLocationSearch = ({
  locations,
  onItemClicked,
  editStyle
}: ListLocationSearchProps) => {
  const onClick = (location: Location) => {
    onItemClicked(location)
  }
  return (
    <Div
      {...(editStyle ? { position: "relative" } : { position: "absolute" })}
      zIndex={100}
    >
      <SearchDropdown>
        {locations.map((location: any) => (
          <SearchDropdownItem
            key={JSON.stringify(location)}
            onClick={() => onClick(location)}
          >
            {location?.label}
          </SearchDropdownItem>
        ))}
      </SearchDropdown>
    </Div>
  )
}
