import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import CampaignNotesEditMode from "./components/EditMode"
import NotesList from "./components/NotesList"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Button, Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

type CampaignNotesProps = {
  isLoading: boolean
}

const CampaignNotes = ({ isLoading }: CampaignNotesProps) => {
  const { campaignId, notes, setNotes } = useCampaign()

  const { open, close } = useModal()

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <CampaignNotesEditMode {...{ campaignId, notes, setNotes, close }} />
    )
  }, [campaignId, close, notes, open, setNotes])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <Button mode="standard-green" size="small" onClick={noteOpenModal}>
            {messages.person.note.plusNote}
          </Button>
        )
      }}
    >
      <Div width="100%" py="s">
        <NotesList isLoading={isLoading} />
      </Div>
    </Section>
  )
}
export default React.memo(CampaignNotes)
