import React, { useMemo } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { Flex } from "@ikiru/talentis-fpc"
import { Candidate } from "views/candidates/candidates.types"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { AutoSave } from "components/functional/formik/formik-autosave/AutoSave"
import { useCandidate } from "views/assignments/components/candidates-list/components/candidate-record/CandidateContext"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import {
  e2eTargets,
  InterviewProgressField,
  interviewProgressInitialTouched,
  interviewProgressInitialValues,
  InterviewProgressValues,
  candidateStatuses,
  candidateStages
} from "./definitions"
import { useTeam } from "views/team/team/team-module.context"
import { FormikCheckedDatapicker } from "components/functional/formik/formik-checked-datapicker/FormikCheckedDatepicker"
import { messages } from "setup/messages/messages"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { getChangedValues } from "views/assignments/utils"
import { isEmpty } from "lodash"

export const InterviewProgress = () => {
  const { candidate, onChangeInterviewProgress } = useCandidate()

  const { teamMembers } = useTeam()
  const {
    updateCandidateStage,
    assignmentId,
    selectedTab,
    updateCompanyCandidate,
    updateCompaniesStage
  } = useAssignment()
  const {
    trackCandidateAssignToSet,
    trackCandidateDueDateSet,
    trackCandidateCompletedOrRemoveSet
  } = useTelemetry()

  const membersOptions = useMemo(
    () =>
      teamMembers.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.id
      })),
    [teamMembers]
  )

  const initialValues = useMemo(
    () => ({
      ...interviewProgressInitialValues,
      ...(candidate.interviewProgressState as InterviewProgressValues),
      assignTo: candidate.assignTo,
      dueDate: candidate.dueDate
    }),
    [candidate.interviewProgressState, candidate.assignTo, candidate.dueDate]
  )

  const onSubmit = onChangeInterviewProgress(
    candidate.id,
    (candidateNew: Candidate) => {
      if (selectedTab === "companyCandidate") {
        updateCompanyCandidate(candidateNew)
        updateCompaniesStage(
          candidate.interviewProgressState?.stage || "",
          candidateNew
        )
      }
      updateCandidateStage(candidate.id, candidateNew)
    }
  )

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const formattedInitialValues = {
      assignTo: initialValues.assignTo,
      dueDate: initialValues.dueDate
    }

    const formattedValues = {
      assignTo: values.assignTo,
      dueDate: values.dueDate
    }

    const interviewProgressValues = {
      stage: values?.stage,
      status: values?.status
    }

    const interviewProgressInitialValues = {
      stage: initialValues?.stage,
      status: initialValues?.status
    }
    const changeValues = getChangedValues(
      formattedValues,
      formattedInitialValues
    )
    const changedInterviewProgress = getChangedValues(
      interviewProgressValues,
      interviewProgressInitialValues
    )

    const newValues: any = {
      ...changeValues,
      interviewProgressState: changedInterviewProgress
    }
    if (isEmpty(changedInterviewProgress)) {
      delete newValues.interviewProgressState
    }

    onSubmit(newValues, actions)
  }

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={interviewProgressInitialTouched}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {() => {
        return (
          <AutoSave debounceMs={0}>
            <Form>
              <Flex
                mb={["xs"]}
                alignItems="start"
                justifyContent="flex-start"
                flexDirection="row"
              >
                <Flex flexDirection="column" mr="s">
                  <FormikSelect
                    layout={{
                      mb: ["xs"],
                      mr: "xs",
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    options={candidateStages}
                    id={InterviewProgressField.Stage}
                    name={InterviewProgressField.Stage}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.Stage]}`}
                  />
                  <FormikSelect
                    layout={{
                      mt: ["none", "xxs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    options={candidateStatuses}
                    id={InterviewProgressField.Status}
                    name={InterviewProgressField.Status}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    width="80%"
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.Status]}`}
                  />
                </Flex>
                <Flex flexDirection="column">
                  <FormikCheckedDatapicker
                    variant="small"
                    layout={{
                      mt: ["none"],
                      mb: ["xs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 3
                    }}
                    onChange={() => {
                      trackCandidateDueDateSet({ assignmentId })
                    }}
                    onComplete={() => {
                      trackCandidateCompletedOrRemoveSet({ assignmentId })
                    }}
                    onRemove={() => {
                      trackCandidateCompletedOrRemoveSet({ assignmentId })
                    }}
                    id={InterviewProgressField.DueDate}
                    name={InterviewProgressField.DueDate}
                    label={messages.person.assignments.dueDate}
                  />
                  <FormikSelect
                    layout={{
                      mt: ["none", "xxs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    firstOption={{
                      label: messages.form.generic.assignTo,
                      value: ""
                    }}
                    options={membersOptions}
                    onChange={() => {
                      trackCandidateAssignToSet({ assignmentId })
                    }}
                    id={InterviewProgressField.AssignTo}
                    name={InterviewProgressField.AssignTo}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    width="80%"
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.AssignTo]}`}
                  />
                </Flex>
              </Flex>
            </Form>
          </AutoSave>
        )
      }}
    </Formik>
  )
}
