import { useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterUrl } from "setup/router/routes"
import { useModal } from "utils/hooks/use-modal"
import useAddPersonToProject from "./components/AddPeople/hooks/useAddPersonToProject"

export const useNewContactModal = () => {
  const { close, onClose } = useModal()
  const {
    closeAndRedirectToAssignment,
    closeAndRedirectToCampaign,
    addCampaignToPerson,
    addAssignmentToPerson
  } = useAddPersonToProject()
  const navigate = useNavigate()

  const closeModal = useCallback(() => {
    close(ModalNames.NewContact)
  }, [close])

  const closeAndRedirect = useCallback(
    (id?: string) => {
      if (id) {
        onClose(ModalNames.NewContact, () => {
          navigate(`${RouterUrl.ProfileDetails}/${id}`)
        })
      }

      closeModal()
    },
    [navigate, onClose, closeModal]
  )

  const createContact = useCallback(
    async (name: string, assignmentId: string, campaignId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: PersonsEndpoints.Root,
        data: { name }
      })
      if (assignmentId) {
        addAssignmentToPerson(assignmentId, response?.data?.id)
        closeAndRedirectToAssignment(assignmentId)
      } else if (campaignId) {
        addCampaignToPerson(campaignId, response?.data?.id)
        closeAndRedirectToCampaign(campaignId)
      } else {
        closeAndRedirect(response?.data?.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeAndRedirect]
  )

  return {
    close: closeModal,
    createContact,
    closeAndRedirect
  }
}

export const useSearchQuery = () => new URLSearchParams(useLocation().search)
