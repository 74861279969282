import React, { useCallback } from "react"
import {
  BodyText,
  colors,
  Div,
  H4,
  Flex,
  SectionHeader,
  Switch
} from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { Pagination } from "components/functional/pagination/Pagination"
import { CompanyPersonRecord } from "./CompanyPersonRecord"
import {
  CompanyKnowEmployeesTypes,
  EmployeeType
} from "views/companies/hooks/useCompanyKnowEmployees"
import { messages } from "setup/messages/messages"
import { SwitchLabel } from "./style"
import { FormerCompanyPersonRecord } from "./FormerCompanyPersonRecord"

export const KnowEmployees = (props: CompanyKnowEmployeesTypes) => {
  const {
    knowEmployees,
    paginationKnowEmployeesList,
    isLoading,
    totalPages,
    handlePageChange,
    setMode,
    mode
  } = props
  const isFormer = mode === EmployeeType.Former
  const onKnownChange = useCallback(
    () => setMode(isFormer ? EmployeeType.Current : EmployeeType.Former),
    [setMode, isFormer]
  )
  return (
    <>
      <SectionHeader
        size="xxSmall"
        title={
          <H4 m="0" fontSize="18px" color={colors.grey.dark}>
            {isFormer
              ? messages.assignment.formerEmployees
              : messages.assignment.currentEmployees}
          </H4>
        }
        actions={
          <>
            <Flex>
              <SwitchLabel
                {...(isFormer
                  ? { color: "grey.dark" }
                  : { color: "grey.darkest" })}
              >
                {messages.assignment.currentEmployees}
              </SwitchLabel>
              <Switch
                label=""
                checked={isFormer}
                onChange={onKnownChange}
                colorVariant="standard"
              />
              <SwitchLabel
                ml="xxs"
                {...(isFormer
                  ? { color: "grey.darkest" }
                  : { color: "grey.dark" })}
              >
                {messages.assignment.formerEmployees}
              </SwitchLabel>
            </Flex>
          </>
        }
      />
      <Flex flexDirection="column" width="100%">
        {isLoading ? (
          <Div mt="100px">
            <Loader />
          </Div>
        ) : Boolean(knowEmployees?.length) ? (
          <Flex width="100%" flexWrap="wrap">
            <Div px="s" pt="s" bg={colors.grey.lightest} width="100%">
              {isFormer
                ? knowEmployees.map((item) => (
                    <FormerCompanyPersonRecord key={item.personId} {...item} />
                  ))
                : knowEmployees.map((item) => (
                    <CompanyPersonRecord key={item.personId} {...item} />
                  ))}
              {totalPages > 1 && (
                <Flex width="100%" justifyContent="center" mb={20}>
                  <Pagination
                    {...paginationKnowEmployeesList}
                    onPageChange={handlePageChange}
                  />
                </Flex>
              )}
            </Div>
          </Flex>
        ) : (
          <Div bg="grey.lightest" p="m">
            <BodyText my="xs" color="grey.standard">
              {messages.companies.companyInfo.noKnownEmployees}
            </BodyText>
          </Div>
        )}
      </Flex>
    </>
  )
}
