import { Div, spacing } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import styledCss from "@styled-system/css"
import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"
import { Form } from "formik"

type ModalContainerProps = {
  defaultWidth?: number
}

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
`

export const NoteListContainer = styled(Div)`
  h4 {
    font-size: 18px;
  }
  p {
    width: 100%;
  }
  svg {
    width: 12px;
    height: 12px;
  }
`
export const ModalFormContainer = styled.div<ModalContainerProps>`
  ${styledCss(tempInnerGreyModalStyles)}
  width: 800px;
  background: ${({ theme }) => theme.colors.grey.lightest};
  padding: 0px;
`
export const StyledForm = styled(Form)`
  padding: ${spacing.m}px;
`
