import styled from "styled-components"
import { Flex, Div, breakpoints, theme } from "@ikiru/talentis-fpc"

export const SearchFlex = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey.light};
  width: 100%;
  padding: ${theme.space.xs}px;
  flex-direction: column;
  ${theme.mediaQueries.md} {
    @media screen and (max-width: ${breakpoints.xl}) {
      flex-direction: column;
    }
    @media screen and (min-width: ${breakpoints.xl}) {
      flex-direction: row;
    }
  }
`

export const CompanyComingSoonWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  max-width: 230px;
  background-color: ${theme.colors.grey.light};
  height: fit-content;
  width: 100%;
  margin-left: ${theme.space.l}px;
  padding-bottom: ${theme.space.l}px;
  border-radius: 10px;
  margin-bottom: 30px;
`

export const StyledImgWrapper = styled(Div)`
  cursor: pointer;
  margin-bottom: ${theme.space.xs}px;
`
