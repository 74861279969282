import React, { useEffect, useState } from "react"
import { PersonNoteModalWrapper } from "./style"
import {
  Button,
  CrossIcon,
  Div,
  Flex,
  H4,
  Loader,
  Textarea
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { onDocumentClick } from "utils/helpers"
import { messages } from "setup/messages/messages"

type AttachmentModelProps = {
  noteId: string
  documentId: string
}

const AttachmentModel = ({ noteId, documentId }: AttachmentModelProps) => {
  const [emailData, setEmailData] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    const fetchEmailData = async () => {
      const data = await onDocumentClick(noteId, documentId)

      setEmailData(data)
      setIsLoading(false)
    }
    fetchEmailData()
  }, [noteId, documentId])

  const { close } = useModal()

  return (
    <PersonNoteModalWrapper>
      <Div px="m" pb="m">
        <Flex justifyContent="space-between" alignItems="flex-start" pt="m">
          <H4 m="none" textAlign="left" color="grey.dark" flex={1}>
            {messages.clientPortal.notes.emailAttactment}
          </H4>
          <Button
            onClick={() => close(ModalNames.EmailAttachments)}
            mode="standard-white"
            size="action-medium"
          >
            <CrossIcon fill="grey.dark" />
          </Button>
        </Flex>
        {!isLoading ? (
          <Div mt="xs">
            <Textarea
              value={emailData}
              resize="none"
              style={{ height: "300px" }}
            />
          </Div>
        ) : (
          <Flex m="xxl">
            <Loader />
          </Flex>
        )}
      </Div>
    </PersonNoteModalWrapper>
  )
}

export default AttachmentModel
