import { apiRequest } from "setup/api/api"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { GetFormAction, FormAction } from "utils/forms/form.types"
import {
  PersonCampaignsValues,
  PersonCampaigns
} from "./constants/add-person-campaign.definitions"
import { serializeAllErrorMessages } from "utils/forms/forms"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnAddPersonCampaign = FormAction<PersonCampaignsValues>
type GetOnAddPersonCampaign = GetFormAction<PersonCampaignsValues>

export const getOnAddPersonCampaign: GetOnAddPersonCampaign =
  (personId, onSuccess, beforeSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers
    const campaignId = values[PersonCampaigns.Campaigns]
    personId = await beforeSubmit(personId)
    const [error, response] = await apiRequest.post({
      endpoint: ContactsEndpoints.Root,
      data: { campaignId, personId },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    response && onSuccess!(response.data)
    error &&
      setFieldError(PersonCampaigns.Campaigns, serializeAllErrorMessages(error))
  }
