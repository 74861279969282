import React, { useCallback } from "react"
import { getOnAddPersonBiographySubmit } from "../actions"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Candidate } from "views/candidates/candidates.types"
import { useModal } from "utils/hooks/use-modal"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { BioModal } from "../BioModal"
import { usePerson } from "views/persons/person-module/candiate-module.context"

const usePersonRecordBiography = (candidate: Candidate) => {
  const { updateCandidate, selectedTab, updateCompanyCandidate } =
    useAssignment()
  const { setCandidate } = usePerson()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreateBiographySubmit = useCallback(
    getOnAddPersonBiographySubmit(candidate, (candidateLoc) => {
      if (selectedTab === "companyCandidate") {
        updateCompanyCandidate(candidateLoc)
      }
      updateCandidate(candidateLoc)

      setCandidate({ ...candidateLoc, id: candidate.id })
    }),
    [updateCandidate]
  )

  const { open } = useModal()

  const openBiographyModal = useCallback(
    (name: string) =>
      (note?: {
        commentaryDescription: string
        commentaryShowToClient: boolean
      }) => {
        const title = `${
          candidate.bio?.data
            ? messages.person.bio.editBio
            : messages.person.bio.addBio
        } ${Boolean(name) ? `${messages.clientPortal.notes.for} ${name}` : ""}`
        open(
          ModalNames.PersonRecordBiographyModal,
          <BioModal
            title={title}
            onSubmit={onCreateBiographySubmit}
            initialValues={
              candidate.bio?.data
                ? {
                    biographyDescription: candidate.bio.data,
                    setAsMainBiography: false
                  }
                : undefined
            }
            typeTitle=""
            candidate={candidate}
          />
        )
      },
    [open, onCreateBiographySubmit, candidate]
  )

  return {
    onCreateBiographySubmit,
    openBiographyModal
  }
}

export { usePersonRecordBiography }
