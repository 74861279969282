import * as Yup from "yup"

export enum NewContactFormFields {
  PersonName = "person-name"
}

export type NewContactForm = {
  [NewContactFormFields.PersonName]: string
}

export const initialValues: NewContactForm = {
  [NewContactFormFields.PersonName]: ""
}

export const newContactFormValidationSchema = Yup.object({
  [NewContactFormFields.PersonName]: Yup.string().trim().required()
})

export const e2eTargets = {
  personNameInput: "newContactModalPersonNameInput",
  modalContainer: "newContactModalContainer",
  modalCloseButton: "newContactModalCloseButton",
  submitButton: "newContactModalSubmitButton"
}
