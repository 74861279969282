import React, { useState, useRef, useEffect } from "react"
import { Field } from "components/visual/field/Field"
import { Input, InputProps } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useIntl } from "react-intl"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import { Nullable } from "tsdef"
import {
  DatepickerHeader,
  DatepickerCalendarIcon as CalendarIcon,
  DatepickerHeaderProps,
  StyledDatepickerContainer,
  NotifyIcon,
  DatepickerPositiveIcon as PositiveIcon
} from "@ikiru/talentis-fpc"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { getDatePatternMask, getLocaleDatePattern } from "utils/format-date"
import { CheckboxField } from "@ikiru/talentis-fpc"
import { Flex, StyledSystemElement } from "@ikiru/talentis-fpc"
import { getDateInputColor, notifyIcons } from "./utils"
import { motion } from "framer-motion"
import { SmallText } from "@ikiru/talentis-fpc"
import { colors } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type FormikCheckedDatapickerProps = InputProps & {
  label?: React.ReactChild
  name: Pick<InputProps, "name">
  maxDate?: Date
  minDate?: Date
  dateFormat?: string
  layout?: StyledSystemElement<HTMLDivElement>
  onComplete?: VoidFunction
  onRemove?: VoidFunction
  onChange?: VoidFunction
}

type DatapickerRenderHeaderProps = {
  date: Date
} & Pick<
  DatepickerHeaderProps,
  "increaseMonth" | "decreaseMonth" | "changeYear"
>

export const FormikCheckedDatapicker = React.forwardRef(
  (props: FormikCheckedDatapickerProps, ref) => {
    const {
      label,
      name,
      onBlur,
      maxDate,
      minDate,
      dateFormat = getLocaleDatePattern(),
      id,
      layout,
      onComplete: onCompleteMethod,
      onRemove,
      onChange: onChangeMethod,
      ...explicitProps
    } = props
    const intl = useIntl()
    const [formikProps, meta, helpers] = useField(name as string)

    const value = formikProps.value

    const colorVariant = getDateInputColor(moment.utc(new Date(value)))
    const Icon = colorVariant ? notifyIcons[colorVariant] : NotifyIcon

    const [isDone, setIsDone] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [date, setDate] = useState<Nullable<Date>>(null)
    // "any" because this is problem with DataPicker types doesn't include input prop
    const datepickerRef = useRef<any>(ref)
    const status = useStatus(meta)

    const inputWidth = datepickerRef?.current?.input?.inputElement?.offsetWidth

    const onChange = (date: Nullable<Date>) => {
      if (moment(date).isValid() || date === null) {
        setDate(date)
        helpers.setTouched(true)
        helpers.setValue(date)
        onChangeMethod?.()
      }
    }

    useEffect(() => {
      const dateValue = value
        ? moment.utc(new Date(value), dateFormat).toDate()
        : null
      setDate(dateValue)
    }, [value, dateFormat])

    const onComplete = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      setIsDone(true)
      setTimeout(() => {
        onChange(null)
        onCompleteMethod?.()
        setIsDone(false)
      }, 1000)
    }

    const renderCustomHeader = ({
      date,
      decreaseMonth,
      increaseMonth,
      changeYear
    }: DatapickerRenderHeaderProps) => {
      const month = moment.months()[moment(date).month()]
      const year = moment(date).year()

      return (
        <DatepickerHeader
          month={month}
          year={year}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          changeYear={changeYear}
        />
      )
    }

    return (
      <Field style={{ zIndex: isOpen ? 4 : 3 }}>
        <StyledDatepickerContainer {...layout}>
          <DatePicker
            ref={datepickerRef}
            id={id}
            name={name}
            calendarClassName="talentis-datepicker"
            dateFormat={dateFormat}
            showPopperArrow={false}
            selected={date}
            minDate={minDate}
            maxDate={maxDate}
            locale={intl.locale}
            onChange={onChange}
            popperPlacement="bottom-end"
            popperClassName="talentis-datepicker-popper"
            renderCustomHeader={renderCustomHeader}
            calendarContainer={({ children }) => (
              <Flex
                flexDirection="column"
                position="relative"
                className="react-datepicker talentis-datepicker"
              >
                <Flex>{children}</Flex>
                {value ? (
                  <Flex bg={colors.grey.light}>
                    <Link
                      m="xxs"
                      size="small"
                      as="button"
                      onClick={() => {
                        onChange(null)
                        onRemove?.()
                        datepickerRef.current?.setOpen(false)
                      }}
                    >
                      {messages.form.generic.removeReminder}
                    </Link>
                  </Flex>
                ) : null}
              </Flex>
            )}
            onCalendarOpen={() => {
              setIsOpen(true)
            }}
            onCalendarClose={() => {
              datepickerRef.current?.input.inputElement.blur()
              setIsOpen(false)
            }}
            customInput={
              isDone ? (
                <motion.div
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Flex
                    bg={colors.white.standard}
                    justifyContent="center"
                    alignItems="center"
                    width={inputWidth || "100%"}
                  >
                    <PositiveIcon />
                    <SmallText fontWeight={600} m={0}>
                      {messages.generic.complete}
                    </SmallText>
                  </Flex>
                </motion.div>
              ) : (
                <Input
                  ref={ref}
                  {...formikProps}
                  {...explicitProps}
                  label={label}
                  data-e2e-target="form-datapicker-input"
                  onBlur={(event) => {
                    formikProps.onBlur(event)
                    onBlur && onBlur(event)
                  }}
                  previx={() => (value ? <Icon /> : null)}
                  sufix={({ value }) => {
                    return value ? (
                      <Flex position="relative" top={-12} right={20}>
                        <CheckboxField
                          style={{ width: 14, height: 14 }}
                          onClick={onComplete}
                          {...{ colorVariant }}
                        />
                      </Flex>
                    ) : (
                      <CalendarIcon />
                    )
                  }}
                  mask={getDatePatternMask(dateFormat)}
                  colorVariant={value ? colorVariant : undefined}
                  isUnderlined
                  {...{ status }}
                />
              )
            }
          />
        </StyledDatepickerContainer>
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Field>
    )
  }
)
