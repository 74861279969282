import React from "react"
import { H3, BodyText, colors } from "@ikiru/talentis-fpc"
import { ComingSoonWrapper } from "./style"
import { messages } from "setup/messages/messages"

const ComingSoonOutlook = () => {
  return (
    <ComingSoonWrapper>
      <H3 color={colors.grey.standard}>
        {messages.project.outReach.comingSoon}
      </H3>
      <BodyText textAlign="center" m="0" color={colors.grey.standard} px="xs">
        {messages.project.outReach.comingSoonMessage}
      </BodyText>
    </ComingSoonWrapper>
  )
}

export default ComingSoonOutlook
