import { PaginationProps } from "components/functional/pagination/Pagination"
import { Assignment } from "views/assignments/assignment.types"
import { Campaign } from "views/campaigns/campaign.types"
import {
  WebSiteType,
  Stage,
  Status,
  Person,
  Experience,
  RecentNoteType
} from "views/persons/person.types"
import { Nullable } from "tsdef"
import { OffLimitsType } from "components/OffLimits/type"
import { ProjectTeamMember } from "components/ProjectTeamMembers/types"

export type AzureLocationAddress = {
  municipality?: string
  countrySubdivision?: string
  countrySubdivisionName?: string
  countrySecondarySubdivision?: string
  countryTertiarySubdivision?: string
  municipalitySubdivision?: string
  country?: string
}

export type AzureLocation = {
  type: string
  id: string
  entityType: string
  address: AzureLocationAddress
}

export enum LogicalOperator {
  AND = "and",
  OR = "or"
}

export enum CompanySize {
  VERYSMALL = "verySmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  VERYLARGE = "veryLarge"
}

export type KeywordBundle = {
  keywords: string[]
  keywordsSearchUsingORLogic: LogicalOperator
  keywordsSearchLogic: CurrentPastEitherLogic
  keywordsSearchRecordPerson: boolean
  keywordsSearchRecordCompany: boolean
}

export type JobTitleBundle = {
  jobTitles: string[]
  jobTitleLogic: CurrentPastEitherLogic
}

export enum DistanceUnit {
  Miles = "Miles",
  KM = "KM"
}

export enum CurrentPastEitherLogic {
  Current = "current",
  Past = "past",
  Either = "either"
}

export enum FilterType {
  Industries = "industries",
  JobTitleBundle = "jobTitleBundle",
  JobTitleBundleLogic = "jobTitlesBundleSearchLogic",
  Locations = "azureLocations",
  Distance = "searchDistance",
  DistanceUnit = "distanceUnit",
  Countries = "countries",
  BoardLevelExperience = "hasExecutiveExperience",
  BoostFemale = "boostFemale",
  LikelyToMove = "isLikelyToMove",
  IndustriesLogic = "industriesSearchLogic",
  Companies = "companyNames",
  CurrentJobExperienceYearsMax = "currentJobExperienceYearsMax",
  CurrentJobExperienceYearsMin = "currentJobExperienceYearsMin",
  TotalJobExperienceYearsMax = "totalJobExperienceYearsMax",
  TotalJobExperienceYearsMin = "totalJobExperienceYearsMin",
  CompanyLogic = "companyNamesSearchLogic",
  CompanySize = "companySizes",
  CompanySizeLogic = "companySizeSearchLogic",
  KeywordBundle = "keywordBundle",
  ExcludeKeywordSearch = "excludeKeywordSearch",
  KeywordsSearchRecordCompany = "keywordsSearchRecordCompany",
  KeywordsSearchRecordPerson = "keywordsSearchRecordPerson",
  KeywordsSearchLogic = "keywordsSearchLogic",
  KeywordsBundleSearchUsingORLogic = "KeywordsBundleSearchUsingORLogic",
  KeywordsBundleSearchLogic = "keywordsBundleSearchLogic",
  KeywordsBundleSearchRecordPerson = "keywordsBundleSearchRecordPerson",
  KeywordsBundleSearchRecordCompany = "keywordsBundleSearchRecordCompany",
  JobTitlesBundleSearchUsingANDLogic = "JobTitlesBundleSearchUsingANDLogic"
}

export type Filters = {
  [FilterType.Industries]?: string[]
  [FilterType.JobTitleBundle]?: JobTitleBundle[]
  [FilterType.Locations]?: AzureLocation[]
  [FilterType.Distance]?: number
  [FilterType.CurrentJobExperienceYearsMin]?: string
  [FilterType.CurrentJobExperienceYearsMax]?: string
  [FilterType.TotalJobExperienceYearsMin]?: string
  [FilterType.TotalJobExperienceYearsMax]?: string
  [FilterType.DistanceUnit]?: DistanceUnit
  [FilterType.Countries]?: string[]
  [FilterType.BoardLevelExperience]?: boolean
  [FilterType.BoostFemale]?: boolean
  [FilterType.LikelyToMove]?: boolean
  [FilterType.Companies]?: string[]
  [FilterType.JobTitleBundleLogic]?: CurrentPastEitherLogic
  [FilterType.IndustriesLogic]?: CurrentPastEitherLogic
  [FilterType.CompanyLogic]?: CurrentPastEitherLogic
  [FilterType.CompanySizeLogic]?: CurrentPastEitherLogic
  [FilterType.KeywordBundle]?: KeywordBundle[]
  [FilterType.ExcludeKeywordSearch]?: Omit<
    KeywordBundle,
    "keywordsSearchUsingORLogic"
  >
  [FilterType.KeywordsBundleSearchUsingORLogic]?: LogicalOperator
  [FilterType.KeywordsBundleSearchLogic]?: CurrentPastEitherLogic
  [FilterType.KeywordsBundleSearchRecordPerson]?: boolean
  [FilterType.KeywordsBundleSearchRecordCompany]?: boolean
  [FilterType.JobTitlesBundleSearchUsingANDLogic]?: LogicalOperator
}

export type Job = {
  position?: string
  companyName?: string
  startDate?: string | Date
  endDate?: string | Date
  industries?: string[]
}

export type WebSite = {
  websiteType: WebSiteType
  url: string
}

export type SearchPerson = {
  personId?: string
  dataPoolId: string
  companyName?: string
  jobTitle?: string
  location?: string
  linkedInProfileUrl?: string
  experience: Experience[]
  name?: string
  photo?: {
    url?: string
  }
  isOfflimits: boolean
  offlimits: OffLimitsType
  webSites?: WebSite[]
  teamMembers: ProjectTeamMember[]
  recentNote: RecentNoteType
  isGtPerson: boolean
}

export type SearchPersonWithAssignment = {
  person: Person
  currentJob: Job
  previousJobs: Job[]
  assignmentIds: string[]
  campaignIds: string[]
  stages: Stage[]
  status: Status[]
  isGtPerson: boolean
}

export type SavedSearch = {
  id: string
  query?: {}
  name: string
  description: string
  createdDate: string
  isDefault: boolean
}

export type SearchResponse = {
  firstItemOnPage?: number
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  isFirstPage?: boolean
  isLastPage?: boolean
  lastItemOnPage?: number
  pageCount?: number
  pageSize?: number
  totalItemCount?: number
  personsWithAssignmentIds: SearchPersonWithAssignment[]
  personsWithCampaignIds: any[]
}

export type UpdatingPerson = {
  localId?: string
  assignment: Assignment
  updated?: boolean
  error?: boolean
  hidden?: boolean
}

export type AddArrayBasedFilter = {
  filterName: FilterType | string
  value: string | string[]
  limit?: number
  callback?: () => void
}

export type SearchState = {
  areFiltersExpanded?: boolean
  pageSize: number
  pageNumber: number
  isLoading: boolean
  isCriteriaNotDefined: boolean
  filters: Filters
  totalItems: number
  totalPages: number
  persons?: SearchPersonWithAssignment[]
  activeAssignments?: Assignment[]
  selectedAssignment: string
  selectedSearchDataPoolId: string
  updatingPersons: Record<string, UpdatingPerson>
  hasStoredFilters: boolean
  savedSearches?: SavedSearch[]
  isLoadingSeaches?: boolean
  isFinishedLoadingSeaches?: boolean
  selectedSearchPersonId: string
  selectedCampaign: string
  activeCampaigns: Campaign[]
  isAssignmentSelect: boolean | null
  isAnonymized: boolean
  hasResetFilters: boolean
  hasSearched: boolean
}

export enum SearchActionTypes {
  SetIsLoading = "setIsLoading",
  SetIsCriteriaNotDefined = "setIsCriteriaNotDefined",
  UpdateFilters = "updateFilters",
  ClearCertainFilters = "clearCertainFilters",
  SetPersons = "setPersons",
  SetRecordsCount = "setRecordsCount",
  SetPage = "setPage",
  SetActiveAssignments = "setActiveAssignments",
  SetSelectedAssignment = "setSelectedAssignment",
  SetPersonUpdating = "setPersonUpdating",
  RemoveUpdatingPerson = "removeUpdatingPerson",
  UpdatePerson = "updatePerson",
  SetFiltersExpanded = "setFiltersExpanded",
  ResetFilters = "resetFilters",
  SetHasStoredFilters = "setHasStoredFilters",
  SavedSearch = "savedSearch",
  SetIsLoadingSeaches = "setIsLoadingSeaches",
  SetIsFinishedLoadingSeaches = "setIsFinishedLoadingSeaches",
  SetSelectedSearchPersonId = "setSelectedSearchPersonId",
  SetSelectedSearchDataPoolId = "setSelectedSearchDataPoolId",
  UpdatePersonsStage = "updatePersonsStage",
  UpdatePersonsAssignment = "updatePersonsAssignment",
  SetActiveCampaigns = "setActiveCampaigns",
  SetSelectedCampaign = "setSelectedCampaign",
  SetIsAssignmentSelect = "setIsAssignmentSelect",
  SetPageSize = "SetPageSize",
  UpdatePersonsCampaign = "updatePersonsCampaign",
  SetIsAnonymized = "setIsAnonymized",
  SetHasResetFilters = "setHasResetFilters"
}

export type SearchContextValues = SearchState & {
  getPreparedFilters: () => Nullable<Partial<Filters>>
  paginationProps: Omit<PaginationProps, "onPageChange">
  addArrayBasedFilter: (value: AddArrayBasedFilter) => void
  removeArrayBasedFilter: (
    filterName: FilterType | string,
    value: string
  ) => void
  clearArrayBasedFilter: (filterName: FilterType | string) => void
  onPageChange: (page: number) => void
  setSelectedAssignment: (assignmentId: string) => void
  setSelectedCampaign: (campaignId: string) => void
  handleLocalPerson: (localPersonId: string, dataPoolPersonId: string) => void
  handleDataPoolPerson: (dataPoolPersonId: string) => void
  linkageDataPoolPerson: (dataPoolPersonId: string) => Promise<any>
  setFiltersExpanded: (expanded: boolean) => void
  updateFilters: (data: Filters) => void
  clearFilters: (data: Filters) => void
  resetFilters: () => void
  loadPreviousSearch: () => void
  searchTalentGraph: () => void
  loadExamplesSearch: (examplesSearch: any) => void
  handleSaveSearch: (values: any, filters: any) => void
  fetchSearches: (data?: boolean) => void
  removeSavedSearch: (searchId: string) => void
  setDefaultSearch: (searchId: string, isDefault: boolean) => void
  setSelectedSearchPersonId: (personId: string) => void
  setSelectedSearchDataPoolId: (datapool: string) => void
  updatePerson: (data: SearchPersonWithAssignment[]) => void
  updatePersonsStage: (
    personId: string,
    oldStage: string | null | undefined,
    stage: string
  ) => void
  updatePersonsAssignment: (
    personId: string,
    assignmentId?: string,
    dataPoolId?: string
  ) => void
  setIsAssignmentSelect: (data: boolean) => void
  fetchAssignments: () => void
  fetchCampaigns: () => void
  setIsAnonymized: (isAnonymized: boolean) => void
  updatePersonsCampaign: (
    personId: string,
    campaignId?: string,
    dataPoolId?: string
  ) => void
  setHasResetFilters: (data: boolean) => void
}

export type SetIsLoading = (data: boolean) => void
export type SetIsCriteriaNotDefined = (data: boolean) => void
export type UpdateFilters = (data: Record<string, unknown>) => void
export type SetPersons = (data: SearchPersonWithAssignment[]) => void
export type SetRecordsCount = (totalItems: number, totalPages: number) => void
export type SetPage = (data: number) => void
export type SetActiveAssignments = (data: Assignment[]) => void
export type SetSelectedAssignment = (data: string) => void
export type SetPersonUpdating = (data: string) => void
export type RemoveUpdatingPerson = (key: string) => void
export type UpdatePerson = (data: SearchPersonWithAssignment[]) => void
export type SetFiltersExpanded = (data: boolean) => void
export type ResetFilters = () => void
export type SavedSearches = (data: SavedSearch[]) => void
export type SetIsLoadingSeaches = (data: boolean) => void
export type SetSelectedSearchPersonId = (personId: string) => void
export type SetSelectedSearchDataPoolId = (datapoolId: string) => void
export type UpdatePersonsStage = (
  personId: string,
  oldStage: string | null | undefined,
  stage: string
) => void
export type UpdatePersonsAssignment = (
  personId: string,
  assignmentId?: string,
  dataPoolId?: string
) => void
export type SetActiveCampaigns = (data: Campaign[]) => void
export type SetSelectedCampaign = (data: string) => void
export type SetIsAssignmentSelect = (data: boolean) => void
export type SetIsAnonymized = (data: boolean) => void
export type UpdatePersonsCampaign = (
  personId: string,
  campaignId?: string,
  dataPoolId?: string
) => void
export type SetHasResetFilters = (data: boolean) => void
