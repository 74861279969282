import { Div, H5, StyledSystemProps } from "@ikiru/talentis-fpc"
import styled, { css } from "styled-components"

import { height } from "styled-system"

export type OfflimitsProps = {
  isOfflimits?: boolean
}

export const OffLimitsStyled = styled(H5)<{
  isSmall: boolean
  isHorizontal: boolean
}>`
  ${({ isHorizontal }) =>
    !isHorizontal &&
    css`
      transform: rotate(270deg);
      transform-origin: 35px 36px;
    `}

  white-space: nowrap;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors?.white.standard};
  margin: 0px;
  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 12px;
      padding-left: ${({ theme }) => theme.space.s}px;
    `};
`

export const StyledOfflimits = styled(Div)<
  {
    isCircle?: boolean
    isLeftCircle?: boolean
    isRightCircle?: boolean
    isTopRightCircle?: boolean
  } & StyledSystemProps
>`
  width: 20px;
  ${height};
  background-color: ${({ theme }) => theme.colors?.red.standard};

  ${({ isCircle }) =>
    isCircle &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    `}

  ${({ isLeftCircle }) =>
    isLeftCircle &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    `}

    ${({ isRightCircle }) =>
    isRightCircle &&
    css`
      border-top-left-radius: 0px;
      border-bottom-left-radius: 8px;
    `}

   
    ${({ isTopRightCircle }) =>
    isTopRightCircle &&
    css`
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0px;
    `}
`
