import React, { useEffect, useRef, useState } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { PersonOverlayContainer, PersonContent } from "./style"
import { useChangeItemTask } from "./hook/useChangeItemTask"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { OnPageChangeProp } from "../../../assignments/components/candidates-list"
import { useTaskManagement } from "../../../homepage/task-management-module/task-management-module.context"
import { capitalize } from "lodash"
import { Task } from "views/homepage/task-management-module/task-management-module.types"

const TaskPersonOverlay = ({ handlePageChange }: OnPageChangeProp) => {
  const [candidatePosition, setCandidatePosition] = useState<number>(1)
  const {
    isLoading,
    pageNumber,
    pageSize,
    totalFilteredCount,
    selectedTab,
    tasks,
    selectedRecordId,
    setSelectedRecordId,
    isAssignment,
    setTasks,
    setOverlayTaskIndex,
    overlayTaskIndex
  } = useTaskManagement()

  const { directionNext, directionPrevious } = useChangeItemTask(
    { handlePageChange },
    pageNumber,
    pageSize,
    totalFilteredCount,
    tasks,
    setSelectedRecordId,
    setOverlayTaskIndex,
    overlayTaskIndex
  )

  const { setPersonOverlayUpdated, personOverlayUpdated, person, candidates } =
    usePerson()

  const overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = () => {
    setSelectedRecordId("")
  }

  const closeOverlayFromBackground = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      closeOverlay()
    }
  }

  useEffect(() => {
    if (personOverlayUpdated) {
      let newTasks: Task[] = []
      if (isAssignment) {
        newTasks = tasks.map((task) => {
          Object.entries(candidates).forEach(([key, candidate]) => {
            if (candidate.assignmentId === task.assignmentId) {
              task.interviewProgressState = candidate.interviewProgressState
            }
          })
          if (task.personId === selectedRecordId) {
            task.normalizedPersonData = person
          }
          return task
        })
      }

      setTasks(newTasks)
      setPersonOverlayUpdated(false)
    }
  }, [
    candidates,
    isAssignment,
    person,
    personOverlayUpdated,
    selectedRecordId,
    setPersonOverlayUpdated,
    setTasks,
    tasks
  ])

  useEffect(() => {
    if (!isLoading) {
      setCandidatePosition(overlayTaskIndex + 1)
    }
  }, [isLoading, overlayTaskIndex])

  return (
    <PersonOverlayContainer onClick={closeOverlayFromBackground}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={closeOverlay}
            candidateStage={capitalize(selectedTab)}
            disableNav={isLoading}
            totalItems={totalFilteredCount}
            currentCandidateNumber={candidatePosition}
          />
          <PersonOverlay
            personId={selectedRecordId}
            isLoadingCandidates={isLoading}
            project="task"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default TaskPersonOverlay
