import { InterviewProgressStagesOption } from "../candidate-record/components/InterviewProgress/definitions"

const correctStageOrder = [
  "archive",
  "identified",
  "source",
  "screening",
  "internalInterview",
  "shortList",
  "firstClientInterview",
  "secondClientInterview",
  "thirdClientInterview",
  "offer",
  "placed"
]

export const orderAssignmentStages = (
  stageArray: InterviewProgressStagesOption[]
) => {
  const orderedStages: string[] = []

  correctStageOrder.forEach((stageName) => {
    const matchingStage = stageArray.find(
      (stageDefinition) => stageDefinition.value === stageName
    )
    if (matchingStage) {
      orderedStages.push(matchingStage.value)
    }
  })

  return orderedStages
}
