import React, { useCallback } from "react"
import { Field } from "components/visual/field/Field"
import { Label } from "components/visual/label/Label"
import { Error } from "@ikiru/talentis-fpc"
import { Input } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useDropzone } from "react-dropzone"
import {
  FormikInputProps,
  dropzoneE2ETarget
} from "./formik-dropzone.definitions"

export const FormikDropzone = React.forwardRef(
  (props: FormikInputProps, ref) => {
    const {
      label,
      name,
      config = { multiple: false, noDrag: true },
      renderDropzoneComponent,
      "data-e2e-target-name": dataE2ETagName = dropzoneE2ETarget,
      innerRef,
      onFileDialogCancel,
      ...explicitProps
    } = props
    const [formikProps, meta, helpers] = useField(name as string)

    const onDrop = useCallback(
      (acceptedFiles: any) => {
        helpers.setValue(acceptedFiles[0])
      },
      [helpers]
    )

    const dropzone = useDropzone({
      onDrop,
      onFileDialogCancel: onFileDialogCancel,
      ...config
    })

    const { getRootProps, getInputProps, isDragActive } = dropzone

    return (
      <Field>
        <button
          hidden
          ref={innerRef}
          type="button"
          onClick={getRootProps().onClick}
        />
        {label && <Label htmlFor={props.id}>{label}</Label>}
        {/* @ts-ignore */}
        <input type="hidden" {...formikProps} {...explicitProps} />
        <Input
          ref={ref}
          data-e2e-target-name={dataE2ETagName}
          {...getInputProps()}
        />
        {renderDropzoneComponent({
          ...formikProps,
          getRootProps,
          getInputProps,
          onFileDialogCancel,
          isDragActive
        })}
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Field>
    )
  }
)
