import React from "react"
import { H5, SmallText, colors } from "@ikiru/talentis-fpc"

import { Nullable } from "tsdef"
import moment from "moment"

type EducationCardProps = {
  value?: string
  institute?: string
  startDate: Nullable<Date>
  endDate: Nullable<Date>
}

const EducationCard = ({
  value,
  institute,
  startDate,
  endDate
}: EducationCardProps) => {
  return (
    <>
      <H5 m="0">{value}</H5>
      <H5 m="0" fontSize="13px" color={colors.grey.dark}>
        {institute}
      </H5>
      <SmallText m="0" mt="xs" color={colors.grey.dark}>
        {endDate && `${moment(endDate).format("YYYY")}`}
      </SmallText>
    </>
  )
}

export default EducationCard
