import { Button, Flex, Link } from "@ikiru/talentis-fpc"
import { InviteColleaguesModal } from "components/modals/InviteColleaguesModal"
import { useFormikContext } from "formik"
import React, { useCallback, useEffect, useMemo } from "react"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { messages } from "setup/messages/messages"
import { getAssignments } from "views/assignments/pages/list-assignment/actions"
import { ModalNames } from "../../../../setup/modal/modal.definitions"
import { useModal } from "../../../../utils/hooks/use-modal"

const Invitations = () => {
  const { open, skip, close } = useModal()

  const { getTalentisUser, updateInitialLoginFLag } = useTalentisUser()

  const skipInvite = useCallback(async () => {
    try {
      const { assignments } = await getAssignments()
      if (assignments?.length > 0) close(ModalNames.InviteColleagues)
      else skip(ModalNames.InviteColleagues, () => {})
    } catch (error) {
      console.log(error)
    }
  }, [skip, close])

  const openModal = useCallback(async () => {
    // check if there are existing assignments
    const { assignments } = await getAssignments()
    if (assignments.length > 0) return
    // update initial login flag
    await updateInitialLoginFLag()
    open(
      ModalNames.InviteColleagues,
      <InviteColleaguesModal
        formActionsComponent={(formik) => {
          return <FooterComponent skipInvite={skipInvite} />
        }}
      />
    )
  }, [open, skipInvite, updateInitialLoginFLag])

  useEffect(
    () => {
      getTalentisUser()
        .then((data) => {
          if (!data?.passedInitialLoginForSearchFirm) {
            // display invite colleague modal
            openModal()
          }
        })
        .catch((err) => {
          console.log("Error =>", err)
        })
    },
    // eslint-disable-next-line
    [getTalentisUser]
  )

  return <></>
}

export default React.memo(Invitations)

interface FooterProps {
  skipInvite: VoidFunction
}
const FooterComponent = ({ skipInvite }: FooterProps) => {
  const formContext = useFormikContext()
  const disabled = useMemo(() => {
    let disable = true
    try {
      if (formContext.errors) {
        const error: any = formContext.errors
        const values: any = formContext.values
        // check if path is referencing a non-existent field
        const fieldIndex = error?.path?.match(/(\d+)/)[1]
        if (!fieldIndex && error?.errors?.length > 0) disable = true
        else if (!fieldIndex) disable = false
        else
          disable = !values?.invitations[Number(error?.path?.match(/(\d+)/)[1])]
            ? true
            : Object.keys(
                values?.invitations[Number(error?.path?.match(/(\d+)/)[1])] ||
                  {}
              ).length > 0
      } else disable = formContext?.isSubmitting || !formContext.dirty
    } catch (error) {
      console.log(error)
    }
    return disable
  }, [formContext])

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Button
        mode="primary"
        type="submit"
        disabled={!formContext.dirty || disabled}
        mt="s"
        color="white.standard"
      >
        {messages.invitation.sendInvites}
      </Button>
      <Link onClick={skipInvite} mt="s" mb="l">
        {messages.form.generic.skipStep}
      </Link>
    </Flex>
  )
}
