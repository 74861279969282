import { Div, Flex, StyledSystemProps } from "@ikiru/talentis-fpc"
//Delete if not used / or needed
// import { FormValueAddIn } from "@ikiru/talentis-fpc/dist/components/theme/Typography/style"
import { Form } from "formik"
import styled, { css } from "styled-components"

export const StyledDiv = styled(Div)`
  background-color: ${({ theme }) => theme.colors?.grey?.lightest};
  padding: ${({ theme }) => theme.space.l}px;
`

export const PersonTabWrapper = styled(Flex)<
  {
    active?: boolean
  } & StyledSystemProps
>`
  width: 100%;
  height: 29px;
  background-color: ${({ theme }) => theme.colors.green.lightest};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: ${({ theme }) => theme.space.xxs}px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.green.light};
    `}
`

export const StyledForm = styled(Form)`
  height: 450px;
`
