import {
  Div,
  theme,
  colors,
  Flex,
  SmallText,
  MoveArrows
} from "@ikiru/talentis-fpc"
import styled from "styled-components"

type ItemCardContainerProps = {
  isDragging: boolean
  isExistingData: boolean
  isParsed?: boolean
}

type DragContentContainerProp = {
  isOver: boolean
  isDropped: boolean
}

export const ItemCardWrapper = styled(Flex)<ItemCardContainerProps>`
  justify-content: space-between;
  margin-right: ${theme.space.xs}px;
  margin-left: ${theme.space.xs}px;
  margin-top: ${theme.space.xs}px;
  background: ${({ theme, isExistingData }) =>
    isExistingData ? "transparent" : theme.colors.white.standard};
  background-color: ${({ isParsed, theme }) =>
    isParsed ? theme.colors.orange.lightest : ""};
  border: 1px solid ${theme.colors.grey.light};
  ${({ isDragging, theme, isExistingData }) =>
    isDragging &&
    `
    background: ${theme.colors.green.lightest};
    transform: ${isExistingData ? "rotate(-7.021deg)" : "rotate(7.021deg)"};
    flex-shrink: 0;
`};
  cursor: grab;
`
export const ItemCardContainer = styled(Flex)`
  flex-direction: column;
  padding: ${theme.space.xxs}px 5px 4px ${theme.space.xxs}px;
`

export const DraggableItemContainer = styled(Div)`
  width: fit-content;
  & :hover {
    border: 1px dashed ${theme.colors.green.light};
    background-color: ${theme.colors.green.lightest};
  }
`

export const DragContentContainer = styled(Flex)<DragContentContainerProp>`
  align-items: baseline;
  width: fit-content;
  padding-right: ${({ theme }) => theme.space.xxs}px;
  padding-left: ${({ theme }) => theme.space.xxs}px;
  background-color: ${({ isOver, isDropped }) => {
    if (isDropped) {
      return colors.orange.lightest
    }
    return isOver ? theme.colors.green.lightest : ""
  }};
`
export const UndoButton = styled(SmallText)`
  margin-left: ${theme.space.xxs}px;
  color: ${theme.colors.green.standard};
  cursor: pointer;

  & :hover {
    text-decoration: underline;
  }
`

export const DragItemIcon = styled(MoveArrows)`
  width: 18px;
  height: 18px;

  cursor: grab;
  path {
    fill: ${theme.colors.green.light};
  }
`
