import { Flex } from "@ikiru/talentis-fpc"
import React from "react"
import PersonDetailSection from "./components/person-details-section"
import PersonAddress from "./components/person-address"
import PersonWebLink from "./components/person-web-links/PersonWebLink"
import PersonContacts from "./components/person-contact-info/PersonContacts"
import PersonTeam from "./components/person-team"

const PersonDetailsTab = () => {
  return (
    <Flex flexWrap="wrap">
      <Flex
        flexDirection="column"
        width={[1, 1, 1, 1 / 2]}
        pr={[0, 0, 0, "xs"]}
      >
        <PersonDetailSection />
        <PersonAddress />
        <PersonTeam />
      </Flex>
      <Flex
        flexDirection="column"
        width={[1, 1, 1, 1 / 2]}
        pl={[0, 0, 0, "xs"]}
      >
        <PersonContacts />
        <PersonWebLink />
      </Flex>
    </Flex>
  )
}

export default PersonDetailsTab
