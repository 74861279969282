import styled from "styled-components"
import { Flex, colors, BodyText, spacing } from "@ikiru/talentis-fpc"
import { borderValue } from "../parsing/style"

type DateWrapperProps = {
  bottomBorder?: boolean
}

export const DateWrapper = styled(Flex)<DateWrapperProps>`
  border: 0.5px solid ${colors.grey.light};
  border-bottom: ${({ bottomBorder }) =>
    Boolean(bottomBorder) ? `0.5px solid ${colors.grey.light}` : "none"};
`

export const BodyTextStyled = styled(BodyText)<{ isDeleted: boolean }>`
  border-left: ${borderValue};
  margin: 0;
  padding: ${spacing.xxs}px;
  ${({ isDeleted }) => isDeleted && `text-decoration: line-through;`};
`
