import React from "react"

import {
  Flex,
  H4,
  Loader,
  Button,
  colors,
  PositiveIcon,
  ErrorBox,
  H6,
  SmallText
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type ConfirmationSectionProps = {
  selectedPeople: string[]
  totalItemOfFetch: number
  isSaving: boolean
  savePeople: () => Promise<void>
  setSelectedPeople: React.Dispatch<React.SetStateAction<string[]>>
  hasAddedPerson: boolean
  hasAddedPeople: boolean
  beingAddedToAssignment: boolean
  beingAddedToCampaign: boolean
  n360Error: boolean
}

const ConfirmationSection = (props: ConfirmationSectionProps) => {
  const {
    selectedPeople,
    isSaving,
    savePeople,
    setSelectedPeople,
    hasAddedPeople,
    hasAddedPerson,
    beingAddedToAssignment,
    beingAddedToCampaign,
    totalItemOfFetch,
    n360Error
  } = props
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      pb="20px"
      alignItems="center"
      textAlign="center"
    >
      {n360Error && (
        <ErrorBox>
          <H6 m="0">{messages.person.newContact.errorMessageHeader}</H6>
          <SmallText mr="xxs">
            {messages.person.newContact.errorMessage}
          </SmallText>
        </ErrorBox>
      )}
      {!(hasAddedPerson || hasAddedPeople) && (
        <H4 mb="xs" mt="xs">
          {selectedPeople.length === 1
            ? messages.person.newContact.addSelectedPerson
            : messages.person.newContact.addSelectedPeople.format(
                selectedPeople.length,
                totalItemOfFetch
              )}
        </H4>
      )}
      {!(hasAddedPerson || hasAddedPeople) ? (
        isSaving ? (
          <Loader />
        ) : (
          <Flex>
            <Button
              mode="secondary"
              m="xxs"
              width="100px"
              onClick={() => setSelectedPeople([])}
              size="small"
            >
              {messages.generic.cancel}
            </Button>
            <Button
              mode="standard-green"
              m="xxs"
              width="100px"
              onClick={savePeople}
              size="small"
            >
              {messages.generic.yes}
            </Button>
          </Flex>
        )
      ) : (
        <>
          <PositiveIcon width={30} height={30} />
          {hasAddedPerson && (
            <H4 color={colors.green.dark} mt="xs">
              {messages.person.newContact.successMessages.addPerson.format(
                beingAddedToAssignment || beingAddedToCampaign
                  ? beingAddedToAssignment
                    ? messages.assignment.addNew.toLowerCase()
                    : messages.person.campaigns.campaign.toLowerCase()
                  : messages.person.contacts
              )}
            </H4>
          )}
          {hasAddedPeople && (
            <H4 color={colors.green.dark} mt="xs">
              {messages.person.newContact.successMessages.addPeople.format(
                beingAddedToAssignment || beingAddedToCampaign
                  ? beingAddedToAssignment
                    ? messages.assignment.addNew.toLowerCase()
                    : messages.person.campaigns.campaign.toLowerCase()
                  : messages.person.contacts
              )}
            </H4>
          )}
        </>
      )}
    </Flex>
  )
}

export default ConfirmationSection
