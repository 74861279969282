import { Tag } from "components/Tags/tags.types"

export enum TagDataField {
  Name = "name"
}

export enum TagsDataField {
  Tags = "tags"
}

export type TagsPrimaryData = {
  tags: Tag[]
}

export const tagsPrimaryDataInitialValues = {
  [TagsDataField.Tags]: [{ id: 0, name: "", color: "", background: "" }]
}
