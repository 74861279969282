import { useState, useEffect, useMemo } from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints,
  PersonsEndpoints
} from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { SelectOptionProps } from "@ikiru/talentis-fpc"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { formatToOption } from "utils/transformer"
import { Assignment } from "views/assignments/assignment.types"
import {
  transformAssignmentOptions,
  transformCampaignOptions
} from "../helpers"
import { Campaign } from "views/campaigns/campaign.types"

export const useOnNotesLoad = () => {
  const [isNotesLoading, setIsNotesLoading] = useState(true)
  const { setNotes, personId, candidates, contacts } = usePerson()
  const [isAssignmentLoading, setIsAssignmentLoading] = useState<boolean>(true)
  const [assignmentsOptions, setAssignmentsOptions] = useState<
    SelectOptionProps[]
  >([])

  const [isCampaignLoading, setIsCampaignLoading] = useState<boolean>(true)
  const [campaignsOptions, setCampaignsOptions] = useState<SelectOptionProps[]>(
    []
  )

  const isLoading = useMemo(
    () => isAssignmentLoading || isNotesLoading || isCampaignLoading,
    [isAssignmentLoading, isCampaignLoading, isNotesLoading]
  )

  useEffect(() => {
    if (personId) {
      const getNotes = async () => {
        const [, response] = await apiRequest.get({
          endpoint: PersonsEndpoints.Notes,
          endpointParams: { id: personId },
          config: expandEndpointByParams({
            expand: [
              "createdByUser",
              "assignment",
              "campaign",
              "updatedByUser"
            ],
            // @todo remove after pagination is implemented
            Limit: 200
          })
        })

        setNotes(response?.data?.notes)
        setIsNotesLoading(false)
      }
      getNotes()
    } else {
      setNotes([])
      setIsNotesLoading(false)
    }
  }, [personId, setNotes])

  useEffect(() => {
    const populateAssignemntsOptions = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.SimpleList,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })
      const options =
        !error &&
        response?.data?.simpleActiveAssignments?.map((item: Assignment) =>
          formatToOption<Assignment>(item, ["name", "id"])
        )
      setAssignmentsOptions(
        transformAssignmentOptions(candidates, options || [])
      )
      setIsAssignmentLoading(false)
    }

    const populateCampaignsOptions = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.SimpleListCampaign,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      const options =
        !error &&
        response?.data?.simpleActiveCampaigns?.map((item: Campaign) =>
          formatToOption<Campaign>(item, ["name", "id"])
        )
      setCampaignsOptions(transformCampaignOptions(contacts, options || []))
      setIsCampaignLoading(false)
    }
    populateAssignemntsOptions()
    populateCampaignsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    assignmentsOptions,
    campaignsOptions
  }
}
