import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Button,
  Div,
  Flex,
  H4,
  colors,
  Separator,
  H5,
  Select,
  Link,
  CrossIcon,
  Loader
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  BodyStyled,
  CVParsingModalWrapper,
  DragAndDropWrapper,
  FileWrapper
} from "./style"
import CVparsingPicture from "assets/cvparsing.png"
import { useDropzone } from "react-dropzone"
import { PersonDocument } from "../person-documnets/types"
import {
  getParsedDataV2,
  getParsedNewDataV2,
  isEmptyPersonRecord
} from "./helpers"
import { NetworkIssue } from "./NetworkIssue"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { ParsingResumeModal } from "./parsing/ParsingResumeModal"
import { usePerson } from "../../person-module/candiate-module.context"
import { getNormalizedCVPersonDetails } from "../person-details/utils/get-cv-person-details"
import {
  CVProfessionalExperience,
  CVSkills,
  Person
} from "views/persons/person.types"
import { getNormalizedCvSkill } from "../person-details/utils/get-cv-skills"
import { getCVExperience } from "../person-details/utils/get-cv-experience"
import { useWindowSize } from "utils/hooks/use-window-size"
import { NoCVParsing } from "./NoCVParsing"
import { OverviewCVModal } from "./overview/OverviewCVModal"

const allowedExtensions = ["pdf", "doc", "docx", "rtf", "txt"]

export const CVParsingModal = () => {
  const [file, setFile] = useState<File | null>()
  const [document, setDocument] = useState<PersonDocument>()
  const [isParsing, setIsParsing] = useState(false)
  const [isNetworkIssue, setIsNetworkIssue] = useState(false)
  const [isSmallSize, setIsSmallSize] = useState(false)
  const [isCancelParsing, setIsCancelParsing] = useState(false)

  const {
    documents,
    setDocuments,
    personId,
    profileDetails,
    setCVParsing,
    cvParsing,
    person,
    keywords,
    setTempCvParsingData
  } = usePerson()

  const { close, openInPortal, open } = useModal()

  const { width } = useWindowSize()

  useEffect(() => {
    if (width && width > 990) {
      setIsSmallSize(false)
    } else {
      setIsSmallSize(true)
    }
  }, [width])

  const onSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event?.target
      const tempFile = documents.find(
        (document: PersonDocument) => document.id === value
      )
      setDocument(tempFile)
    },
    [documents]
  )

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !!file
  })

  const options = useMemo(() => {
    const filteredFiles =
      documents.filter((document: PersonDocument) => {
        const fileExtension =
          document.filename.split(".").pop()?.toLowerCase() || ""
        return allowedExtensions.includes(fileExtension)
      }) || []

    if (filteredFiles.length > 0) {
      return [
        { value: "", label: messages.person.cvparsing.selectDocument },
        ...filteredFiles.map((document) => ({
          value: document.id,
          label: document.filename
        }))
      ]
    } else {
      return [{ value: "", label: messages.person.cvparsing.noFiles }]
    }
  }, [documents])

  const getParseCVData = useCallback(
    (parsePerson: Person) => {
      let experienceData: CVProfessionalExperience = null!
      let parsedSkills: CVSkills = null!

      const isEmptyProfile = isEmptyPersonRecord(profileDetails)

      experienceData = getCVExperience(parsePerson, person, isEmptyProfile)

      parsedSkills = getNormalizedCvSkill(
        parsePerson,
        person,
        keywords,
        isEmptyProfile
      )

      const { cvPersonDetails, cvContactDetails } =
        getNormalizedCVPersonDetails(
          parsePerson,
          profileDetails,
          isEmptyProfile
        )
      setCVParsing({
        ...cvParsing,
        personDetails: cvPersonDetails,
        contactInfo: cvContactDetails,
        professionalExperience: experienceData,
        skills: parsedSkills
      })
      setTempCvParsingData(parsePerson)

      const isParsingStop = localStorage.getItem("isCancelParsing")

      if (isParsingStop !== "true") {
        setIsParsing(false)
        close(ModalNames.CVParsing)

        if (isEmptyPersonRecord(profileDetails)) {
          open(ModalNames.OverviewCVModal, <OverviewCVModal />)
        } else {
          openInPortal(ModalNames.ParsingResume, <ParsingResumeModal />)
        }
      }
    },
    [
      profileDetails,
      setCVParsing,
      cvParsing,
      setTempCvParsingData,
      person,
      close,
      openInPortal,
      open,
      keywords
    ]
  )

  const parsingCV = useCallback(async () => {
    localStorage.setItem("isCancelParsing", "false")
    setIsCancelParsing(false)
    setIsParsing(true)

    if (file?.name) {
      const { response } = await getParsedNewDataV2(file, personId)

      if (response?.data.documentId) {
        const [, responseDoc] = await apiRequest.get({
          endpoint: PersonsEndpoints.Documents,
          endpointParams: personId
        })

        setDocuments(responseDoc?.data?.documents)

        if (responseDoc?.data?.documents) {
          const tempFile = responseDoc.data.documents.find(
            (document: PersonDocument) =>
              document.id === response.data.documentId
          )
          setDocument(tempFile)
          setFile(null)
        }
      }
      if (response?.data.person) {
        getParseCVData(response?.data.person)
      } else {
        setIsNetworkIssue(true)
      }
    } else {
      const { response } = await getParsedDataV2(personId, document!.id)

      if (response?.data) {
        getParseCVData(response?.data.person)
      } else {
        setIsNetworkIssue(true)
      }
    }
  }, [file, personId, setDocuments, document, getParseCVData])

  const disabled = Boolean(file || document)

  const cancelParsing = () => {
    localStorage.setItem("isCancelParsing", "true")
    setIsCancelParsing(true)
    setIsParsing(false)
  }

  return (
    <CVParsingModalWrapper>
      <Flex justifyContent="space-between" width="100%">
        <Flex alignItems="baseline" justifyContent="space-between" width="100%">
          <H4 color={colors.grey.dark} mt="0">
            {messages.person.cvparsing.titleModal}
          </H4>
          {isNetworkIssue && (
            <Button
              onClick={() => close(ModalNames.CVParsing)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          )}
        </Flex>

        {!isParsing && (
          <Flex>
            <Button
              onClick={parsingCV}
              mode="primary"
              mr="xxs"
              size="small"
              disabled={!disabled}
            >
              {messages.person.cvparsing.parse}
            </Button>
            <Button
              onClick={() => close(ModalNames.CVParsing)}
              mode="standard-white"
              size="small"
            >
              {messages.generic.cancel}
            </Button>
          </Flex>
        )}
        {isParsing && !isNetworkIssue && (
          <Flex>
            <Button
              onClick={() => cancelParsing()}
              mode="standard-white"
              size="small"
            >
              {messages.generic.cancel}
            </Button>
            <Button
              ml="xs"
              onClick={() => {
                close(ModalNames.CVParsing)
                cancelParsing()
              }}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </Flex>
        )}
      </Flex>
      {isSmallSize ? (
        <NoCVParsing />
      ) : isNetworkIssue ? (
        <NetworkIssue
          handleTryAgain={() => {
            setIsNetworkIssue(false)
            setIsParsing(false)
          }}
        />
      ) : (
        <>
          {isParsing && !isCancelParsing ? (
            <Flex flexDirection="column" m="auto" alignItems="center">
              <Loader />
              <H5>{messages.person.cvparsing.parsingResume}</H5>
            </Flex>
          ) : (
            <>
              {(file || document) && (
                <FileWrapper>
                  <Link href="#" standalone mb="0">
                    {file?.name || document?.filename}
                  </Link>
                  <Button
                    size="action-small"
                    mode="standard-white"
                    onClick={() => {
                      setFile(undefined)
                      setDocument(undefined)
                    }}
                  >
                    <CrossIcon />
                  </Button>
                </FileWrapper>
              )}
              <BodyStyled disabled={disabled}>
                <DragAndDropWrapper
                  {...getRootProps({})}
                  isDragActive={isDragActive}
                >
                  <input {...getInputProps()} />
                  <img alt="CV parsing" width={123} src={CVparsingPicture} />
                  <H5 textAlign="center" my="xs" color={colors.grey.dark}>
                    {messages.person.cvparsing.dragAndDrop}
                  </H5>
                </DragAndDropWrapper>
                <Div width="100%" px="m" mt="xs">
                  <Separator
                    text={messages.person.cvparsing.or}
                    backgroundColor={colors.grey.lightest}
                  />
                </Div>

                <Button
                  onClick={getRootProps().onClick}
                  mode="standard-green"
                  size="small"
                  mb="m"
                  disabled={disabled}
                >
                  {messages.person.cvparsing.browseFile}
                </Button>
                <Div width="100%" px="m">
                  <Separator
                    text={messages.person.cvparsing.or}
                    backgroundColor={colors.grey.lightest}
                  />
                </Div>

                <Select
                  label={messages.person.cvparsing.selectAttached}
                  status="valid"
                  onChange={onSelectChange}
                  options={options}
                  disabled={disabled}
                ></Select>
              </BodyStyled>
            </>
          )}
        </>
      )}
    </CVParsingModalWrapper>
  )
}
