import React, { useCallback, useMemo } from "react"
import { InterviewProgressStage } from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { useClientPortalConfiguration } from "../../module/context"

const useStageCandidates = (stage: InterviewProgressStage) => {
  const {
    groupedCandidates,
    photos,
    toggleCandidatesInClientView,
    toggleCandidatesShowCommentaryInClientView
  } = useClientPortalConfiguration()

  const candidates = useMemo(
    () => groupedCandidates?.[stage] || [],
    [groupedCandidates, stage]
  )

  const selectedCandidatesCount = useMemo(
    () => candidates.filter((candidate) => candidate.showInClientView).length,
    [candidates]
  )

  const areAllSelected = useMemo(
    () =>
      selectedCandidatesCount === candidates.length &&
      Boolean(candidates.length),
    [selectedCandidatesCount, candidates]
  )

  const onToggleStage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const candidateIds = candidates
        .filter((candidate) => candidate.showInClientView !== checked)
        .map((candidate) => candidate.id)

      toggleCandidatesInClientView(candidateIds, checked)
    },
    [candidates, toggleCandidatesInClientView]
  )

  const onToggleCandidate = useCallback(
    (candidate: any) => (checked: boolean) => {
      toggleCandidatesInClientView([candidate.id], checked)
    },
    [toggleCandidatesInClientView]
  )

  const onToggleShowToClient = useCallback(
    (candidate: any) => (checked: boolean) => {
      toggleCandidatesShowCommentaryInClientView([candidate], checked)
    },
    [toggleCandidatesShowCommentaryInClientView]
  )

  return {
    candidates,
    areAllSelected,
    photos,
    selectedCandidatesCount,
    onToggleStage,
    onToggleCandidate,
    onToggleShowToClient
  }
}

export { useStageCandidates }
