import React, { useEffect, useState } from "react"
import SimilarPersonAiSearch from "./SimilarPersonAiSearch"
import ChatGPTSearchBox from "./ChatGPTSearchBox"
import { useSearchParams } from "react-router-dom"
import { useAISearch } from "./hooks/useAISearch"
import { useAlert } from "setup/alert/hooks"
import { messages } from "setup/messages/messages"
import { useSearch } from "views/search/SearchModule/context"
import { isEmpty } from "lodash"

const ChatGPTSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [personId, setPersonId] = useState("")
  const [name, setName] = useState("")
  const { warning } = useAlert()
  const { filters } = useSearch()
  const {
    getSimilarPeople,
    isLoading,
    getAISearchCriteria,
    isError,
    setIsError,
    updateSearchCriteria,
    isSimilarPersonError,
    hasSearched
  } = useAISearch()

  useEffect(() => {
    if (searchParams.has("personId")) {
      setPersonId(searchParams.get("personId") || "")
      searchParams.delete("personId")
      setSearchParams(searchParams)
    }
    if (searchParams.has("name")) {
      setName(searchParams.get("name") || "")
      searchParams.delete("name")
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (isSimilarPersonError) {
      setPersonId("")
      warning(messages.search.chatGPT.similarPeopleAiError, { closable: true })
    }
  }, [warning, isSimilarPersonError])

  useEffect(() => {
    if (hasSearched && isEmpty(filters)) {
      setPersonId("")
    }
  }, [filters, hasSearched])

  return personId ? (
    <SimilarPersonAiSearch
      personId={personId}
      getSimilarPeople={getSimilarPeople}
      isLoading={isLoading}
      name={name}
    />
  ) : (
    <ChatGPTSearchBox
      {...{
        getAISearchCriteria,
        isLoading,
        updateSearchCriteria,
        setIsError,
        isError
      }}
    />
  )
}

export default ChatGPTSearch
