export enum ChromeExternalMessageTypes {
  Version = "getExtensionVersion",
  SilentSignIn = "silentSignIn",
  SilentLogout = "silentLogout",
  RefetchUser = "refetchUser"
}

export enum ChromeExternalMessageErrorTypes {
  UnrecognizedType = "unrecognized-type"
}

export type ExternalMessage<T> = {
  source: string
  error?: ChromeExternalMessageErrorTypes
  payload?: T
}

export type VersionMessage = ExternalMessage<{
  version: string
}>
