import { bioSettingsInitialValues } from "./const"

export const loadAIBioSettings = () => {
  const prevSettingsString = localStorage.getItem("AIBioSettings")

  if (prevSettingsString) {
    const prevSettings = JSON.parse(prevSettingsString)

    return prevSettings
  }
  return bioSettingsInitialValues
}
