import React from "react"
import { Formik, Form } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { formState, initialFormState } from "utils/forms/form-state"
import {
  RegisterUserField,
  registerUserInitialValues,
  registerUsersAutocompleteKeys
} from "views/users/register-user/constants/register-user.definitions"
import { registerUserUserValidationSchema } from "views/users/register-user/constants/register-user.schema"
import { onRegisterUserSubmit } from "views/users/register-user/register-user.actions"
import { RouterUrl } from "setup/router/routes"
import { Button } from "@ikiru/talentis-fpc"
import { RouterLink } from "components/RouterLink/RouterLink"
import { Div } from "@ikiru/talentis-fpc"
import { colors } from "@ikiru/talentis-fpc"
import { H1 } from "@ikiru/talentis-fpc"

export type RegisterUserProps = {
  email: string
  company: string
  userId: string
  searchFirmId: string
}

export const RegisterUser = (props: RegisterUserProps) => {
  const { company, userId, email, searchFirmId } = props
  return (
    <Div width="100%">
      <Formik
        initialValues={{
          ...registerUserInitialValues,
          [RegisterUserField.Email]: email,
          [RegisterUserField.UserId]: userId,
          [RegisterUserField.SearchFirmId]: searchFirmId
        }}
        validationSchema={registerUserUserValidationSchema}
        onSubmit={onRegisterUserSubmit}
        initialStatus={initialFormState}
      >
        {(formik) => {
          const { status, isSubmitting } = formik
          return !formState.isFinished(status) ? (
            <>
              <H1 mt="xs" mb="l" color="green.standard">
                {messages.manageTeam.finishRegistration}
              </H1>
              <Form data-target="form" data-target-name="register-user">
                <FormikInput
                  name={RegisterUserField.Email}
                  id={RegisterUserField.Email}
                  disabled
                  label={messages.form.generic.emailAddress}
                />
                <FormikInput
                  disabled
                  defaultValue={company || "-"}
                  label={messages.form.generic.companyName}
                  name="predefined-company-name"
                />
                <FormikInput
                  id={RegisterUserField.FirstName}
                  name={RegisterUserField.FirstName}
                  label={messages.form.generic.firstName}
                  autoComplete={
                    registerUsersAutocompleteKeys[RegisterUserField.FirstName]
                  }
                />
                <FormikInput
                  id={RegisterUserField.LastName}
                  name={RegisterUserField.LastName}
                  label={messages.form.generic.lastName}
                  autoComplete={
                    registerUsersAutocompleteKeys[RegisterUserField.LastName]
                  }
                />
                <FormikInput
                  id={RegisterUserField.JobTitle}
                  name={RegisterUserField.JobTitle}
                  label={messages.form.generic.jobTitle}
                  autoComplete={
                    registerUsersAutocompleteKeys[RegisterUserField.JobTitle]
                  }
                />
                <FormikInput
                  id={RegisterUserField.Password}
                  name={RegisterUserField.Password}
                  label={messages.form.generic.password}
                  autoComplete={
                    registerUsersAutocompleteKeys[RegisterUserField.Password]
                  }
                  type="password"
                />
                <br />
                <Button
                  mt="m"
                  width="100%"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {messages.form.generic.submit}
                </Button>
              </Form>
            </>
          ) : (
            <Div textAlign="center">
              <H1
                fontSize={["29px"]}
                lineHeight={["32px"]}
                color={colors.green.standard}
              >
                {messages.registerUser.complete.accountCreated}
              </H1>

              <RouterLink to={RouterUrl.Homepage}>
                {messages.signup.logIn}
              </RouterLink>
            </Div>
          )
        }}
      </Formik>
    </Div>
  )
}
