import React from "react"
import { H5 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

export const NoCVParsing = () => {
  return (
    <H5 textAlign="center" mt="100px">
      {messages.person.cvparsing.noParsingPartOne} <br />
      {messages.person.cvparsing.noParsingPartTwo}
    </H5>
  )
}
