import React from "react"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ListPersonCandidates } from "views/persons/components/person-assignments/components/ListPersonCandidates"
import { PersonAssignmentOption } from "./types"
import PersonSections from "../person-components/PersonSections"
import PersonSectionButton from "../person-components/PersonSectionButton"
import { useLinkToProject } from "views/persons/hooks/useLinKToProject"

type AssignmentIsLoadingType = {
  assignments: boolean
  candidates: boolean
}

export type PersonAssignmentProps = {
  options: PersonAssignmentOption[]
  loading: AssignmentIsLoadingType
  project?: string
}

export const PersonAssignment = React.memo((props: PersonAssignmentProps) => {
  const { candidates } = usePerson()
  const { loading } = props
  const { linkAssignmentModal } = useLinkToProject()

  return (
    <PersonSections
      title={messages.person.assignments.title}
      sectionCount={Object.values(candidates).length}
      actions={
        <PersonSectionButton
          disabled={loading.assignments}
          label={messages.person.assignments.assignmentLink}
          onClick={() => linkAssignmentModal(props)}
        />
      }
    >
      <ListPersonCandidates
        candidates={candidates}
        isLoading={loading.candidates}
      />
    </PersonSections>
  )
})
