import React from "react"
import { Tag, TagVariants, colors, H4 } from "@ikiru/talentis-fpc"
import { List } from "./style"
import { SelectSection } from "views/internal-search/InternalSearchModule/types"

type PreferredListProps = {
  title?: string
  keywords?: string[]
  onKeywordRemove?: (keyword: string) => any
  variant?: TagVariants
  valueForSelect?: SelectSection[]
}

export const PreferredList = (props: PreferredListProps) => {
  const {
    keywords = [],
    title,
    onKeywordRemove,
    variant = "grey-dark",
    valueForSelect
  } = props

  const temValue = (keyword: string) => {
    return valueForSelect?.find((item: SelectSection) => item.value === keyword)
      ?.label
  }

  return keywords && Boolean(keywords?.length) ? (
    <>
      {title && (
        <H4 my="none" color={colors.grey.dark}>
          {title}
        </H4>
      )}
      <List>
        {keywords.map((keyword) => (
          <Tag
            key={JSON.stringify(keyword)}
            as="li"
            mr="xxs"
            mb="none"
            mt="xs"
            variant={variant}
            disabled={Boolean(!onKeywordRemove)}
            onRemoveClick={onKeywordRemove && (() => onKeywordRemove(keyword))}
          >
            {valueForSelect ? temValue(keyword) : keyword}
          </Tag>
        ))}
      </List>
    </>
  ) : null
}
