import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import {
  AssignmentFeeData,
  assignmentFeeDataInitialValues
} from "views/assignments/components/assignment-data/constants/definitions"
import { getChangedValues } from "views/assignments/utils"
import { Button, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "./style"
import { AssignmentFeeFormLayout } from "../../form/AssignmentFeeFormLayout"
import { AssignmentFeeDataValidationSchema } from "../../constants/schema"

type EditFeeDataProps = {
  onSubmit: (
    values: AssignmentFeeData,
    helpers: FormikHelpers<AssignmentFeeData>
  ) => void
  initialValues?: AssignmentFeeData
  formActionsComponent?: (
    formikProps: FormikProps<AssignmentFeeData>
  ) => JSX.Element
}

export const EditFeeData = ({
  onSubmit,
  initialValues,
  formActionsComponent
}: EditFeeDataProps) => {
  const { close } = useModal()

  const submitForm = (
    values: AssignmentFeeData,
    action: FormikHelpers<AssignmentFeeData>
  ) => {
    values.finalFee = Number(values.finalFee)
    values.feeFrom = Number(values.feeFrom)
    values.feeTo = Number(values.feeTo)

    const changeValues = getChangedValues(values, initialValues)

    values.feeCurrency && localStorage.setItem("currency", values.feeCurrency)

    onSubmit(changeValues, action)
    close(ModalNames.EditFeeData)
  }

  return (
    <StyledDiv>
      <Formik
        initialValues={{
          ...assignmentFeeDataInitialValues,
          ...initialValues,
          ...(initialValues?.feeCurrency
            ? { feeCurrency: initialValues?.feeCurrency }
            : { feeCurrency: localStorage.getItem("currency") || "USD" })
        }}
        validationSchema={AssignmentFeeDataValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form>
            <ModalSectionHeader
              title={messages.assignment.assignmentFeeEdit}
              size="xSmall"
              actions={
                <>
                  <Button mx="xxs" my="0" type="submit" size="small">
                    {messages.form.generic.save}
                  </Button>
                  <Button
                    mode="secondary"
                    mx="xxs"
                    my="0"
                    size="small"
                    onClick={() => close(ModalNames.EditFeeData)}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
            <AssignmentFeeFormLayout />
            {formActionsComponent?.(formik)}
          </Form>
        )}
      </Formik>
    </StyledDiv>
  )
}
