import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import CompanySearch from "views/assignments/components/assignment-company/CompanySearch"
import LocationSearch from "views/assignments/components/location-select/LocationSearch"
import { CompanySelected } from "views/assignments/components/assignment-company/types"
import { Location } from "views/assignments/assignment.types"
import {
  AssignmentDataField,
  assignmentStatuses,
  e2eTargets
} from "views/assignments/components/assignment-data/constants/definitions"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"

type AssignmentCreateModalType = {
  values: any
  handleChange: any
  handleBlur: any
  setLocationValue: React.Dispatch<React.SetStateAction<Location>>
  setCompanyValue?: React.Dispatch<React.SetStateAction<CompanySelected>>
}

const AssignmentCreateModal = ({
  values,
  handleChange,
  handleBlur,
  setLocationValue,
  setCompanyValue
}: AssignmentCreateModalType) => {
  return (
    <Flex width="100%" flexWrap="wrap" flexDirection="column" padding="s">
      <FormikSelect
        layout={{ width: "100%" }}
        data-e2e-target-name={e2eTargets.status}
        options={assignmentStatuses}
        id={AssignmentDataField.Status}
        name={AssignmentDataField.Status}
        label={messages.form.generic.assignmentStatus}
      />
      <FormikInput
        layout={{ width: "100%" }}
        value={values?.jobTitle}
        onChange={handleChange}
        onBlur={handleBlur}
        id={AssignmentDataField.JobTitle}
        name={AssignmentDataField.JobTitle}
        label={messages.form.generic.jobTitle}
      />
      <LocationSearch
        id={AssignmentDataField.Location}
        name={AssignmentDataField.Location}
        label={messages.form.generic.location}
        selectedLocation={setLocationValue}
      />
      {setCompanyValue && (
        <CompanySearch
          id={AssignmentDataField.CompanyName}
          name={AssignmentDataField.CompanyName}
          label={messages.form.generic.companyName}
          selectedCompany={setCompanyValue}
        />
      )}
    </Flex>
  )
}

export default AssignmentCreateModal
