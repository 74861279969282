import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { PhoneDataType, TaggedEmail } from "views/persons/person.types"

export const onToggleIsPreffered = async (
  values: Record<string, TaggedEmail[] | PhoneDataType[]>,
  personId: string
) => {
  let [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: values,
    endpointParams: personId
  })
  return response?.data
}
