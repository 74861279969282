import styled, { css } from "styled-components"
import {
  Div,
  Flex,
  CrossIcon as Cross,
  ExternalLinkIcon as ExternalLink
} from "@ikiru/talentis-fpc"

type StyledFieldProps = React.HTMLAttributes<HTMLInputElement> & {
  isLarge: boolean | undefined
}

type OpenNewTabProp = {
  isLarge: boolean | undefined
}

type StyledContentProps = React.HTMLAttributes<HTMLInputElement> & {
  oneContent: boolean
}
export const PersonOverlayContainer = styled(Div)`
  padding: ${({ theme }) => theme.space.s}px;
  background-color: rgba(34, 65, 96, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  text-align: left;
`

export const PersonContent = styled(Div)`
  margin-left: 17%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`

export const BannerContainer = styled(Flex)<StyledContentProps>`
  padding: 19px;
  background-color: ${({ theme }) => theme.colors.grey.light};
  height: 60px;
  justify-content: ${({ oneContent }) =>
    oneContent ? "flex-end" : "space-between"};
  position: sticky;
  top: 0;
  z-index: 6;
`

export const BannerLabelContent = styled(Flex)<StyledFieldProps>`
  height: 30px;
  margin-left: ${({ isLarge }) => (isLarge ? 10 : 1)}px;
  align-items: center;
  justify-content: flex-start;
`

export const BannerNavContent = styled(Div)`
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const NavLabel = styled.p`
  font-size: 16px;
  margin-bottom: -0px;
  margin-top: 0px;
  color: ${({ theme }) => theme.colors?.grey?.dark};
`

const sharedCrossStyle = css`
  background: ${({ theme }) => theme.colors?.white?.standard};
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
`

export const CrossContainer = styled(Div)`
  ${sharedCrossStyle}
  width: 30px;
  height: 30px;
  margin-top: -10px;
`
export const CrossIcon = styled(Cross)`
  ${sharedCrossStyle}
  width: 25px;
  height: 25px;
  padding: 5px;
`

export const LinkIcon = styled(ExternalLink)`
  width: 13px;
  height: 13px;
  margin-left: 5px;
`

export const OpenNewTabLink = styled(Div)<OpenNewTabProp>`
  position: relative;
  left: ${({ isLarge }) => (isLarge ? 10 : 0)}px;
  top: 10px;
`
