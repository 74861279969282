import React, { useState } from "react"
import { StyledTooltipContent } from "./style"
import { Div } from "@ikiru/talentis-fpc"

type TooptipProps = {
  content: JSX.Element
  children: React.ReactNode
}

const Tooltip = ({ content, children }: TooptipProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const showTooltip = () => {
    setIsVisible(true)
  }

  const hideTooltip = () => {
    setIsVisible(false)
  }

  return (
    <Div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && <StyledTooltipContent>{content}</StyledTooltipContent>}
    </Div>
  )
}

export default Tooltip
