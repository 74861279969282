import React from "react"
import { Link } from "@ikiru/talentis-fpc"

type ClickablePhoneNumberProps = {
  phoneNumber: string
}

const ClickablePhoneNumber = ({ phoneNumber }: ClickablePhoneNumberProps) => {
  return (
    <Link
      m="none"
      ml="4px"
      mr="5px"
      style={{ lineHeight: "17px" }}
      href={`tel:${phoneNumber}`}
    >
      {phoneNumber}
    </Link>
  )
}

export default ClickablePhoneNumber
