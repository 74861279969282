import styled from "styled-components"
import { Button } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"

export const ClientPortalShareSettingsModalWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey.lightest};
  padding: ${({ theme }) => theme.space.m}px;
  width: 100svw;
  height: 100svh;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      width: 600px;
      height: auto;
      min-height: 320px;
    }
  `}
`

export const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.space.xs}px;
  right: ${({ theme }) => theme.space.xs}px;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      top: ${theme.space.m}px;
      right: ${theme.space.m}px;
    }
  `}
`

export const ScrollableDiv = styled(Div)`
  max-height: calc(100svh - 250px);
  overflow-y: auto;
`
