import React from "react"
import {
  CustomLink,
  ItemContainer,
  PreferredLabel,
  UsageTypeStyle
} from "./style"
import { CopyPasteIcon, Div, Flex, Toggle } from "@ikiru/talentis-fpc"

import UsageIcon from "./UsageIcon"
import { messages } from "setup/messages/messages"
import {
  EmailTypeEnums,
  PhoneTypeEnums
} from "../../person-details-tab/components/person-contact-info/form/constants/definitions"
import { e2eTargets } from "../../person-details-tab/constant/definitions"

type ItemComponentProps = {
  isPreferred: boolean
  onToggle: (index: number) => Promise<void>
  index: number
  usageType: PhoneTypeEnums | EmailTypeEnums
  usageLabel: string
  itemValue: string
  isItemEmail?: boolean
  isEmail?: boolean
}

const ItemComponent = (props: ItemComponentProps) => {
  const {
    isPreferred,
    onToggle: onToggleCallback,
    index,
    usageType,
    usageLabel,
    itemValue,
    isEmail = false,
    isItemEmail = false
  } = props

  const onToggle = () => {
    onToggleCallback(index)
  }

  return (
    <Flex flexDirection="column">
      {index === 0 && (
        <Div mb="xs">
          <PreferredLabel>{messages.form.generic.preferred}</PreferredLabel>
        </Div>
      )}
      <ItemContainer>
        <Flex mr="l">
          <Toggle
            labelProps={{ mt: "-5px" }}
            label={
              <Flex alignItems="flex-end">
                <Div mb="-3px">
                  <UsageIcon usageType={usageType} isEmail={isEmail} />
                </Div>
                <UsageTypeStyle>{usageLabel}</UsageTypeStyle>
              </Flex>
            }
            type="checkbox"
            hideUnderline
            checked={isPreferred}
            onClick={onToggle}
          />
        </Flex>
        <Flex alignItems="flex-end">
          {isItemEmail ? (
            <CustomLink
              size="small"
              data-e2e-target-name={`${e2eTargets.taggedEmails}-${index}`}
              href={`mailto:${itemValue}`}
            >
              {itemValue}
            </CustomLink>
          ) : (
            <CustomLink
              size="small"
              data-e2e-target-name={`${e2eTargets.taggedEmails}-${index}`}
              style={{ lineHeight: "13px" }}
              href={`tel:${itemValue}`}
            >
              {itemValue}
            </CustomLink>
          )}
          <CopyPasteIcon
            width="18px"
            height="20px"
            style={{ marginTop: "-3px" }}
            viewBox="0 -3 20 20"
            onClick={() => {
              navigator.clipboard.writeText(itemValue)
            }}
            cursor="pointer"
          />
        </Flex>
      </ItemContainer>
    </Flex>
  )
}

export default ItemComponent
