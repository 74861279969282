import React from "react"
import {
  FacebookIcon,
  YouTubeTempIcon,
  LinkedInIcon,
  XingIcon,
  CrunchbaseIcon,
  ReutersIcon,
  BloombergIcon,
  ZoomInfoIcon,
  TwitterIcon,
  OwlerIcon,
  CompaniesHouseIcon,
  InteractiveSignet
} from "@ikiru/talentis-fpc"

type WebSiteType =
  | "linkedIn"
  | "xing"
  | "crunchbase"
  | "reuters"
  | "bloomberg"
  | "facebook"
  | "zoomInfo"
  | "talentis"
  | "twitter"
  | "owler"
  | "companiesHouse"
  | "youTube"
  | "other"

export type WebSite = {
  type?: WebSiteType
  url: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {}
}

type KnownWebSiteType =
  | "linkedIn"
  | "xing"
  | "crunchbase"
  | "reuters"
  | "bloomberg"
  | "facebook"
  | "zoomInfo"
  | "talentis"
  | "twitter"
  | "owler"
  | "companiesHouse"
  | "youTube"

export type KnownWebSite = WebSite & {
  type: KnownWebSiteType
}

export const knownWebSitesIcons: Record<
  KnownWebSiteType,
  React.FunctionComponent<
    React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
  >
> = {
  linkedIn: LinkedInIcon,
  xing: XingIcon,
  crunchbase: CrunchbaseIcon,
  reuters: ReutersIcon,
  bloomberg: BloombergIcon,
  facebook: FacebookIcon,
  zoomInfo: ZoomInfoIcon,
  twitter: TwitterIcon,
  talentis: InteractiveSignet,
  owler: OwlerIcon,
  companiesHouse: CompaniesHouseIcon,
  youTube: YouTubeTempIcon
}
