import { ProjectRecordFilter } from "components/ProjectRecordsFilters/types"
import { useCallback } from "react"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

const useEditCampaignFilter = () => {
  const { appliedFilters, updateFilters } = useCampaign()
  /**
   * Check if a given filter is already in our list of applied filters
   */
  const filterIsApplied = useCallback(
    (contactFilter: ProjectRecordFilter) => {
      return (
        appliedFilters.findIndex(
          (filter: ProjectRecordFilter) =>
            filter.recordProperty === contactFilter?.recordProperty &&
            filter.filterKey === contactFilter.filterKey
        ) !== -1
      )
    },
    [appliedFilters]
  )

  const addFilter = useCallback(
    (contactFilter: ProjectRecordFilter) => {
      if (filterIsApplied(contactFilter)) {
        return
      }
      updateFilters([...appliedFilters, contactFilter], true)
    },
    [filterIsApplied, updateFilters, appliedFilters]
  )

  const removeFilter = useCallback(
    (contactFilter: ProjectRecordFilter) => {
      if (!filterIsApplied(contactFilter)) {
        return
      }

      updateFilters(
        appliedFilters.filter(
          (filter: ProjectRecordFilter) =>
            filter.value !== contactFilter.value &&
            filter.label !== contactFilter.label
        ),
        true
      )
    },
    [filterIsApplied, updateFilters, appliedFilters]
  )
  return { filterIsApplied, addFilter, removeFilter }
}

export default useEditCampaignFilter
