import { messages } from "setup/messages/messages"
import * as Yup from "yup"

export enum NoteField {
  NoteTitle = "noteTitle",
  NoteDescription = "noteDescription"
}

export const initialDefaultNoteState = {
  [NoteField.NoteTitle]: "",
  [NoteField.NoteDescription]: ""
}

export const noteValidationSchema = Yup.object({
  [NoteField.NoteTitle]: Yup.string()
    .max(100, messages.form.generic.errors.mustBeNoLongerThan.format(100))
    .required(messages.generic.required)
})
