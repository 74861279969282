import React, { useState } from "react"
import { useDrop } from "react-dnd"
import { Div } from "@ikiru/talentis-fpc"

export type CustomColumnDetailsType = {
  dataId: string
  objectName: string
}

type DropContainerProps = {
  acceptType: string
  customColumnDetails: CustomColumnDetailsType
  children: (isOver: boolean, isDropped: boolean) => JSX.Element
}

const DropContainer = ({
  acceptType,
  children,
  customColumnDetails
}: DropContainerProps) => {
  const [isDropped, setIsDropped] = useState(false)

  const { dataId, objectName } = customColumnDetails

  const [{ isOver }, drop] = useDrop({
    accept: acceptType,
    drop: () => {
      setIsDropped(true)
      return { objectName, dataId }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  return (
    <Div width="100%" mr="24px" height="100%" ref={drop}>
      {children(isOver, isDropped)}
    </Div>
  )
}

export default DropContainer
