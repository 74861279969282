import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { Unavailable } from "setup/api/interceptors/response/Unavailable"
import { AccessDenied } from "setup/api/interceptors/response/AccessDenied"
import { GeneralError } from "setup/api/interceptors/response/GeneralError"
import { PatchHeaders } from "./interceptors/request/PatchHeaders"

const requestInterceptors: any[] = [new PatchHeaders()]

const responseInterceptors: any[] = [
  new Unavailable(),
  new AccessDenied(),
  new GeneralError()
]

type ResolveCallbackType = (config: AxiosRequestConfig) => AxiosRequestConfig
type ResponseCallbackType = (
  response: AxiosResponse<any>
) => AxiosResponse<any> | Promise<AxiosResponse<any>>

export const registerInterceptors = (axiosInstance: AxiosInstance = axios) => {
  requestInterceptors.forEach((instance) => {
    const { resolve } = instance
    const boundResolve = resolve.bind(
      instance
    ) as unknown as ResolveCallbackType

    axiosInstance.interceptors.request.use(boundResolve)
  })

  responseInterceptors.forEach((instance) => {
    const { resolve, reject } = instance
    const boundResolve = resolve.bind(
      instance
    ) as unknown as ResponseCallbackType
    const boundReject = reject.bind(instance) as unknown as ResponseCallbackType

    axiosInstance.interceptors.response.use(boundResolve, boundReject)
  })
}
