import { AxiosError, AxiosResponse } from "axios"

export class Unavailable {
  public resolve = (response: AxiosResponse) => response
  public reject = async (error: AxiosError) => {
    if (error.message === "Network Error") {
      error.response = {
        headers: "NetworkError",
        config: {},
        status: 418,
        statusText: "",
        data: "Network error. Cannot connect to the server."
      }
    }

    return Promise.reject(error)
  }
}
