import { colors } from "@ikiru/talentis-fpc"
import React, { useCallback, useMemo, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { messages } from "setup/messages/messages"
import { ProjectParams } from ".."
import CustomSelect from "../Components/CustomSelect/index"
import { Plus } from "../Components/CustomSelect/styles"
import { ProjectButton, ProjectButtonLabel } from "../style"
import { Campaign } from "views/campaigns/campaign.types"

export type OptionsType = {
  label: string
  value: string
  disabled?: boolean
}

export type CampaignSelectorProps = {
  add: Function
  isLoading: boolean
  activeCampaigns: Campaign[] | undefined
  selectedCampaign: string
  setSelectedCampaign: (value: string) => void
  isAssignmentSelect: boolean | null
  fetchCampaigns: (isAdding: boolean) => void
  fetchAssignments: (isAdding: boolean) => void
}

const CampaignSelector = ({
  add,
  isLoading,
  activeCampaigns,
  selectedCampaign,
  setSelectedCampaign,
  isAssignmentSelect,
  fetchCampaigns,
  fetchAssignments
}: CampaignSelectorProps) => {
  const { projectId: campaignId = "" } = useParams<ProjectParams>()

  const handleChange = useCallback(
    (event: any) => {
      setSelectedCampaign(event?.value)
      localStorage.setItem("searchCampaign", event?.value)
    },
    [setSelectedCampaign]
  )

  const [options, setOptions] = useState<OptionsType[]>([])

  useEffect(() => {
    setOptions([
      {
        label: messages.talentGraphSearch.selectCampaign,
        value: "",
        disabled: true
      },
      ...(activeCampaigns?.map((campaign) => ({
        label: campaign.name,
        value: campaign.id
      })) || [])
    ])
  }, [activeCampaigns])

  useEffect(() => {
    if (
      campaignId &&
      !selectedCampaign &&
      activeCampaigns?.find((campaign) => campaign.id === campaignId)
    ) {
      localStorage.setItem("searchCampaign", campaignId)
      setSelectedCampaign(campaignId)
    }
  }, [campaignId, activeCampaigns, setSelectedCampaign, selectedCampaign])

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectedCampaign),
    [options, selectedCampaign]
  )

  return (
    <>
      {activeCampaigns ? (
        <CustomSelect
          label={messages.talentGraphSearch.selectCampaign}
          disabled={isLoading}
          options={options}
          defaultValue={options[0]}
          value={selectedOption}
          onChange={handleChange}
          isSearchable={false}
          isAssignmentSelect={isAssignmentSelect}
          fetchCampaigns={fetchCampaigns}
          fetchAssignments={fetchAssignments}
        />
      ) : (
        <ProjectButton
          onClick={() => add()}
          mode="standard-green"
          border="none"
          colorway={{ group: "grey", variant: "standard" }}
          hoverColorway={{ group: "grey", variant: "dark" }}
          prefix={<Plus width={12} height={12} fill={colors.white.standard} />}
        >
          <ProjectButtonLabel>
            {messages.talentGraphSearch.projectSelector.projectSwitch.campaign}
          </ProjectButtonLabel>
        </ProjectButton>
      )}
    </>
  )
}

export default React.memo(CampaignSelector)
