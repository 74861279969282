import React from "react"
import { Div, H5 } from "@ikiru/talentis-fpc"
import { FilterPopupContainer, FilterPopupContent } from "../../style"
import { FilterToggle } from "../../FilterToggle"
import { ProjectRecordFilter } from "../../types"

interface IGeneralFiltersProps {
  translatedString: string
  filtersData: ProjectRecordFilter[]
  filterIsApplied: (recordFilter: ProjectRecordFilter) => boolean
  addFilter: (recordFilter: ProjectRecordFilter) => void
  removeFilter: (recordFilter: ProjectRecordFilter) => void
}

export const GeneralFilters = React.memo(
  ({
    filtersData,
    translatedString,
    filterIsApplied,
    addFilter,
    removeFilter
  }: IGeneralFiltersProps) => {
    return (
      <FilterPopupContainer>
        <Div backgroundColor="grey.light" p="xs">
          <H5 color="grey.dark" m="0">
            {translatedString}
          </H5>
        </Div>
        <FilterPopupContent>
          {filtersData.map((filter) => (
            <Div key={JSON.stringify(filter.value)}>
              <FilterToggle
                filter={filter}
                {...{ filterIsApplied, addFilter, removeFilter }}
              />
            </Div>
          ))}
        </FilterPopupContent>
      </FilterPopupContainer>
    )
  }
)
