import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  CampaignDataField,
  campaignStatuses,
  e2eTargets
} from "views/campaigns/components/campaign-data/constants/definitions"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"

export const CampaignDataFormLayout = React.memo(() => (
  <Div alignItems="center" justifyContent="stretch">
    <FormikSelect
      data-e2e-target-name={e2eTargets.status}
      options={campaignStatuses}
      id={CampaignDataField.Status}
      name={CampaignDataField.Status}
      label={messages.campaign.campaignStatus}
    />
    <FormikInput
      data-e2e-target-name={e2eTargets.name}
      id={CampaignDataField.Name}
      name={CampaignDataField.Name}
      label={messages.campaign.campaignName}
    />
    <FormikDatepicker
      data-e2e-target-name={e2eTargets.startDate}
      id={CampaignDataField.StartDate}
      name={CampaignDataField.StartDate}
      label={messages.campaign.startDate}
    />
    <Div mt="xs">
      <FormikTextarea
        rows={4}
        id={CampaignDataField.Description}
        name={CampaignDataField.Description}
        label={messages.campaign.campaignDescription}
        resize="none"
      />
    </Div>
  </Div>
))
