import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { apiRequest } from "setup/api/api"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"

export const getParsedDataV2 = async (personId: string, documentId: string) => {
  const [, response] = await apiRequest.post({
    endpoint: PersonsEndpoints.CVParsingV2,
    endpointParams: { id: personId, docId: documentId },
    data: {},
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return { response }
}

export const getParsedNewDataV2 = async (file: File, personId: string) => {
  const formData = new FormData()

  file && formData.append("file", file as unknown as Blob)

  formData.append("personId", personId)

  const [, response] = await apiRequest.post({
    endpoint: PersonsEndpoints.CVParsingV2,
    endpointParams: { id: personId },
    data: formData,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return { response }
}

export const parsePersonWithAI = async (description: string) => {
  const [, response] = await apiRequest.post({
    endpoint: PersonsEndpoints.ParsePersonWithAI,
    data: { description: description },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return { response }
}

export const updateCVPerson = async (personId: string, data: any) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: data,
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
  return response?.data
}

export const isEmptyPersonRecord = (person: PersonProfileDetails) => {
  const { name, taggedEmails, webSites, ...otherFields } = person

  const areAllFieldsEmpty = Object.values(otherFields).every(
    (value) =>
      value === null ||
      value === undefined ||
      value === false ||
      (typeof value === "string" && value.trim() === "") ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null &&
        // @ts-ignore
        typeof value?.value === "string" &&
        // @ts-ignore
        value?.value.trim() === "") ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        // @ts-ignore
        Array.isArray(value?.value) &&
        // @ts-ignore
        value?.value.length === 0)
  )

  if (
    areAllFieldsEmpty &&
    (name || taggedEmails.length > 0 || webSites.length > 0)
  ) {
    return true
  } else {
    return false
  }
}

export const areAllPropertiesEmpty = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && !isEmptyData(obj[key])) {
      return false
    }
  }
  return true
}

export const isEmptyData = (value: any) => {
  if (Array.isArray(value)) {
    return value.length === 0
  } else if (typeof value === "object" && value !== null) {
    return areAllPropertiesEmpty(value)
  }
  return false
}

export const isObjectEmptyAIResult = (obj: PersonProfileDetails) => {
  function isEmptyValue(value: any) {
    if (
      value === "00000000-0000-0000-0000-000000000000" ||
      value === "" ||
      value === null ||
      value === false ||
      value === 0 ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        value !== null &&
        Object.keys(value).length === 0)
    ) {
      return true
    }
    return false
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      //@ts-ignore
      if (!isEmptyValue(obj[key])) {
        return false
      }
    }
  }

  return true
}
