export const transformLinkText = (linkText: string): string => {
  const prefix = "www."
  let url

  try {
    url = new URL(linkText)
  } catch {
    return linkText
  }

  const hostname = url.hostname

  return hostname.startsWith(prefix) ? hostname.substr(prefix.length) : hostname
}
