import { RadioBox } from "@ikiru/talentis-fpc"
import { Note } from "components/NoteItem/types"
import React from "react"
import { useNoteBox } from "./hooks"
import NoteFormWrapper from "./NoteForm/Wrapper"

type NoteBoxProps = {
  note: Note
  personId: string
  isSelected: boolean
  onSelectNote: (note: Note) => void
  onUpdateNote?: (note: Note) => void
}

const NoteBox = ({
  note,
  personId,
  isSelected,
  onSelectNote,
  onUpdateNote
}: NoteBoxProps) => {
  const { isEditMode, setEditMode, onSubmit } = useNoteBox(
    note,
    personId,
    (note) => {
      onUpdateNote?.(note)
      if (isSelected) {
        onSelectNote?.(note)
      }
    }
  )

  if (isEditMode)
    return (
      <NoteFormWrapper
        onCancel={() => setEditMode(false)}
        onSubmit={onSubmit(note.id)}
        note={note}
      />
    )

  return (
    <RadioBox
      title={note?.noteTitle}
      checked={isSelected}
      onChange={() => onSelectNote(note)}
      onActionClick={() => setEditMode(true)}
    >
      {note.noteDescription}
    </RadioBox>
  )
}

export default React.memo(NoteBox)
