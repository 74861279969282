import { apiRequest } from "setup/api/api"
import {
  UploadProjectDocumentField,
  UploadProjectDocumentValues
} from "./constants/upload-project-document.definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { FormAction, GetFormAction } from "utils/forms/form.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { ProjectType, ProjectValues } from "./constants/consts"

export type OnUploadProjectDocumentSubmit =
  FormAction<UploadProjectDocumentValues>

type GetOnUploadProjectDocumentSubmit =
  GetFormAction<UploadProjectDocumentValues>

export const getOnUploadProjectDocumentSubmit: GetOnUploadProjectDocumentSubmit =

    (projectId, onUploadSuccess, _, projectType = ProjectType.Assignment) =>
    async (values, helpers) => {
      const { [UploadProjectDocumentField.File]: file } = values
      const { setSubmitting, setFieldError, resetForm } = helpers

      const formData = new FormData()

      file && formData.append("file", file as unknown as Blob)

      setSubmitting(true)

      const [error, response] = await apiRequest.post({
        endpoint: ProjectValues[projectType].rootEndpoint,
        data: formData,
        endpointParams: projectId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      callWithErrorMessages(setFieldError, error)

      if (response) {
        onUploadSuccess!(response.data.files)
        resetForm()
        setSubmitting(false)
      }
    }
