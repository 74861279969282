import React from "react"
import { messages } from "setup/messages/messages"
import { BodyText, H3 } from "@ikiru/talentis-fpc"
import { NoResultsStyled } from "./styles"

export const NoResults = () => {
  return (
    <NoResultsStyled>
      <H3 color="green.standard" textAlign="center" mb="none">
        {messages.talentGraphSearch.noResults}
      </H3>
      <BodyText color="grey.standard" textAlign="center" mt="xxs">
        {messages.internalSearch.pleaseEditCriteria}
      </BodyText>
    </NoResultsStyled>
  )
}
