import React from "react"
import { messages } from "setup/messages/messages"
import { AssignmentDetailsForm } from "views/assignments/components/assignment-create-form/AssignmentDetailsForm"
import { Button, Div, getItem } from "@ikiru/talentis-fpc"
import { useAssignmentSubmit } from "utils/hooks/use-assignment-submit"
import { AssignmentDetailsFields } from "../manage-assignment/assignment-details.types"
import { localStorageKeys } from "setup/storage/storage.definitions"
import { AssignmentStatus } from "../../constants/assignment-statuses"

export const CreateAssignment = () => {
  const onSubmit = useAssignmentSubmit()

  return (
    <Div width={[1, 1, 1, 1 / 2, 1 / 3]}>
      <AssignmentDetailsForm
        initialValues={{
          [AssignmentDetailsFields.Status]:
            (getItem(
              localStorageKeys.assignmentStatusInput,
              false
            ) as AssignmentStatus) || AssignmentStatus.Active
        }}
        onSubmit={onSubmit}
        formActionsComponent={({ isSubmitting }) => (
          <Button type="submit" disabled={isSubmitting}>
            {messages.form.generic.save}
          </Button>
        )}
      />
    </Div>
  )
}
