import React from "react"
import { FormikProps } from "formik"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { Button, PlusIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  CreatePersonField,
  CreatePersonValues
} from "views/persons/components/person-details/form/constants/definitions"
import { AddButtonContainer } from "./style"

type EditableWebsiteSectionButtonProps = {
  innerRefs: {
    [key in "addWebsiteButtonRef"]: React.MutableRefObject<HTMLButtonElement>
  }
  formikProps: FormikProps<CreatePersonValues>
} & Omit<React.HTMLProps<HTMLButtonElement>, "hidden" | "type" | "aria-label">

export const EditableWebsiteSectionButton = (
  props: EditableWebsiteSectionButtonProps
) => {
  const { innerRefs, formikProps } = props
  const { addWebsiteButtonRef } = innerRefs

  const onAddField = (
    { setFieldValue, values }: FormikProps<CreatePersonValues>,
    FieldName: keyof Pick<PersonProfileDetails, "webSites">
  ) => {
    const array = values[FieldName] as PersonValue<any>[]
    const isAnyEmpty = !array.every(Boolean)

    if (!isAnyEmpty) {
      setFieldValue(FieldName, [
        ...values[FieldName],
        { value: "", isReadOnly: false }
      ])
    }
  }

  return (
    <AddButtonContainer>
      <Button
        ml="xxs"
        size="small"
        prefix={<PlusIcon fill="currentColor" width={12} height={12} />}
        mode="standard-white"
        onClick={(e) => {
          onAddField(formikProps, CreatePersonField.WebSites)
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {messages.form.generic.webSite}
      </Button>
      <button
        ref={addWebsiteButtonRef}
        hidden
        type="button"
        aria-label="add-website-button"
        onClick={(e) => {
          onAddField(formikProps, CreatePersonField.WebSites)
          e.stopPropagation()
          e.preventDefault()
        }}
      />
    </AddButtonContainer>
  )
}
