import React, { useState } from "react"
import {
  Button,
  Flex,
  Div,
  colors,
  PlusIcon,
  Switch
} from "@ikiru/talentis-fpc"
import {
  projectListE2ETargets,
  searchInputName,
  searchFormInitialValues
} from "./list-project.definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Formik, Form } from "formik"
import { ProjectListSearch } from "./ProjectListSearch"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import AddAssignmentPopUp from "./AddAssignmentPopUp"
import { useCompany } from "views/companies/company-module/company-module.context"
import { SwitchLabel } from "views/companies/components/company-details/components/KnowEmployees/style"

interface ProjectSearchProps {
  projectNames: string[]
  onChanged: (searchProject: string) => void
  onItemClicked: (projectClicked: string) => void
  buttonTitle: string
  searchInput: string
  showAddAssignmentButton?: boolean
  setShowMyAssignmentsOnly?: (showMyAssignmentsOnly: boolean) => void
  showMyAssignmentsOnly?: boolean
}

export const ProjectSearch = ({
  projectNames,
  onChanged,
  onItemClicked,
  buttonTitle,
  searchInput,
  showAddAssignmentButton = false,
  setShowMyAssignmentsOnly,
  showMyAssignmentsOnly = false
}: ProjectSearchProps) => {
  const { companyDetails } = useCompany()
  const [isShowListProjectSearch, setIsShowListProjectSearch] = useState(false)
  const [searchedWord, setSearchedWord] = useState("")
  const { open } = useModal()

  const selectSearchProject = (name: string) => {
    onItemClicked(name)
    setSearchedWord(name)
    setIsShowListProjectSearch(false)
  }

  const onChange = (e: any) => {
    setSearchedWord(e.target.value)
    onChanged(e.target.value)
    if (e.target.value === "") {
      setIsShowListProjectSearch(false)
    }

    if (!isShowListProjectSearch) {
      setIsShowListProjectSearch(true)
    }
  }

  const onClick = () => {
    onItemClicked(searchedWord)
    setIsShowListProjectSearch(false)
  }

  const setInitialResults = () => {
    setIsShowListProjectSearch(false)
    setSearchedWord("")
    onItemClicked("")
  }

  const addAssigmentOverlay = () => {
    open(
      ModalNames.NewAssignment,
      <AddAssignmentPopUp
        companyId={companyDetails.id}
        companyName={companyDetails.name}
      />
    )
  }

  const switchFilter = (
    <Flex>
      <SwitchLabel
        {...(showMyAssignmentsOnly
          ? { color: "grey.dark" }
          : { color: "grey.darkest" })}
      >
        {messages.assignment.showAllAssignment}
      </SwitchLabel>
      <Switch
        label=""
        checked={showMyAssignmentsOnly}
        colorVariant="standard"
        onChange={() => setShowMyAssignmentsOnly?.(!showMyAssignmentsOnly)}
      />
      <SwitchLabel
        ml="xxs"
        {...(showMyAssignmentsOnly
          ? { color: "grey.darkest" }
          : { color: "grey.dark" })}
      >
        {messages.assignment.ShowOnlyMyAssignment}
      </SwitchLabel>
    </Flex>
  )

  return (
    <Formik
      initialValues={searchFormInitialValues}
      onSubmit={onClick}
      validateOnBlur={true}
    >
      {() => {
        return (
          <Form>
            <Flex
              p="xs"
              justifyContent={"space-between"}
              bg={colors.grey.light}
              mb="2px"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
              >
                <Flex>
                  <Div minWidth={["170px", "200px", "300px", "400px"]}>
                    <FormikInput
                      onClear={setInitialResults}
                      name={searchInputName}
                      label={searchInput}
                      onChange={onChange}
                      value={searchedWord}
                      autoComplete="off"
                    />
                    {isShowListProjectSearch && (
                      <ProjectListSearch
                        projectNames={projectNames}
                        onItemClicked={selectSearchProject}
                      />
                    )}
                  </Div>
                  <Button
                    marginLeft="10px"
                    my="xs"
                    data-e2e-target={projectListE2ETargets.submitButton}
                    type="submit"
                    mode="standard-green"
                    size="small"
                  >
                    {buttonTitle}
                  </Button>
                </Flex>
                {!showAddAssignmentButton &&
                  setShowMyAssignmentsOnly &&
                  switchFilter}
                {showAddAssignmentButton && (
                  <Flex alignItems="center">
                    <Button
                      mr="l"
                      mode="standard-green"
                      size="small"
                      onClick={addAssigmentOverlay}
                      prefix={
                        <PlusIcon
                          width={12}
                          height={12}
                          fill={colors.white.standard}
                        />
                      }
                    >
                      {messages.assignment.addNew}
                    </Button>

                    {switchFilter}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}
