import { EndpointName } from "setup/api/api.types"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints,
  CompanyEndpoints
} from "setup/api/endpoints/endpoints"

export enum ProjectType {
  Assignment = "assignment",
  Campaign = "campaign",
  Company = "company"
}

export const ProjectValues: Record<
  ProjectType,
  { rootEndpoint: EndpointName; downloadEndpoint: EndpointName }
> = {
  [ProjectType.Assignment]: {
    rootEndpoint: AssignmentsEndpoints.AssignmentDocument,
    downloadEndpoint: AssignmentsEndpoints.AssignmentDocDownloads
  },
  [ProjectType.Campaign]: {
    rootEndpoint: CampaignsEndpoints.CampaignDocument,
    downloadEndpoint: CampaignsEndpoints.CampaignDocDownloads
  },
  [ProjectType.Company]: {
    rootEndpoint: CompanyEndpoints.CompanyDocument,
    downloadEndpoint: CompanyEndpoints.CompanyDocDownloads
  }
}
