import { useCallback } from "react"
import { apiRequest } from "setup/api/api"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

export const useTagsContacts = () => {
  const { updateContactStage, setPersonOverlayUpdated } = usePerson()
  const updateContactState = useCampaign()

  const onTagsSelect = useCallback(
    async (tags: number[], contactId: any) => {
      const [error, response] = await apiRequest.patch({
        endpoint: ContactsEndpoints.Root,
        endpointParams: contactId,
        data: {
          tags
        }
      })

      if (response) {
        updateContactStage(contactId, response.data)
        if (!updateContactState.selectedContactId) {
          updateContactState.updateContactStage(contactId, response.data)
        }
        setPersonOverlayUpdated(true)
      }
      return [error, response]
    },
    [setPersonOverlayUpdated, updateContactStage, updateContactState]
  )

  return {
    onTagsSelect
  }
}
