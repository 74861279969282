import React, { useCallback, useMemo } from "react"
import { pick } from "lodash"
import {
  Button,
  PencilIcon,
  PlusIcon,
  colors,
  H4,
  Section,
  Div
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  EditOffLimitsField,
  OffLimitsData as OffLimitsDataType
} from "components/OffLimits/constants/definitions"
import { OffLimits } from "components/OffLimits/OffLimits"
import { EditOffLimitsData } from "components/OffLimits/OffLimitsDataModal/EditOffLimitsData"
import { OffLimitsType } from "components/OffLimits/type"

type OffLimitsDataProps = {
  offLimits: OffLimitsType
  onSubmit: (values: OffLimitsDataType) => void
  noEditable?: boolean
}

export const OffLimitsData = ({
  offLimits,
  onSubmit,
  noEditable = false
}: OffLimitsDataProps) => {
  const offLimitsData = useMemo(
    () => pick(offLimits, Object.values(EditOffLimitsField)),
    [offLimits]
  )

  const { open } = useModal()

  const offLimitsModal = useCallback(() => {
    open(
      ModalNames.EditOffLimits,
      <EditOffLimitsData initialValues={offLimitsData} onSubmit={onSubmit} />
    )
  }, [offLimitsData, onSubmit, open])

  return (
    <Section
      header={{
        titleProps: {
          fontWeight: 500,
          as: H4,
          fontSize: "18px !important",
          my: 0,
          p: 0,
          color: colors.grey.dark
        },
        title: messages.offLimits.offLimits,
        actions: (
          <>
            {offLimitsData.endDate || offLimitsData.comment || noEditable ? (
              <Button
                mode="standard-white"
                size="action-medium"
                onClick={offLimitsModal}
              >
                <PencilIcon />
              </Button>
            ) : (
              <Button
                mode="standard-green"
                size="small"
                onClick={offLimitsModal}
                prefix={
                  <PlusIcon
                    width={12}
                    height={12}
                    fill={colors.white.standard}
                  />
                }
              >
                {messages.offLimits.offLimits}
              </Button>
            )}
          </>
        )
      }}
    >
      <Div py="m">
        <OffLimits {...offLimits} />
      </Div>
    </Section>
  )
}
