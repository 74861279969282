import React, { useEffect, useRef, useState } from "react"
import { PersonContent, PersonOverlayContainer } from "./style"
import { Overlay } from "@ikiru/talentis-fpc"
import OverlayNavigation from "./OverlayNavigation"
import { CompanyKnowEmployeesTypes } from "views/companies/hooks/useCompanyKnowEmployees"
import { useCompany } from "views/companies/company-module/company-module.context"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useChangeItem } from "./hook/useChangeItem"
import PersonOverlay from "."
import { getProjectCounts, getUpdatedPhotoAndCallBack } from "./utils"
import { sortExperience } from "../person-experience/utils"

const CompanyPeopleOverlay = (props: CompanyKnowEmployeesTypes) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    handlePageChange,
    knowEmployees,
    paginationKnowEmployeesList,
    setKnowEmployees
  } = props
  const { selectedKnownEmployeeId, setSelectedKnownEmployeeId, companyId } =
    useCompany()

  const {
    getProfileData,
    personOverlayUpdated,
    contacts,
    candidates,
    person,
    personOverlayPhotoUpdated,
    profileDetails
  } = usePerson()

  const { directionNext, directionPrevious, isLastElement, isFirstElement } =
    useChangeItem(
      { handlePageChange },
      paginationKnowEmployeesList.pageNumber,
      paginationKnowEmployeesList.pageSize,
      paginationKnowEmployeesList.totalItemCount,
      knowEmployees || [],
      selectedKnownEmployeeId,
      setSelectedKnownEmployeeId,
      paginationKnowEmployeesList.pageCount
    )

  useEffect(() => {
    const findPerson = knowEmployees.find(
      (employee) => employee.personId === selectedKnownEmployeeId
    )
    setIsLoading(false)
    if (!findPerson) {
      setIsLoading(true)
      if (isLastElement) {
        setSelectedKnownEmployeeId(knowEmployees[0].personId)
        getProfileData(knowEmployees[0].personId)
      } else if (isFirstElement) {
        setSelectedKnownEmployeeId(knowEmployees[19].personId)
        getProfileData(knowEmployees[19].personId)
      }
    }
  }, [
    knowEmployees,
    setSelectedKnownEmployeeId,
    getProfileData,
    isFirstElement,
    isLastElement,
    selectedKnownEmployeeId
  ])

  let overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      setSelectedKnownEmployeeId("")
    }
  }

  useEffect(() => {
    if (personOverlayUpdated) {
      getUpdatedPhotoAndCallBack(
        selectedKnownEmployeeId,
        personOverlayPhotoUpdated,
        (photoUrl) => {
          const newKnowEmployees = knowEmployees.map((employee) => {
            if (employee.personId === selectedKnownEmployeeId) {
              const counts = getProjectCounts(contacts, candidates)
              employee.counts = [
                { name: "Assignments", count: counts.assignments },
                { name: "Shortlisted", count: counts.shortListed },
                { name: "Placed", count: counts.placed },
                { name: "Campaigns", count: counts.campaigns }
              ]
              const currentExp = sortExperience(person?.experience, companyId)
              if (currentExp.length) {
                employee.position = currentExp[0].title
              }
              employee.isOfflimits = profileDetails.isOfflimits
              employee.location = person?.location || ""
              employee.name = person?.name || ""
              if (photoUrl) {
                employee.photo = photoUrl
              }
            }
            return employee
          })
          setKnowEmployees(newKnowEmployees)
        }
      )
    }
  }, [
    candidates,
    companyId,
    contacts,
    knowEmployees,
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    profileDetails,
    selectedKnownEmployeeId,
    setKnowEmployees
  ])

  return (
    <PersonOverlayContainer onClick={closeOverlay}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={() => setSelectedKnownEmployeeId("")}
            oneContent
          />
          <PersonOverlay
            personId={selectedKnownEmployeeId}
            isLoadingCandidates={isLoading}
            project="company"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default CompanyPeopleOverlay
