import React, { useCallback, useMemo, useReducer } from "react"
import _ from "lodash"

import {
  SetIsLoading,
  TeamActionTypes,
  SetTeamMembers,
  TeamMember,
  AddInvitationResent,
  UpdateTeamMemberRole,
  SetSelectedTeamMember,
  ToggleActiveResponse
} from "./team-module.types"
import { TeamContext } from "./team-module.context"
import { teamReducer, initialTeamState } from "./team-module.reducer"
import { apiRequest } from "setup/api/api"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"
import { useAuth } from "setup/auth/module/auth.context"
import { messages } from "setup/messages/messages"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import { useAlert } from "setup/alert/hooks"
import { useModal } from "utils/hooks/use-modal"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { ModalNames } from "setup/modal/modal.definitions"
import { sortTeamMembers } from "./utils"
import { LicensesWarningModal } from "../invite-user/components/licenses-warning-modal"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type TeamModuleProps = {
  children: React.ReactNode
}

export const TeamModule = ({ children }: TeamModuleProps) => {
  const { user } = useAuth()
  const alert = useAlert()
  const {
    open: openModal,
    close: closeModal,
    onClose: onModalClose
  } = useModal()
  const [state, dispatch] = useReducer(teamReducer, initialTeamState)

  const setIsLoading: SetIsLoading = useCallback(
    (isLoading: boolean) => {
      dispatch({ type: TeamActionTypes.SetIsLoading, payload: isLoading })
    },
    [dispatch]
  )

  const setTeamMembers: SetTeamMembers = useCallback(
    (teamMembers, invited, inactiveMembers, invitationsResent) => {
      dispatch({
        type: TeamActionTypes.SetTeamMembers,
        payload: { teamMembers, invited, inactiveMembers, invitationsResent }
      })
    },
    [dispatch]
  )

  const updateTeamMemberRole: UpdateTeamMemberRole = useCallback(
    (teamMemberId: string, userRole: UserRoles) => {
      dispatch({
        type: TeamActionTypes.UpdateTeamMemberRole,
        payload: {
          teamMemberId,
          userRole
        }
      })
    },
    [dispatch]
  )

  const addInvitationResent: AddInvitationResent = useCallback(
    (teamMemberId) => {
      dispatch({
        type: TeamActionTypes.AddInvitationResent,
        payload: teamMemberId
      })
    },
    [dispatch]
  )

  const setSelectedTeamMember: SetSelectedTeamMember = useCallback(
    (teamMemberId) => {
      dispatch({
        type: TeamActionTypes.SetSelectedTeamMember,
        payload: teamMemberId
      })
    },
    [dispatch]
  )

  const fetchTeamMembers = useCallback(async () => {
    setIsLoading(true)

    const [, response] = await apiRequest.get({
      endpoint: UsersEndpoints.Root
    })

    if (response) {
      const {
        complete = [],
        invited = [],
        invitedForNewSearchFirm = []
      } = _.chain(response?.data?.users).groupBy("status").value()

      const userId = _.get(user, "profile.UserId")

      let teamMembers: TeamMember[] = [...invitedForNewSearchFirm, ...complete]

      teamMembers = sortTeamMembers(teamMembers, userId)

      const inactiveMembers = teamMembers.filter(
        (teamMember) => teamMember.isDisabled === true
      )
      const activeMembers = teamMembers.filter(
        (teamMember) => teamMember.isDisabled === false
      )

      setTeamMembers(
        activeMembers,
        _.sortBy(invited, "emailAddress"),
        inactiveMembers
      )
    }

    setIsLoading(false)
  }, [setIsLoading, setTeamMembers, user])

  const amIOwner = useMemo(() => user?.userRole === UserRoles.Owner, [user])

  const possibleRoles = useMemo(
    () => [
      {
        value: UserRoles.Owner,
        label: messages.manageTeam.roles.owner,
        disabled: !amIOwner
      },
      {
        value: UserRoles.Admin,
        label: messages.manageTeam.roles.admin
      },
      {
        value: UserRoles.TeamMember,
        label: messages.manageTeam.roles.teamMember
      }
    ],
    [amIOwner]
  )

  const onMemberRoleChange = useCallback(
    async (id: any, userRole: any) => {
      const [, response] = await apiRequest.put({
        endpoint: UsersEndpoints.Root,
        endpointParams: id,
        data: {
          id,
          userRole
        }
      })

      if (Boolean(response)) {
        updateTeamMemberRole(id, userRole)
      }
    },
    [updateTeamMemberRole]
  )

  const onResendInvitationLink = useCallback(
    async (memberId: string) => {
      await apiRequest.post({
        endpoint: UsersEndpoints.Resend,
        data: {},
        endpointParams: memberId
      })

      addInvitationResent(memberId)
    },
    [addInvitationResent]
  )

  const onRemoveInvitationLink = useCallback(
    async (memberId: string) => {
      const [error, response] = await apiRequest.put({
        endpoint: UsersEndpoints.Revoke,
        data: {},
        endpointParams: memberId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })
      response && fetchTeamMembers()
      error?.data?.errors?.["SearchFirmUser"]?.[0] &&
        alert.error(error?.data?.errors?.["SearchFirmUser"]?.[0], {
          timeout: 5000
        })
    },
    [fetchTeamMembers, alert]
  )

  const toggleTeamMemberStatus = useCallback(
    (teamMemberId: string, isDisabled: boolean) => {
      const members = [...state.teamMembers, ...state.inactiveMembers]

      const userId = _.get(user, "profile.UserId")

      const memberIndex = members.findIndex(
        (teamMember) => teamMember.id === teamMemberId
      )

      members[memberIndex] = {
        ...members[memberIndex],
        isDisabled
      }

      const teamMembers = sortTeamMembers(members, userId)

      const inactiveMembers = teamMembers.filter(
        (teamMember) => teamMember.isDisabled === true
      )
      const activeMembers = teamMembers.filter(
        (teamMember) => teamMember.isDisabled === false
      )

      setTeamMembers(activeMembers, state.invited, inactiveMembers)
    },
    [
      state.teamMembers,
      state.inactiveMembers,
      state.invited,
      user,
      setTeamMembers
    ]
  )

  const makeInactive = useCallback(
    (teamMemberId: any) => async () => {
      closeModal(ModalNames.MakeInactive)

      const [, response] = await apiRequest.put({
        endpoint: UsersEndpoints.MakeInActive,
        data: {
          searchFirmUserIdToMakeInActive: teamMemberId
        }
      })

      if (response) toggleTeamMemberStatus(teamMemberId, true)

      setSelectedTeamMember(undefined)
    },
    [closeModal, setSelectedTeamMember, toggleTeamMemberStatus]
  )

  const makeActive = useCallback(
    (teamMemberId: any) => async () => {
      closeModal(ModalNames.MakeActive)

      const [error, response] = await apiRequest.put({
        endpoint: UsersEndpoints.MakeActive,
        data: {
          searchFirmUserIdToMakeInActive: teamMemberId
        },
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (response) {
        toggleTeamMemberStatus(teamMemberId, false)
      } else if (
        error?.data?.response === ToggleActiveResponse.NoAvailableLicenses
      ) {
        openModal(ModalNames.Licenses, <LicensesWarningModal />)
      }

      setSelectedTeamMember(undefined)
    },
    [closeModal, openModal, setSelectedTeamMember, toggleTeamMemberStatus]
  )

  const onCancel = useCallback(() => {
    setSelectedTeamMember(undefined)
    closeModal(ModalNames.MakeInactive)
    closeModal(ModalNames.MakeActive)
  }, [setSelectedTeamMember, closeModal])

  const openMakeInactiveModal = useCallback(
    (teamMemberId: string) => {
      openModal(
        ModalNames.MakeInactive,
        <ConfirmationModal
          title={messages.manageTeam.makeUserInactiveQuestion}
          subtitle={messages.manageTeam.inactiveUsersCannotLogIn}
          confirmButtonLabel={messages.manageTeam.yesMakeInactive}
          cancelButtonLabel={messages.manageTeam.dontMakeInactive}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={makeInactive(teamMemberId)}
        />
      )
      onModalClose(ModalNames.MakeInactive, () => {
        setSelectedTeamMember(undefined)
      })
    },
    [makeInactive, onCancel, openModal, onModalClose, setSelectedTeamMember]
  )

  const openMakeActiveModal = useCallback(
    (teamMemberId: string) => {
      openModal(
        ModalNames.MakeActive,
        <ConfirmationModal
          title={messages.manageTeam.makeUserActiveQuestion}
          confirmButtonLabel={messages.manageTeam.yesMakeActive}
          cancelButtonLabel={messages.manageTeam.dontMakeActive}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={makeActive(teamMemberId)}
        />
      )
      onModalClose(ModalNames.MakeActive, () => {
        setSelectedTeamMember(undefined)
      })
    },
    [makeActive, onCancel, openModal, onModalClose, setSelectedTeamMember]
  )

  const toggleActive = useCallback(
    async (memberId: string, isActive: boolean) => {
      setSelectedTeamMember(memberId)
      if (!isActive) {
        openMakeInactiveModal(memberId)
      } else {
        openMakeActiveModal(memberId)
      }
    },
    [openMakeInactiveModal, setSelectedTeamMember, openMakeActiveModal]
  )

  const activeOwnersCount = useMemo(
    () =>
      state.teamMembers?.filter(
        (teamMember: TeamMember) => teamMember.userRole === UserRoles.Owner
      )?.length || 0,
    [state.teamMembers]
  )

  return (
    <TeamContext.Provider
      value={{
        ...state,
        user,
        amIOwner,
        possibleRoles,
        activeOwnersCount,
        toggleActive,
        setTeamMembers,
        fetchTeamMembers,
        onMemberRoleChange,
        onResendInvitationLink,
        onRemoveInvitationLink
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
