import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { gdprLawfulBasisOptions } from "./gdpr-lawful-basis"
import { PersonConsentsField } from "./definitions"
import { GDPRStatusOption } from "./gdpr-statuses"

const lawfulBasis = Yup.string().oneOf(
  gdprLawfulBasisOptions.map(({ value }) => value)
)

const originCharLimit = 50
const commentCharLimit = 400

const dataOrigin = Yup.string()
  .max(
    originCharLimit,
    messages.person.consents.form.dataOriginError.format(originCharLimit)
  )
  .nullable()

const gdprComment = Yup.string().max(
  commentCharLimit,
  messages.person.consents.form.gdprCommentError.format(commentCharLimit)
)

export const initialPersonConsentValidationSchema = Yup.object({
  [PersonConsentsField.LawfulBasis]: lawfulBasis,
  [PersonConsentsField.DataOrigin]: dataOrigin,
  [PersonConsentsField.GdprComment]: gdprComment
})

export const getPersonConsentValudationSchema = (
  gdprOptions: GDPRStatusOption[]
) => {
  const hasOptions = Boolean(gdprOptions.length)
  const GDPRStatus = Yup.string().oneOf(gdprOptions.map(({ value }) => value))
  return Yup.object({
    [PersonConsentsField.LawfulBasis]: lawfulBasis,
    [PersonConsentsField.DataOrigin]: dataOrigin,
    [PersonConsentsField.GdprComment]: gdprComment,
    ...(hasOptions && { [PersonConsentsField.Status]: GDPRStatus })
  })
}
