import React from "react"
import { salesMail, salesPhones } from "./consts"
import {
  Link,
  BodyText,
  Flex,
  SectionBody,
  SectionHeader,
  Div
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { LinkEmailStyled } from "./styles"

const UpdateBillingCTABox = () => (
  <Div display="block">
    <SectionHeader
      size="xSmall"
      title={messages.billing.updateBillingCTA.title}
    />
    <SectionBody>
      <BodyText mt="xs" mb="none">
        {messages.billing.updateBillingCTA.body}
      </BodyText>
      <Flex flexDirection="column">
        <BodyText my="xxs" mb="none">
          {messages.billing.updateBillingCTA.emailUs}:{"  "}
          <LinkEmailStyled size="small" href={`mailto:${salesMail}`}>
            {salesMail}
          </LinkEmailStyled>
        </BodyText>
        <BodyText mt="xxs" mb="none">
          {messages.billing.updateBillingCTA.us}
          {"  "}
          <Link size="small" href={`tel:${salesPhones.us}`}>
            {salesPhones.us}
          </Link>
        </BodyText>
        <BodyText mt="xxs" mb="none">
          {messages.billing.updateBillingCTA.uk}
          {"  "}
          <Link size="small" href={`tel:${salesPhones.uk}`}>
            {salesPhones.uk}
          </Link>
        </BodyText>
        <BodyText mt="xxs" mb="none">
          {messages.billing.updateBillingCTA.australia}
          {"  "}
          <Link size="small" href={`tel:${salesPhones.australia}`}>
            {salesPhones.australia}
          </Link>
        </BodyText>
      </Flex>
    </SectionBody>
  </Div>
)

export default React.memo(UpdateBillingCTABox)
