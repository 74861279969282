import React, { useCallback, useEffect } from "react"
import { messages } from "setup/messages/messages"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { useEstimate } from "views/subscription/components/billing-address/hooks"
import { BillingError } from "views/subscription/components/billing-error"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { BillingAddressForm } from "./components/BillingAddressForm"
import { BillingAddressType } from "./components/BillingAddressForm/BillingAddressForm.definitions"
import { SubscriptionPurchaseStep } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

export const BillingAddress = () => {
  const {
    setBillingDetails,
    setIsLoading,
    setPaymentError,
    changeStep,
    billingDetails,
    paymentData
  } = useSubscriptionPurchase()

  const { updateEstimate, putAndValidateAddressAndVAT } = useEstimate()

  const { paymentError } = paymentData

  const onSubmit = useCallback(
    async (values: BillingAddressType) => {
      setIsLoading(true)
      setBillingDetails(values)

      try {
        await updateEstimate(
          values.countryCode,
          values.postcode,
          values.vatNumber
        )
        await putAndValidateAddressAndVAT(
          values.countryCode,
          values.postcode,
          values.addressFirstLine,
          values.city,
          values.email,
          values.vatNumber,
          values.phone
        )
        changeStep(SubscriptionPurchaseStep.CARD_DETAILS)
      } catch (error) {
        setPaymentError(error.message)
        return
      } finally {
        setIsLoading(false)
      }
    },
    [
      setBillingDetails,
      setIsLoading,
      updateEstimate,
      setPaymentError,
      putAndValidateAddressAndVAT,
      changeStep
    ]
  )

  // Unset any errors when we leave this step
  useEffect(
    () => () => {
      setPaymentError("")
    },
    [setPaymentError]
  )

  return (
    <Flex width="100%" flexWrap="wrap" justifyContent="center">
      <Div width="360px" mx="s" mt="s" mb={["xxl", "m", "m"]}>
        {paymentError && (
          <BillingError
            my="s"
            title={messages.subscription.paymentError.addressFailed}
            message={paymentError}
          />
        )}
        <BillingAddressForm
          initialValues={billingDetails}
          onSubmit={onSubmit}
        />
      </Div>
    </Flex>
  )
}
