import React from "react"
import { Toggle, ToggleProps } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { Div } from "@ikiru/talentis-fpc"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"

type FormikToggleProps = ToggleProps & {
  name: Pick<ToggleProps, "name">
  hideErrorMessage?: boolean
  hideUnderline?: boolean
}

const e2eTarget = "form-input"

export const FormikToggle = (props: FormikToggleProps) => {
  const {
    label,
    name,
    onBlur: explicitOnBlur,
    hideErrorMessage,
    hideUnderline,
    type,
    ...otherExplicitProps
  } = props
  const [formikProps, meta] = useField(name as string)

  const {
    onBlur: formikDefaultOnBlur,
    value,
    ...otherFormikProps
  } = formikProps

  const status = useStatus({
    ...meta,
    value: type === "radio" ? props.value === value : value
  })
  const shouldDisplayError = useShouldDisplayError(meta) && !hideErrorMessage

  return (
    <Div>
      <Toggle
        label={label}
        data-e2e-target={e2eTarget}
        status={status}
        hideUnderline={hideUnderline}
        type={type}
        onBlur={(event) => {
          formikDefaultOnBlur(event)
          explicitOnBlur?.(event)
        }}
        checked={type === "radio" ? props.value === value : value}
        {...otherFormikProps}
        {...otherExplicitProps}
      />
      {shouldDisplayError && <Error>{meta.error}</Error>}
    </Div>
  )
}
