const strings = {
  form: {
    generic: {
      name: "Name",
      fullName: "Full name",
      jobTitle: "Job title",
      company: "Company",
      location: "Location",
      role: "Role",
      title: "Title",
      emailAddresses: "Email addresses",
      emailAddress: "Email address",
      phone: "Phone number",
      webSite: "Website",
      webSites: "Add website",
      linkedInUrl: "LinkedIn URL",
      biography: "Biography",
      submit: "Submit",
      signup: "Sign up",
      companyName: "Company name",
      enterCompany: "Enter company name",
      country: "Country",
      knownAs: "Known as",
      dateOfBirth: "DOB",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      phoneType: "Phone type",
      phoneNumber: "Phone number",
      phoneNumbers: "Phone numbers",
      addCompany: "Add Company",
      addPhoneNumber: "Add phone number",
      addNewEmail: "Add email address",
      addMissingPhoneNumber: "Add phone number",
      addMissingNewEmail: "Add email address",
      addMissingWebsite: "Add website",
      password: "Password",
      enterAPearsonsName: "Enter a person's name",
      lookUpPersonName: "Quick lookup person by name",
      searchContacts: "Search contacts",
      contactLookup: "Lookup",
      browse: "Browse",
      clear: "Clear",
      upload: "Upload",
      preferred: "Preferred",
      nationality: "Nationality",
      refresh: "Refresh",
      mr: "Mr.",
      ms: "Ms.",
      mrs: "Mrs.",
      dr: "Dr.",
      prof: "Prof.",
      miss: "Miss.",
      blank: "",
      chooseACountry: "Country: (None selected) ",
      postalAddress: "Postal address",
      addressLine1: "Address line 1",
      addressLine2: "Address line 2",
      city: "City",
      postcode: "Post code",
      phoneTypeLabels: {
        personal: "Personal",
        businessDirect: "Business direct",
        business: "Business",
        other: "Other"
      },
      emailTypeLabels: {
        personal: "Personal",
        business: "Business",
        other: "Other"
      },
      errors: {
        mustBeNoLongerThan: "Must be no longer than {0} characters.",
        mustBeNoSmallerThan: "Must be no smaller than {0} characters.",
        urlFormat: "Wrong URL format",
        captcha:
          "This appears to be an automated request. If this is incorrect, please contact us!",
        invalidEmail: "Invalid email address",
        emailExists: "Email already exists",
        locationDropDown:
          "Required - please enter location and select from dropdown list",
        companySelect:
          "Required - please enter company name and select from dropdown list"
      },
      showMore: "Show More",
      showLess: "Show Less",
      save: "Save",
      next: "Next",
      previous: "Previous",
      assignmentName: "Assignment name",
      startDate: "Start date",
      endDate: "End date",
      today: "Today",
      assignment: "Assignment",
      assignmentStartDate: "Assignment start date",
      assignmentStatus: "Status",
      sector: "Sector",
      keywords: "Keywords",
      workHistory: "Work history",
      show: "Show",
      hide: "Hide",
      removeReminder: "Remove reminder",
      assignTo: "Assign to",
      inviteColleagues: "Invite your colleagues",
      inviteMoreColleagues: "+ Invite more Colleagues",
      inviteSent: "Invites Sent",
      inviteAlreadySent: "Invite already sent to user",
      workWithColleagues:
        "Work with your colleagues on assignments – share candidate details, assign tasks, progress candidates and view each others notes",
      skipStep: "Skip this Step",
      youCanAddOrRemove:
        "You can add or remove colleagues and manage permissions from the Team area:",
      description: "Description"
    }
  },
  person: {
    title: "People",
    contacts: "contacts",
    details: {
      name: "Name",
      jobTitle: "Job title",
      company: "Company",
      education: "Education",
      location: "Location",
      linkedInUrl: "LinkedIn URL",
      localOnly: "This person is local only.",
      linkGraph: "Link to TalentGraph",
      linkError: "There is already another record with the same LinkedIn URL.",
      linkSuccess: "This person has been linked to TalentGraph successfully.",
      createdDate: "Created date:",
      latestNoteDate: "Latest note date:",
      errors: {
        invalidLink: "Please enter a valid link.",
        invalidEmail: "Invalid email address",
        invalidPhoneNumber: "Invalid phone number"
      }
    },
    personRecordBox: {
      atLeast: "at least ",
      yearsExp: "yrs exp.",
      noInfo: "(no info about exp.)",
      year: "yr",
      month: "mo",
      yearsNmonths: "s"
    },
    personTabSection: {
      displayName: "Display Name",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      knownAs: "Known as",
      nationality: "Nationality",
      title: "Title",
      dateOfBirth: "DOB",
      biography: "Biography",
      address: "Address",
      dataPrivacy: "Data privacy",
      location: "Location",
      webLinks: "Web links",
      webSite: "Website",
      webSites: "Websites",
      contactInfo: "Contact info",
      email: "Email",
      emailAddress: "Email address",
      phoneNumbers: "Phone number",
      form: {
        personDetailsFormTitle: "Person details edit mode",
        addressEditMode: "Address edit mode",
        webLinksEditMode: "Web links edit mode",
        contactInfoEditMode: "Contact info edit mode",
        attributesEditMode: "Uncategorised keywords",
        profExperienceEditMode: "Professional experience edit mode",
        profExperienceCreateMode: "Professional experience create mode"
      },
      tabHeaders: {
        genericDetails: "Generic details",
        biography: "Biography"
      },
      noDataTexts: {
        noAddress: "No address details",
        noContacts: "No contact info details",
        noWebLinks: "No web link details ",
        noExperience: "No professional experience details",
        noEducation: "No education details",
        noAssignment: "No assignment details",
        noCampaign: "No campaign details",
        noNotes: "No note details",
        noDocuments: "No document details",
        noAttributes: "No attributes details"
      }
    },
    assignments: {
      title: "Assignments",
      noAssignmentsMessage:
        "You need to create an assignment before you can attach a person to it",
      assignmentLink: "Assignment link",
      linkToAssignment: "Link to assignment",
      linkAssignment: "Link assignment",
      selectAssignment: "Select assignment",
      interviewProgressTitle: "Status",
      all: "All",
      interviewProgress: {
        stages: {
          identified: "Identified",
          source: "Source",
          screening: "Screening",
          internalInterview: "Internal interview",
          shortList: "Shortlist",
          firstClientInterview: "First client interview",
          secondClientInterview: "Second client interview",
          thirdClientInterview: "Third client interview",
          offer: "Offer",
          placed: "Placed",
          archive: "Archive"
        },
        statuses: {
          noStatus: "[No status]",
          needToContact: "Need to contact",
          leftMessage: "Left message",
          contacted: "Contacted",
          interested: "Interested",
          notInterested: "Not interested",
          arrangingInterview: "Arranging interview",
          interviewPlanned: "Interview planned",
          awaitingFeedback: "Awaiting feedback",
          awaitingReferences: "Awaiting references",
          onHold: "On hold",
          withdrew: "Withdrew",
          rejected: "Rejected"
        },
        noStatusWithOutParenthesis: "No status"
      },
      dueDate: "Due date",
      none: "[None]",
      noTags: "[no tags]"
    },
    experience: {
      title: "Professional experience",
      titleSalary: "Salary & Package",
      at: " at ",
      form: {
        sectorLabel: "Sector"
      },
      areyousuredelete:
        "Are you sure you want to delete this professional experience?",
      salary: "Salary",
      package: "Package",
      unspecified: "Unspecified",
      comment: "Comment",
      salaryTitle: "Salary from (annual)",
      currency: "Currency",
      titleEmptyRecord: "Let's fill in the gaps!",
      fillByParseCVTitle: "Parse resume / CV",
      fillByParseCVDescription:
        "Convert a resume, cv or LinkedIn PDF profile to a populated record.",
      fillBySocialMediaTitle: "Parse from social media URL",
      fillBySocialMediaDescription:
        "Got a social media URL for this person? Populate a record from over 850M social profiles",
      fillManuallyTitle: "Add professional experience manually",
      fillManuallyDescription: "No formal profile on the candidate? Type away!",
      parseTextModalTitle: "Parse from text",
      parseTextModalPlaceholder: "Copy/paste or dictate the bio here",
      parseTextButton: "Parse",
      parseTextPlaceholder: "Please copy/paste text here",
      parseTextTitle: "Parse from voice or text",
      parseTextDescription: "Parse from a spoken or raw text bio/profile",
      parseAIIssueOne:
        "Sorry, we have encountered a problem parsing the profile summary.",
      parseAIIssueTwo:
        " This may be a temporary issue. Please try again or amend the text."
    },
    keywords: {
      title: "Keywords",
      form: {
        addKeywordPlaceholder: "Add keyword",
        addKeywordLengthError:
          "The keyword should be at most {0} characters long."
      }
    },
    consents: {
      title: "Person's preferences",
      dataPrivacy: "Data privacy",
      updatedDate: "Updated date",
      form: {
        lawfulBasisLabel: "Lawful basis",
        gdprLawfulBasis: "GDPR lawful basis",
        gdprExpiration: "Optional expiration",
        gdprComment: "GDPR comment",
        statusLabel: "Privacy status",
        dataOriginLabel: "Data origin",
        dataOriginError: "Data origin must be at most {0} characters long.",
        gdprCommentError: "GDPR Comment must be at most {0} characters long.",
        options: {
          basis: {
            notSet: "Not set",
            none: "[none]",
            verbalConsent: "Verbal consent",
            emailConsent: "Email consent",
            digitalConsent: "Digital consent",
            legitimateInterest: "Legitimate interest",
            notRequired: "Not required"
          },
          status: {
            notStarted: "Not started",
            consentRequestSent: "Consent request sent",
            consentGiven: "Consent given",
            consentRefused: "Consent refused",
            notificationSent: "Notification sent",
            objected: "Objected"
          }
        }
      }
    },
    uploadDocument: {
      fileAdded: "File added",
      title: "Documents",
      newDocument: "Document"
    },
    avatar: {
      avatar: "Avatar",
      uploadAvatar: "Add a photo",
      uploadCta: "Choose a file",
      photoUploadingPleaseWait: "Photo uploading, please wait",
      savePhoto: "Save photo",
      deletePhoto: "Delete photo",
      changePhoto: "Change photo",
      fileSizeMust: "File size must be no larger than 2MB",
      fileFormatMust: "File format must be a jpg or gif or png or jpeg",
      confirmDeletionMessage: "Are you sure you want to delete the photo?"
    },
    commentary: {
      title: "Commentary",
      addNote: "Add a commentary",
      newNote: "Commentary",
      saveCommentary: "Save commentary",
      form: {
        commentaryText: "Commentary text"
      },
      showInClientView: "Show commentary in client view",
      currentlyShared:
        "This commentary is currently shared in the client portal. Any changes made will be shared with your client.",
      pleaseUncheck:
        "If you do not wish to share, please uncheck the option below before continuing."
    },
    note: {
      title: "Notes",
      addNote: "Add note",
      editNote: "Edit Note",
      newNote: "Note",
      deleteNote: "Delete note",
      saveNote: "Save note",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      plusNote: "+ Note",
      form: {
        noteText: "Note text",
        noteTitle: "Note title",
        noteEditMode: "Note edit mode",
        noteDescription: "Note description",
        addNote: "Add note",
        linkTo: "Link note to",
        none: "None",
        assignment: "Assignment",
        campaign: "Campaign",
        options: {
          none: "[none]"
        }
      },
      showLessNote: "show less note",
      showFullNote: "show full note",
      edited: "Edited",
      mostRecentNote: "Most recent note:",
      latestNote: "Latest note",
      latestNoteDate: "Latest note date: "
    },
    teasers: {
      findContactDetailsButton: "Find contact details",
      title: "What we've found:",
      foundDetails: {
        emailFound: "Email(s) found.",
        phoneFound: "Phone number(s) found."
      },
      notFoundDetails: {
        emailFound: "Emails not found.",
        phoneFound: "Phone numbers not found. "
      },
      savedDetails: "Found contact details now saved.",
      contactDetailsNotFound: "No new contact details found.",
      fetchingContactDetails: "Fetching contact...",
      getContactDetails: "Use 1 credit to access details",
      unableToRetrieve: "Unable to retrieve contact, please try again later.",
      outOfCredits: "You do not have enough credits for this action.",
      noContactDetails: "No contact details found. No credits were used."
    },
    newContact: {
      addSelectedPerson: "Do you want to add selected person?",
      addSelectedPeople:
        "Selected {0} of {1}. Do you want to add selected people?",
      potentialDuplicatesFound: "{0} potential duplicates found",
      saveNewRecordQuestion: "Are you sure you want to save a new record?",
      saveNewRecord: "Yes, save new record",
      weFound: "We have found {0} potential matches",
      notFound: "No people found",
      errorMessageHeader: "Sorry, we have encountered a problem",
      errorMessage:
        "This may be a temporary issue. Please try again or if problem persists, contact support.",
      personAddedToCompanyData:
        "This person will only be added to your company's data, not the Talentis Talent Graph.",
      notAutoUpdates: "This person's details will not automatically update.",
      addContact: "Add contact",
      dontKnow: "Don't know their full details?",
      stillCantFind: "Still can't find person?",
      createNewContact: "Create new contact",
      pleaseSelect: `Please select the person or people you want to add to your {0}. If you can't find the person, please refine your search.`,
      successMessages: {
        addPerson: "This person has been successfully added to {0}.",
        addPeople: "These people have being successfully being added to {0}."
      }
    },
    bio: {
      title: "Biography",
      showFullBio: "Show full bio",
      hideFullBio: "Hide full bio",
      addBio: "Add a biography",
      editBio: "Edit biography",
      setMain:
        "This is an assignment specific biography. By default, it will not overwrite the main person record bio. " +
        "If you would like to make this the main bio for this person, tick the box below.",
      setMainLabel: "Make this the main biography",
      form: {
        biographyText: "Biography text",
        save: "Save biography"
      }
    },
    contact: {
      form: {
        title: "Quick search",
        hint: "*Please fill at least two of these: First/Last name, Company, or Job Title.",
        resultTitle: "Add people",
        mayAlreadyBeOnTalentis:
          "The person or people you are adding may already be in our TalentGraph",
        reduceDuplication:
          "If you know the details of the person / people, please enter as much details as you can so we can find their record and this will reduce duplication records being created.",
        findPerson: "Find person"
      },
      AddPeople: {
        whatYourLookingFor:
          "Enter what you are looking for, and we will search 850 million profiles on your behalf.",
        LookingToAdd:
          "Looking to add a specific person or find a person (or people) with a specific job title at a specific organization? Enter what you are looking for here, and we will search 850 million profiles on your behalf."
      },
      findPeople: {
        cityStateCountry: "City|State|County",
        mayHaveLinks: "May have links to location:",
        locationHelpTipStart: `The location search finds people currently located in or may have links to the chosen location(s). Use the `,
        advancedSearch: "Advanced Search",
        locationHelpTipEnd: "feature for more targeted searching."
      },
      interviewProgress: {
        statuses: {
          noStatus: "[No status]",
          needToContact: "Need to contact",
          leftMessage: "Left message",
          contacted: "Contacted",
          interested: "Interested",
          notInterested: "Not interested",
          arrangingIMeeting: "Arranging meeting",
          meetingPlanned: "Meeting planned",
          awaitingFeedback: "Awaiting feedback",
          awaitingReferences: "Awaiting references",
          onHold: "On hold",
          pitchingStage: "Pitching stage",
          assignmentWon: "Assignment won"
        }
      },
      dueDate: "Due date",
      none: "[None]",
      noTags: "[no tags]"
    },
    campaigns: {
      title: "Campaigns",
      campaign: "campaign",
      noCampaignsMessage:
        "You need to create a campaign before you can attach a person to it",
      campaignLink: "Campaign link",
      linkCampaign: "Link campaign",
      linkToCampaign: "Link to campaign",
      selectCampaign: "Select campaign",
      campaingName: "Campaign Name",
      showMore: "Show all campaigns",
      hideMore: "Hide all campaign"
    },
    tags: "Tags",
    personTabHeaders: {
      personDetails: "Person details",
      professionalExperience: "Professional experience",
      assignment: "Assignment",
      campaigns: "Campaign",
      notes: "Note",
      document: "Document",
      other: "Other info",
      attributes: "Attributes",
      s: "s"
    },
    personTeam: {
      title: "Team",
      editMode: "Team edit mode",
      noTeamMembers: "No team members",
      knownTo: "Known to",
      members: "Members",
      member: "Member"
    },
    cvparsing: {
      menuCV: "Parse resume/CV",
      menuParseText: "Parse voice/text",
      titleModal: "Parsing resume/CV",
      parse: "Parse",
      education: "Education",
      keywords: "Keywords",
      dragAndDrop: "Drag and drop your file here",
      browseFile: "Browse file",
      selectAttached: "Select from attached documents",
      selectDocument: "Select a document",
      or: "or",
      noFiles: "No file for this person",
      parsingResume: "Parsing resume/CV. This may take a moment. Please wait.",
      parsingPersonWithAI: "Parsing text. This may take a moment. Please wait.",
      noInternetConnection: "Resume/CV parsing error",
      checkInternet:
        "We have encountered a problem while parsing this resume/CV. Please check the file and try again.",
      tryAgain: "Try again",
      stepPersonDetails: "Person details",
      stepContactInfo: "Contact info",
      stepProfessionalExperience: "Professional experience",
      stepSkills: "Skills",
      contentParse:
        "Select any parsed data that you would like merged into the existing record, then click Next",
      dragAndDropContent:
        "To create a new job in your existing record, drag a parsed role from the right to the top left. To update an existing job, select the title, dates or job description and drag that data from the right to the role on the left that you wish to update",
      keywordParse:
        "Delete any keywords that you do not require. We will import any remaining skills",
      explainEducationDnD:
        "Drag any education data from the right to the left to save it into Talentis.",
      experience: {
        parsedTitle: "Parsed data",
        existingTitle: "Existing data",
        dropHere: "Drop the {0} here",
        experienceDetails: {
          title: "title",
          date: "date",
          description: "description"
        },
        explainMessageDnD:
          "Drag the experience you want to add here from the parsed data"
      },
      deleteAll: "Delete all",
      restoreAll: "Restore all",
      updateAll: "Update all",
      recoverAll: "Recover all",
      addAll: "Add all",
      parsedData: "Parsed data",
      existingData: "Existing data",
      personDetails: {
        name: "Display name",
        title: "Title",
        firstName: "First name",
        lastName: "Last Name",
        middleName: "Middle name",
        knownAs: "Known as",
        nationality: "Nationality",
        dateOfBirth: "DOB",
        bio: "Biography",
        addressLine1: "Address Line",
        addressLine2: "Address Line",
        addressLine3: "Address Line",
        addressLine4: "Address Line",
        city: "City",
        country: "Country",
        postcode: "Postcode",
        location: "Location"
      },
      contactInfo: {
        phones: "Phone number",
        webSites: "Websites",
        taggedEmails: "Email address",
        linkedInProfileUrl: "Linkedin URL"
      },
      overviewCV:
        "Please review these changes before completing the process. Upon completing the process, the following changes will be made to the record.",
      finishReview: "Finish review",
      updatePersonRecord: "Update person record",
      overview: {
        goBackPersonDetails: "Go back to person details",
        goBackProfessional: "Go back to professional experience",
        goBackContactInfo: "Go back to contact info",
        goBackSkills: "Go back to skills"
      },
      noParsingPartOne:
        "Due to small screen size or limited available screen space, the review parsing screen cannot be loaded.",
      noParsingPartTwo:
        "Please try resizing your browser or use a larger screen.",
      noChanges: "No changes made in this section.",
      unsavedChanges: "Unsaved changes",
      leaveWithoutSaving: "Leave without saving",
      alertMessage:
        "You are about to leave this page without saving. Please save all changes before proceeding to next step.",
      savingData: "Saving, please wait"
    }
  },
  createPerson: {
    title: "Add a new person",
    warning: "This person may already exist",
    viewPerson: "View Person",
    submitCta: "Add person"
  },
  searchPerson: {
    title: "Contacts",
    addContact: "Contact",
    tableColumns: {
      personsName: "Person's name",
      jobTitle: "Job title",
      company: "Company",
      location: "Location",
      createdData: "Created date",
      linkedInUrl: "LinkedIn URL",
      url: "Url"
    },
    addPersonModal: {
      header:
        "Find a person's online profile to import their information and start adding to their record",
      subHeader:
        "After importing a person to the TalentGraph their information will be automatically updated each time their profile is viewed.",
      submitCta: "Search for person",
      openNewTab: "Open in new tab"
    },
    ignoringPerson: {
      ignore: "Ignore",
      unignore: "Unignore",
      modal: {
        anAssignment: "an assignment",
        aCampaign: "a campaign",
        modalHeader: "Please select {0} before ignoring {1}",
        modalMessage:
          "This feature ignores {1} on searches relating to a specific {0} only.",
        closeButton: "Got it"
      }
    }
  },
  manageTeam: {
    title: "Settings",
    team: "Team",
    invite: "Invite",
    inviteUser: "Invite user",
    sendInvite: "Send Invite",
    inviteUserCta: "Generate invitation link",
    finishRegistration: "Finish the registration process",
    inviteAnother: "Invite another user",
    success: {
      title: "Invitation created",
      message: "The invitation email has been sent to"
    },
    invited: "Invited",
    currentTeam: "Current team",
    rolePermissions: "Role permissions",
    roles: {
      owner: "Owner",
      ownerPermissions: {
        hasFullAccess: "Has full access."
      },
      admin: "Admin",
      adminPermissions: {
        canEditTeamSettings: "Can edit team settings.",
        cannotAssignOwnerRole: "Cannot assign Owner role.",
        cannotAccessPlanAndBillingSettings:
          "Cannot access plan and billing settings."
      },
      teamMember: "Member",
      teamMemberPermissions: {
        cannotAccess: "Cannot access plan, billing or team settings."
      }
    },
    revokeModal: {
      title: "Are you sure you want to remove this invite",
      cancelLabel: "No don't remove",
      okLabel: "Yes, remove invite"
    },
    resendInvite: "Resend invite",
    removeInvite: "Remove invite",
    inviteSent: "Invite sent",
    inviteResent: "Invite resent",
    makeActive: "Make active",
    makeInactive: "Make inactive",
    inactive: "Inactive",
    makeUserInactiveQuestion:
      "Are you sure you want to make this user inactive?",
    makeUserActiveQuestion: "Are you sure you want to make this user active?",
    inactiveUsersCannotLogIn:
      "Inactive users cannot log in or use the browser extension",
    yesMakeInactive: "Yes, make inactive",
    dontMakeInactive: "No, don't make inactive",
    yesMakeActive: "Yes, make active",
    dontMakeActive: "No, don't make active",
    licensesModal: {
      allOfYourUser: "All of your user licenses have been allocated",
      toInviteSomeone:
        "To invite someone else you'll need to increase the number of users on your plan, remove an invited user or make a user inactive.",
      contactUs: "Contact us to purchase user licenses",
      backToTeam: "Back to team page"
    }
  },
  registerUser: {
    invalid: {
      base: "Invalid invite link"
    },
    complete: {
      accountCreated: "Your account has been successfully created"
    }
  },
  verifySearchFirm: {
    loading: "We're verifying your email address... "
  },
  signup: {
    signupForTalentis: "Sign up for your {freetrial} of Talentis",
    freeTrial: "free trial",
    noCreditCardRequired: "No credit card required.",
    alreadyGotAnAccount: "Already got an account",
    logIn: "Log In",
    verifyYourEmail:
      "Verify your email address to complete the sign up process",
    emailSentTo: "The verification email has been sent to ",
    accountHasNotBeenVerified: "Account has not been verified.",
    emailAddressHasAlreadyBeenInvitedToATeam:
      "Email address has already been invited to a team.",
    resendVerificationEmail: "Resend verification email",
    resendInvitationEmail: "Resend me my invitation email",
    invitationEmailHasBeenSent: "Invitation email has been sent.",
    verificationEmailHasBeenSent: "Verification email has been sent.",
    extension: {
      getReadyToUse: "Get ready to use Talentis...",
      installBrowserExtensionDescription:
        "You'll need to install the browser extension to use most of the features in Talentis...",
      installBrowserExtension: "Install the browser extension"
    },
    bySigningYouAgree: "By signing up you agree to our",
    termsOfUse: "terms of use",
    and: "and",
    privacyPolicy: "privacy policy"
  },
  search: {
    runSearch: "Run search",
    noContactsFound: "No contacts found",
    toCreateContacts:
      "To create contacts use the Talentis Chrome extension while viewing an online profile and add information to the person's record.",
    useTheSearch:
      " Use the search bar at the top of the page or visit a person's online profile to get started.",
    searchedPhrase: "Searched phrase",
    userguiding: {
      welcome: "Welcome to Talentis!",
      based: "Talentis is based around a very powerful search tool",
      augmented:
        "each of which is augmented with information about the organizations that the potential candidates work for",
      easy: "It’s really easy to use – but it’s also different to other products",
      next: "Next",
      letsBuildDesc: {
        letsbuild: "Let's build a search",
        letslook: "Let's look for a",
        salesleader: "sales leader",
        forA: "for a",
        semiConductor: "semiconductor company",
        with: "with",
        expSalesTeam: "experience of running an international sales team",
        international: "international sales"
      },
      accessing: "accessing more than 559M public profiles",
      team: "team",
      letsgo: "Let's start"
    },
    totalResults: "Total results",
    chatGPT: {
      beta: "BETA",
      buildYourSearch: "Build your search with ChatGPT!",
      whatAreYouLookingFor: "What are you looking for?",
      searchExplaination: "Enter one or two sentences to get started.",
      byUsingThisSearch:
        "*By using this feature you acknowledge that data you enter here is being transferred to ChatGPT and that this is legal in your jurisdiction.",
      workingMagic: "Working our magic - building your search!",
      searchIsReady: "Your initial search criteria is ready!",
      reviewAndMakeChanges: `Review and make any changes and then click`,
      toSeeCandidates: "to see your candidates!",
      runAgain: "Run search",
      startAgain: "Start again",
      exampleSearch: "Eg. VP Sales. Fintech.",
      notHappy: "Not happy? Rerun the query",
      similarPeopleAiError:
        "Unable to load similar people search criteria due to not enough information. Please try again or try another person.",
      errorMessage:
        "Sorry, we can’t quite work out what you want from the information provided, please give us a little more to work with."
    }
  },
  auth: {
    login: "Log in",
    logout: "Log out",
    signup: "Sign Up"
  },
  navigation: {
    links: {
      homepage: "Home",
      managePersons: "Manage People",
      addPerson: "Add Person",
      searchPerson: "Search Person",
      manageTeam: "Settings",
      addAssignment: "Assignment",
      listAssignment: "Assignments",
      listContacts: "Search contacts",
      companies: "Companies",
      search: "Global search",
      listCampaign: "Campaigns",
      addCampaign: "Campaign",
      reports: "Reports",
      support: "Help/Support",
      outlook: "Outlook"
    },
    actions: {
      settings: "Settings",
      profile: "Account",
      nn: "No Name",
      billing: "Billing"
    },
    helpers: {
      help: "Help",
      feedback: "Feedback",
      upgrade: "Upgrade plan"
    }
  },
  assignment: {
    title: "Manage Assignments",
    noResultFound: "No results found.",
    plural: "Assignments",
    addNew: "Assignment",
    actions: "Actions",
    createAssignment: "Create Assignment",
    addAnAssignment: "Add an assignment",
    addTeamMember: "Add team member",
    editAssignment: "Edit Assignment",
    roleDetails: "Role details",
    clientDetails: "Client details",
    assignmentTitle: "Assignment details",
    assignmentEditTitle: "Edit assignment",
    assignmentProfileName: "Assignment name",
    assignmentProfileStatus: "Status",
    assignmentProfileJobTitle: "Job title",
    assignmentProfileStartDate: "Start Date",
    assignmentProfileEndDate: "End Date",
    assignmentProfileCompany: "Company",
    assignmentGatedTalent: "Share with GatedTalent members",
    assignmentBrief: "Brief",
    assignmentPackage: "Package",
    assignmentNoPackage: "No package details",
    assignmentPackageComment: "Package comment",
    assignmentSalaryFrom: "Salary from",
    assignmentSalaryTo: "Salary to",
    assignmentPackageEdit: "Edit package",
    assignmentSalaryFromMonthly: "Salary from (annual)",
    assignmentSalaryToMonthly: "Salary to (annual)",
    assignmentCurrenry: "Currency",
    assignmentFeeEdit: "Edit financials",
    assignmentFinalFee: "Final fee",
    assignmentFeeTo: "Estimate fee to",
    assignmentFeeFrom: "Estimate fee from",
    assignmentFeeComment: "Fee comment",
    assignmentFeeEstimate: "Estimate fee",
    assignmentNoFee: "No financial details",
    assignmentFinancial: "Financial",
    assignmentFinancials: "Financials",
    assignmentTags: "Tags",
    addCandidateTags: "+ Tag",
    formerEmployees: "Former employees",
    currentEmployees: "Current employees",
    showAllAssignment: "Show all assignments",
    ShowOnlyMyAssignment: "Show only my assignments",
    assignmentTabHeaders: {
      assignmentDetails: "Assignment details",
      targetCompanies: "Target companies",
      candidates: "Candidates",
      notes: "Notes",
      documents: "Documents",
      tags: "Tags",
      clientPortal: "Client portal",
      outreachTemplates: "Outreach templates"
    },
    interviewStage: {
      all: "All candidates",
      identified: "Identified",
      source: "Source",
      screening: "Screening",
      internalInterview: "Internal interview",
      shortList: "Shortlist",
      firstClientInterview: "1st Interview",
      secondClientInterview: "2nd Interview",
      thirdClientInterview: "3rd Interview",
      offer: "Offer",
      placed: "Placed",
      archive: "Archive",
      clientInterview: "Client interview"
    },
    details: {
      title: "Assignment details",
      started: "Assignment started",
      actions: {
        addCandidate: "Add candidate",
        quickSearch: "Quick search",
        addNewCandidate: "Add new candidate",
        searchCandidates: "Search candidates",
        searchLocalDatabase: "From my database"
      },
      candidates: {
        title: "Candidates",
        filteredCount: "{0} of {1}",
        export: "Export",
        exportAll: "All",
        exportFilteredView: "Filtered View",
        exporting: "Exporting...",
        errorOccured: "Unable to download file. Please try again.",
        linkPeople:
          "Link people to this assignment to view them here and track their progress",
        useChromeExtension:
          "Using the Talentis Chrome extension is the easiest way to link people to assignments.",
        useSearchBar:
          "Use the search bar at the top of the page or visit a person's online profile to get started.",
        updating: "Updating",
        exportPrefix: "candidates",
        searchForCandidates: "Search for candidates",
        searchAI: "AI search",
        searchAIError: "Something went wrong, please try again.",
        advanceSearch: "Advanced search",
        addCandidateWhileBrowsingProfileOnline:
          "You can also add candidates to your assignment while viewing their online profile",
        howToLink: "How to link candidates to assignments",
        trialWarningTitle: "This feature is not available on Trial",
        trialWarningBody:
          "Sign up to one of our paid plans to access this feature.",
        trialWarningBotton: "Subscribe now",
        noCandidates: "Add your first candidate",
        quickSearch: "Quick search",
        quickSearchDescription:
          "Search public data optimized for name or company search",
        advancedSearch: "Advanced Search",
        advancedSearchDescription:
          "Search public data using augmented keywords - powerful experience search",
        searchAIDescription:
          "Linked your client to the TalentGraph? Let OpenAI get your search started!",
        myDataBaseSearch: "My database search",
        myDataBaseSearchDescription:
          "Search through your existing Talentis contacts"
      }
    },
    addCandidates: "Add candidates",
    listAssignment: "Assignments",
    tableColumns: {
      name: "Name",
      companyName: "Company",
      jobTitle: "Job title",
      location: "Location",
      startDate: "Start date",
      status: "Status"
    },
    statuses: {
      all: "All",
      lead: "Lead",
      opportunity: "Opportunity",
      active: "Active",
      onHold: "On hold",
      abandoned: "Abandoned",
      placed: "Placed"
    },
    statusLabels: {
      all: "All",
      lead: "Leads",
      opportunity: "Opportunities",
      active: "Active",
      onHold: "On hold",
      abandoned: "Abandoned",
      placed: "Placed"
    },
    similarPeople: {
      findSimilarPeople: "Find similar people",
      findSimilarPeopleUsingAi: "Find similar people using AI",
      findingSimilarPeople: "Finding similar people",
      selectCandidateToMatch: "Select a candidate to match from",
      notFound: "No similar people found",
      selectDifferentCandidate: "Select a different candidate to match from",
      selected: "{0} selected",
      selectAll: "Select all",
      moveSelected: "Move selected",
      stage: "Stage",
      addCandidates: "Add candidates to start matching",
      updatingStageFor: "Updating stage for",
      movedTo: "moved to",
      error: "An error occurred when moving {0} to {1}. Please try again.",
      seeSimilarPeople: "See similar people"
    },
    roleAtCompany: "{0} at {1}",
    filters: {
      filter: "Filter",
      status: "Status",
      stage: "Stage",
      assignedTo: "Assigned to",
      dueDate: "Due date",
      tagsCandidates: "Tags",
      dueDateOptions: {
        overdue: "Overdue",
        last7Days: "In last 7 days",
        yesterday: "Yesterday",
        today: "Today",
        tomorrow: "Tomorrow",
        next7Days: "In next 7 days",
        next14Days: "In next 14 days",
        next30Days: "In next 30 days",
        anyDate: "Any date",
        noDueDate: "No due date"
      }
    },
    searchLable: "Enter assignment name",
    searchButton: "Search assignment",
    addLinkedIn:
      "Add the LinkedIn URL for this company to link it to the TalentGraph and make the most of Talentis!",
    linkUsedBy: "This link is used by",
    wouldLink: "Would you like to link this assignment to",
    linkCorrect: "Invalid LinkedIn URL",
    linkExists: "This link already exists in another local company.",
    addLinkedInExample: "Example",
    linkCompanySuccess:
      "This company has been linked to the TalentGraph successfully.",
    assignmentTeam: "Assignment team",
    assignmentOwner: "Owner",
    assignmentMember: "Member",
    assignmentOwners: "Owners",
    assignmentMembers: "Members"
  },
  generic: {
    current: "Current",
    present: "Present",
    loading: "Content loading, please wait",
    returnToHomepage: "Return to homepage",
    unexpectedError:
      "An unexpected error occured. Try refreshing the page. If the error persists, please contact us!",
    save: "Save",
    submit: "Submit",
    cancel: "Cancel",
    yes: "Yes",
    noCancel: "No, Cancel",
    edit: "Edit",
    done: "Done",
    required: "Required",
    emailRequired: "Please provide a valid email address",
    chooseRole: "Choose a Role",
    add: "Add",
    close: "Close",
    datetime: "{0} at {1}",
    remove: "Remove",
    errorTryAgain: "Something went wrong. Please try again.",
    complete: "Complete",
    generalError: {
      title: "An error occurred",
      subTitle: "Please refresh the page to try again",
      text: "If the problem persist please "
    },
    contactSupport: "Contact Support",
    update: "Update",
    delete: "Delete",
    number: "Enter number"
  },
  googleSearch: {
    label: "Search via Google X-Ray",
    female: "Boost female contacts",
    searchOnGoogle: "Search the web"
  },
  supportForm: {
    title: "Contact support",
    tutorials: "Tutorials",
    tutorialTitles: {
      title1: "Introduction to assignments",
      title2: "Quick search in Talentis",
      title3: "Advanced search in Talentis",
      title4: "Using the Talentis Chrome extension",
      title5: "Introduction to People (contacts) records",
      title6: "Introduction to Company records",
      title7: "Task management in Talentis",
      title8: "Campaigns in Talentis",
      title9: "Managing assignments",
      title10: "Reporting using Talentis Word add-in",
      title11: "Managing emails Talentis Outlook add-in",
      title12: "Off limits in Talentis",
      title13: "Resume/CV Parsing",
      title14: "Linking a company to TalentGraph",
      title15: "Talentis Training Webinar (recording June 2024)",
      title16: "Contact search - search your database"
    },
    bottomTitle: "Get in touch",
    tutorialInfo:
      "These tutorial videos will guide you through different aspects of Talentis.",
    info: "Please send us your query and we will get back to you as soon as possible.",
    sentSuccess: "Your message was sent successfully!",
    placeholders: {
      name: "Name",
      company: "Company",
      email: "Email",
      phone: "Phone number (optional)",
      message: "Message*"
    },
    send: "Send",
    appendix: {
      emailUs: "Email us:",
      americas: "Americas:",
      ukEmea: "UK / EMEA:",
      auNz: "Australia / NZ:"
    }
  },
  browserExtension: {
    install: "Install the browser extension",
    findAndEngageWithCandidates:
      "find and engage with potential candidates across the web",
    worksBestInChrome:
      "Talentis works best in Chrome - use the browser extension and full site for the optimum experience."
  },
  subscription: {
    faqs: "FAQs",
    loadingPlans: "Updating prices",
    faq: {
      title: "FAQs",
      items: {
        upgradePlan: {
          question: "Can I upgrade to Connect later?",
          answer: "Yes you can upgrade at any time."
        },
        changeTeamSize: {
          question: "Can I change the team size later?",
          answer:
            "Yes, you can change the team size at any time. You'll be charged a pro-rated fee for adding new users. Any reduction in users will take effect at the end of your billing period."
        },
        paymentMethods: {
          question: "What payment methods do you accept?",
          answer: "We accept all major debit and credit cards in every country."
        },
        contractLength: {
          question: "How long is the contract?",
          answer: "You can choose from monthly or yearly contracts."
        }
      }
    },
    purchase: {
      upgradeYourPlan: "Upgrade your plan",
      freeTrialEnds: "Your free trial ends {0}",
      selectYourPlan: "Select your plan",
      selectPlan: "Select plan",
      planDetails: "Plan details",
      payByCard: "Pay by card",
      confirmPlan: "Confirm Plan",
      normalPrice: "Normal price {0}",
      choosePlan: {
        basic: "Choose Talentis",
        connect: "Choose Talentis Connect"
      },
      legalSubtext:
        "Prices are based on a yearly contract. Prices exclude any applicable taxes, these will be shown on the payment page. Plans are paid in advance. Plans automatically renew at standard pricing.",
      titleDeactivate:
        "Are you sure you want to deactivate your own user account?",
      cancelButton: "No, return to user list",
      confirmationButton: "Yes, deactivate my account",
      deactivateDescription:
        "Deactivating your account will result in losing access to the system.",
      pendingInvite: "Pending invites"
    },
    planDetails: {
      form: {
        totalNumberOfUsers: "Total number of users",
        currentNumberOfUsers: "current number of users",
        currency: "Currency",
        billingFrequency: "Billing frequency",
        yearlyAmount: "Yearly - {0}",
        monthlyAmount: "Monthly - {0}",
        perUserPerMonth: "per user, per month",
        wasAmount: "Was {0}",
        continueToBillingInformation: "Continue to billing information",
        continueToPayment: "Continue to payment",
        forMoreThan: "For more than 50 users please",
        contactUs: "contact us",
        soWeCanTailor: "so we can tailor the plan to your needs."
      },
      legalSubtext:
        "Prices exclude any applicable taxes, these will be shown on the payment page. Plans are paid in advance. Plans automatically renew at standard pricing."
    },
    plans: {
      basic: "Talentis",
      connect: "Talentis Connect"
    },
    offers: {
      launch: {
        title: "Launch offer",
        description:
          "Sign-up to an annual subscription before the end of May 2021 and benefit from significant savings"
      },
      july2021: {
        title: "Upgrade in July to receive 25% off",
        description: "Add your promo code on the payment page:",
        yearlyCode: "Yearly plan: ",
        monthlyCode: "Monthly plan: ",
        subtext:
          "Annual package requires up front payment. Monthly package is a monthly payment and may be cancelled at any time. After 12 months, normal price applies."
      },
      august2021: {
        title: "Upgrade now to receive 15% off"
      }
    },
    paymentDetails: {
      billingAddress: "Billing Address",
      cardDetails: "Card details",
      nameOnCard: "Name on card",
      cardNumber: "Card number",
      expiryDate: "Expiry date (MM/YY)",
      securityCode: "Security code (CVV)",
      billingInformation: "Billing information",
      addressFirstLine: "1st line of address",
      addressCity: "City",
      addressPostalCode: "ZIP / Postal code",
      phone: "Phone (optional - for billing only)",
      vatNumber: "VAT number (optional)",
      agreeTo: "Agree to",
      termsAndConditions: "Terms and Conditions",
      termsAndConditionsError: "You must agree to the Terms and Conditions",
      purchase: "Purchase plan",
      byConfirming:
        "By confirming your purchase, you allow Talentis to charge your card for this payment and future payments when your plan renews.",
      loading: "Processing card details, please wait",
      loadingPlanUpdate: "Upgrading plan, please wait",
      legalSubtext:
        "Plans are paid in advance. Plans automatically renew at standard pricing."
    },
    promoCode: {
      promoCode: "Promo code",
      promoCodeAdded: "Promo code added",
      promoCodeError: "Invalid promo code, please try again.",
      promo: "Promo"
    },
    orderSummary: {
      totalCostOfPlan: "Total cost of plan",
      user: "user",
      users: "users",
      oneMonth: "1 month",
      twelveMonths: "12 months",
      taxes: "Taxes",
      discount: "Discount"
    },
    orderSuccess: {
      paymentSuccessful: "Payment successful",
      planUpdatedSuccessful: "Plan confirmed",
      youHavePurchased: "You've purchased:",
      planUpdated: "Your Plan:"
    },
    paymentError: {
      invalidVAT: "Invalid VAT number",
      paymentFailed: "Payment failed",
      addressFailed: "Oops...",
      thereIsAnIssue:
        "There is an issue processing your payment. Please try a different card or contact your bank/card issuer."
    },
    exitPrompt: {
      title: "Are you sure you want to exit your upgrade?",
      subtitle:
        "Your details will not be saved and you'll need to start the process again.",
      exit: "Yes, exit upgrade",
      continue: "No, continue with upgrade"
    },
    features: {
      unlimitedAccess: "Unlimited access to the Talentis TalentGraph",
      contactCreditsAvailableForPurchase:
        "Contact Credits available for purchase",
      everythingInTheTalentisPlan: "Everything in the Talentis Plan",
      contactCreditsIncluded: "{0} Contact Credits included",
      per: "per",
      user: "user",
      perMonth: "per month",
      significantDiscountOnAdditional:
        "Significant discount on additional Contact Credits compared to Talentis Plan",
      purchaseMoreContactCredits: "Ability to purchase more contact credits"
    },
    currencies: {
      usd: "US dollar",
      gbp: "GBP",
      eur: "Euro",
      aud: "AUD",
      pln: "Polish Zloty"
    },
    removeUserPartOne: "Please deselect users and invites that.",
    removeUserPartTwo: "you do not wish to keep on your subscription"
  },
  homepage: {
    welcome: "Welcome to Talentis",
    loveToHearYourOpinion: "We'd love to hear what you think of Talentis",
    tellUsAboutYourExperience:
      "Tell us about your experience and what can be improved",
    seeOnDashboard: "What would you like to see on your dashboard?",
    provideFeedback: "Provide feedback",
    completeOurSurvey: "Complete our survey",
    noActiveAssignments: "No active assignments",
    addAssignment: "Add an assignment",
    viewAllAssignments: "View all assignments",
    activeAssignments: "Active assignments"
  },
  seo: {
    title: "{0} | Talentis – Recruitment software",
    assignments: "Assignments",
    contacts: "Contacts",
    team: "Team",
    search: "Search",
    addNewProfile: "Add new profile",
    addNewContact: "Add new contact",
    addNewAssignment: "Add new assignment",
    createAssignment: "Create assignment",
    signUp: "Sign up",
    inviteUser: "Invite user",
    register: "Register",
    dashboard: "Dashboard",
    campaigns: "Campaigns",
    addNewCampaigns: "Add new campaign",
    internalSearch: "Internal search"
  },
  talentGraphSearch: {
    title: "Global search - search the TalentGraph",
    searchCriteria: "Search criteria",
    yearsInCurrentPos: "Years in current position",
    yearsTotal: "Years of experience",
    filters: {
      sector: "Sector",
      jobTitle: "Job title",
      keywords: "Keywords",
      excludeKeyword: "Exclude keywords",
      location: "Location",
      distance: "Distance",
      from: "from",
      to: "to",
      unit: "Unit",
      boardExperience: "Board director experience (independent non-exec)",
      boostFemale: "Boost female contacts",
      likelyToMove: "May be more open to recruiter contact",
      company: "Company",
      companySize: "Company Size",
      diversity: "Diversity options",
      anonymize: "Anon. search results"
    },
    companySize: {
      verySmall: "Very Small",
      small: "Small",
      medium: "Medium",
      large: "Large",
      veryLarge: "Very Large"
    },
    distanceUnits: {
      KM: "km",
      Miles: "miles"
    },
    comingSoon: "Coming soon!",
    additionalSearchCriteria: "Additional search criteria",
    experience: "{0} at {1}",
    present: "Present",
    noEmloymentHistory: "No employment history available",
    loadingCandidates: "Loading candidates",
    findingMatches: "Finding matches, please wait",
    foundResultsFromTalentGraph: "{0} results from the TalentGraph",
    foundResults: "{0} results",
    updatingPersonAssignment: "Adding person to an assignment",
    updatingPersonCampaign: "Adding person to a campaign",
    selectAssignment: "Select assignment",
    selectAssignmentToLink: "Select assignment to link this person",
    linkToAssignment: "Link to assignment",
    linkedTo: "Linked to {0}",
    selectCampaign: "Select campaign",
    createNew: "Create new {0}",
    selectCampaignToLink: "Select campaign to link this person",
    linkToCampaign: "Link to campaign",
    errors: {
      jobTitleLength: "Must be no longer than 50 characters",
      keywordLength: "Keyword must be at most 50 characters",
      locationLength: "Town, City or State must be at most 50 characters",
      countryLength: "Country must be at most 50 characters",
      limitsError: {
        searchJobTitle:
          "There is a limit to {0} job titles can be entered and searched",
        searchCountry:
          "There is a limit to {0} countries can be entered and searched",
        searchLocation:
          "There is a limit to {0} towns, cities, states can be entered and searched",
        searchKeyword:
          "There is a limit to {0} keywords can be entered and searched",
        searchCompany:
          "There is a limit to {0} companies can be entered and searched",
        boardLevelExperience: "",
        likelyToMove: "",
        locationDistance: "",
        locationDistanceUnit: "",
        keywordsSearchUsingORLogic: "",
        searchCurrent: "",
        searchJobTitleLogic: "",
        searchCompanyLogic: "",
        searchCompanySizeLogic: "",
        default: "There is a limit to {0} things can be entered and searched"
      }
    },
    placeholders: {
      jobTitle: "Type job title and press enter",
      keywords: "Type keywords and press enter",
      companyNames: "Type company name and press enter"
    },
    noResults: "No results match your criteria.",
    pleaseEditCriteria:
      "Please edit your criteria and search again, or try one of the methods below.",
    powerful: "Talentis search is powerful - Learn how to use it!",
    takeTour: "Take a quick tour",
    clearAll: "Clear all",
    keywordSearchFieldSelector: {
      label: "Search in:",
      person: "Person's information",
      company: "Company information"
    },
    keywordLogicSelector: {
      label: "Find profiles that mention:",
      atLeastOne: "At least one keyword (OR)",
      all: "All keywords (AND)",
      excludeAll: "Exclude all"
    },
    keywordRolesLogicSelector: {
      label: "Roles to search:",
      searchLogic: {
        current: "Current",
        past: "Past",
        either: "Either"
      }
    },
    viewResults: "View results",
    reloadSearch: "Reload last search",
    searchLogic: {
      current: "Current",
      past: "Past",
      either: "Either"
    },
    findMore: {
      label: "Find more candidates",
      useThe: "Use the",
      talentisBrowserExtension: "Talentis browser extension",
      toLinkPeople:
        "to link people to your assignment when searching on other sites",
      useOtherMethods:
        "Alternatively, try our other methods for finding candidates",
      findPeopleOnPopularSites:
        "Find people on popular sites, then use the Talentis browser extension to link them to your assignment",
      searchTheWeb: "Search the web",
      searchLinkedIn: "Search LinkedIn"
    },
    logic: {
      and: "AND",
      or: "OR"
    },
    addKeywordField: "Add keyword field",
    addJobTitleField: "Add job title field",
    experienceTalentisSearch: {
      title: "Experience Talentis Search",
      description:
        "Talentis search is designed to find the candidates that other search tools miss. Use the filters on the left to build your own search or check out the same searches and videos below to see how we built them. Stuck?",
      urlBook: " Book a session ",
      descriptionEnd: "with one of our team.",
      examples: {
        product: {
          title: "Product leadership in Fintech and Payments",
          description:
            "An example of how “company descriptions” allows you to find candidates you’ll miss on LinkedIn",
          url: "https://vimeo.com/754708431/1a0f2e9e43 "
        },
        selling: {
          title: "Selling into Boeing",
          description:
            "Searching job titles to find people with specific product or company experience.",
          url: "https://vimeo.com/735361504/4a97ac0a56"
        },
        ceos: {
          title: "CEOs with a financial background working in consumer goods",
          description:
            "Bringing it all together by combining current and previous job titles with sector expertise",
          url: "https://vimeo.com/754711535/951389ad60"
        }
      }
    },
    talentisSearch: {
      title: "Get the most out of Talentis Search",
      description: 'Watch this 4 minute "how to" guide!'
    },
    savedSearch: {
      load: "Load saved searches",
      save: "Save this search",
      saveSearch: "Save your search",
      defaultSearchScreen: "Save as default search screen",
      setDefaultSearchScreen: "Set as default search screen",
      removeDefaultSearchScreen: "Remove default status for search screen",
      defaultSearch: "Save as default search",
      defaultSearchTitle: "Default search",
      defaultSearchChangers: "Save as default for Job Changers Dashboard",
      removeDefaultSearchChangers: "Remove default status for dashboard",
      defaultSearchChangersTitle: "Default for Changers Dashboard",
      savedSearchData: "Created date:",
      loadedSearches: "Your saved searches",
      removeSearchTitle: "Are you sure you want to delete this search?",
      confirmButtonLabel: "Yes, remove search",
      cancelButtonLabel: "No, don't remove"
    },
    candidateDetails: {
      assignment: "Assignments",
      shortlisted: "Shortlisted",
      placed: "Placed",
      campaign: "Campaigns",
      team: "Team"
    },
    projectSelector: {
      projectSwitch: {
        assignment: "Assignment",
        campaign: "Campaign"
      },
      projectModal: {
        save: "Save",
        create: `Create {0}`
      }
    },
    findAlternativeUrls: "Find alternative URLs",
    searchKeywordTitle: "Keyword searching",
    searchKeywordParagraphFirst: "In Talentis,",
    searchKeywordParagraphSecond: " there is no need to type ANDs and ORs",
    searchKeywordHint: `Simply hit return after each keyword or keyphrase and select the "AND" or "OR" option directly underneath.`,
    searchKeywordsExampleAnd: `"Fintech AND Financial Services"`,
    searchKeywordsExampleOr: `"Fintech OR Financial Services"`,
    searchKeywordsVideo: "Please watch the video for more details.",
    searchJobtitlesTitle: "Job title searching",
    searchJobtitlesPartSecond:
      "there is no need to type ANDs, ORs, brackets or speech marks (double quotes)",
    searchJobtitlesPartThird:
      "Simply hit return after each job title, it is automatically an OR between the job titles.",
    searchJobtitlesPartForth:
      "You can add additional job title box to extend your search query.",
    searchJobTitlesExample:
      "Here is an example on how to build a search. Looking for brand manager or brand director or marketing manager or marketing director.",
    searchJobTitlesHitOne:
      "Type the word Brand, hit return and type the word Marketing",
    searchJobTitlesHitSecond:
      "Click on the + Add job title field and select AND to extend your search",
    searchJobTitlesHitThird:
      "Type the word Manager, hit return and type the word Director",
    searchJobTitlesExampleAndOr:
      "(Brand OR Marketing) AND (Manager OR Director)",
    searchJobTitlesQuery: "You have now build your search query:",
    gotIt: "Got it",
    gt2Talent: {
      modalTitle: "'s GatedTalent information",
      notVerified:
        "This information is provided by a GatedTalent user, it is unverified and may not be accurate. Please mention GatedTalent should you contact the candidate.",
      verified:
        "This information is provided by a GatedTalent user who has validated ownership of their LinkedIn profile and opted-in to share their details with Talentis users. Please mention GatedTalent should you contact the candidate.",
      availableResume: "This user has made their resume available.",
      clickHere: "Click here ",
      toView: "to view it.",
      sharedInfo: "This user has shared the following contact details:"
    }
  },
  notPermission: {
    youDontHave: "You don't have permisson to access this page.",
    toGainAccess:
      "To gain access an Owner or Manager user in your team needs to change your role."
  },
  reports: {
    title: "Reports",
    nextGeneration: "Next Generation Reporting in Word",
    openWord: "Open Word, go to the",
    insert: "Insert",
    tabAnd: "tab and select",
    getAddins: "Get Add-ins",
    seeImage: "see Image",
    image: "Image",
    willAppear: "The Store pane will appear",
    search: "Search",
    talentis: "Talentis",
    inThe: "In the",
    add: "Add",
    talentisAddInWord: "Talentis Add-In for Word",
    selectThe: "Select the",
    andClick: "and click",
    talentisAddIn: "Talentis Add-In",
    boxSearchFor: "box search for",
    youWillSee: "You will see the",
    appearOnRibbon: "appear on your Word ribbon",
    install:
      "Talentis Add-in is compatible with Microsoft Word on Windows and Mac, both web and desktop app on all versions from Word 2021 and Office 365 onwards.",
    youCanGet: "You can get Talentis Add-in directly from the",
    storeOnline: "Microsoft Office Store online",
    orFromWithin: "or from within Word.",
    hereIsHow: "Here is how you install Talentis Add-in from within Word ",
    compat:
      "Talentis is compatible with Microsoft Word on Windows, Mac and the\n" +
      "            web and supports all versions from Word 2013 onwards. Users may\n" +
      "            install the Talentis add-in directly from the Microsoft Word Store.\n" +
      "            We are currently working with Microsoft to validate and deploy our\n" +
      "            add-in into the Office Store, and will update this page as soon as\n" +
      "            the Add-In has been made available.",
    availNow: "Office 365 Users with Admin access (available now):",
    businessAcc:
      "If you have an Office 365 business account and manage your own\n" +
      "            tenant, you can login to the admin centre and deploy the manifest\n" +
      "            file to your whole organisation or to specific users or groups. For\n" +
      "            more information, please refer to the Microsoft documentation:",
    launch: "We will soon be launching Talentis reporting.",
    manifest: "The Talentis manifest is located at:",
    support: "For help and support, please contact",
    notUsing: "Not using Office 365?",
    coming: "Talentis is coming soon to the Microsoft Word Store.",
    watchThis: "Watch this space!",
    generate: "Generate client reports directly from Word.",
    fast: "Fast, efficient, beautiful.",
    watch: "Watch this space!"
  },
  billing: {
    title: "Plan and billing",
    currentPlan: {
      title: "Current plan",
      numberOfUsers: "{0} users",
      usedUsers: "Using {0} of {1}",
      manageTeam: "Manage team",
      billed: {
        year: "Billed yearly",
        month: "Billed monthly"
      },
      renewalDate: "Renewal and payment date {0}",
      renewalCost:
        "Renewal cost {0} ({1} per user, per month, excluding any applicable taxes).",
      freeTrial: "Free trial",
      endDate: "Ends {0}",
      daysRemaining: "{0} days remaining",
      afterYourTrial:
        "After your free trial ends you won't be able to use Talentis unless you upgrade to a paid plan.",
      dataDeleted: "Your data will be deleted after 30 days.",
      expiredSubscription: "Your subscription has expired."
    },
    updateBillingCTA: {
      title: "Want to change your plan?",
      body: "Contact us to change or cancel your plan, add or reduce users, or purchase contact credits.",
      us: "Americas:",
      uk: "UK / EMEA:",
      australia: "Australia / NZ:",
      emailUs: "Email us"
    },
    contactCredits: {
      title: "Contact credits",
      remaining: "Credits available",
      expiring: "Expiring credits",
      expiringInfo: "(Credits nearest to expiry are used first)",
      validUntil: "valid until"
    }
  },
  clientPortal: {
    label: "Client portal",
    create: "Create client portal",
    edit: "Edit client portal",
    editingMode: "You are editing the client view for an assignment",
    close: "Close client view",
    share: "Share",
    addLogo: "Add your logo",
    addClientLogo: "Add client logo",
    candidates: "Candidates",
    showAll: "Show all in client view",
    show: "Show in client view",
    selectedCandidates: "{0} of {1}",
    access: {
      copyTheUniqueAccess:
        "Copy the unique access details for your client contact from the list below. These details only allow access to this report.",
      sharingWithYourClientContact: "Sharing with your client contact",
      clientContactsWithAccess: "Client contacts with access",
      clientContact: "Client contact",
      copyAccessDetails: "Copy access details",
      reportShareSettings: "Client report share settings",
      clientContactEmailAccess: "Client contact's email address",
      createAccessDetails: "Create access details",
      onlyUnique:
        "A unique username and password will be generated for this email address, which can only be used to view this report.",
      youCanRemove:
        "You can remove access at any time. The password can be reset by your client from the log in screen.",
      removeAccess: "Remove access"
    },
    notes: {
      title: "Notes",
      selectNoteFor: "Select a note to show for {0}",
      newNote: "New note",
      saveChanges: "Save changes",
      dontShow: "Don't show a note",
      editNote: "Edit note",
      addNote: "Add note",
      for: "for",
      emailAttactment: "Email Attachment"
    },
    commentary: {
      title: "Commentary",
      addCommentary: "Add commentary",
      editCommentary: "Edit commentary"
    }
  },
  invitation: {
    sendInvites: "Send invites"
  },
  pageNotFound: {
    fourZeroFour: "404",
    pageNotFound: "Page Not Found"
  },
  companiesSearch: {
    title: "Companies",
    lookUpOrCreate: `Lookup or create a company from here`,
    ifYouCreate: `If you create a company, be
    sure to link it to the TalentGraph to take advantage of the ability
    of Talentis to auto populate company and employee information and to
    power the AI search functionality!`,
    createNewCompany: "Create new company",
    cantFindCompany: " Can't find company?",
    linkTalentEx: "Link to Talentis example",
    noTargetCompanies:
      "No target companies found. Add candidates and we will build a list automatically.",
    targetCompanies: "Target companies",
    noCompanyCandidate: "No candidates for this company"
  },
  support: {
    title: "Help/Support"
  },
  campaign: {
    title: "Campaign",
    plural: "Campaigns",
    addCampaign: "Add a campaign",
    campaignDetails: "Campaign details",
    campaignContacts: "Contacts",
    campaignNotes: "Notes",
    campaignTags: "Tags",
    documents: "Documents",
    campaignName: "Campaign name",
    campaignDescription: "Description",
    campaingFormValidation: "Campaign name is a required field.",
    statuses: {
      active: "Active",
      closed: "Closed"
    },
    details: {
      action: {
        addContact: "Add contact"
      },
      started: "Start date",
      contacts: {
        title: "Contacts",
        updating: "Updating"
      },
      searchForContacts: "Search for contacts",
      noContacts: "Add your first contact",
      quickSearch: "Quick search",
      quickSearchDescription:
        "Search public data optimized for name or company search",
      advancedSearch: "Advanced Search",
      advancedSearchDescription:
        "Search public data using augmented keywords - powerful experience search",
      viaSearch: "You can add contacts via search",
      exportPrefix: "contacts"
    },
    description: "Description",
    startDate: "Start date",
    campaignStatus: "Status",
    addNewNote: "Campaign",
    noResults: "No results found.",
    searchCampaign: "Search campaign",
    searchInput: "Enter campaign name",
    campaignEditTitle: "Edit campaign",
    campaignTeam: "Campaign team",
    campaignOwner: "Owner",
    campaignMember: "Member",
    campaignOwners: "Owners",
    campaignMembers: "Members"
  },
  tags: {
    editTags: "You can rename tags by clicking on the color."
  },
  project: {
    projectTemplatesAI: "Outreach templates",
    filters: {
      filter: "Filter",
      status: "Status",
      stage: "Stage",
      assignedTo: "Assigned to",
      dueDate: "Due date",
      tagsCandidates: "Tags",
      dueDateOptions: {
        overdue: "Overdue",
        last7Days: "In last 7 days",
        yesterday: "Yesterday",
        today: "Today",
        tomorrow: "Tomorrow",
        next7Days: "In next 7 days",
        next14Days: "In next 14 days",
        next30Days: "In next 30 days",
        anyDate: "Any date",
        noDueDate: "No due date"
      },
      noResultsFoundTitle: "No matching results found.",
      noResultsFoundText: "Please remove or change your filter."
    },
    filteredCount: "{0} of {1}",
    noTags: "[no tags]",
    outReach: {
      informal: "Informal",
      formal: "Formal",
      gptTitle: "Create your outreach templates with ChatGPT!",
      coreMessage: "Core message",
      coreMessageAssignmentPH:
        "We are searching for a CFO candidate for a retailer and would like to introduce ourselves to potential candidates.",
      coreMessageCampaignPH:
        "XYZ Search is an executive search firm specializing in fintech. We'd like to introduce ourselves to potential clients.",
      coreMessageExplain:
        "Please input the core message of your project and this will be used in the creation of your customized templates.",
      coreMessageError: "You need to enter a Core message for your company.",
      gdprTemplates: "GDPR templates",
      aiTemplates: "AI templates",
      gptDisclaimer: `*By using this feature you acknowledge that data you
      enter here is being transferred to ChatGPT and that this is legal
      in your jurisdiction.`,
      copied: "Copied!",
      edit: "Edit",
      comingSoon: "Coming soon",
      companySoon: "The company dashboard.",
      comingSoonMessage:
        "Access your templates from Outlook, Chrome extension and bulk email tools.",
      gdprDisclaimer: `*These templates are provided as approximate guidance
only. Under the GDPR and other relevant data protection
legislation you have legal responsibilities for the
information you supply to data subjects and you should
consult your own legal advisors for legal advice specific
to your own business practice and circumstances.
Dillistone Group and its subsidiaries accept no liability of any sort
arising from the use of these templates.`,
      defaultConsentTemplate: `Dear [FORENAME] 

We are retained by organisations to identify and place individuals into new employment opportunities.  We have identified you as an individual that could be of interest to our clients.

We take your privacy very seriously and in order for us to keep you informed about relevant opportunities, we request your consent to [continue to] hold your personal data.  If you would like to know more about current suitable opportunities, then please contact us on [USPHONE].

If you do not formally provide consent by completing this process, we will be legally obliged to remove you from our database and can no longer put you forward for such opportunities.  To aid this process, and make it as clear for you as possible, please review your options below.

We hope you allow us to hold your data and look forward to establishing a professional and prosperous relationship with you.

Option 1:
Yes, I consent to your processing of my personal data for the purpose of finding and enabling career opportunities for me. I understand you will hold my data under this basis for [retention period] months and process in accordance with your privacy policy*.  I understand I can withdraw my consent at any time in which case my data will be appropriately deleted.

[*Could be an attachment or hyperlink.] 

Option 2: 
(If you do not respond within 30 days this is what will happen.)
No thank you. I do not consent to you holding my data and processing it. I understand that my data will be appropriately deleted and that I will not receive notifications of any further career opportunities. 


Please select the link below for the appropriate option you wish to choose which will open a pre-populated email to us. Please send this email to us so we can process your request. Note that if you do nothing we will legally have to proceed as if you had selected option 2 and delete your data. 

Option 1: [Insert opt-in hyperlink]

Option 2: [Insert opt-out hyperlink]

Yours faithfully,

[USFULLNAME]
[USJOBTITLE]
Tel: [USPHONE]
`,
      defaultLegitimateInterestTemplate: `Dear [FORENAME»

  We are retained by organisations to identify and place individuals into new employment opportunities.  We have identified you as an individual that could be of interest to our clients.
  
  We take your privacy very seriously and in order for us to keep you informed about relevant opportunities, we need to hold some relevant information about you.  We will not hold your data for any other purpose than assisting you with future career opportunities.
  
  We want to develop and maintain a trusted relationship with you.  To learn more about what type of data we hold and how we ensure it is protected and secured, please click on the link below to view our Privacy Policy*.  We wish to hold your data on the grounds of Legitimate Interest and you do have the right to object to this. If you do not wish to work with us we will, of course, permanently delete your data. To aid this process, and make it as clear for you as possible, please review your options below.
  
  [Link to privacy policy or attach to email]
  
  We hope you allow us to hold your data and look forward to establishing a professional and prosperous relationship with you.
  
  Option 1: (If you do not select one of the options this is what will happen.)
  Yes, I understand you will process my personal data for the purpose of finding and enabling career opportunities for me. I understand you will hold my data under this basis and process it as per your privacy policy. I understand I can object to processing at any time in which case my data will be appropriately deleted.
  
  [*Could hyperlink]
  
  Option 2:
  No thank you. I object to you holding my data and processing it. I understand that my data will be appropriately deleted and that I will not receive notifications of any further career opportunities. 
  
  Please select the link below for the appropriate option you wish to choose which will open a pre-populated email to us. Please send this email to us so we can process your request. Note that if you do nothing we will assume you do not object to our processing of your data as per option 1.
  
  Option 1: [Insert opt-in hyperlink]

  Option 2: [Insert opt-out hyperlink]
  
  Yours faithfully,
  
  [USFULLNAME]
  [USJOBTITLE]
  Tel: [USPHONE] 
  `,
      templateNames: {
        connectionRequest: "Connection request template",
        linkedinMessage: "LinkedIn message template",
        introductoryEmail: "Introductory email template",
        liNotification: "GDPR legitimate interest notification",
        consentNotification: "GDPR consent notification"
      }
    }
  },
  taskManagement: {
    title: "Task management",
    noTask: "No tasks",
    noTaskText: "You don't have any tasks for {0}",
    dates: {
      overdue: "Overdue",
      yesterday: "Yesterday",
      today: "Today",
      tomorrow: "Tomorrow",
      future: "Future"
    }
  },
  outlook: {
    title: "Outlook",
    description:
      "Access and manage your Talentis contacts in Outlook with the new Talentis Add-in",
    install:
      "The Talentis Add-in for Outlook is compatible with Microsoft Outlook on Windows and Mac and with Office 365 on both the web and desktop apps.",
    youCanGetPart1: "You can install the Talentis Add-in directly from the ",
    link: "Microsoft Office Store online ",
    youCanGetPart2: "or from within Outlook.",
    list: "Here is how you install Add-in from within Outlook",
    in: "In ",
    outlook: "Outlook",
    goTo: ", go to the",
    home: "Home ",
    tab: "tab on the ribbon and click on ",
    getAddIn: "Get Add-ins",
    addInDialog: "The Add-in dialog will appear.",
    inThe: "In the ",
    search: "Search ",
    box: "box,search for ",
    talentis: "Talentis",
    select: "Select the ",
    talentisAddIn: "Talentis Add-in for Outlook ",
    and: "and click ",
    add: "Add",
    see: "You will see the the ",
    talentisAdd: "Talentis Add-in ",
    appear: "appear on your Outlook ribbon.",
    goToEmail:
      'Go to any email in your inbox, and "pin" Talentis to keep it open.',
    explanation:
      "Phase 1 of our Talentis Add-in for Outlook works with incoming emails only and allows you to add candidates and add or update actions, progress and notes from inside Outlook. We will soon add functionality to log incoming emails, following by similar functionality for outgoing messages."
  },
  companies: {
    companyInfo: {
      about: "About",
      sector: "Sector",
      companySize: "Company size",
      companyFound: "Founded",
      companyHeadquarters: "Headquarters",
      companyEmployees: "employees",
      assignments: "Assignments",
      knownEmployees: "Known employees",
      noKnownEmployees: "No known employees for this company",
      speciality: "Specialties",
      documents: "Documents",
      size: {
        verySmall: "Very small",
        small: "Small",
        medium: "Medium",
        large: "Large",
        veryLarge: "Very large",
        unknown: "Unknown"
      },
      companyName: "Company name"
    },
    companyDetails: "Company details",
    companyTeamMembers: "Company team",
    companyDetailsEdit: "Edit company details",
    companyAddSpeciality: "Add specialties"
  },
  offLimits: {
    offLimits: "Off limits",
    offLimitsEndDate: "Off limits end date",
    offLimitsComment: "Off limits comment",
    offLimitsNoSet: "Not set",
    offLimitsEdit: "Edit off limits",
    removeDate: "Remove date",
    lastComment: "Last edited ",
    inheritedCompany: "Inherited company off limits",
    inheritedCompanyEditMode: "Inherited company off limits edit mode",
    override: "Override",
    overrideSet: "Override set by ",
    personOffLimits: "Person off limits"
  },
  aiBio: {
    title: "Generate biography with AI",
    generateBio: "Generate biography",
    generatedBio: "Generated biography",
    bioSettings: "Biography settings",
    overviewDescription: `Please make your edits in the space provided, then click
    the save, to apply your changes.`,
    words: "words",
    approxLength: "Approximate response length:",
    byUsing:
      "*By using this feature, you acknowledge you are sending data to OpenAI (ChatGTP) and that this is legal in your jurisdiction. Updated biographies may optionally be synced back to Talentis.",
    featureDescription:
      "This feature will replace the biography for this person.",
    newGeneratedBio: "AI generated biography",
    saveNewBio: "Save new biography",
    regenerateBio: "Regenerate biography",
    loadingMessage: "Generating new biography",
    generateWithAI: "Generate biography with AI"
  },
  internalSearch: {
    buildYourSearch: "Build your search",
    startNewSearch: "Start new search",
    loadSavedSearch: " Load saved search",
    saveSearch: "Save this search",
    title: "Search your Talentis records",
    foundResults: "Found {0} results matching your criteria",
    pleaseEditCriteria: "Please edit your criteria and search again.",
    manyFiltersComing: "Many more search filters!",
    selectAssignmentToLinkOne: "Select assignment to link ",
    selectAssignmentToLinkTwo: "this person",
    selectCampaignToLinkOne: "Select campaign to link ",
    selectCampaignToLinkTwo: "this person"
  }
}
export default strings
