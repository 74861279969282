import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useAuth } from "setup/auth/module/auth.context"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import { RouterUrl } from "../routes"

export const subscriptionRequired = (Component: () => JSX.Element) => {
  return () => {
    const { user } = useAuth()
    const { getTalentisUser } = useTalentisUser()
    const navigate = useNavigate()
    const location = useLocation()

    const isSubscriptionExpired = user?.isSubscriptionExpired === true

    useEffect(() => {
      const checkIsSubscriptionExpired = async () => {
        const talentisUser = await getTalentisUser()
        const isUserOwner = talentisUser?.userRole === UserRoles.Admin
        const isUserOnSubscriptionPage =
          location.pathname === RouterUrl.Subscription

        isUserOwner &&
          !isUserOnSubscriptionPage &&
          navigate(RouterUrl.Subscription)
      }
      isSubscriptionExpired && checkIsSubscriptionExpired()
    }, [isSubscriptionExpired, navigate, location.pathname, getTalentisUser])

    return <Component />
  }
}
