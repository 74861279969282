import React from "react"
import { messages } from "setup/messages/messages"
import { Route, useResolvedPath, Routes } from "react-router-dom"
import { RouterSubUrl, RouterUrl } from "setup/router/routes"
import { Navigation } from "components/visual/navigation/navigation.styled"
import { NavigationItem } from "components/visual/navigationItem/navigationItem.styled"
import { RouterLink } from "components/RouterLink/RouterLink"
import { CreateCampaign } from "views/campaigns/pages/create-campaign/CreateCampaign"
import { ListCampaign } from "views/campaigns/pages/list-campaign/ListCampaign"
import { Helmet } from "react-helmet"
import { CampaignDetailsWrapper } from "./pages/campaign-details/CampaignDetailsWrapper"

export const Campaigns = () => {
  const path = useResolvedPath("").pathname
  return (
    <Routes>
      <Route
        path={path}
        element={
          <>
            <h1>{messages.campaign.title}</h1>
            <Navigation>
              <NavigationItem>
                <RouterLink
                  data-e2e-target="link"
                  data-e2e-target-name="campaign"
                  to={RouterUrl.CampaignList}
                >
                  {messages.navigation.links.addCampaign}
                </RouterLink>
              </NavigationItem>
            </Navigation>
          </>
        }
      />

      <Route
        path={RouterSubUrl.CampaignCreate}
        element={
          <>
            <Helmet>
              <title>
                {messages.seo.title.format(messages.seo.addNewAssignment)}
              </title>
            </Helmet>
            <CreateCampaign />
          </>
        }
      />
      <Route
        path={RouterSubUrl.CampaignList}
        element={
          <>
            <Helmet>
              <title>{messages.seo.title.format(messages.seo.campaigns)}</title>
            </Helmet>
            <ListCampaign />
          </>
        }
      />
      <Route
        path={`${RouterSubUrl.CampaignDetails}/:campaignId`}
        element={<CampaignDetailsWrapper />}
      />
    </Routes>
  )
}
