import styled from "styled-components"
import { Button, Flex } from "@ikiru/talentis-fpc"

export const FilterPopupContainer = styled(Flex)`
  flex-direction: column;
  height: calc(100% - 4px);
`

export const FilterPopupContent = styled(Flex)`
  flex-direction: column;
  padding: 10px;
  overflow: auto;
`
export const FilterFlex = styled(Flex)`
  @media (max-width: 768px) {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
  }
`
export const StyledButton = styled(Button)`
  position: absolute;
  right: ${({ theme }) => theme.space.xs}px;
  top: 15%;
`
