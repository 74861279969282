import React from "react"
import { Flex, H5 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

const SubBar = () => (
  <Flex p="s" width="100%" alignItems="center">
    <H5 color="grey.dark" my="none" ml="xs">
      {messages.clientPortal.editingMode}
    </H5>
  </Flex>
)

export default React.memo(SubBar)
