import React, { useState } from "react"
import {
  Flex,
  Loader,
  Div,
  H5,
  BodyText,
  Button,
  RefreshWhiteIcon,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { Formik, Form } from "formik"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import GenerateAiBioFormLayout from "./GenerateAiBioFormLayout"
import GeneratedBioOverview from "./GeneratedBioOverview"
import { generateAIBio } from "./action"
import { GenerateAiBioFormValues } from "./const"
import { messages } from "setup/messages/messages"

type GenerateBioFormProps = {
  bioSettings: GenerateAiBioFormValues
  onCloseFun?: () => void
  onSaveFun?: (data: any) => void
  onGenerate?: (newBio: string) => void
  noBanner?: boolean
}
const GenerateBioForm = ({
  onCloseFun,
  onSaveFun,
  onGenerate,
  noBanner,
  bioSettings
}: GenerateBioFormProps) => {
  const [generatedBio, setGeneratedBio] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { personId } = usePerson()
  const { close } = useModal()

  const submitForm = async (values: GenerateAiBioFormValues) => {
    setIsLoading(true)

    const data = await generateAIBio(personId, values.isShort, values.language)
    localStorage.setItem("AIBioSettings", JSON.stringify(values))
    if (!!onGenerate) {
      onGenerate(data.bio)
    } else {
      setGeneratedBio(data.bio)
    }
    setIsLoading(false)
  }

  const onSave = async () => {
    setIsSaving(true)
    !!onSaveFun && onSaveFun(generatedBio)
    setIsSaving(false)
  }

  const onClose = () => {
    if (!onCloseFun) {
      close(ModalNames.GenerateBioAI)
    } else {
      onCloseFun()
    }
  }

  return (
    <Formik initialValues={bioSettings} onSubmit={submitForm}>
      {(formik) => (
        <Form>
          <>
            {!noBanner && (
              <ModalSectionHeader
                title={messages.aiBio.title}
                size="xSmall"
                actions={
                  <>
                    <Button
                      mode="standard-white"
                      size="small"
                      type="button"
                      onClick={onClose}
                      mr="xs"
                    >
                      {messages.generic.cancel}
                    </Button>

                    <Button
                      size="small"
                      mr="xxs"
                      disabled={isLoading || !generatedBio}
                      onClick={onSave}
                      type="button"
                    >
                      {messages.aiBio.saveNewBio}
                    </Button>
                  </>
                }
              />
            )}
            {isLoading || isSaving ? (
              <Flex py="xl" mx="auto">
                <Loader style={{ textAlign: "center" }}>
                  {isSaving ? "" : messages.aiBio.loadingMessage}
                </Loader>
              </Flex>
            ) : !!generatedBio ? (
              <Div px="m" py="xs">
                <H5 color="green.dark" m="0">
                  {messages.aiBio.newGeneratedBio}
                </H5>
                <BodyText>{messages.aiBio.overviewDescription}</BodyText>
                <GeneratedBioOverview
                  setNewBio={setGeneratedBio}
                  newBio={generatedBio}
                />
                {bioSettings && (
                  <Flex width="100%" justifyContent="flex-end" mt="xs">
                    <Button
                      mode="standard-grey-dark"
                      size="extra-small"
                      onClick={() => setGeneratedBio("")}
                      prefix={<RefreshWhiteIcon width={12} height={12} />}
                    >
                      {messages.aiBio.regenerateBio}
                    </Button>
                  </Flex>
                )}
              </Div>
            ) : (
              <Div px="m" py="xs">
                <H5 color="green.dark" m="0">
                  {messages.aiBio.bioSettings}
                </H5>
                <BodyText>{messages.aiBio.featureDescription}</BodyText>
                <GenerateAiBioFormLayout formik={formik} />
              </Div>
            )}
            {!generatedBio && (
              <Flex justifyContent="center" p="xs">
                <Button
                  size="small"
                  type="button"
                  onClick={() => formik.submitForm()}
                  mr="xxs"
                  disabled={isLoading}
                >
                  {messages.aiBio.generateBio}
                </Button>
              </Flex>
            )}
          </>
        </Form>
      )}
    </Formik>
  )
}

export default GenerateBioForm
