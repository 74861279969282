import {
  simpleRequestMethod,
  complexRequestMethod
} from "setup/api/utils/request-definitions"
import Axios from "axios"
import { registerInterceptors } from "setup/api/register-interceptors"
import { baseURL } from "setup/api/utils/get-base-url"

export const api = Axios.create({
  baseURL
})

registerInterceptors(api)

export const apiRequest = {
  get: simpleRequestMethod(api, "get"),
  delete: simpleRequestMethod(api, "delete"),
  post: complexRequestMethod(api, "post"),
  put: complexRequestMethod(api, "put"),
  patch: complexRequestMethod(api, "patch")
}

export const CancelToken = Axios.CancelToken
export const isRequestCancelled = Axios.isCancel
