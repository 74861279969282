import React from "react"
import { Nullable } from "tsdef"
import { messages } from "setup/messages/messages"
import { Stage } from "views/persons/person.types"
import { ActivitiesDetails } from "./ActivitiesDetails"
import { countAllStages } from "../../helper"
import { StyledFlex } from "../../styles"
import { Flex } from "@ikiru/talentis-fpc"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import { ProjectTeamMember } from "components/ProjectTeamMembers/types"
import { findCountStage } from "utils/helpers"

type CandidateActivitiesDetailsProps = {
  personId?: Nullable<string>
  datapoolId?: string
  stages: Stage[]
  showLabel: boolean
  assignmentIds: string[]
  campaignIds: string[]
  mY?: string
  projectTeamMembers: ProjectTeamMember[]
}

export const CandidateActivitiesDetails = ({
  personId,
  datapoolId,
  stages,
  showLabel,
  assignmentIds,
  campaignIds = [],
  mY = "xs",
  projectTeamMembers
}: CandidateActivitiesDetailsProps) => {
  const candidateActivitiesDetails = [
    {
      name: messages.talentGraphSearch.candidateDetails.assignment,
      count: assignmentIds.length
    },
    {
      name: messages.talentGraphSearch.candidateDetails.shortlisted,
      count: countAllStages(stages)
    },
    {
      name: messages.talentGraphSearch.candidateDetails.placed,
      count: findCountStage(stages, "Placed")
    },
    {
      name: messages.talentGraphSearch.candidateDetails.campaign,
      count: campaignIds.length
    }
  ]

  return (
    <StyledFlex alignItems="baseline" justifyContent="space-between">
      <Flex justifyContent="stretch" my={mY} flexWrap="wrap">
        {candidateActivitiesDetails.map(({ name, count }) => (
          <ActivitiesDetails
            key={name}
            name={name}
            count={count}
            personId={personId}
            datapoolId={datapoolId}
          />
        ))}
      </Flex>
      <ProjectTeam
        title={messages.person.personTeam.title}
        projectTeamMembers={projectTeamMembers}
        showLabel={showLabel}
        isList
      />
    </StyledFlex>
  )
}
