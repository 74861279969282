import React, { useEffect } from "react"
import { get } from "lodash"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Flex, Div, Overlay } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { KEYWORDS_LIMIT } from "views/search/SearchModule/consts"
import { useSearch } from "views/search/SearchModule/context"
import { SearchCriteriaField } from "../definitions"
import { useMandatoryFiltersWereSet, useNestedArrayFieldChange } from "../hooks"
import { LogicSelector } from "./LogicSelector"
import { keywordsTemplateString } from "./consts"
import FilterWrapper from "../../FilterWrapper"
import { AnimatePresence, motion } from "framer-motion"

export const ExcludeKeywordsFilter = () => {
  const { filters, removeArrayBasedFilter } = useSearch()
  const mandatoryFiltersWereSet = useMandatoryFiltersWereSet()
  const filterName = keywordsTemplateString
  const fieldName = SearchCriteriaField.ExcludeKeyword

  const onBlur = useNestedArrayFieldChange({
    field: fieldName,
    filterName,
    limit: KEYWORDS_LIMIT,
    isOnBlur: true
  })

  const onKeyPress = useNestedArrayFieldChange({
    field: fieldName,
    filterName,
    limit: KEYWORDS_LIMIT
  })

  const currentKeywords = get(filters, filterName, [])

  useEffect(() => {
    if (!mandatoryFiltersWereSet) {
      filters.excludeKeywordSearch?.keywords.forEach((keyword) =>
        removeArrayBasedFilter(filterName, keyword)
      )
    }
  }, [filters, filterName, removeArrayBasedFilter, mandatoryFiltersWereSet])

  return (
    <AnimatePresence>
      <motion.div
        key={fieldName}
        initial={{ height: 0, overflowY: "hidden" }}
        animate={{ height: "auto", overflowY: "visible" }}
        exit={{ height: "0px", overflowY: "hidden" }}
      >
        <FilterWrapper>
          <Overlay opacity={0.5} visible={!mandatoryFiltersWereSet} />
          <Flex flexDirection="column" width="100%">
            <Div width="100%">
              <FormikInput
                name={fieldName}
                label={messages.talentGraphSearch.filters.excludeKeyword}
                placeholder={messages.talentGraphSearch.placeholders.keywords}
                disableDefaultOnBlur
                {...{
                  onKeyPress: onKeyPress as (
                    e: React.KeyboardEvent<HTMLInputElement>
                  ) => void,
                  onBlur: onBlur as (
                    e: React.FocusEvent<HTMLInputElement>
                  ) => void
                }}
              />
              <KeywordsList
                keywords={currentKeywords}
                onKeywordRemove={(value) =>
                  removeArrayBasedFilter(filterName, value)
                }
                variant="orange-darkest"
              />
            </Div>
            <LogicSelector />
          </Flex>
        </FilterWrapper>
      </motion.div>
    </AnimatePresence>
  )
}
