import React, { useMemo, useCallback, lazy, Suspense } from "react"
import { Div, Flex, SectionBody, SectionHeader } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { ContactsEmptyList } from "./components/contact-empty-list"
import ContactListHeader from "./ContactListHeader"
import { Pagination } from "components/functional/pagination/Pagination"
import { ProjectFilterContainer } from "components/ProjectRecordsFilters/style"
import { ProjectFilters } from "components/ProjectRecordsFilters/ProjectFilters"
import { NoMatchingResults } from "components/ProjectRecordsFilters/NoMatchingResults"
import { getItemCount } from "components/ProjectRecordsFilters/helpers"
import { FilterContact } from "./components/contact-filters/FilterContact"
import { getAppliedFiltersParams } from "components/ProjectRecordsFilters/helpers"
import useEditCampaignFilter from "./components/contact-filters/hooks/useEditCampaignFilter"

const ContactRecordWrapper = lazy(
  () => import("./components/contact-record/ContacRecordWrapper")
)

export const ContactsList = React.memo(() => {
  const {
    contacts,
    paginationContact: {
      pageNumber,
      totalItemCount = 0,
      pageSize = 1,
      totalFilteredCount = 0
    },
    isContactsLoading,
    setCampaignPaginationContact,
    appliedFilters,
    getCampaignContacts,
    campaignId
  } = useCampaign()

  const { removeFilter } = useEditCampaignFilter()

  const itemsCount = useMemo(() => contacts.length, [contacts])

  const headerCount = useMemo(
    () => getItemCount(totalFilteredCount, totalItemCount),
    [totalFilteredCount, totalItemCount]
  )

  const pageCount = useMemo(
    () => Math.ceil(totalFilteredCount / pageSize),
    [totalFilteredCount, pageSize]
  )

  const onPageChange = useCallback(
    (pageNumber: any) => {
      setCampaignPaginationContact({ pageNumber })
      const params = getAppliedFiltersParams(appliedFilters)
      params.append("page", pageNumber.toString())
      getCampaignContacts(campaignId, params)
    },
    [
      appliedFilters,
      campaignId,
      setCampaignPaginationContact,
      getCampaignContacts
    ]
  )

  const renderPaging = () => (
    <Flex width="100%" flexDirection="row" justifyContent="center" mb="s">
      <Pagination
        totalItemCount={totalFilteredCount}
        pageSize={pageSize}
        pageNumber={pageNumber || 1}
        hasNextPage={pageNumber !== pageCount}
        hasPreviousPage={pageNumber !== 1}
        pageCount={pageCount}
        maxPages={10}
        onPageChange={(pageNumber) => onPageChange(pageNumber)}
      />
    </Flex>
  )

  return (
    <Div backgroundColor="grey.lightest" minHeight="40%">
      <SectionHeader
        size="xxSmall"
        title={messages.campaign.details.contacts.title}
        itemsCount={headerCount}
        flexDirection={["column", "row"]}
        alignItems={["flex-start", "center"]}
      />
      {(contacts.length > 0 || !!appliedFilters.length) && (
        <ProjectFilterContainer>
          <Flex justifyContent="space-between" px="s">
            <ProjectFilters
              appliedFilters={appliedFilters}
              removeFilter={removeFilter}
              filterComponent={<FilterContact />}
            />
            <Div mt="-2px">
              <ContactListHeader />
            </Div>
          </Flex>
        </ProjectFilterContainer>
      )}
      {isContactsLoading ? (
        <Flex py="m">
          <Loader />
        </Flex>
      ) : Boolean(itemsCount) ? (
        <>
          <Flex width="100%" flexDirection="column" pr="s" pl="s">
            {contacts.map((contact: any) => (
              <Div key={contact.id}>
                <Suspense fallback={<></>}>
                  <ContactRecordWrapper contact={contact} />
                </Suspense>
              </Div>
            ))}
          </Flex>
          {pageCount > 1 && renderPaging()}
        </>
      ) : (
        <SectionBody>
          {appliedFilters.length ? (
            <NoMatchingResults />
          ) : (
            <ContactsEmptyList />
          )}
        </SectionBody>
      )}
    </Div>
  )
})
