import { Div, theme } from "@ikiru/talentis-fpc"
import styled from "styled-components"

type ContactInputContainerProps = {
  isEditSearchPage: boolean
  isFullLength?: boolean
}

export const ContactInputContainer = styled(Div)<ContactInputContainerProps>`
  margin-top: ${theme.space.xs}px;
  padding-right: ${theme.space.xs}px;

  ${({ isFullLength = false }) =>
    !isFullLength
      ? `

      width: clamp(43%, 100%, 50%);
    `
      : `
      width: clamp(86%, 100%, 100%);
    `}
  @media (min-width: 768px) and (max-width: 1230px) {
    ${({ isEditSearchPage }) =>
      isEditSearchPage &&
      `
      min-width: 100%;
      flex: 1;`}
  }
`

export const HelpIconContainer = styled(Div)`
  position: relative;
  padding-right: ${theme.space.xs}px;
  margin: 0;
  cursor: pointer;
  top: -2px;
`

export const HelpPopUpContainer = styled(Div)`
  position: absolute;
  min-width: 300px;
  z-index: 99;
  top: 5px;
  right: 25px;
  border: 2px solid ${theme.colors.grey.standard};
  padding: 3px;
  background-color: ${theme.colors.white.standard};
  border-radius: 5px;
`
