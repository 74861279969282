import { FormikHelpers } from "formik"
import { callWithErrorMessages } from "utils/forms/forms"
import { commonFormStateKey, FormStates } from "utils/forms/form-state"
import {
  registerUserInitialValues,
  RegisterUserField
} from "views/users/register-user/constants/register-user.definitions"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type CreatePersonValues = typeof registerUserInitialValues

export const onRegisterUserSubmit = async (
  values: CreatePersonValues,
  helpers: FormikHelpers<CreatePersonValues>
) => {
  const { setFieldError, setStatus, setSubmitting } = helpers

  setSubmitting(true)
  setStatus({ [commonFormStateKey]: FormStates.Pending })

  const { [RegisterUserField.UserId]: userId, ...data } = values

  const [error] = await apiRequest.put({
    endpoint: UsersEndpoints.Invite,
    endpointParams: userId,
    data,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  setStatus({ [commonFormStateKey]: FormStates.Static })

  setSubmitting(false)

  if (error) {
    callWithErrorMessages(setFieldError, error)
  } else {
    setStatus({ [commonFormStateKey]: FormStates.Finished })
  }
}
