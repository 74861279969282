import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useQueryParameter } from "utils/hooks/use-query"
import { apiRequest } from "setup/api/api"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"
import { RouterUrl } from "setup/router/routes"
import { getErrorMessage } from "utils/get-error-message"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const initialInvitedUser = {
  email: "",
  company: "",
  id: "",
  searchFirmId: ""
}

export enum RegisterInvitedUserViewState {
  Loading = "loading",
  Invalid = "invalid",
  Valid = "valid"
}

type ErrorMessages = { header: string; content: string }

export const useInvitationDetails = () => {
  const navigate = useNavigate()
  const token = useQueryParameter("invitation") || ""

  const [errorMessage, setErrorMessage] = useState<ErrorMessages | undefined>()
  const [user, setUser] = useState(initialInvitedUser)

  const [viewState, setViewState] = useState(
    RegisterInvitedUserViewState.Loading
  )

  useEffect(() => {
    const getInvitationDetails = async (token: string) => {
      const [error, response] = await apiRequest.get({
        endpoint: UsersEndpoints.Invite,
        config: { params: { token }, headers: { ...skipErrorHeader } }
      })

      if (error) {
        const header = getErrorMessage(error, "header")
        const content = getErrorMessage(error, "content")
        setErrorMessage({ header, content })
        setViewState(RegisterInvitedUserViewState.Invalid)
      } else {
        const { inviteEmailAddress, companyName, id, searchFirmId } =
          response?.data
        setUser({
          email: inviteEmailAddress,
          company: companyName,
          searchFirmId,
          id
        })
        setViewState(RegisterInvitedUserViewState.Valid)
      }
    }

    token ? getInvitationDetails(token) : navigate(RouterUrl.Homepage)
  }, [token, navigate])

  return { viewState, user, errorMessage }
}
