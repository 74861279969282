import { useMemo } from "react"
import moment from "moment"
import { defaultDateFormat } from "utils/moment"
import { TrialDetails } from "../../definitions"

export const useTrialPlanDetails = (trialDetails: TrialDetails) => {
  const { trialEndDate } = trialDetails

  const formattedEndDate = useMemo(
    () => moment(trialEndDate).format(defaultDateFormat),
    [trialEndDate]
  )

  // moment.diff will be negative if it's in the future, so use Math.abs
  // if the diff is in the past (already expired), just don't show this
  const daysRemaining = useMemo(() => {
    const diff = moment().diff(trialEndDate, "days")
    return diff < 1 ? Math.abs(diff) : null
  }, [trialEndDate])

  return {
    formattedEndDate,
    daysRemaining
  }
}
