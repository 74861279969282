import React, { useCallback, useMemo } from "react"
import { useField } from "formik"
import { Error } from "@ikiru/talentis-fpc"
import { Select, SelectProps, SelectOptionProps } from "@ikiru/talentis-fpc"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"
import { Div, StyledSystemElement } from "@ikiru/talentis-fpc"

type FormikSelectProps = SelectProps & {
  layout?: StyledSystemElement<HTMLDivElement>
  firstOption?: SelectOptionProps
  controlled?: boolean
}

export const FormikSelect = (props: FormikSelectProps) => {
  const {
    label,
    name,
    options,
    onChange,
    layout,
    onBlur,
    firstOption,
    controlled = false,
    ...fieldProps
  } = props

  const [formikProps, meta] = useField(name as string)
  const { onBlur: formikOnBlur, onChange: formikOnChange } = formikProps

  const onBlurMethod = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      formikOnBlur(event)
      onBlur && onBlur(event)
    },
    [onBlur, formikOnBlur]
  )

  const onChangeMethod = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      formikOnChange(event)
      onChange && onChange(event)
    },
    [onChange, formikOnChange]
  )

  const status = useStatus(meta)
  const shouldDisplayError = useShouldDisplayError(meta)
  const selectOptions = useMemo(
    () => (firstOption ? [firstOption, ...options] : options),
    [firstOption, options]
  )

  return (
    <Div {...layout}>
      <Select
        {...fieldProps}
        {...formikProps}
        value={controlled ? fieldProps.value : formikProps.value}
        label={label}
        status={status}
        onChange={onChangeMethod}
        onBlur={onBlurMethod}
        data-e2e-target="form-select"
        options={selectOptions}
      />
      {shouldDisplayError && <Error>{meta.error}</Error>}
    </Div>
  )
}

export default FormikSelect
