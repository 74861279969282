import React, { useState } from "react"
import {
  H2,
  H5,
  Flex,
  colors,
  Avatar,
  PolygonIcon,
  Div,
  H6
} from "@ikiru/talentis-fpc"
import { WebSitesRecord } from "./WebSitesRecord"
import { EditableDetailsPayload } from "components/EditableDetails/editable-details.types"
import { CompanyPhones } from "./CompanyPhones"
import { FullCompanyInfo } from "./FullCompanyInfo"
import { useCompany } from "views/companies/company-module/company-module.context"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { StyledPopupTrigger, OffLimitsContainerStyled } from "./style"
import { messages } from "setup/messages/messages"
import { linkCompanyToDatapool } from "../../../../assignments/components/assignment-client-details/linkedin-form/actions"
import { LinkedInUrlField } from "../../../../assignments/components/assignment-client-details/linkedin-form/constants/definitions"
import LinkToTalentGraph from "components/ProjectActions/LinkToTalantGraph/LinkToTalentGraph"
import { OffLimitsBlock } from "components/OffLimits/OffLimitsBlock"
import { isDateGreaterThanToday } from "utils/format-date"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import useSubmitCompanyDetails from "views/companies/hooks/useSubmitCompanyDetails"

type ViewCompanyDetailsProps = {
  editableControls: EditableDetailsPayload
  addPhoneNumberButtonRef?: any
  addWebsiteButtonRef?: any
}

type Option = {
  label: string | JSX.Element
  onClick?: () => void
}

export const ViewCompanyDetails = React.memo(
  ({
    editableControls,
    addPhoneNumberButtonRef,
    addWebsiteButtonRef
  }: ViewCompanyDetailsProps) => {
    const { companyDetails, setCompanyDetails, offLimits } = useCompany()
    const [editLinkedIn, setEditLinkedIn] = useState(false)
    const [companyNotFound, setCompanyNotFound] = useState(false)
    const [showLinkSuccess, setShowLinkSuccess] = useState(false)

    const {
      id,
      name,
      phoneNumbers,
      websiteLinks,
      isLinkedToDataPool,
      logoUrl,
      addresses,
      teamMembers
    } = companyDetails

    const firstAddress = addresses[0]
    const remainingAddresses = addresses.slice(1)

    const options: Option[] = remainingAddresses.map((address) => ({
      label: <H6 m={0}>{address?.freeformAddress}</H6>
    }))
    options.unshift({ label: firstAddress?.freeformAddress })

    const { companyOpenModal } = useSubmitCompanyDetails()

    return (
      <>
        <LinkToTalentGraph
          isForCompany
          editLinkedIn={editLinkedIn}
          setEditLinkedIn={setEditLinkedIn}
          dataNotFound={companyNotFound}
          showLinkSuccess={showLinkSuccess}
          isLinkedToDataPool={isLinkedToDataPool}
          needToLinkMessage={messages.assignment.addLinkedIn}
          notFoundMessage={messages.assignment.linkCorrect}
          successMessage={messages.assignment.linkCompanySuccess}
          exampleText="https://www.linkedin.com/company/ikiru-people/"
          onCancel={() => {
            setEditLinkedIn(false)
            setCompanyNotFound(false)
          }}
          onSubmit={async (values, action) => {
            setCompanyNotFound(false)
            const [error, response] = await linkCompanyToDatapool()(
              values,
              id,
              action
            )
            if (error?.data?.ConflictDetails && error?.status === 409) {
              action.setFieldError(
                `${LinkedInUrlField.LinkedInUrl}.value`,
                messages.assignment.linkExists
              )
            } else if (error) {
              setCompanyNotFound(true)
            } else {
              setCompanyDetails({
                ...response?.data
              })
              setEditLinkedIn(false)
              setTimeout(() => setShowLinkSuccess(false), 4000)

              setShowLinkSuccess(true)
            }
          }}
        />
        <Flex width="100%">
          {offLimits?.endDate && isDateGreaterThanToday(offLimits.endDate) && (
            <OffLimitsContainerStyled
              mt={showLinkSuccess || !isLinkedToDataPool ? "0" : "-10px"}
            >
              <OffLimitsBlock />
            </OffLimitsContainerStyled>
          )}

          <Flex
            width="100%"
            flexDirection="row"
            flexWrap={["wrap", "nowrap"]}
            mt="xxs"
            mb="xxs"
          >
            <Flex flexShrink={0} mr="s" width={80} height={80}>
              <Avatar photo={{ url: logoUrl }} name={name} />
            </Flex>
            <Flex width="100%" flexDirection="column">
              <Flex
                width="100%"
                justifyContent="space-between"
                flexWrap={["wrap", "nowrap"]}
                mb="xs"
              >
                <Flex
                  flexWrap="wrap"
                  alignContent="flex-start"
                  mb={firstAddress ? 0 : "xs"}
                >
                  {name && (
                    <H2
                      color={colors.green.standard}
                      mb="xxs"
                      mt="xxs"
                      width="100%"
                      height="18px"
                    >
                      {name}
                    </H2>
                  )}
                  {firstAddress && (
                    <Flex alignItems="center" mt="xxs">
                      <H5 my="xxs" mr="xs">
                        {firstAddress?.freeformAddress}
                      </H5>
                      {remainingAddresses.length > 0 && (
                        <OptionsPopup
                          alignSelf="center"
                          display="flex"
                          config={{
                            PopupProps: {
                              position: "bottom left"
                            }
                          }}
                          trigger={
                            <StyledPopupTrigger>
                              {`+${remainingAddresses.length}`}
                              <PolygonIcon />
                            </StyledPopupTrigger>
                          }
                          options={options}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex
                  flexDirection="column"
                  textAlign="right"
                  alignItems="flex-end"
                >
                  {Boolean(phoneNumbers.length) ? (
                    <CompanyPhones
                      phoneNumbers={phoneNumbers}
                      editableControls={editableControls}
                      addPhoneNumberButtonRef={addPhoneNumberButtonRef}
                    />
                  ) : null}
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <Div mt="-15px" mr="xs">
                  {websiteLinks.length ? (
                    <WebSitesRecord
                      webSites={websiteLinks}
                      editableControls={editableControls}
                      addWebsiteButtonRef={addWebsiteButtonRef}
                    />
                  ) : null}
                </Div>
                <Div mt="-6px">
                  <ProjectTeam
                    projectTeamMembers={teamMembers}
                    title={messages.companies.companyTeamMembers}
                    isOffLimits={isDateGreaterThanToday(offLimits.endDate)}
                    onClickAddButton={companyOpenModal}
                    useAddButton
                    showAddButton
                  />
                </Div>
              </Flex>

              <FullCompanyInfo />
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }
)
