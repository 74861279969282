import React, { useCallback, useEffect } from "react"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterUrl } from "setup/router/routes"
import { useModal } from "utils/hooks/use-modal"
import { useNavigate } from "react-router-dom"
import { ValueOf } from "tsdef"

export const ModalOpener = (props: {
  name: ModalNames
  modal: JSX.Element
  returnRoute?: ValueOf<typeof RouterUrl>
}) => {
  const { name, modal, returnRoute } = props
  const { open, onClose, removeOnCloseCallback } = useModal()
  const navigate = useNavigate()

  const onModalClose = useCallback(() => {
    if (returnRoute) {
      navigate(returnRoute)
    }
  }, [navigate, returnRoute])

  useEffect(() => {
    open(name, modal)

    onClose(name, onModalClose)

    return () => {
      removeOnCloseCallback(name, onModalClose)
    }
  }, [
    name,
    modal,
    returnRoute,
    onModalClose,
    open,
    onClose,
    removeOnCloseCallback
  ])

  return <></>
}
