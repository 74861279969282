import { SelectOptionProps } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

export enum PhoneTypeEnums {
  Personal = "personal",
  BusinessDirect = "businessDirect",
  Business = "business",
  Other = "other",
  Business_Switchboard = "business_Switchboard"
}

export enum EmailTypeEnums {
  Personal = "personal",
  Business = "business",
  Other = "other"
}

export const phoneNumberInitialValue = {
  value: {
    isPreferred: false,
    phoneDeviceType: "other",
    phoneNumber: "",
    phoneUsageType: "other"
  },
  isReadOnly: false
}

export const phoneTypeOptions: SelectOptionProps[] = [
  {
    value: PhoneTypeEnums.Personal,
    label: messages.form.generic.phoneTypeLabels.personal
  },
  {
    value: PhoneTypeEnums.Business,
    label: messages.form.generic.phoneTypeLabels.business
  },
  {
    value: PhoneTypeEnums.BusinessDirect,
    label: messages.form.generic.phoneTypeLabels.businessDirect
  },
  {
    value: PhoneTypeEnums.Other,
    label: messages.form.generic.phoneTypeLabels.other
  }
]

export const emailTypeOptions: SelectOptionProps[] = [
  {
    value: EmailTypeEnums.Personal,
    label: messages.form.generic.emailTypeLabels.personal
  },
  {
    value: EmailTypeEnums.Business,
    label: messages.form.generic.emailTypeLabels.business
  },
  {
    value: EmailTypeEnums.Other,
    label: messages.form.generic.emailTypeLabels.other
  }
]

export const phoneUsageSortArr: string[] = [
  PhoneTypeEnums.Personal,
  PhoneTypeEnums.BusinessDirect,
  PhoneTypeEnums.Business,
  PhoneTypeEnums.Other
]

export const emailUsageSortArr: string[] = [
  EmailTypeEnums.Personal,
  EmailTypeEnums.Business,
  EmailTypeEnums.Other
]

export const phoneNumberEmptyEntry = {
  value: {
    isPreferred: false,
    phoneDeviceType: "other",
    phoneNumber: "",
    phoneUsageType: PhoneTypeEnums.Personal
  },
  isReadOnly: false
}

export const emailEmptyEntry = {
  value: { email: "", type: "", isPreferred: false },
  isReadOnly: false
}
