import React from "react"
import { Div, BodyText } from "@ikiru/talentis-fpc"
import { Nullable } from "tsdef"

type MoreInfoProps = {
  name?: Nullable<string>
  jobTitle?: Nullable<string>
  company?: Nullable<string>
  location?: Nullable<string>
}

const MoreInfo = ({ name, jobTitle, company, location }: MoreInfoProps) => {
  return (
    <Div p="2px" style={{ cursor: "pointer" }}>
      {name && (
        <BodyText m="0px" fontSize="13px" lineHeight="14px">
          <strong>Name:</strong> {name}
        </BodyText>
      )}
      {jobTitle && (
        <BodyText m="0px" fontSize="13px" lineHeight="14px">
          <strong>JobTitle:</strong> {jobTitle}
        </BodyText>
      )}
      {company && (
        <BodyText m="0px" fontSize="13px" lineHeight="14px">
          <strong>Company:</strong> {company}
        </BodyText>
      )}
      {location && (
        <BodyText m="0px" fontSize="13px" lineHeight="14px">
          <strong>Location:</strong> {location}
        </BodyText>
      )}
    </Div>
  )
}

export default MoreInfo
