import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import {
  UploadPersonDocumentValues,
  UploadPersonDocumentField
} from "./constants/upload-person-document.definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { FormAction, GetFormAction } from "utils/forms/form.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnUploadPersonDocumentSubmit =
  FormAction<UploadPersonDocumentValues>
type GetOnUploadPersonDocumentSubmit = GetFormAction<UploadPersonDocumentValues>

export const getOnUploadPersonDocumentSubmit: GetOnUploadPersonDocumentSubmit =
  (personId, onUploadSuccess, beforeSubmit) => async (values, helpers) => {
    const { [UploadPersonDocumentField.File]: file } = values
    const { setSubmitting, setFieldError, resetForm } = helpers
    const formData = new FormData()

    file && formData.append("file", file as unknown as Blob)

    setSubmitting(true)
    personId = await beforeSubmit(personId)
    const [error, response] = await apiRequest.post({
      endpoint: PersonsEndpoints.Documents,
      data: formData,
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    callWithErrorMessages(setFieldError, error)

    if (response) {
      onUploadSuccess!(response.data)
      resetForm()
    }
  }
