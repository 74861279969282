import moment, { Moment } from "moment"
import {
  DatepickerNotifyIcon as NotifyIcon,
  DatepickerNotifyFullyRingingIcon as NotifyFullyRingingIcon,
  DatepickerNotifyPartlyRingingIcon as NotifyPartlyRingingIcon,
  DatepickerNotifyPartlyRingingTommorowIcon as NotifyPartlyRingingTommorowIcon
} from "@ikiru/talentis-fpc"

const calcDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => moment().add(offset, unit)

export const getLTEDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => {
  const date = calcDate(offset, unit)
  date.hours(23).minutes(59).seconds(59)

  return date
}

export const getGTEDate = (
  offset = 0,
  unit: moment.unitOfTime.DurationConstructor = "days"
) => {
  const date = calcDate(offset, unit)
  date.hours(0).minutes(0).seconds(0)

  return date
}

const today = { lte: getLTEDate(), gte: getGTEDate() }
const tommorow = { lte: getLTEDate(1), gte: getGTEDate(1) }

export const getDateInputColor = (date: Moment) => {
  if (!date) return undefined

  const isAfter = date.isBefore(today.gte, "day")
  const isDayBefore =
    date.isAfter(today.gte, "day") && date.isSameOrBefore(tommorow.gte, "day")
  const isToday = date.isSame(today.gte, "day")
  const isMoreThreeDays = date.isAfter(tommorow.gte, "day")

  if (isToday) return "orange"
  if (isAfter) return "red"
  if (isDayBefore) return "yellow"
  if (isMoreThreeDays) return "grey"
}

export const notifyIcons = {
  grey: NotifyIcon,
  red: NotifyFullyRingingIcon,
  yellow: NotifyPartlyRingingTommorowIcon,
  orange: NotifyPartlyRingingIcon
}
