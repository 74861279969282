import React from "react"
import { Flex, SmallText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { formatDate } from "utils/format-date"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { OffLimits } from "../OffLimits"
import { Experience } from "views/persons/person.types"

type InheritedOffLimitsProps = {
  inheritedOffLimits: Experience
}

export const InheritedOffLimits = ({
  inheritedOffLimits
}: InheritedOffLimitsProps) => {
  const { offlimits, offlimitsOverride, company, companyId } =
    inheritedOffLimits

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <CompanyLink
          companyName={company}
          companyId={companyId}
          fontSize={[15, 15, 16]}
        />
        {offlimitsOverride?.isOverridden && (
          <SmallText mt="xxs">
            {messages.offLimits.overrideSet}
            {formatDate(offlimitsOverride.updated!)}
            {` ${offlimitsOverride.updatedBy?.firstName} ${offlimitsOverride.updatedBy?.lastName}`}
          </SmallText>
        )}
      </Flex>

      <OffLimits {...offlimits} isNoLastEdit isSmall />
    </>
  )
}
