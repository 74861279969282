import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import { createCampaignValidationSchema } from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.schema"
import {
  CreateCampaignValues,
  CreateCampaignField,
  createCampaignInitialValues,
  CampaignPrimaryData
} from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { messages } from "setup/messages/messages"
import { Div, Flex, SectionBody, SectionHeader, H1 } from "@ikiru/talentis-fpc"

type CampaignDetailsFormProps = {
  initialValues?: Partial<CreateCampaignValues>
  onSubmit: (
    values: CampaignPrimaryData,
    helpers: FormikHelpers<CampaignPrimaryData>
  ) => void
  formActionsComponent: (
    formikProps: FormikProps<CreateCampaignValues>
  ) => JSX.Element
}

export const CampaignDetailsForm = ({
  initialValues,
  onSubmit,
  formActionsComponent
}: CampaignDetailsFormProps) => {
  return (
    <Formik
      initialValues={{
        ...createCampaignInitialValues,
        ...initialValues
      }}
      validationSchema={createCampaignValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <Div>
              <Div mb="m">
                <Flex my="xxs" justifyContent="flex-start" alignItems="center">
                  <H1 m={0} color="green.standard" flexGrow={1}>
                    {messages.campaign.addCampaign}
                  </H1>
                  {formActionsComponent(formik)}
                </Flex>
              </Div>
              <Div mb="m">
                <SectionHeader title={messages.campaign.campaignDetails} />
                <SectionBody>
                  <Flex width="100%" flexWrap="wrap">
                    <Div width="100%">
                      <FormikInput
                        id={CreateCampaignField.CampaignName}
                        name={CreateCampaignField.CampaignName}
                        label={`${messages.campaign.campaignName}*`}
                      />
                    </Div>
                    <Div width="100%">
                      <FormikTextarea
                        id={CreateCampaignField.Description}
                        name={CreateCampaignField.Description}
                        label={messages.campaign.campaignDescription}
                        resize="none"
                      />
                    </Div>
                  </Flex>
                </SectionBody>
              </Div>
            </Div>
          </Form>
        )
      }}
    </Formik>
  )
}
