import React from "react"
import { useModal } from "utils/hooks/use-modal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useTeam } from "views/team/team/team-module.context"
import { PersonTeamMember } from "views/persons/person.types"
import { ModalNames } from "setup/modal/modal.definitions"
import { EditPersonTeam } from "../form/EditPersonTeamModal/EditPersonTeam"
import { sendPersonPatchRequest } from "views/persons/components/person-details/form/actions"
import {
  Membership,
  ProjectTeamMember
} from "components/ProjectTeamMembers/types"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

const useSaveTeam = () => {
  const { open: openModal, close: closeModal } = useModal()
  const {
    teamMembers: personTeamMembers,
    personId,
    setTeamMembers,
    setPersonOverlayUpdated,
    profileDetails
  } = usePerson()
  const { teamMembers } = useTeam()
  const { createLocalPerson } = usePersonDataPoolLinkage()
  const patchPerson = (values: {
    teamMembers: Partial<PersonTeamMember>[]
  }) => {
    createLocalPerson(personId, profileDetails?.dataPoolPersonId).then(
      (personId) => {
        return sendPersonPatchRequest(personId, values).then(
          ([, response]) => {
            setTeamMembers(response?.data?.localPerson?.teamMembers)
            closeModal(ModalNames.PersonTeamEdit)
            setPersonOverlayUpdated(true)
          },
          () => {
            closeModal(ModalNames.PersonTeamEdit)
          }
        )
      }
    )
  }

  const editPersonTeam = () => {
    openModal(
      ModalNames.PersonTeamEdit,
      <EditPersonTeam
        onSubmit={patchPerson}
        personTeamMembers={personTeamMembers}
        teamMembers={teamMembers}
      />
    )
  }

  const sortPersonTeam = (a: ProjectTeamMember, b: ProjectTeamMember) => {
    if (a.membership === b.membership) {
      const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
      const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
      return nameA.localeCompare(nameB)
    } else if (
      a.membership === Membership.KnownTo &&
      b.membership === Membership.Member
    ) {
      return -1
    } else {
      return 1
    }
  }
  return { editPersonTeam, personTeamMembers, sortPersonTeam }
}

export { useSaveTeam }
