import { Nullable } from "tsdef"
import { PaymentIntent } from "@chargebee/chargebee-js-react-wrapper"
import { Coupon } from "./coupons"
import { Frequency } from "../components/plan-details/form/constants/plan-details.definitions"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { TeamMemberStatus } from "views/team/team/team-module.types"
import { UserRoles } from "setup/auth/module/constants/auth.types"

export type ChargebeePlan = {
  id: string
  price: PaymentEstimateResponse
  period?: number
  periodUnit: PeriodUnit
  currencyCode: Currency
  planType: SubscriptionType
  defaultTokens?: number
  canPurchaseRocketReach?: boolean
}

export enum SubscriptionType {
  Trial = "trial",
  Talentis = "basic",
  TalentisConnect = "connect"
}

export enum Currency {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  AUD = "AUD",
  PLN = "PLN"
}

export type SubscriptionPlan = {
  type: SubscriptionType
  currency: Currency
  monthly?: PricingEntity
  yearly?: PricingEntity
}

export type PricingEntity = {
  id: string
  planId: string
  price: PaymentEstimateResponse
  defaultTokens?: number
}

export enum PeriodUnit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

export type SubscriptionPlanDetails = {
  totalUsersNumber: number
  currency: Currency
  frequency: Frequency
  users: string[]
}

export type SubscriptionPlanFeature = {
  icon?: React.ReactNode
  description: React.ReactNode
}

export type Discount = {
  name: string
  types: SubscriptionType[]
  value: number
}

export type BillingDetails = {
  email?: string
  addressFirstLine?: string
  city?: string
  countryCode?: string
  postcode?: string
  vatNumber?: string
}

export type PaymentEstimate = {
  total?: number
  amount?: number
  discount?: number
  taxAmount?: number
}

export type PaymentEstimateResponse = {
  total: number
  amount: number
  discount: number
  taxAmount: number
  invalidCoupons: string[]
}

export enum SubscriptionPurchaseStep {
  PLAN_SELECTION = "PLAN_SELECTION",
  PLAN_DETAILS = "PLAN_DETAILS",
  BILLING_ADDRESS = "BILLING_ADDRESS",
  CARD_DETAILS = "CARD_DETAILS"
}

export type User = {
  firstName: string
  lastName: string
  id: string
  emailAddress: string
  isActive: boolean
  status: TeamMemberStatus
  userRole: UserRoles
}

export type SubscriptionPurchaseState = {
  step: SubscriptionPurchaseStep
  arePlansFetching: boolean
  availablePlans: SubscriptionPlan[]
  purchaseData: {
    plan?: SubscriptionPlan
    couponsUsed?: Coupon[]
    planDetails?: SubscriptionPlanDetails
  }
  paymentData: {
    paymentComplete: boolean
    paymentError: Nullable<string>
    paymentIntent: Nullable<PaymentIntent>
  }
  paymentEstimate: PaymentEstimate
  billingDetails: BillingDetails
  usersCount: User[]
  isLoading: boolean
  loadingMessage: string
  stepTitle: string
}

export enum SubscriptionPurchaseActionTypes {
  ChangeStep = "change-step",
  StartFetchingPlans = "start-fetching-plans",
  StopFetchingPlans = "stop-fetching-plans",
  SelectSubscriptionPlan = "select-subscription-plan",
  SetUsersCount = "set-users-count",
  SetPlanDetails = "set-plan-details",
  SetPaymentComplete = "set-payment-complete",
  AddCoupon = "add-coupon",
  RemoveCoupon = "remove-coupon",
  SetIsLoading = "set-is-loading",
  SetPaymentIntent = "set-payment-intent",
  SetPaymentError = "set-payment-error",
  SetBillingDetails = "set-billing-Details",
  SetPaymentEstimate = "set-payment-estimate",
  ChangeStepTitle = "change-step-title"
}

export type SubscriptionPurchaseContextValues = SubscriptionPurchaseState & {
  changeStep: (step: SubscriptionPurchaseStep) => void
  selectPlan: (plan: SubscriptionPlan) => void
  setPlanDetails: (planDetails: SubscriptionPlanDetails) => void
  setPaymentComplete: (complete: boolean) => void
  addCoupon: (...coupons: Coupon[]) => void
  removeCoupon: (...coupons: Coupon[]) => void
  setIsLoading: (isLoading: boolean, loadingMessage?: string) => void
  setBillingDetails: (billingDetails: BillingDetails) => void
  setPaymentEstimate: (paymentEstimate: PaymentEstimate) => void
  setPaymentIntent: (paymentIntent: PaymentIntent) => void
  setPaymentError: (error: string) => void
  fetchPaymentIntent: (
    subscriptionPlanId: string,
    subscriptionStartDate: string,
    unitQuantity: number,
    couponids: string[],
    billingAddressEmail: string,
    billingAddressLine1: string,
    billingAddressCity: string,
    billingAddressCountryCode: string,
    billingAddressZipOrPostCode: string,
    customerVatNumber?: string
  ) => Promise<Nullable<PaymentIntent>>
  fetchEstimate: (
    subscriptionPlanId: string,
    subscriptionStartDate: string,
    unitQuantity: number,
    couponids: string[],
    billingAddressCountryCode: string,
    billingAddressZipOrPostCode: string,
    customerVatNumber?: string
  ) => Promise<Nullable<PaymentEstimateResponse>>
  createSubscription: (
    id: string,
    plan: string,
    couponCodes: string[],
    usersCount: number,
    users: string[]
  ) => Promise<any>
  fetchPlans: (
    currency?: Currency,
    coupons?: string[]
  ) => Promise<NormalizedReturnTuple<ChargebeePlan[]>>
  setStepTitle: (stepTitle: string) => void
}

export type SubscriptionPurchaseAction = {
  type: SubscriptionPurchaseActionTypes
  payload?: any
}
