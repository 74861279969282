import { Div } from "@ikiru/talentis-fpc"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { Form } from "formik"
import styled from "styled-components"

export const StyledNotesList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
`
export const StyledDiv = styled(Div)`
  width: 800px;
  min-height: 480px;
  background-color: ${({ theme }) => theme.colors?.grey?.lightest};
  padding: ${({ theme }) => theme.space.s}px;
  @media (max-width: 768px) {
    width: 100%;
    height: 360px;
    min-height: 360px;
  }
`
export const StyledFormikTextarea = styled(FormikTextarea)`
  min-height: 350px;
  @media (max-width: 768px) {
    min-height: 200px;
  }
`
export const StyledForm = styled(Form)`
  margin: ${({ theme }) => theme.space.m}px;
`
