import React, { useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { PopupActions } from "reactjs-popup/dist/types"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { Popup, PopupProps } from "@ikiru/talentis-fpc"
import { preventDefault } from "utils/prevent-default"
import { OptionsContainer, Option, OptionLabel } from "./OptionsPopup.styled"
import { Nullable } from "tsdef"

export type OptionsPopupProps = PopupProps & {
  trigger: React.ReactElement
  options: HeaderOption[]
}

export const OptionsPopup = React.memo(
  ({ trigger, options, config = {}, ...rest }: OptionsPopupProps) => {
    const popupRef = useRef<PopupActions>()
    const navigate = useNavigate()

    const newConfig = {
      ...config,
      PopupProps: {
        ...(config?.PopupProps || {}),
        ref: popupRef,
        arrow: false
      },
      ContentProps: {
        ...(config?.ContentProps || {}),
        boxShadow: "0 0 4px rgba(75, 106, 136, 0.8)"
      }
    }

    const handleClick = useCallback(
      (onClick: Nullable<(e: MouseEvent) => void>, disableClose: any) =>
        (e: any) => {
          if (!disableClose && popupRef?.current !== undefined) {
            popupRef.current.close()
          }

          onClick?.(e)
        },
      []
    )

    return (
      <Popup trigger={trigger} config={newConfig} {...rest}>
        <OptionsContainer>
          {(options as HeaderOption[]).map(
            (
              { label, onClick, path, e2eTargets, disabled, disableClose },
              index
            ) => {
              const customOnClick = path ? () => navigate(path) : onClick

              return (
                <Option
                  key={`${label}_${index}`}
                  onClick={
                    disabled
                      ? preventDefault
                      : handleClick(customOnClick || null, disableClose)
                  }
                  data-e2e-target={e2eTargets?.e2eTarget}
                  data-e2e-target-name={e2eTargets?.e2eTargetName}
                  disabled={disabled}
                >
                  <OptionLabel>{label}</OptionLabel>
                </Option>
              )
            }
          )}
        </OptionsContainer>
      </Popup>
    )
  }
)
