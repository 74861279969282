import React from "react"
import get from "lodash/get"
import { messages } from "setup/messages/messages"
import { H6, Div, Flex } from "@ikiru/talentis-fpc"
import { useSearch } from "views/search/SearchModule/context"
import { CurrentPastEitherLogic } from "views/search/SearchModule/types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { useRadioField, useCheckboxField } from "../hooks"
import { SearchCriteriaField } from "../definitions"
import {
  keywordsRolesLogicTemplateString,
  keywordsRecordPersonTemplateString,
  keywordsRecordCompanyTemplateString
} from "./consts"

export const LogicSelector = () => {
  const filterRolesName = keywordsRolesLogicTemplateString
  const fieldRolesName = SearchCriteriaField.KeywordsSearchLogic

  const filterRecordPersonName = keywordsRecordPersonTemplateString
  const fieldRecordPersonName = SearchCriteriaField.KeywordsSearchRecordPerson

  const filterRecordCompanyName = keywordsRecordCompanyTemplateString
  const fieldRecordCompanyName = SearchCriteriaField.KeywordsSearchRecordCompany

  const onRolesChange = useRadioField(filterRolesName)
  const onChangeRecordPerson = useCheckboxField(filterRecordPersonName)
  const onChangeRecordCompany = useCheckboxField(filterRecordCompanyName)
  const { filters } = useSearch()

  const keywordRolesLogic: any = get(
    filters,
    filterRolesName,
    CurrentPastEitherLogic.Current
  )
  const keywordRecordPerson = get(filters, filterRecordPersonName, true)
  const keywordRecordCompany = get(filters, filterRecordCompanyName, true)

  return (
    <Div width="100%" mt="xxs" backgroundColor="rgba(255,255,255,0.7)" p="xs">
      <H6 mt="xs" mb="xxs" color="grey.dark">
        {messages.talentGraphSearch.keywordSearchFieldSelector.label}
      </H6>
      <FormikToggle
        small
        type="checkbox"
        hideUnderline
        notCheckedFade
        onChange={onChangeRecordPerson.onChange}
        name={fieldRecordPersonName}
        checked={keywordRecordPerson === true}
        labelProps={{ ml: "xxs" }}
        value={CurrentPastEitherLogic.Current}
        label={messages.talentGraphSearch.keywordSearchFieldSelector.person}
      />
      <FormikToggle
        small
        type="checkbox"
        hideUnderline
        notCheckedFade
        onChange={onChangeRecordCompany.onChange}
        name={fieldRecordCompanyName}
        labelProps={{ ml: "xxs" }}
        value={CurrentPastEitherLogic.Current}
        checked={keywordRecordCompany === true}
        label={messages.talentGraphSearch.keywordSearchFieldSelector.company}
      />
      <H6 mt="xs" mb="xxs" color="grey.dark">
        {messages.talentGraphSearch.keywordRolesLogicSelector.label}
      </H6>
      <Flex>
        <Div mr="s">
          <FormikToggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={onRolesChange}
            value={CurrentPastEitherLogic.Current}
            checked={keywordRolesLogic === CurrentPastEitherLogic.Current}
            name={fieldRolesName}
            labelProps={{ ml: "xxs" }}
            label={
              messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
                .current
            }
          />
        </Div>
        <Div mr="s">
          <FormikToggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={onRolesChange}
            value={CurrentPastEitherLogic.Past}
            checked={keywordRolesLogic === CurrentPastEitherLogic.Past}
            name={fieldRolesName}
            labelProps={{ ml: "xxs" }}
            label={
              messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
                .past
            }
          />
        </Div>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          onChange={onRolesChange}
          value={CurrentPastEitherLogic.Either}
          checked={keywordRolesLogic === CurrentPastEitherLogic.Either}
          name={fieldRolesName}
          labelProps={{ ml: "xxs" }}
          label={
            messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
              .either
          }
        />
      </Flex>
    </Div>
  )
}
