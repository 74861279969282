import styled from "styled-components"
import {
  Div,
  Flex,
  colors,
  spacing,
  H5,
  PlusIcon,
  Link
} from "@ikiru/talentis-fpc"

type PlusIconStyledProps = {
  fillColor: string
  backgroundColor: string
}

export const StyledDiv = styled(Div)`
  width: 49% !important;
  position: absolute;
  z-index: 9;
  right: 0;
`

export const StyledFormOption = styled(Flex)`
  justify-content: space-between;
  margin: 0 ${spacing.s}px;
  padding: ${spacing.xs}px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
  }
`

export const StyledFormWrapper = styled(Flex)`
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 25px;
  flex-direction: column;
  border: 1px solid ${colors.grey.light};
  max-height: 290px;
`

export const StyledPopupTrigger = styled(Flex)`
  cursor: pointer;
  align-items: center;
  padding-top: ${spacing.xxs}px;
  padding-bottom: ${spacing.xxs}px;
  padding-right: ${spacing.xs}px;
`

export const StyledPopupOption = styled(Flex)`
  justify-content: space-between;
  margin: 0 ${spacing.s}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
  }
`

export const StyledTitle = styled(H5)`
  width: 340px;
  margin: 0;
  color: ${colors.grey.dark};
`

export const PlusIconStyled = styled(PlusIcon)<PlusIconStyledProps>`
  width: 24px;
  height: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  padding: 5px;
  margin-bottom: -8px;
  fill: ${({ fillColor }) => fillColor};
  cursor: pointer;
`

export const StyledLink = styled(Link)`
  margin: 0;
  &:focus-visible {
    outline: none;
  }
`
