import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { PlanDetailsForm } from "./form"
import { usePlanDetails } from "./hooks"
import { PersonTeam } from "./form/PersonTeam"

export const PlanDetails = () => {
  const formProps = usePlanDetails()
  const { subscribeUsers, changeSubscribeUsers } = formProps
  return (
    <Flex flexWrap="wrap" alignItems="center" flexDirection="column">
      <PersonTeam
        subscribeUsers={subscribeUsers}
        changeSubscribeUsers={changeSubscribeUsers}
      />
      <PlanDetailsForm {...formProps} />
    </Flex>
  )
}
