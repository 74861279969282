import React from "react"
import { messages } from "setup/messages/messages"
import { Route } from "react-router-dom"
import { InviteUser } from "views/team/invite-user/InviteUser"
import { H2 } from "@ikiru/talentis-fpc"
import { Team } from "./team"
import { Helmet } from "react-helmet"
import { CustomSwitch } from "setup/router/CustomSwitch"

export const TeamRoute = () => (
  <CustomSwitch>
    <Route
      path="/"
      element={
        <>
          <Helmet>
            <title>{messages.seo.title.format(messages.seo.team)}</title>
          </Helmet>
          <Team />
        </>
      }
    />

    <Route
      path="/invite-user"
      element={
        <>
          <Helmet>
            <title>{messages.seo.title.format(messages.seo.inviteUser)}</title>
          </Helmet>
          <H2>{messages.manageTeam.inviteUser}</H2>
          <InviteUser />
        </>
      }
    />
  </CustomSwitch>
)
