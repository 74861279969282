import styled from "styled-components"
import get from "lodash/get"
import { fontFamilies } from "@ikiru/talentis-fpc"

type StyledInputProps = {
  color: string
  background: string
}

export const StyledInput = styled.input<StyledInputProps>`
  width: 190px;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  color: ${({ theme, color }) => get(theme.colors, color)};
  background: ${({ theme, background }) => get(theme.colors, background)};
  font-size: 13px;
  font-weight: 500;
  font-family: ${fontFamilies.gibson};

  &:focus {
    outline: none;
    opacity: 0.7;
  }
`
