import React from "react"
import { Router } from "setup/router/Router"
import { Auth } from "setup/auth/module/Auth"
import { AuthMethod } from "setup/auth/module/constants/auth.types"
import { api } from "setup/api/api"
import { config } from "setup/auth/auth.config"
import { FPCTheme, Flex } from "@ikiru/talentis-fpc"
import { AlertProvider } from "setup/alert/Provider"
import { IntlModule } from "setup/intl/IntlModule"
import Chargebee from "setup/chargebee"
import { Loader } from "components/Loader/Loader"
import {
  ReactPlugin,
  withAITracking
} from "@microsoft/applicationinsights-react-js"
import { ai } from "setup/app-insights/Service"
import AppInsightsProvider from "setup/app-insights/Provider"
import { FPCTheme as TalentisFPCTheme, theme } from "@ikiru/talentis-fpc"
import { ThemeProvider } from "styled-components"
import { setupUserGuiding } from "setup/userGuiding"

Chargebee()
setupUserGuiding()

const App = () => {
  return (
    <AppInsightsProvider>
      <IntlModule>
        <FPCTheme>
          <TalentisFPCTheme>
            <ThemeProvider {...{ theme }}>
              <Auth
                loader={
                  <Flex height="100svh">
                    <Loader />
                  </Flex>
                }
                config={config}
                method={AuthMethod.Redirect}
                api={api}
              >
                <AlertProvider>
                  <Router />
                </AlertProvider>
              </Auth>
            </ThemeProvider>
          </TalentisFPCTheme>
        </FPCTheme>
      </IntlModule>
    </AppInsightsProvider>
  )
}

export default withAITracking(ai.reactPlugin as ReactPlugin, App)
