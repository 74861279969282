import { tempInnerModalStyles } from "setup/modal/modal.helpers"
import styled from "styled-components"
import styledCss from "@styled-system/css"
import { Button, Div } from "@ikiru/talentis-fpc"

export const NewContactModalWrapper = styled(Div)`
  ${styledCss(tempInnerModalStyles)}
  padding: 0px;
  margin: 0px;
  border-radius: 0;
  @media (min-width: 508px) {
    min-width: clamp(80vw, 95vw, 95vw);
    min-width: clamp(80svw, 95svw, 95svw);
  }

  @media (max-width: 508px) {
    width: 100%;
    max-width: 450px;
  }

  max-height: 90dvh;
  background: ${({ theme }) => theme.colors.grey.lightest};
`

export const CloseButton = styled(Button)`
  position: absolute;
  right: 0;

  & svg {
    width: 18px;
    height: 18px;
  }
`
