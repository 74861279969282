import React, { MutableRefObject } from "react"
import { Formik, Form } from "formik"
import { FormikDropzone } from "components/functional/formik/formik-dropzone/FormikDropzone"
import { Dropzone } from "components/functional/dropzone/Dropzone"
import { OnUploadPersonDocumentSubmit } from "./upload-person-document.actions"
import {
  UploadPersonDocumentInitialValues,
  UploadPersonDocumentField
} from "./constants/upload-person-document.definitions"

type UploadPersonDocumentProps = {
  onSubmit: OnUploadPersonDocumentSubmit
  buttonRef?: MutableRefObject<HTMLButtonElement>
  onFileDialogCancel: () => void
}

export const UploadPersonDocument = (props: UploadPersonDocumentProps) => {
  const { onSubmit, buttonRef, onFileDialogCancel } = props
  return (
    <Formik
      initialValues={UploadPersonDocumentInitialValues}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { resetForm } = formik
        return (
          <Form>
            <FormikDropzone
              innerRef={buttonRef}
              id={UploadPersonDocumentField.File}
              name={UploadPersonDocumentField.File}
              onFileDialogCancel={onFileDialogCancel}
              renderDropzoneComponent={(props) => (
                <Dropzone {...props} resetForm={resetForm} />
              )}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
