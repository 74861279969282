import React from "react"
import { OffLimitsData } from "./OffLimitsData"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { patchOffLimits, patchInheritedCompanyOffLimits } from "./actions"
import { parseMomentDate } from "utils/moment"
import { OverrideType } from "components/OffLimits/type"

export const PersonOffLimits = () => {
  const {
    setOffLimits,
    personId,
    setProfileDetails,
    profileDetails,
    setPersonOverlayUpdated
  } = usePerson()

  const onSubmit = async (newOffLimits: any) => {
    const response = await patchOffLimits(personId, newOffLimits)
    const endDate = parseMomentDate(response?.localPerson?.offlimits?.endDate)

    setOffLimits({ ...response?.localPerson?.offlimits, endDate })
    setProfileDetails({
      ...profileDetails,
      isOfflimits: response?.localPerson?.isOfflimits
    })
    setPersonOverlayUpdated(true)
  }

  const onSubmitInheritedOffLimits = async (
    overrideExperiences: OverrideType[]
  ) => {
    for (let i = 0; i < overrideExperiences.length; i++) {
      const overrideExperience = overrideExperiences[i]
      const payload = {
        offlimitsOverride: {
          isOverridden: overrideExperience.isOverridden
        }
      }

      const response = await patchInheritedCompanyOffLimits(
        personId,
        overrideExperience.id,
        payload
      )

      if (i === overrideExperiences.length - 1) {
        setProfileDetails({
          ...profileDetails,
          isOfflimits: response.isOfflimits,
          experience: {
            isReadOnly: profileDetails.experience.isReadOnly,
            value: response.experience
          }
        })
      }
    }
    setPersonOverlayUpdated(true)
  }

  return (
    <OffLimitsData
      onSubmitOffLimits={onSubmit}
      onSubmitInheritedOffLimits={onSubmitInheritedOffLimits}
    />
  )
}
