import React from "react"
import { pick } from "lodash"
import { EditableSection } from "components/EditableSection"
import { AssignmentTagsPrimaryData } from "./constants/definitions"
import { Assignment } from "views/assignments/assignment.types"
import { patchAssignment } from "views/assignments/actions"
import { messages } from "setup/messages/messages"
import {
  TagsDataField,
  TagsPrimaryData
} from "components/Tags/constants/definitions"
import { TagList } from "components/Tags/TagList"
import { TagsForm } from "components/Tags/form"

type AssignmentTagsProps = Assignment & {
  onAssignmentUpdated: (assignment: Assignment) => void
}

export const AssignmentTags = React.memo(
  ({ onAssignmentUpdated, ...data }: AssignmentTagsProps) => {
    const assignmentTagsData: AssignmentTagsPrimaryData = pick(
      data,
      Object.values(TagsDataField)
    )

    return (
      <EditableSection
        isDefaultExpanded
        targetName={TagsDataField.Tags}
        size="xxSmall"
        header={{
          title: messages.assignment.assignmentTags
        }}
        view={() => <TagList tags={assignmentTagsData.tags} />}
        edit={(editableControls) => {
          const onSubmit = patchAssignment<TagsPrimaryData>(
            data,
            (assignment) => {
              onAssignmentUpdated(assignment)
              editableControls.setViewMode()
            }
          )

          return (
            <TagsForm initialValues={assignmentTagsData} onSubmit={onSubmit} />
          )
        }}
      />
    )
  }
)
