import * as Yup from "yup"
import { SupportFormField } from "./support.definitions"

export const supportValidationSchema = Yup.object({
  [SupportFormField.Name]: Yup.string().required("Required"),
  [SupportFormField.Company]: Yup.string().required("Required"),
  [SupportFormField.Email]: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  [SupportFormField.Message]: Yup.string().required("Required")
})
