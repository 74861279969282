const getBrowser = () => {
  const userAgent = navigator.userAgent
  let browser

  switch (true) {
    case userAgent.includes("Edg"):
      browser = "Edge"
      break
    case userAgent.includes("Chrome"):
      browser = "Chrome"
      break
    default:
      browser = "Other"
  }

  return browser
}

export default getBrowser
