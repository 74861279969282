import { messages } from "setup/messages/messages"

export const formalList = [
  {
    label: messages.project.outReach.formal,
    value: "formal"
  },
  {
    label: messages.project.outReach.informal,
    value: "informal"
  }
]
