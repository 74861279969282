import React from "react"
import { Avatar, Div, H5, SmallText } from "@ikiru/talentis-fpc"
import { SearchDropdown, SearchDropdownItem, StyledDiv } from "./style"
import { SearchCompany } from "./types"

type ListCompanyProps = {
  companies: SearchCompany[]
  onItemClicked: (company: SearchCompany) => void
  editStyle?: boolean
}

export const ListCompany = ({
  companies,
  onItemClicked,
  editStyle
}: ListCompanyProps) => {
  const onClick = (company: SearchCompany) => {
    onItemClicked(company)
  }

  return (
    <Div
      {...(editStyle ? { position: "relative" } : { position: "absolute" })}
      zIndex={10}
      width="100%"
    >
      <SearchDropdown>
        {companies.map((company: SearchCompany) => (
          <SearchDropdownItem key={company.id} onClick={() => onClick(company)}>
            <StyledDiv>
              <Avatar name={company.name} />
            </StyledDiv>
            <Div>
              <H5 m="0">{company.name}</H5>
              <SmallText m="0">{company.location}</SmallText>
            </Div>
          </SearchDropdownItem>
        ))}
      </SearchDropdown>
    </Div>
  )
}
