import React, { useCallback } from "react"
import pick from "lodash/pick"
import { ViewAssignmentData } from "./components/ViewAssignmentData"
import {
  AssignmentDataField,
  AssignmentPrimaryData
} from "./constants/definitions"
import { Assignment } from "views/assignments/assignment.types"
import { patchAssignment } from "views/assignments/actions"
import { messages } from "setup/messages/messages"
import {
  Button,
  Div,
  Flex,
  H5,
  PencilIcon,
  Section,
  colors
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { EditAssignData } from "./components/AssignmentDataModal/EditAssignData"
import { GatedTalentLogoIcon as GTIcon } from "@ikiru/talentis-fpc"
import { StyledButton, StyledDiv, StyledLink, StyledShareIcon } from "./style"
import { PackageData } from "./components/AssignmentSalaryPackage/PackageData"
import { FeeData } from "./components/AssignmentFee/FeeData"
import { useTeam } from "views/team/team/team-module.context"

type AssignmentDataProps = Assignment & {
  onAssignmentUpdated: (assignment: Assignment) => void
}

export const AssignmentData = React.memo(
  ({ onAssignmentUpdated, ...data }: AssignmentDataProps) => {
    const assignmentData: AssignmentPrimaryData = pick(
      data,
      Object.values(AssignmentDataField)
    )

    const { open } = useModal()
    const { teamMembers } = useTeam()

    const onSubmit = patchAssignment<AssignmentPrimaryData>(
      data,
      (assignment) => {
        onAssignmentUpdated(assignment)
      }
    )

    const assignOpenModal = useCallback(() => {
      open(
        ModalNames.EditAssignData,
        <EditAssignData
          initialValues={assignmentData}
          onSubmit={onSubmit}
          assignmentTeamMembers={data.teamMembers}
          teamMembers={teamMembers}
        />
      )
    }, [assignmentData, onSubmit, open, data.teamMembers, teamMembers])

    return (
      <>
        <Section
          size="xxSmall"
          header={{
            title: messages.assignment.assignmentTitle,
            // @ts-ignore
            actions: (
              <Button
                mode="standard-white"
                size="action-medium"
                onClick={assignOpenModal}
              >
                <PencilIcon />
              </Button>
            )
          }}
        >
          <ViewAssignmentData
            assignmentId={data.id}
            {...assignmentData}
            assignmentTeamMembers={data.teamMembers}
          />
        </Section>
        <PackageData {...data} onAssignmentUpdated={onAssignmentUpdated} />
        <FeeData {...data} onAssignmentUpdated={onAssignmentUpdated} />
        <StyledDiv>
          <Div>
            <GTIcon />
          </Div>
          <Flex alignItems="center">
            <H5 m={0} mr="xs" color={colors.grey.dark}>
              {messages.assignment.assignmentGatedTalent}
            </H5>
            <StyledLink
              href={`https://tinyurl.com/26z5ux2b`}
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton prefix={<StyledShareIcon />} size="small">
                {messages.clientPortal.share}
              </StyledButton>
            </StyledLink>
          </Flex>
        </StyledDiv>
      </>
    )
  }
)
