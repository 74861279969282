import React, { useState } from "react"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import {
  CreatePersonField,
  CreatePersonValues
} from "views/persons/components/person-details/form/constants/definitions"
import { Inner } from "components/visual/layout/layout.styled"
import { messages } from "setup/messages/messages"
import { PersonDetailsForm as CreatePersonForm } from "views/persons/components/person-details/form"
import { Grid } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { Person } from "views/persons/person.types"
import {
  onCreatePersonSubmit,
  OnPersonDetailsSubmitType
} from "views/persons/components/person-details/form/actions"
import { ExistingPersons } from "views/persons/pages/create-person/components/ExistingPersons"
import { H2 } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { useNavigate } from "react-router-dom"
import { e2eTargets } from "views/persons/components/person-data-privacy/definitions"

export const CreatePerson = () => {
  const [existingPersons, setExistingPersons] = useState<Person[]>([])
  const navigate = useNavigate()

  const checkPersonsExist = (values: CreatePersonValues) => {
    return async () => {
      const [error, response] = await apiRequest.get({
        endpoint: PersonsEndpoints.Root,
        config: {
          params: {
            name: values[CreatePersonField.Name],
            email: values[CreatePersonField.EmailAddresses][0]?.value?.email
          },
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (!error) {
        setExistingPersons(response?.data?.persons as Person[])
      } else {
        clearExistingPersonsList()
      }
    }
  }

  const clearExistingPersonsList = () => {
    setExistingPersons([])
  }

  const onSubmit: OnPersonDetailsSubmitType = async (values, helpers) => {
    const [, response] = await onCreatePersonSubmit(values, helpers)

    if (response) {
      const profile = response.data as Person
      clearExistingPersonsList()
      navigate(RouterUrl.ProfileDetails + "/" + profile.id)
    }
  }

  return (
    // @ts-ignore
    <Grid columns={8}>
      <Inner style={{ gridColumn: "1 / 6" }}>
        <H2>{messages.createPerson.title}</H2>
        <CreatePersonForm
          onSubmit={onSubmit}
          checkPersonsExist={checkPersonsExist}
          formActionsComponent={({ isSubmitting }) => (
            <Button
              mt="s"
              data-e2e-target={e2eTargets.submitButton}
              type="submit"
              disabled={isSubmitting}
              size="small"
            >
              {messages.createPerson.submitCta}
            </Button>
          )}
        />
      </Inner>
      {Boolean(existingPersons.length) && (
        <ExistingPersons persons={existingPersons} />
      )}
    </Grid>
  )
}
