import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { urlRegex } from "utils/regexes"
import { CreatePersonField } from "views/persons/components/person-details-tab/constant/definitions"

export const personWebSiteValidationSchema = Yup.object({
  [CreatePersonField.LinkedInUrl]: Yup.object({
    value: Yup.string()
      .matches(urlRegex, messages.person.details.errors.invalidLink)
      .max(150, messages.form.generic.errors.mustBeNoLongerThan.format(150))
  }),
  [CreatePersonField.WebSites]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        url: Yup.string().matches(
          urlRegex,
          messages.form.generic.errors.urlFormat
        ),
        type: Yup.string()
      })
    })
  )
})
