import { Nullable } from "tsdef"

export type ContactLookup = {
  personId: string
  emailTeasers: string[]
  phoneTeasers: string[]
  rocketReachPreviouslyFetchedTeasers?: boolean
}

export type ContactLookupTeaserRequest = {
  hasEmails: boolean
  hasPhoneNumbers: boolean
  personId: string
  searchResponseId: any
  status: string
}

export type ContactLookupDetails = {
  taggedEmails: Nullable<string[]>
  phoneNumbers: Nullable<string[]>
  rocketReachPreviouslyFetchedTeasers: boolean
  creditsExpired?: boolean
  personId: string
}

export const rocketReachTeasersE2ETargets = {
  listing: "email-teasers",
  notFound: "email-teasers-not-found"
}
