import React, { useMemo } from "react"
import moment from "moment"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { personDocumentsE2eTargets } from "views/persons/components/person-documnets/definitions"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { Link } from "@ikiru/talentis-fpc"
import { Line } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"

export const PersonDocumentList = () => {
  const { documents, personId } = usePerson()

  const sortedDocuments = useMemo(
    () => documents?.sort((a, b) => moment(b.createdDate).diff(a.createdDate)),
    [documents]
  )

  const onDocumentClick = (documentId: string) => {
    return async () => {
      const [error, response] = await apiRequest.get({
        endpoint: PersonsEndpoints.DownloadDocument,
        endpointParams: { id: personId, documentId },
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      !error && window.open(response?.data?.temporaryUrl)
    }
  }

  return (
    <Div width="100%">
      {sortedDocuments.length ? (
        <Flex width="100%" flexWrap="wrap">
          {sortedDocuments?.map((document, index) => (
            <Div key={document.id} width="100%">
              {index !== 0 ? <Line variant="thin" /> : null}
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                pt={index === 0 ? "xs" : "s"}
                pb="s"
                data-e2e-target={personDocumentsE2eTargets.personDocument}
                flexWrap={["wrap", "nowrap"]}
              >
                <Div width={["100%", "auto"]}>
                  <Link
                    as="button"
                    type="button"
                    standalone
                    data-e2e-target={
                      personDocumentsE2eTargets.personDocumentLink
                    }
                    m={0}
                    textAlign="left"
                    onClick={onDocumentClick(document.id)}
                  >
                    {document.filename}
                  </Link>
                </Div>
                <SmallText m={0} mt={["xs", 0]}>
                  {formatDateTime(document.createdDate)}
                </SmallText>
              </Flex>
            </Div>
          ))}
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <PersonNoDataScreen
            message={messages.person.personTabSection.noDataTexts.noDocuments}
          />
        </Flex>
      )}
    </Div>
  )
}
