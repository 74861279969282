import React from "react"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  AssignmentPackageDataField,
  currenciesOptions
} from "views/assignments/components/assignment-data/constants/definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"

export const AssignmentPackageSalaryFormLayout = () => {
  return (
    <Div alignItems="center" justifyContent="stretch" position="relative">
      <Flex mb="xs" flexWrap="wrap">
        <Div minWidth="230px" mr="xs">
          <FormikSelect
            height={400}
            options={currenciesOptions()}
            id={AssignmentPackageDataField.Currency}
            name={AssignmentPackageDataField.Currency}
            label={messages.assignment.assignmentCurrenry}
          />
        </Div>
        <Div minWidth="230px" mr="xs">
          <FormikInput
            id={AssignmentPackageDataField.SalaryFrom}
            name={AssignmentPackageDataField.SalaryFrom}
            label={messages.assignment.assignmentSalaryFromMonthly}
          />
        </Div>
        <Div minWidth="230px">
          <FormikInput
            id={AssignmentPackageDataField.SalaryTo}
            name={AssignmentPackageDataField.SalaryTo}
            label={messages.assignment.assignmentSalaryToMonthly}
          />
        </Div>
      </Flex>
      <Div mt="xs">
        <FormikTextarea
          rows={3}
          id={AssignmentPackageDataField.PackageComment}
          name={AssignmentPackageDataField.PackageComment}
          label={messages.assignment.assignmentPackageComment}
          resize="none"
        />
      </Div>
    </Div>
  )
}
