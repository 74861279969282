import React from "react"
import GPTTemplateInput from "."
import { AITemplatesType, AiTemplatesName } from "../../constants/definitions"
import TemplateFormOptions from "../AISelect"
import {
  Flex,
  SmallText,
  theme,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useMediaQuery } from "utils/hooks/use-media-query"

type CoreMessageInputProps = {
  templateValues: AITemplatesType
  format: string
  language: string
  onGenerateTemplate: (
    format: string,
    language: string,
    coreMessage: string
  ) => void
  onChangeCoreTemplate: (name: AiTemplatesName, newValue: string) => void
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  setFormat: React.Dispatch<React.SetStateAction<string>>
  defaultSearchPlaceHolder: string
}

const CoreMessageInput = (props: CoreMessageInputProps) => {
  const {
    format,
    language,
    onChangeCoreTemplate,
    onGenerateTemplate,
    setFormat,
    setLanguage,
    templateValues,
    defaultSearchPlaceHolder
  } = props
  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)
  return (
    <Flex flexDirection="column" bg={theme.colors.green.light} flexGrow="1">
      <GPTTemplateInput
        value={templateValues?.coreMessage || ""}
        setValue={(value: any) =>
          onChangeCoreTemplate(AiTemplatesName.CoreMessage, value)
        }
        onSubmit={async () =>
          onGenerateTemplate(format, language, templateValues.coreMessage || "")
        }
        defaultSearchPlaceHolder={defaultSearchPlaceHolder}
      />

      <Flex width="100%" justifyContent="space-between">
        {!isMobile && (
          <SmallText ml="xl">
            {messages.project.outReach.gptDisclaimer}
          </SmallText>
        )}
        <Flex flexDirection="column" width="100%" alignItems="flex-end">
          <TemplateFormOptions
            setFormat={setFormat}
            setLanguage={setLanguage}
          />
          {isMobile && (
            <SmallText mx="xl" textAlign="center" mt="-20px" mb="m">
              {messages.project.outReach.gptDisclaimer}
            </SmallText>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CoreMessageInput
