import { Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const AISearchContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.green.light};
  padding-bottom: ${({ theme }) => theme.space.xl}px;
`
