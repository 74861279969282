import styled from "styled-components"
import { BodyText, StyledSystemProps, colors } from "@ikiru/talentis-fpc"

export const ImageText = styled.span`
  font-size: 13px;
`

export const SmallImg = styled(BodyText)<StyledSystemProps>`
  font-size: 13px;
  color: ${colors.grey.dark};
  margin-top: 15px;
  margin-bottom: 5px;
`

export const List = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
  li {
    text-align: left;
    padding: 0 0 10px 10px;
    ::before {
      content: "\\1F449";
      font-size: 25px;
      margin-right: 10px;
      position: relative;
      bottom: -5px;
    }
  }
`
