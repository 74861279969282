import { messages } from "setup/messages/messages"

export const roleDescriptions = {
  [messages.manageTeam.roles.owner]: Object.values(
    messages.manageTeam.roles.ownerPermissions
  ),
  [messages.manageTeam.roles.admin]: Object.values(
    messages.manageTeam.roles.adminPermissions
  ),
  [messages.manageTeam.roles.teamMember]: Object.values(
    messages.manageTeam.roles.teamMemberPermissions
  )
}
