import { TalentisUser, UserRoles } from "setup/auth/module/constants/auth.types"

export enum ToggleActiveResponse {
  NoAvailableLicenses = "NoAvailableLicenses"
}

export type TeamState = {
  isLoading: boolean
  teamMembers: TeamMember[]
  invited: TeamMember[]
  inactiveMembers: TeamMember[]
  invitationsResent: Record<string, boolean>
  selectedTeamMember?: string
}

export type TeamMember = {
  id: string
  firstName: string
  lastName: string
  isDisabled?: boolean
  status: TeamMemberStatus
  userRole: UserRoles
  jobTitle: string
  emailAddress: string
  invitedBy?: Pick<
    TeamMember,
    "emailAddress" | "firstName" | "lastName" | "jobTitle"
  >
  confirmationEmailSent?: boolean
  confirmationEmailSentDate?: Date
  newInvite?: boolean
}

export enum TeamMemberStatus {
  Complete = "complete",
  Invited = "invited",
  InvitedForNewSearchFirm = "invitedForNewSearchFirm"
}

export enum TeamActionTypes {
  SetIsLoading = "setIsLoading",
  SetTeamMembers = "setTeamMembers",
  AddInvitationResent = "addInvitationResent",
  UpdateTeamMemberRole = "updateTeamMemberRole",
  SetSelectedTeamMember = "setSelectedTeamMember"
}

type SelectRoleOption = {
  value: UserRoles
  label: string
}

export type TeamContextValues = TeamState & {
  user?: TalentisUser
  amIOwner: boolean
  possibleRoles: SelectRoleOption[]
  activeOwnersCount: number
  toggleActive: (memberId: string, isActive: boolean) => void
  onMemberRoleChange: (memberId: string, role: UserRoles) => void
  fetchTeamMembers: VoidFunction
  setTeamMembers: SetTeamMembers
  onResendInvitationLink: (memberId: string) => void
  onRemoveInvitationLink: (memberId: string) => void
}

export type SetIsLoading = (data: boolean) => void
export type SetTeamMembers = (
  teamMembers: TeamMember[],
  invited: TeamMember[],
  inactiveMembers: TeamMember[],
  invitationsResent?: Object
) => void
export type AddInvitationResent = (teamMemberId: string) => void
export type UpdateTeamMemberRole = (
  teamMemberId: string,
  role: UserRoles
) => void
export type SetSelectedTeamMember = (teamMemberId?: string) => void

export type TeamAction = {
  type: TeamActionTypes
  payload?: any
}
