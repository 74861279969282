import React from "react"
import { Loader } from "components/Loader/Loader"
import { RegisterInvitedUserTokenInvalid } from "views/users/finish-registration/components/RegisterInvitedUserTokenInvalid"
import { RegisterInvitedUserTokenValid } from "views/users/finish-registration/components/RegisterInvitedUserTokenValid"
import {
  useInvitationDetails,
  RegisterInvitedUserViewState
} from "views/users/finish-registration/hooks/use-invitation-details"

import { Div, Flex } from "@ikiru/talentis-fpc"
import { FullWoIcon as FullWo } from "@ikiru/talentis-fpc"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"

export const FinishRegistration = () => {
  const { viewState, user, errorMessage } = useInvitationDetails()
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.register)}</title>
      </Helmet>
      <Flex
        overflowY="auto"
        width="100%"
        height="100svh"
        bg="green.dark"
        alignItems="center"
        flexDirection="column"
      >
        <Flex my="l" width="100%" justifyContent="center">
          <FullWo height="42px" />
        </Flex>
        <Div width={["100%", "100%", "420px"]}>
          <Flex
            height="100%"
            maxHeight={["auto", "auto", "560px"]}
            flexWrap="wrap"
            bg="grey.lightest"
            p={["xxs", "xl"]}
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            {viewState === RegisterInvitedUserViewState.Loading && <Loader />}
            {viewState === RegisterInvitedUserViewState.Invalid && (
              <RegisterInvitedUserTokenInvalid errorMessage={errorMessage} />
            )}
            {viewState === RegisterInvitedUserViewState.Valid && (
              <RegisterInvitedUserTokenValid
                email={user.email}
                company={user.company}
                userId={user.id}
                searchFirmId={user.searchFirmId}
              />
            )}
          </Flex>
        </Div>
      </Flex>
    </>
  )
}
