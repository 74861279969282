import React, { useMemo } from "react"
import { messages } from "setup/messages/messages"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { Line } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { SummaryHeader, SummaryPrice, PlanName } from "./style"
import { formatPrice } from "utils/format-price"
import { Frequency } from "views/subscription/components/plan-details/form/constants/plan-details.definitions"
import { Currency } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import {
  Coupon,
  CouponType
} from "views/subscription/subscription-purchase-module/coupons"

export interface OrderSummaryProps {
  totalPrice: number
  taxAmount: number
  discount: number
  subtotal: number
  currencyCode: Currency
  numberOfUsers: number
  frequency: Frequency
  planName: string
  appliedCoupons: Coupon[]
  reverse?: boolean
  confirmationMessage?: string
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const {
    currencyCode,
    totalPrice,
    taxAmount,
    discount,
    subtotal,
    numberOfUsers,
    frequency,
    planName,
    appliedCoupons,
    reverse,
    confirmationMessage
  } = props

  const { user, users, oneMonth, twelveMonths, totalCostOfPlan, taxes } =
    messages.subscription.orderSummary

  const price = useMemo(
    () => formatPrice(totalPrice, currencyCode),
    [totalPrice, currencyCode]
  )

  const formattedSubtotal = useMemo(
    () => formatPrice(subtotal, currencyCode),
    [subtotal, currencyCode]
  )

  const formattedTax = useMemo(
    () => formatPrice(taxAmount, currencyCode),
    [taxAmount, currencyCode]
  )

  const formattedDiscount = useMemo(
    () => formatPrice(discount, currencyCode),
    [discount, currencyCode]
  )

  const discountMessage = useMemo(() => {
    const userCoupon = appliedCoupons.find(
      (coupon) => coupon.type === CouponType.USER_COUPON
    )

    return userCoupon
      ? userCoupon.code
      : messages.subscription.orderSummary.discount
  }, [appliedCoupons])

  const pricePerUser = useMemo(() => {
    const monthlyPrice =
      frequency === Frequency.Month ? subtotal : subtotal / 12
    return formatPrice(monthlyPrice / numberOfUsers, currencyCode)
  }, [subtotal, numberOfUsers, currencyCode, frequency])

  const priceBreakdownString = useMemo(
    () =>
      `${pricePerUser} x ${numberOfUsers} ${
        numberOfUsers > 1 ? users : user
      } x ${frequency === Frequency.Month ? oneMonth : twelveMonths}`,
    [
      pricePerUser,
      numberOfUsers,
      frequency,
      users,
      user,
      oneMonth,
      twelveMonths
    ]
  )

  const numberOfUsersString = useMemo(
    () => `${numberOfUsers} ${numberOfUsers > 1 ? users : user}`,
    [numberOfUsers, users, user]
  )

  return (
    <Flex
      width={["100%", "100%", 210]}
      maxWidth={[360, 360, 210]}
      margin={["auto", "auto", 0]}
      flexDirection={reverse ? "column-reverse" : "column"}
    >
      <Div bg="white.standard" p="s">
        <SummaryHeader color="grey.dark">{totalCostOfPlan}:</SummaryHeader>
        <SummaryPrice>{price}</SummaryPrice>

        <Line variant="thin" />
        <Flex justifyContent="space-between" mt="xs">
          <SmallText>{priceBreakdownString}</SmallText>
          <SmallText fontWeight="600">{formattedSubtotal}</SmallText>
        </Flex>

        {Boolean(discount) && (
          <Flex justifyContent="space-between" mt="xxs">
            <Flex flexDirection="column">
              {Boolean(appliedCoupons.length) && (
                <SmallText mb="0" fontWeight="600">
                  {messages.subscription.promoCode.promo}
                </SmallText>
              )}
              <SmallText mb="0">{discountMessage}</SmallText>
            </Flex>
            <SmallText mb="0" fontWeight="600">
              -{formattedDiscount}
            </SmallText>
          </Flex>
        )}

        <Flex justifyContent="space-between" mt="xxs">
          <SmallText mb="0">{taxes}</SmallText>
          <SmallText mb="0" fontWeight="600">
            {formattedTax}
          </SmallText>
        </Flex>
      </Div>

      <Div bg="grey.light" p="s">
        {confirmationMessage && (
          <SummaryHeader color="grey.standard">
            {confirmationMessage}
          </SummaryHeader>
        )}
        <PlanName>{planName}</PlanName>
        <PlanName color="grey.dark">{numberOfUsersString}</PlanName>
      </Div>
    </Flex>
  )
}
