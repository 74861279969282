import React from "react"
import {
  ColorThemeType,
  Pagination as StyledPagination
} from "@ikiru/talentis-fpc"
import paginate from "jw-paginate"

export const e2eTargets = {
  container: "pagination-container",
  nextArrow: "pagination-next-arrow",
  backArrow: "pagination-back-arrow",
  number: "pagination-number"
}

export type PaginationProps = {
  firstItemOnPage?: number
  totalItemCount: number
  pageSize: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageNumber: number
  maxPages: number
  pageCount: number
  onPageChange: (pageNumber: number) => void
  isFirstPage?: boolean
  isLastPage?: boolean
  lastItemOnPage?: boolean
  isSmall?: boolean
  colorTheme?: ColorThemeType
}

export const Pagination = (props: PaginationProps) => {
  const {
    totalItemCount,
    pageSize,
    hasNextPage,
    hasPreviousPage,
    pageNumber,
    maxPages = 5,
    onPageChange,
    isSmall = false,
    colorTheme,
    ...other
  } = props

  const { pages } = paginate(totalItemCount, pageNumber, pageSize, maxPages)

  return (
    <StyledPagination
      e2eTargets={e2eTargets}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      range={pages}
      currentPage={pageNumber}
      onPageChange={onPageChange}
      isSmall={isSmall}
      colorTheme={colorTheme}
      {...other}
    />
  )
}
