import { apiRequest } from "setup/api/api"
import {
  CandidatesEndpoints,
  PersonsEndpoints
} from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { BioAddValues, GetOnAddBioSubmitType } from "./types"
import { Candidate } from "../../../views/candidates/candidates.types"
import { skipErrorHeader } from "../../../setup/api/utils/skip-error-header"
import { FormikHelpers } from "formik"

export const getOnAddPersonBiographySubmit: GetOnAddBioSubmitType =
  (candidate: Candidate, onSubmit) =>
  async (values: BioAddValues, helpers: FormikHelpers<BioAddValues>) => {
    const { setFieldError } = helpers
    const candidateNewBio = values.setAsMainBiography
      ? null
      : values.biographyDescription

    if (values.setAsMainBiography) {
      await apiRequest.patch({
        endpoint: PersonsEndpoints.Root,
        data: { bio: values.biographyDescription },
        endpointParams: candidate.personId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })
    }

    const [error, response] = await apiRequest.patch({
      endpoint: CandidatesEndpoints.Root,
      endpointParams: candidate.id,
      data: {
        bio: {
          data: candidateNewBio
        }
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }
