import { useState } from "react"

export const useSpeechToText = () => {
  const [previewText, setPreviewText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isListening, setIsListening] = useState(false)

  if ("webkitSpeechRecognition" in window) {
    const SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition

    const recognition = new SpeechRecognition()
    recognition.continuous = true
    recognition.interimResults = true
    recognition.lang = "en-US"

    recognition.onstart = () => {
      setIsListening(true)
      setIsLoading(false)
    }

    const startListening = (initialValue: string) => {
      if (!("webkitSpeechRecognition" in window)) return
      setPreviewText(initialValue)

      let finalTranscripts = initialValue
      recognition.onresult = function (event) {
        var interimTranscripts = ""
        for (var i = event.resultIndex; i < event.results.length; i++) {
          var transcript = event.results[i][0].transcript
          transcript.replace("\n", "<br>")
          if (event.results[i].isFinal) {
            finalTranscripts += transcript + " "
          } else {
            setPreviewText(
              finalTranscripts + (interimTranscripts += transcript)
            )
          }
        }
      }
    }

    const stopListening = () => {
      setIsListening(false)
      recognition.stop()
      recognition.abort()
    }

    const toggleMic = (initialValue: string) => {
      if (isListening) {
        setIsListening(false)
        recognition.stop()
        recognition.abort()
      } else {
        recognition.start()
        setIsLoading(true)
        startListening(initialValue)
      }
    }

    return { toggleMic, isListening, stopListening, previewText, isLoading }
  } else {
    return {}
  }
}
