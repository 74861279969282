import React, { useCallback, useEffect } from "react"
import { FieldArray, FastField, FieldProps, useFormikContext } from "formik"
import get from "lodash/get"
import { Input, Flex, Div, Button, PlusIcon, Error } from "@ikiru/talentis-fpc"
import { FieldArrayActionButtons } from "./FieldArrayActionButtons"
import { getStatus } from "../hooks/use-status"

export type Primitive = string | number | boolean | object

type FormikArrayFieldProps = {
  id: React.Key
  name: string
  SubHeader?: React.ElementType
  e2eTarget?: string
  label?: string
  component?: React.ComponentType<React.PropsWithChildren<any>>
  ComponentProps?:
    | Record<string, any>
    | ((name: string, index: number) => Record<string, any>)
  initialValue?: Primitive
  displayAddMoreBtn?: boolean
  displayActionBtn?: boolean
  nestedKey?: string
  autoFocusFirstElement?: boolean
  inputContainerStyle?: Record<string, any>
  onChange?: (
    event: React.SyntheticEvent,
    fieldName: string,
    index: number
  ) => void
}

export const FormikArrayField = (props: FormikArrayFieldProps) => {
  const {
    label,
    name,
    e2eTarget = name,
    component: Component = Input,
    ComponentProps,
    initialValue = "",
    displayAddMoreBtn = true,
    displayActionBtn = true,
    nestedKey = "",
    onChange,
    autoFocusFirstElement,
    inputContainerStyle,
    SubHeader
  } = props
  const { values, setFieldValue } = useFormikContext()

  const arrayValues = get(values, name, [])

  useEffect(() => {
    if (!arrayValues.length) {
      setFieldValue(name, [initialValue])
    }
  }, [setFieldValue, arrayValues, initialValue, name])

  const handlePush = useCallback(
    (e: any) => {
      e.stopPropagation()
      setFieldValue(name, [...arrayValues, initialValue])
    },
    [setFieldValue, arrayValues, name, initialValue]
  )

  return (
    <Flex data-e2e-target={e2eTarget}>
      <Div width="100%">
        {SubHeader && <SubHeader />}
        <FieldArray
          name={name}
          render={(arrayHelpers) =>
            arrayValues.map((_: string, index: number) => {
              const fieldName = `${name}[${index}]${
                nestedKey ? `.${nestedKey}` : ""
              }`
              return (
                <FastField name={fieldName} key={fieldName}>
                  {({ field, meta }: FieldProps) => {
                    const componentProps = ComponentProps
                      ? ComponentProps instanceof Function
                        ? ComponentProps(name, index)
                        : ComponentProps
                      : {}
                    return (
                      <>
                        <Flex width="100%" {...inputContainerStyle}>
                          <Component
                            {...field}
                            {...componentProps}
                            onChange={(e) => {
                              onChange?.(e, name, index)
                              field.onChange(e)
                            }}
                            data-e2e-target-name={`${e2eTarget}-${index}`}
                            label={label}
                            name={fieldName}
                            status={getStatus(
                              meta.touched,
                              Boolean(meta.error),
                              meta.value
                            )}
                            autoFocus={index === 0 && autoFocusFirstElement}
                          />
                          {displayActionBtn && (
                            <FieldArrayActionButtons
                              index={index}
                              e2eTarget={e2eTarget}
                              {...arrayHelpers}
                            />
                          )}
                        </Flex>
                        {meta.error && meta.touched && (
                          <Error>{meta.error}</Error>
                        )}
                      </>
                    )
                  }}
                </FastField>
              )
            })
          }
        />
      </Div>
      {displayAddMoreBtn && (
        <Flex alignSelf="flex-end">
          <Button
            mx={5}
            onClick={handlePush}
            type="button"
            size="action-small"
            colorway={{ group: "grey", variant: "lightest" }}
            hoverColorway={{ group: "grey", variant: "light" }}
          >
            <PlusIcon />
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
