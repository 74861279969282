import React from "react"
import { messages } from "setup/messages/messages"
import { Link } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { BodyText, H2 } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"
import { ConfirmationModalWrapper, CloseButton } from "./styles"

type ConfirmationModalProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  confirmButtonLabel: React.ReactNode
  cancelButtonLabel: React.ReactNode
  children?: React.ReactNode
  onClose: VoidFunction
  onCancel: VoidFunction
  onConfirm: VoidFunction
}

export const ConfirmationModal = ({
  title,
  subtitle,
  confirmButtonLabel,
  cancelButtonLabel,
  children,
  onClose,
  onCancel,
  onConfirm
}: ConfirmationModalProps) => (
  <ConfirmationModalWrapper>
    <CloseButton
      mode="standard-white"
      size="action-medium"
      onClick={onClose}
      title={messages.generic.close}
    >
      <CrossIcon title={messages.generic.close} />
    </CloseButton>
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="s"
    >
      <H2 mt="none" textAlign="center" color="grey.dark">
        {title}
      </H2>
      {subtitle && (
        <BodyText textAlign="center" mt="0" px="m">
          {subtitle}
        </BodyText>
      )}
      {children && children}
      <Button onClick={onConfirm} mt="s">
        {confirmButtonLabel}
      </Button>
      <Link onClick={onCancel} mt="s" mb="none">
        {cancelButtonLabel}
      </Link>
    </Flex>
  </ConfirmationModalWrapper>
)
