import React, { useEffect, useState } from "react"
import {
  Link,
  EarthIcon,
  PolygonIcon,
  Flex,
  H6,
  Div,
  PlusIcon,
  FacebookIcon as Facebook,
  YouTubeTempIcon as YouTube,
  LinkedInIcon as LinkedIn,
  CrunchbaseIcon as Crunchbase,
  XingIcon as Xing,
  ReutersIcon as Reuters,
  BloombergIcon as Bloomberg,
  ZoomInfoIcon as ZoomInfo,
  TwitterIcon as Twitter,
  OwlerIcon as Owler,
  CompaniesHouseIcon as CompaniesHouse
} from "@ikiru/talentis-fpc"
import { transformLinkText } from "utils/links"
import { WebSite } from "views/persons/person.types"
import { Nullable } from "tsdef"
import {
  personProfileDetailsE2ETargets as e2eTargets,
  personProfileDetailsE2ETargets
} from "../../definitions"
import { PersonProfileFields } from "views/persons/pages/manage-person/person-profile.types"
import { messages } from "setup/messages/messages"
import { LinkIcon, WebSitesList, StyledPopupTrigger } from "../style"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"

type WebSitesRecordType = {
  webSites: PersonValue<WebSite>[]
  linkedInProfileUrl: Nullable<PersonValue<string>>
  editPersonWebLinks: (data: boolean) => void
}

type Option = {
  label: string | JSX.Element
  onClick?: () => void
}

export const mapIcons = {
  linkedIn: <LinkedIn />,
  xing: <Xing />,
  crunchbase: <Crunchbase />,
  reuters: <Reuters />,
  bloomberg: <Bloomberg />,
  facebook: <Facebook />,
  zoomInfo: <ZoomInfo />,
  twitter: <Twitter />,
  owler: <Owler />,
  companiesHouse: <CompaniesHouse />,
  youTube: <YouTube />
}

export const WebSitesRecord = ({
  webSites,
  linkedInProfileUrl,
  editPersonWebLinks
}: WebSitesRecordType) => {
  const [notPersonalWebSites, setNotPersonalWebSites] = useState<
    PersonValue<WebSite>[]
  >([])
  const [personalWebSites, setPersonalWebSites] = useState<
    PersonValue<WebSite>[]
  >([])

  const websiteOptions: Option[] = [
    ...personalWebSites.map((website) => ({
      label: (
        <Link
          href={website.value.url}
          standalone
          m="0"
          size="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          {transformLinkText(website.value.url)}
        </Link>
      )
    })),
    {
      label: (
        <Link href="#" standalone m="0" size="small">
          <PlusIcon height="12px" />
          {messages.form.generic.webSites}
        </Link>
      ),
      onClick: () => {
        editPersonWebLinks(true)
      }
    }
  ]

  useEffect(() => {
    let tempNotPersonalWebSite: PersonValue<WebSite>[] = []
    let tempPersonalWebSite: PersonValue<WebSite>[] = []

    webSites.forEach((item) => {
      const icon =
        item.value.type &&
        item.value.type !== "other" &&
        mapIcons[item.value.type]
      const iconExists = Boolean(icon)
      iconExists
        ? tempNotPersonalWebSite.push(item)
        : tempPersonalWebSite.push(item)
    })
    setNotPersonalWebSites(tempNotPersonalWebSite)
    setPersonalWebSites(tempPersonalWebSite)
  }, [webSites])

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  return (
    <WebSitesList as="ul" data-e2e-target-name={e2eTargets.webSites}>
      {Boolean(linkedInProfileUrl?.value) && (
        <Div
          as="li"
          mr="xxs"
          width="28px"
          height="28px"
          data-e2e-target-name={
            personProfileDetailsE2ETargets[
              PersonProfileFields.LinkedInProfileUrl
            ]
          }
        >
          <LinkIcon
            onClick={() => openInNewTab(linkedInProfileUrl?.value || "")}
          >
            <LinkedIn width="28px" height="28px" />
          </LinkIcon>
        </Div>
      )}
      {notPersonalWebSites.map((item, index) => {
        const icon =
          item.value.type &&
          item.value.type !== "other" &&
          mapIcons[item.value.type]
        return (
          <Div
            as="li"
            key={`${item?.value}-${index}`}
            data-e2e-target-name={`${e2eTargets.webSites}-${index}`}
            mr="xxs"
            width="28px"
            height="28px"
          >
            <LinkIcon onClick={() => openInNewTab(item?.value.url)}>
              {icon}
            </LinkIcon>
          </Div>
        )
      })}

      {Boolean(personalWebSites.length) && (
        <Flex alignItems="center" ml="xxs">
          <EarthIcon width="18px" height="18px" viewBox="0 0 16 16" />
          <Link
            m="none"
            mx="xxs"
            href={personalWebSites[0].value.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {transformLinkText(personalWebSites[0].value.url)}
          </Link>
          {personalWebSites.length > 0 && (
            <OptionsPopup
              alignSelf="center"
              display="flex"
              config={{
                PopupProps: {
                  position: "bottom right"
                }
              }}
              trigger={
                <StyledPopupTrigger>
                  {personalWebSites.length > 1 && (
                    <H6 m="none" mr="2px">
                      {`+${personalWebSites.length - 1}`}
                    </H6>
                  )}
                  <PolygonIcon />
                </StyledPopupTrigger>
              }
              options={websiteOptions}
            />
          )}
        </Flex>
      )}
    </WebSitesList>
  )
}
