import React, { useMemo } from "react"
import { Flex, Div, colors, H5, BodyText, Tag } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ShowMore, showMoreInfo } from "components/ShowMore"
import { useCompany } from "views/companies/company-module/company-module.context"
import { AboutStyledSpan } from "./style"

export const FullCompanyInfo = () => {
  const { companyDetails } = useCompany()

  const { descriptions, industries, yearFounded, speciality, size } =
    companyDetails

  const infoCompany: showMoreInfo[] = useMemo(
    () => [
      {
        title: messages.companies.companyInfo.sector,
        info: industries[0]?.name
      },
      {
        title: messages.companies.companyInfo.companySize,
        info:
          messages.companies.companyInfo.size[size] ||
          messages.companies.companyInfo.size.unknown
      }
    ],
    [size, industries]
  )

  const extraInfoCompany: showMoreInfo[] = useMemo(
    () => [
      {
        title: messages.companies.companyInfo.companyHeadquarters,
        info: ""
      },
      { title: messages.companies.companyInfo.companyFound, info: yearFounded }
    ],
    [yearFounded]
  )

  const specialityList = useMemo(() => speciality?.split(","), [speciality])

  return (
    <Div>
      <ShowMore
        hasBackground
        isLongDescription={false}
        visibleLines={2}
        toDisplayInfo={infoCompany}
        moreInfo={Boolean(descriptions.length || yearFounded)}
        showMoreContainerProps={{ mt: ["25px", "25px", "5px"] }}
      >
        {(isExpanded) => {
          return (
            <>
              {isExpanded && (
                <>
                  <Div>
                    {extraInfoCompany.map(
                      (info) =>
                        Boolean(info.info) && (
                          <Flex
                            alignItems="baseline"
                            key={info.title}
                            height="20px"
                            mt="xs"
                          >
                            <H5 color={colors.grey.darkest} m="0" mr="8px">
                              {info.title}
                            </H5>
                            <BodyText m="0">{info.info}</BodyText>
                          </Flex>
                        )
                    )}
                  </Div>
                  {Boolean(descriptions.length) && (
                    <BodyText m="0" mt="xs">
                      <AboutStyledSpan>
                        {messages.companies.companyInfo.about}&nbsp;-&nbsp;
                      </AboutStyledSpan>
                      {descriptions[0].text}
                    </BodyText>
                  )}
                </>
              )}
              {Boolean(speciality) && (
                <Flex flexDirection="column" mt="xs" alignItems="baseline">
                  <H5 color={colors.grey.darkest} m="0" mr="8px">
                    {messages.companies.companyInfo.speciality}
                  </H5>
                  <Flex
                    flexWrap="wrap"
                    height={isExpanded ? null : "40px"}
                    overflow={isExpanded ? null : "hidden"}
                  >
                    {specialityList.map((specialityItem) => {
                      return (
                        <Tag
                          m="0"
                          mr="xxs"
                          mt="xs"
                          height="25px"
                          variant="grey"
                          key={specialityItem}
                          disabled
                        >
                          {specialityItem}
                        </Tag>
                      )
                    })}
                  </Flex>
                </Flex>
              )}
            </>
          )
        }}
      </ShowMore>
    </Div>
  )
}
