import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  Flex,
  Div,
  H2,
  BodyText,
  CrossIcon,
  Button,
  colors
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"

export const TrialWarningModal = () => {
  const { close, onClose } = useModal()
  const navigate = useNavigate()

  const closeAndRedirect = useCallback(() => {
    onClose(ModalNames.Trial, () => {
      navigate(RouterUrl.Subscription)
    })

    close(ModalNames.Trial)
  }, [navigate, onClose, close])

  return (
    <Div style={tempInnerGreyModalStyles}>
      <Flex justifyContent="flex-end">
        <Button
          onClick={() => close(ModalNames.Trial)}
          mode="standard-white"
          size="action-medium"
        >
          <CrossIcon fill={colors.grey.dark} />
        </Button>
      </Flex>
      <Flex
        p="m"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        textAlign="center"
      >
        <H2 color="grey.dark">
          {messages.assignment.details.candidates.trialWarningTitle}
        </H2>
        <BodyText m={0}>
          {messages.assignment.details.candidates.trialWarningBody}
        </BodyText>
        <Button mt="10px" onClick={closeAndRedirect}>
          {messages.assignment.details.candidates.trialWarningBotton}
        </Button>
      </Flex>
    </Div>
  )
}
