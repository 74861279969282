import React, { useMemo } from "react"
import { BodyText, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { cloneDeep, get } from "lodash"
import { OverviewRow } from "./OverviewRow"
import moment from "moment"
import { Nullable } from "tsdef"
import { isEmptyData } from "../helpers"

export const OverviewPersonDetails = () => {
  const { cvParsing, setCVParsing } = usePerson()

  const filteredValues = useMemo(() => {
    if (cvParsing.personDetails) {
      return Object.entries(cvParsing.personDetails)
        .filter(([_key, value]) => value.isChecked && value.parsed)
        .reduce((acc, [key, value]) => {
          // @ts-ignore
          if (key === "dateOfBirth") {
            // @ts-ignore
            acc[key] = moment(value.parsed).format("MM/DD/YYYY")
          } else {
            // @ts-ignore
            acc[key] = value.tempValue || value.parsed
          }
          return acc
        }, {})
    }
  }, [cvParsing.personDetails])

  const onChangeParsed = (text: string | Nullable<Date>, key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].parsed = text
    if (tempCVParsing.personDetails[key].hasOwnProperty("tempValue")) {
      delete tempCVParsing.personDetails[key].tempValue
    }
    setCVParsing({ ...tempCVParsing })
  }

  const handleDelete = (key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].isChecked =
      !tempCVParsing.personDetails[key].isChecked
    setCVParsing({ ...tempCVParsing })
  }

  const saveTempValue = (text: string | Nullable<Date>, key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].tempValue = text
    setCVParsing({ ...tempCVParsing })
  }

  return (
    <Flex flexDirection="column">
      {isEmptyData(filteredValues) ? (
        <BodyText pl="xxs">{messages.person.cvparsing.noChanges}</BodyText>
      ) : (
        Object.entries(filteredValues).map(([key, value], index) => (
          <OverviewRow
            key={key}
            bottomBorder={Object.entries(filteredValues).length - 1 === index}
            title={get(messages, `person.cvparsing.personDetails.${key}`)}
            value={value as string}
            onChangeParsed={(text) => onChangeParsed(text, key)}
            handleDelete={() => handleDelete(key)}
            valueKey={key}
            saveTempValue={(text) => saveTempValue(text, key)}
          />
        ))
      )}
    </Flex>
  )
}
