import { Candidate } from "views/candidates/candidates.types"
import { InterviewProgressStage } from "./components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { interviewStagesOrder } from "./constants/interview-stages-order"
import { isEmpty, isEqual, xorWith } from "lodash"
import { Assignment } from "./assignment.types"
import { generateLabel } from "components/AzureLocation/helper"
import { Nullable } from "tsdef"
import { AssignmentPrimaryData } from "./components/assignment-data/constants/definitions"

export const isValuesEqual = (value: any, initialValue: any) => {
  return isEmpty(xorWith(value, initialValue, isEqual))
}

export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((changeValue: any, [key, value]) => {
    const hasChanged = initialValues[key] !== value

    if (hasChanged) {
      changeValue[key] = value
    }

    return changeValue
  }, {})
}

export const getUpdatedName = (
  values: AssignmentPrimaryData,
  initialValues: AssignmentPrimaryData,
  changedValues: Partial<AssignmentPrimaryData>
) => {
  let valuesName = values?.name
  if (
    initialValues?.companyName &&
    changedValues.companyName &&
    valuesName?.includes(initialValues?.companyName)
  ) {
    valuesName = valuesName.replace(
      initialValues.companyName,
      changedValues.companyName
    )
  }
  if (
    initialValues?.jobTitle &&
    changedValues.jobTitle &&
    valuesName?.includes(initialValues?.jobTitle)
  ) {
    valuesName = valuesName.replace(
      initialValues.jobTitle,
      changedValues.jobTitle
    )
  }
  return valuesName
}

export const getChangedValuesWithArray = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((changeValue: any, [key, value]) => {
    let hasChanged = false

    if (Array.isArray(initialValues[key])) {
      hasChanged = !isValuesEqual(value, initialValues[key])
    } else {
      hasChanged = initialValues[key] !== value
    }

    if (hasChanged) {
      changeValue[key] = value
    }

    return changeValue
  }, {})
}

export const getActiveInterviewStage = (candidates: Candidate[]) => {
  const activeAssignments = candidates.map(
    (candidate: Candidate) => candidate?.interviewProgressState?.stage
  )
  const groupsToShow = interviewStagesOrder.filter(
    (group: InterviewProgressStage) => activeAssignments.includes(group)
  )
  return groupsToShow
}

export const geoLocationForLocation = (assignments: Assignment[]) => {
  assignments.map((assignment) => {
    if (assignment.geoLocation) {
      assignment.location = generateLabel(assignment.geoLocation)
    }
    return assignment
  })
  return assignments
}

export const excludeArchive = (
  selectedStage: string,
  params: URLSearchParams,
  forExportAll = false
) => {
  if (selectedStage === "all" || forExportAll) {
    Object.values(InterviewProgressStage).forEach((stageName: string) => {
      if (stageName !== InterviewProgressStage.Archive && stageName !== "all") {
        params.append("Stages", stageName)
      }
    })
  }
  return params
}

export const sortByDate = (data: Assignment[]) => {
  return data?.sort(
    (assignmentA: Assignment, assignmentB: Assignment) =>
      (assignmentB.startDate ? new Date(assignmentB.startDate).valueOf() : 0) -
      (assignmentA.startDate ? new Date(assignmentA.startDate).valueOf() : 0)
  )
}

export const generateCurrency = (salary: Nullable<number>, currency: string) =>
  salary &&
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency
  }).format(salary)
