import React, { useMemo } from "react"
import { BodyText, Div, H5 } from "@ikiru/talentis-fpc"
import { DragItemIcon, ItemCardContainer, ItemCardWrapper } from "./style"
import { ShowMore } from "components/ShowMore"
import { CVDataType, Experience } from "views/persons/person.types"
import { DragType, DraggableDataName } from "../../constants/definition"
import ExistingDropItemWrapper from "./ExistingDropItemWrapper"
import { getEndDate, getStartDate } from "./utils"
import useHandleDndItem from "../../hooks/useHandleDndItem"
import { sortDragAndDropItems } from "../../../utils"

type DragItemProps = {
  isDragging?: boolean
  id: string
  description: string
  componentHover: string
  type: string
  changedValues: Partial<Experience>
  undoDataChange: (id: string, objectName: string) => void
} & Experience

const DragItemExistingData = ({
  isDragging = false,
  id,
  title,
  company,
  startDate,
  endDate,
  type,
  description,
  changedValues = {}
}: DragItemProps) => {
  const { items, setItems, updateTempPersonData } = useHandleDndItem({
    dragType: "experience"
  })

  const {
    title: changedTitle = null,
    startDate: changedStartDate = null,
    endDate: changedEndDate = null,
    description: changedDescription = null
  } = changedValues

  const isParsed = useMemo(() => type === CVDataType.Parsed, [type])

  const undoDataChange = (id: string, objectName: string) => {
    const newState = items.map((exp) => {
      if (exp.id === id) {
        if (objectName === DraggableDataName.Date) {
          delete exp.itemComponentProps.changedValues.startDate
          delete exp.itemComponentProps.changedValues.endDate
        } else {
          delete exp.itemComponentProps.changedValues[objectName]
        }
      }
      return exp
    })

    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  return (
    <ItemCardWrapper
      isDragging={isDragging}
      isExistingData={true}
      isParsed={type === "parsed"}
    >
      <ItemCardContainer>
        <H5 m="none" px="xxs">
          {company}
        </H5>

        <ExistingDropItemWrapper
          acceptType={DragType.ParsedTitle}
          id={id}
          isParsed={isParsed}
          objectName={DraggableDataName.Title}
          hasBeingChange={Boolean(changedTitle)}
          undoDataChange={undoDataChange}
          isValueValid={Boolean(changedTitle || title)}
        >
          <H5 m="none">{changedTitle || title}</H5>
        </ExistingDropItemWrapper>

        <ExistingDropItemWrapper
          acceptType={DragType.ParsedDate}
          id={id}
          isParsed={isParsed}
          objectName={DraggableDataName.Date}
          hasBeingChange={Boolean(changedStartDate)}
          undoDataChange={undoDataChange}
          isValueValid={Boolean(changedStartDate || startDate)}
        >
          <BodyText m="none">
            {`${getStartDate(changedStartDate || startDate)} - ${getEndDate(
              changedEndDate || endDate,
              changedStartDate || startDate
            )}`}
          </BodyText>
        </ExistingDropItemWrapper>

        <ExistingDropItemWrapper
          acceptType={DragType.ParsedDescription}
          id={id}
          isParsed={isParsed}
          objectName={DraggableDataName.Description}
          hasBeingChange={Boolean(changedDescription)}
          undoDataChange={undoDataChange}
          isValueValid={Boolean(changedDescription || description)}
        >
          <ShowMore
            visibleLines={3}
            description={changedDescription || description}
          />
        </ExistingDropItemWrapper>

        <BodyText m="none" px="xxs">
          Salary:
        </BodyText>
      </ItemCardContainer>
      <Div pt="xs" pr="xs">
        <DragItemIcon />
      </Div>
    </ItemCardWrapper>
  )
}

export default DragItemExistingData
