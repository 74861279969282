import React, { useEffect, useRef } from "react"
import { Div } from "@ikiru/talentis-fpc"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"

export type ItemComponentProps = {
  isDraggable?: boolean
  [key: string]: any
}

type MovableItemProp = {
  itemId: string
  index: number
  type: string
  currentColumnId: string
  ItemComponent: (props: ItemComponentProps) => JSX.Element
  itemProps: any
  setItems: (currentItem: CurrentItemType, columnId: string) => void
}

export type CurrentItemType = {
  index: number
  currentColumnId: string
  itemId: string
}

export const MovableItem = ({
  itemId,
  index,
  type,
  currentColumnId,
  ItemComponent,
  setItems,
  itemProps
}: MovableItemProp) => {
  const changeItemColumn = (currentItem: CurrentItemType, columnId: string) => {
    setItems(currentItem, columnId)
  }

  const ref = useRef(null)

  const [{ isDragging }, drag, preview] = useDrag({
    type: type,
    item: { index, currentColumnId, itemId },
    end: (item, monitor) => {
      const dropResult: any = monitor.getDropResult()

      if (dropResult) {
        const { newColumnName } = dropResult

        changeItemColumn(item, newColumnName)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(ref)

  useEffect(() => {
    preview(getEmptyImage())
  }, [preview])

  return (
    <Div ref={ref}>
      <Div display={isDragging ? "none" : ""}>
        <ItemComponent {...itemProps} />
      </Div>
    </Div>
  )
}
