import React from "react"
import { useMultiBoxes } from "../components/hooks"
import { Flex, Button, colors, PlusIcon } from "@ikiru/talentis-fpc"
import { JobTitlesBox } from "./JobTitlesBox"
import { LogicalConnection } from "../components/LogicalConnection"
import { JOB_TITLES_BOXES_LIMIT } from "./consts"
import { messages } from "setup/messages/messages"
import FilterWrapper from "../../FilterWrapper"
import { SearchCriteriaField } from "../definitions"
import { FilterType } from "views/search/SearchModule/types"

export const JobTitleFilter = () => {
  const { boxesCount, addNewBox } = useMultiBoxes(
    SearchCriteriaField.JobTitleBundle,
    FilterType.JobTitleBundle
  )

  const onClick = (e: Event) => {
    addNewBox()
    ;(e.target as HTMLElement)?.scrollIntoView()
  }

  return (
    <Flex flexDirection="column" width="100%" height="auto">
      {[...Array(boxesCount).keys()].map((_, index: number, array) => (
        <FilterWrapper key={index + boxesCount}>
          <Flex flexDirection="column" width="100%">
            <JobTitlesBox {...{ index, boxesCount }} />
            {index + 1 < boxesCount && (
              <Flex
                my="none"
                style={{ position: "relative", bottom: "-27px" }}
                zIndex={1}
              >
                <LogicalConnection
                  filterType={FilterType.JobTitlesBundleSearchUsingANDLogic}
                />
              </Flex>
            )}
            {boxesCount < JOB_TITLES_BOXES_LIMIT &&
              index === array.length - 1 && (
                <Flex my="xs">
                  <Button
                    prefix={
                      <PlusIcon
                        width={12}
                        height={12}
                        fill={colors.white.standard}
                      />
                    }
                    size="small"
                    mode="standard-green"
                    //@ts-ignore
                    onClick={onClick}
                  >
                    {messages.talentGraphSearch.addJobTitleField}
                  </Button>
                </Flex>
              )}
          </Flex>
        </FilterWrapper>
      ))}
    </Flex>
  )
}
