import { apiRequest } from "setup/api/api"
import { NewPersons, PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { PersonDetailsType } from "../.."

export const fetchFoundPeople = async (
  data: PersonDetailsType,
  pageNumber: number
) => {
  let searchFilters = data

  if (data.country === "notSelected") {
    searchFilters["country"] = ""
  }
  const requestObject = {
    first_name: searchFilters.firstName,
    last_name: searchFilters.lastName,
    company_name: {
      value: searchFilters.companies,
      experience_search_type: searchFilters.companyLogic
    },
    job_title: {
      value: searchFilters.jobTitle,
      experience_search_type: searchFilters.jobTitleLogic
    },
    keywords: {
      values: searchFilters.keywords,
      operator: searchFilters.keywordLogic
    },
    country: searchFilters.country,
    cities: searchFilters.location,
    options: {
      page_size: 15,
      page_number: pageNumber
    }
  }
  const [, response] = await apiRequest.post({
    endpoint: NewPersons.Root,
    data: requestObject
  })

  return response?.data
}

export const fetchSelectedPeople = async (
  selectedIds: string[],
  errorCallback: (data: string) => void
) => {
  let params = new URLSearchParams()

  const logError = () => {
    selectedIds.forEach((ids) => {
      errorCallback(ids)
    })
  }

  try {
    selectedIds.forEach((id) => {
      params.append("n360PeopleIds", id)
    })
    const response = await apiRequest.get({
      endpoint: NewPersons.Root,
      config: {
        params
      }
    })

    if (response[1]?.data.people.length !== selectedIds.length) {
      logError()
      throw new Error()
    }

    return response[1]?.data
  } catch (error) {
    logError()
    throw new Error()
  }
}

export const linkageDataPoolPerson = async (dataPoolPersonId: string) => {
  const [, response] = await apiRequest.post({
    endpoint: PersonsEndpoints.DataPoolLinkage,
    data: { dataPoolPersonId }
  })

  if (response) {
    const localPersonId = response?.data?.localPerson?.id
    return localPersonId
  }
  return undefined
}
