export const commonFormStateKey = "formState"

export enum FormStates {
  Static = "static",
  Pending = "pending",
  Finished = "finished"
}

type FormikState = { [key: string]: any } & {
  [commonFormStateKey]: FormStates
}

export const getFormState = (state?: FormikState) =>
  state && state[commonFormStateKey]

export const initialFormState = {
  [commonFormStateKey]: FormStates.Static
}

export const formState = {
  states: FormStates,
  getState: getFormState,
  isFinished: (state: FormikState) =>
    getFormState(state) === FormStates.Finished,
  isPending: (state: FormikState) => getFormState(state) === FormStates.Pending,
  isStatic: (state: FormikState) => getFormState(state) === FormStates.Static
}
