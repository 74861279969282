import React, { useState, useEffect } from "react"
import { AppInsightsContext } from "./Context"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { ai, getAppInsights } from "./Service"
import { defaultSendData } from "./consts"
import {
  TrackEventMethod,
  TrackExceptionMethod,
  TrackMetricMethod,
  TrackTraceMethod
} from "./types"

const AppInsightsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const appInsights = getAppInsights()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const AppInsightsInstrumentationKey = window.fpConfig.appInsightsKey
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey)
      setInitialized(true)
    }
  }, [initialized])

  const trackException: TrackExceptionMethod = (errorName) => {
    appInsights?.trackException({
      error: new Error(errorName),
      severityLevel: SeverityLevel.Error
    })
  }

  const trackTrace: TrackTraceMethod = (message, data = {}) => {
    appInsights?.trackTrace(
      {
        message,
        severityLevel: SeverityLevel.Information
      },
      { ...defaultSendData, ...data }
    )
  }

  const trackEvent: TrackEventMethod = (event, data = {}) => {
    appInsights?.trackEvent(event, { ...defaultSendData, ...data })
  }

  const trackMetric: TrackMetricMethod = (metric, data = {}) => {
    appInsights?.trackMetric(metric, { ...defaultSendData, ...data })
  }

  return (
    <AppInsightsContext.Provider
      value={{
        appInsights,
        trackException,
        trackTrace,
        trackEvent,
        trackMetric
      }}
    >
      {children}
    </AppInsightsContext.Provider>
  )
}

export default AppInsightsProvider
