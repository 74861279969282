import { createContext } from "react"
import { noop } from "lodash"
import { AlertOptions } from "./Provider"

type AlertContextProps = {
  show: (message: React.ReactNode, options: AlertOptions) => string | null
  close: (id: string) => void
  closeAll: VoidFunction
}

const defaultContextValue = {
  show: () => null,
  close: noop,
  closeAll: noop
}

export const AlertContext =
  createContext<AlertContextProps>(defaultContextValue)
