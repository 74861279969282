import React from "react"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { Flex } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"
import { PositiveIcon } from "@ikiru/talentis-fpc"
import { H2 } from "@ikiru/talentis-fpc"
import {
  OrderSummary,
  OrderSummaryProps
} from "views/subscription/components/billing-card-details/components/OrderSummary"

type OrderSuccessProps = Omit<
  OrderSummaryProps,
  "reverse" | "confirmationMessage"
>

export const OrderSuccess = (props: OrderSuccessProps) => {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      my={["m", "xl", "xl"]}
      px={["xs", "0", "0"]}
    >
      <PositiveIcon />
      <H2 mt="xxs" mb="m" color="grey.dark">
        {props.totalPrice !== 0
          ? messages.subscription.orderSuccess.paymentSuccessful
          : messages.subscription.orderSuccess.planUpdatedSuccessful}
      </H2>
      <OrderSummary
        {...props}
        confirmationMessage={
          props.totalPrice !== 0
            ? messages.subscription.orderSuccess.youHavePurchased
            : messages.subscription.orderSuccess.planUpdated
        }
        reverse
      />
      {/* TODO: Link to "plan and billing" when the page exists */}
      <Link href={RouterUrl.Homepage} mt="m">
        {messages.generic.returnToHomepage}
      </Link>
    </Flex>
  )
}
