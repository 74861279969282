import moment from "moment"

export enum CouponType {
  LAUNCH_OFFER = "launchOffer",
  USER_COUPON = "userCoupon"
}

export type Coupon = {
  type: CouponType
  code: string
  dueDate?: moment.Moment
}

export const autoAddedCoupons = [] as Coupon[]
