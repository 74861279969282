import styled from "styled-components"

export const Field = styled.div`
  display: grid;
  grid-template-columns: 2;

  & + & {
    margin-top: 16px;
  }
`
