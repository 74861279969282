import React from "react"
import { Div, Button, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { EditOffLimitsField } from "../constants/definitions"
import { useFormikContext } from "formik"

type OffLimitsFormProps = {
  isShowButton: boolean
}

export const OffLimitsForm = ({ isShowButton }: OffLimitsFormProps) => {
  const { setFieldValue } = useFormikContext()

  const handleClearDate = () => {
    setFieldValue(EditOffLimitsField.EndDate, null)
  }

  return (
    <Div>
      <Flex alignItems="center">
        <Div width="352px">
          <FormikDatepicker
            id={EditOffLimitsField.EndDate}
            name={EditOffLimitsField.EndDate}
            label={messages.offLimits.offLimitsEndDate}
          />
        </Div>

        {isShowButton && (
          <Button
            type="button"
            mode="standard-white"
            mx="xs"
            size="small"
            onClick={() => handleClearDate()}
          >
            {messages.offLimits.removeDate}
          </Button>
        )}
      </Flex>
      <Div mt="xs">
        <FormikTextarea
          rows={6}
          id={EditOffLimitsField.Comment}
          name={EditOffLimitsField.Comment}
          label={messages.offLimits.offLimitsComment}
          resize="none"
        />
      </Div>
    </Div>
  )
}
