import React from "react"
import { CVDataType, Education } from "views/persons/person.types"
import { Div } from "@ikiru/talentis-fpc"
import {
  DragItemIcon,
  ItemCardContainer,
  ItemCardWrapper
} from "../DragAndDropItems/style"
import EducationCard from "./EducationCard"

type EducationDragItemProps = {
  isDragging?: boolean
  id: string
  description: string
  type: string
  isMovedToParsed?: boolean
  isMovedToExisting?: boolean
} & Education

const EducationDragItem = ({
  isDragging = false,
  type,
  value,
  isMovedToExisting,
  isMovedToParsed,
  institute,
  startDate,
  endDate
}: EducationDragItemProps) => {
  return (
    <ItemCardWrapper
      isDragging={isDragging}
      isExistingData={
        (type === CVDataType.Existing && !Boolean(isMovedToParsed)) ||
        (type === CVDataType.Parsed && Boolean(isMovedToExisting))
      }
      isParsed={type === CVDataType.Parsed && Boolean(isMovedToExisting)}
    >
      <ItemCardContainer>
        <Div pl="xxs">
          <EducationCard
            value={value}
            institute={institute?.name}
            startDate={startDate}
            endDate={endDate}
          />
        </Div>
      </ItemCardContainer>
      <Div pt="xs" pr="xs">
        <DragItemIcon />
      </Div>
    </ItemCardWrapper>
  )
}

export default EducationDragItem
