import { Button, CrossIcon, Flex, H1, LargeText } from "@ikiru/talentis-fpc"
import React from "react"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { ModalContainer } from "./styles"
import { messages } from "setup/messages/messages"

type ErrorProp = {
  isAssignmentSelected: boolean
}
const ErrorModal = ({ isAssignmentSelected }: ErrorProp) => {
  const { close } = useModal()
  const isAssignment = isAssignmentSelected
    ? messages.searchPerson.ignoringPerson.modal.anAssignment
    : messages.searchPerson.ignoringPerson.modal.aCampaign
  const isCandidate = isAssignmentSelected
    ? messages.assignment.details.candidates.title.toLowerCase()
    : messages.campaign.details.contacts.title.toLowerCase()
  const pluralProjectNames = isAssignmentSelected
    ? messages.assignment.addNew.toLowerCase()
    : messages.campaign.title.toLowerCase()
  return (
    <ModalContainer>
      <Flex justifyContent="flex-end">
        <Button
          onClick={() => close(ModalNames.NoSelectedAssignmentOrCandidate)}
          mode="standard-white"
          size="action-medium"
        >
          <CrossIcon fill="grey.dark" />
        </Button>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px="l"
        pb="s"
      >
        <H1 color="grey.standard" textAlign="center">
          {messages.searchPerson.ignoringPerson.modal.modalHeader.format(
            isAssignment,
            isCandidate
          )}
        </H1>
        <LargeText
          color="grey.standard"
          textAlign="center"
          fontSize="19px"
          pb="s"
        >
          {messages.searchPerson.ignoringPerson.modal.modalMessage.format(
            pluralProjectNames,
            isCandidate
          )}
        </LargeText>
        <Button
          onClick={() => close(ModalNames.NoSelectedAssignmentOrCandidate)}
        >
          {messages.searchPerson.ignoringPerson.modal.closeButton}
        </Button>
      </Flex>
    </ModalContainer>
  )
}
export default ErrorModal
