import { TeamMember } from "../../@types/fruity-parsnip"

export enum Membership {
  Owner = "owner",
  Member = "member",
  KnownTo = "knownTo"
}

export type ProjectTeamUpdate = {
  userId: string
  membership: Membership | null
}

export type ProjectTeamMember = TeamMember<Membership>
