import React from "react"
import { TabLabel } from "./style"

export type TabTitleProps = {
  isActive: boolean
  handleClick: (index: number) => void
  index: number
  title: string
  disabled?: boolean
}

export const TabTitle = (props: TabTitleProps) => {
  const { isActive, title, index, handleClick, disabled = false } = props

  const handleOnClick = () => !disabled && handleClick(index)

  return (
    <TabLabel
      isActive={isActive}
      onClick={handleOnClick}
      index={index}
      title={title}
      disabled={disabled}
    >
      {title}
    </TabLabel>
  )
}
