import React from "react"
import { Button, Flex, H5, BodyText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import NetworkErrorPicture from "assets/network_error.png"

type NetworkIssueProps = {
  handleTryAgain: () => void
}

export const NetworkIssue = ({ handleTryAgain }: NetworkIssueProps) => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <img alt="CV parsing" width={230} src={NetworkErrorPicture} />
      <H5 m="0">{messages.person.cvparsing.noInternetConnection}</H5>

      <BodyText mb="m" textAlign="center">
        {messages.person.cvparsing.checkInternet}
      </BodyText>
      <Button
        onClick={handleTryAgain}
        mode="standard-green"
        size="small"
        mb="m"
      >
        {messages.person.cvparsing.tryAgain}
      </Button>
    </Flex>
  )
}
