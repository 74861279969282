import React, { useState, useCallback } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Line } from "@ikiru/talentis-fpc"
import { Loader } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"
import { SectionBody } from "@ikiru/talentis-fpc"
import { SectionHeader } from "@ikiru/talentis-fpc"
import { DropdownArrowIcon as DropdownArrow } from "@ikiru/talentis-fpc"
import { TeamMember as TeamMemberType } from "views/team/team/team-module.types"
import { TeamMember } from "./components/TeamMember"
import { InviteForm } from "./components/InviteForm"
import { useTeam } from "../../team-module.context"
import { CreatePersonValues } from "./definitions"

type TeamMembersProps = {
  label: string
  isInviteFormVisible?: boolean
  members?: TeamMemberType[]
  onInviteUser?: (user: CreatePersonValues) => void
}

export const TeamMembers = ({
  label,
  members = [],
  isInviteFormVisible,
  onInviteUser
}: TeamMembersProps) => {
  const { user, isLoading } = useTeam()
  const [isExpanded, setIsExpanded] = useState(true)

  const handleToggleButton = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])

  const handleInviteUser = (user: CreatePersonValues) => {
    if (onInviteUser) {
      onInviteUser(user)
    }
  }

  return (
    <Div data-e2e-target-name={`team-members-${label}`}>
      <SectionHeader
        size="xSmall"
        title={label}
        itemsCount={members.length}
        actions={
          <Button
            mode="standard-white"
            size="action-small"
            onClick={handleToggleButton}
          >
            <DropdownArrow
              fill="currentColor"
              width={12}
              height={12}
              style={{
                transform: `rotate(${isExpanded ? 0 : "180deg"})`
              }}
            />
          </Button>
        }
      />
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key={label}
            initial={{ height: "auto", overflowY: "hidden" }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <SectionBody px="xs">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {isInviteFormVisible && (
                    <InviteForm onInviteUser={handleInviteUser} />
                  )}
                  {members.map((member, index) => (
                    <React.Fragment key={member.id}>
                      <TeamMember
                        currentUser={member.id === user?.profile?.UserId}
                        {...member}
                      />
                      {index !== members.length - 1 && <Line variant="thin" />}
                    </React.Fragment>
                  ))}
                </>
              )}
            </SectionBody>
          </motion.div>
        )}
      </AnimatePresence>
    </Div>
  )
}
