import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { BetaIcon, CustomSmallText, GPTHeader, HeaderContainer } from "./style"
import { messages } from "setup/messages/messages"
import GPTInput from "../GPTInput"

type GPTHomeScreenProp = {
  gptSearch: string
  setGPTSearch: React.Dispatch<React.SetStateAction<string>>
  onSubmit: () => Promise<void>
  isError: boolean
}

const GPTHomeScreen = (props: GPTHomeScreenProp) => {
  return (
    <Div mb="l">
      <HeaderContainer>
        <GPTHeader>{messages.search.chatGPT.buildYourSearch}</GPTHeader>
        <BetaIcon>{messages.search.chatGPT.beta}</BetaIcon>
      </HeaderContainer>
      <GPTInput
        {...props}
        label={messages.search.chatGPT.whatAreYouLookingFor}
        placeHolder={messages.search.chatGPT.exampleSearch}
        explainedLabel={messages.search.chatGPT.searchExplaination}
      />
      <Flex>
        <CustomSmallText>
          {messages.search.chatGPT.byUsingThisSearch}
        </CustomSmallText>
      </Flex>
    </Div>
  )
}

export default GPTHomeScreen
