import get from "lodash/get"
import cloneDeep from "lodash/cloneDeep"
import { formatPrice } from "utils/format-price"
import { messages } from "setup/messages/messages"
import { PricingCardProps } from "@ikiru/talentis-fpc"
import { generateSubscriptionPlanFeatures } from "views/subscription/subscription-purchase-module/plan-helpers"
import {
  Currency,
  SubscriptionPlan,
  SubscriptionType
} from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

export const defaultCurrency = Currency.USD

export const getPlanTitle = (plan: SubscriptionPlan) => {
  return get(messages.subscription.plans, plan.type, plan.type || "")
}

export const mapPlanToPricingCard = (
  plan: SubscriptionPlan
): Omit<PricingCardProps, "onChoose"> | undefined => {
  const yearlyPricing = plan?.yearly?.price

  if (!yearlyPricing) {
    return undefined
  }

  const yearlyPrice =
    (yearlyPricing?.total - (yearlyPricing.taxAmount || 0)) / 12

  return {
    title: getPlanTitle(plan),
    advantages:
      generateSubscriptionPlanFeatures({
        tokens: plan?.monthly?.defaultTokens || 0
      })[plan.type] || [],
    price: formatPrice(yearlyPrice || 0, plan?.currency),
    strikethroughText: yearlyPricing?.discount
      ? messages.subscription.purchase.normalPrice.format(
          formatPrice(yearlyPricing?.amount / 12, plan?.currency)
        )
      : undefined,
    chooseBtnLabel: get(
      messages.subscription.purchase.choosePlan,
      plan.type,
      plan.type || ""
    )
  }
}

const planTypeSortingArray: SubscriptionType[] = [
  SubscriptionType.Talentis,
  SubscriptionType.TalentisConnect
]

export const sortPlansByType = (
  plans: SubscriptionPlan[]
): SubscriptionPlan[] =>
  cloneDeep(plans).sort(
    (prev, next) =>
      planTypeSortingArray.indexOf(prev.type) -
      planTypeSortingArray.indexOf(next.type)
  )
