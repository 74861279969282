import React, { useContext } from "react"
import { NavLink } from "react-router-dom"
import { Flex } from "@ikiru/talentis-fpc"
import { HeaderLink } from "components/navigation/constants/navigation.definitions"
import { SidebarContext } from "components/visual/header/SidebarContext"
import { preventDefault } from "utils/prevent-default"
import { LinkWrapper, LinkIcon, LinkText } from "./SidebarLinks.styled"

export const SidebarLink = React.memo(
  ({
    label,
    icon,
    path,
    e2eTargets,
    disabled,
    activeLinkClassCustom
  }: HeaderLink) => {
    const { isExpanded } = useContext(SidebarContext)

    return (
      <LinkWrapper
        activeClassCustom={activeLinkClassCustom}
        as={NavLink}
        key={path}
        to={path}
        data-e2e-target={e2eTargets?.e2eTarget}
        data-e2e-target-name={e2eTargets?.e2eTargetName}
        disabled={disabled}
        {...(disabled
          ? {
              onClick: preventDefault
            }
          : {})}
      >
        <Flex alignItems="center">
          <LinkIcon>
            {path === "/support" ? (
              <icon.type
                {...icon.props}
                width={35}
                height={35}
                viewBox="0 0 40 40"
              />
            ) : (
              icon
            )}
          </LinkIcon>
          {isExpanded && <LinkText>{label}</LinkText>}
        </Flex>
      </LinkWrapper>
    )
  }
)
