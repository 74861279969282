import React, { useMemo, useCallback } from "react"
import { Nullable } from "tsdef"
import {
  Span,
  spacing,
  Div,
  Flex,
  BodyText,
  SmallText,
  ConnectedIcon,
  Tag,
  Button,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  Experience,
  RecentNoteType,
  WebSite,
  WebSiteType
} from "views/persons/person.types"
import { OverlayLoader } from "components/Loader/OverlayLoader"
import {
  PersonRecordBox,
  PersonDetailsBox,
  ExperienceBox,
  AssignmentBox,
  LocationBox,
  CandidateDetailsBox,
  BodyTextClampedStyled,
  PersonDetailsBlock,
  StyledSmallText,
  OffLimitsContainerBox,
  StyledGT2
} from "./styles"
import { formatDate } from "utils/format-date"
import { Job } from "views/search/SearchModule/types"
import { Stage } from "views/persons/person.types"
import { WebSites } from "components/WebSites/index"
import moment from "moment"
import { CandidateActivitiesDetails } from "./components/CandidateActivitiesDetails"
import { PersonLink } from "components/PersonRecords/OverlayOpenner/PersonLink"
import { useSearch } from "views/search/SearchModule/context"
import { buildSearchUrl, generateInitials } from "./helper"
import { PersonAvatarOverlay } from "components/PersonRecords/OverlayOpenner/PersonAvatar"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { sortExperience } from "views/persons/components/person-experience/utils"
import { ShowMore } from "components/ShowMore"
import { ProjectTeamMember } from "../../../../components/ProjectTeamMembers/types"
import { Note } from "../../../../components/Notes/types"
import { isNotNullOrZero } from "utils/notNull"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { GT2Modal } from "./components/GT2/GT2Modal"
import Gt2Icon from "assets/Gt2Icon.png"

type PersonRecordLayoutProps = {
  isIgnored: boolean
  experience: Experience[]
  stages: Stage[]
  campaignIds: string[]
  assignmentIds: string[]
  dataPoolId: string
  isOfflimits: boolean
  teamMembers: ProjectTeamMember[]
  recentNote: RecentNoteType
  isGtPerson: boolean
  personId?: Nullable<string>
  name?: Nullable<string>
  companyName?: Nullable<string>
  bio?: Nullable<string>
  jobTitle?: Nullable<string>
  photoUrl?: Nullable<string>
  linkedInProfileUrl?: Nullable<string>
  webSites?: WebSite[]
  isUpdating: boolean
  isConnected?: boolean
  location?: string
  AssignmentComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  notes?: Note[]
  previousJobs?: Job[]
}

export const PersonRecordLayout = ({
  bio,
  name,
  photoUrl,
  webSites = [],
  isUpdating,
  isConnected,
  location,
  personId,
  teamMembers = [],
  dataPoolId,
  AssignmentComponent,
  isIgnored,
  stages,
  assignmentIds,
  campaignIds,
  previousJobs = [],
  experience = [],
  recentNote,
  isOfflimits,
  notes = [],
  isGtPerson
}: PersonRecordLayoutProps) => {
  const { isAssignmentSelect, setSelectedSearchDataPoolId, isAnonymized } =
    useSearch()

  const searchUrl = useMemo(() => {
    return buildSearchUrl(experience, name)
  }, [experience, name])

  const personJobs = useMemo(() => {
    return sortExperience(experience, undefined, true).slice(0, 5)
  }, [experience])

  const yearsExperience = useMemo(() => {
    const allPersonJobs = experience
    if (!allPersonJobs.length) return null
    const sDateFormat = allPersonJobs
      .map((job: Experience) => {
        return job.startDate && formatDate(job.startDate)
      })
      .filter((j) => j)
    const eDate = allPersonJobs
      .map((job: Experience) =>
        !job.endDate
          ? moment().format("MM/DD/YYYY")
          : job.endDate && formatDate(job.endDate)
      )
      .sort((a: string | undefined, b: string | undefined) =>
        moment(b).diff(moment(a), "hours")
      )
    const sDate = sDateFormat.sort((a: string | null, b: string | null) =>
      moment(a).diff(moment(b), "hours")
    )
    const diff = moment(eDate[0]).diff(moment(sDate[0]), "years")
    return diff
      ? diff < 25
        ? `(${messages.person.personRecordBox.atLeast} ${diff} ${messages.person.personRecordBox.yearsExp})`
        : `(${25} + ${messages.person.personRecordBox.yearsExp})`
      : ""
  }, [experience])

  const hideName = isAnonymized && !Boolean(personId)

  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  const personLinkUnderline = useMemo(
    () => Boolean((!isLarge && personId) || (isLarge && !isIgnored)),
    [isIgnored, isLarge, personId]
  )

  const mediaQuerySmall = getMinWidthMediaQuery("xs")
  const isSmall = useMediaQuery(mediaQuerySmall)

  const { open } = useModal()

  const openGT2 = useCallback(
    () =>
      open(
        ModalNames.GT2Modal,
        <GT2Modal dataPoolPersonId={dataPoolId} name={name} />
      ),
    [dataPoolId, open, name]
  )

  return (
    <PersonRecordBox isIgnored={isIgnored} isOfflimits={isOfflimits}>
      <OverlayLoader isLoading={isUpdating}>
        {isAssignmentSelect
          ? messages.talentGraphSearch.updatingPersonAssignment
          : messages.talentGraphSearch.updatingPersonCampaign}
      </OverlayLoader>
      {isOfflimits && (
        <OffLimitsContainerBox
          isCircle
          isLeftCircle={!isSmall}
          isHorizontal={!isSmall}
          isTopRightCircle={!isSmall}
          pl={!isSmall ? "xxs" : 0}
        >
          {messages.offLimits.offLimits}
        </OffLimitsContainerBox>
      )}
      <PersonDetailsBlock
        opacity={isIgnored ? 0.2 : 1}
        isOffLimits={isOfflimits}
      >
        <PersonDetailsBox isIgnored={isIgnored}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Flex
              flexDirection={["row", "column"]}
              mb={spacing.xs}
              width="100%"
            >
              <Div ml={isIgnored ? "-5px" : "-3px"}>
                <PersonAvatarOverlay
                  name={name}
                  personId={personId}
                  datapoolId={dataPoolId}
                  photoUrl={hideName ? null : photoUrl}
                  setPersonId={setSelectedSearchDataPoolId}
                  height={isIgnored ? 46 : 60}
                  width={isIgnored ? 46 : 60}
                />
              </Div>
              <Flex ml={["xs", "none"]} flexDirection="column">
                <Flex
                  alignItems="cener"
                  mb="xxs"
                  ml={isIgnored ? "-2px" : "none"}
                >
                  <PersonLink
                    personId={personId}
                    underline={personLinkUnderline}
                    setPersonId={setSelectedSearchDataPoolId}
                    dataPoolId={dataPoolId}
                  >
                    {hideName && name?.length ? generateInitials(name) : name}
                  </PersonLink>
                  {isConnected && <ConnectedIcon width={spacing.m} />}
                </Flex>

                <BodyTextClampedStyled expanded={false} linesNumber={1}>
                  {location}
                </BodyTextClampedStyled>

                <Flex>
                  <WebSites
                    iconSize={24}
                    linkedInIconSize={{ height: 24, width: 28 }}
                    marginLeft="-2px"
                    marginRight={isIgnored ? "7px" : undefined}
                    webSites={
                      [
                        {
                          type: "talentis" as WebSiteType,
                          url: "",
                          onClick: async (
                            e: React.MouseEvent<HTMLElement, MouseEvent>
                          ) => {
                            e.preventDefault()
                            setSelectedSearchDataPoolId(dataPoolId)
                          }
                        },
                        ...webSites
                      ]?.slice(0, 3) || []
                    }
                  />

                  {isGtPerson && (
                    <StyledGT2 onClick={openGT2}>
                      <img alt="GT2 Icon" width={24} src={Gt2Icon} />
                    </StyledGT2>
                  )}
                </Flex>

                {!isIgnored && (
                  <>
                    <Flex>
                      <Button
                        as="a"
                        href={searchUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        size="extra-small"
                        mode="standard-grey-light"
                        my="xxs"
                      >
                        {messages.talentGraphSearch.findAlternativeUrls}
                      </Button>
                    </Flex>
                    <Span>{yearsExperience}</Span>
                  </>
                )}
              </Flex>
            </Flex>
            <Flex mb="-10px">
              <StyledSmallText>
                {messages.person.note.latestNoteDate}
                {recentNote &&
                  isNotNullOrZero(recentNote.toString()) &&
                  (recentNote.createdOrUpdated
                    ? moment(recentNote.createdOrUpdated).format("DD/MM/YYYY")
                    : "")}
              </StyledSmallText>
            </Flex>
          </Flex>
        </PersonDetailsBox>
      </PersonDetailsBlock>
      <Div opacity={isIgnored ? 0.2 : 1}>
        <ExperienceBox>
          {(isIgnored ? personJobs.slice(0, 1) : personJobs).map(
            (job: any, idx) => {
              const jobIndustries = previousJobs.find(
                (jobIn) =>
                  jobIn.companyName === job.company &&
                  jobIn.position === job.title
              )?.industries
              return (
                <Div key={`${job.position}_${idx}`} mt="xs" ml="s">
                  <Flex alignItems="center" justifyContent="space-between">
                    <BodyText fontWeight={600} mt="none" mb={0} mr="xs">
                      {messages.talentGraphSearch.experience.format(
                        job.title || "",
                        job.company || ""
                      )}
                    </BodyText>
                    <Div>
                      {Boolean(jobIndustries?.length) && (
                        <Tag
                          min-height="18px"
                          mb="-5px"
                          mr="xs"
                          variant="grey"
                          disabled
                        >
                          {jobIndustries}
                        </Tag>
                      )}
                    </Div>
                  </Flex>

                  <SmallText color="grey.dark" my={0}>
                    {!!job.startDate && `${formatDate(job.startDate)} – `}
                    {!!job.endDate
                      ? formatDate(job.endDate)
                      : job.startDate && messages.talentGraphSearch.present}
                    {!!job.duration && `  ( ${job.duration})`}
                  </SmallText>
                </Div>
              )
            }
          ) || (
            <SmallText color="grey.dark" my={0}>
              {messages.talentGraphSearch.noEmloymentHistory}
            </SmallText>
          )}
        </ExperienceBox>
        {isIgnored && (
          <CandidateDetailsBox>
            <CandidateActivitiesDetails
              personId={personId}
              datapoolId={dataPoolId}
              stages={stages}
              showLabel
              assignmentIds={assignmentIds}
              campaignIds={campaignIds}
              mY="xs"
              projectTeamMembers={teamMembers}
            />
          </CandidateDetailsBox>
        )}
        {!isIgnored && (
          <CandidateActivitiesDetails
            personId={personId}
            datapoolId={dataPoolId}
            stages={stages}
            showLabel
            assignmentIds={assignmentIds}
            campaignIds={campaignIds}
            projectTeamMembers={teamMembers}
          />
        )}
        {!isIgnored && bio && (
          <LocationBox>
            <ShowMore
              title={messages.person.bio.title}
              visibleLines={2}
              description={bio}
            />
          </LocationBox>
        )}
      </Div>
      <AssignmentBox isIgnored={isIgnored}>
        {AssignmentComponent && <AssignmentComponent />}
      </AssignmentBox>
    </PersonRecordBox>
  )
}
