export const e2eTargets = {
  candidateName: "candidate-name",
  candidateCompany: "candidate-company",
  candidateJob: "candidate-job",
  candidateLoader: "candidate-loader"
}

export type Location = {
  addressLine?: string
  countryName?: string
  cityName?: string
}
