import React, { useEffect, useMemo, useState } from "react"
import { Flex, H6 } from "@ikiru/talentis-fpc"
import {
  sectionFieldTemplateString,
  sectionFieldConditionTemplateString,
  initialTemplateString,
  FIELD_BOXES_LIMIT,
  sectionFieldSearchValuesTemplateString,
  timeFrameFieldConditionTemplateString
} from "./consts"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Field } from "views/internal-search/InternalSearchModule/types"
import {
  getLocalSearchReferenceValue,
  getLocalSearchReferenceEnums
} from "views/internal-search/actions"
import { useFormikContext } from "formik"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { useAddSectionField } from "views/internal-search/hooks"
import { get } from "lodash"
import { conditionOptions, defaultConditionValue } from "./utils"
import { DateInputFiled } from "./DateInputFiled"
import { InputField } from "./InputField"
import { SelectInputField } from "./SelectInputField"
import { ButtonsFlex, StyledMinusIcon, StyledPlusIcon } from "./styles"
import { TimeFrame } from "../TimeFrame"

type SearchFieldBoxProps = {
  index: number
  indexSection: number
  fields: Field[]
  boxesCount: number
}

export const SearchFieldBox = ({
  index,
  indexSection,
  fields,
  boxesCount
}: SearchFieldBoxProps) => {
  const { addNewBox, removeBox } = useAddSectionField(indexSection)

  const [selectedField, setSelectedField] = useState<Field | undefined>()

  const [optionsBoolean, setOptionsBoolean] = useState([])

  const [valueForSelect, setValueForSelect] = useState([])

  const { setFieldValue } = useFormikContext()

  const { updateFilters, clearArrayBasedFilter, filters } = useInternalSearch()

  const initialFilterName = initialTemplateString.format(indexSection, index)

  const fieldName = sectionFieldTemplateString.format(indexSection, index)

  const fieldTimeFrameName = timeFrameFieldConditionTemplateString.format(
    indexSection,
    index
  )

  const fieldConditionName = sectionFieldConditionTemplateString.format(
    indexSection,
    index
  )

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )

  const optionsField = useMemo(
    () =>
      fields.map((field: Field) => ({
        value: field.id,
        label: field.name,
        typeField: field.fieldType
      })),
    [fields]
  )

  const handleFieldChange = async (selectedOption: any) => {
    const selectedFieldFind = fields.find(
      (field) => field.id === selectedOption.target.value
    )

    if (selectedFieldFind) {
      updateFilters({
        [fieldSearchValuesName]: []
      })

      updateFilters({
        [fieldConditionName]: ""
      })
    }

    setSelectedField(selectedFieldFind)
    //@ts-ignore
    setOptionsBoolean(conditionOptions(selectedFieldFind?.fieldType))

    if (selectedFieldFind?.fieldType === "referenced") {
      let referencedValue = await getLocalSearchReferenceValue(
        selectedOption.target.value
      )

      const transformedData = referencedValue.items
        .filter((item: any) => item.value.trim() !== "")
        .map((item: any) => ({
          value: item.id,
          label: item.value
        }))

      setValueForSelect(transformedData)
    } else if (selectedFieldFind?.fieldType === "enum") {
      let referencedEnumValue = await getLocalSearchReferenceEnums(
        selectedOption.target.value
      )

      const referencedEnumValueTemp = referencedEnumValue.values.map(
        (enumValue: any) => ({
          value: enumValue,
          label: enumValue.replace(/([A-Z])/g, " $1").trim()
        })
      )
      setValueForSelect(referencedEnumValueTemp)
    }

    updateFilters({
      [fieldName]: selectedOption.target.value
    })
  }

  const handleConditionChange = (selectedOption: any) => {
    updateFilters({
      [fieldSearchValuesName]: []
    })

    updateFilters({
      [fieldConditionName]: selectedOption.target.value
    })
  }

  const currentFieldValue = get(filters, fieldName)
  const currentFieldConditionValue = get(filters, fieldConditionName)

  useEffect(() => {
    selectedField?.fieldType &&
      !currentFieldConditionValue &&
      updateFilters({
        [fieldConditionName]: defaultConditionValue(selectedField.fieldType)
      })

    const fetchSearchReferenceValue = async () => {
      let referencedValue = await getLocalSearchReferenceValue(
        currentFieldValue
      )

      const transformedData = referencedValue.items
        .filter((item: any) => item.value.trim() !== "")
        .map((item: any) => ({
          value: item.id,
          label: item.value
        }))

      setValueForSelect(transformedData)
    }

    if (selectedField?.fieldType === "referenced" && currentFieldValue) {
      fetchSearchReferenceValue()
    }
  }, [
    selectedField,
    currentFieldValue,
    currentFieldConditionValue,
    fieldConditionName,
    updateFilters
  ])

  useEffect(() => {
    if (currentFieldValue && optionsBoolean?.length === 0) {
      const selectedFieldFind = fields.find(
        (field) => field.id === currentFieldValue
      )
      setSelectedField(selectedFieldFind)
      //@ts-ignore
      setOptionsBoolean(conditionOptions(selectedFieldFind?.fieldType))
    }
  }, [currentFieldValue, fields, optionsBoolean])

  const isBlank = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return ["blank", "notBlank"].includes(condition)
  }, [filters, indexSection, index])

  return (
    <Flex alignItems="center">
      <Flex mb="5px" ml="5px">
        <Flex mx="5px" flexDirection="column" minWidth="250px">
          <FormikSelect
            label="Select a field"
            options={optionsField}
            id={fieldName}
            name={fieldName}
            variant="middle"
            firstOption={{
              label: "Select a field",
              value: ""
            }}
            onChange={(e) => {
              handleFieldChange(e)
              setFieldValue(`${initialFilterName}.values`, "")
              setFieldValue(`${initialFilterName}.values0`, "")
              setFieldValue(`${initialFilterName}.values1`, "")
            }}
            controlled
            value={currentFieldValue}
          />
          {selectedField?.showTimeFrame && (
            <TimeFrame fieldName={fieldTimeFrameName} />
          )}
        </Flex>
        {optionsBoolean?.length > 0 && (
          <Flex
            mx="5px"
            flexDirection="column"
            minWidth="200px"
            maxWidth="200px"
          >
            <FormikSelect
              label="Condition"
              options={optionsBoolean}
              id={fieldConditionName}
              name={fieldConditionName}
              variant="middle"
              onChange={(e) => handleConditionChange(e)}
              controlled
              value={currentFieldConditionValue}
            />
          </Flex>
        )}
        {!isBlank && (
          <Flex mx="5px">
            {(selectedField?.fieldType === "string" ||
              selectedField?.fieldType === "number" ||
              selectedField?.fieldType === "document") && (
              <InputField index={index} indexSection={indexSection} />
            )}
            {selectedField?.fieldType === "date" && (
              <DateInputFiled index={index} indexSection={indexSection} />
            )}
            {(selectedField?.fieldType === "referenced" ||
              selectedField?.fieldType === "enum") &&
              valueForSelect.length > 0 && (
                <SelectInputField
                  index={index}
                  indexSection={indexSection}
                  valueForSelect={valueForSelect}
                />
              )}
          </Flex>
        )}
        <H6 color="#4B6A88;" fontWeight="600" ml="10px" mt="15px">
          {filters?.sections?.[indexSection]?.condition?.toUpperCase()}
        </H6>
      </Flex>
      <ButtonsFlex pl={index !== boxesCount - 1 ? "80px" : "40px"}>
        {index === boxesCount - 1 && boxesCount < FIELD_BOXES_LIMIT && (
          <StyledRemoveButton
            mode="standard-white"
            size="action-small"
            onClick={addNewBox}
          >
            <StyledPlusIcon />
          </StyledRemoveButton>
        )}
        {boxesCount > 1 && (
          <StyledRemoveButton
            mode="standard-white"
            size="action-small"
            //@ts-ignore
            onClick={() => {
              clearArrayBasedFilter(initialFilterName)
              removeBox(index)
            }}
          >
            <StyledMinusIcon />
          </StyledRemoveButton>
        )}
      </ButtonsFlex>
    </Flex>
  )
}
