import React from "react"
import { Tag } from "@ikiru/talentis-fpc"
import { components, OptionProps } from "react-select"

const multiselectOptionE2eTarget = "multiselect-option"

export const MultiselectOption = (props: OptionProps<any, true>) => {
  const {
    data: { "data-e2e-target-name": e2eTargetName },
    isFocused
  } = props

  return (
    <Tag
      variant={isFocused ? "grey-dark" : "grey"}
      as="div"
      width="100%"
      m="none"
      mb="xxs"
      data-e2e-target={multiselectOptionE2eTarget}
      data-e2e-target-name={e2eTargetName}
    >
      <components.Option {...props} />
    </Tag>
  )
}
