import { apiRequest } from "setup/api/api"
import { SearchFirmEndpoints } from "setup/api/endpoints/endpoints"
import { parseVerificationToken } from "./verify-search-firm.utils"

export const completeSearchFirmVerification = async (rawParam: string) => {
  const verificationToken = parseVerificationToken(rawParam)

  const [, response] = await apiRequest.put({
    endpoint: SearchFirmEndpoints.Root,
    data: {
      inviteToken: verificationToken
    }
  })

  return Boolean(response)
}
