import { useState } from "react"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import { InterviewProgressStatus } from "../candidate-record/components/InterviewProgress/definitions"

export const useTaskFilterData = () => {
  const { allAvailableFilters } = useTaskManagement()

  const [statusFilter] = useState<InterviewProgressStatus[]>(
    allAvailableFilters.status
  )
  const [assignToFilter] = useState<string[]>(
    allAvailableFilters.assignedTo || []
  )
  const [stageFilter] = useState<string[]>(allAvailableFilters.stage)

  return {
    statusFilter,
    assignToFilter,
    stageFilter
  }
}
