import {
  Div,
  CopyPasteIcon,
  PencilIcon,
  Flex,
  colors,
  SmallText,
  Button,
  H5
} from "@ikiru/talentis-fpc"
import React, { useMemo, useState } from "react"
import { useRef } from "react"
import { OutReachTemplateTextArea } from "./styles"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import TemplateEditModal, { ValuesType } from "./TemplateEditModal"
import { FormikHelpers } from "formik"
import { messages } from "setup/messages/messages"

type AITextareaProps = {
  label: string
  name: string
  defaultRows?: number
  value: string
  onSave: (
    dataKey: string,
    values: ValuesType,
    action: FormikHelpers<ValuesType>
  ) => void
  lowerCaseTitle?: boolean
}

const AITextarea = ({
  label,
  name,
  defaultRows = 1,
  value,
  onSave,
  lowerCaseTitle = false
}: AITextareaProps) => {
  const [hasBeingCopied, setHasBeingCopied] = useState(false)
  const textareaRef = useRef(null)
  const { open } = useModal()

  const rowHeight = useMemo(() => {
    if (defaultRows === 1) {
      return Boolean(value) ? 15 : 1
    }
    return defaultRows
  }, [defaultRows, value])

  const openTemplateModal = () => {
    open(
      ModalNames.TemplateEditModal,
      <TemplateEditModal
        dataKey={name}
        title={label}
        value={value}
        onSave={onSave}
        lowerCaseTitle={lowerCaseTitle}
      />
    )
  }

  const onCopy = () => {
    setHasBeingCopied(true)
    setTimeout(() => setHasBeingCopied(false), 1500)
    navigator.clipboard.writeText(value)
  }

  const isDisabled = useMemo(() => !Boolean(value.length), [value.length])
  return (
    <Flex flexDirection="column" width="100%" mb="m">
      {hasBeingCopied && (
        <SmallText
          bg={colors.white.standard}
          width="fit-content"
          px="xs"
          py="2px"
        >
          {messages.project.outReach.copied}
        </SmallText>
      )}
      <Flex width="100%">
        <OutReachTemplateTextArea
          ref={textareaRef}
          //@ts-ignore
          label={
            <H5
              m="0"
              color={colors.grey.standard}
              fontSize={isDisabled ? 20 : 16}
            >
              {label}
            </H5>
          }
          value={value}
          style={{ display: isDisabled ? "none" : "" }}
          disabled={isDisabled}
          resize="vertical"
          rows={rowHeight}
          readOnly
        />

        <Flex flexDirection="column" mx="m" height="50%" mt="xxs">
          <Button
            mode="standard-white"
            size="action-medium"
            onClick={openTemplateModal}
            prefix={
              <Div pl="8px" pt="xxs">
                <PencilIcon width="18px" height="18px" />
              </Div>
            }
            mb="xs"
          />
          <Button
            mode="standard-white"
            size="action-medium"
            onClick={onCopy}
            prefix={
              <Div pl="8px" pt="xxs">
                <CopyPasteIcon width="18px" height="18px" />
              </Div>
            }
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AITextarea
