import React, { useCallback, useEffect, useState, useMemo } from "react"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { expandEndpointByParams } from "setup/api/api.helpers"
import moment from "moment"
import { PersonNote } from "views/persons/components/person-notes/types"
import { NoteAddValues } from "../types"
import { FormAction } from "utils/forms/form.types"
import { getOnAddPersonNoteSubmit, getOnEditPersonNoteSubmit } from "../actions"
import { useModal } from "utils/hooks/use-modal"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { NoteModal } from "../NoteModal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

const usePersonRecordNotes = (personRecordId: string, id = "", type = "") => {
  const [isLoading, setIsLoading] = useState(false)
  const [note, setNote] = useState<PersonNote>()

  const { personOverlayNoteUpdated, setPersonOverlayNoteUpdated, personId } =
    usePerson()

  const { selectedTab, updateCompanyCandidateNote } = useAssignment()

  const { open } = useModal()

  const typeId = useMemo(
    () => (type === "assignment" ? "assignmentId" : "campaignId"),
    [type]
  )

  const getLatestNote = (personNotes: PersonNote[]) => {
    return personNotes?.sort((a: PersonNote, b: PersonNote) =>
      moment(b.updatedDate || b.createdDate).diff(
        a.updatedDate || a.createdDate
      )
    )?.[0]
  }

  const fetchNotes = useCallback(
    async (personId: string) => {
      setIsLoading(true)
      const [, response] = await apiRequest.get({
        endpoint: PersonsEndpoints.Notes,
        endpointParams: { id: personId },
        config: expandEndpointByParams({
          expand: [
            "createdByUser",
            type,
            "updatedByUser",
            "assignment",
            "campaign"
          ]
        })
      })

      if (type) {
        let noteTemp = response?.data?.notes?.filter(
          (note: PersonNote) => note[typeId] === id
        )

        setNote(getLatestNote(noteTemp))
      } else {
        setNote(getLatestNote(response?.data?.notes))
      }

      setIsLoading(false)
    },
    [id, type, typeId]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreateNoteSubmit: FormAction<NoteAddValues> = useCallback(
    getOnAddPersonNoteSubmit(personRecordId, type, id, (note: PersonNote) => {
      setPersonOverlayNoteUpdated(true)
      setNote(note)
    }),
    []
  )

  const onEditNoteSubmit = useCallback(
    (noteId: string): FormAction<NoteAddValues> =>
      getOnEditPersonNoteSubmit(
        personRecordId,
        type,
        id,
        noteId,
        (note: PersonNote) => {
          setPersonOverlayNoteUpdated(true)
          setNote(note)
        }
      ),
    [personRecordId, type, id, setPersonOverlayNoteUpdated]
  )

  const openNoteModalCallback = useCallback(
    (personName: any, assignmentName: any, typeTitle: any) =>
      (note?: PersonNote) => {
        const title = `${
          note
            ? messages.clientPortal.notes.editNote
            : messages.clientPortal.notes.addNote
        } ${
          Boolean(personName)
            ? `${messages.clientPortal.notes.for} ${personName}`
            : ""
        }`
        open(
          ModalNames.PersonRecordNoteModal,
          <NoteModal
            title={title}
            onSubmit={note ? onEditNoteSubmit(note.id) : onCreateNoteSubmit}
            name={assignmentName}
            initialValues={note}
            typeTitle={typeTitle}
          />
        )
      },
    [open, onCreateNoteSubmit, onEditNoteSubmit]
  )

  useEffect(() => {
    if (personRecordId) fetchNotes(personRecordId)
  }, [personRecordId, fetchNotes])

  useEffect(() => {
    const reFetchNotes = async () => {
      await fetchNotes(personRecordId)
      setPersonOverlayNoteUpdated(false)
    }
    if (personOverlayNoteUpdated && personRecordId === personId) {
      reFetchNotes()
    }
  }, [
    personRecordId,
    fetchNotes,
    personOverlayNoteUpdated,
    setPersonOverlayNoteUpdated,
    personId
  ])

  useEffect(() => {
    if (selectedTab === "companyCandidate" && note) {
      updateCompanyCandidateNote(personRecordId, note)
    }
  }, [note, personRecordId, selectedTab, updateCompanyCandidateNote])

  return {
    note,
    isLoading,
    onCreateNoteSubmit,
    onEditNoteSubmit,
    openNoteModalCallback
  }
}

export { usePersonRecordNotes }
