import { Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { hexToRGB } from "utils/hexToRGB"

type statusTabContainer = {
  isSelected: boolean
}

export const TabContainer = styled(Flex)<statusTabContainer>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  width: 191px;
  cursor: pointer;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.6)};
  border-bottom: ${({ theme, isSelected }) =>
    isSelected
      ? `2px solid ${theme.colors.green.standard}`
      : `2px solid ${theme.colors.white.standard}`};
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`
