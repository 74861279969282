export const industries = [
  "Accounting",
  "Airlines and Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel and Fashion",
  "Architecture and Planning",
  "Arts and Creative",
  "Automotive",
  "Aviation and Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies and Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic and Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer and Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense Aerospace",
  "Design",
  "Education Management",
  "E-Learning",
  "Electrical and Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming and Agriculture",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food and Beverages",
  "Food Production",
  "Fund-Raising",
  "Furniture",
  "Gambling and Casinos",
  "Glass, Ceramics and Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness and Fitness",
  "Healthcare",
  "Higher Education",
  "Hospitality",
  "Human Resources",
  "Import and Export",
  "Individual and Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology and Services",
  "Insurance",
  "International Affairs",
  "International Trade and Development",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure, Travel and Tourism",
  "Libraries",
  "Logistics and Supply Chain",
  "Luxury Goods and Jewellery",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Market Research",
  "Marketing and Advertising",
  "Mechanical and Industrial Engineering",
  "Media Production",
  "Medical Devices",
  "Medical Services",
  "Mental Health Care",
  "Military",
  "Mining and Metals",
  "Mobile Games",
  "Motion Pictures and Film",
  "Museums and Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Non-profit Organization Management",
  "Oil and Gas / Energy",
  "Online Media",
  "Outsourcing/Offshoring",
  "Package and Freight Delivery",
  "Packaging and Containers",
  "Paper and Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary Education",
  "Printing",
  "Program Development",
  "Public Policy",
  "Public Relations and Communications",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational Facilities and Services",
  "Recruiting Services",
  "Religious Institutions",
  "Renewables and Environment",
  "Research",
  "Restaurants and Bars",
  "Retail",
  "Security and Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Software",
  "Sporting Goods",
  "Sports",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Training and Coaching Services",
  "Translation and Localization",
  "Transportation",
  "Utilities",
  "Venture Capital and Private Equity",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine and Spirits",
  "Wireless",
  "Writing and Editing"
]
