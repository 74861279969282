import { KeyboardEvent, useCallback, FocusEvent, useMemo } from "react"
import get from "lodash/get"
import { useFormikContext } from "formik"
import { SearchCriteriaField } from "./definitions"
import { FilterType } from "views/search/SearchModule/types"
import { useSearch } from "views/search/SearchModule/context"
import { messages } from "setup/messages/messages"
import { FormikMultiselectOptionType } from "components/functional/formik/formik-multiselect/FormikMultiselect"
import {
  keywordsRecordPersonTemplateString,
  keywordsRecordCompanyTemplateString,
  keywordsRecordPersonRegExTemplateString,
  keywordsRecordCompanyRegExTemplateString,
  keywordsLogicTemplateString
} from "./Keywords/consts"
import { validFilter } from "./ExcludeKeywords/consts"
import { filterListNotEmptyValues } from "./ExcludeKeywords/helper"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { HintKeywordsModal } from "./FilterModal/HintKeywordsModal"
import { HintJobTitlesModal } from "./FilterModal/HintJobTitlesModal"
import React from "react"
import { jobTitlesTemplateString } from "./JobTitle/consts"

type UseArrayFieldChangeParams = {
  field: SearchCriteriaField
  filterType: FilterType
  limit?: number
  isOnBlur?: boolean
}

export const useMandatoryFiltersWereSet = () => {
  const { filters } = useSearch()

  return useMemo(() => {
    const filterValues = Object.entries(filters).map((filter) => {
      if (validFilter.includes(filter[0])) {
        return filter[1]
      } else {
        return undefined
      }
    })
    return filterListNotEmptyValues(filterValues).includes(true)
  }, [filters])
}

export const useToggleButtonField = (filterType: FilterType) => {
  const { updateFilters } = useSearch()

  return useCallback(
    (_: any, value: any) => {
      updateFilters({
        [filterType]: value
      })
    },
    [filterType, updateFilters]
  )
}

export const useRadioField = (filterType: FilterType | string) => {
  const { updateFilters } = useSearch()

  return useCallback(
    (event: any) => {
      const value = event.target.value
      updateFilters({
        [filterType]: value
      })
    },
    [filterType, updateFilters]
  )
}

export const useCheckboxField = (filterType: FilterType | string) => {
  const { updateFilters, filters } = useSearch()

  return {
    onChange: useCallback(
      (event: any) => {
        const value = event.target.checked
        const keywordsRecordPersonRegEx = new RegExp(
          keywordsRecordPersonRegExTemplateString
        )
        const keywordsRecordCompanyRegEx = new RegExp(
          keywordsRecordCompanyRegExTemplateString
        )
        const numberPattern = /\d+/g
        const keywordIndex = filterType.match(numberPattern)?.join("")

        let filterValues = {
          [filterType]: value
        }

        if (keywordsRecordPersonRegEx.test(filterType) && value === false) {
          const filterKey =
            // @ts-ignore
            keywordsRecordCompanyTemplateString.format(keywordIndex)

          if (!filterValues[filterKey]) {
            filterValues[filterKey] = true
          }
        } else if (
          keywordsRecordCompanyRegEx.test(filterType) &&
          value === false
        ) {
          const filterKey =
            // @ts-ignore
            keywordsRecordPersonTemplateString.format(keywordIndex)

          if (!filterValues[filterKey]) {
            filterValues[filterKey] = true
          }
        }

        updateFilters(filterValues)
      },
      [filterType, updateFilters]
    ),
    // @ts-ignore
    checked: !!filters[filterType]
  }
}

export const useTypeaheadFieldChange = (
  filterType: FilterType,
  callback?: (values: any[]) => void
) => {
  const { updateFilters } = useSearch()

  return useCallback(
    (values: FormikMultiselectOptionType[] = []) => {
      const selectedValues = values?.map((option) => option.value) || []
      updateFilters({
        [filterType]: selectedValues
      })
      callback?.(selectedValues)
    },
    [filterType, updateFilters, callback]
  )
}

export const useSelectFieldChange = (filterType: FilterType) => {
  const { updateFilters } = useSearch()

  return useCallback(
    (event: any) => {
      updateFilters({
        [filterType]: event?.target?.value || []
      })
    },
    [filterType, updateFilters]
  )
}

export const useArrayFieldChange = ({
  field,
  filterType,
  limit,
  isOnBlur
}: UseArrayFieldChangeParams) => {
  const formik = useFormikContext()
  const { addArrayBasedFilter } = useSearch()

  return useCallback(
    (e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
      if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter" || isOnBlur) {
        e.preventDefault()
        formik.setFieldTouched(field)

        if (!formik.isValid) return

        addArrayBasedFilter({
          filterName: filterType,
          value: e.currentTarget.value,
          limit,
          callback: () => {
            formik.setFieldError(
              field,
              get(
                messages.talentGraphSearch.errors.limitsError,
                field,
                ""
              ).format(limit || 0) ||
                messages.talentGraphSearch.errors.limitsError.default.format(
                  limit || 0
                )
            )
          }
        })

        formik.setFieldValue(field, "", false)
        formik.setSubmitting(false)
      }
    },
    [addArrayBasedFilter, field, filterType, formik, limit, isOnBlur]
  )
}

type UseNestedArrayFieldChangeParams = {
  field: string
  filterName: string
  limit?: number
  isOnBlur?: boolean
  index?: number
}

export const useNestedArrayFieldChange = ({
  field,
  filterName,
  limit,
  isOnBlur,
  index
}: UseNestedArrayFieldChangeParams) => {
  const formik = useFormikContext()
  const { addArrayBasedFilter, updateFilters } = useSearch()

  const { open } = useModal()

  return useCallback(
    (e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
      if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter" || isOnBlur) {
        e.preventDefault()
        formik.setFieldTouched(field)

        let tempValuesArray: Array<string> = []
        const regex = /^"([^"]*)"$/
        let tempValuesArrayFilter: Array<string> = []

        let containsAndOr = false
        // check only jobTitles and keywords
        if (filterName.includes("keywords")) {
          // check in " "
          if (regex.test(e.currentTarget.value)) {
            // check if OR or AND in " " ,it is error
            if (
              e.currentTarget.value.includes(" OR ") ||
              e.currentTarget.value.includes(" AND ")
            ) {
              open(ModalNames.HintKeywords, <HintKeywordsModal />)
              formik.setFieldValue(field, "", false)
              formik.setSubmitting(false)
            } else {
              tempValuesArray.push(e.currentTarget.value.replace(/"/g, ""))
            }
            //if not in " "
          } else {
            //if has OR and AND together it is error
            if (
              e.currentTarget.value.includes(" OR ") &&
              e.currentTarget.value.includes(" AND ")
            ) {
              open(ModalNames.HintKeywords, <HintKeywordsModal />)
              formik.setFieldValue(field, "", false)
              formik.setSubmitting(false)
            } else {
              // check if OR, then we split string o words
              if (e.currentTarget.value.includes(" OR ")) {
                open(ModalNames.HintKeywords, <HintKeywordsModal />)
                containsAndOr = true
                tempValuesArrayFilter = e.currentTarget.value.split(" OR ")
                // we need to select OR in checkbox
                const filterNameTest = keywordsLogicTemplateString.format(
                  index || 0
                )
                updateFilters({
                  [filterNameTest]: "or"
                })
              } else if (e.currentTarget.value.includes(" AND ")) {
                open(ModalNames.HintKeywords, <HintKeywordsModal />)
                const filterNameTest = keywordsLogicTemplateString.format(
                  index || 0
                )
                updateFilters({
                  [filterNameTest]: "and"
                })

                containsAndOr = true
                tempValuesArrayFilter = e.currentTarget.value.split(" AND ")
              } else {
                tempValuesArrayFilter = [e.currentTarget.value]
              }
              let isErrorTemp = false
              tempValuesArrayFilter.forEach((value) => {
                if (
                  !regex.test(value) &&
                  value.split(" ").length > 1 &&
                  containsAndOr
                ) {
                  isErrorTemp = true
                  formik.setFieldValue(field, "", false)
                  formik.setSubmitting(false)
                }
              })
              if (!isErrorTemp) {
                tempValuesArrayFilter.forEach((value) => {
                  if (regex.test(value)) {
                    tempValuesArray.push(value.replace(/"/g, ""))
                  } else {
                    tempValuesArray.push(value)
                  }
                })
              }
            }
          }
        } else {
          tempValuesArray.push(e.currentTarget.value)
        }

        if (!formik.isValid) return

        addArrayBasedFilter({
          filterName,
          value: tempValuesArray,
          limit,
          callback: () => {
            formik.setFieldError(
              field,
              get(messages.talentGraphSearch.errors.limitsError, field).format(
                limit || 0
              ) ||
                messages.talentGraphSearch.errors.limitsError.default.format(
                  limit || 0
                )
            )
          }
        })

        formik.setFieldValue(field, "", false)
        formik.setSubmitting(false)
      }
    },
    [
      isOnBlur,
      formik,
      field,
      addArrayBasedFilter,
      filterName,
      limit,
      index,
      open,
      updateFilters
    ]
  )
}

export const useNestedArrayJobTitleFieldChange = ({
  field,
  filterName,
  limit,
  isOnBlur,
  index = 0
}: UseNestedArrayFieldChangeParams) => {
  const formik = useFormikContext()
  const { addArrayBasedFilter } = useSearch()

  const { open } = useModal()

  return useCallback(
    (e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
      if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter" || isOnBlur) {
        e.preventDefault()
        formik.setFieldTouched(field)

        let tempValuesArray: Array<string> = []
        const regex = /^"([^"]*)"$/
        let tempValuesArrayTest: Array<string> = []

        let containsAndOr = false

        const jobTitleValue = e.currentTarget.value
        // check in " "
        if (regex.test(jobTitleValue)) {
          // check if OR or AND in " " ,it is error
          if (
            jobTitleValue.includes(" OR ") ||
            jobTitleValue.includes(" AND ")
          ) {
            open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
            formik.setFieldValue(field, "", false)
            formik.setSubmitting(false)
          } else {
            tempValuesArray.push(jobTitleValue.replace(/"/g, ""))
          }
          //if not in " "
        } else {
          //if has OR and AND together it is error
          if (
            jobTitleValue.includes(" OR ") &&
            jobTitleValue.includes(" AND ")
          ) {
            open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
            formik.setFieldValue(field, "", false)
            formik.setSubmitting(false)
          } else {
            // check if OR, then we split string o words
            if (jobTitleValue.includes(" OR ")) {
              containsAndOr = true
              tempValuesArrayTest = jobTitleValue.split(" OR ")
            } else if (jobTitleValue.includes(" AND ")) {
              const matches = jobTitleValue.match(new RegExp("AND", "g"))
              const count = matches ? matches.length : 0
              containsAndOr = true
              const tempSplitJobTitles = jobTitleValue.split(" AND ")

              // if the first box and only two ANDs we create 2 boxes, if more than 3 we do not do anything just show pop up
              if (index === 0 && count === 1) {
                open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
                tempValuesArrayTest.push(
                  tempSplitJobTitles[0].replace(/"/g, "")
                )
                addArrayBasedFilter({
                  filterName: jobTitlesTemplateString.format(1),
                  value: tempSplitJobTitles[1].replace(/"/g, "")
                })
              }

              if (index === 0 && count === 2) {
                open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
                tempValuesArrayTest.push(
                  tempSplitJobTitles[0].replace(/"/g, "")
                )
                addArrayBasedFilter({
                  filterName: jobTitlesTemplateString.format(1),
                  value: tempSplitJobTitles[1].replace(/"/g, "")
                })
                addArrayBasedFilter({
                  filterName: jobTitlesTemplateString.format(2),
                  value: tempSplitJobTitles[2].replace(/"/g, "")
                })
              }
              // if the second box and only one AND we create 1 boxes, if more than 2 we do not do anything just show pop up
              if (index === 1 && count === 1) {
                open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
                tempValuesArrayTest.push(
                  tempSplitJobTitles[0].replace(/"/g, "")
                )
                addArrayBasedFilter({
                  filterName: jobTitlesTemplateString.format(2),
                  value: tempSplitJobTitles[1].replace(/"/g, "")
                })
              }
              // if the third box and have AND we do not do anything just show pop up
              if (index === 2) {
                open(ModalNames.HintJobTitles, <HintJobTitlesModal />)
              }
            } else {
              tempValuesArrayTest = [jobTitleValue]
            }
            let isErrorTest = false
            tempValuesArrayTest.forEach((value) => {
              if (
                !regex.test(value) &&
                value.split(" ").length > 1 &&
                containsAndOr
              ) {
                isErrorTest = true
                formik.setFieldValue(field, "", false)
                formik.setSubmitting(false)
              }
            })
            if (!isErrorTest) {
              tempValuesArrayTest.forEach((value) => {
                if (regex.test(value)) {
                  tempValuesArray.push(value.replace(/"/g, ""))
                } else {
                  tempValuesArray.push(value)
                }
              })
            }
          }
        }

        if (!formik.isValid) return

        addArrayBasedFilter({
          filterName,
          value: tempValuesArray,
          limit,
          callback: () => {
            formik.setFieldError(
              field,
              get(messages.talentGraphSearch.errors.limitsError, field).format(
                limit || 0
              ) ||
                messages.talentGraphSearch.errors.limitsError.default.format(
                  limit || 0
                )
            )
          }
        })

        formik.setFieldValue(field, "", false)
        formik.setSubmitting(false)
      }
    },
    [
      isOnBlur,
      formik,
      field,
      addArrayBasedFilter,
      filterName,
      limit,
      index,
      open
    ]
  )
}
