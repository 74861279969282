import React from "react"
import { Popup, Flex } from "@ikiru/talentis-fpc"
import { navigationSelectors } from "components/visual/layout/definitions"

interface IFilterPopupProps {
  trigger: React.ReactElement
  onOpen?: VoidFunction
  onClose?: VoidFunction
  filterComponent?: React.ReactNode
}

export const FilterPopup = (props: IFilterPopupProps) => {
  const { trigger, onOpen, onClose, filterComponent } = props

  const newConfig = {
    PopupProps: {
      arrow: false,
      keepTooltipInside: true,
      onOpen,
      onClose
    },
    ContentProps: {
      boxShadow: "0 0 4px rgba(75, 106, 136, 0.8)"
    }
  }

  return (
    <Popup
      trigger={trigger}
      config={newConfig}
      closeOnScrollSelector={`#${navigationSelectors.mainContainer}`}
    >
      <Flex flexWrap={["wrap", "nowrap"]} bg="grey.lightest" maxHeight={500}>
        {filterComponent}
      </Flex>
    </Popup>
  )
}
