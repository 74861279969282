import React, { useCallback, useState, useEffect, useMemo } from "react"
import { messages } from "setup/messages/messages"
import { useSearch } from "views/search/SearchModule/context"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { Flex } from "@ikiru/talentis-fpc"
import { SaveSearchModal } from "../SaveSearchModal"
import { LoadSavedSearchesModal } from "../LoadSavedSearches"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { getSavedSearchData } from "./action"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { BookmarkIconStyled, SavedSearchButton } from "./style"
import moment from "moment"

export const SaveSearches = () => {
  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const [isOpenLoad, setIsOpenLoad] = useState<boolean>(false)
  const [wasSavedSearchesLoadedOnce, setWasSavedSearchesLoadedOnce] =
    useState<boolean>(false)
  const { open: openModal, close } = useModal()

  const {
    filters,
    loadExamplesSearch,
    savedSearches,
    handleSaveSearch,
    removeSavedSearch,
    setDefaultSearch,
    isLoadingSeaches,
    isFinishedLoadingSeaches,
    resetFilters,
    getPreparedFilters
  } = useSearch()

  const { trackSavedSearches } = useTelemetry()

  const sortedsavedSearches = useMemo(
    () =>
      savedSearches?.sort((a, b) => moment(b.createdDate).diff(a.createdDate)),
    [savedSearches]
  )

  const onSubmit = useCallback(
    (values: any) => {
      trackSavedSearches()
      handleSaveSearch(values, filters)
    },
    [filters, handleSaveSearch, trackSavedSearches]
  )

  const fetchSavedSearch = useCallback(
    async (searchId: string) => {
      resetFilters()
      const savedSearch = await getSavedSearchData(searchId)
      loadExamplesSearch(savedSearch.query)
    },
    [loadExamplesSearch, resetFilters]
  )

  const showSavedSearch = useCallback(
    (searchId: any) => {
      fetchSavedSearch(searchId)
    },
    [fetchSavedSearch]
  )

  const closeRemoveModal = useCallback(() => {
    close(ModalNames.DeleteSavedSearch)
    setIsOpenLoad(true)
  }, [close, setIsOpenLoad])

  const confirmRemove = useCallback(
    (searchId: any) => {
      close(ModalNames.DeleteSavedSearch)
      removeSavedSearch(searchId)
    },
    [close, removeSavedSearch]
  )

  const remove = useCallback(
    (searchId: string) => {
      close(ModalNames.LoadSaveSearches)
      openModal(
        ModalNames.DeleteSavedSearch,
        <ConfirmationModal
          title={messages.talentGraphSearch.savedSearch.removeSearchTitle}
          confirmButtonLabel={
            messages.talentGraphSearch.savedSearch.confirmButtonLabel
          }
          cancelButtonLabel={
            messages.talentGraphSearch.savedSearch.cancelButtonLabel
          }
          onCancel={closeRemoveModal}
          onClose={closeRemoveModal}
          onConfirm={() => confirmRemove(searchId)}
        />
      )
    },
    [close, confirmRemove, openModal, closeRemoveModal]
  )

  const openLoadSavedSearches = useCallback(() => {
    openModal(
      ModalNames.LoadSaveSearches,
      <LoadSavedSearchesModal
        showSavedSearch={showSavedSearch}
        searches={sortedsavedSearches}
        remove={remove}
        setDefaultSearch={setDefaultSearch}
      />
    )
    setIsOpenLoad(false)
  }, [
    openModal,
    remove,
    showSavedSearch,
    sortedsavedSearches,
    setDefaultSearch
  ])

  useEffect(() => {
    if (isLoadingSeaches) {
      setIsRemoving(true)
    }
  }, [isLoadingSeaches])

  useEffect(() => {
    if (isOpenLoad) {
      openLoadSavedSearches()
    }
  }, [isOpenLoad, openLoadSavedSearches])

  useEffect(() => {
    if (!isLoadingSeaches && isRemoving && !!savedSearches?.length) {
      openLoadSavedSearches()
      setIsRemoving(false)
    }
  }, [isLoadingSeaches, isRemoving, openLoadSavedSearches, savedSearches])

  useEffect(() => {
    if (isFinishedLoadingSeaches) {
      if (savedSearches?.length && !wasSavedSearchesLoadedOnce) {
        const defaultSearch = savedSearches.find((search) => search.isDefault)
        if (defaultSearch) {
          fetchSavedSearch(defaultSearch.id)
        }
      }
      setWasSavedSearchesLoadedOnce(true)
    }
  }, [
    isFinishedLoadingSeaches,
    savedSearches,
    fetchSavedSearch,
    wasSavedSearchesLoadedOnce
  ])

  return (
    <Flex>
      {!!savedSearches?.length && (
        <SavedSearchButton
          mode="standard-white"
          size="small"
          mr="xs"
          onClick={openLoadSavedSearches}
        >
          {messages.talentGraphSearch.savedSearch.load}
        </SavedSearchButton>
      )}
      <SavedSearchButton
        prefix={<BookmarkIconStyled />}
        mode="standard-grey-light"
        size="small"
        disabled={!getPreparedFilters()}
        onClick={() =>
          openModal(
            ModalNames.SaveSearch,
            <SaveSearchModal onSubmit={onSubmit} />
          )
        }
      >
        {messages.talentGraphSearch.savedSearch.save}
      </SavedSearchButton>
    </Flex>
  )
}
