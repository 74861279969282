import { ILocalPersonModel } from "models/LocalPerson/LocalPerson"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"

export enum PersonProfileFields {
  Name = "name",
  Company = "company",
  CompanyId = "companyId",
  Experience = "experience",
  Bio = "bio",
  EmailAddresses = "taggedEmails",
  JobTitle = "jobTitle",
  LinkedInProfileUrl = "linkedInProfileUrl",
  Location = "location",
  PhoneNumbers = "phones",
  WebSites = "webSites",
  DataPoolId = "dataPoolPersonId",
  RocketReachFetchedInformation = "rocketReachFetchedInformation",
  IsOffLimits = "isOfflimits",
  TeamMembers = "teamMembers",
  Title = "title",
  FirstName = "firstName",
  LastName = "lastName",
  MiddleName = "middleName",
  KnownAs = "knownAs",
  Nationality = "nationality",
  DateOfBirth = "dateOfBirth",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  AddressLine3 = "addressLine3",
  AddressLine4 = "addressLine4",
  City = "city",
  Country = "country",
  PostCode = "postcode",
  Education = "education"
}

export type PersonProfileDetails = Pick<
  ILocalPersonModel,
  | "name"
  | "company"
  | "companyId"
  | "experience"
  | "bio"
  | "taggedEmails"
  | "jobTitle"
  | "linkedInProfileUrl"
  | "location"
  | "phones"
  | "webSites"
  | "rocketReachFetchedInformation"
  | "isOfflimits"
  | "title"
  | "firstName"
  | "lastName"
  | "middleName"
  | "knownAs"
  | "nationality"
  | "dateOfBirth"
  | "addressLine1"
  | "addressLine2"
  | "addressLine3"
  | "addressLine4"
  | "city"
  | "country"
  | "postcode"
  | "education"
> & { dataPoolPersonId?: string } & { phoneNumbers?: PersonValue<string[]> }
