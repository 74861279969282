export enum SignupField {
  SearchFirmName = "searchFirmName",
  SearchFirmCountryCode = "searchFirmCountryCode",
  SearchFirmPhoneNumber = "searchFirmPhoneNumber",
  UserFirstName = "userFirstName",
  UserLastName = "userLastName",
  UserPassword = "userPassword",
  UserEmailAddress = "userEmailAddress",
  EmailConfirmed = "emailConfirmed",
  AccountDisabled = "accountDisabled",
  UserType = "userType"
}

export const SignupInitialValues = {
  [SignupField.SearchFirmName]: "",
  [SignupField.SearchFirmCountryCode]: "",
  [SignupField.SearchFirmPhoneNumber]: "",
  [SignupField.UserFirstName]: "",
  [SignupField.UserLastName]: "",
  [SignupField.UserPassword]: "",
  [SignupField.UserEmailAddress]: "",
  [SignupField.EmailConfirmed]: "",
  [SignupField.AccountDisabled]: "",
  [SignupField.UserType]: ""
}

export const signupE2ETargets = {
  successMessage: "signup-success-message"
}

export const signupAutofillNames = {
  [SignupField.UserFirstName]: "given-name",
  [SignupField.UserLastName]: "family-name",
  [SignupField.SearchFirmName]: "organization",
  [SignupField.SearchFirmCountryCode]: "country",
  [SignupField.SearchFirmPhoneNumber]: "phone",
  [SignupField.UserEmailAddress]: "email"
}
