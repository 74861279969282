import React, { useCallback } from "react"
import { Tag } from "@ikiru/talentis-fpc"
import { Tag as TagType } from "components/Tags/tags.types"
import { tagColor } from "views/assignments/components/candidates-list/helpers"

type PersonRecordTagProps = {
  personRecordTags: number[]
  tag: TagType
  personRecordId: string
  usePersonRecordTags: any
}

export const PersonRecordTag = ({
  personRecordTags,
  tag,
  personRecordId,
  usePersonRecordTags
}: PersonRecordTagProps) => {
  const { onTagsSelect } = usePersonRecordTags()

  const removeTag = useCallback(() => {
    let tempTags: number[] = personRecordTags
    tempTags = tempTags.filter((item) => item !== tag.id)
    onTagsSelect(tempTags, personRecordId)
  }, [personRecordTags, personRecordId, tag.id, onTagsSelect])

  return (
    <Tag
      variant={tagColor(tag.background)}
      height={24}
      mt="xs"
      mb="0"
      key={tag.name}
      onRemoveClick={removeTag}
      disabled
    >
      {tag.name}
    </Tag>
  )
}
