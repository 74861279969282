import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { useAssignmentData } from "views/assignments/hooks/useAssignmentData"
import { useCandidates } from "views/assignments/hooks/useCandidates"
import { ClientPortalConfigurationContext } from "./context"
import {
  ClientPortalConfigurationModuleLocationParams,
  ClientPortalConfigurationModuleProps
} from "./types"
import { Candidate } from "views/candidates/candidates.types"
import { groupCandidates } from "views/assignments/assignment-module/assignment-module.helpers"
import { Nullable } from "tsdef"
import { Note } from "components/NoteItem/types"

const ClientPortalConfigurationModule = (
  props: ClientPortalConfigurationModuleProps
) => {
  const { children } = props
  const { assignmentId = "" } =
    useParams<ClientPortalConfigurationModuleLocationParams>()

  const { isLoading: isAssignmentLoading, assignment } = useAssignmentData({
    assignmentId
  })

  const {
    photos,
    candidates,
    groupedCandidates,
    isLoading: isCandidatesLoading,
    updateCandidates
  } = useCandidates({ assignmentId })

  const toggleCandidatesInClientView = useCallback(
    (candidateIds: string[], showInClientView: boolean) => {
      candidateIds.forEach((id) => {
        apiRequest.patch({
          endpoint: CandidatesEndpoints.Root,
          endpointParams: id,
          data: {
            showInClientView
          }
        })
      })

      const newCandidates = candidates.map((candidate: Candidate) =>
        candidateIds.includes(candidate.id)
          ? { ...candidate, showInClientView }
          : candidate
      )
      const groupedCandidates = groupCandidates(newCandidates)

      updateCandidates({ candidates: newCandidates, groupedCandidates })
    },
    [candidates, updateCandidates]
  )

  const toggleCandidatesShowCommentaryInClientView = useCallback(
    (candidatesToBePatched: Candidate[], showToClient: boolean) => {
      candidatesToBePatched.forEach(({ id, commentary }) => {
        apiRequest.patch({
          endpoint: CandidatesEndpoints.Root,
          endpointParams: id,
          data: { commentary: { data: commentary.data, showToClient } }
        })
      })

      const newCandidates = candidates.map((candidate: Candidate) =>
        candidatesToBePatched
          .map((candidate) => candidate.id)
          .includes(candidate.id)
          ? {
              ...candidate,
              commentary: { ...candidate.commentary, showToClient }
            }
          : candidate
      )
      const groupedCandidates = groupCandidates(newCandidates)

      updateCandidates({ candidates: newCandidates, groupedCandidates })
    },
    [candidates, updateCandidates]
  )

  const updateCandidateNote = useCallback(
    (candidateId: any, note: Nullable<Note>) => {
      const newCandidates = candidates.map((candidate: Candidate) =>
        candidate.id === candidateId
          ? {
              ...candidate,
              linkSharedNote: note,
              sharedNoteId: note?.id || null
            }
          : candidate
      )
      const groupedCandidates = groupCandidates(newCandidates)

      updateCandidates({ candidates: newCandidates, groupedCandidates })
    },
    [candidates, updateCandidates]
  )

  return (
    <ClientPortalConfigurationContext.Provider
      value={{
        photos,
        assignment,
        candidates,
        groupedCandidates,
        isAssignmentLoading,
        isCandidatesLoading,
        updateCandidateNote,
        toggleCandidatesInClientView,
        toggleCandidatesShowCommentaryInClientView
      }}
    >
      {children}
    </ClientPortalConfigurationContext.Provider>
  )
}

export default React.memo(ClientPortalConfigurationModule)
