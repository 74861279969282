import { InternalFilterType } from "views/internal-search/InternalSearchModule/types"

export const FIELD_BOXES_LIMIT = 10

export const initialTemplateString = `${InternalFilterType.Sections}[{0}].fields[{1}]`
export const sectionConditionTemplateString = `${InternalFilterType.Sections}[{0}].condition`
export const sectionFieldTemplateString = `${InternalFilterType.Sections}[{0}].fields[{1}].id`
export const sectionFieldConditionTemplateString = `${InternalFilterType.Sections}[{0}].fields[{1}].condition`
export const timeFrameFieldConditionTemplateString = `${InternalFilterType.Sections}[{0}].fields[{1}].timeFrame`
export const sectionFieldSearchValuesTemplateString = `${InternalFilterType.Sections}[{0}].fields[{1}].values`
