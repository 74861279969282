import { apiRequest } from "setup/api/api"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { GetFormAction } from "utils/forms/form.types"
import { InterviewProgressValues } from "views/campaigns/components/contacts/components/contact-record/components/InterviewProgress/definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type GetOnChangeInterviewProgress = GetFormAction<InterviewProgressValues>

export const getOnChangeInterviewProgress: GetOnChangeInterviewProgress =
  (contactId, onSuccess) => async (values, helpers) => {
    const { setSubmitting } = helpers

    const [error, response] = await apiRequest.patch({
      endpoint: ContactsEndpoints.Root,
      endpointParams: contactId,
      data: { ...values },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    setSubmitting(false)
    response && onSuccess!(response.data)

    return [error, response]
  }
