import { messages } from "setup/messages/messages"

export type AssignmentStatusOption = {
  label: string
  value: AssignmentStatus
}

export enum AssignmentStatus {
  Lead = "lead",
  Opportunity = "opportunity",
  Active = "active",
  OnHold = "onHold",
  Abandoned = "abandoned",
  Placed = "placed"
}

export const assignmentStatusMap = new Map([
  [AssignmentStatus.Lead, messages.assignment.statuses.lead],
  [AssignmentStatus.Opportunity, messages.assignment.statuses.opportunity],
  [AssignmentStatus.Active, messages.assignment.statuses.active],
  [AssignmentStatus.OnHold, messages.assignment.statuses.onHold],
  [AssignmentStatus.Abandoned, messages.assignment.statuses.abandoned],
  [AssignmentStatus.Placed, messages.assignment.statuses.placed]
])

const lead: AssignmentStatusOption = {
  value: AssignmentStatus.Lead,
  label: messages.assignment.statuses.lead
}

const opportunity: AssignmentStatusOption = {
  value: AssignmentStatus.Opportunity,
  label: messages.assignment.statuses.opportunity
}

const active: AssignmentStatusOption = {
  value: AssignmentStatus.Active,
  label: messages.assignment.statuses.active
}

const onHold: AssignmentStatusOption = {
  value: AssignmentStatus.OnHold,
  label: messages.assignment.statuses.onHold
}

const abandoned: AssignmentStatusOption = {
  value: AssignmentStatus.Abandoned,
  label: messages.assignment.statuses.abandoned
}

const placed: AssignmentStatusOption = {
  value: AssignmentStatus.Placed,
  label: messages.assignment.statuses.placed
}

export const assignmentStatuses = [
  lead,
  opportunity,
  active,
  onHold,
  abandoned,
  placed
]
