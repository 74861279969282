import React, { useEffect, useCallback, useState, useMemo } from "react"
import { apiRequest } from "setup/api/api"
import { scrollToTop } from "views/search/SearchModule/utils"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { CompanyPersonRecordProps } from "../components/company-details/components/KnowEmployees/CompanyPersonRecord"
import { fetchPhotosAndSetUpdatedEntities } from "models/LocalPerson/localPerson.actions"

type PaginationKnowEmployeesListType = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageCount: number
  pageNumber: number
  pageSize: number
  totalItemCount: number
  maxPages: number
}

export enum EmployeeType {
  Current = "current",
  Former = "former"
}

export type CompanyKnowEmployeesTypes = {
  paginationKnowEmployeesList: PaginationKnowEmployeesListType
  isLoading: boolean
  knowEmployees: CompanyPersonRecordProps[]
  setKnowEmployees: React.Dispatch<
    React.SetStateAction<CompanyPersonRecordProps[]>
  >
  totalPages: number
  handlePageChange: (page: number) => void
  setMode: (mode: EmployeeType) => void
  mode: EmployeeType
}

const useCompanyKnowEmployees = (companyId: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [knowEmployees, setKnowEmployees] = useState<
    CompanyPersonRecordProps[]
  >([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [mode, setMode] = useState<EmployeeType>(EmployeeType.Current)

  const paginationKnowEmployeesList = useMemo(
    () => ({
      hasNextPage: pageNumber < totalPages,
      hasPreviousPage: pageNumber > 1,
      pageCount: totalPages,
      pageNumber: pageNumber,
      pageSize: 20,
      totalItemCount: totalItems,
      maxPages: 5
    }),
    [pageNumber, totalPages, totalItems]
  )

  const handlePageChange = useCallback((page: number) => {
    scrollToTop()
    setPageNumber(page)
  }, [])

  const fetchKnowEmployees = useCallback(async () => {
    if (!companyId) {
      console.error("no company id, cannot fetch employees")
      return
    }
    setIsLoading(true)
    const [, response] = await apiRequest.get<{
      people: any[]
      totalPages: number
      totalItemCount: number
      currentPage: number
    }>({
      endpoint: PersonsEndpoints.Employees,
      endpointParams: { id: companyId, mode },
      config: {
        params: {
          pageSize: 20,
          page: pageNumber
        }
      }
    })

    setTotalItems(response?.data?.totalItemCount || 0)
    setTotalPages(response?.data.totalPages || 0)

    fetchPhotosAndSetUpdatedEntities(
      response?.data?.people || [],
      setKnowEmployees
    )

    setKnowEmployees(response?.data?.people || [])

    setIsLoading(false)
  }, [companyId, pageNumber, mode])

  useEffect(() => {
    fetchKnowEmployees()
  }, [companyId, pageNumber, fetchKnowEmployees])

  const setModeAndRefreshEmployees = useCallback(
    (mode: EmployeeType) => {
      setKnowEmployees([])
      setMode(mode)
    },
    [setKnowEmployees, setMode]
  )

  return {
    isLoading,
    knowEmployees,
    setKnowEmployees,
    totalPages,
    paginationKnowEmployeesList,
    handlePageChange,
    setMode: setModeAndRefreshEmployees,
    mode
  }
}

export { useCompanyKnowEmployees }
