import React from "react"
import { Flex, BodyText } from "@ikiru/talentis-fpc"
import { StyledCard, StyledH5, IconStyled } from "./style"

type SearchCardProps = {
  cardImg: string
  title: string
  description: string
  onSelectCard: () => void
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  isDisabled?: boolean
  isSmallIcon?: boolean
}

export const SearchCard = ({
  cardImg,
  title,
  description,
  onSelectCard,
  icon: Icon,
  isDisabled = false,
  isSmallIcon = false
}: SearchCardProps) => {
  const size = isSmallIcon ? 20 : 30
  return (
    <Flex flexDirection="column" width="200px" mr="l">
      <StyledCard
        onClick={isDisabled ? undefined : onSelectCard}
        isDisabled={isDisabled}
      >
        {Icon && (
          <IconStyled>
            <Icon width={size} height={size} />
          </IconStyled>
        )}
        <img
          alt={title}
          src={cardImg}
          //@ts-ignore
          width="176px"
          height="176px"
        />
        <StyledH5>{title}</StyledH5>
      </StyledCard>
      <BodyText>{description}</BodyText>
    </Flex>
  )
}
