import React, { useCallback } from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { H2 } from "@ikiru/talentis-fpc"
import { PricingCard } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { mapPlanToPricingCard, sortPlansByType } from "./utils"
import {
  SubscriptionPlan,
  SubscriptionPurchaseStep
} from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import { Loader } from "@ikiru/talentis-fpc"

export const PlanSelection = () => {
  const { availablePlans, arePlansFetching, selectPlan, changeStep } =
    useSubscriptionPurchase()

  const onChooseSubscriptionPlan = useCallback(
    (plan: SubscriptionPlan) => () => {
      selectPlan(plan)
      changeStep(SubscriptionPurchaseStep.PLAN_DETAILS)
    },
    [changeStep, selectPlan]
  )

  if (arePlansFetching)
    return (
      <Flex width="100%" p="xl">
        <Loader>
          <H2 color="green.standard" my="none">
            {messages.subscription.loadingPlans}
          </H2>
        </Loader>
      </Flex>
    )

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex justifyContent="center" mt="l" mb={["xxs", "m"]} flexWrap="wrap">
        {sortPlansByType(availablePlans).map((plan) => {
          const pricingCardProps = mapPlanToPricingCard(plan)

          if (!pricingCardProps) return null

          return (
            <PricingCard
              key={plan.type}
              width={300}
              {...pricingCardProps}
              onChoose={onChooseSubscriptionPlan(plan)}
              mx="s"
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
