import { Assignment } from "views/assignments/assignment.types"
import { AssignmentStatus } from "views/assignments/constants/assignment-statuses"

export type GroupedAssignment = Partial<Record<AssignmentStatus, Assignment[]>>

export const assignmentListE2ETargets = {
  list: "assignments-list",
  linkToDetails: "assign-link",
  title: "assignments-list-title",
  submitButton: "submit-button"
}

export const searchInputName = "searchString"

export type SearchFormValues = {
  searchString: string
}

export const searchFormInitialValues = {
  searchString: ""
}
