import styled, { css } from "styled-components"
import { Flex, Div, colors, spacing, BodyText } from "@ikiru/talentis-fpc"
import { scrollBarCvStyle } from "components/FPCComponents/DragAndDrop/Columns/style"

export const ParsingResumeModalWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 95vw;
  height: 95vh;
`

export const CVParsingAlertModalWrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${colors.grey.light};
  padding: ${spacing.m}px;
  width: 600px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`

export const ToggleWrapper = styled(Div)`
  display: grid;
  place-items: center;
  background-color: ${colors.white.standard};
  width: 45px;
`

export const ParsedWrapper = styled(Flex)`
  background-color: ${colors.white.standard};
  align-items: baseline;
  width: 42.4%;
`

export const DateWrapper = styled(Flex)`
  border: 0.5px solid ${colors.grey.light};
  width: 100%;
  align-items: stretch;
`

export const borderValue = `1px solid ${colors.grey.light}`

export const ExistingDateWrapper = styled(BodyText)`
  border-left: ${borderValue};
  border-right: ${borderValue};
  width: 42.3%;
  margin: 0;
  padding: ${spacing.xxs}px;
`

export const commonHeights = css`
  height: 35vh;

  @media (min-height: 576px) {
    height: 55vh;
  }
  @media (min-height: 768px) {
    height: 65vh;
  }
`
export const DragAndDropContainer = styled(Flex)`
  flex-direction: column;
`

export const containerDefaults = css`
  flex-direction: column;
  ${scrollBarCvStyle}
`

export const PersonDetailsContainer = styled(Flex)`
  ${containerDefaults}
  ${commonHeights}
`

export const PersonContactsContainer = styled(Flex)`
  ${containerDefaults}
  ${commonHeights}
`

export const PersonKeywordsContainer = styled(Flex)`
  ${containerDefaults}
  overflow-y: scroll;
  height: 10vh;

  @media (min-height: 576px) {
    height: 10vh;
  }
  @media (min-height: 768px) {
    height: 20vh;
  }
`

export const PersonEducationContainer = styled(Div)`
  width: 100%;
  box-sizing: border-box;
`

export const OverviewCVModalWrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 95vw;
  height: 95vh;
`

export const OverviewSectionContainer = styled(Div)`
  ${containerDefaults}
`
