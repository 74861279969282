import { Note } from "components/NoteItem/types"
import React, { useCallback, useMemo } from "react"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { ModalNames } from "setup/modal/modal.definitions"
import { Nullable } from "tsdef"
import { useModal } from "utils/hooks/use-modal"
import { Candidate } from "views/candidates/candidates.types"
import { useClientPortalConfiguration } from "../../module/context"
import { transformJobs } from "../../utils"
import NoteSelectionModal from "../NoteSelectionModal"

const useCandidateRecord = (candidate: Candidate) => {
  const { open: openModal, close: closeModal } = useModal()
  const { updateCandidateNote } = useClientPortalConfiguration()

  const closeNoteSelectionModal = useCallback(
    () => closeModal(ModalNames.PortalConfigurationCandidateNoteSelection),
    [closeModal]
  )

  const onSelectNote = useCallback(
    async (note: Nullable<Note>) => {
      const [, response] = await apiRequest.patch({
        endpoint: CandidatesEndpoints.Root,
        endpointParams: candidate.id,
        data: {
          sharedNoteId: note?.id || null
        }
      })

      closeNoteSelectionModal()

      if (response) {
        updateCandidateNote(candidate.id, note)
      }
    },
    [candidate.id, closeNoteSelectionModal, updateCandidateNote]
  )

  const openSelectNoteModal = useCallback(() => {
    openModal(
      ModalNames.PortalConfigurationCandidateNoteSelection,
      <NoteSelectionModal
        {...{ candidate }}
        onCancel={closeNoteSelectionModal}
        onSave={onSelectNote}
      />,
      true
    )
  }, [openModal, candidate, closeNoteSelectionModal, onSelectNote])

  const jobs = useMemo(
    () =>
      transformJobs(
        candidate?.normalizedPersonData?.previousJobs,
        candidate?.normalizedPersonData?.currentJob
      ),
    [candidate]
  )

  const websites = useMemo(
    () => [
      ...(candidate?.normalizedPersonData?.linkedInProfileUrl
        ? [
            {
              type: "linkedIn",
              url: candidate?.normalizedPersonData?.linkedInProfileUrl
            }
          ]
        : []),
      ...(candidate.normalizedPersonData?.webSites || [])
    ],
    [candidate]
  )

  return { jobs, websites, openSelectNoteModal }
}

export { useCandidateRecord }
