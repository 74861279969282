import { Booleans, Conditions, InternalSearchFilters } from "./types"

//For predefined values such as stages, statuses, team members etc
export const predefined = [
  { value: "equal", label: "Equals (IS EXACTLY) " },
  { value: "notEqual", label: "Does not equal (IS DIFFERENT TO)" },
  { value: "blank", label: "Is blank" },
  { value: "any", label: "Is any of (OR) " },
  { value: "none", label: "Is none of (EXCLUDES)" },
  { value: "all", label: "Is all of (AND) " }
]

//For documents, cvs, notes description/title, etc
export const docs = [
  { value: "equal", label: "Equals (IS EXACTLY) " },
  { value: "contain", label: "Contains" }
]

//For names, company names, assignment title, job title, etc - string
export const strings = [
  { value: "equal", label: "Equals (IS EXACTLY) " },
  { value: "beginWith", label: "Begins with" },
  { value: "blank", label: "Is blank" },
  { value: "notBlank", label: "Is not blank " },
  { value: "contain", label: "Contains" }
]

//For numbers
export const numbers = [
  { value: "equal", label: "Equals" },
  { value: "greater", label: "Is greater than" },
  { value: "greaterOrEqual", label: "Is greater than or equal to" },
  { value: "less", label: "Is less than" },
  { value: "lessOrEqual", label: "Is less than or equal to" },
  { value: "between", label: "Is between" },
  { value: "notBetween", label: "Is not in between" }
]

export const dates = [
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
  { value: "between", label: "Between" }
]

export const defaultFilters: InternalSearchFilters = {
  sections: [
    {
      id: "personDetails",
      condition: Conditions.AND,
      fields: [
        {
          values: [],
          condition: Booleans.Contains,
          id: "displayName"
        },
        {
          values: [],
          condition: "any",
          id: "teamMember"
        }
      ]
    },
    {
      id: "professionalExperience",
      condition: Conditions.AND,
      fields: [
        {
          values: [],
          condition: Booleans.Contains,
          id: "experienceTitle"
        },
        {
          values: [],
          condition: Booleans.Contains,
          id: "experienceCompanyName"
        }
      ]
    }
  ],
  condition: Conditions.AND
}
