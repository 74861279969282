import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { useCompany } from "views/companies/company-module/company-module.context"
import { CompanyDetails } from "../../components/company-details/index"

export const Company = () => {
  const { isLoading, companyDetails } = useCompany()

  return isLoading && !companyDetails ? (
    <Loader />
  ) : (
    <Flex flexWrap="wrap">
      <CompanyDetails />
    </Flex>
  )
}
