import React from "react"
import { MainTagPiece } from "@ikiru/talentis-fpc"
import { components } from "react-select"

// any = prop from components.MultiValueLabel
export const MultiValueLabel = (props: any) => {
  const { innerProps, ...other } = props

  return (
    <MainTagPiece
      m="none"
      variant={props?.selectProps?.tagVariant || "grey"}
      removable
    >
      <components.MultiValueLabel {...other} />
    </MainTagPiece>
  )
}
