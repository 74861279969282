import React, { useCallback, useMemo } from "react"
import ProjectSwitch from "components/ProjectActions/ProjectSwitch"
import {
  Div,
  Flex,
  SectionBody,
  SectionHeader,
  useMedia
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import { NoTasks } from "./components/NoTasks"
import { Pagination } from "components/functional/pagination/Pagination"
import { NoMatchingResults } from "components/ProjectRecordsFilters/NoMatchingResults"
import HorizontalScroll from "components/HorizontalScroll"
import { TabsWrapper } from "components/HorizontalScroll/Tabs/style"
import TabCard from "components/HorizontalScroll/Tabs/index"
import Tasks from "./components/Tasks"
import TaskPersonOverlay from "views/persons/components/person-overlay/TaskPersonOverlay"
import { scrollToTop } from "../../../search/SearchModule/utils"
import { getAppliedFiltersParams } from "../../../assignments/components/candidates-list/components/candidate-filters/helper"
import { getTasksList } from "../../task-management-module/task-management-module.helpers"
import { CandidateFilterContainer } from "../../../assignments/components/candidates-list/style"
import { TaskCandidateFilters } from "../../../assignments/components/candidates-list/components/candidate-filters/TaskCandidateFilters"

const arrayTabs = (array: any) => {
  let tempTabs = []
  for (let key in array) {
    if (key !== "all") {
      tempTabs.push({ label: key, count: array[key] })
    }
  }
  return tempTabs
}

const TaskManagement = () => {
  const {
    isAssignment,
    setIsAssignment,
    isLoading,
    pageNumber,
    pageSize,
    totalFilteredCount,
    setPageNumber,
    dueDates,
    selectedTab,
    setSelectedTab,
    tasks,
    appliedFilters,
    selectedRecordId
  } = useTaskManagement()

  const breakpoint = useMedia()

  const pageCount = useMemo(
    () => Math.ceil(totalFilteredCount / pageSize),
    [totalFilteredCount, pageSize]
  )

  const dueDatesTabs = useMemo(() => arrayTabs(dueDates), [dueDates])

  const onPageChange = useCallback(
    (pageNumber: any) => {
      setPageNumber(pageNumber)
    },
    [setPageNumber]
  )

  const tabOnClick = useCallback(
    (label: any) => {
      setSelectedTab(label)
      setPageNumber(1)
    },
    [setSelectedTab, setPageNumber]
  )

  const setSwitch = useCallback(() => {
    setIsAssignment(!isAssignment)
    setSelectedTab("today")
    setPageNumber(1)
  }, [setIsAssignment, setPageNumber, setSelectedTab, isAssignment])

  const handlePageChange = useCallback(
    (page: any) => {
      scrollToTop()
      onPageChange(page)
      const params = getAppliedFiltersParams(appliedFilters)
      params.append("page", page)
      getTasksList(params)
    },
    [onPageChange, appliedFilters]
  )

  return (
    <>
      <Div backgroundColor="grey.lightest" width="100%">
        <SectionHeader
          size="xSmall"
          title={messages.taskManagement.title}
          flexDirection={["column", "row"]}
          alignItems={["flex-start", "center"]}
          actions={
            <ProjectSwitch
              isAssignment={isAssignment}
              setIsAssignment={setSwitch}
            />
          }
        />
        <TabsWrapper>
          <HorizontalScroll isRowTab={true}>
            {dueDatesTabs?.map(({ label, count }: any, index) => (
              <TabCard
                width={["xl", "lg"].includes(breakpoint) ? "200px" : "130px"}
                isSelected={selectedTab === label}
                itemId={label}
                count={count}
                label={label}
                key={label + index}
                handleClick={tabOnClick}
                isRowTab={true}
              />
            ))}
          </HorizontalScroll>
        </TabsWrapper>
        {isLoading ? (
          <Flex my="xl">
            <Loader />
          </Flex>
        ) : tasks.length ? (
          <>
            <Flex width="100%" flexDirection="column">
              <CandidateFilterContainer>
                <TaskCandidateFilters />
              </CandidateFilterContainer>
              <Tasks />
            </Flex>
            {pageCount > 1 && (
              <Flex
                width="100%"
                flexDirection="row"
                justifyContent="center"
                mb="s"
              >
                <Pagination
                  totalItemCount={totalFilteredCount}
                  pageSize={pageSize}
                  pageNumber={pageNumber || 1}
                  hasNextPage={pageNumber !== pageCount}
                  hasPreviousPage={pageNumber !== 1}
                  pageCount={pageCount}
                  maxPages={10}
                  onPageChange={(pageNumber) => onPageChange(pageNumber)}
                />
              </Flex>
            )}
          </>
        ) : appliedFilters.length ? (
          <Flex width="100%" flexDirection="column">
            <CandidateFilterContainer>
              <TaskCandidateFilters />
            </CandidateFilterContainer>
            <NoMatchingResults />
          </Flex>
        ) : (
          <SectionBody>
            <NoTasks date={selectedTab} />
          </SectionBody>
        )}
      </Div>
      {selectedRecordId && (
        <TaskPersonOverlay handlePageChange={handlePageChange} />
      )}
    </>
  )
}

export default TaskManagement
