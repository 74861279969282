import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import {
  Flex,
  Select,
  Toggle,
  colors,
  Error,
  SelectOptionProps
} from "@ikiru/talentis-fpc"
import { CVCustomInputWrapper, PencilIconStyle, StyledInput } from "./style"
import InputFooter from "./InputFooter"
import { ContactInfoObject } from "views/persons/person.types"
import { getErrorMessage, getRegExp } from "../parsing/helper"
import OverviewInputFooter from "./OverviewInputFooter"

type CVCustomInputProps = {
  onChangeInput: (date: ContactInfoObject) => void
  text: string
  selectedValue: string
  isPreferred: boolean
  type: string
  options: SelectOptionProps[]
  width?: string
  typeText?: string
  isDisabled?: boolean
  isOverview?: boolean
  handleDelete?: () => void
  saveTempValue?: (date: ContactInfoObject) => void
}

const CVCustomInput = ({
  text,
  selectedValue,
  isPreferred,
  onChangeInput,
  width,
  type,
  options,
  typeText,
  isDisabled,
  isOverview = false,
  handleDelete,
  saveTempValue
}: CVCustomInputProps) => {
  const [error, setError] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [editedText, setEditedText] = useState(text)
  const [isChecked, setIsChecked] = useState(isPreferred)
  const [typeSelected, setTypeSelected] = useState(selectedValue)

  const inputRef = useRef(null)

  const textType = useMemo(
    () => (typeText && text ? `${editedText} (${typeText})` : editedText),
    [editedText, typeText, text]
  )

  const handleEditClick = () => {
    setIsEditing(true)
  }

  useEffect(() => {
    if (isEditing) {
      //@ts-ignore
      inputRef.current?.focus()
    }
  }, [isEditing])

  const handleSaveClick = () => {
    const tempDate: ContactInfoObject = {
      text: editedText,
      selectedValue: typeSelected,
      isPreferred: isChecked
    }

    onChangeInput(tempDate)
    setIsEditing(false)
    setError("")
  }

  const handleCancelClick = () => {
    setEditedText(text)
    setTypeSelected(selectedValue)
    setIsChecked(isPreferred)
    setIsEditing(false)
    setError("")
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const regexString = getRegExp(type)

    if (!regexString.test(value)) {
      setError(getErrorMessage(type))
    } else {
      const removeSpaces = value.replace(/\s/g, "")
      setEditedText(removeSpaces)

      setError("")
    }
    const tempDate: ContactInfoObject = {
      text: value,
      selectedValue: typeSelected,
      isPreferred: isChecked
    }
    saveTempValue && saveTempValue(tempDate)
    setEditedText(value)
  }

  return (
    <CVCustomInputWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      isEditing={isEditing}
      width={width || "100%"}
      isDisabled={isDisabled}
    >
      <StyledInput
        ref={inputRef}
        value={isEditing ? editedText : textType}
        onChange={handleInputChange}
        disabled={!isEditing || isDisabled}
        isEditing={isEditing}
      />
      {error && <Error>{error}</Error>}
      {!isEditing && isHover && !isDisabled && (
        <>
          {isOverview ? (
            <OverviewInputFooter
              handleDelete={handleDelete}
              handleEditClick={handleEditClick}
            />
          ) : (
            <PencilIconStyle
              color={colors.grey.dark}
              onClick={handleEditClick}
              width={14}
              height={14}
            />
          )}
        </>
      )}
      {isEditing && (
        <Flex justifyContent="space-between" width="100%">
          <Flex width="250px">
            <Select
              options={options}
              value={typeSelected}
              status="valid"
              variant="small"
              onChange={(e) => {
                // @ts-ignore
                setTypeSelected(e.target.value)
                saveTempValue &&
                  saveTempValue({
                    text: editedText,
                    // @ts-ignore
                    selectedValue: e.target.value,
                    isPreferred: isChecked
                  })
              }}
            />

            <Toggle
              wrapperProps={{ ml: "15px" }}
              hideUnderline
              small
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked((pre) => !pre)
                saveTempValue &&
                  saveTempValue({
                    text: editedText,
                    selectedValue: typeSelected,
                    isPreferred: !isChecked
                  })
              }}
            />
          </Flex>

          <InputFooter
            isDisabled={!!error}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        </Flex>
      )}
    </CVCustomInputWrapper>
  )
}

export default CVCustomInput
