import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import AssignmentNotesEditMode from "./components/EditMode"
import NotesList from "./components/NotesList"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Button, Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

const AssignmentNotes = () => {
  const { assignmentId, notes, setNotes } = useAssignment()

  const { open, close } = useModal()

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <AssignmentNotesEditMode {...{ assignmentId, notes, setNotes, close }} />
    )
  }, [assignmentId, notes, setNotes, close, open])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <Button mode="standard-green" size="small" onClick={noteOpenModal}>
            + Note
          </Button>
        )
      }}
    >
      <Div py="s" width="100%">
        <NotesList />
      </Div>
    </Section>
  )
}

export default React.memo(AssignmentNotes)
