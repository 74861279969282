import styled from "styled-components"
import { SpaceProps } from "styled-system"
import { fontFamilies } from "@ikiru/talentis-fpc"
import { Span } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"

// To offset the Link underline and keep the "remove" link in line
// with the promo code
const linkOffset = "-6"

export const PromoAddedText = styled(Span)<SpaceProps>`
  font-family: ${fontFamilies.gibson};
  font-weight: 500;
`

export const RemoveLink = styled(Link)`
  margin: 0 0 ${linkOffset}px ${({ theme }) => theme.space.xs}px;
  word-break: normal;
`
