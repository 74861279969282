export enum DragType {
  ExpItem = "expItem",
  ParsedCompany = "parsedCompany",
  ParsedTitle = "parsedTitle",
  ParsedDate = "parsedDate",
  ParsedDescription = "parsedDescription"
}

export enum DraggableDataName {
  Title = "title",
  Company = "company",
  Date = "date",
  Description = "description"
}
