import React, { useMemo } from "react"
import { Avatar } from "@ikiru/talentis-fpc"
import { Nullable } from "tsdef"
import { PersonLink } from "../PersonLink"
import { AvatarWrapper } from "./style"

type AvatarProp = {
  name: Nullable<string> | undefined
  personId: Nullable<string> | undefined
  datapoolId?: string
  photoUrl: Nullable<string> | undefined
  setPersonId: (data: string) => void
  setOverlayTaskIndex?: (taskIndex: number) => void
  taskIndex?: number
  width?: number
  height?: number
  companyId?: string
}

export const PersonAvatarOverlay = React.memo(
  ({
    personId,
    datapoolId,
    photoUrl,
    name,
    setPersonId,
    setOverlayTaskIndex,
    taskIndex,
    width,
    height,
    companyId
  }: AvatarProp) => {
    const cursor = useMemo(() => (personId ? "pointer" : "normal"), [personId])
    return (
      <AvatarWrapper height={height || 60} width={width || 60}>
        <PersonLink
          personId={personId}
          underline={false}
          setPersonId={setPersonId}
          setOverlayTaskIndex={setOverlayTaskIndex}
          taskIndex={taskIndex}
          dataPoolId={datapoolId}
          companyId={companyId || undefined}
        >
          <Avatar
            borderless
            width={width || 60}
            height={height || 60}
            disableHoverOpacity
            photo={{ url: photoUrl }}
            {...{ cursor, name }}
          />
        </PersonLink>
      </AvatarWrapper>
    )
  }
)
