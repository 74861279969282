import React from "react"
import { Div } from "@ikiru/talentis-fpc"

type RotateActionProps = {
  isActive: boolean
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
}

const RotateAction = ({ isActive, children }: RotateActionProps) => {
  return (
    <Div
      style={{
        transform: `rotate(${isActive ? "180deg" : 0})`
      }}
    >
      {children}
    </Div>
  )
}

export default RotateAction
