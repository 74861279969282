import styled from "styled-components"
import { fontWeight } from "styled-system"
import { GridBox } from "@ikiru/talentis-fpc"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { StyledSystemProps } from "@ikiru/talentis-fpc"

type DuplicateRecordWrapperProps = {
  isActive: boolean
  maxWidth: string
}

export const DuplicateRecordWrapper = styled(Flex)<DuplicateRecordWrapperProps>`
  height: 90px;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: 220px;

  align-items: center;
  padding: ${({ theme }) => theme.space.xs}px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isActive, theme }) =>
    isActive && `border-color: ${theme.colors.grey.dark}`};
`

export const AvatarWrapper = styled(Div)`
  min-width: 50px;
  width: 50px;
  height: 50px;
`

export const PersonDetails = styled(Flex)`
  flex-direction: column;
  margin-left: ${({ theme }) => theme.space.xs}px;
  margin-right: ${({ theme }) => theme.space.xs}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TruncatedText = styled(SmallText)<StyledSystemProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  ${fontWeight}
`

export const WebsitesDetailsBox = styled(GridBox)`
  grid-area: websites;
  padding: ${({ theme }) => theme.space.xs}px;

  & svg {
    height: 100%;
    width: auto;
    transition: filter 0.3s ease;
  }

  svg {
    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const LinkIcon = styled.a``

export const WebsiteIcon = styled.a`
  margin-bottom: ${({ theme }) => theme.space.xxs}px;

  & svg {
    height: 24px;
  }
`
