import {
  InterviewProgressField,
  InterviewProgressStage,
  InterviewProgressStatus
} from "../components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"

export const e2eTargets = {
  assignmentDetails: "assignment-details"
}

export const interviewProgressInitialValues = {
  [InterviewProgressField.Stage]: InterviewProgressStage.Identified,
  [InterviewProgressField.Status]: InterviewProgressStatus.NoStatus,
  [InterviewProgressField.DueDate]: null,
  [InterviewProgressField.AssignTo]: null
}

export const pageSize = 25
