import { Div, colors } from "@ikiru/talentis-fpc"
import { XYCoord } from "react-dnd"
import styled from "styled-components"

type LayerDragItemContainerProps = {
  currentOffset: XYCoord
}

export const DraggingStyledDiv = styled(Div)`
  background-color: ${colors.green.lightest};
  width: 420px;
  transform: rotate(7.021deg);
`
export const LayerDragItemContainer = styled(Div)<LayerDragItemContainerProps>`
  position: fixed;
  pointer-events: none;
  z-index: 101;
  left: 0;
  top: 0;
  max-width: 440px;
  transform: ${({ currentOffset }) =>
    `translate(${currentOffset.x}px, ${currentOffset.y}px)`};
`
