import React, { useRef } from "react"
import { messages } from "setup/messages/messages"
import { UploadPersonDocument } from "views/persons/components/person-documnets/form/UploadPersonDocument"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { getOnUploadPersonDocumentSubmit } from "views/persons/components/person-documnets/form/upload-person-document.actions"
import { PersonDocumentList } from "views/persons/components/person-documnets/components/PersonDocumentsList"
import { Loader } from "components/Loader/Loader"
import { Flex, SectionBody, SectionHeader } from "@ikiru/talentis-fpc"
import { EditableDetails } from "components/EditableDetails/EditableDetails"
import TabSectionHeader from "../person-components/TabSectionHeader"
import { isAutoTests } from "setup/global/config"
import PersonSectionButton from "../person-components/PersonSectionButton"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

type PersonDocumentProps = {
  isLoadingDocuments: boolean
}
export const PersonDocuments = React.memo(
  ({ isLoadingDocuments }: PersonDocumentProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null!)
    const { personId, documents, setDocuments, setPersonOverlayUpdated } =
      usePerson()
    const { createLocalPerson } = usePersonDataPoolLinkage()

    return (
      <EditableDetails
        noBottomSpace
        actions={(editableControls) => (
          <SectionHeader
            title={
              <TabSectionHeader
                label={messages.person.uploadDocument.title}
                count={documents.length}
              />
            }
            px="m"
            height="45px"
            actions={
              <PersonSectionButton
                disabled={isLoadingDocuments}
                label={messages.person.uploadDocument.title}
                onClick={async () => {
                  await editableControls.setEditMode()
                  if (Boolean(!isAutoTests())) {
                    buttonRef?.current?.click()
                  }
                }}
              />
            }
          />
        )}
        view={() => (
          <SectionBody>
            <Flex width="100%" alignItems="center" justifyContent="center">
              {isLoadingDocuments ? <Loader /> : <PersonDocumentList />}
            </Flex>
          </SectionBody>
        )}
        edit={(editableControls) => {
          const { setViewMode } = editableControls

          const onDocumentSubmit = getOnUploadPersonDocumentSubmit(
            personId,
            (document) => {
              setDocuments([
                ...documents,
                ...(Array.isArray(document) ? document : [document])
              ])
              setPersonOverlayUpdated(true)
              setViewMode()
            },
            createLocalPerson
          )

          const onFileDialogCancel = () => {
            setViewMode()
          }

          return (
            <>
              <SectionBody>
                <UploadPersonDocument
                  buttonRef={buttonRef}
                  onSubmit={onDocumentSubmit}
                  onFileDialogCancel={onFileDialogCancel}
                />
              </SectionBody>
              <SectionBody>
                <PersonDocumentList />
              </SectionBody>
            </>
          )
        }}
      />
    )
  }
)
