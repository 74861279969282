import React, { Dispatch, SetStateAction, useRef } from "react"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { Flex, SectionBody, SectionHeader } from "@ikiru/talentis-fpc"
import { EditableDetails } from "components/EditableDetails/EditableDetails"
import { isAutoTests } from "setup/global/config"
import TabSectionHeader from "views/persons/components/person-components/TabSectionHeader"
import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"
import { ProjectDocumentType } from "./types"
import { getOnUploadProjectDocumentSubmit } from "./form/upload-project-document.actions"
import { ProjectType } from "./form/constants/consts"
import { UploadProjectDocument } from "./form/UploadProjectDocument"
import { ProjectDocumentList } from "./components/ProjectDocumentsList"

export type ProjectDocumentProps = {
  projectId: string
  isLoadingDocuments: boolean
  documents: ProjectDocumentType
  setDocuments: Dispatch<SetStateAction<ProjectDocumentType>>
  projectType: ProjectType
}

export const ProjectDocuments = ({
  projectId,
  isLoadingDocuments,
  documents = [],
  setDocuments,
  projectType
}: ProjectDocumentProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null!)

  return (
    <EditableDetails
      noBottomSpace
      actions={(editableControls) => (
        <SectionHeader
          title={
            <TabSectionHeader
              label={messages.person.uploadDocument.title}
              count={documents.length}
            />
          }
          px="m"
          height="45px"
          actions={
            <PersonSectionButton
              disabled={isLoadingDocuments}
              label={messages.person.uploadDocument.title}
              onClick={async () => {
                await editableControls.setEditMode()
                if (Boolean(!isAutoTests())) {
                  buttonRef?.current?.click()
                }
              }}
            />
          }
        />
      )}
      view={() => (
        <SectionBody>
          <Flex width="100%" alignItems="center" justifyContent="center">
            {isLoadingDocuments ? (
              <Loader />
            ) : (
              <ProjectDocumentList
                documents={documents}
                projectId={projectId}
                projectType={projectType}
              />
            )}
          </Flex>
        </SectionBody>
      )}
      edit={(editableControls) => {
        const { setViewMode } = editableControls

        const onDocumentSubmit = getOnUploadProjectDocumentSubmit(
          projectId,
          (document) => {
            setDocuments([
              ...documents,
              ...(Array.isArray(document) ? document : [document])
            ])

            setViewMode()
          },
          () => {},
          projectType
        )

        const onFileDialogCancel = () => {
          setViewMode()
        }

        return (
          <>
            <SectionBody>
              <UploadProjectDocument
                buttonRef={buttonRef}
                onSubmit={onDocumentSubmit}
                onFileDialogCancel={onFileDialogCancel}
              />
            </SectionBody>
            <SectionBody>
              <ProjectDocumentList
                documents={documents}
                projectId={projectId}
                projectType={projectType}
              />
            </SectionBody>
          </>
        )
      }}
    />
  )
}
