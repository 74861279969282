import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "setup/auth/module/auth.context"
import {
  UserRolePermissionLevels,
  UserRoles
} from "setup/auth/module/constants/auth.types"
import { RouterUrl } from "./routes"

export const userRolePermissionRoute =
  (userRole: UserRoles) => (Component: () => JSX.Element) => {
    return () => {
      const { user } = useAuth()

      const hasPermission =
        UserRolePermissionLevels[user?.userRole as UserRoles] >=
        UserRolePermissionLevels[userRole]

      return hasPermission ? (
        <Component />
      ) : (
        <Navigate to={RouterUrl.NotPermission} />
      )
    }
  }
