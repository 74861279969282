import { apiRequest } from "setup/api/api"
import {
  PersonsEndpoints,
  AttributesEndpoints
} from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const values = [
  {
    title: "Industry",
    key: "0-0",
    id: "0-0",
    children: [
      {
        title: "Technology",
        key: "0-0-1",
        id: "0-0-1",
        children: [
          {
            title: "AI",
            key: "0-0-1-1",
            id: "0-0-1-1"
          },
          {
            title: "SaaS",
            key: "0-0-1-2",
            id: "0-0-1-2",
            children: [
              {
                title: "B2B",
                key: "0-0-1-2-1",
                id: "0-0-1-2-1"
              },
              {
                title: "B2C",
                key: "0-0-1-2-2",
                id: "0-0-1-2-2"
              }
            ]
          }
        ]
      },
      {
        title: "Financial Services",
        key: "0-0-2",
        id: "0-0-2"
      },
      {
        title: "Professional Services",
        key: "0-0-3",
        id: "0-0-3"
      }
    ]
  },
  {
    title: "Function",
    key: "0-1",
    id: "0-1",
    children: [
      {
        title: "Board",
        key: "0-1-1",
        id: "0-1-1",
        children: [
          {
            title: "Chair",
            key: "0-1-1-1",
            id: "0-1-1-1"
          },
          {
            title: "Senior NED",
            key: "0-1-1-2",
            id: "0-1-1-2"
          },
          {
            title: "NED",
            key: "0-1-1-3",
            id: "0-1-1-3"
          },
          {
            title: "Remco",
            key: "0-1-1-4",
            id: "0-1-1-4"
          },
          {
            title: "Audit Co",
            key: "0-1-1-5",
            id: "0-1-1-5"
          }
        ]
      },
      {
        title: "C-Level",
        key: "0-1-2",
        id: "0-1-2",
        children: [
          {
            title: "Chief Executive",
            key: "0-1-2-1",
            id: "0-1-2-1"
          },
          {
            title: "Chief Financial",
            key: "0-1-2-2",
            id: "0-1-2-2"
          },
          {
            title: "Chief Marketing",
            key: "0-1-2-3",
            id: "0-1-2-3"
          },
          {
            title: "Chief People",
            key: "0-1-2-4",
            id: "0-1-2-4"
          }
        ]
      },
      {
        title: "Manager",
        key: "0-1-3",
        id: "0-1-3"
      }
    ]
  },
  {
    title: "Qualifications",
    key: "0-2",
    id: "0-2",
    children: [
      {
        title: "Postgraduate degree",
        key: "0-2-1",
        id: "0-2-1",
        children: [
          {
            title: "Phd",
            key: "0-2-1-1",
            id: "0-2-1-1"
          }
        ]
      },
      {
        title: "Masters degree",
        key: "0-2-2",
        id: "0-2-2",
        children: [
          {
            title: "MSc",
            key: "0-2-2-1",
            id: "0-2-2-1"
          },
          {
            title: "MBA",
            key: "0-2-2-2",
            id: "0-2-2-2"
          },
          {
            title: "MA",
            key: "0-2-2-3",
            id: "0-2-2-3"
          }
        ]
      }
    ]
  },
  {
    title: "International Experience",
    key: "0-3",
    id: "0-3",
    children: [
      {
        title: "Europe",
        key: "0-3-1",
        id: "0-3-1",
        children: [
          {
            title: "UK",
            key: "0-3-1-1",
            id: "0-3-1-1"
          },
          {
            title: "Germany",
            key: "0-3-1-2",
            id: "0-3-1-2"
          },
          {
            title: "France",
            key: "0-3-1-3",
            id: "0-3-1-3"
          }
        ]
      },
      {
        title: "EMEA",
        key: "0-3-2",
        id: "0-3-2"
      },
      {
        title: "Asia Pacific",
        key: "0-3-3",
        id: "0-3-3",
        children: [
          {
            title: "Singapore",
            key: "0-3-3-1",
            id: "0-3-3-1"
          },
          {
            title: "Hong Kong",
            key: "0-3-3-2",
            id: "0-3-3-2"
          },
          {
            title: "Australia",
            key: "0-3-3-3",
            id: "0-3-3-3"
          }
        ]
      },
      {
        title: "US",
        key: "0-3-4",
        id: "0-3-4"
      },
      {
        title: "Canada",
        key: "0-3-5",
        id: "0-3-5"
      }
    ]
  },
  {
    title: "Languages",
    key: "0-4",
    id: "0-4",
    children: [
      {
        title: "English",
        key: "0-4-1",
        id: "0-4-1",
        children: [
          {
            title: "English Fluent",
            key: "0-4-1-1",
            id: "0-4-1-1"
          },
          {
            title: "English Working",
            key: "0-4-1-2",
            id: "0-4-1-2"
          }
        ]
      },
      {
        title: "Spanish",
        key: "0-4-2",
        id: "0-4-2",
        children: [
          {
            title: "Spanish Fluent",
            key: "0-4-2-1",
            id: "0-4-2-1"
          },
          {
            title: "Spanish Working",
            key: "0-4-2-2",
            id: "0-4-2-2"
          }
        ]
      }
    ]
  },
  {
    title: "Custom",
    key: "0-5",
    id: "0-5",
    children: [
      {
        title: "Whatever",
        key: "0-5-1",
        id: "0-5-1"
      },
      {
        title: "You",
        key: "0-5-2",
        id: "0-5-2"
      },
      {
        title: "Want",
        key: "0-5-3",
        id: "0-5-3"
      }
    ]
  }
]

export const assignAttributes = async (personId: string, ids: string[]) => {
  const [, response] = await apiRequest.patch({
    endpoint: PersonsEndpoints.PersonAttributes,
    data: { ids },
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getAllAttributes = async () => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.Root,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return values //response?.data
}
