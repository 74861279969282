import styled from "styled-components"
import styledCss from "@styled-system/css"
import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"
import { Div } from "@ikiru/talentis-fpc"

type ModalContainerProps = {
  defaultWidth?: number
}

export const ModalFormContainer = styled.div<ModalContainerProps>`
  ${styledCss(tempInnerGreyModalStyles)}
  width: 320px;
  background: ${({ theme }) => theme.colors.grey.lightest};

  ${({ theme, defaultWidth = 520 }) => `
  ${theme.mediaQueries.xs} {
    width: ${defaultWidth}px;
  }
`};
`
export const StyledDiv = styled(Div)`
  width: 50%;
  padding: ${({ theme }) => theme.space.xxs}px;
`
