import React from "react"
import { Flex } from "@ikiru/talentis-fpc"

export const ModifyCompanyDetails = () => {
  return (
    <Flex width="100%" flexWrap="wrap" flexGrow={1}>
      Coming soon
    </Flex>
  )
}
