import React, { useState } from "react"
import { Avatar } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { DropzoneProps } from "components/functional/dropzone/dropzone.definitions"
import { Loader } from "components/Loader/Loader"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { FormStates } from "utils/forms/form-state"
import { personAvatarE2ETargets } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { OnAvatarDeleteType } from "views/persons/components/person-avatar/person-avatar.actions"
import { ManagePersonAvatar } from "views/persons/components/person-avatar/components/manage-person-avatar/ManagePersonAvatar"

type ManagePersonAvatarWrapperProps = DropzoneProps & {
  onAvatarDelete: OnAvatarDeleteType
}

export const ManagePersonAvatarWrapper = (
  props: ManagePersonAvatarWrapperProps
) => {
  const {
    value,
    getRootProps,
    formState,
    isSubmitting,
    photo,
    name = "",
    onAvatarDelete: onAvatarDeleteFromProps
  } = props

  const rootProps = getRootProps()
  const { onClick: openFileExplorer } = rootProps

  const [isDeleting, setIsDeliting] = useState(false)

  const onAvatarDelete = () => {
    setIsDeliting(true)
    onAvatarDeleteFromProps()
  }

  return (
    <Flex
      mb="s"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {formState !== FormStates.Pending ? (
        <Div {...rootProps} mb="s" width={200} height={200}>
          <Avatar
            data-e2e-target-name={personAvatarE2ETargets.dropzoneContainer}
            photo={photo}
            name={name}
            value={value}
            cursor="pointer"
            onClick={openFileExplorer}
          />
        </Div>
      ) : (
        <>
          <Loader />
          <p>{messages.person.avatar.photoUploadingPleaseWait}</p>
        </>
      )}

      <ManagePersonAvatar
        hasSavedPhoto={Boolean(photo?.url)}
        hasAvatarPreview={Boolean(value)}
        onAvatarDelete={onAvatarDelete}
        actionsPending={isSubmitting || isDeleting}
        onUploadButtonClick={openFileExplorer}
      />
    </Flex>
  )
}
