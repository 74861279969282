import React, { useEffect } from "react"
import { useAuth } from "setup/auth/module/auth.context"

export const authenticatedRoute = (Component: () => JSX.Element) => {
  return () => {
    const { isLoggedIn, login } = useAuth()

    useEffect(() => {
      !isLoggedIn && login!()
    }, [isLoggedIn, login])

    return isLoggedIn ? <Component /> : null
  }
}
