import React, { useState, useCallback, useMemo } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { BodyText, SmallText } from "@ikiru/talentis-fpc"
import {
  TeamMember as TeamMemberType,
  TeamMemberStatus
} from "views/team/team/team-module.types"
import { Select } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useTeam } from "views/team/team/team-module.context"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { colors, spacing } from "@ikiru/talentis-fpc"
import { ThreeDotsIcon } from "@ikiru/talentis-fpc"
import { Tag } from "@ikiru/talentis-fpc"
import { TickIcon } from "@ikiru/talentis-fpc"
import { OverlayLoader } from "components/Loader/OverlayLoader"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"

type TeamMemberProps = TeamMemberType & {
  currentUser: boolean
}

export const TeamMember = React.memo(
  ({
    id,
    firstName,
    lastName,
    emailAddress,
    isDisabled,
    userRole,
    currentUser,
    newInvite,
    status
  }: TeamMemberProps) => {
    const {
      amIOwner,
      possibleRoles,
      activeOwnersCount,
      invitationsResent,
      selectedTeamMember,
      toggleActive,
      onMemberRoleChange,
      onResendInvitationLink,
      onRemoveInvitationLink
    } = useTeam()
    const [isPopupOpen, setPopupOpen] = useState(false)
    const { open, close } = useModal()

    const onSelectChange = useCallback(
      (event: any) => {
        const { value } = event?.target || {}
        onMemberRoleChange(id, value)
      },
      [id, onMemberRoleChange]
    )

    const isInvited = useMemo(
      () => status === TeamMemberStatus.Invited,
      [status]
    )

    const handleCallbackWithCloseModal = useCallback(
      (callback?: any) => () => {
        callback?.()
        close(ModalNames.RemoveInvite)
      },
      [close]
    )

    const openModal = useCallback(
      (id: string) => {
        open(
          ModalNames.RemoveInvite,
          <ConfirmationModal
            title={messages.manageTeam.revokeModal.title}
            cancelButtonLabel={messages.manageTeam.revokeModal.cancelLabel}
            confirmButtonLabel={messages.manageTeam.revokeModal.okLabel}
            onClose={handleCallbackWithCloseModal()}
            onCancel={handleCallbackWithCloseModal()}
            onConfirm={handleCallbackWithCloseModal(() => {
              onRemoveInvitationLink(id)
            })}
          />
        )
      },
      [handleCallbackWithCloseModal, onRemoveInvitationLink, open]
    )

    const options = useMemo(
      () =>
        isInvited
          ? [
              {
                label: messages.manageTeam.resendInvite,
                onClick: () => onResendInvitationLink(id)
              },
              {
                label: messages.manageTeam.removeInvite,
                onClick: () => openModal(id)
              }
            ]
          : currentUser
          ? []
          : ([
              (activeOwnersCount > 1 || userRole !== UserRoles.Owner) &&
              !isDisabled
                ? {
                    label: messages.manageTeam.makeInactive,
                    onClick: () => toggleActive(id, false)
                  }
                : undefined,
              isDisabled
                ? {
                    label: messages.manageTeam.makeActive,
                    onClick: () => toggleActive(id, true)
                  }
                : undefined
            ].filter(Boolean) as HeaderOption[]),
      [
        id,
        userRole,
        isInvited,
        isDisabled,
        currentUser,
        activeOwnersCount,
        onResendInvitationLink,
        toggleActive,
        openModal
      ]
    )

    return (
      <Flex
        width={[1]}
        px="xs"
        py="s"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        position="relative"
      >
        <OverlayLoader isLoading={selectedTeamMember === id} />
        <Flex width={[1, 1, 0.6]} alignItems="center">
          {!!invitationsResent[id] && !newInvite && (
            <Tag my="none" mr="xs" variant="green">
              <TickIcon
                fill="currentColor"
                style={{ marginRight: spacing.xxs }}
              />
              {messages.manageTeam.inviteResent}
            </Tag>
          )}
          {newInvite && (
            <Tag my="none" mr="xs" variant="green">
              <TickIcon
                fill="currentColor"
                style={{ marginRight: spacing.xxs }}
              />
              {messages.manageTeam.inviteSent}
            </Tag>
          )}
          <BodyText fontWeight={600} width={[1, 1, 0.5]} my="none">
            {firstName || lastName
              ? `${firstName || ""} ${lastName || ""}`
              : emailAddress}{" "}
            {currentUser && "(you)"}
          </BodyText>
          {(firstName || lastName) && (
            <SmallText my="none">{emailAddress}</SmallText>
          )}
        </Flex>
        <Flex>
          <Div width="125px">
            <Select
              variant="small"
              value={userRole}
              disabled={userRole === UserRoles.Owner && !amIOwner}
              status="valid"
              onChange={onSelectChange}
              options={possibleRoles}
            ></Select>
          </Div>
          {options.length > 0 ? (
            <OptionsPopup
              alignSelf="center"
              display="flex"
              ml="s"
              config={{
                PopupProps: {
                  position: "bottom right",
                  onOpen: () => setPopupOpen(true),
                  onClose: () => setPopupOpen(false)
                }
              }}
              trigger={
                <Flex
                  p={3}
                  bg={isPopupOpen ? colors.grey.light : colors.transparent}
                  borderRadius={spacing.xxs}
                  style={{ cursor: "pointer" }}
                >
                  <ThreeDotsIcon fill={colors.grey.dark} height={24} />
                </Flex>
              }
              options={options}
            />
          ) : (
            <Div width={25}></Div>
          )}
        </Flex>
      </Flex>
    )
  }
)
