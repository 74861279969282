import styled from "styled-components"

export const FAQWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  grid-gap: ${({ theme }) => theme.space.s}px;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 ${theme.space.xxl}px;
    }
  `}
`

export const FAQItem = styled.div`
  flex-direction: column;
`
