import React, { useMemo } from "react"
import { BodyText, Div, H5, SmallText, Tag, colors } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { OverviewRow } from "./OverviewRow"
import { List } from "../../person-details/components/style"
import { getUpdatedKeywords } from "../parsing/helper"
import {
  getEndDate,
  getStartDate
} from "../parsing/components/DragAndDropItems/utils"
import moment from "moment"

export const OverviewSkills = () => {
  const { cvParsing } = usePerson()

  const filteredKeywordsValues = useMemo(
    () => getUpdatedKeywords(cvParsing.skills),
    [cvParsing.skills]
  )

  const filteredEducationValues = useMemo(() => {
    if (cvParsing.skills) {
      const data = cvParsing.skills.parsedEducation.filter((educ) => {
        return educ.isMovedToExisting
      })
      return data
    }
    return []
  }, [cvParsing.skills])

  return (
    <Div>
      <Div>
        <H5 width="137px" m="none" p="xxs">
          {messages.person.cvparsing.keywords}
        </H5>
        <OverviewRow bottomBorder>
          <List style={{ paddingLeft: "10px", paddingTop: "5px" }}>
            {Boolean(filteredKeywordsValues.length) ? (
              filteredKeywordsValues.map((keyword) => {
                return (
                  <Tag
                    key={JSON.stringify(keyword)}
                    as="li"
                    mr="xxs"
                    variant={keyword.isDeleted ? "red" : "grey"}
                    disabled
                    isLineThrough={keyword.isDeleted}
                  >
                    {keyword.value}
                  </Tag>
                )
              })
            ) : (
              <BodyText pl="xxs">
                {messages.person.cvparsing.noChanges}
              </BodyText>
            )}
          </List>
        </OverviewRow>
      </Div>
      <Div>
        <H5 width="137px" m="none" p="xxs">
          {messages.person.cvparsing.education}
        </H5>
        {Boolean(filteredEducationValues.length) ? (
          filteredEducationValues.map((educ) => {
            return (
              <OverviewRow bottomBorder key={educ.id}>
                <Div pl="xs" py="xxs">
                  <H5 m="0">{educ.value}</H5>
                  {educ.institute?.name && (
                    <H5 m="0" fontSize="13px" color={colors.grey.dark}>
                      {educ.institute.name}
                    </H5>
                  )}
                  <SmallText m="0" mt="xs" color={colors.grey.dark}>
                    {educ.startDate &&
                      `${getStartDate(educ.startDate)} - ${getEndDate(
                        educ.endDate,
                        educ.startDate
                      )}`}
                    {!educ.startDate &&
                      educ.endDate &&
                      `${moment(educ.endDate).format("YYYY")}`}
                  </SmallText>
                </Div>
              </OverviewRow>
            )
          })
        ) : (
          <BodyText pl="xxs">{messages.person.cvparsing.noChanges}</BodyText>
        )}
      </Div>
    </Div>
  )
}
