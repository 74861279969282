import React from "react"
import { BodyText, colors, Div, Flex, Toggle } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { User } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import {
  StyledAddress,
  StyledName,
  StyledOptions,
  StyledWrapper
} from "./style"

type PersonTeamProps = {
  subscribeUsers: User[]
  changeSubscribeUsers: (id: string) => void
}

export const PersonTeam = ({
  subscribeUsers,
  changeSubscribeUsers
}: PersonTeamProps) => {
  const options = [
    ...subscribeUsers.map((teamMember: User) => {
      return (
        <Flex
          justifyContent="space-between"
          my="xxs"
          width="100%"
          alignItems="baseline"
        >
          <StyledName color={colors.grey.dark}>
            {teamMember.status === "invited"
              ? messages.subscription.purchase.pendingInvite
              : `${teamMember.firstName} ${teamMember.lastName}`}
          </StyledName>
          <StyledAddress>{teamMember.emailAddress}</StyledAddress>
          <Div>
            <Toggle
              type="checkbox"
              hideUnderline
              checked={teamMember.isActive}
              onChange={() => changeSubscribeUsers(teamMember.id)}
              small
            />
          </Div>
        </Flex>
      )
    })
  ]

  return (
    <StyledWrapper>
      <BodyText my="xs" p="0" color={colors.grey.dark}>
        {messages.subscription.removeUserPartOne}
        <br /> {messages.subscription.removeUserPartTwo}
      </BodyText>
      <StyledOptions>{options}</StyledOptions>
    </StyledWrapper>
  )
}
