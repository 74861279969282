import React, { useEffect, useCallback, useState, useMemo, useRef } from "react"
import {
  Flex,
  H1,
  Badge,
  Div,
  H5,
  PlusIcon,
  colors,
  SectionBody,
  LargeText
} from "@ikiru/talentis-fpc"
import { getAllCampaigns, pageSize, getAllSearchCampaigns } from "./actions"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { RouterLink } from "components/RouterLink/RouterLink"
import { CampaignRecord } from "./components/CampaignRecord"
import {
  CampaignRecord as CampaignRecordType,
  CampaignsTabs,
  CampaignStatuses
} from "./type"
import CampaignTab from "./components/CampaignTab"
import { Pagination } from "components/functional/pagination/Pagination"
import { tabsFormer, getTotalCount, tabsFormerSearch } from "./helpers"
import { ProjectSearch } from "components/ProjectActions/ProjectSearch/ProjectSearch"
import { TabsContainer, CampaignTabContainer } from "./components/styles"
import ScrollButton from "components/HorizontalScroll/ScrollButton"
import useHorizantialScroll from "utils/hooks/use-horizantial-scroll"
import ActionButton from "components/ProjectActions/ActionButton"

export const ListCampaign = () => {
  const [campaigns, setCampaigns] = useState<Array<CampaignRecordType>>([])
  const [allCampaignsTabs, setAllCampaignsTabs] = useState<
    Array<CampaignsTabs>
  >([])
  const [campaignsTabs, setCampaignsTabs] = useState<Array<CampaignsTabs>>([])
  const [selectedStatus, setSelectedStatus] = useState<string>("")
  const [totallSelectedCount, setTotalSelectedCount] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [findCampaignsName, setFindCampaignsName] = useState<string[]>([])
  const [searchName, setSearchName] = useState<string>("")

  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollLeft, isOverflow, disableNext, disablePrev } =
    useHorizantialScroll({ scrollElement, scrollUpAmmount: 100 })

  const pageCount = useMemo(() => {
    return Math.ceil(totallSelectedCount / pageSize)
  }, [totallSelectedCount])

  const fetchCampaigns = useCallback(async () => {
    const { campaigns, campaignStatuses } = await getAllCampaigns()

    if (campaigns.length > 0) {
      const tempTotalCount = getTotalCount(campaignStatuses)
      setTotalCount(tempTotalCount)
      let tempStatus =
        [
          campaignStatuses.find(
            (status: CampaignStatuses) => status.data === "active"
          )
        ] || []
      tempStatus =
        tempStatus.concat(
          campaignStatuses.filter(
            (status: CampaignStatuses) => status.data !== "active"
          )
        ) || []

      setAllCampaignsTabs(tabsFormer(tempStatus))

      if (
        campaignStatuses.length > 1 &&
        campaignStatuses.find(
          (campaignStatus: CampaignStatuses) => campaignStatus.data === "active"
        )
      ) {
        setSelectedStatus("active")
      } else {
        setIsLoading(false)
        setTotalSelectedCount(tempTotalCount)
        setCampaignsTabs(tabsFormer(campaignStatuses))
        setCampaigns(campaigns)
      }
    } else {
      setCampaignsTabs(tabsFormer([]))
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchCampaigns()
  }, [fetchCampaigns])

  const getTempTotalCount = useCallback(
    (campaignStatuses: any) => {
      if (selectedStatus !== "all" && selectedStatus !== "") {
        return campaignStatuses.find(
          (campaignStatus: CampaignStatuses) =>
            campaignStatus.data === selectedStatus
        )?.count
      } else {
        return getTotalCount(campaignStatuses)
      }
    },
    [selectedStatus]
  )

  useEffect(() => {
    if (totalCount === 0) return

    let isCampaignLoading = true

    const fetchCampaignsTabs = async () => {
      const { campaigns, campaignStatuses } = await getAllCampaigns(
        1,
        selectedStatus,
        searchName
      )

      if (isCampaignLoading) {
        setCampaigns(campaigns)
        setTotalSelectedCount(getTempTotalCount(campaignStatuses))
        setCampaignsTabs(
          tabsFormerSearch(campaignStatuses, selectedStatus, allCampaignsTabs)
        )
        setIsLoading(false)
      }
    }

    fetchCampaignsTabs()

    return () => {
      isCampaignLoading = false
    }
  }, [
    selectedStatus,
    searchName,
    getTempTotalCount,
    totalCount,
    allCampaignsTabs
  ])

  const pageChanged = useCallback(
    async (numberPage: any) => {
      setIsLoading(true)
      const { campaigns } = await getAllCampaigns(
        numberPage,
        selectedStatus,
        searchName
      )
      setIsLoading(false)
      setPageNumber(numberPage)
      setCampaigns(campaigns)
    },
    [selectedStatus, searchName]
  )

  const onChangeSearch = useCallback(
    async (searchInput: any) => {
      if (!searchInput) return
      const { campaignNames } = await getAllSearchCampaigns(
        searchInput,
        selectedStatus
      )
      setFindCampaignsName(campaignNames)
    },
    [selectedStatus]
  )

  const selectedCampaign = useCallback(
    async (campaignName: any) => {
      if (searchName === campaignName) return
      if (totalCount === 0) return
      setSearchName(campaignName)
      setIsLoading(true)
    },
    [searchName, totalCount]
  )

  const tabOnClick = useCallback(
    async (status: any) => {
      const tempStatus = status.toLowerCase()
      if (selectedStatus === tempStatus) return
      setSelectedStatus(tempStatus)
      if (totalCount === 0) return
      setIsLoading(true)
      setPageNumber(1)
    },
    [selectedStatus, totalCount]
  )

  return (
    <Flex flexDirection="column">
      <Flex mb="s" width="100%" justifyContent="space-between">
        <Flex alignItems="center">
          <H1 color="green.standard" my="none" mr="xxs">
            {messages.seo.campaigns}
          </H1>
          {totalCount > 0 && (
            <Badge
              bg="grey.standard"
              color="white.standard"
              height={24}
              mb="xxs"
            >
              <H5 color="white.standard" my="none">
                {totalCount}
              </H5>
            </Badge>
          )}
        </Flex>
        <Div display={["none", "none", "block"]}>
          <RouterLink to={RouterUrl.CampaignCreate}>
            <ActionButton
              type="button"
              mode="standard-grey-dark"
              prefix={
                <PlusIcon width={12} height={12} fill={colors.white.standard} />
              }
              size="small"
              label={messages.campaign.title}
            />
          </RouterLink>
        </Div>
      </Flex>
      <Flex width="100%" flexDirection="column">
        <ProjectSearch
          projectNames={findCampaignsName}
          onChanged={onChangeSearch}
          onItemClicked={selectedCampaign}
          buttonTitle={messages.campaign.searchCampaign}
          searchInput={messages.campaign.searchInput}
        />
        <Flex>
          <Div mt="-2px">
            <ScrollButton
              onClick={() => scrollLeft(false)}
              disabled={!isOverflow || disablePrev}
            />
          </Div>
          <TabsContainer
            ref={scrollElement}
            numberColumns={campaignsTabs.length}
          >
            {campaignsTabs.map((campaignTab: CampaignsTabs) => (
              <CampaignTab
                {...campaignTab}
                key={campaignTab.label}
                onClick={tabOnClick}
              />
            ))}
          </TabsContainer>
          {!isOverflow && Boolean(campaignsTabs.length) && (
            <CampaignTabContainer isSelected={false} flexGrow={1} />
          )}
          <Div mt="-2px">
            <ScrollButton
              isNext
              onClick={scrollLeft}
              disabled={!isOverflow || disableNext}
            />
          </Div>
        </Flex>
        {isLoading ? (
          <Flex mt="s">
            <Loader />
          </Flex>
        ) : (
          <SectionBody>
            {!campaigns.length && (
              <LargeText color="grey.standard" p="15px" pt="none">
                {messages.campaign.noResults}
              </LargeText>
            )}
            {campaigns.map((campaign: CampaignRecordType) => (
              <CampaignRecord {...campaign} key={campaign.id} />
            ))}
            {pageCount > 1 && (
              <Flex
                width="100%"
                flexDirection="row"
                justifyContent="center"
                mb="s"
              >
                <Pagination
                  totalItemCount={totallSelectedCount}
                  pageSize={pageSize}
                  pageNumber={pageNumber || 1}
                  hasNextPage={pageNumber !== pageCount}
                  hasPreviousPage={pageNumber !== 1}
                  pageCount={pageCount}
                  maxPages={10}
                  onPageChange={(numberPage) => pageChanged(numberPage)}
                />
              </Flex>
            )}
          </SectionBody>
        )}
      </Flex>
    </Flex>
  )
}
