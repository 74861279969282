import styled from "styled-components"
import {
  Button,
  fontFamilies,
  Flex,
  StyledSystemElement,
  spacing
} from "@ikiru/talentis-fpc"

export const NoteContainer = styled.div<{ noPadding?: boolean }>`
  padding-top: ${({ noPadding }) => (noPadding ? 0 : spacing.xs)}px;
`

export const NoteFlexContainer = styled(Flex)<
  StyledSystemElement<HTMLDivElement>
>`
  H4,
  span,
  p {
    font-family: ${fontFamilies.sourceSans};
    color: ${({ theme }) => theme.colors.grey.dark};
    margin-bottom: 7px;
  }
  H4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 4px;
  }
  span {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0;
  }
  p ~ svg {
    min-width: 12px;
    max-width: 12px;
    max-height: 12px;
    margin-left: 6px;
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.grey.dark};
  }
`

type ButtonProps = {
  floatRight?: boolean
}

export const AddNoteButtonWrapper = styled(Button)<ButtonProps>`
  float: ${({ floatRight }) => (floatRight ? "right" : "left")};
  white-space: nowrap;
`

export const NoteButton = styled(Button)`
  height: 25px !important;
  width: 25px !important;
  margin-top: -2px;
`

export const NoteButtonAbsolute = styled(NoteButton)`
  flex-direction: column-reverse;
  position: absolute;
  right: ${spacing.xs}px;
`
