import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { CreatePersonField } from "views/persons/components/person-details-tab/constant/definitions"

export const personContactsValidationSchema = Yup.object({
  [CreatePersonField.EmailAddresses]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        email: Yup.string()
          .max(255, messages.form.generic.errors.mustBeNoLongerThan.format(255))
          .email(messages.person.details.errors.invalidEmail),
        smtpValid: Yup.string().nullable()
      })
    })
  ),
  [CreatePersonField.PhoneNumbers]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        phoneNumber: Yup.string().max(
          27,
          messages.form.generic.errors.mustBeNoLongerThan.format(27)
        )
      })
    })
  )
})
