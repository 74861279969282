import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { Div } from "@ikiru/talentis-fpc"
import { linkedInValidationSchema } from "./constants/schema"
import {
  LinkedInUrlField,
  OnCompanyLinkSubmitType
} from "./constants/definitions"

type LinkCompanyFormProps = {
  initialValues?: {}
  onSubmit: OnCompanyLinkSubmitType
  formActionsComponent: (
    formikProps: FormikProps<{
      [LinkedInUrlField.LinkedInUrl]: { value: string }
    }>
  ) => JSX.Element
}

export const LinkedInForm = (props: LinkCompanyFormProps) => {
  const { initialValues, onSubmit, formActionsComponent } = props

  const submitForm = (
    values: { [LinkedInUrlField.LinkedInUrl]: { value: string } },
    action: FormikHelpers<{ [LinkedInUrlField.LinkedInUrl]: { value: string } }>
  ) => {
    onSubmit(values, action)
  }

  return (
    <Formik
      initialValues={{
        ...{ [LinkedInUrlField.LinkedInUrl]: { value: "" } },
        ...initialValues
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={linkedInValidationSchema}
      onSubmit={submitForm}
    >
      {(formik) => {
        return (
          <Form>
            <Div style={{ maxWidth: "617px" }}>
              <FormikInput
                id={LinkedInUrlField.LinkedInUrl}
                name={`${LinkedInUrlField.LinkedInUrl}.value`}
                label={messages.form.generic.linkedInUrl}
              />
            </Div>
            {formActionsComponent(formik)}
          </Form>
        )
      }}
    </Formik>
  )
}
