import React from "react"
import { useSearch } from "views/search/SearchModule/context"
import { useMultiBoxes } from "../components/hooks"
import { useNestedArrayJobTitleFieldChange } from "../hooks"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Flex, Button, CrossIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { JOB_TITLE_KEYWORDS_LIMIT } from "views/search/SearchModule/consts"
import { FilterType } from "views/search/SearchModule/types"
import { SearchCriteriaField } from "../definitions"
import { CurrentPastBothToggle } from "../components/CurrentPastBothToggle"
import {
  jobTitlesTemplateString,
  initialTemplateString,
  jobTitlesRolesLogicTemplateString
} from "./consts"
import get from "lodash/get"

type JobTitlesBoxProps = {
  index: number
  boxesCount: number
}

export const JobTitlesBox = ({ index, boxesCount }: JobTitlesBoxProps) => {
  const { filters, removeArrayBasedFilter, clearArrayBasedFilter } = useSearch()
  const { removeBox } = useMultiBoxes(
    SearchCriteriaField.JobTitleBundle,
    FilterType.JobTitleBundle
  )

  const filterName = jobTitlesTemplateString.format(index)
  const initialFilterName = initialTemplateString.format(index)
  const fieldName = SearchCriteriaField.JobTitle.format(index)
  const fieldRolesName = SearchCriteriaField.JobTitleLogic.format(index)
  const filterRolesName = jobTitlesRolesLogicTemplateString.format(index)
  const currentJobTitle = get(filters, filterName, [])

  const propsFiled = {
    field: fieldName,
    filterName,
    limit: JOB_TITLE_KEYWORDS_LIMIT,
    index
  }

  const onBlur = useNestedArrayJobTitleFieldChange({
    ...propsFiled,
    isOnBlur: true
  })

  const onKeyPress = useNestedArrayJobTitleFieldChange({ ...propsFiled })

  const CloseButton = () => {
    return (
      <Button
        mode="standard-white"
        size="action-small"
        onClick={() => {
          clearArrayBasedFilter(initialFilterName)
          removeBox(index)
        }}
      >
        <CrossIcon />
      </Button>
    )
  }

  return (
    <Flex flexDirection="column" width="100%">
      {boxesCount > 1 && (
        <Flex pb="xxs" justifyContent="flex-end">
          <CloseButton />
        </Flex>
      )}
      <FormikInput
        name={fieldName}
        label={messages.talentGraphSearch.filters.jobTitle}
        placeholder={messages.talentGraphSearch.placeholders.jobTitle}
        disableDefaultOnBlur
        autoFocus={index !== 0}
        {...{
          onKeyPress: onKeyPress as (
            e: React.KeyboardEvent<HTMLInputElement>
          ) => void,
          onBlur: onBlur as (e: React.FocusEvent<HTMLInputElement>) => void
        }}
      />
      <KeywordsList
        keywords={currentJobTitle}
        onKeywordRemove={(value) => removeArrayBasedFilter(filterName, value)}
      />
      <CurrentPastBothToggle
        fieldName={fieldRolesName}
        filterType={filterRolesName}
      />
    </Flex>
  )
}
