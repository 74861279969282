import React, { useMemo, useCallback } from "react"
import { InputProps } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { Div } from "@ikiru/talentis-fpc"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"
import {
  Multiselect,
  MultiselectProps
} from "components/Multiselect/Multiselect"
import { MultiValue, OnChangeValue } from "react-select"

const e2eTarget = "form-multiselect"

export type FormikMultiselectOptionType<T = unknown> = {
  label: string
  value: T
  "data-e2e-target"?: string
}

type FormikInputProps = MultiselectProps<FormikMultiselectOptionType> & {
  label?: React.ReactChild
  name: Pick<InputProps, "name">
  controlled?: boolean
}

export const FormikMultiselect = React.forwardRef(
  (props: FormikInputProps, ref) => {
    const {
      label,
      name,
      onBlur: explicitOnBlur,
      onChange: explicitOnChange,
      defaultValue,
      controlled = false,
      ...otherExplicitProps
    } = props
    const [formikProps, meta, helpers] = useField(name as string)

    const { onBlur: formikDefaultOnBlur, ...otherFormikProps } = formikProps

    const value = controlled ? props.value : formikProps.value

    const status = useStatus(meta)
    const shouldDisplayError = useShouldDisplayError(meta)

    const onChange = useCallback(
      (value: OnChangeValue<FormikMultiselectOptionType, true>) => {
        explicitOnChange?.(value)

        helpers.setValue(value)
        helpers.setTouched(true)
      },
      [explicitOnChange, helpers]
    )

    const normalizedValue = useMemo(
      () => (Array.isArray(value) && value.length ? value : null),
      [value]
    )

    return (
      <Div>
        <Multiselect
          ref={ref}
          label={label}
          data-e2e-target={e2eTarget}
          status={status}
          onBlur={(event) => {
            formikDefaultOnBlur(event)
            explicitOnBlur?.(event)
          }}
          {...otherFormikProps}
          {...otherExplicitProps}
          value={normalizedValue as MultiValue<unknown>}
          defaultValue={defaultValue}
          onChange={onChange}
        />
        {shouldDisplayError && <Error>{meta.error}</Error>}
      </Div>
    )
  }
)
