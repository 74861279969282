import { InputProps } from "@ikiru/talentis-fpc"
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone"
import { FieldInputProps } from "formik"
import { MutableRefObject, ReactElement } from "react"

type DropzoneConfig = {
  multiple?: boolean
  noDrag?: boolean
}

export type FormikInputProps = InputProps & {
  label?: React.ReactChild
  config?: DropzoneConfig
  innerRef?: MutableRefObject<HTMLButtonElement>
  onFileDialogCancel?: () => void
  renderDropzoneComponent: (
    props: DropzoneInputProps & DropzoneRootProps & FieldInputProps<any>
  ) => ReactElement
  ["data-e2e-target-name"]?: string
}

export const dropzoneE2ETarget = "dropzone-input"
