import React from "react"
import { H4, SmallText, LittleText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Flex } from "@ikiru/talentis-fpc"

export const GeneralErrorAlert = () => {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p="m"
    >
      <H4 mt="none" mb="s" color="orange.darkest">
        {messages.generic.generalError.title}
      </H4>
      <SmallText mt="none" mb="s" color="grey.dark">
        {messages.generic.generalError.subTitle}
      </SmallText>
      <LittleText mt="none" mb="s" color="grey.dark">
        {messages.generic.generalError.text}
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a href="#">{messages.generic.contactSupport}</a>
      </LittleText>
    </Flex>
  )
}
