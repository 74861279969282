import React from "react"
import ClientPortalConfiguration from "."
import ClientPortalConfigurationModule from "./module"

const ClientPortalConfigurationWrapper = () => (
  <ClientPortalConfigurationModule>
    <ClientPortalConfiguration />
  </ClientPortalConfigurationModule>
)

export default React.memo(ClientPortalConfigurationWrapper)
