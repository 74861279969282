import React, { useCallback, useState } from "react"
import { Formik } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { Flex, H2, CrossIcon, Button, Div } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  SaveSearchForm,
  SaveSearchFormFields,
  saveSearchFormValidationSchema,
  initialValues
} from "./SaveSearchModal.definitions"
import { SaveSearchModalWrapper, SaveForm } from "./style"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"

interface SaveSearchModalProps {
  onSubmit: (values: SaveSearchForm) => void
}

export const SaveSearchModal = ({ onSubmit }: SaveSearchModalProps) => {
  const { close } = useModal()
  let [isDefaultSearch, setIsDefaultSearch] = useState(false)

  const onFormSubmit = useCallback(
    (data: SaveSearchForm) => {
      close(ModalNames.SaveSearch)
      onSubmit(data)
    },
    [close, onSubmit]
  )

  const formikContextValue = {
    initialValues,
    validationSchema: saveSearchFormValidationSchema,
    onSubmit: onFormSubmit,
    isInitialValid: false
  }

  return (
    <SaveSearchModalWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <H2 color="grey.dark" pl="m">
          {messages.talentGraphSearch.savedSearch.saveSearch}
        </H2>
        <Button
          onClick={() => close(ModalNames.SaveSearch)}
          mode="standard-white"
          size="action-medium"
        >
          <CrossIcon fill="grey.dark" />
        </Button>
      </Flex>
      <Flex p="m" flexDirection="row" textAlign="center">
        <Formik {...formikContextValue}>
          {({ isValid }) => (
            <SaveForm>
              <FormikInput
                autoFocus
                id={SaveSearchFormFields.SearchName}
                name={SaveSearchFormFields.SearchName}
                label="Enter search name"
              />
              <FormikTextarea
                style={{ marginTop: "5px" }}
                rows={2}
                id={SaveSearchFormFields.SearchDescription}
                name={SaveSearchFormFields.SearchDescription}
                label="Description"
                resize="none"
              />
              <Div mt="m">
                <FormikToggle
                  small
                  type="checkbox"
                  hideUnderline
                  notCheckedFade
                  name={SaveSearchFormFields.DefaultSearch}
                  labelProps={{ ml: "xxs" }}
                  onClick={(el) => {
                    setIsDefaultSearch(!isDefaultSearch)
                  }}
                  checked={isDefaultSearch}
                  label={
                    messages.talentGraphSearch.savedSearch.defaultSearchScreen
                  }
                />
              </Div>
              <Button disabled={!isValid} mx="auto" my="m" type="submit">
                {messages.form.generic.save}
              </Button>
            </SaveForm>
          )}
        </Formik>
      </Flex>
    </SaveSearchModalWrapper>
  )
}
