import React from "react"
import {
  Link as RouterBareLink,
  LinkProps as RouterLinkProps
} from "react-router-dom"
import { Link as FPCLink, LinkProps } from "@ikiru/talentis-fpc"
import { ButtonProps, Button as FPCButton } from "@ikiru/talentis-fpc"

export const RouterLink = (props: LinkProps & Pick<RouterLinkProps, "to">) => (
  <FPCLink {...props} as={RouterBareLink as any} />
)

export const RouterButton = (
  props: ButtonProps & Pick<RouterLinkProps, "to">
) => <FPCButton {...props} as={RouterBareLink as any} />
