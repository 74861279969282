import React, { useReducer, useCallback, useEffect } from "react"
import {
  taskManagementStateReducer,
  initialTaskManagementState
} from "./task-management-module.reducer"
import {
  SetIsLoading,
  TaskManagementActionTypes,
  SetPageNumber,
  SetTasksCount,
  SetDueDates,
  SetIsAssignment,
  SetSelectedTab,
  SetSelectedRecordId,
  SetTasks,
  GetTaskList,
  SetOverlayTaskIndex
} from "./task-management-module.types"
import { TaskManagementContext } from "./task-management-module.context"
import { useTeam } from "views/team/team/team-module.context"
import {
  getAllAvailableFilters,
  getPreparedParams,
  getTasksFiltersFromSessionStorage,
  getTasksList,
  hasTasksFiltersInSessionStorage,
  saveTasksFiltersToSessionStorage
} from "./task-management-module.helpers"
import { Task } from "views/homepage/task-management-module/task-management-module.types"
import {
  ApplyFilters,
  CandidateFilter,
  UpdateFilters
} from "../../assignments/assignment-module/assignment-module.types"
import { SetAllAvailableFilters } from "../../campaigns/campaign-module/campaign-module.types"
import { FilterArrayType } from "../../../components/ProjectRecordsFilters/types"
import { fetchPhotosAndSetUpdatedEntities } from "models/LocalPerson/localPerson.actions"

type TaskManagementModuleProps = {
  children: React.ReactNode
}

export const TaskManagementModule = ({
  children
}: TaskManagementModuleProps) => {
  const { fetchTeamMembers } = useTeam()

  const [state, dispatch] = useReducer(
    taskManagementStateReducer,
    initialTaskManagementState
  )

  const { isAssignment, selectedTab, pageNumber, pageSize } = state

  const setIsLoading: SetIsLoading = (data) => {
    dispatch({ type: TaskManagementActionTypes.SetIsLoading, payload: data })
  }

  const setTasks: SetTasks = (data) => {
    dispatch({
      type: TaskManagementActionTypes.SetTasks,
      payload: data
    })
  }

  const setPageNumber: SetPageNumber = useCallback((data) => {
    dispatch({ type: TaskManagementActionTypes.SetPageNumber, payload: data })
  }, [])

  const setIsAssignment: SetIsAssignment = (data) => {
    dispatch({ type: TaskManagementActionTypes.SetIsAssignment, payload: data })
  }

  const setSelectedTab: SetSelectedTab = (data) => {
    dispatch({ type: TaskManagementActionTypes.SetSelectedTab, payload: data })
  }

  const setTasksCount: SetTasksCount = useCallback(
    (totalItems, totalFilteredCount) => {
      dispatch({
        type: TaskManagementActionTypes.SetTasksCount,
        payload: { totalItems, totalFilteredCount }
      })
    },
    []
  )

  const setDueDates: SetDueDates = useCallback((dueDates) => {
    dispatch({
      type: TaskManagementActionTypes.SetDueDates,
      payload: dueDates
    })
  }, [])

  const setSelectedRecordId: SetSelectedRecordId = (data) => {
    dispatch({
      type: TaskManagementActionTypes.SetSelectedRecordId,
      payload: data
    })
  }

  const setOverlayTaskIndex: SetOverlayTaskIndex = (data) => {
    dispatch({
      type: TaskManagementActionTypes.SetOverlayTaskIndex,
      payload: data
    })
  }

  // please do not pay attention , will finished in another PR
  const updateTask = useCallback((oldTask: Task, newCandidate: Task) => {
    // const newContacts = assignmentTasks.map((candidate: Task) =>
    //   candidate. === campaignId
    //     ? { ...candidate, ...newCandidate }
    //     : candidate
    // )
    // setAssignmentTasks(newContacts)
  }, [])

  const applyFilters: ApplyFilters = useCallback(
    (candidateFilters: CandidateFilter[]) => {
      dispatch({
        type: TaskManagementActionTypes.SetApplyFilters,
        payload: { candidateFilters }
      })
    },
    [dispatch]
  )

  const getTaskList: GetTaskList = useCallback(
    async (params = new URLSearchParams()) => {
      const taskType = isAssignment ? "assignment" : "campaign"

      params.append("DueFilter", selectedTab)
      params.append("pageSize", pageSize.toString())
      params.append("page", pageNumber.toString())

      setIsLoading(true)

      let { tasks, dueDayCount, countFiltered, count } = await getTasksList(
        params,
        taskType
      )
      fetchPhotosAndSetUpdatedEntities(tasks, setTasks)

      if (tasks.length > 0) {
        setTasks(tasks)
        setIsLoading(false)
        setDueDates(dueDayCount)
        setTasksCount(count, countFiltered)
      } else {
        setPageNumber(pageNumber - 1)
      }
    },
    [
      isAssignment,
      pageNumber,
      pageSize,
      selectedTab,
      setDueDates,
      setPageNumber,
      setTasksCount
    ]
  )

  const updateFilters: UpdateFilters = useCallback(
    (candidateFilters: CandidateFilter[], needRefresh = false) => {
      applyFilters(candidateFilters)
      const params = getPreparedParams(candidateFilters, isAssignment)
      if (needRefresh) {
        params.append("page", "1")
        setPageNumber(1)
        getTaskList(params)
      }
      saveTasksFiltersToSessionStorage(candidateFilters, isAssignment)
    },
    [applyFilters, getTaskList, setPageNumber, isAssignment]
  )

  const loadPreviousTasksFilters = useCallback(() => {
    const sessionStorageFilters =
      getTasksFiltersFromSessionStorage([String(isAssignment)]) || []

    applyFilters(sessionStorageFilters)
  }, [applyFilters, isAssignment])

  useEffect(() => {
    const hasStoredFilters = hasTasksFiltersInSessionStorage()
    if (hasStoredFilters) {
      loadPreviousTasksFilters()
    }
  }, [loadPreviousTasksFilters])

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  useEffect(() => {
    let isTasksLoading = true

    const sessionStorageFilters = getTasksFiltersFromSessionStorage([
      String(isAssignment)
    ])
    const params = sessionStorageFilters
      ? getPreparedParams(sessionStorageFilters, isAssignment)
      : new URLSearchParams()

    const taskType = isAssignment ? "assignment" : "campaign"
    params.append("DueFilter", selectedTab)
    params.append("pageSize", pageSize.toString())
    params.append("page", pageNumber.toString())

    setIsLoading(true)

    const fetchTasks = async () => {
      let { tasks, dueDayCount, countFiltered, count } = await getTasksList(
        params,
        taskType
      )

      fetchPhotosAndSetUpdatedEntities(tasks, setTasks)

      if (isTasksLoading) {
        setTasks(tasks)
        setIsLoading(false)
        setDueDates(dueDayCount)
        setTasksCount(count, countFiltered)
      }
    }

    fetchTasks()

    return () => {
      isTasksLoading = false
    }
  }, [
    isAssignment,
    selectedTab,
    pageNumber,
    pageSize,
    setDueDates,
    setTasksCount
  ])

  const setAllAvailableFilters: SetAllAvailableFilters = useCallback(
    (data: FilterArrayType) => {
      dispatch({
        type: TaskManagementActionTypes.SetAllAvailableFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const fetchAllAvailableFilters = useCallback(async () => {
    const { status, assignedTo, stage } = await getAllAvailableFilters(
      isAssignment,
      selectedTab
    )
    setAllAvailableFilters({ status, assignedTo, stage })
  }, [isAssignment, selectedTab, setAllAvailableFilters])

  useEffect(() => {
    fetchAllAvailableFilters()
  }, [fetchAllAvailableFilters])

  return (
    <TaskManagementContext.Provider
      value={{
        ...state,
        setPageNumber: setPageNumber,
        setIsAssignment: setIsAssignment,
        setSelectedTab: setSelectedTab,
        updateTask: updateTask,
        updateFilters,
        setSelectedRecordId: setSelectedRecordId,
        getTaskList: getTaskList,
        setTasks,
        setOverlayTaskIndex: setOverlayTaskIndex
      }}
    >
      {children}
    </TaskManagementContext.Provider>
  )
}
