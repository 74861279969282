import { Div } from "@ikiru/talentis-fpc"
import HorizontalScroll from "components/HorizontalScroll"
import React, { useEffect, useState } from "react"
import { StatusTabDetails } from "."
import { correctOrder } from "./const"
import StatusCard, { tabCardProps } from "./StatusCard"

type IProp = {
  onSelect: tabCardProps
  assignmentStatus: StatusTabDetails[]
  selectedStatus: string
}

const StatusGroupTabs = ({
  onSelect,
  assignmentStatus,
  selectedStatus
}: IProp) => {
  const [statusOrdered, setStatusOrdered] = useState<StatusTabDetails[]>([])

  useEffect(() => {
    let statusDetailsArray: StatusTabDetails[] = correctOrder
      .map((statusName) => {
        const ordered: StatusTabDetails = assignmentStatus.find(
          (status) => status?.data === statusName
        ) || { data: "empty", count: 0 }
        return ordered
      })
      .filter((status) => status)
    setStatusOrdered(statusDetailsArray)
  }, [assignmentStatus])

  return (
    <Div mt={-2}>
      <HorizontalScroll>
        {statusOrdered.map((status: StatusTabDetails, index: number) => {
          if (status.data === "empty") {
            return null
          }
          return (
            <StatusCard
              status={status}
              key={status.data + index}
              itemId={status.data}
              onSelect={onSelect}
              selectedStatus={selectedStatus}
            />
          )
        })}
      </HorizontalScroll>
    </Div>
  )
}

export default StatusGroupTabs
