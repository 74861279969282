import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { useSearch } from "views/search/SearchModule/context"
import { getNormalizedPersonProfileDetails } from "../../person-details/utils/get-person-details"
import { PersonPhoto } from "../../person-avatar/constants/person-avatar.definitions"
import { useCallback, useState, useEffect } from "react"
import { scrollToTop } from "views/search/SearchModule/utils"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { parseMomentDate } from "utils/moment"

export const useChangeSearchPerson = () => {
  const [isFirstElement, setIsFirstElement] = useState(false)
  const [isLastElement, setIsLastElement] = useState(false)

  const {
    setSelectedSearchPersonId,
    setSelectedSearchDataPoolId,
    selectedSearchDataPoolId,
    persons = [],
    selectedSearchPersonId: localPersonId,
    paginationProps,
    onPageChange,
    pageNumber,
    isLoading
  } = useSearch()

  const {
    getProfileData,
    setProfileDetails,
    setIsLoading,
    setPhoto,
    setKeywords,
    setCreatedDate,
    setOffLimits,
    setTeamMembers
  } = usePerson()

  const directionNext = () => changeSearchPerson(1)

  const directionPrevious = () => changeSearchPerson(-1)

  const getPersonSearch = useCallback(
    (selectedId: string = "") => {
      const person = persons?.find(
        (person) => person.person?.dataPoolPersonId === selectedId
      )?.person
      setCreatedDate("")
      if (person?.personId) {
        setSelectedSearchPersonId(person?.personId)
        getProfileData(person?.personId)
      } else {
        setSelectedSearchPersonId("")
        const normalized = new LocalPersonModel({
          dataPoolPerson: person,
          localPerson: {}
        })
        const {
          keywords,
          photo,
          gdprLawfulBasisState,
          createdDate,
          offlimits,
          ...data
        } = normalized
        const endDate = parseMomentDate(offlimits?.endDate)
        // @ts-ignore
        setOffLimits({ ...offlimits, endDate })
        setTeamMembers([])
        const profileDetails = getNormalizedPersonProfileDetails(data)
        setProfileDetails({
          ...profileDetails,
          isOfflimits: person?.isOfflimits || false
        })

        if (photo?.url) {
          setPhoto(photo as PersonPhoto)
        } else {
          setPhoto({ url: "" })
        }
        setKeywords(keywords)
        setIsLoading(false)
      }
    },
    [
      getProfileData,
      persons,
      setCreatedDate,
      setIsLoading,
      setKeywords,
      setOffLimits,
      setPhoto,
      setProfileDetails,
      setSelectedSearchPersonId,
      setTeamMembers
    ]
  )

  useEffect(() => {
    const noId = persons.find(
      (person) => person.person.dataPoolId === selectedSearchDataPoolId
    )
    if (!isLoading && !Boolean(noId)) {
      if (isLastElement) {
        setSelectedSearchDataPoolId(persons[0].person.dataPoolId)
        getPersonSearch(persons[0].person.dataPoolId)
      } else if (isFirstElement) {
        setSelectedSearchDataPoolId(
          persons[paginationProps.pageSize - 1].person.dataPoolId
        )
        getPersonSearch(persons[paginationProps.pageSize - 1].person.dataPoolId)
      }
    }
  }, [
    getPersonSearch,
    isFirstElement,
    isLastElement,
    isLoading,
    pageNumber,
    paginationProps.pageSize,
    persons,
    selectedSearchDataPoolId,
    setSelectedSearchDataPoolId
  ])

  const handlePageChange = (page: number) => {
    onPageChange(page)
    scrollToTop()
  }

  const changeSearchPerson = (direction: number) => {
    setIsFirstElement(false)
    setIsLastElement(false)

    const searchIdList = persons.map(
      (candidate) => candidate.person?.dataPoolPersonId
    )

    const searchPersonLocation: number = searchIdList.indexOf(
      selectedSearchDataPoolId
    )

    const newSearchPersonLocation: number = searchPersonLocation + direction

    if (
      !(newSearchPersonLocation < 0) &&
      !(newSearchPersonLocation > searchIdList.length - 1)
    ) {
      const newId = searchIdList[newSearchPersonLocation]
      setSelectedSearchDataPoolId(newId)
      getPersonSearch(newId)
    } else if (paginationProps.maxPages > 1) {
      if (
        paginationProps.pageNumber < paginationProps.maxPages &&
        direction === 1
      ) {
        setIsLastElement(true)
        paginationProps.hasNextPage &&
          handlePageChange(paginationProps.pageNumber + 1)
      } else if (paginationProps.pageNumber > 1 && direction === -1) {
        setIsFirstElement(true)
        handlePageChange(paginationProps.pageNumber - 1)
      }
    }
  }

  return {
    directionNext,
    directionPrevious,
    getPersonSearch,
    localPersonId
  }
}
