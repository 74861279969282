import React from "react"
import {
  H1,
  Flex,
  SectionBody,
  BodyText,
  colors,
  H3,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import CompaniesSearchForm from "../../components/company-searchform/CompaniesSearchForm"
import { CompanyComingSoonWrapper, SearchFlex, StyledImgWrapper } from "./style"
import LinkToTalentis from "assets/LinktoTalentGraph.png"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

export const CompaniesSearch = () => {
  const mediaQuery = getMinWidthMediaQuery("xs")
  const isMobile = !useMediaQuery(mediaQuery)

  const { open } = useModal()

  const handleVideoClick = () => {
    open(
      ModalNames.VideoExample,
      <iframe
        src="https://player.vimeo.com/video/924615562?h=1d8e13c816"
        width="900"
        height="700"
        frameBorder="0"
        allowFullScreen
        title={messages.companiesSearch.linkTalentEx}
      />,
      true
    )
  }

  return (
    <Flex flexDirection={"column"}>
      <Helmet>
        <title>
          {messages.seo.title.format(messages.companiesSearch.title)}
        </title>
      </Helmet>
      <H1 my="xs" color="green.standard">
        {messages.companiesSearch.title}
      </H1>
      <SearchFlex>
        <CompaniesSearchForm />
      </SearchFlex>
      <SectionBody mt="xs">
        <Flex flexDirection="column" alignItems="center" pb="m">
          <H1 mb="s" textAlign="center" color="grey.dark">
            {messages.companiesSearch.lookUpOrCreate}
          </H1>
          <BodyText mb="l" px="xs" mt="0" textAlign="center">
            {messages.companiesSearch.ifYouCreate}
          </BodyText>
          <Flex
            width="100%"
            flexWrap={isMobile ? "wrap" : "nowrap"}
            justifyContent="center"
          >
            <StyledImgWrapper
              width={isMobile ? "100%" : "70%"}
              onClick={handleVideoClick}
            >
              <img
                alt={messages.companiesSearch.linkTalentEx}
                width="100%"
                src={LinkToTalentis}
              />
            </StyledImgWrapper>
            <CompanyComingSoonWrapper>
              <H3 color={colors.grey.standard}>
                {messages.project.outReach.comingSoon}
              </H3>
              <BodyText
                textAlign="center"
                m="0"
                color={colors.grey.standard}
                px="xs"
              >
                {messages.project.outReach.companySoon}
              </BodyText>
            </CompanyComingSoonWrapper>
          </Flex>
        </Flex>
      </SectionBody>
    </Flex>
  )
}
