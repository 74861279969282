import React, { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { RouterUrl } from "setup/router/routes"
import { Loader } from "components/Loader/Loader"
import { useAuth } from "setup/auth/module/auth.context"
import { Flex } from "@ikiru/talentis-fpc"

export const Logout = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  const onLogoutClick = useCallback(async () => {
    await logout?.()
    navigate(RouterUrl.Homepage /*, { logout: true }*/)
  }, [navigate, logout])

  useEffect(() => {
    onLogoutClick()
  }, [onLogoutClick])

  return (
    <Flex height="100svh">
      <Loader />
    </Flex>
  )
}
