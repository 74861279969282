import { apiRequest } from "setup/api/api"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { getDataFilter } from "components/ProjectRecordsFilters/helpers"

export const getCampaignListContact = async (
  campaignId: string,
  params = new URLSearchParams()
) => {
  const [, response] = await apiRequest.get({
    endpoint: ContactsEndpoints.SearchById,
    endpointParams: { campaignId: campaignId },
    config: { params: params }
  })

  const contacts = response?.data.data || []
  const totalItemCount = response?.data?.count || 0
  const totalFilteredCount = response?.data?.countFiltered || 0

  return { contacts, totalItemCount, totalFilteredCount }
}

export const getAllAvailableFilters = async (campaignId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: ContactsEndpoints.FilterData,
    endpointParams: { campaignId: campaignId }
  })

  const {
    statuses,
    assignedsTo,
    dueDates: dates,
    tags: tagIds
  } = response?.data || {}

  const status = getDataFilter(statuses)
  const assignedTo = getDataFilter(assignedsTo)
  const tags = getDataFilter(tagIds)
  const dueDates = getDataFilter(dates)

  return { status, assignedTo, tags, dueDates }
}
