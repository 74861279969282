import { useMemo } from "react"
import { ProjectRecordFilter } from "components/ProjectRecordsFilters/types"
import uniq from "lodash/uniq"
import { messages } from "setup/messages/messages"
import { Tag } from "components/Tags/tags.types"

/**
 * Return a list of tags filters
 */
export const useTags = (tagIdsFilter: number[], projectDetailsTags: Tag[]) => {
  const tagsFilters = useMemo<ProjectRecordFilter[]>(() => {
    const contactTagsIds = tagIdsFilter.map((tag) =>
      tag || tag === 0 ? tag : "noTag"
    )

    const noTags = {
      recordProperty: "tags",
      value: {
        noTags: []
      },
      label: messages.project.noTags,
      filterKey: "noTags",
      disabled: !contactTagsIds.includes("noTag")
    }

    const filters = projectDetailsTags.map((tag) => {
      const filter = {
        recordProperty: "tags",
        value: {
          tags: tag.id
        },
        label: tag.name,
        filterKey: `tags${tag.name}`,
        disabled: !contactTagsIds.includes(tag.id),
        span: tag.background,
        variant: tag.background
      }

      return filter
    })
    return uniq([noTags, ...filters])
  }, [projectDetailsTags, tagIdsFilter])

  return {
    tagsFilters
  }
}
