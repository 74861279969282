import {
  CVDataType,
  Education,
  ExistingEducation,
  ParsedEducation,
  Person
} from "views/persons/person.types"
import { createCvExistingColumn } from "./get-cv-experience"
import { Keyword } from "views/persons/person-module/person-module.types"

export const getNormalizedCvSkill = (
  data: Person,
  person: Person | undefined,
  keywords: Keyword[],
  isEmptyProfile: boolean
) => {
  const existingKeywords =
    keywords
      ?.map((keyword) => ({
        ...keyword,
        isDeleted: false
      }))
      .sort((a, b) => a.value.localeCompare(b.value)) || []

  const unDuplicatedKeywords: string[] = []
  data?.keywords?.forEach((keyword: string) => {
    if (!unDuplicatedKeywords.includes(keyword)) {
      unDuplicatedKeywords.push(keyword)
    }
  })

  const parsingKeywords =
    unDuplicatedKeywords
      .map((keyword: string) => ({
        value: keyword,
        id: null,
        keywordCategoryId: null,
        isDeleted: false
      }))
      .sort((a, b) => a.value.localeCompare(b.value)) || []

  const existingEducation: ExistingEducation[] =
    person?.education.map((educ: Education) =>
      createCvExistingColumn(educ, CVDataType.Existing, isEmptyProfile)
    ) || []

  const parsedEducation: ParsedEducation[] = data.education
    .filter(
      (parsedEduc) =>
        !Boolean(
          person?.education.filter((exp) => exp.id === parsedEduc.id).length
        )
    )
    .map((educ: Education) =>
      createCvExistingColumn(educ, CVDataType.Parsed, isEmptyProfile)
    )

  return {
    existingKeywords,
    parsingKeywords,
    parsedEducation,
    existingEducation
  }
}
