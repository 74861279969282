import React from "react"
import { BodyText, Button, Flex, H4, colors } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { CVParsingAlertModalWrapper } from "./style"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { OverviewCVModal } from "../overview/OverviewCVModal"
import { ParsingResumeModal } from "./ParsingResumeModal"
import cloneDeep from "lodash/cloneDeep"
import { processTempPersonDetails, processTempContactInfo } from "./helper"
import { CVParsing } from "views/persons/person.types"

type CVParsingAlertModalProps = {
  closeWithoutSaving: () => void
  isFromReview?: boolean
  updatePersonRecord?: (cvParsing: CVParsing) => void
}

export const CVParsingAlertModal = ({
  closeWithoutSaving,
  isFromReview,
  updatePersonRecord
}: CVParsingAlertModalProps) => {
  const { previousButtonClicked, setActiveTab, cvParsing, setCVParsing } =
    usePerson()

  const { close, open, openInPortal } = useModal()

  const saveAllData = () => {
    const tempCVParsing = cloneDeep(cvParsing)
    processTempPersonDetails(tempCVParsing.personDetails)
    processTempContactInfo(tempCVParsing.contactInfo)
    setCVParsing({ ...tempCVParsing })
    goToNextStep(tempCVParsing)
  }

  const goToNextStep = (tempCVParsing?: CVParsing) => {
    if (previousButtonClicked === "overview") {
      close(ModalNames.CVParsingAlertModal)
      close(ModalNames.ParsingResume)
      open(ModalNames.OverviewCVModal, <OverviewCVModal />)
    } else if (previousButtonClicked === "finish") {
      close(ModalNames.CVParsingAlertModal)
      updatePersonRecord && tempCVParsing && updatePersonRecord(tempCVParsing)
    } else {
      setActiveTab(previousButtonClicked as number)
      close(ModalNames.CVParsingAlertModal)
      if (isFromReview) {
        close(ModalNames.OverviewCVModal)
        openInPortal(ModalNames.ParsingResume, <ParsingResumeModal />)
      }
    }
  }

  const closeAlert = () => {
    closeWithoutSaving()
    goToNextStep(cvParsing)
  }

  const saveChanges = () => {
    saveAllData()
  }

  return (
    <CVParsingAlertModalWrapper>
      <Flex justifyContent="space-between" width="100%">
        <H4 color={colors.grey.dark} mt="0">
          {messages.person.cvparsing.unsavedChanges}
        </H4>
        <Flex>
          <Button
            onClick={() => close(ModalNames.CVParsingAlertModal)}
            mode="standard-white"
            size="small"
          >
            {messages.generic.cancel}
          </Button>
        </Flex>
      </Flex>
      <BodyText>{messages.person.cvparsing.alertMessage}</BodyText>
      <Flex justifyContent="flex-end">
        <Button onClick={closeAlert} mode="standard-white" size="small" mr="xs">
          {messages.person.cvparsing.leaveWithoutSaving}
        </Button>
        <Button onClick={saveChanges} mode="primary" size="small">
          {messages.generic.save}
        </Button>
      </Flex>
    </CVParsingAlertModalWrapper>
  )
}
