import styled from "styled-components"
import { H6 } from "@ikiru/talentis-fpc"

export const OptionsContainer = styled.div``

export const OptionLabel = styled(H6)`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const Option = styled.div<{ disabled?: boolean }>`
  padding: ${({ theme }) => theme.space.xs}px;
  cursor: pointer;
  opacity: 0.3;

  ${({ theme, disabled }) =>
    !disabled &&
    `
  opacity: 1;

  &:hover {
    &:first-child {
      border-top-left-radius: ${theme.borderRadius.standard}px;
      border-top-right-radius: ${theme.borderRadius.standard}px;
    }

    &:last-child {
      border-bottom-left-radius: ${theme.borderRadius.standard}px;
      border-bottom-right-radius: ${theme.borderRadius.standard}px;
    }

    background-color: ${theme.colors.grey.light};

    & ${OptionLabel} {
      color: ${theme.colors.grey.darkest};
    }
  }
  `}

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey.darkest};
  }
`
