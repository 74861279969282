import styled, { css } from "styled-components"
import { Flex, Div, colors, spacing } from "@ikiru/talentis-fpc"

type DragAndDropWrapperProps = {
  isDragActive: boolean
}

type BodyStyledProps = {
  disabled: boolean
}

export const CVParsingModalWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 490px;
  min-height: 455px;
`

export const DragAndDropWrapper = styled(Div)<DragAndDropWrapperProps>`
  margin-top: ${spacing.m}px;
  padding-top: ${spacing.m}px;
  text-align: center;
  width: 390px;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      background-color: ${colors.green.lightest};
      border: dashed ${colors.grey.standard};
    `}
`

export const FileWrapper = styled(Flex)`
  justify-content: space-between;
  padding: ${spacing.xs}px;
  width: 390px;
  border: solid ${colors.grey.light};
  margin-top: ${spacing.xl}px;
`

export const BodyStyled = styled(Flex)<BodyStyledProps>`
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.xxl}px;
  width: 300px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`
