import React, { useEffect, useState } from "react"
import {
  Flex,
  H2,
  Button,
  CrossIcon,
  Div,
  BodyText,
  H5,
  Loader,
  theme,
  Toggle,
  SmallText
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import FindPersonForm from "../FindPerson/FindPersonForm"
import { PersonDetailsType } from "../.."
import CreatePerson from "../CreatePerson"
import { fetchSelectedPeople, linkageDataPoolPerson } from "./action"
import { PersonSearchResult } from "views/persons/pages/search-person/search-person/search-person.definitions"
import { PersonFoundRecord } from "../PossibleDuplicates/components/PersonFoundRecord"
import {
  FormContainer,
  FormInputContainer,
  FoundPeopleContainer,
  PaginationWrapper,
  TooltipContainer,
  TooltipInfoContainer
} from "./styles."
import { useNewContactModal } from "../../hooks"
import useAddPersonToProject from "./hooks/useAddPersonToProject"
import ConfirmationSection from "./ComfirmationSection"
import {
  Pagination,
  PaginationProps
} from "components/functional/pagination/Pagination"
import MoreInfo from "./MoreInfo"
import { calcMaxPages } from "./utlis"
import { useTelemetry } from "utils/hooks/use-telemetry"

type AddPeopleProps = {
  personSearchDetails: PersonDetailsType
  onSubmit: (data: PersonDetailsType, pageNumber?: number) => void
  onSubmitNewRecord: (name: string) => void
  foundPeople: PersonSearchResult[]
  isLoadingFind: boolean
  assignmentId: string
  campaignId: string
  isMobile: boolean
  paginationProps?: PaginationProps
}

const AddPeople = ({
  onSubmit,
  foundPeople,
  isLoadingFind: isLoading,
  personSearchDetails,
  onSubmitNewRecord,
  assignmentId,
  campaignId,
  isMobile,
  paginationProps
}: AddPeopleProps) => {
  const [selectedPeople, setSelectedPeople] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [tempName, setTempName] = useState(
    personSearchDetails.firstName + " " + personSearchDetails.lastName
  )
  const [hasAddedPerson, setHasAddedPerson] = useState(false)
  const [hasAddedPeople, setHasAddedPeople] = useState(false)
  const [tooltipsOn, setTooltipsOn] = useState(false)
  const [beingAddedToAssignment, setBeingAddedToAssignment] = useState(false)
  const [beingAddedToCampaign, setBeingAddedToCampaign] = useState(false)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
  const [n360Error, setN360Error] = useState(false)
  const {
    addAssignmentToPerson,
    addCampaignToPerson,
    closeAndRedirectToCampaign,
    closeAndRedirectToAssignment
  } = useAddPersonToProject()
  const { trackN360Error } = useTelemetry()

  useEffect(() => {
    setTempName(
      personSearchDetails.firstName + " " + personSearchDetails.lastName
    )
  }, [personSearchDetails.firstName, personSearchDetails.lastName])

  const { close, closeAndRedirect } = useNewContactModal()

  const onClickPerson = (id: string) => {
    if (selectedPeople.includes(id)) {
      const newPeopleList =
        selectedPeople.filter((personId) => personId !== id) || []

      setSelectedPeople(newPeopleList)
    } else {
      setSelectedPeople([...selectedPeople, id])
    }
  }

  const delayForSuccessMessage = (callback: (data?: any) => void) => {
    setTimeout(() => callback(), 5000)
  }
  const savePeople = async () => {
    setIsSaving(true)
    try {
      const fetchProfiles = await fetchSelectedPeople(
        selectedPeople,
        trackN360Error
      )

      const fetchPromises = fetchProfiles.people.map(({ id }: any) => {
        return linkageDataPoolPerson(id)
      })
      const localIdsList = await Promise.all(fetchPromises)

      if (localIdsList.length === 1) {
        setHasAddedPerson(true)
      } else {
        setHasAddedPeople(true)
      }

      if (!assignmentId && !campaignId) {
        if (localIdsList.length === 1) {
          delayForSuccessMessage(() => closeAndRedirect(localIdsList[0]))
        } else {
          delayForSuccessMessage(() => close())
        }
      } else {
        if (assignmentId) {
          setBeingAddedToAssignment(true)
        } else {
          setBeingAddedToCampaign(true)
        }

        if (localIdsList.length === 1) {
          if (assignmentId) {
            await addAssignmentToPerson(assignmentId, localIdsList[0])
            delayForSuccessMessage(() =>
              closeAndRedirectToAssignment(assignmentId)
            )
          } else {
            await addCampaignToPerson(campaignId, localIdsList[0])
            delayForSuccessMessage(() => closeAndRedirectToCampaign(campaignId))
          }
        } else {
          if (assignmentId) {
            const addPromise = localIdsList.map((localId) => {
              return addAssignmentToPerson(assignmentId, localId)
            })
            await Promise.all(addPromise)
            delayForSuccessMessage(() =>
              closeAndRedirectToAssignment(assignmentId)
            )
          } else {
            const addPromise = localIdsList.map((localId) => {
              return addCampaignToPerson(campaignId, localId)
            })
            await Promise.all(addPromise)
            delayForSuccessMessage(() => closeAndRedirectToCampaign(campaignId))
          }
        }
      }
    } catch (error) {
      setN360Error(true)
    }
    setIsSaving(false)
  }

  const onSubmitForm = (data: PersonDetailsType) => {
    setSelectedPeople([])
    onSubmit(data)
  }

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingNextPage(false)
    }
  }, [isLoading])

  return (
    <Flex
      backgroundColor={theme.colors.grey.light}
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="flex-end"
      alignItems="stretch"
      flex="1"
    >
      <FormContainer isMobile={isMobile}>
        <Flex justifyContent="space-between">
          <H2 textAlign="left" color="grey.dark" m="0" mt="20px">
            {messages.person.contact.form.resultTitle}
          </H2>
          {isMobile && (
            <Button
              mt="-20px"
              onClick={() =>
                assignmentId || campaignId
                  ? assignmentId
                    ? closeAndRedirectToAssignment(assignmentId)
                    : closeAndRedirectToCampaign(campaignId)
                  : close()
              }
              mode="action"
              size="action-standard"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          )}
        </Flex>
        <BodyText mt="m">
          {messages.person.contact.AddPeople.whatYourLookingFor}
        </BodyText>
        <FormInputContainer isMobile={isMobile}>
          <FindPersonForm
            {...{
              onSubmit: onSubmitForm,
              personSearchDetails,
              isEditSearchPage: true
            }}
          />
        </FormInputContainer>
      </FormContainer>

      <Div
        maxHeight={isMobile ? "40vh" : ""}
        p="xs"
        overflow="auto"
        minWidth="60%"
      >
        <Flex flexDirection="column" alignItems="flex-end">
          {!isMobile && (
            <Button
              mt="-5px"
              onClick={() =>
                assignmentId || campaignId
                  ? assignmentId
                    ? closeAndRedirectToAssignment(assignmentId)
                    : closeAndRedirectToCampaign(campaignId)
                  : close()
              }
              mode="action"
              size="action-standard"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor={theme.colors.grey.light}
        >
          {isLoading && !isLoadingNextPage ? (
            <Flex mt="100px">
              <Loader />
            </Flex>
          ) : (
            <Div height="100%" overflowY="auto">
              <Flex flexDirection="column" alignItems="center" mb="l">
                {!foundPeople.length ? (
                  <BodyText>{messages.person.newContact.notFound}</BodyText>
                ) : (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <H5 textAlign="center" mt="0">
                      {messages.person.newContact.weFound.format(
                        paginationProps?.totalItemCount || 0
                      )}
                    </H5>
                    <BodyText textAlign="center" mb="s" mt="0">
                      {messages.person.newContact.pleaseSelect.format(
                        assignmentId || campaignId
                          ? assignmentId
                            ? messages.assignment.addNew.toLowerCase()
                            : messages.person.campaigns.campaign.toLowerCase()
                          : messages.person.contacts
                      )}
                    </BodyText>
                    {isLoadingNextPage ? (
                      <Flex minHeight="43vh">
                        <Loader />
                      </Flex>
                    ) : (
                      <>
                        <FoundPeopleContainer>
                          {foundPeople.map((item) => {
                            if (tooltipsOn) {
                              return (
                                <TooltipContainer key={item.id}>
                                  <PersonFoundRecord
                                    {...item}
                                    isActive={selectedPeople.includes(item.id)}
                                    onClickCallBack={onClickPerson}
                                    maxWidth="100%"
                                  />
                                  <TooltipInfoContainer>
                                    <MoreInfo {...item} />
                                  </TooltipInfoContainer>
                                </TooltipContainer>
                              )
                            }
                            return (
                              <PersonFoundRecord
                                {...item}
                                isActive={selectedPeople.includes(item.id)}
                                onClickCallBack={onClickPerson}
                                maxWidth="100%"
                              />
                            )
                          })}
                        </FoundPeopleContainer>
                        <Flex width="100%" justifyContent="flex-end" mt="xxs">
                          <Toggle
                            type="checkbox"
                            label={<SmallText>Show extended info</SmallText>}
                            labelProps={{ mt: "-4px" }}
                            hideUnderline
                            onChange={() => {
                              setTooltipsOn((prevState) => !prevState)
                            }}
                            checked={tooltipsOn}
                          />
                        </Flex>
                      </>
                    )}
                    <PaginationWrapper
                      isFirstPage={paginationProps?.isFirstPage}
                      isLastPage={paginationProps?.isLastPage}
                    >
                      {paginationProps && (
                        <Pagination
                          {...paginationProps}
                          colorTheme={{
                            background: theme.colors.grey.light
                          }}
                          maxPages={calcMaxPages(paginationProps)}
                          hasNextPage={
                            paginationProps.pageCount !==
                            paginationProps.pageNumber
                          }
                          onPageChange={(pageNumber) => {
                            setIsLoadingNextPage(true)
                            onSubmit(personSearchDetails, pageNumber)
                          }}
                        />
                      )}
                    </PaginationWrapper>
                  </Flex>
                )}
              </Flex>
              {selectedPeople.length ? (
                <ConfirmationSection
                  n360Error={n360Error}
                  selectedPeople={selectedPeople}
                  isSaving={isSaving}
                  totalItemOfFetch={paginationProps?.totalItemCount || 0}
                  savePeople={savePeople}
                  setSelectedPeople={setSelectedPeople}
                  hasAddedPerson={hasAddedPerson}
                  hasAddedPeople={hasAddedPeople}
                  beingAddedToAssignment={beingAddedToAssignment}
                  beingAddedToCampaign={beingAddedToCampaign}
                />
              ) : (
                <CreatePerson
                  {...{
                    name:
                      personSearchDetails.firstName +
                      " " +
                      personSearchDetails.lastName,
                    title: messages.person.newContact.stillCantFind,
                    onSubmit: onSubmitNewRecord,
                    isMobile: true,
                    isHorizontalView: true,
                    tempName,
                    setTempName
                  }}
                />
              )}
            </Div>
          )}
        </Flex>
      </Div>
    </Flex>
  )
}

export default AddPeople
