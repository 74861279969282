import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { ActionButtonStyled } from "./style"
import { CVActionButtonProps } from "."

export const AddAllCVActionButton = ({
  onEditAll,
  onRestoreAll,
  disableEditAll = false,
  editAllLabel,
  restoreAllLabel,
  disableRestore = false
}: CVActionButtonProps) => {
  return (
    <Flex>
      <ActionButtonStyled
        mode="standard-green"
        size="extra-small"
        onClick={onRestoreAll}
        disabled={disableRestore}
      >
        {restoreAllLabel}
      </ActionButtonStyled>
      <ActionButtonStyled
        mode="standard-white"
        size="extra-small"
        onClick={onEditAll}
        disabled={disableEditAll}
      >
        {editAllLabel}
      </ActionButtonStyled>
    </Flex>
  )
}
