import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import ViewableSection from "components/ViewableSection"
import { PersonSectionHeaders } from "../../style"
import { messages } from "setup/messages/messages"
import PersonAddressView from "./components/PersonAddressView"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useModal } from "utils/hooks/use-modal"
import useSubmitPerson from "../../hook/useSubmitPerson"
import { ModalNames } from "setup/modal/modal.definitions"
import EditPersonAddress from "./form/EditPersonAddress"

const PersonAddress = () => {
  const { profileDetails } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()

  const editPersonAddress = () => {
    openModal(
      ModalNames.EditPersonDetails,
      <EditPersonAddress
        onSubmit={onPersonSubmit}
        initialValues={{
          location: profileDetails.location,
          postcode: profileDetails.postcode,
          city: profileDetails.city,
          country: profileDetails.country,
          addressLine1: profileDetails.addressLine1,
          addressLine2: profileDetails.addressLine2
        }}
      />
    )
  }

  return (
    <Div width="100%" mb="s">
      <ViewableSection
        view={<PersonAddressView />}
        title={
          <PersonSectionHeaders>
            {messages.person.personTabSection.address}
          </PersonSectionHeaders>
        }
        editFunction={editPersonAddress}
      />
    </Div>
  )
}

export default PersonAddress
