import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { Keyword } from "views/persons/person-module/person-module.types"

export const patchKeyword = async (personId: string, keywords: string[]) => {
  await apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: { keywords },
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
}

export const updateKeyword = async (personId: string, keywords: Keyword[]) => {
  const [, response] = await apiRequest.put({
    endpoint: PersonsEndpoints.Keywords,
    data: { keywords },
    endpointParams: { id: personId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}
