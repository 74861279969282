import {
  ArrowIcon,
  BodyText,
  Flex,
  InformationIcon,
  Textarea,
  fontFamilies,
  colors
} from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { hexToRGB } from "utils/hexToRGB"

export const NotificationContainer = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.s}px;
  width: 100%;
`
export const NotificationIcon = styled(InformationIcon)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 6px;
  margin-top: -5px;
  background-color: ${({ theme }) => theme.colors.yellow.light};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space?.xxs}px;
`

export const NotificationBodyText = styled(BodyText)`
  font-size: 14px;
  margin: 0px;
  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      font-size: 16px;
    }
    `}
`

export const InputSection = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0px ${({ theme }) => theme.space.xl}px;
`

export const GPTTextarea = styled(Textarea)`
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: ${hexToRGB(colors.grey.standard, 0.4)};
    font-family: ${fontFamilies.gibson};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.1px;
  }

  &::-webkit-scrollbar {
    width: 25px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 17px white solid;
    background: ${({ theme }) => theme.colors.grey.light};
    border-radius: 4px;
  }
`

export const IconContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.green.standard};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 2;
  margin: -40px ${({ theme }) => theme.space.xs}px 25px 0px;
`

export const StyledArrowIcon = styled(ArrowIcon)`
  width: 18px;
  fill: ${({ theme }) => theme.colors.white.standard};
  cursor: pointer;
`
