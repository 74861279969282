import React from "react"
import { H5, Link } from "@ikiru/talentis-fpc"
import { OverviewSectionWrapper } from "./style"

type OverviewSectionProps = {
  title: string
  linkText: string
  goBack: () => void
}

export const OverviewSection = ({
  title,
  linkText,
  goBack
}: OverviewSectionProps) => {
  return (
    <OverviewSectionWrapper>
      <H5 m="0">{title}</H5>

      <Link href="#" size="small" onClick={goBack} mb="0">
        {linkText}
      </Link>
    </OverviewSectionWrapper>
  )
}
