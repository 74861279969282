import styled from "styled-components"
import { Flex, colors, spacing, Link } from "@ikiru/talentis-fpc"

export const GT2ModalWrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 750px;
  min-height: 300px;
`

export const StyledLink = styled(Link)`
  width: fit-content;
`
