import styled, { css } from "styled-components"
import { activeLinkClassName } from "components/visual/header/constants/header.definitions"
import {
  outlookActiveLinkCss,
  reportActiveLinkCss
} from "./components/SidebarLinks/SidebarLinks.styled"
import { messages } from "setup/messages/messages"

const activeLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.grey.dark};

  & svg {
    fill: ${({ theme }) => theme.colors.white.standard};
  }
`

const hoverLinkCss = css`
  border-color: ${({ theme }) => theme.colors.grey.darkest};
  background-color: ${({ theme }) => theme.colors.grey.light};

  & svg {
    fill: ${({ theme }) => theme.colors.grey.darkest};
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-bottom: 3px solid;
  border-color: ${({ theme }) => theme.colors.grey.light};
  overflow-y: hidden;
  white-space: nowrap;
  user-select: none;
  padding: ${({ theme }) => theme.space.xs}px;
  justify-content: space-between;
`

type ActionIconParams = {
  size: number
}

export const ActionIcon = styled.div<ActionIconParams>`
  margin-right: ${({ theme }) => theme.space.xxs}px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.grey.dark} !important;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 50%;
  border: 2px solid;
  & svg {
    max-height: 100%;
    max-width: 100%;
    fill: ${({ theme }) => theme.colors.grey.dark};
  }
`

export const ActionWrapper = styled.div<{
  disabled?: boolean
  label?: string
}>`
  text-decoration: none !important;

  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.3;

      &:not(.${activeLinkClassName}):not(:focus):hover ${ActionIcon} {
        ${hoverLinkCss}
      }
    `
      : `
      cursor: pointer;
  `}

  &.${activeLinkClassName} ${ActionIcon} {
    ${({ label }) =>
      messages.navigation.links.outlook === label
        ? outlookActiveLinkCss
        : messages.navigation.links.reports === label
        ? reportActiveLinkCss
        : activeLinkCss}
  }
`
