import { Flex, theme, spacing } from "@ikiru/talentis-fpc"
import styled from "styled-components"

type StyledMicrophoneContainerProps = {
  isListening: boolean
  isOneLine?: boolean
}
export const StyledMicrophoneContainer = styled(
  Flex
)<StyledMicrophoneContainerProps>`
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${({ isOneLine }) => (isOneLine ? theme.space.xs : theme.space.m)}px;
  right: 20px;
  background-color: ${({ isListening }) =>
    isListening ? theme.colors.green.standard : theme.colors.white.standard};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.9;
  border: 2px solid ${theme.colors.green.standard};
  svg {
    max-width: 20px;
    fill: ${({ isListening }) =>
      isListening ? theme.colors.white.standard : theme.colors.green.standard};
  }

  ${({ isListening }) =>
    isListening &&
    `
    animation: isSpeaking 2s ease-in-out infinite;
  `}

  @keyframes isSpeaking {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    50% {
      transform: scale(1.13);
      -webkit-transform: scale(1.13);
    }
    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`
