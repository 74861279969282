import React from "react"
import { messages } from "setup/messages/messages"
import { useResolvedPath, Route } from "react-router-dom"
import { RouterSubUrl, RouterUrl } from "setup/router/routes"
import { Navigation } from "components/visual/navigation/navigation.styled"
import { NavigationItem } from "components/visual/navigationItem/navigationItem.styled"
import { RouterLink } from "components/RouterLink/RouterLink"
import { CreateAssignment } from "views/assignments/pages/create-assignment/CreateAssignment"
import { ListAssignment } from "views/assignments/pages/list-assignment/ListAssignment"
import { AssignmentDetailsWrapper } from "views/assignments/pages/manage-assignment/AssignmentDetailsWrapper"
import { Helmet } from "react-helmet"
import ClientPortalConfigurationWrapper from "./client-portal-configuration/ClientPortalConfigurationWrapper"
import { CustomSwitch } from "setup/router/CustomSwitch"

export const Assignments = () => {
  const path = useResolvedPath("").pathname

  return (
    <CustomSwitch>
      <Route
        path={path}
        element={
          <>
            <h1>{messages.assignment.title}</h1>
            <Navigation>
              <NavigationItem>
                <RouterLink
                  data-e2e-target="link"
                  data-e2e-target-name="add-assignment"
                  to={RouterUrl.AssignmentCreate}
                >
                  {messages.navigation.links.addAssignment}
                </RouterLink>
              </NavigationItem>
            </Navigation>
          </>
        }
      ></Route>
      <Route
        path={RouterSubUrl.AssignmentCreate}
        element={
          <>
            <Helmet>
              <title>
                {messages.seo.title.format(messages.seo.addNewAssignment)}
              </title>
            </Helmet>
            <CreateAssignment />
          </>
        }
      />
      <Route
        path={RouterSubUrl.AssignmentList}
        element={
          <>
            <Helmet>
              <title>
                {messages.seo.title.format(messages.seo.assignments)}
              </title>
            </Helmet>
            <ListAssignment />
          </>
        }
      />

      <Route
        path={`${RouterSubUrl.AssignmentDetails}/:assignmentId`}
        element={<AssignmentDetailsWrapper />}
      />
      <Route
        path={RouterSubUrl.AssignmentPortalConfig}
        element={<ClientPortalConfigurationWrapper />}
      />
    </CustomSwitch>
  )
}
