import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { CustomBodyTexts, DataPrivacySubHeaders } from "./style"
import moment from "moment"
import DisplayComponent from "./DisplayComponent"

const PersonDataPrivacyView = () => {
  const { profilePrivacy: gdprLawfulBasisState } = usePerson()

  const {
    gdprDataOrigin = "",
    gdprLawfulBasisOption,
    gdprLawfulBasisOptionsStatus,
    gdprExpiration,
    gdprComment,
    gdprUpdated
  } = gdprLawfulBasisState || {}

  const isGdprLawful = gdprLawfulBasisOption !== "none"

  const gdprOptions = messages.person.consents.form.options.basis
  const gdprStatusOptions = messages.person.consents.form.options.status

  const infoItems = [
    {
      label: messages.person.consents.form.statusLabel,
      text:
        gdprLawfulBasisOptionsStatus &&
        gdprStatusOptions[gdprLawfulBasisOptionsStatus],
      condition: gdprLawfulBasisOptionsStatus
    },
    {
      label: messages.person.consents.form.dataOriginLabel,
      text: gdprDataOrigin,
      condition: gdprDataOrigin
    },
    {
      label: messages.person.consents.form.gdprComment,
      text: gdprComment,
      condition: gdprComment
    },
    {
      label: messages.person.consents.form.gdprExpiration,
      text: moment(gdprExpiration).format("DD-MM-YYYY"),
      condition: gdprExpiration
    },
    {
      label: messages.person.consents.updatedDate,
      text: moment(gdprUpdated).format("DD-MM-YYYY"),
      condition: gdprUpdated
    }
  ]

  return (
    <Flex flexWrap="wrap" py="xs">
      <Flex>
        <DataPrivacySubHeaders>
          {messages.person.consents.form.gdprLawfulBasis}
        </DataPrivacySubHeaders>
        <CustomBodyTexts>
          {gdprOptions[isGdprLawful ? gdprLawfulBasisOption! : "notSet"]}
        </CustomBodyTexts>
      </Flex>

      {infoItems
        .filter((item) => item.condition)
        .map((item, index) => (
          <DisplayComponent key={index} label={item.label} text={item.text} />
        ))}
    </Flex>
  )
}

export default PersonDataPrivacyView
