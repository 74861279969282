import styled from "styled-components"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { ScrollMenu } from "react-horizontal-scrolling-menu"

interface TabContainerProps {
  isSelected: boolean
  width: string
}

export const TabContainer = styled(Flex)<TabContainerProps>`
  flex-direction: column;
  background: ${({ theme }) => theme.colors?.grey?.light};
  align-items: center;
  width: ${({ width }) => width};
  max-height: 48px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.green.standard : theme.colors?.grey?.light};
`

export const TabContainerRow = styled(Flex)<TabContainerProps>`
  flex-direction: row-reverse;
  background: ${({ theme }) => theme.colors?.grey?.light};
  align-items: center;
  width: ${({ width }) => width};
  max-height: 36px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  justify-content: center;
  border-bottom: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.green.standard : theme.colors?.white?.standard};
  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const TabsWrapper = styled(Div)`
  min-width: 100%;
  height: 38px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors?.white?.standard};

  .react-horizontal-scrolling-menu--wrapper {
    height: 38px !important;
  }
`

export const ScrollMenuContainer = styled(ScrollMenu)`
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`
