import groupBy from "lodash/groupBy"
import get from "lodash/get"
import { Candidate } from "views/candidates/candidates.types"
import { CandidateFilter, GroupedCandidates } from "./assignment-module.types"
import isEmpty from "lodash/isEmpty"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import {
  FilterCondition,
  FilterValue
} from "../components/candidates-list/components/candidate-filters/filters/definitions"
import moment from "moment"

export const groupCandidates = (candidates: Candidate[]) =>
  groupBy(candidates, "interviewProgressState.stage") as GroupedCandidates

export const saveCandidatesFiltersToSessionStorage = (
  filters: CandidateFilter[],
  assignmentId: string
) => {
  if (!assignmentId) return

  if (isEmpty(filters)) {
    sessionStorage.removeItem(sessionStorageKeys.previousCandidatesFilters)
    return
  }

  try {
    const oldFilters = getCandidatesFiltersFromSessionStorage()

    const newFilters = {
      ...oldFilters,
      [assignmentId]: filters
    }

    const filtersJson = JSON.stringify(newFilters)
    sessionStorage.setItem(
      sessionStorageKeys.previousCandidatesFilters,
      filtersJson
    )
  } catch (eror) {
    console.error("Could not save candidates filters")
  }
}

export const getCandidatesFiltersFromSessionStorage = (): Record<
  string,
  CandidateFilter[]
> => {
  const filtersJson = sessionStorage.getItem(
    sessionStorageKeys.previousCandidatesFilters
  )

  if (!filtersJson) {
    return {}
  }

  let filters = {}

  try {
    filters = JSON.parse(filtersJson)
  } catch (error) {
    console.error("Could not load candidates filters")
  }

  return filters
}

export const hasCandidatesFiltersInSessionStorage = () => {
  const filters = getCandidatesFiltersFromSessionStorage()
  return filters && !isEmpty(filters)
}

export const compareFilter = (
  filterCondition: FilterCondition,
  filterConditionValue: FilterValue,
  usersValue: FilterValue
) => {
  switch (filterCondition) {
    case "lte":
      return moment(usersValue as Date).isSameOrBefore(
        moment(filterConditionValue as Date),
        "day"
      )
    case "gte":
      return moment(usersValue as Date).isSameOrAfter(
        moment(filterConditionValue as Date),
        "day"
      )
    case "exists":
      return !!usersValue === filterConditionValue
    case "ne":
      return filterConditionValue !== usersValue
    case "tags":
      return (usersValue as number[]).includes(filterConditionValue as number)
    case "noTags":
      return !(usersValue as number[]).length
    case "eq":
    default:
      return filterConditionValue === usersValue
  }
}

const andPropertyFilterNames = ["tags"]

const andOrSetting = (candidateProperty: string): "every" | "some" => {
  return andPropertyFilterNames.includes(candidateProperty) ? "every" : "some"
}

const iterateAndOrFilter = (
  filtersGroup: CandidateFilter[],
  filerGroupKey: string
) => {
  return filtersGroup[andOrSetting(filerGroupKey)].bind(filtersGroup)
}

export const filterCandidates = (
  candidates: Candidate[],
  appliedFilters: CandidateFilter[]
) => {
  if (appliedFilters.length === 0) {
    return candidates
  }

  const groupedFilters = appliedFilters.reduce(
    (prev, current) => ({
      ...prev,
      [current.candidateProperty]: [
        ...(prev?.[current.candidateProperty] || []),
        current
      ]
    }),
    {} as Record<string, Array<CandidateFilter>>
  )

  return candidates.filter((candidate) =>
    Object.entries(groupedFilters).every(([filerGroupKey, filtersGroup]) =>
      iterateAndOrFilter(
        filtersGroup,
        filerGroupKey
      )((appliedFilter) => {
        const candidateValue = get(
          candidate,
          appliedFilter.candidateProperty,
          null
        )

        return Object.entries(appliedFilter.value).every(
          ([filterCondition, value]) =>
            compareFilter(
              filterCondition as FilterCondition,
              value as FilterValue,
              candidateValue
            )
        )
      })
    )
  )
}
