import React from "react"
import { MultiValueProps, components } from "react-select"
import { TagWrapper } from "@ikiru/talentis-fpc"

const multiValueContainerE2eTarget = "multivalue-item"

export const MultiValueContainer = (props: MultiValueProps<any>) => {
  const {
    data: { "data-e2e-target-name": e2eTargetName }
  } = props

  return (
    <TagWrapper
      variant="grey"
      m="none"
      data-e2e-target={multiValueContainerE2eTarget}
      data-e2e-target-name={e2eTargetName}
    >
      <components.MultiValueContainer {...props} />
    </TagWrapper>
  )
}
