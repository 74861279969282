import React from "react"
import get from "lodash/get"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Flex, Div, CrossIcon, Button } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { KEYWORDS_LIMIT } from "views/search/SearchModule/consts"
import { useSearch } from "views/search/SearchModule/context"
import { SearchCriteriaField } from "../definitions"
import { useNestedArrayFieldChange } from "../hooks"
import { LogicSelector } from "./LogicSelector"
import { keywordsTemplateString, initialTemplateString } from "./consts"
import { useMultiBoxes } from "../components/hooks"
import { FilterType } from "views/search/SearchModule/types"

type IProps = {
  index: number
  boxesCount: number
}

export const KeywordsBox = ({ index, boxesCount }: IProps) => {
  const { filters, removeArrayBasedFilter, clearArrayBasedFilter } = useSearch()
  const { removeBox } = useMultiBoxes(
    SearchCriteriaField.KeywordBundle,
    FilterType.KeywordBundle
  )

  const filterName = keywordsTemplateString.format(index)
  const initialFilterName = initialTemplateString.format(index)
  const fieldName = SearchCriteriaField.Keyword.format(index)

  const onBlur = useNestedArrayFieldChange({
    field: fieldName,
    filterName,
    limit: KEYWORDS_LIMIT,
    isOnBlur: true,
    index: index
  })

  const onKeyPress = useNestedArrayFieldChange({
    field: fieldName,
    filterName,
    limit: KEYWORDS_LIMIT,
    index: index
  })

  const currentKeywords = get(filters, filterName, [])

  const CloseButton = () => {
    if (boxesCount > 1) {
      return (
        <Flex pb="xxs" justifyContent="flex-end">
          <Button
            mode="standard-white"
            size="action-small"
            onClick={() => {
              clearArrayBasedFilter(initialFilterName)
              removeBox(index)
            }}
          >
            <CrossIcon />
          </Button>
        </Flex>
      )
    } else {
      return null
    }
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Div width="100%">
        <CloseButton />
        <FormikInput
          name={fieldName}
          label={messages.talentGraphSearch.filters.keywords}
          placeholder={messages.talentGraphSearch.placeholders.keywords}
          disableDefaultOnBlur
          autoFocus={index !== 0}
          {...{
            onKeyPress: onKeyPress as (
              e: React.KeyboardEvent<HTMLInputElement>
            ) => void,
            onBlur: onBlur as (e: React.FocusEvent<HTMLInputElement>) => void
          }}
        />
        <KeywordsList
          keywords={currentKeywords}
          onKeywordRemove={(value) => removeArrayBasedFilter(filterName, value)}
        />
      </Div>
      <LogicSelector {...{ index }} />
    </Flex>
  )
}
