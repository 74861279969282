import { StyledSystemProps, Div, colors, Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { hexToRGB } from "utils/hexToRGB"

type PersonDetailsWrapperProps = {
  isOffLimits?: boolean
}

export const PersonDetailsWrapper = styled(Div)<
  StyledSystemProps & PersonDetailsWrapperProps
>`
  width: 100%;
  border: 0.5px solid ${colors.grey.light};
  background-color: ${colors.grey.lightest};
  background-color: ${({ theme, isOffLimits }) =>
    isOffLimits
      ? hexToRGB(theme.colors.red.lightest, 0.7)
      : `${theme.colors.grey.lightest};`};

  padding: ${({ theme }) => theme.space.s}px;
`

export const LinkContainerStyled = styled(Flex)`
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: ${colors.green.lightest};
  flex-direction: column;
  position: relative;
`

export const OffLimitsContainerStyled = styled(Flex)`
  margin-right: -30px;
  padding-right: 30px;
  margin-top: 0px;
`
