import moment from "moment"

export const defaultTimeFormat = "h:mmA"
export const defaultDateFormat = "DD/MM/YYYY"

export const parseMomentDate = (date?: any) => {
  if (date) {
    return moment(date).toDate()
  }
  return date
}
