import { noop } from "lodash"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import {
  TeamState,
  TeamActionTypes,
  TeamAction,
  TeamMember
} from "./team-module.types"
import { TeamContextValues } from "./team-module.types"

export const initialTeamState: TeamState = {
  isLoading: false,
  teamMembers: [],
  invited: [],
  inactiveMembers: [],
  invitationsResent: {},
  selectedTeamMember: undefined
}

export const initialTeamContextValues: TeamContextValues = {
  ...initialTeamState,
  amIOwner: false,
  possibleRoles: [],
  user: undefined,
  activeOwnersCount: 0,
  toggleActive: noop,
  fetchTeamMembers: noop,
  setTeamMembers: noop,
  onMemberRoleChange: noop,
  onResendInvitationLink: noop,
  onRemoveInvitationLink: noop
}

export const teamReducer = (state: TeamState, action: TeamAction) => {
  const { type, payload } = action

  switch (type) {
    case TeamActionTypes.SetIsLoading:
      return {
        ...state,
        isLoading: payload
      }
    case TeamActionTypes.SetTeamMembers:
      return {
        ...state,
        teamMembers: payload?.teamMembers || [],
        invited: payload?.invited || [],
        inactiveMembers: payload?.inactiveMembers || [],
        invitationsResent: payload?.invitationsResent || {}
      }
    case TeamActionTypes.AddInvitationResent:
      const invited = state.invited.map((member) => {
        if (member.id !== payload) {
          return member
        } else {
          return {
            ...member,
            newInvite: false
          }
        }
      })
      return {
        ...state,
        invited,
        invitationsResent: { ...state.invitationsResent, [payload]: true }
      }
    case TeamActionTypes.UpdateTeamMemberRole:
      return {
        ...state,
        teamMembers: updateUserRole(
          payload?.teamMemberId,
          payload?.userRole,
          state?.teamMembers
        ),
        invited: updateUserRole(
          payload?.teamMemberId,
          payload?.userRole,
          state?.invited
        ),
        inactiveMembers: updateUserRole(
          payload?.teamMemberId,
          payload?.userRole,
          state?.inactiveMembers
        )
      }
    case TeamActionTypes.SetSelectedTeamMember:
      return {
        ...state,
        selectedTeamMember: payload
      }
    default:
      return state
  }
}

const updateUserRole = (
  id: string,
  userRole: UserRoles,
  members: TeamMember[]
): TeamMember[] =>
  members.map((member) => (member.id === id ? { ...member, userRole } : member))
