import React, { useState } from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import {
  AssignmentPrimaryData,
  assignmentPrimaryDataInitialValues
} from "views/assignments/components/assignment-data/constants/definitions"
import { AssignmentPrimaryDataValidationSchema } from "views/assignments/components/assignment-data/constants/schema"
import { getChangedValues, getUpdatedName } from "views/assignments/utils"
import { AssignmentDataFormLayout } from "../../form/layout"
import { Button, Div, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "./style"
import { CompanySelected } from "views/assignments/components/assignment-company/types"
import {
  AssignmentTeamUpdate,
  AssignmentTeamMember,
  Location
} from "views/assignments/assignment.types"
import { TeamMember } from "views/team/team/team-module.types"
import { AzureLocationInitialValue } from "components/AzureLocation/definition"
import { ProjectTeamForm } from "components/ProjectTeamMembers/form/ProjectTeamForm"

type AssignmentDataFormProps = {
  onSubmit: (
    values: AssignmentPrimaryData,
    helpers: FormikHelpers<AssignmentPrimaryData>
  ) => void
  initialValues: AssignmentPrimaryData
  formActionsComponent?: (
    formikProps: FormikProps<AssignmentPrimaryData>
  ) => JSX.Element
  assignmentTeamMembers: AssignmentTeamMember[]
  teamMembers: TeamMember[]
}

export const EditAssignData = ({
  onSubmit,
  initialValues,
  formActionsComponent,
  assignmentTeamMembers,
  teamMembers
}: AssignmentDataFormProps) => {
  const { close } = useModal()

  const [companyValue, setCompanyValue] = useState<CompanySelected>({
    id: initialValues?.companyId || "",
    name: initialValues?.companyName || ""
  })
  const [location, setLocation] = useState<Location>({
    label: initialValues?.location || "",
    value: initialValues?.geoLocation || AzureLocationInitialValue
  })

  const [selectedMembers, setSelectedMembers] = useState<
    AssignmentTeamUpdate[]
  >([])

  const submitForm = (
    values: AssignmentPrimaryData,
    action: FormikHelpers<AssignmentPrimaryData>
  ) => {
    values.companyName = companyValue.name

    if (companyValue.id) {
      values.companyId = companyValue.id
    } else {
      values.companyId = undefined
    }

    if (location.label !== "") {
      values.location = location.label.trim()
      values.geoLocation = location?.value
    }

    let changeValues = getChangedValues(values, initialValues)
    const updatedName = getUpdatedName(values, initialValues!, changeValues)

    if (updatedName !== values?.name) {
      changeValues.name = updatedName
    }

    changeValues = {
      ...changeValues,
      ...(selectedMembers.length && { teamMembers: [...selectedMembers] })
    }

    onSubmit(changeValues, action)
    close(ModalNames.EditAssignData)
  }

  return (
    <StyledDiv width={["100%", "100%", "700px"]}>
      <Formik
        initialValues={{
          ...assignmentPrimaryDataInitialValues,
          ...initialValues,
          ...(initialValues?.endDate
            ? { endDate: new Date(initialValues?.endDate) }
            : { endDate: null })
        }}
        validationSchema={AssignmentPrimaryDataValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form>
            <ModalSectionHeader
              size="xSmall"
              title={messages.assignment.assignmentEditTitle}
              actions={
                <>
                  <Button
                    mx="xxs"
                    my="0"
                    type="submit"
                    size="small"
                    disabled={!formik.isValid}
                  >
                    {messages.form.generic.save}
                  </Button>
                  <Button
                    mode="secondary"
                    mx="xxs"
                    my="0"
                    size="small"
                    onClick={() => close(ModalNames.EditAssignData)}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
            <AssignmentDataFormLayout
              companyValue={companyValue}
              setCompanyValue={setCompanyValue}
              setLocation={setLocation}
              locationValue={location}
            />
            <Div width="350px">
              <ProjectTeamForm
                projectTeamMembers={assignmentTeamMembers}
                setSelectedMembers={setSelectedMembers}
                teamMembers={teamMembers}
              />
            </Div>
            {formActionsComponent?.(formik)}
          </Form>
        )}
      </Formik>
    </StyledDiv>
  )
}
