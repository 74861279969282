import React from "react"
import { Textarea, TextareaProps, Div, Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"
import StyledMicrophone from "./Microphone"

type FormikTextareaProps = TextareaProps & {
  name: Pick<TextareaProps, "name">
  label?: React.ReactChild
  isMicrophone?: boolean
}

const e2eTarget = "form-input"

export const FormikTextarea = React.forwardRef(
  (props: FormikTextareaProps, ref) => {
    const {
      label,
      name,
      onBlur: explicitOnBlur,
      resize = "vertical",
      onKeyDown,
      isMicrophone = false,
      ...otherExplicitProps
    } = props
    const [formikProps, meta, helpers] = useField(name as string)
    const { setValue } = helpers
    const { onBlur: formikDefaultOnBlur, ...otherFormikProps } = formikProps

    const status = useStatus(meta)
    const shouldDisplayError = useShouldDisplayError(meta)

    return (
      <Div>
        {isMicrophone ? (
          <StyledMicrophone
            value={meta.value as string}
            setValue={setValue}
            isOneLine={props.rows === 1}
          >
            {(onUserTyping) => (
              <Textarea
                ref={ref}
                label={label}
                data-e2e-target={e2eTarget}
                status={status}
                onBlur={(event) => {
                  formikDefaultOnBlur(event)
                  explicitOnBlur?.(event)
                }}
                resize={resize}
                {...otherFormikProps}
                {...otherExplicitProps}
                onKeyDown={(event) => {
                  onKeyDown?.(event)
                  onUserTyping()
                }}
              />
            )}
          </StyledMicrophone>
        ) : (
          <Textarea
            ref={ref}
            label={label}
            data-e2e-target={e2eTarget}
            status={status}
            onBlur={(event) => {
              formikDefaultOnBlur(event)
              explicitOnBlur?.(event)
            }}
            resize={resize}
            {...otherFormikProps}
            {...otherExplicitProps}
            onKeyDown={(event) => {
              onKeyDown?.(event)
            }}
          />
        )}

        {shouldDisplayError && <Error>{meta.error}</Error>}
      </Div>
    )
  }
)
