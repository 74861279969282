import React, { useCallback, useState } from "react"
import { messages } from "setup/messages/messages"
import { Button } from "@ikiru/talentis-fpc"
import { Tag } from "@ikiru/talentis-fpc"
import { TaskFilterPopup } from "./TaskFilterPopup"
import { Flex } from "@ikiru/talentis-fpc"
import { tagColor } from "views/assignments/components/candidates-list/helpers"
import { useTaskManagement } from "../../../../../homepage/task-management-module/task-management-module.context"
import { CandidateFilter } from "../../../../assignment-module/assignment-module.types"
import { useTaskFilter } from "./useTaskFilter"
import { StyledButton } from "./style"

export const TaskCandidateFilters = () => {
  const { appliedFilters, tasks, updateFilters } = useTaskManagement()
  const { removeFilter } = useTaskFilter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onMenuOpen = useCallback(() => setIsMenuOpen(true), [setIsMenuOpen])

  const onMenuClose = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen])

  const handleRefresh = () => {
    setIsMenuOpen(false)

    updateFilters(appliedFilters, true)
  }

  return (
    <>
      <Flex alignItems="center" mt="-15px" flexWrap="wrap">
        {tasks.length > 0 && (
          <TaskFilterPopup
            onOpen={onMenuOpen}
            onClose={onMenuClose}
            trigger={
              <Button
                size="small"
                borderColor={isMenuOpen ? "grey.dark" : "grey.standard"}
                borderWidth="2px"
                borderStyle="solid"
                mode={isMenuOpen ? "standard-grey" : "standard-white"}
                colorway={
                  isMenuOpen ? { group: "grey", variant: "dark" } : undefined
                }
                mr="xs"
                mt="xs"
              >
                + {messages.assignment.filters.filter}
              </Button>
            }
          />
        )}
        {appliedFilters.map((filter: CandidateFilter) => (
          <Tag
            variant={tagColor(filter?.variant)}
            height={24}
            mt="xs"
            mb="0"
            key={filter.label}
            onRemoveClick={() => removeFilter(filter)}
          >
            {filter.label}
          </Tag>
        ))}
      </Flex>
      {appliedFilters.length > 0 && (
        <StyledButton
          mode="standard-white"
          size="small"
          ml="xs"
          onClick={handleRefresh}
        >
          {messages.form.generic.refresh}
        </StyledButton>
      )}
    </>
  )
}
