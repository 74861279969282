export enum PersonKeywordsFields {
  AddKeyword = "keyword"
}

export type PersonExperienceValues = {
  [PersonKeywordsFields.AddKeyword]: string
}

export const personKeywordsInitialValues = {
  [PersonKeywordsFields.AddKeyword]: ""
}
