import React, { useCallback, useEffect } from "react"
import { useFormikContext } from "formik"
import { debounce } from "ts-debounce"
import { isEqual } from "lodash"

type AutoSaveProps = {
  debounceMs: number
  children: React.ReactElement
}

export const AutoSave = (props: AutoSaveProps) => {
  const { debounceMs, children } = props
  const formik = useFormikContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(debounce(formik.submitForm, debounceMs), [
    debounceMs,
    formik.submitForm
  ])

  useEffect(() => {
    if (!isEqual(formik.initialValues, formik.values)) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, formik.values, formik.initialValues])

  return children
}
