import React from "react"
import { OffLimitsStyled, StyledOfflimits } from "./style"
import { StyledSystemProps } from "@ikiru/talentis-fpc"

type OffLimitsProps = StyledSystemProps & {
  children: React.ReactNode
  isSmall?: boolean
  isCircle?: boolean
  isHorizontal?: boolean
  isLeftCircle?: boolean
  isRightCircle?: boolean
  isTopRightCircle?: boolean
}

export const OffLimits = ({
  children,
  isSmall = false,
  isCircle = false,
  isHorizontal = false,
  isLeftCircle = false,
  isRightCircle = false,
  isTopRightCircle = false,
  ...styledProps
}: OffLimitsProps) => {
  return (
    <StyledOfflimits
      isCircle={isCircle}
      isLeftCircle={isLeftCircle}
      isRightCircle={isRightCircle}
      isTopRightCircle={isTopRightCircle}
      {...styledProps}
    >
      <OffLimitsStyled isSmall={isSmall} isHorizontal={isHorizontal}>
        {children}
      </OffLimitsStyled>
    </StyledOfflimits>
  )
}
