import { useState } from "react"
import { apiRequest } from "setup/api/api"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

const useCampaignTemplates = () => {
  const { campaignId, setCampaignAITemplates } = useCampaign()
  const [isLoading, setIsLoading] = useState(false)
  const getAiTemplates = async () => {
    const [, response] = await apiRequest.get({
      endpoint: CampaignsEndpoints.CampaignAiTemplate,
      endpointParams: campaignId
    })

    setCampaignAITemplates(response?.data)
  }

  const generateAITemplates = async (
    format: string,
    language: string,
    coreMessage: string
  ) => {
    setIsLoading(true)
    const [, response] = await apiRequest.post({
      endpoint: CampaignsEndpoints.CampaignAiTemplate,
      endpointParams: campaignId,
      data: {
        format,
        language,
        coreMessage
      }
    })

    let newAITemplates: any = response?.data

    setCampaignAITemplates(newAITemplates)
    setIsLoading(false)
  }

  const updateAiTemplate = async (data: any) => {
    setIsLoading(true)
    const [, response] = await apiRequest.put({
      endpoint: CampaignsEndpoints.CampaignAiTemplate,
      endpointParams: campaignId,
      data: data
    })

    let newAITemplates: any = response?.data

    setCampaignAITemplates(newAITemplates)
    setIsLoading(false)
  }

  return { getAiTemplates, generateAITemplates, updateAiTemplate, isLoading }
}

export default useCampaignTemplates
