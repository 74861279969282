import { PhoneDataType, TaggedEmail } from "views/persons/person.types"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import {
  EmailTypeEnums,
  PhoneTypeEnums,
  emailUsageSortArr,
  phoneUsageSortArr
} from "../person-details-tab/components/person-contact-info/form/constants/definitions"

export const sortProfileDetails = (profileDetails: PersonProfileDetails) => {
  const sortedPhoneDetails = profileDetails.phones
    .sort(
      (x, y) =>
        phoneUsageSortArr.indexOf(x.value.phoneUsageType + "") -
        phoneUsageSortArr.indexOf(y.value.phoneUsageType + "")
    )
    .sort((x, y) =>
      x.value.isPreferred === y.value.isPreferred
        ? 0
        : x.value.isPreferred
        ? -1
        : 1
    )

  const sortedEmailDetails = profileDetails.taggedEmails
    .sort(
      (x, y) =>
        emailUsageSortArr.indexOf(x.value.type + "") -
        emailUsageSortArr.indexOf(y.value.type + "")
    )
    .sort((x, y) =>
      x.value.isPreferred === y.value.isPreferred
        ? 0
        : x.value.isPreferred
        ? -1
        : 1
    )

  const unsortedProfileDetails = profileDetails
  unsortedProfileDetails.phones = sortedPhoneDetails
  unsortedProfileDetails.taggedEmails = sortedEmailDetails
  return unsortedProfileDetails
}

export const PhoneUsageTypeNumExchanger = (phoneData: PhoneDataType) => {
  switch (phoneData.phoneUsageType) {
    case PhoneTypeEnums.Other:
      phoneData.phoneUsageType = 0
      break
    case PhoneTypeEnums.Personal:
      phoneData.phoneUsageType = 1
      break
    case PhoneTypeEnums.Business:
      phoneData.phoneUsageType = 2
      break
    case PhoneTypeEnums.BusinessDirect:
      phoneData.phoneUsageType = 3
      break
  }
  return phoneData
}

export const emailTypeNumExchanger = (emailData: TaggedEmail) => {
  switch (emailData.type) {
    case EmailTypeEnums.Other:
      emailData.type = 0
      break
    case EmailTypeEnums.Personal:
      emailData.type = 1
      break
    case EmailTypeEnums.Business:
      emailData.type = 2
      break
  }
  return emailData
}
