import React, { useEffect } from "react"
import { messages } from "setup/messages/messages"
import { Div, H4, Toggle } from "@ikiru/talentis-fpc"
import { FilterType } from "views/search/SearchModule/types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { useCheckboxField, useMandatoryFiltersWereSet } from "../hooks"
import { SearchCriteriaField } from "../definitions"
import { useSearch } from "views/search/SearchModule/context"
import { InsightsEventName } from "setup/app-insights/definitions"
import { useAppInsights } from "setup/app-insights/Context"
import { useAuth } from "setup/auth/module/auth.context"

export const BoostFemaleFilter = () => {
  const { filters, clearFilters, isAnonymized, setIsAnonymized } = useSearch()
  const { trackEvent } = useAppInsights()
  const { user } = useAuth()
  const mandatoryFiltersWereSet = useMandatoryFiltersWereSet()
  const checkboxFieldProps = useCheckboxField(FilterType.BoostFemale)

  useEffect(() => {
    if (!mandatoryFiltersWereSet && filters[SearchCriteriaField.BoostFemale]) {
      clearFilters({ [SearchCriteriaField.BoostFemale]: undefined })
    }
  }, [filters, clearFilters, mandatoryFiltersWereSet])

  return (
    <Div width="100%">
      <H4 mt="0px" mb="xxs" color="grey.dark">
        {messages.talentGraphSearch.filters.diversity}
      </H4>
      <FormikToggle
        small
        id={SearchCriteriaField.BoostFemale}
        name={SearchCriteriaField.BoostFemale}
        type="checkbox"
        hideUnderline={true}
        disabled={!mandatoryFiltersWereSet}
        notCheckedFade
        wrapperProps={{ mb: "none" }}
        labelProps={{ ml: "xxs" }}
        label={messages.talentGraphSearch.filters.boostFemale}
        {...checkboxFieldProps}
      />
      <Toggle
        small
        hideUnderline
        notCheckedFade
        wrapperProps={{ mb: "none" }}
        labelProps={{ ml: "xxs" }}
        type="checkbox"
        checked={isAnonymized}
        disabled={!mandatoryFiltersWereSet}
        label={messages.talentGraphSearch.filters.anonymize}
        onChange={() => {
          if (!isAnonymized) {
            trackEvent(
              { name: InsightsEventName.TurnOnAnonymousSearch },
              {
                searchFirmId: user?.profile?.SearchFirmId,
                userId: user?.profile?.UserId
              }
            )
          }
          setIsAnonymized(!isAnonymized)
        }}
      />
    </Div>
  )
}
