import React, { useCallback } from "react"
import { pick } from "lodash"
import { CampaignPrimaryData, CampaignDataField } from "./constants/definitions"
import { Campaign } from "views/campaigns/campaign.types"
import { patchCampaign } from "views/campaigns/actions"
import { Button, Div, PencilIcon, Section } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { CampaignDataView } from "./CampaignDataView"
import { messages } from "setup/messages/messages"
import { EditCampaignData } from "./components/CampaignDataModal/EditCampaignData"

type CampaignDataProps = Campaign & {
  onCampaignUpdated: (campaign: Campaign) => void
}

export const CampaignData = React.memo(
  ({ onCampaignUpdated, ...data }: CampaignDataProps) => {
    const campaignData: CampaignPrimaryData = pick(
      data,
      Object.values(CampaignDataField)
    )

    const { open } = useModal()
    const onSubmit = patchCampaign<CampaignPrimaryData>(data, (campaign) => {
      onCampaignUpdated(campaign)
    })

    const campaignOpenModal = useCallback(() => {
      open(
        ModalNames.EditCampaignData,
        <EditCampaignData
          initialValues={campaignData}
          onSubmit={onSubmit}
          campaignTeamMembers={[]}
          teamMembers={[]}
        />
      )
    }, [campaignData, onSubmit, open])

    return (
      <Section
        size="xxSmall"
        header={{
          title: messages.campaign.title,
          actions: (
            <Button
              mode="standard-white"
              size="action-medium"
              onClick={campaignOpenModal}
            >
              <PencilIcon color="red" />
            </Button>
          )
        }}
      >
        <Div py="s">
          <CampaignDataView campaignId={data.id} {...campaignData} />
        </Div>
      </Section>
    )
  }
)
