import styled from "styled-components"
import { Flex, colors, spacing } from "@ikiru/talentis-fpc"

const modalStyles = `
  flex-direction: column;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
`

export const HintKeywordsModalWrapper = styled(Flex)`
  ${modalStyles}
  width: 782px;
`

export const HintJobtitlesModalWrapper = styled(Flex)`
  ${modalStyles}
  width: 855px;
`
