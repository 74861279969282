import React from "react"
import { Div, fontFamilies } from "@ikiru/talentis-fpc"
import { Tag } from "components/Tags/tags.types"
import { StyledDiv } from "./style"

interface TagListProps {
  tags: Tag[]
}

export const TagList = ({ tags = [] }: TagListProps) => {
  const tagList = tags.map((tag) => {
    return (
      <StyledDiv
        width="190px"
        borderRadius="5px"
        mb="xs"
        p="xs"
        color={tag.color}
        bg={tag.background}
        fontFamily={fontFamilies.gibson}
        fontSize={13}
        fontWeight={500}
        key={tag.id}
      >
        {tag.name}
      </StyledDiv>
    )
  })

  return <Div>{tagList}</Div>
}
