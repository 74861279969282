import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { CreateCampaignValues } from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.definitions"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { FormAction } from "utils/forms/form.types"

export type OnCampaignDetailsSubmit = FormAction<CreateCampaignValues>

type ApiRequestType = (
  values: CreateCampaignValues
) => Promise<NormalizedReturnTuple>

const onUpdate =
  (request: ApiRequestType) =>
  async (
    values: CreateCampaignValues,
    helpers: FormikHelpers<CreateCampaignValues>
  ) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const onCreateCampaignSubmit = onUpdate((data) =>
  apiRequest.post({
    endpoint: CampaignsEndpoints.Root,
    data
  })
)
