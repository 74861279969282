type filterValuetype = string | boolean | object | number | undefined

export const filterListNotEmptyValues = (array: filterValuetype[]) =>
  array.map((filter: filterValuetype) => {
    switch (typeof filter) {
      case "string":
        return Boolean(filter)
      case "boolean":
        return filter
      case "object":
        if (Array.isArray(filter)) {
          if (filter[0] && typeof filter[0] === "object") {
            const filterInnerArray: any = Object.values(filter[0])[0]
            return filterInnerArray.length > 0
          }
          return filter.length > 0
        } else {
          const filterObjectEmptyCheck: boolean[] = filterListNotEmptyValues(
            Object.values(filter)
          )
          return filterObjectEmptyCheck.includes(true)
        }
      default:
        return false
    }
  })
