import React, { useState } from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import { getChangedValues } from "views/assignments/utils"
import { Button, Div, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "./style"
import {
  ProjectTeamMember,
  ProjectTeamUpdate
} from "components/ProjectTeamMembers/types"
import { ProjectTeamForm } from "components/ProjectTeamMembers/form/ProjectTeamForm"
import {
  CompanyPrimaryData,
  companyPrimaryDataInitialValues
} from "../constants/definitions"
import { CompanyPrimaryDataValidationSchema } from "../constants/schema"
import { TeamMember } from "views/team/team/team-module.types"
import { CompanyDataForm } from "./CompanyDataForm"
import { SpecialtiesForm } from "./SpecialtiesForm"

type EditCompanyDataProps = {
  onSubmit: (
    values: CompanyPrimaryData,
    helpers: FormikHelpers<CompanyPrimaryData>
  ) => void
  initialValues?: CompanyPrimaryData
  formActionsComponent?: (
    formikProps: FormikProps<CompanyPrimaryData>
  ) => JSX.Element
  projectTeamMembers: ProjectTeamMember[]
  speciality: string
  teamMembers: TeamMember[]
}

export const EditCompanyData = ({
  onSubmit,
  initialValues,
  formActionsComponent,
  projectTeamMembers,
  speciality,
  teamMembers
}: EditCompanyDataProps) => {
  const { close } = useModal()

  const [selectedMembers, setSelectedMembers] = useState<ProjectTeamUpdate[]>(
    []
  )

  const [specialityNew, setSpecialityList] = useState("")

  const submitForm = (
    values: CompanyPrimaryData,
    action: FormikHelpers<CompanyPrimaryData>
  ) => {
    let changeValues = getChangedValues(values, initialValues)
    changeValues = {
      ...changeValues,
      ...(selectedMembers.length && { teamMembers: [...selectedMembers] }),
      ...(Boolean(specialityNew) && { speciality: specialityNew })
    }

    onSubmit(changeValues, action)
    close(ModalNames.EditCompanyDetails)
  }

  return (
    <StyledDiv width={["100%", "100%", "755px"]}>
      <Formik
        initialValues={{
          ...companyPrimaryDataInitialValues,
          ...initialValues
        }}
        validationSchema={CompanyPrimaryDataValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form>
            <ModalSectionHeader
              title={messages.companies.companyDetailsEdit}
              size="xSmall"
              actions={
                <>
                  <Button mx="xxs" my="0" type="submit" size="small">
                    {messages.form.generic.save}
                  </Button>
                  <Button
                    mode="standard-white"
                    mx="xxs"
                    my="0"
                    size="small"
                    onClick={() => close(ModalNames.EditCompanyDetails)}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
            <CompanyDataForm />
            <SpecialtiesForm
              speciality={speciality}
              updateSpeciality={(speciality) => setSpecialityList(speciality)}
            />
            <Div width="350px">
              <ProjectTeamForm
                projectTeamMembers={projectTeamMembers}
                setSelectedMembers={setSelectedMembers}
                teamMembers={teamMembers}
                title={messages.companies.companyTeamMembers}
              />
            </Div>
            {formActionsComponent?.(formik)}
          </Form>
        )}
      </Formik>
    </StyledDiv>
  )
}
