import React, { useEffect } from "react"
import { messages } from "setup/messages/messages"
import { Div } from "@ikiru/talentis-fpc"
import { FilterType } from "views/search/SearchModule/types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { useCheckboxField, useMandatoryFiltersWereSet } from "../hooks"
import { SearchCriteriaField } from "../definitions"
import { H5Style as H5 } from "@ikiru/talentis-fpc"
import { useSearch } from "views/search/SearchModule/context"

export const BoardLevelExperienceFilter = () => {
  const { filters, clearFilters } = useSearch()
  const checkboxFieldProps = useCheckboxField(FilterType.BoardLevelExperience)
  const mandatoryFiltersWereSet = useMandatoryFiltersWereSet()

  useEffect(() => {
    if (!mandatoryFiltersWereSet && filters[FilterType.BoardLevelExperience]) {
      clearFilters({ [FilterType.BoardLevelExperience]: undefined })
    }
  }, [filters, clearFilters, mandatoryFiltersWereSet])

  return (
    <Div width="100%">
      <FormikToggle
        id={SearchCriteriaField.BoardLevelExperience}
        name={SearchCriteriaField.BoardLevelExperience}
        type="checkbox"
        hideUnderline={true}
        alignTop
        disabled={!mandatoryFiltersWereSet}
        // @ts-ignore
        typographyStyle={H5}
        notCheckedFade
        wrapperProps={{ mb: "none" }}
        labelProps={{ ml: "xxs" }}
        label={messages.talentGraphSearch.filters.boardExperience}
        {...checkboxFieldProps}
      />
    </Div>
  )
}
