import React, { useState } from "react"
import { BodyText, Div, Flex, H5, Toggle } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import CVInput from "../CVInputs/CVInput"
import { ExistingData } from "../components/ExistingData"
import { ParsedData } from "../components/ParsedData"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { cloneDeep, get } from "lodash"
import {
  DateWrapper,
  // ExistingDateWrapper,
  ParsedWrapper,
  PersonContactsContainer,
  ToggleWrapper
} from "./style"
import CVCustomInput from "../CVInputs/CVCustomInput"
import {
  emailTypeOptions,
  phoneTypeOptions
} from "../../person-details-tab/components/person-contact-info/form/constants/definitions"
import CVInputExist from "../CVInputs/CVInputExist"
import isEmpty from "lodash/isEmpty"
import { getTypeMessage } from "./helper"

export const ParseContactInfo = () => {
  const [isUpdateAll, setIsUpdateAll] = useState(false)
  const [isDeleteAll, setIsDeleteAll] = useState(false)
  const { cvParsing, setCVParsing } = usePerson()

  const checkedParseField = (key: string, index: number) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.contactInfo[key][index].isChecked =
      !tempCVParsing.contactInfo[key][index].isChecked
    setCVParsing({ ...tempCVParsing })
  }

  const onChangeParsed = (date: any, index: number, type: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    if (tempCVParsing.contactInfo[type][index].hasOwnProperty("tempValue")) {
      delete tempCVParsing.contactInfo[type][index]["tempValue"]
    }

    tempCVParsing.contactInfo[type][index].isChecked = true
    if (type === "webSites") {
      tempCVParsing.contactInfo[type][index].parsed = {
        text: date
      }
    } else {
      tempCVParsing.contactInfo[type][index].parsed = { ...date }
    }
    setCVParsing({ ...tempCVParsing })
  }

  const saveTempValue = (date: any, index: number, type: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    if (type === "webSites") {
      tempCVParsing.contactInfo[type][index].tempValue = { text: date }
    } else {
      tempCVParsing.contactInfo[type][index].tempValue = { ...date }
    }
    setCVParsing({ ...tempCVParsing })
  }

  const addAll = () => {
    setIsUpdateAll((prevState) => !prevState)
    const tempCVParsing = cloneDeep(cvParsing)
    Object.values(tempCVParsing.contactInfo).forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach((element: any) => (element.isChecked = !isUpdateAll))
      }
    })
    setCVParsing({ ...tempCVParsing })
  }

  const handleDelete = (key: string, index: number) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.contactInfo[key][index].isDeleted =
      !tempCVParsing.contactInfo[key][index].isDeleted
    setCVParsing({ ...tempCVParsing })
  }

  const handleDeleteAll = () => {
    setIsDeleteAll((prevState) => !prevState)
    const tempCVParsing = cloneDeep(cvParsing)
    Object.values(tempCVParsing.contactInfo).forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach((element: any) => (element.isDeleted = !isDeleteAll))
      }
    })
    setCVParsing({ ...tempCVParsing })
  }

  return (
    <>
      <BodyText>{messages.person.cvparsing.contentParse}</BodyText>
      <Flex justifyContent="flex-end" mr="xs">
        <ExistingData
          deleteAll={handleDeleteAll}
          buttonText={
            isDeleteAll
              ? messages.person.cvparsing.recoverAll
              : messages.person.cvparsing.deleteAll
          }
        />
        <ParsedData
          isAllChecked={isUpdateAll}
          checkedAll={addAll}
          title={messages.person.cvparsing.addAll}
        />
      </Flex>

      <PersonContactsContainer>
        {Object.entries(cvParsing?.contactInfo).map(([type, values]) => (
          <Div key={type} mb="m">
            {values.length > 0 &&
              values.map((value: any, index: number) => {
                return (
                  <DateWrapper key={value.existing.text}>
                    <H5 width="15.4%" m="none" p="xxs">
                      {index === 0
                        ? get(messages, `person.cvparsing.contactInfo.${type}`)
                        : ""}
                    </H5>

                    <CVInputExist
                      typeText={getTypeMessage(
                        type,
                        value.existing.selectedValue
                      )}
                      width="42.4%"
                      text={value.existing?.text}
                      handleDelete={() => handleDelete(type, index)}
                      isDeleted={value.isDeleted}
                      isDisabled={isEmpty(value.existing)}
                    />

                    <ParsedWrapper>
                      {type === "webSites" ? (
                        <CVInput
                          text={value.parsed.text}
                          onChangeInput={(text) =>
                            onChangeParsed(text, index, type)
                          }
                          isDisabled={isEmpty(value.parsed)}
                          saveTempValue={(text) =>
                            saveTempValue(text, index, type)
                          }
                        />
                      ) : (
                        <CVCustomInput
                          typeText={getTypeMessage(
                            type,
                            value.parsed.selectedValue
                          )}
                          text={value.parsed.text}
                          isPreferred={value.parsed.isPreferred}
                          selectedValue={value.parsed.selectedValue}
                          onChangeInput={(date) =>
                            onChangeParsed(date, index, type)
                          }
                          saveTempValue={(date) =>
                            saveTempValue(date, index, type)
                          }
                          options={
                            type === "phones"
                              ? phoneTypeOptions
                              : emailTypeOptions
                          }
                          type={type}
                          isDisabled={isEmpty(value.parsed)}
                        />
                      )}
                      {!isEmpty(value.parsed) && (
                        <ToggleWrapper>
                          <Toggle
                            wrapperProps={{ ml: "27px" }}
                            hideUnderline
                            small
                            type="checkbox"
                            checked={value.isChecked}
                            onChange={() => checkedParseField(type, index)}
                          />
                        </ToggleWrapper>
                      )}
                    </ParsedWrapper>
                  </DateWrapper>
                )
              })}
            {/* {type === "linkedInProfileUrl" && (
                <DateWrapper key={values.existing}>
                  <H5 width="137px" m="none" p="xxs">
                    {get(messages, `person.cvparsing.contactInfo.${type}`)}
                  </H5>
                  <ExistingDateWrapper>{values.existing}</ExistingDateWrapper>
                  <ParsedWrapper>
                    <CVInput text={values.parsed} width="380px" isDisabled />
                    <ToggleWrapper>
                      <Toggle
                        wrapperProps={{ ml: "15px" }}
                        hideUnderline
                        small
                        type="checkbox"
                        disabled
                      />
                    </ToggleWrapper>
                  </ParsedWrapper>
                </DateWrapper>
              )} */}
          </Div>
        ))}
      </PersonContactsContainer>
    </>
  )
}
