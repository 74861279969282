import { Div, Flex } from "@ikiru/talentis-fpc"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import React from "react"
import { messages } from "setup/messages/messages"
import { CurrentPastEitherLogic } from "views/search/SearchModule/types"

type PersonFromTogglesProps = {
  currentValue: string
  fieldName: string
  onChangeToggle: (field: string, newValue: string) => void
}

const PersonFromToggles = ({
  fieldName,
  currentValue,
  onChangeToggle
}: PersonFromTogglesProps) => {
  const onFilterChange = (e: any) => {
    const newValue = e.target.value
    onChangeToggle(fieldName, newValue)
  }
  return (
    <Flex>
      <Div mr="xs">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          onChange={onFilterChange}
          value={CurrentPastEitherLogic.Current}
          checked={currentValue === CurrentPastEitherLogic.Current}
          name={fieldName}
          labelProps={{ ml: "xxs" }}
          label={
            messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
              .current
          }
        />
      </Div>
      <Div mr="xs">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          onChange={onFilterChange}
          value={CurrentPastEitherLogic.Past}
          checked={currentValue === CurrentPastEitherLogic.Past}
          name={fieldName}
          labelProps={{ ml: "xxs" }}
          label={
            messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
              .past
          }
        />
      </Div>
      <FormikToggle
        small
        type="radio"
        hideUnderline
        notCheckedFade
        onChange={onFilterChange}
        value={CurrentPastEitherLogic.Either}
        checked={currentValue === CurrentPastEitherLogic.Either}
        name={fieldName}
        labelProps={{ ml: "xxs" }}
        label={
          messages.talentGraphSearch.keywordRolesLogicSelector.searchLogic
            .either
        }
      />
    </Flex>
  )
}

export default PersonFromToggles
