import HorizontalScroll from "components/HorizontalScroll"
import React, { useMemo } from "react"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

import StageCard from "./StageCard"
import { StageTab } from "./style"
import { candidateStages } from "../candidate-record/components/InterviewProgress/definitions"
import { orderAssignmentStages } from "./utils"

const StageTabSection = () => {
  const { activeStages } = useAssignment()

  const tabArray = useMemo(() => {
    const orderedStagesList = orderAssignmentStages(candidateStages)
    const filterNullValues: any[] = orderedStagesList
      .map((stage) => [stage, activeStages[stage]])
      .filter(([_key, value]) => value && value > 0)

    if (activeStages.all) {
      filterNullValues.push(["all", activeStages.all])
    }
    return filterNullValues.reverse()
  }, [activeStages])

  return (
    <StageTab>
      <HorizontalScroll isRowTab>
        {tabArray.map(([key, _value], index) => {
          if (index === 0 && tabArray.length === 2) {
            return <div key={key + index} />
          }
          return <StageCard itemId={key} stage={key} key={key + index} />
        })}
      </HorizontalScroll>
    </StageTab>
  )
}

export default StageTabSection
