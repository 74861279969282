import React from "react"
import moment from "moment"
import {
  Div,
  H5,
  BodyText,
  Flex,
  colors,
  SmallText,
  Button,
  PencilIcon
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { formatDateTime } from "utils/format-date"
import { OffLimitsType } from "./type"

type OffLimitsProps = {
  editOnClick?: () => void
  isNoLastEdit?: boolean
  isEditMode?: boolean
  isSmall?: boolean
} & OffLimitsType

export const OffLimits = ({
  endDate,
  comment,
  updatedBy,
  updated,
  editOnClick,
  isNoLastEdit,
  isEditMode,
  isSmall
}: OffLimitsProps) => {
  const formattedDate = endDate ? moment(endDate).format("DD/MM/YYYY") : null

  const noSet = (
    <BodyText my="0" color={colors.grey.standard}>
      {messages.offLimits.offLimitsNoSet}
    </BodyText>
  )

  const colorText = isEditMode ? colors.grey.standard : colors.grey.darkest
  return (
    <>
      <Flex justifyContent="space-between" color={colorText}>
        <Div pr="xxs">
          <H5 my="0" color={colorText}>
            {messages.offLimits.offLimitsEndDate}
            {isEditMode && ":"}
          </H5>

          {formattedDate ? (
            <BodyText my="0" color={colorText}>
              {formattedDate}
            </BodyText>
          ) : (
            noSet
          )}
        </Div>
        {editOnClick && (endDate || comment) && (
          <Button
            mode="standard-white"
            size="action-medium"
            onClick={editOnClick}
          >
            <PencilIcon />
          </Button>
        )}
      </Flex>

      <Div mt={isEditMode || isSmall ? "xs" : "m"}>
        <H5 m="0" color={colorText}>
          {messages.offLimits.offLimitsComment}
          {isEditMode && ":"}
        </H5>
        {comment ? (
          <ShowMore visibleLines={1} description={comment} color={colorText} />
        ) : (
          noSet
        )}
      </Div>

      {updatedBy && (
        <SmallText color={colorText}>
          {isNoLastEdit ? "" : messages.offLimits.lastComment}
          {formatDateTime(updated)}
          {` ${updatedBy?.firstName} ${updatedBy?.lastName}`}
        </SmallText>
      )}
    </>
  )
}
