import React from "react"
import { Tag } from "@ikiru/talentis-fpc"
import { personKeywordsE2ETargets } from "./definitions"
import { messages } from "setup/messages/messages"
import { List } from "./style"
import { SectionTitle } from "views/persons/components/person-details-tab/style"
import { Keyword } from "views/persons/person-module/person-module.types"

type KeywordsListProps = {
  keywords?: Keyword[]
  onKeywordRemove?: (index: number) => any
}

export const KeywordsList = (props: KeywordsListProps) => {
  const { keywords, onKeywordRemove } = props

  return keywords && Boolean(keywords?.length) ? (
    <>
      <SectionTitle mb="xs">{messages.person.keywords.title}</SectionTitle>
      <List>
        {keywords.map((keyword, index) => (
          <Tag
            key={index + keyword.value}
            as="li"
            data-e2e-target={personKeywordsE2ETargets.keyword}
            mr="xxs"
            variant="grey"
            disabled
            onRemoveClick={onKeywordRemove && (() => onKeywordRemove(index))}
          >
            {keyword.value}
          </Tag>
        ))}
      </List>
    </>
  ) : null
}
