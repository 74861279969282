import React from "react"
import { Flex, Switch } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { SwitchLabel } from "./style"

interface ProjectSwitchProps {
  isAssignment: boolean,
  setIsAssignment: (isAssignment: boolean) => void
}

const ProjectSwitch = ({ isAssignment, setIsAssignment }: ProjectSwitchProps) => {
  return (
    <Flex>
      <SwitchLabel isSelected={isAssignment}>
        {messages.talentGraphSearch.projectSelector.projectSwitch.assignment}
      </SwitchLabel>
      <Switch
        colorVariant="secondary"
        label={""}
        checked={!isAssignment}
        onChange={() => setIsAssignment(!isAssignment)}
      />
      <SwitchLabel isSelected={!isAssignment}>
        {messages.talentGraphSearch.projectSelector.projectSwitch.campaign}
      </SwitchLabel>
    </Flex>
  )
}

export default ProjectSwitch
