import ViewableSection from "components/ViewableSection"
import React, { useRef } from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonSectionHeaders } from "../../style"
import { Div, Flex, PlusIcon } from "@ikiru/talentis-fpc"
import ActionButton from "components/ProjectActions/ActionButton"
import { messages } from "setup/messages/messages"
import PersonContactsView from "./components/PersonContactsView"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import useSubmitPerson from "../../hook/useSubmitPerson"
import PersonContactEdit from "./form/PersonContactEdit"
import { CreatePersonField } from "../../constant/definitions"
import {
  emailEmptyEntry,
  phoneNumberEmptyEntry
} from "./form/constants/definitions"
import { DefaultEditedField } from "components/EditableDetails/editable-details.types"

const PersonContacts = () => {
  const { profileDetails } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()

  const addPhoneNumberButtonRef = useRef<HTMLButtonElement>(null!)
  const addEmailAddressButtonRef = useRef<HTMLButtonElement>(null!)
  const buttonRefs = {
    addPhoneNumberButtonRef,
    addEmailAddressButtonRef
  }

  const editPersonContacts = (
    addingNewEntryEmail = false,
    addingNewEntryPhone = false
  ) => {
    const initialValues = {
      [CreatePersonField.PhoneNumbers]: profileDetails.phones.length
        ? profileDetails.phones
        : [phoneNumberEmptyEntry],
      [CreatePersonField.EmailAddresses]: profileDetails.taggedEmails.length
        ? profileDetails.taggedEmails
        : [emailEmptyEntry]
    }

    let defaultEditedField: DefaultEditedField = ""
    if (addingNewEntryEmail) {
      if (
        Boolean(
          initialValues.taggedEmails[initialValues.taggedEmails.length - 1]
            .value.email
        )
      ) {
        initialValues.taggedEmails.push(emailEmptyEntry)
      }
      defaultEditedField = CreatePersonField.EmailAddresses
    }

    if (addingNewEntryPhone) {
      if (
        Boolean(
          initialValues.phones[initialValues.phones.length - 1].value
            .phoneNumber
        )
      ) {
        initialValues.phones.push(phoneNumberEmptyEntry)
      }
      defaultEditedField = CreatePersonField.PhoneNumbers
    }

    openModal(
      ModalNames.EditPersonDetails,
      <PersonContactEdit
        onSubmit={onPersonSubmit}
        initialValues={initialValues}
        buttonRefs={buttonRefs}
        defaultEditedField={defaultEditedField}
      />
    )
  }

  return (
    <Div width="100%" mb="s">
      <ViewableSection
        view={<PersonContactsView />}
        title={
          <PersonSectionHeaders>
            {messages.person.personTabSection.contactInfo}
          </PersonSectionHeaders>
        }
        actions={
          <Flex>
            <ActionButton
              mr="xs"
              onClick={() => editPersonContacts(true)}
              label={messages.person.personTabSection.email}
              prefix={<PlusIcon width={12} height={12} fill="currentColor" />}
            />
            <ActionButton
              onClick={() => editPersonContacts(false, true)}
              label={messages.person.personTabSection.phoneNumbers}
              prefix={<PlusIcon width={12} height={12} fill="currentColor" />}
            />
          </Flex>
        }
        editFunction={editPersonContacts}
      />
    </Div>
  )
}
export default PersonContacts
