import axios from "axios"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { apiRequest } from "setup/api/api"
import { Stage } from "views/persons/person.types"

export const parseBooleanLikeEnv = (env?: string): boolean =>
  env?.toLowerCase() === "true"

export const onDocumentClick = async (noteId: string, documentId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: PersonsEndpoints.NotesDocuments,
    endpointParams: { noteId, documentId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  const emailData = await axios
    .get(response?.data?.temporaryUrl)
    .then(function (response) {
      return response.data
    })

  return emailData
}

export const replaceAllOccurrences = (
  originalString: string,
  searchValue: string | RegExp,
  replaceValue: string
) => {
  const regex = new RegExp(searchValue, "g")
  return originalString.replace(regex, replaceValue)
}

export const countLines = (text: string) => {
  const lettersPerLine = 35
  const maxLinesAllowed = 14
  const abs = Math.abs(text.length / lettersPerLine)
  return (abs <= maxLinesAllowed ? abs : maxLinesAllowed) || 4
}

export const findCountStage = (stages: Stage[], nameStage: string) => {
  return stages?.find((stage) => stage.name === nameStage)?.count || 0
}
