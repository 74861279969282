import React, { useCallback } from "react"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { colors } from "@ikiru/talentis-fpc"
import { H2, BodyText } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"
import { LicensesWarningModalWrapper, CloseButton } from "./style"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { Link } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { useNavigate } from "react-router-dom"
import { messages } from "setup/messages/messages"
import { ValueOf } from "tsdef"

export const LicensesWarningModal = () => {
  const { close, onClose } = useModal()
  const navigate = useNavigate()

  const closeAndRedirect = useCallback(
    (route?: ValueOf<typeof RouterUrl>) => () => {
      onClose(ModalNames.Licenses, () => {
        route && navigate(route)
      })

      close(ModalNames.Licenses)
    },
    [navigate, onClose, close]
  )

  return (
    <LicensesWarningModalWrapper>
      <Flex position="relative" justifyContent="flex-end">
        <CloseButton
          onClick={closeAndRedirect()}
          mode="standard-white"
          size="action-medium"
        >
          <CrossIcon fill={colors.grey.dark} />
        </CloseButton>
      </Flex>
      <Flex p="m" flexDirection="row" flexWrap="wrap">
        <H2 m="xs" textAlign="center" color="grey.dark">
          {messages.manageTeam.licensesModal.allOfYourUser}
        </H2>
        <BodyText m={0} textAlign="center">
          {messages.manageTeam.licensesModal.toInviteSomeone}
        </BodyText>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          <Div my="xs">
            <Button as="a" href="mailto:info@ikirupeople.com">
              {messages.manageTeam.licensesModal.contactUs}
            </Button>
          </Div>
          <Link onClick={closeAndRedirect(RouterUrl.Team)}>
            {messages.manageTeam.licensesModal.backToTeam}
          </Link>
        </Flex>
      </Flex>
    </LicensesWarningModalWrapper>
  )
}
