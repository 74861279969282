import styled from "styled-components"
import { Flex, theme } from "@ikiru/talentis-fpc"

export const StyledFlex = styled(Flex)`
  flex-direction: column;
  min-height: 100px;
`

export const ComingSoonWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  max-width: 230px;
  background-color: ${theme.colors.grey.light};
  height: fit-content;
  width: 100%;
  padding-bottom: ${theme.space.s}px;
  border-radius: 10px;
  margin-bottom: 30px;
`
