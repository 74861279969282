import React, { useCallback, useMemo } from "react"
import { EditableSection } from "components/EditableSection"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { personExperienceE2ETargets as e2eTargets } from "./definitions"
import { messages } from "setup/messages/messages"
import { PersonExperienceView } from "./components/PersonExperienceView"
import {
  Button,
  Div,
  Flex,
  PencilIcon,
  PlusIcon,
  SectionHeader,
  colors
} from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { EditPersonExperience } from "../professional-experience-modal/EditPersonExperience"
import ActionButton from "components/ProjectActions/ActionButton"
import { EmptyExperience } from "./components/EmptyExperience"

export const PersonExperience = React.memo(() => {
  const {
    profileDetails,
    setProfileDetails,
    createExperience,
    personId,
    setPersonOverlayUpdated
  } = usePerson()
  const { open, close } = useModal()

  const experience = useMemo(
    () => profileDetails.experience.value,
    [profileDetails]
  )

  const createPersonExperienceModal = useCallback(() => {
    const onSubmit = async (values: any) => {
      const payload = {
        title: values.title,
        description: values.description,
        startDate: values.startDate,
        endDate: values.endDate,
        company: values.company,
        companyId: values.companyId,
        salary: values.salary,
        package: values.package,
        comment: values.comment
      }
      const response = await createExperience(personId, payload)
      setProfileDetails({
        ...profileDetails,
        companyId: {
          isReadOnly: profileDetails.companyId.isReadOnly,
          value: response?.[1]?.data?.companyId || ""
        },
        company: {
          isReadOnly: profileDetails.company.isReadOnly,
          value: response?.[1]?.data?.company || ""
        },
        jobTitle: {
          isReadOnly: profileDetails.jobTitle.isReadOnly,
          value: response?.[1]?.data?.jobTitle || ""
        },
        experience: {
          isReadOnly: profileDetails.experience.isReadOnly,
          value: response?.[1]?.data?.experience || []
        }
      })
      setPersonOverlayUpdated(true)
      close(ModalNames.EditPersonExperience)
    }
    open(
      ModalNames.EditPersonExperience,
      <EditPersonExperience onSubmit={onSubmit} isCreate isCompanyEditable />
    )
  }, [
    open,
    createExperience,
    personId,
    setProfileDetails,
    profileDetails,
    setPersonOverlayUpdated,
    close
  ])

  return (
    <EditableSection
      size="xSmall"
      noBottomSpace
      isDefaultExpanded
      header={{ title: messages.person.experience.title }}
      targetName={e2eTargets.section}
      actions={({ setViewMode, setEditMode, isInEditMode, isInViewMode }) => (
        <SectionHeader
          title={messages.person.experience.title}
          size="xxSmall"
          actions={
            <>
              {isInEditMode && (
                <>
                  <Button
                    size="small"
                    mode="standard-white"
                    onClick={() => {
                      setViewMode()
                    }}
                  >
                    {messages.generic.done}
                  </Button>
                </>
              )}
              {isInViewMode && (
                <>
                  <ActionButton
                    prefix={
                      <Div mt="1px">
                        <PlusIcon
                          fill={colors.white.standard}
                          width={13}
                          height={13}
                        />
                      </Div>
                    }
                    mode="standard-green"
                    size="small"
                    onClick={() => createPersonExperienceModal()}
                    mr="xs"
                    label={messages.person.experience.title}
                  />

                  {Boolean(experience.length) && (
                    <Button
                      style={{ minWidth: "30px" }}
                      mode="standard-white"
                      size="action-medium"
                      onClick={() => {
                        setEditMode()
                      }}
                    >
                      <PencilIcon color="red" />
                    </Button>
                  )}
                </>
              )}
            </>
          }
        />
      )}
      view={() => {
        return (
          <Flex justifyContent="center" width="100%">
            {experience.length ? (
              <PersonExperienceView />
            ) : (
              <EmptyExperience
                addManually={createPersonExperienceModal}
                isLinkToTalentGraph={Boolean(profileDetails.dataPoolPersonId)}
              />
            )}
          </Flex>
        )
      }}
      edit={() => {
        return <PersonExperienceView editMode />
      }}
    />
  )
})
